import styled from 'styled-components';

import {Tooltip as MuiTooltip} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import * as theme from '../../../../theme';

export const Tooltip = styled(MuiTooltip)`
  font-size: 14px;
`;

export const DescriptionIcon = styled(InfoOutlinedIcon)`
  margin-right: 4px;
  vertical-align: text-bottom;
  color: ${theme.text.secondary};
`;
