import _ from 'lodash';
import {format} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import {Paper} from '../../Base';

import langs from '../../../localization/langs';

import * as Styled from './TenantVersion.styles';

const MISSING_VALUE = '-';

function getDeployedDateDisplayValue(deployedDate) {
  return deployedDate === MISSING_VALUE ? deployedDate : format(new Date(deployedDate), 'P');
}

function TenantVersion({tenant}) {
  const deployedDate = _.get(tenant, 'lastDeploymentDate', MISSING_VALUE);
  const deployedVersion = _.get(tenant, 'applicationVersion', MISSING_VALUE);

  return (
    <Paper style={{height: '100%', padding: '2rem'}}>
      <Styled.Header>{langs('APP_VERSION')}</Styled.Header>
      <Styled.Version>
        <Styled.Value>{deployedVersion}</Styled.Value>
      </Styled.Version>
      <Styled.Header>{langs('LAST_UPDATED')}</Styled.Header>
      <Styled.Value>{getDeployedDateDisplayValue(deployedDate)}</Styled.Value>
    </Paper>
  );
}

TenantVersion.propTypes = {
  tenant: PropTypes.object.isRequired
};

export default TenantVersion;
