export default {
  DEPLOYMENTS: 'Deployments',
  DEPLOYMENTS_ACTION_DIALOG_CURRENT_REPLICAS: 'Current number of replicas:',
  DEPLOYMENTS_ACTION_DIALOG_ENTER_REPLICAS: 'Select amount of replicas:',
  DEPLOYMENTS_ACTION_DIALOG_REDIRECT: 'Redirect to logs',
  DEPLOYMENTS_ACTION_DIALOG_SELECT_BRANCH: 'Select a branch:',
  DEPLOYMENTS_ACTION_DIALOG_TITLE: 'Service action: <%=action%>',
  DEPLOYMENTS_ACTION_DIALOG_WARNING_ALL: 'This action will apply on <%=count%> services:',
  DEPLOYMENTS_ACTION_DIALOG_WARNING_ONE: 'This action will apply on the following service:',
  DEPLOYMENTS_ACTION_DIALOG_WARNING_TOO_MANY: 'Too many deployments to show',
  DEPLOYMENTS_ACTION_DIALOG_WARNING_TOP: 'This action will apply on <%=count%> services, sample:',
  DEPLOYMENTS_ACTIONS_DEPLOY: 'Deploy',
  DEPLOYMENTS_ACTIONS_REPLICATE: 'Scale',
  DEPLOYMENTS_ACTIONS_RESTART: 'Restart',
  DEPLOYMENTS_ACTIONS_STOP: 'Stop',
  DEPLOYMENTS_ACTIONS_TITLE: 'Actions for <%=path%>',
  DEPLOYMENTS_FIELD_BRANCH: 'Version',
  DEPLOYMENTS_FIELD_COUNT: 'Number of services',
  DEPLOYMENTS_FIELD_DEPLOYMENT_DATE: 'Deployment Date',
  DEPLOYMENTS_FIELD_DEPLOYMENT_ID: 'ID',
  DEPLOYMENTS_FIELD_DEPLOYMENT_REPLICAS: 'Replicas',
  DEPLOYMENTS_FIELD_DESCRIPTION: 'Description',
  DEPLOYMENTS_FIELD_ENV: 'Environment',
  DEPLOYMENTS_FIELD_NAMESPACE: 'Namespace',
  DEPLOYMENTS_FIELD_OWNER: 'Owner',
  DEPLOYMENTS_FIELD_SERVICE: 'Service',
  DEPLOYMENTS_FIELD_STACK: 'Stack',
  MULTI_TENANT: 'Multi Tenant',
  NAMESPACES: 'Namespaces',
  NAMESPACES_EDIT_NAMESPACE: 'Edit Namespace',
  NAMESPACES_FAILED_TO_CREATE: 'Failed to create namespace',
  NAMESPACES_FAILED_TO_LOAD: 'Failed to load namespace',
  NAMESPACES_FAILED_TO_UPDATE: 'Failed to update namespace',
  NAMESPACES_FIELD_ENV_NAME: 'Environment',
  NAMESPACES_FIELD_ID: 'ID',
  NAMESPACES_FIELD_NAME: 'Name',
  NAMESPACES_FIELD_STACKS: 'Stacks',
  NAMESPACES_NEW_NAMESPACE: 'New Namespace',
  NAMESPACES_SUCCESSFULLY_UPDATED: 'Namespace was updated successfully',
  RELEASE_CYCLE: 'Release Cycle',
  RELEASE_CYCLE_MASTER: 'Master',
  RELEASE_CYCLE_RELEASE: 'Release',
  RELEASE_CYCLE_UNKNOWN: 'Unknown',
  SERVICES: 'Services',
  SERVICES_EDIT_SERVICE: 'Edit Service',
  SERVICES_FAILED_TO_CREATE: 'Failed to create service',
  SERVICES_FAILED_TO_LOAD: 'Failed to load service',
  SERVICES_FAILED_TO_UPDATE: 'Failed to update service',
  SERVICES_FIELD_CHART: 'Chart',
  SERVICES_FIELD_CRON_EXP: 'Cron Expressions',
  SERVICES_FIELD_DESCRIPTION: 'Description',
  SERVICES_FIELD_ID: 'ID',
  SERVICES_FIELD_NAME: 'Name',
  SERVICES_FIELD_OWNER: 'Owner',
  SERVICES_FIELD_VERSION_RANGE: 'Version Range',
  SERVICES_NEW_SERVICE: 'New Service',
  SERVICES_SUCCESSFULLY_UPDATED: 'Service was updated successfully',
  STACKS: 'Stacks',
  STACKS_EDIT_STACK: 'Edit Stack',
  STACKS_FAILED_TO_CREATE: 'Failed to create stack',
  STACKS_FAILED_TO_LOAD: 'Failed to load stack',
  STACKS_FAILED_TO_UPDATE: 'Failed to update stack',
  STACKS_FIELD_ID: 'ID',
  STACKS_FIELD_NAME: 'Name',
  STACKS_FIELD_SERVICES: 'Services',
  STACKS_NEW_STACK: 'New Stack',
  STACKS_SUCCESSFULLY_UPDATED: 'Stack was updated successfully'
};
