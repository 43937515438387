import _ from 'lodash';

import utils from '../../../utils';

export function composePermissionName(serviceName, envName, permissionName) {
  return _.toLower(`${_.kebabCase(serviceName)}.${envName}.${permissionName}`);
}

export function wrapPermissionNames(serviceName, permissions, env) {
  if (_.isString(permissions)) {
    permissions = [permissions];
  }
  env = env || utils.urlUtils.getCurrentEnv();
  return _.map(permissions, (permission) => composePermissionName(serviceName, env, permission));
}

export default {
  composePermissionName,
  wrapPermissionNames
};
