import styled from 'styled-components';

import * as theme from '../../../theme';

export const Link = styled.a`
  &:not(:last-of-type) {
    margin-right: 5px;
  }

  color: ${theme.link.text};
  &:hover {
    color: ${theme.link.hover};
  }
`;
