import moment from 'moment';
import React from 'react';

import langs from '../localization/langs';

import DateField from '../components/Base/DateField';

import * as Styled from '../components/Base/Link.styles';

function boolean(value) {
  return value ? '✓' : null;
}

function date(value) {
  if (!value) {
    return null;
  }
  return <DateField date={value} />;
}

function duration(time1, time2) {
  if (!time1 || !time2) {
    return null;
  }
  const start = moment(time1);
  const end = moment(time2);
  const duration = moment.duration(end - start);
  return duration.humanize();
}

function email(value) {
  if (!value) {
    return null;
  }
  return (
    <Styled.BaseLink target="_blank" rel="noopener noreferrer" href={`mailto:${value}`}>
      {value}
    </Styled.BaseLink>
  );
}

function link(value, onClick) {
  return <Styled.TextLink onClick={onClick}>{value}</Styled.TextLink>;
}

function list(values) {
  if (!values) {
    return null;
  }
  return (
    <ul>
      {values.map((value) => (
        <li key={value}>{value}</li>
      ))}
    </ul>
  );
}

function tenant(value) {
  if (!value) {
    return null;
  }
  return (
    <Styled.BaseLink target="_blank" rel="noopener noreferrer" href={`https://${value}.armis.com`}>
      {value}
    </Styled.BaseLink>
  );
}

function withPrefix(prefix, value) {
  if (!value) {
    return null;
  }
  return langs(`${prefix}${value}`);
}

export default {
  boolean,
  date,
  duration,
  email,
  link,
  list,
  tenant,
  withPrefix
};
