import React from 'react';
import PropTypes from 'prop-types';

import ConfigurationNavigation from '../../../Configuration/ConfigurationNavigation';
import EditorActions from './EditorActions';
import ProgressBar from '../../../Base/Progressbar';
import RoleList from './RoleList';

import * as Styled from './SchemaEditorForm.styles';

const DEFAULT_DISPLAY_SETTINGS = {
  showAllLabel: false
};

function SchemaEditorForm({
  enableAddButton,
  enableDeleteButton,
  enableSaveButton,
  isLoading,
  labelDefinitions,
  onAddBindingClick,
  onDeleteBindingClick,
  onSaveButtonClick,
  onRoleItemClick,
  roles
}) {
  return (
    <Styled.Container>
      <ProgressBar isLoading={isLoading} />
      <Styled.MainGrid container>
        <ConfigurationNavigation displaySettings={DEFAULT_DISPLAY_SETTINGS} labelDefinitions={labelDefinitions} />
        <Styled.ItemsGrid item xs={9}>
          <EditorActions
            enableAddButton={enableAddButton}
            enableDeleteButton={enableDeleteButton}
            enableSaveButton={enableSaveButton}
            onAddBindingClick={onAddBindingClick}
            onDeleteBindingClick={onDeleteBindingClick}
            onSaveClick={onSaveButtonClick}
          />
          <Styled.ScrollableContainer container>
            <RoleList onRoleSelectionChange={onRoleItemClick} roles={roles} />
          </Styled.ScrollableContainer>
        </Styled.ItemsGrid>
      </Styled.MainGrid>
    </Styled.Container>
  );
}

SchemaEditorForm.propTypes = {
  enableAddButton: PropTypes.bool.isRequired,
  enableDeleteButton: PropTypes.bool.isRequired,
  enableSaveButton: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  labelDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          disabled: PropTypes.bool,
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      ).isRequired,
      permissionsFunc: PropTypes.func,
      value: PropTypes.object,
      width: PropTypes.number.isRequired
    })
  ).isRequired,
  onAddBindingClick: PropTypes.func.isRequired,
  onDeleteBindingClick: PropTypes.func.isRequired,
  onRoleItemClick: PropTypes.func.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired
    })
  )
};

export default SchemaEditorForm;
