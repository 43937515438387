import styled from 'styled-components';

import {Grid, Paper} from '@material-ui/core';

export const FullWidthPaper = styled(Paper)`
  width: 100%;
  padding: 25px;
  height: 100%;
`;

export const Container = styled(Grid)`
  padding-bottom: 15px;
`;
