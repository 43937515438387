import format from 'date-fns/format';

import client from '../client';

const LINE_SEPARATOR = '@%@%@%@';

function formatLog(val) {
  try {
    const parsed = JSON.parse(val);
    const prefix = `${format(
      parsed.timestamp ? new Date(parsed.timestamp) : Date.now(),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
    )} ${parsed.level_name}`;
    const message = `${prefix} ${parsed.function_filename}:${parsed.function_name}:${parsed.line} ${parsed.msg} ${
      parsed?.stack_trace ? `\n${parsed.stack_trace}` : ''
    }`;
    return {level: parsed.level_name, message, timestamp: parsed.timestamp || parsed.start_time};
  } catch (e) {
    return {message: val};
  }
}

export function stream(deploymentId, logType, onChunk) {
  // Axios doesn't support streaming, so we have to do it manually
  // This method is streaming k8s logs from specific
  // The logs are JSON formatted and streamed as they are, except LINE_SEPARATOR
  // which is used to separate logs from different lines
  return fetch(`/mtms/api/v1/deployments/${deploymentId}${logType ? `/jobs/${logType}` : ''}/logs`).then((response) => {
    const reader = response.body.getReader();

    return new ReadableStream({
      start(controller) {
        function handleChunk() {
          reader.read().then(({done, value}) => {
            if (done) {
              controller.close();
              return;
            }
            try {
              controller.enqueue(value);
            } catch (e) {
              reader.cancel();
              return;
            }
            const decodedValue = new TextDecoder().decode(value);
            onChunk(decodedValue.split(LINE_SEPARATOR).map(formatLog));
            handleChunk();
          });
        }

        handleChunk();
      }
    });
  });
}

export function getHooks(deploymentId) {
  return client.get(`/mtms/api/v1/deployments/${deploymentId}/hooks`);
}
