import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import AutoComplete from '../../Base/AutoComplete';
import AffectedEntities from '../parts/AffectedEntities';
import ConfigurationInput from '../../Base/ConfigurationInput';
import ConfigurationSelect from '../../Base/ConfigurationSelect';

import enums from '../enums';
import hooks from '../../../hooks';
import langs from '../../../localization/langs';
import models from '../../../models';
import utils from '../utils';

import * as Styled from './AddConfigurationKey.styles';

function AddConfigurationKey({
  value,
  onClose,
  onChange,
  levels,
  categories,
  displaySettings,
  selectedValues,
  getAffectedEntities,
  getConfigurationKeys
}) {
  const [affectedEntities, setAffectedEntities] = React.useState([]);
  const [category, setCategory] = React.useState(_.head(categories).id);
  const [configurationKeys, setConfigurationKeys] = React.useState([]);
  const [defaultValue, setDefaultValue] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [isError, setIsError] = React.useState(false);
  const [keyName, setKeyName] = React.useState('');
  const [keyType, setKeyType] = React.useState(models.configuration.TYPES.STRING);
  const [subCategory, setSubCategory] = React.useState(enums.SUB_CATEGORY.GENERAL);

  const isLevelsEmpty = _.every(selectedValues, ({value}) => _.isNull(value?.name));

  React.useEffect(() => {
    if (value && getAffectedEntities && !isLevelsEmpty) {
      fetchAffectedServices().then(setAffectedEntities);
    }
  }, [value, isLevelsEmpty]);

  const fetchConfigurationKeys = hooks.useDebounce(async (query) => {
    const keys = await getConfigurationKeys(query);
    setConfigurationKeys(keys);
  }, 500);

  async function fetchAffectedServices() {
    if (!_.isFunction(getAffectedEntities)) {
      return [];
    }
    const levelValueArray = getLevelValueArray();
    return getAffectedEntities(levelValueArray);
  }

  function getLevelValueArray() {
    return _.transform(
      levels,
      (res, level) => {
        const levelValue = _.find(selectedValues, {label: level});
        const levelName = _.get(levelValue, 'value.name');
        if (_.isEmpty(levelName)) {
          return true;
        }
        res.push({level, name: levelName});
      },
      []
    );
  }

  function getKeyLocation() {
    const levelValueArray = getLevelValueArray();
    if (_.isEmpty(levelValueArray)) {
      return <Styled.NoteTitle>{_.head(levels)}</Styled.NoteTitle>;
    }
    return _.map(levelValueArray, ({level, name}) => {
      return (
        <div key={`${level}-${name}`}>
          <Styled.NoteTitle>{_.capitalize(level)}</Styled.NoteTitle>: {name}
        </div>
      );
    });
  }

  async function onSubmit() {
    if (_.isEmpty(keyName) || _.isEmpty(subCategory) || _.isEmpty(description)) {
      setIsError(true);
      return;
    }

    const keyedSelectedValues = utils.getKeyedSelectedValues(selectedValues);
    await onChange(
      keyName,
      category,
      subCategory,
      keyType,
      defaultValue,
      description,
      keyedSelectedValues,
      affectedEntities
    );
    resetForm();
    onClose();
  }

  function getSubCategoryComponent() {
    const selectedCategory = _.find(categories, {id: category});
    const subCategories = _.get(selectedCategory, 'subCategories');
    if (_.isEmpty(subCategories)) {
      return (
        <ConfigurationInput value={subCategory} onChange={setSubCategory} error={isError && _.isEmpty(subCategory)} />
      );
    }
    return (
      <ConfigurationSelect
        value={subCategory}
        onChange={setSubCategory}
        options={_.map(subCategories, ({id, name}) => ({label: name, value: id}))}
      />
    );
  }

  function onCategoryChange(newCategory) {
    setSubCategory(enums.SUB_CATEGORY.GENERAL);
    setCategory(newCategory);
  }

  function resetForm() {
    setCategory(_.head(categories).id);
    setDefaultValue('');
    setDescription('');
    setIsError(false);
    setKeyName('');
    setKeyType(models.configuration.TYPES.STRING);
    setSubCategory(enums.SUB_CATEGORY.GENERAL);
  }

  function onConfigurationKeyChange(newConfigKey, isNew) {
    if (_.isEmpty(newConfigKey)) {
      return;
    }
    if (isNew) {
      setKeyName(newConfigKey);
      setDescription('');
      setKeyType(models.configuration.TYPES.STRING);
      setCategory(_.head(categories).id);
      setSubCategory(enums.SUB_CATEGORY.GENERAL);
    } else {
      setKeyName(newConfigKey.name);
      setKeyType(newConfigKey.type);
      setSubCategory(newConfigKey.keySubCategory);
      setCategory(newConfigKey.keyCategory);
      setDescription(newConfigKey.description);
    }
  }

  function onConfigurationKeyInputChange(newInput) {
    fetchConfigurationKeys(newInput);
  }

  return (
    <Dialog open={value} onClose={onClose}>
      <DialogTitle>
        {langs('ADD_NEW_CONFIGURATION')}
        <br />
        <Styled.Note>{getKeyLocation()}</Styled.Note>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Styled.Label>{langs('CONFIGURATION_KEY_NAME')}</Styled.Label>
          </Grid>
          <Grid item xs={8}>
            <AutoComplete
              onInputChange={onConfigurationKeyInputChange}
              onChange={onConfigurationKeyChange}
              options={configurationKeys}
            />
          </Grid>
          <Grid item xs={4}>
            <Styled.Label>{langs('CONFIGURATION_TYPE')}</Styled.Label>
          </Grid>
          <Grid item xs={8}>
            <ConfigurationSelect
              options={_.map(models.configuration.TYPES, (value) => ({label: value, value}))}
              value={keyType}
              onChange={setKeyType}
            />
          </Grid>
          {displaySettings.showAddNewKeyDefaultValue && (
            <>
              <Grid item xs={4}>
                <Styled.Label>{langs('VALUE')}</Styled.Label>
              </Grid>
              <Grid item xs={8}>
                <ConfigurationInput value={defaultValue} onChange={setDefaultValue} />
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            <Styled.Label>{langs('CATEGORY')}</Styled.Label>
          </Grid>
          <Grid item xs={8}>
            <ConfigurationSelect
              value={category}
              onChange={onCategoryChange}
              options={_.map(categories, ({id, name}) => ({label: name, value: id}))}
            />
          </Grid>
          <Grid item xs={4}>
            <Styled.Label>{langs('SUB_CATEGORY')}</Styled.Label>
          </Grid>
          <Grid item xs={8}>
            {getSubCategoryComponent()}
          </Grid>
          <Grid item xs={4}>
            <Styled.Label>{langs('DESCRIPTION')}</Styled.Label>
          </Grid>
          <Grid item xs={8}>
            <ConfigurationInput
              value={description}
              onChange={setDescription}
              multiline
              rows={3}
              error={isError && _.isEmpty(description)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <AffectedEntities value={_.get(affectedEntities, 'displayValues', [])} />
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {langs('CANCEL')}
        </Button>
        <Button color="secondary" onClick={onSubmit}>
          {langs('ADD')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddConfigurationKey.propTypes = {
  categories: PropTypes.array.isRequired,
  displaySettings: PropTypes.shape({
    showAddNewKeyDefaultValue: PropTypes.bool,
    showAllLabel: PropTypes.bool,
    showConfigurationKeySearch: PropTypes.bool,
    showCreatedByCol: PropTypes.bool,
    showEmptyFilter: PropTypes.bool,
    showInheritCol: PropTypes.bool,
    showLastUpdateCol: PropTypes.bool,
    titleizeSubCategories: PropTypes.bool
  }),
  getAffectedEntities: PropTypes.func.isRequired,
  getConfigurationKeys: PropTypes.func.isRequired,
  levels: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedValues: PropTypes.array.isRequired,
  value: PropTypes.bool.isRequired
};

export default AddConfigurationKey;
