import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import * as theme from '../../../theme';

export const Container = styled(Grid).attrs({container: true})`
  min-width: 25vw;
  max-width: 700px;
  padding: 30px;
  background-color: ${theme.backgrounds.light};
`;

export const Section = styled(Typography).attrs({color: 'textPrimary', variant: 'body1'})`
  margin-top: 30px;
`;
