import consts from '../../../consts';
import langs from '../../../localization/langs';

const CATEGORY_PERMISSIONS_CREATE = consts.rbac.permissions.mtms.configuration.create.categories;
const CATEGORY_PERMISSIONS_EDIT = consts.rbac.permissions.mtms.configuration.edit.categories;
const CATEGORY_PERMISSIONS_READ = consts.rbac.permissions.mtms.configuration.read.categories;

const categories = [
  {
    id: 'APP',
    name: langs('APP_CONFIG'),
    permissions: {
      create: CATEGORY_PERMISSIONS_CREATE.appConfig,
      edit: CATEGORY_PERMISSIONS_EDIT.appConfig,
      read: CATEGORY_PERMISSIONS_READ.appConfig
    }
  },
  {
    id: 'DEPLOY',
    name: langs('DEPLOY_CONFIG'),
    permissions: {
      create: CATEGORY_PERMISSIONS_CREATE.deployConfig,
      edit: CATEGORY_PERMISSIONS_EDIT.deployConfig,
      read: CATEGORY_PERMISSIONS_READ.deployConfig
    }
  },
  {
    id: 'SECRETS',
    name: langs('SECRETS'),
    permissions: {
      create: CATEGORY_PERMISSIONS_CREATE.secrets,
      edit: CATEGORY_PERMISSIONS_EDIT.secrets,
      read: CATEGORY_PERMISSIONS_READ.secrets
    }
  }
];

const displaySettings = {
  showEmptyFilter: true
};

const levelNames = ['ALL', 'SERVICE', 'ENV', 'NAMESPACE'];

export default {
  categories,
  displaySettings,
  levelNames
};
