import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import langs from '../../../localization/langs';
import utils from '../utils';

import ConfigurationRow from './Parts/ConfigurationRow';

import * as Styled from './ConfigurationTable.styles';

function ConfigurationTable({
  changes,
  currentLevel,
  displaySettings,
  extendedTypes,
  levels,
  mainCategory,
  onConfigurationChange,
  onDeleteConfigurationKeyValue,
  onGetConfigurationKeyVersions,
  onRollbackKeyVersion,
  permissionsFuncs,
  rows,
  subCategory
}) {
  const [isExpanded, setIsExpanded] = React.useState(true);

  return (
    <Styled.Container>
      <Styled.SubTitle onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded && <ExpandLess />}
        {!isExpanded && <ExpandMore />}
        {displaySettings.titleizeSubCategories ? _.startCase(_.capitalize(subCategory)) : subCategory}
      </Styled.SubTitle>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Table size="small">
          <TableHead>
            <TableRow>
              {displaySettings.showInheritCol && <Styled.HeaderCell width="20px">{langs('INHERIT')}</Styled.HeaderCell>}
              {displaySettings.showInheritCol && <Styled.HeaderCell width="70px">{langs('FROM')}</Styled.HeaderCell>}
              <Styled.HeaderCell width="130px">{langs('CONFIG_NAME')}</Styled.HeaderCell>
              <Styled.HeaderCell>{langs('CONFIG_VALUE')}</Styled.HeaderCell>
              {displaySettings.showCreatedByCol && (
                <Styled.HeaderCell width="50px">{langs('CREATED_BY')}</Styled.HeaderCell>
              )}
              {displaySettings.showLastUpdateCol && (
                <Styled.HeaderCell width="100px">{langs('ACTIONS')}</Styled.HeaderCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(rows, (row) => (
              <ConfigurationRow
                keyId={row.configurationKeyId}
                valueId={row.configurationValueId}
                configurationType={row.configurationType}
                createdBy={row.createdBy}
                creationDate={row.creationDate}
                currentLevel={currentLevel}
                description={row.description}
                displaySettings={displaySettings}
                extendedTypes={extendedTypes}
                inheritanceLevel={utils.getRowInheritance(changes, row)}
                key={row.key}
                keyName={row.key}
                levels={levels}
                onConfigurationChange={onConfigurationChange}
                onDeleteConfigurationKeyValue={onDeleteConfigurationKeyValue}
                onGetConfigurationKeyVersions={onGetConfigurationKeyVersions}
                onRollbackKeyVersion={onRollbackKeyVersion}
                permissionsFuncs={permissionsFuncs}
                mainCategory={mainCategory}
                subCategory={subCategory}
                value={utils.getRowValue(changes, row)}
              />
            ))}
          </TableBody>
        </Table>
      </Collapse>
    </Styled.Container>
  );
}

ConfigurationTable.propTypes = {
  changes: PropTypes.object.isRequired,
  currentLevel: PropTypes.string.isRequired,
  displaySettings: PropTypes.shape({
    showAllLabel: PropTypes.bool,
    showConfigurationKeySearch: PropTypes.bool,
    showCreatedByCol: PropTypes.bool,
    showEmptyFilter: PropTypes.bool,
    showInheritCol: PropTypes.bool,
    showLastUpdateCol: PropTypes.bool,
    titleizeSubCategories: PropTypes.bool
  }),
  extendedTypes: PropTypes.objectOf(PropTypes.elementType),
  levels: PropTypes.array.isRequired,
  mainCategory: PropTypes.string.isRequired,
  onConfigurationChange: PropTypes.func.isRequired,
  onDeleteConfigurationKeyValue: PropTypes.func.isRequired,
  onGetConfigurationKeyVersions: PropTypes.func.isRequired,
  onRollbackKeyVersion: PropTypes.func.isRequired,
  permissionsFuncs: PropTypes.shape({edit: PropTypes.func}),
  rows: PropTypes.array.isRequired,
  subCategory: PropTypes.string.isRequired
};

export default ConfigurationTable;
