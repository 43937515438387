import _ from 'lodash';

export function getParams(
  like = '',
  branchTypes = null,
  length = 100,
  orderBy = 'creationDate:desc',
  isLatestBuild = true,
  isOpenForCp = false,
  creationDate = null,
  branchGreater = ''
) {
  const params = {'branch[ge]': branchGreater, 'branch[like]': like, length, orderBy};
  if (isLatestBuild) {
    params['isLatestBuild[eq]'] = 'true';
  }
  if (isOpenForCp) {
    params['isOpenForCp[eq]'] = 'true';
  }
  if (!_.isNil(branchTypes)) {
    params['branchType[eq]'] = branchTypes.join(',');
  }
  if (!_.isNil(creationDate)) {
    params['creationDate[ge]'] = creationDate;
  }

  return params;
}
