import styled from 'styled-components';

import {InputBase} from '@material-ui/core';
import * as theme from '../../theme';

export const Container = styled(InputBase)`
  background-color: ${theme.backgrounds.primary};
  padding: 0 5px;
  border: ${({error}) => (error ? `1px solid ${theme.statusColors.error}` : 'none')};
`;
