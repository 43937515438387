import styled from 'styled-components';

import * as theme from '../../../theme';

export const Container = styled.div`
  text-transform: capitalize;
  font-size: 14px;
  user-select: none;
  cursor: pointer;
  display: flex;
  font-weight: 600;
`;

export const EnvSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DisplayName = styled.span`
  font-size: 12px;
`;

export const Options = styled.div`
  position: absolute;
  background-color: ${theme.backgrounds.logo};
  padding: 10px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4);
  z-index: ${theme.zIndex.advancedSearch};
`;

export const Option = styled.a`
  display: block;
  text-decoration: none;
  margin-bottom: 5px;
  &:hover {
    background-color: ${theme.backgrounds.light};
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1);
  }
  color: ${({$selected}) => ($selected ? theme.navbar.activeText : theme.navbar.text)};
`;
