import CytoscapeComponent from 'react-cytoscapejs';
import styled from 'styled-components';

import * as theme from '../../../../../theme';

import MultipleChoiceInput from '../../../../Base/inputs/MultipleChoiceInput';

const BORDER = {
  borderWidth: 4
};

const BORDER_LIGHT = {
  borderWidth: 2
};

const LABELS = {
  color: theme.text.primary
};
const stylesheet = [
  {
    selector: 'edge[type = "envToRole"]',
    style: {
      lineColor: theme.terminalColors.blue
    }
  },
  {
    selector: 'edge[type = "groupToService"]',
    style: {
      lineColor: theme.terminalColors.yellow
    }
  },
  {
    selector: 'edge[type = "permissionToAttribute"]',
    style: {
      lineColor: theme.terminalColors.cyan
    }
  },
  {
    selector: 'edge[type = "permissionToDerived"]',
    style: {
      lineColor: theme.terminalColors.orchid
    }
  },
  {
    selector: 'edge[type = "roleToPermission"]',
    style: {
      lineColor: theme.terminalColors.magenta
    }
  },
  {
    selector: 'edge[type = "serviceToEnv"]',
    style: {
      lineColor: theme.terminalColors.red
    }
  },
  {
    selector: 'edge[type = "userToGroup"]',
    style: {
      lineColor: theme.terminalColors.green
    }
  },
  {
    selector: 'edge[type = "userToService"]',
    style: {
      lineColor: theme.terminalColors.yellow
    }
  },
  {
    selector: 'node[type = "attribute"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.cyan,
      label: 'data(id)',
      shape: 'heptagon',
      ...BORDER_LIGHT,
      ...LABELS
    }
  },
  {
    selector: 'node[type = "derivedPermission"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.orchid,
      label: 'data(id)',
      shape: 'round-diamond',
      ...BORDER_LIGHT,
      ...LABELS
    }
  },
  {
    selector: 'node[type = "env"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.red,
      label: 'data(label)',
      shape: 'square',
      ...BORDER,
      ...LABELS
    }
  },
  {
    selector: 'node[type = "group"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.green,
      label: 'data(id)',
      ...BORDER,
      ...LABELS
    }
  },
  {
    selector: 'node[type = "permission"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.magenta,
      label: 'data(id)',
      shape: 'diamond',
      ...BORDER,
      ...LABELS
    }
  },
  {
    selector: 'node[type = "role"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.blue,
      label: 'data(label)',
      shape: 'star',
      ...BORDER,
      ...LABELS
    }
  },
  {
    selector: 'node[type = "service"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.yellow,
      label: 'data(id)',
      shape: 'triangle',
      ...BORDER,
      ...LABELS
    }
  },
  {
    selector: 'node[type = "user"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.green,
      label: 'data(id)',
      ...BORDER,
      ...LABELS
    }
  }
];

export const Cytoscape = styled(CytoscapeComponent).attrs({stylesheet})`
  height: calc(100vh - 250px);
  width: 100%;
`;

export const Selectors = styled.div`
  height: 50px;
`;

export const SelectorRow = styled(MultipleChoiceInput)`
  flex-direction: row;
`;
