import styled from 'styled-components';

import * as theme from '../../../theme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Form = styled.form`
  width: 60%;
`;

export const Link = styled.a`
  color: ${theme.link.text};
  &:hover {
    color: ${theme.link.hover};
  }
`;
