import React from 'react';
import {useHistory} from 'react-router-dom';

import context from '../../../context';
import langs from '../../../localization/langs';

import ReleaseRequestForm from './ReleaseRequestForm';
import {Breadcrumb} from '../../../components/Base';

const breadcrumb = [{title: langs('DASHBOARD'), url: '/releases'}, langs('RELEASE_REQUEST')];

function ReleaseRequest() {
  const history = useHistory();
  const {getUrl} = context.Navigation.useNavigation();

  function goToDashboard() {
    const url = getUrl('releases.main');
    history.push(url);
  }

  return (
    <div>
      <Breadcrumb sections={breadcrumb} withBorder={false} />
      <ReleaseRequestForm onComplete={goToDashboard} />
    </div>
  );
}

export default ReleaseRequest;
