import styled from 'styled-components';

import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import InputAdornment from '@material-ui/core/InputAdornment';
import MuiInput from '@material-ui/core/Input';

import * as theme from '../../theme';

export const Title = styled.div`
  color: ${theme.text.secondary};
  font-size: 12px;
  margin-bottom: 6px;
  margin-top: 20px;
`;

export const Input = styled(MuiInput)`
  background-color: ${theme.buttons.secondary.background};
  padding: 6px 2px;
`;

export const StartAdornment = styled(InputAdornment).attrs({position: 'start'})`
  color: ${theme.text.secondary};
  padding-left: 10px;
`;

export const EndAdornment = styled.div`
  border-left: 1px solid ${theme.buttons.normal.background};
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2;
  opacity: 0.73;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
`;

export const DescriptionInput = styled(MuiInput)`
  background-color: ${theme.buttons.secondary.background};
  margin-top: 10px;
  padding: 0 2px;
`;

export const DuplicateCommand = styled(ControlPointDuplicateIcon)`
  font-size: 18px;
  color: white;
  &:hover {
    color: inherit;
  }
`;
