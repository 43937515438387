import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export const Container = styled(Grid).attrs({item: true, xs: 12})`
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 500px;
`;

export const Parameters = styled.div`
  word-break: break-word;
  margin-left: -2em;
  margin-top: -1em;
`;

export const SectionTitle = styled(Typography)`
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
`;
