import styled from 'styled-components';

import * as theme from '../../theme';

export const Input = styled.input`
  flex: 1;
  border: 0;
  font-size: inherit;
  border-bottom: 1px ${theme.navbar.separator} solid;
  border-color: ${({error}) => (error ? theme.statusColors.error : theme.navbar.separator)};
  padding: 5px 0;

  background: transparent;
  caret-color: ${theme.navbar.caret};
  color: ${theme.navbar.text};

  &::placeholder {
    color: ${theme.navbar.placeHolder};
  }

  &:focus {
    outline: none;
  }
`;
