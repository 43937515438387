import _ from 'lodash';
import React from 'react';

import TablePagination from '@material-ui/core/TablePagination';

import consts from '../../consts';
import context from '../../context';
import langs from '../../localization/langs';
import services from '../../services';
import useTasksView from './useTasksView';

import {Drawer} from '../../components/Base';
import CollectorChip from '../../components/Base/CollectorChip';
import TaskCommands from '../../components/Tasks/TaskCommands/TaskCommands';
import TaskSection from '../../components/Tasks/TaskSection';

import * as searchOptions from './searchOptions';
import * as Styled from './TasksView.styles';
import {SEARCH_TYPES} from '../../context/Search/utils';

function CollectorsTasks() {
  const searchContext = context.Search.useSearch();
  React.useEffect(() => {
    async function setSearchOptions() {
      const {data: users} = await services.tenants.getUsers();
      searchContext.resetSearchOptions([
        ...searchOptions.options,
        {
          key: 'userFullName',
          options: users.items.map(({fullName}) => ({key: fullName, value: fullName})),
          supportedOperators: [consts.FILTER_OPERATORS.LIKE],
          title: langs('TRIGGERED_BY'),
          type: SEARCH_TYPES.TEXT
        }
      ]);
    }
    setSearchOptions();
  }, []);

  const {
    closeDrawer,
    fetchTasks,
    onSetPage,
    onSetRowsPerPage,
    page,
    rowsPerPage,
    selectedTask,
    selectedTaskId,
    setSelectedTaskId,
    tasksCompleted,
    tasksInProgress,
    totalItems
  } = useTasksView(consts.API_PREFIX_BY_SERVICE_NAME.collectors);

  return (
    <div>
      <Drawer open={Boolean(selectedTask)} onClose={closeDrawer} withBackdrop={true}>
        <TaskCommands
          apiPrefix={consts.API_PREFIX_BY_SERVICE_NAME.collectors}
          entityName={langs('COLLECTORS')}
          fetchOutput={({tenantId, collectorId}, taskId) =>
            services.collectors.getCollectorsTaskOutput(tenantId, taskId, collectorId)
          }
          onClose={closeDrawer}
          renderLabel={({collectorNumber}) => <CollectorChip light={true} label={collectorNumber?.toString() ?? ''} />}
          sectionGetter={({tenantName}) => tenantName}
          tableRoute="collectors.withCustomFilter"
          outputTitleGetter={(command) => `${_.get(command, 'tenantName')}:${_.get(command, 'collectorNumber')}`}
          task={selectedTask}
        />
      </Drawer>

      <div>
        {!_.isEmpty(tasksInProgress) && (
          <TaskSection
            isActive={true}
            onChange={fetchTasks}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={setSelectedTaskId}
            tasks={tasksInProgress}
            statusLabel={langs('IN_PROGRESS')}
            entityName={langs('COLLECTORS')}
          />
        )}
        <TaskSection
          isActive={false}
          selectedTaskId={selectedTaskId}
          setSelectedTaskId={setSelectedTaskId}
          tasks={tasksCompleted}
          statusLabel={langs('COMPLETED_RECENTLY')}
          entityName={langs('COLLECTORS')}
        />
      </div>
      <div>
        <TablePagination
          component={Styled.PaginationContainer}
          count={totalItems}
          page={page}
          onPageChange={onSetPage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onSetRowsPerPage}
        />
      </div>
    </div>
  );
}

export default CollectorsTasks;
