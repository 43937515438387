import _ from 'lodash';

import getters from './getters';
import graphs from './graphs';

const TRANSFORM_MODES = {
  GRAPH: 'GRAPH',
  GROUPS: 'GROUPS',
  RAW: 'RAW'
};

export function getEnvNamesFromSchema(schema) {
  return _.uniq(_.map(_.get(schema, 'groups', []), 'envName')).sort();
}

function transformSchema(schema, mode, selectedEnvs, selectedServices, chainRolesToGroups = false, extendRoles = []) {
  if (mode === TRANSFORM_MODES.RAW) {
    return schema;
  }

  const groups = getters.groups(schema);
  const roles = getters.roles(schema);

  if (mode === TRANSFORM_MODES.GRAPH) {
    const groupsGraphData = _.transform(
      groups,
      (result, group) =>
        (result[group.id] = graphs.group(
          group,
          selectedEnvs,
          selectedServices,
          chainRolesToGroups ? roles : null,
          extendRoles
        )),
      {}
    );
    const rolesGraphData = _.transform(roles, (result, role) => (result[role.id] = graphs.roles(role)), {});
    return {
      groups: groupsGraphData,
      roles: rolesGraphData,
      timestamp: Date.now()
    };
  }

  return {
    groups,
    roles
  };
}

export function transformSchemaAsGraph(schema, selectedEnvs, selectedServices, chainRolesToGroups, extendRoles = []) {
  return transformSchema(
    schema,
    TRANSFORM_MODES.GRAPH,
    selectedEnvs,
    selectedServices,
    chainRolesToGroups,
    extendRoles
  );
}

export function transformSchemaAsGroups(schema) {
  return transformSchema(schema, TRANSFORM_MODES.GROUPS);
}

export function transformSchemaAsRaw(schema) {
  return transformSchema(schema, TRANSFORM_MODES.RAW);
}

export const composeUserPermissionsGraph = graphs.userPermissions;
