import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../../context';
import formUtils from './newTenantFormUtils';
import langs from '../../../localization/langs';
import services from '../../../services';
import useIndustry from './useIndustry';
import * as validations from './validations';

import {Button, Form} from '../../../components/Base';
import CloneTenant from './parts/CloneTenant';
import CopyTenantConfig from './parts/CopyTenantConfig';
import MonitorTenant from './parts/MonitorTenant';
import MultiTenantCreate from './parts/MultiTenantCreate';
import TenantBranch from './parts/TenantBranch';
import TenantEnv from './parts/TenantEnv';
import TenantName from './parts/TenantName';
import TenantStage from './parts/TenantStage';

import * as Styled from './styles';

const BRANCH_OPTIONS = {
  ENABLE_CONTINUOUS_DELIVERY_ST: langs('ENABLE_CONTINUOUS_DELIVERY_ST')
};

export function NewTenantForm({onComplete, defaultClusterName}) {
  const [cluster, setCluster] = React.useState(null);
  const [cloneData, setCloneData] = React.useState(null);
  const [copyConfigData, setCopyConfigData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [industry, setIndustry, isIndustryFilled, industryRender] = useIndustry(cluster);
  const [isLoading, setIsLoading] = React.useState(false);
  const [name, setName] = React.useState('');
  const [branch, setBranch] = React.useState(null);
  const [monitorData, setMonitorData] = React.useState(null);
  const [selectedPrefix, setSelectedPrefix] = React.useState('');
  const [stage, setStage] = React.useState('');
  const [namespace, setNamespace] = React.useState(null);
  const [selectedStacks, setSelectedStacks] = React.useState([]);
  const [branchSelection, setBranchSelection] = React.useState([]);
  const [tenantConfiguration, setTenantConfiguration] = React.useState([]);
  const isSubmitDisabled = formUtils.isSubmitDisabled(
    isLoading,
    cluster,
    name,
    isIndustryFilled,
    selectedStacks,
    namespace,
    stage
  );

  const user = context.User.useUser();

  async function handleSubmit(event) {
    event.preventDefault();

    if (_.isNull(cluster)) {
      setError(langs('ERROR_MISSING_ENV'));
      return;
    }

    const nameValidation = validations.validateTenantName(name, cluster.name);
    if (!nameValidation.isValid) {
      setError(nameValidation.message);
      return;
    }

    setError(null);
    setIsLoading(true);

    const branchName = _.get(branch, 'branch');
    const enableCoralogix = _.get(monitorData, 'enableCoralogix', false);
    const isMonitored = _.get(monitorData, 'isMonitored', false);
    const clone = _.isEmpty(cloneData) && _.isEmpty(copyConfigData) ? null : _.assign({}, cloneData, copyConfigData);
    const configuration = _.concat(tenantConfiguration, [
      {key_name: 'enable_fluentd', value: JSON.stringify(enableCoralogix)}
    ]);
    const env = cluster.name;
    const multiTenant = formUtils.getMultitenantData(
      cluster,
      namespace,
      selectedStacks,
      branchSelection,
      BRANCH_OPTIONS
    );
    const owner = _.get(user, 'email');
    const selectedStage = formUtils.isClusterWithStage(cluster) ? stage : null;
    const singleTenant = {
      continuous: formUtils.isContinuousDelivery(branchSelection, BRANCH_OPTIONS.ENABLE_CONTINUOUS_DELIVERY_ST)
    };
    const tenantName = `${selectedPrefix}${name}`;

    try {
      const result = await services.tenants.create({
        branch: branchName,
        clone,
        configuration,
        env,
        industry,
        isMonitored,
        multiTenant,
        owner,
        singleTenant,
        stage: selectedStage,
        tenantName
      });
      await onComplete(result.data.tenantId);
    } catch (error) {
      const errorMessage = _.get(error, ['response', 'statusText'], error.message);
      setError(langs('ERROR_COULD_NOT_CREATE', {errorMessage, status: error.request.status}));
    }
    setIsLoading(false);
  }

  React.useEffect(() => {
    setBranch(null);
    setBranchSelection([]);
    setIndustry('');
    setMonitorData(null);
    setNamespace(null);
    setStage('');
  }, [cluster]);

  const tenantNamePrefixes = formUtils.getTenantNamePrefixes(cluster);
  return (
    <Styled.Container>
      <Styled.Form onSubmit={handleSubmit}>
        <TenantEnv
          cluster={cluster}
          defaultClusterName={defaultClusterName}
          setCluster={setCluster}
          setSelectedPrefix={setSelectedPrefix}
        />
        <TenantName
          tenantNamePrefixes={tenantNamePrefixes}
          selectedPrefix={selectedPrefix}
          setSelectedPrefix={setSelectedPrefix}
          setName={setName}
          name={name}
        />
        {formUtils.isClusterWithStage(cluster) && <TenantStage stage={stage} setStage={setStage} />}
        {industryRender}
        {formUtils.isDevCluster(cluster) && (
          <MultiTenantCreate
            namespace={namespace}
            setNamespace={setNamespace}
            selectedStacks={selectedStacks}
            setSelectedStacks={setSelectedStacks}
          />
        )}
        {formUtils.isDevCluster(cluster) && (
          <>
            <CloneTenant setCloneData={setCloneData} setTenantConfiguration={setTenantConfiguration} />
            <CopyTenantConfig setCopyConfigData={setCopyConfigData} />
          </>
        )}
        {formUtils.isDevCluster(cluster) && <MonitorTenant monitorData={monitorData} setMonitorData={setMonitorData} />}
        {(formUtils.isDevCluster(cluster) || formUtils.isQACluster(cluster)) && (
          <TenantBranch
            branch={branch}
            setBranch={setBranch}
            branchSelection={branchSelection}
            setBranchSelection={setBranchSelection}
            options={
              !_.isEmpty(selectedStacks) ? _.toArray(BRANCH_OPTIONS) : [BRANCH_OPTIONS.ENABLE_CONTINUOUS_DELIVERY_ST]
            }
          />
        )}
        {error && <Form.Error>{error}</Form.Error>}

        <Form.BottomBar>
          <Button disabled={isSubmitDisabled} loading={isLoading} type="submit">
            {langs('BUTTON_ADD')}
          </Button>
        </Form.BottomBar>
      </Styled.Form>
    </Styled.Container>
  );
}

NewTenantForm.propTypes = {
  defaultClusterName: PropTypes.string,
  onComplete: PropTypes.func
};

export default NewTenantForm;
