import _ from 'lodash';
import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MergeTypeIcon from '@material-ui/icons/MergeType';

import columnDefs from '../../../utils/agGridUtils/columnDefs';
import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import models from '../../../models';
import roles from '../../../roles';
import utils from './table.utils';

import AGTable, {DEFAULT_CONTEXT_MENU} from '../../AGTable/Table';
import {Button} from '../../Base';
import ColoredColumn from '../../AGTable/columns/ColoredColumn';
import Column from '../../AGTable/Column';
import {DeployNeeded, JiraTickets, PullRequests} from './Parts/Columns';
import ExternalLinkColumn from '../../AGTable/columns/ExternalLinkColumn';
import ProtectedComponent from '../../Rbac/ProtectedComponent';
import SelectMenu from '../../Base/SelectMenu';
import useUrlSearch from '../../AGTable/hooks/useUrlSearch';
import useSubmitReviewMenuItem from './Parts/OptionsMenu/useSubmitReviewMenuItem';

import * as Styled from '../../../views/Tenants/CompactMode/CompactMode.styles';

async function getRows(params) {
  params.success(await utils.getReleases(params.api, params.request));
}

function Table() {
  const [tableApi, setTableApi] = React.useState();
  const [filterModel, onFilterChanged] = useUrlSearch(tableApi);

  const [approveMenuItemGetDef, approveMenuItemDialog] = useSubmitReviewMenuItem(true);
  const [declineMenuItemGetDef, declineMenuItemDialog] = useSubmitReviewMenuItem(false);

  const {getUrl} = context.Navigation.useNavigation();
  const user = context.User.useUser();

  const asyncFilterProps = {...columnDefs.BASIC_COL, filter: utils.fetchFilterOptions};

  function getContextMenuItems(params, pr) {
    return _.compact(
      _.concat(
        !_.includes(utils.FINAL_STAGE_STATUSES, pr.status) && [
          approveMenuItemGetDef(pr),
          declineMenuItemGetDef(pr),
          'separator'
        ],
        DEFAULT_CONTEXT_MENU
      )
    );
  }

  return (
    <>
      <Styled.ActionContainer>
        <ProtectedComponent
          permissions={[consts.rbac.permissions.opsWeb.view.cherryPicks]}
          rbacControlObj={user.rbac.opsWeb}
        >
          <SelectMenu
            render={(onClick) => (
              <Button text icon={<KeyboardArrowDownIcon />} onClick={onClick}>
                {langs('NEW_RELEASE')}
              </Button>
            )}
            options={[
              {
                iconComponent: MergeTypeIcon,
                isHighlighted: true,
                label: langs('NEW_RELEASE_MINOR'),
                permissions: user.rbac.opsWeb.isEnabled()
                  ? user.rbac.opsWeb.allowed.view.cherryPicks
                  : roles.SHOW_CHERRY_PICKS,
                url: getUrl('releases.add', null, {requestType: models.releases.requestTypes.MINOR})
              },
              {
                iconComponent: AddIcon,
                isHighlighted: false,
                label: langs('NEW_RELEASE_CANDIDATE'),
                permissions: user.rbac.opsWeb.isEnabled()
                  ? user.rbac.opsWeb.allowed.view.cherryPicks
                  : roles.SHOW_CHERRY_PICKS,
                url: getUrl('releases.add', null, {
                  requestType: models.releases.requestTypes.RELEASE_CANDIDATE
                })
              },
              {
                iconComponent: CallSplitIcon,
                isHighlighted: false,
                label: langs('NEW_RELEASE_MAJOR'),
                permissions: user.rbac.opsWeb.isEnabled()
                  ? user.rbac.opsWeb.allowed.view.cherryPicks
                  : roles.SHOW_CHERRY_PICKS,
                url: getUrl('releases.add', null, {requestType: models.releases.requestTypes.MAJOR})
              }
            ]}
          />
        </ProtectedComponent>
      </Styled.ActionContainer>

      <AGTable
        data={getRows}
        filterModel={filterModel}
        getContextMenuItems={getContextMenuItems}
        onFilterChanged={onFilterChanged}
        pagination
        rowIdField={_.camelCase(models.staticData.RELEASE_FIELDS.RELEASE_ID)}
        setTableApi={setTableApi}
        title={langs('RELEASES')}
      >
        <Column
          {...columnDefs.NUMBER_COL}
          colId={_.camelCase(models.staticData.RELEASE_FIELDS.PR_ID)}
          render={ExternalLinkColumn}
          renderProps={{urlPrefix: consts.URL_PREFIX_GITHUB_PULL_REQUEST}}
          title={langs('PR_ID')}
          value={_.camelCase(models.staticData.RELEASE_FIELDS.PR_ID)}
        />
        <Column
          {...asyncFilterProps}
          colId={_.camelCase(models.staticData.RELEASE_FIELDS.RELEASE_NAME)}
          title={langs('RELEASE_NAME')}
          value={_.camelCase(models.staticData.RELEASE_FIELDS.RELEASE_NAME)}
        />
        <Column
          {...columnDefs.NUMBER_COL}
          colId={_.camelCase(models.staticData.RELEASE_FIELDS.BUILD_ID)}
          title={langs('BUILD_ID')}
          value={_.camelCase(models.staticData.RELEASE_FIELDS.BUILD_ID)}
        />
        <Column
          {...asyncFilterProps}
          colId={_.camelCase(models.staticData.RELEASE_FIELDS.URGENCY)}
          render={ColoredColumn}
          renderProps={{colors: utils.URGENCY_COLORS, render: (value) => value?.replace('URGENCY_', '')}}
          filterParams={{valueFormatter: ({value}) => value?.replace('URGENCY_', '')}}
          title={langs('URGENCY')}
          tooltip={'urgency'}
          value={_.camelCase(models.staticData.RELEASE_FIELDS.URGENCY)}
        />
        <Column
          {...columnDefs.NUMBER_COL}
          colId={_.camelCase(models.staticData.RELEASE_FIELDS.SOURCE_PR_IDS)}
          filter={false}
          render={PullRequests}
          title={langs('SOURCE_PR_IDS')}
          value={_.camelCase(models.staticData.RELEASE_FIELDS.SOURCE_PR_IDS)}
        />
        <Column
          {...columnDefs.BASIC_COL}
          colId={_.camelCase(models.staticData.RELEASE_FIELDS.JIRA_TICKET)}
          render={JiraTickets}
          title={langs('JIRA_TICKET')}
          value={_.camelCase(models.staticData.RELEASE_FIELDS.JIRA_TICKET)}
        />
        <Column title={langs('REASON')} value={_.camelCase(models.staticData.RELEASE_FIELDS.REASON)} />
        <Column
          {...columnDefs.DATE_COL}
          colId={_.camelCase(models.staticData.RELEASE_FIELDS.RELEASE_CREATION_DATE)}
          filter={false}
          title={langs('CREATION_DATE')}
          value={_.camelCase(models.staticData.RELEASE_FIELDS.RELEASE_CREATION_DATE)}
        />
        <Column
          {...columnDefs.BASIC_COL}
          colId={_.camelCase(models.staticData.RELEASE_FIELDS.DEPLOY_NEEDED)}
          render={DeployNeeded}
          title={langs('DEPLOY_NEEDED')}
          filter={_.map(consts.BOOLEAN_MAPPER, _.startCase)}
          tooltip={''}
          value={_.identity}
        />
        <Column
          {...columnDefs.BASIC_COL}
          colId={_.camelCase(models.staticData.RELEASE_FIELDS.CREATED_BY)}
          filter={false}
          title={langs('REQUESTED_BY')}
          value={_.camelCase(models.staticData.RELEASE_FIELDS.CREATED_BY)}
        />
        <Column
          colId={_.camelCase(models.staticData.RELEASE_FIELDS.APPROVERS)}
          filter={false}
          title={langs('APPROVERS')}
          value={_.camelCase(models.staticData.RELEASE_FIELDS.APPROVERS)}
        />
        <Column
          {...asyncFilterProps}
          colId={_.camelCase(models.staticData.RELEASE_FIELDS.STATUS)}
          exportFunc={({status}) => status}
          filterParams={{valueFormatter: ({value}) => _.trimStart(value, 'STATUS_')}}
          render={ColoredColumn}
          renderProps={{colors: utils.STATUS_COLORS, render: (value) => _.trimStart(value, 'STATUS_')}}
          suppressSizeToFit
          title={langs('STATUS')}
          tooltip={'status'}
          value={_.camelCase(models.staticData.RELEASE_FIELDS.STATUS)}
        />
      </AGTable>
      {approveMenuItemDialog}
      {declineMenuItemDialog}
    </>
  );
}

export default Table;
