import PropTypes from 'prop-types';
import React from 'react';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';

import langs from '../../localization/langs';

import * as Styled from './LinkInput.styles';

function LinkInput({value}) {
  const [title, setTitle] = React.useState(langs('COPY_TO_CLIPBOARD'));
  const inputRef = React.useRef(null);

  function copyToClipboard(e) {
    inputRef.current.select();
    document.execCommand('copy');
    setTitle(langs('COPIED'));
    e.target.focus();
  }

  function download() {
    window.location = value;
  }

  return (
    <>
      <Styled.Input value={value} inputProps={{ref: inputRef}} />
      <Tooltip title={title} leaveDelay={400}>
        <Styled.IconButton onClick={copyToClipboard} onMouseLeave={() => setTitle(langs('COPY_TO_CLIPBOARD'))}>
          <FileCopyIcon />
        </Styled.IconButton>
      </Tooltip>
      <Styled.Divider />
      <Styled.IconButton onClick={download}>
        <CloudDownloadIcon />
      </Styled.IconButton>
    </>
  );
}

LinkInput.propTypes = {
  value: PropTypes.string
};

export default LinkInput;
