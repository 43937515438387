import styled from 'styled-components';

import * as theme from '../../theme';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  margin-bottom: 0.5rem;
  padding: 5px 0;

  color: ${theme.accordion.text};

  &:hover {
    background: ${theme.accordion.background};
  }
`;

export const Title = styled.div`
  margin-left: 0.5rem;
`;
