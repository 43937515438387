import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import command from '../../../models/command';
import langs from '../../../localization/langs';
import models from '../../../models';
import services from '../../../services';

import CommandOutput from '../../CommandOutput/CommandOutput';
import {DateField, ProgressBar} from '../../Base';

import * as Styled from './CollectorPreview.styles';

function CollectorPreview({collectorId, collectorNumber, tenantId, tenantName}) {
  const [collectorCommands, setCollectorCommands] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedCommandStatus, setSelectedCommandStatus] = React.useState(null);

  React.useEffect(() => {
    setIsLoading(true);
    if (!collectorId) {
      setIsLoading(false);
      setCollectorCommands([]);
      return;
    }
    services.collectors
      .getCommandStatusByCollectorId(collectorId)
      .then(({data: {items}}) => setCollectorCommands(items))
      .then(() => setIsLoading(false));
  }, [collectorId]);

  return (
    <div>
      <Styled.Dialog onClose={() => setSelectedCommandStatus(null)} open={Boolean(selectedCommandStatus)}>
        <CommandOutput
          title={`${langs('COMMAND_OUTPUT')} - ${tenantName}:${collectorNumber}`}
          autoRefresh={false}
          fetchOutput={() =>
            services.collectors.getCollectorsTaskOutput(tenantId, selectedCommandStatus.taskId, collectorId)
          }
        />
      </Styled.Dialog>
      <Styled.TenantName>{tenantName}</Styled.TenantName>
      <Styled.Title>{collectorNumber}</Styled.Title>
      <Styled.Title>{langs('LAST_ACTIVITY')}</Styled.Title>
      <ProgressBar isLoading={isLoading} />

      {_.map(collectorCommands, (collectorCommand) => {
        const [Icon, text, hasLog] = models.command.getCommandIconAndTextByStatus(collectorCommand.status);
        const commandType = command.getTextByType(collectorCommand.commandType);

        return (
          <Styled.Row key={collectorCommand.id}>
            <Styled.StatusColumn>
              <Icon />
              <Styled.Link to={`/collectors/tasks?taskId[eq]=${collectorCommand.taskId}`}>{commandType}</Styled.Link>
            </Styled.StatusColumn>
            <Styled.ViewLogsColumn>
              {hasLog && (
                <Styled.Button
                  onClick={() => setSelectedCommandStatus(collectorCommand)}
                  startIcon={<DescriptionOutlinedIcon />}
                >
                  {langs('VIEW_LOG')}
                </Styled.Button>
              )}
            </Styled.ViewLogsColumn>
            <Styled.DateColumn>
              <DateField date={collectorCommand.creationDate} prefix={`${text} `} />
            </Styled.DateColumn>
          </Styled.Row>
        );
      })}
    </div>
  );
}

CollectorPreview.propTypes = {
  collectorId: PropTypes.string,
  collectorNumber: PropTypes.string,
  tenantId: PropTypes.string,
  tenantName: PropTypes.string
};

export default CollectorPreview;
