import _ from 'lodash';

import consts from '../../consts';
import langs from '../../localization/langs';

export const SEARCH_TYPES = {
  BOOLEAN: 'BOOLEAN',
  CHIPS: 'CHIPS',
  TEXT: 'TEXT'
};

const MAX_VISIBLE_SEARCH_OPTIONS = 4;

const OPERATOR_DESCRIPTION_IDS = {
  [consts.FILTER_OPERATORS.EQUAL]: 'KEY_IS_VALUE',
  [consts.FILTER_OPERATORS.NOT_EQUAL]: 'KEY_IS_NOT_VALUE',
  [consts.FILTER_OPERATORS.LIKE]: 'KEY_IS_VALUE',
  [consts.FILTER_OPERATORS.NOT_LIKE]: 'KEY_IS_NOT_VALUE',
  [consts.FILTER_OPERATORS.REGEX]: 'KEY_MATCHES_REGEX'
};

export function describe(options, query) {
  const enabledFilters = getEnabledFilters(options, query);

  if (enabledFilters.length > MAX_VISIBLE_SEARCH_OPTIONS) {
    return langs('COUNT_FILTER_APPLIED', {count: enabledFilters.length});
  }

  return _.map(enabledFilters, (searchField) => {
    if (searchField.type === SEARCH_TYPES.BOOLEAN) {
      return `${searchField.title}`;
    }

    const labelId = OPERATOR_DESCRIPTION_IDS[searchField.filterOperator];
    return langs(labelId, {
      key: searchField.title,
      value: getSearchFieldSelectedValues(searchField, query)
    });
  }).join(', ');
}

const getSearchFieldSelectedValues = (searchField, query) => {
  const queryValues = query
    .get(`${searchField.key}${searchField.filterOperator}`)
    .split(',')
    .map((queryItem) => queryItem.trim());

  return searchField.options.reduce((selectedValues, fieldOption) => {
    queryValues.forEach((queryValue) => {
      if (queryValue === fieldOption.key) {
        selectedValues += selectedValues ? ` ${langs('OR')} ${fieldOption.value}` : fieldOption.value;
      }
    });

    return selectedValues;
  }, '');
};

export function getEnabledFilters(options, query) {
  return _(options)
    .transform((res, option) => {
      const {supportedOperators} = option;
      if (_.isEmpty(supportedOperators)) {
        return res.push(option);
      }
      res.push(
        ..._.map(supportedOperators, (supportedOperator) => {
          return _.defaults({filterOperator: supportedOperator}, option);
        })
      );
    }, [])
    .filter(({key, filterOperator}) => {
      const value = query.get(`${key}${filterOperator}`);
      return !_.isEmpty(value) || value === true;
    })
    .value();
}
