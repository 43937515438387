import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';

import langs from '../../../localization/langs';

import OpsLink from '../../OpsLink';
import OptionsMenu from '../OptionsMenu';
import {StatusLine} from '../../Base';
import TenantLinks from './TenantLinks';

import useStyles from './TenantHeader.styles';
import * as Styled from './TenantHeader.styles';

// Translates backend values to StatusLine supported values
const HEALTH_STATUS_ICON = {
  down: {icon: <CancelIcon />, status: 'error'},
  up: {icon: <CheckCircleIcon />, status: 'success'}
};

function TenantHeader({tenant, isFullViewLink = true, onUpdate = _.stubObject}) {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item className={classes.header} xs={6} container alignItems="center">
        <StatusLine
          status={_.get(HEALTH_STATUS_ICON, `${tenant.healthStatus}.status`)}
          icon={_.get(HEALTH_STATUS_ICON, `${tenant.healthStatus}.icon`)}
        >
          {tenant.tenantName}
        </StatusLine>
        {tenant.subscribed && (
          <Styled.Subscribed>
            <NotificationsOutlinedIcon />
          </Styled.Subscribed>
        )}
      </Grid>

      <Grid
        item
        xs={6}
        container
        alignItems="center"
        justifyContent="flex-end"
        onClick={(event) => event.stopPropagation()}
      >
        {isFullViewLink && (
          <OpsLink to="tenants.tenant" params={{tenantId: tenant.tenantId}}>
            {langs('FULL_VIEW')}
          </OpsLink>
        )}
        <TenantLinks tenantName={tenant.tenantName} />
        <OptionsMenu
          branch={_.get(tenant, 'deployData.versionDeployed')}
          isMonitored={tenant.isMonitored}
          onChange={onUpdate}
          tenantId={tenant.tenantId}
          tenantName={tenant.tenantName}
        />
      </Grid>
    </Grid>
  );
}

TenantHeader.propTypes = {
  isFullViewLink: PropTypes.bool,
  onUpdate: PropTypes.func,
  tenant: PropTypes.object.isRequired
};

export default TenantHeader;
