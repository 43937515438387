import _ from 'lodash';

import client from '../client';
import consts from '../../consts';
import models from '../../models';

const CRON_EXPRESSION = 'jobSchedule';

export async function count(searchParams) {
  return client.get(`/maestro/api/v1/services/_count?${searchParams}`);
}

export async function getById(serviceId) {
  return client.get(`/maestro/api/v1/services/${serviceId}`);
}

export async function list({length = 20, from, orderBy, searchQuery = ''}) {
  const searchParams = new URLSearchParams(searchQuery);
  searchParams.set('length', _.toString(length));
  if (from) {
    searchParams.set('from', from);
  }
  if (orderBy) {
    searchParams.set('orderBy', orderBy);
  }
  return client.get(`/maestro/api/v1/services?${searchParams}`);
}

export async function create(name, owner, description, versionRange, dbParams, useRedis, stackIds, chart, cron) {
  const configuration = _.isEmpty(cron) ? {} : {configuration: [{key_name: CRON_EXPRESSION, value: cron}]};
  const data = _.assign(configuration, {
    chart,
    dbParams,
    description,
    lifecycle: {supportedVersionRange: versionRange},
    name,
    owner,
    stackIds,
    useRedis
  });
  return client.post('/maestro/api/v1/services', data);
}

export async function update(serviceId, name, owner, description, versionRange, stackIds, chart, cron) {
  const configuration = _.isEmpty(cron) ? {} : {configuration: [{key_name: CRON_EXPRESSION, value: cron}]};
  const data = _.assign(configuration, {
    chart,
    description,
    name,
    owner,
    stackIds,
    supportedVersionRange: versionRange
  });
  return client.patch(`/maestro/api/v1/services/${serviceId}`, data);
}

export async function getHelmValues(serviceId, envId, namespaceId) {
  const route = `/mtms/api/v1/services/${serviceId}/environments/${envId}/namespaces/${namespaceId}/helm-values`;
  return client.get(route);
}

export async function getHelmCommand(command) {
  const {data} = await client.get(
    `/mtms/api/v1/commands?commandType${consts.FILTER_OPERATORS.EQUAL}=${models.command.commandType.HELM}&command${consts.FILTER_OPERATORS.EQUAL}=${command}`
  );
  return _.get(data, 'items[0]');
}

export async function validateVersionRange(versionRange) {
  return client.get('/mtms/api/v1/version/_validate', {versionRange});
}

export async function getStacks(serviceId) {
  return client.get(`/maestro/api/v1/services/${serviceId}/stacks`);
}
