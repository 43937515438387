import _ from 'lodash';
import React from 'react';
import useInterval from '@rooks/use-interval';

import consts from '../../consts';
import hooks from '../../hooks';
import services from '../../services';
import task from '../../models/task';

const TOTAL_ITEMS = 1000;
const DEFAULT_PAGE_SIZE = 10;

function useTasksView(apiPrefix) {
  const [tasks, setTasks] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const [queryTaskId] = hooks.useSearchQueryFilter('taskId', consts.FILTER_OPERATORS.EQUAL, null);
  const [selectedTaskId, setSelectedTaskId] = React.useState(parseInt(queryTaskId) || null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [query] = hooks.useUrlSearchParams();

  const selectedTask = _.find(tasks, {id: selectedTaskId});
  const tasksInProgress = _.filter(tasks, (item) => !task.completedTaskStatuses.includes(item.status));
  const tasksCompleted = _.filter(tasks, (item) => task.completedTaskStatuses.includes(item.status));

  function closeDrawer() {
    setSelectedTaskId(null);
  }

  const fetchTasks = React.useCallback(
    async function fetchTasks() {
      const {data} = await services.tasks.listTasks(page * rowsPerPage, rowsPerPage, apiPrefix, query.toString());

      setTasks(data.items);
    },
    [apiPrefix, page, query, rowsPerPage]
  );

  const {start, stop} = useInterval(fetchTasks, 5_000);

  React.useEffect(() => {
    fetchTasks().then(start);
    return stop;
  }, [fetchTasks]);

  function onSetPage(e, page) {
    setPage(page ?? 0);
    setTasks([]);
  }

  function onSetRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, DEFAULT_PAGE_SIZE));
    onSetPage(0);
  }

  return {
    closeDrawer,
    fetchTasks,
    onSetPage,
    onSetRowsPerPage,
    page,
    rowsPerPage,
    selectedTask,
    selectedTaskId,
    setSelectedTaskId,
    tasksCompleted,
    tasksInProgress,
    totalItems: TOTAL_ITEMS
  };
}

export default useTasksView;
