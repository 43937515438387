import _ from 'lodash';
import React from 'react';

import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import roles from '../../../roles';
import services from '../../../services';
import task from '../../../models/task';
import useNewTask from '../../../components/NewTaskWidget/useNewTask';

import SelectCommand from '../../../components/NewTaskWidget/SelectCommand';

const BASE_TASK = {
  apiPrefix: consts.API_PREFIX_BY_SERVICE_NAME[consts.SERVICE_NAMES.COLLECTORS],
  tasksPage: 'collectors.tasks'
};
const TASKS = {
  APP_DEPLOYMENT: {
    ...BASE_TASK,
    icon: 'outline_router_white_24dp',
    name: langs('APP_DEPLOYMENT'),
    permissions: consts.rbac.permissions.cms.collectors.task.runElevated.high,
    role: roles.MANAGE_COLLECTORS,
    type: task.taskType.APP_DEPLOYMENT
  },
  FIELD_ACTIONS: {
    ...BASE_TASK,
    icon: 'outline_build_white_24dp',
    name: langs('FIELD_ACTIONS'),
    permissions: consts.rbac.permissions.cms.collectors.task.runFieldAction,
    selectionComponent: (props) => (
      <SelectCommand
        archiveShellCommandApi={services.collectors.archiveShellCommand}
        createShellCommandApi={services.collectors.createShellCommand}
        getShellCommands={services.collectors.getFieldCommands}
        {...props}
      />
    ),
    type: task.taskType.SHELL_COMMAND
  },
  FIRMWARE_UPGRADE: {
    ...BASE_TASK,
    icon: 'Ansible-Mark-Large-RGB-WhiteOutline',
    name: langs('FIRMWARE_UPGRADE'),
    permissions: consts.rbac.permissions.cms.collectors.task.runElevated.high,
    role: roles.MANAGE_COLLECTORS,
    type: task.taskType.FIRMWARE_UPGRADE
  },
  SHELL_COMMAND: {
    ...BASE_TASK,
    icon: 'outline_code_white_24dp',
    name: langs('SHELL_COMMAND'),
    permissions: consts.rbac.permissions.cms.collectors.task.runShellCommand,
    role: roles.MANAGE_COLLECTORS,
    selectionComponent: (props) => (
      <SelectCommand
        createShellCommandApi={services.collectors.createShellCommand}
        archiveShellCommandApi={services.collectors.archiveShellCommand}
        getShellCommands={services.collectors.getShellCommands}
        {...props}
      />
    ),
    type: task.taskType.SHELL_COMMAND
  }
};

function useNewCollectorTask(task, collectors) {
  return useNewTask(task, collectors, 'collectorId');
}

export default function useNewTaskActions(collectors) {
  const user = context.User.useUser();
  const nameSuffix = _.isEmpty(collectors) ? '' : ` (${_.size(collectors).toLocaleString()})`;

  const [appDeploymentGetDef, appDeploymentDialog] = useNewCollectorTask(TASKS.APP_DEPLOYMENT, collectors);
  const [firmwareUpgradeGetDef, firmwareUpgradeDialog] = useNewCollectorTask(TASKS.FIRMWARE_UPGRADE, collectors);
  const [shellCommandGetDef, shellCommandDialog] = useNewCollectorTask(TASKS.SHELL_COMMAND, collectors);
  const [fieldActionsGetDef, fieldActionsDialog] = useNewCollectorTask(TASKS.FIELD_ACTIONS, collectors);

  const dialogs = (
    <>
      {appDeploymentDialog}
      {fieldActionsDialog}
      {firmwareUpgradeDialog}
      {shellCommandDialog}
    </>
  );

  function getDef(collector) {
    return {
      icon: 'outline_play_arrow_white_24dp',
      name: `${langs('NEW_TASK')}${nameSuffix}`,
      subMenu: [
        shellCommandGetDef(collector, user.rbac.cms),
        fieldActionsGetDef(collector, user.rbac.cms),
        firmwareUpgradeGetDef(collector, user.rbac.cms),
        appDeploymentGetDef(collector, user.rbac.cms)
      ]
    };
  }

  return [getDef, dialogs];
}
