import styled from 'styled-components';

export const Row = styled.div`
  font-size: 12px;
  line-height: 1;
`;

export const Key = styled.div`
  display: inline-block;
  font-weight: bold;
  min-width: 100px;
`;
