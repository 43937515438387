import _ from 'lodash';

import filterConsts from './filterConsts';

function populateNumberModel(operator, value, item, previousItem) {
  const valueNumber = _.parseInt(value);
  if (previousItem) {
    const previousNumber = previousItem.filter;
    item.filter = Math.min(valueNumber, previousNumber);
    item.filterTo = Math.max(valueNumber, previousNumber);
    item.type = filterConsts.NUMERIC_FILTER_TYPES.RANGE;
  } else {
    item.filter = valueNumber;
    item.type = filterConsts.OPERATOR_TO_FILTER_TYPE[operator];
  }
}

function populateDateModel(operator, value, item, previousItem) {
  if (previousItem) {
    const {type: previousType, dateFrom: previousDate} = previousItem;
    if (previousType === filterConsts.NUMERIC_FILTER_TYPES.GREATER_OR_EQUAL) {
      item.dateFrom = previousDate;
      item.dateTo = value;
    } else {
      item.dateFrom = value;
      item.dateTo = previousDate;
    }
    item.type = filterConsts.NUMERIC_FILTER_TYPES.RANGE;
  } else {
    item.dateFrom = value;
    item.type = filterConsts.OPERATOR_TO_FILTER_TYPE[operator];
  }
}

function populateSetModel(operator, value, item) {
  let valueToUse = '';
  try {
    //set values are structured as JSON(...), the reason for slice 5,-1
    valueToUse = JSON.parse(value.slice(5, -1));
  } catch {
    valueToUse = value.split(',');
  }
  item.values = _.map(valueToUse, (val) => (val === 'null' ? null : val));
}

function populateTextModel(operator, value, item) {
  item.filter = _.trim(value, '%');
  item.type = filterConsts.OPERATOR_TO_FILTER_TYPE[operator];
}

const FILTER_TYPE_TO_HANDLER = {
  date: populateDateModel,
  number: populateNumberModel,
  set: populateSetModel,
  text: populateTextModel
};

async function getFilterType(gridApi, field) {
  const column = gridApi.columnModel.getPrimaryColumn(field);
  if (!column) {
    return null;
  }
  const filter = gridApi.filterManager.getOrCreateFilterWrapper(column, 'NO_UI');
  const instance = await filter.filterPromise;
  return instance.getFilterType();
}

async function getModel(gridApi, searchQuery) {
  const result = {};
  for (const [key, value] of searchQuery) {
    let [, field, operator] = key.match(/([.\w]+)(\[\w+])/) || [];
    const filterType = await getFilterType(gridApi, field);
    if (!filterType) {
      continue;
    }
    const item = {filterType};
    const modelHandler = FILTER_TYPE_TO_HANDLER[filterType];
    if (modelHandler) {
      modelHandler(operator, value, item, result[field]);
    } else {
      console.warn(`Ignoring unsupported filter type "${filterType}" for field "${field}"`);
    }
    result[field] = item;
  }
  return result;
}

export default {
  getModel
};
