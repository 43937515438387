import CytoscapeComponent from 'react-cytoscapejs';
import styled from 'styled-components';

import * as theme from '../../../theme';

const LABELS = {
  color: theme.text.primary,
  textMaxWidth: '100px',
  textOverflowWrap: 'anywhere',
  textWrap: 'wrap'
};

const BORDER = {
  borderWidth: 4
};

const QUEUE_SHAPE = {
  shape: 'barrel'
};

const stylesheet = [
  {
    selector: '[type = "pipeline"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.green,
      label: 'data(id)',
      ...BORDER,
      ...LABELS
    }
  },
  {
    selector: '[type = "integration"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.yellow,
      label: 'data(id)',
      shape: 'star',
      ...BORDER,
      ...LABELS
    }
  },
  {
    selector: '[type = "st_topic"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.green,
      label: 'data(label)',
      width: 90,
      ...BORDER,
      ...LABELS,
      ...QUEUE_SHAPE
    }
  },
  {
    selector: '[type = "mt_topic"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.blue,
      label: 'data(id)',
      width: 90,
      ...BORDER,
      ...LABELS,
      ...QUEUE_SHAPE
    }
  },
  {
    selector: '[type = "rmq_channel"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.yellow,
      label: 'data(id)',
      width: 90,
      ...BORDER,
      ...LABELS,
      ...QUEUE_SHAPE
    }
  },
  {
    selector: '[type = "service"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: theme.terminalColors.blue,
      label: 'data(id)',
      ...BORDER,
      ...LABELS
    }
  },
  {
    selector: '[type = "secor"]',
    style: {
      backgroundColor: theme.text.primary,
      borderColor: 'purple',
      label: 'data(id)',
      ...BORDER,
      ...LABELS
    }
  },
  {
    selector: '[isLagging = "true"]',
    style: {
      backgroundColor: theme.terminalColors.red
    }
  },
  {
    selector: 'edge',
    style: {
      'curve-style': 'bezier',
      'line-color': theme.chipBackgrounds.primary,
      'target-arrow-color': theme.chipBackgrounds.primary,
      'target-arrow-shape': 'triangle',
      width: 1
    }
  }
];

export const Cytoscape = styled(CytoscapeComponent).attrs({stylesheet})`
  height: calc(100vh - 302px);
  width: 100%;
`;

export const searchedNodeStyle = {
  backgroundColor: theme.statusBackground.success,
  lineColor: theme.statusBackground.success
};
