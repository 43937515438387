import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {Dialog, DialogContent, DialogContentText} from '@material-ui/core';

import context from '../../../../context';
import hooks from '../../../../hooks';
import langs from '../../../../localization/langs';
import services from '../../../../services';

import ConfigurationInput from '../../../../components/Base/ConfigurationInput';

import * as Styled from './ServiceLogsAction.styles';

const DEFAULT_LOG_TYPES = [null];
const LOG_NAME_MAPPER = {null: langs('APPLICATION')};

function ServiceLogsAction({open, close, rows}) {
  const [logs, setLogs] = React.useState([]);
  const [queuedLogs, setQueuedLogs] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [stream, setStream] = React.useState(null);

  const [logTypes, setLogTypes] = React.useState(DEFAULT_LOG_TYPES);
  const [selectedLogType, setSelectedLogType] = React.useState(null);

  const ref = React.useRef();
  const notifications = context.Notifications.useNotifications();
  const [isPaused, setIsPaused, scrollEvent] = hooks.useAutoScroll(ref, logs);

  const row = _.head(rows);

  React.useEffect(() => {
    if (row?.deploymentId) {
      services.services.logs
        .getHooks(row.deploymentId)
        .then((response) => {
          const items = _.get(response, 'data.items', []);
          setLogTypes((prevState) => _.concat(prevState, _.map(items, 'jobName')));
        })
        .catch((ex) => {
          notifications.error(ex.message);
          console.error(ex);
        });
    }
  }, [row?.deploymentId]);

  React.useEffect(() => {
    if (row?.deploymentId && !isPaused && open) {
      console.log(`opening logs stream to ${row.deploymentId} ${selectedLogType ? `- ${selectedLogType}` : ''}`);
      services.services.logs.stream(row.deploymentId, selectedLogType, setQueuedLogs).then(setStream);
    }
    if (isPaused) {
      onClose(false, false);
    }
  }, [row?.deploymentId, isPaused, selectedLogType]);

  React.useEffect(() => {
    setLogs(
      [...queuedLogs, ...logs].filter((log) => log.message.toLowerCase().includes(search.toLowerCase())).slice(0, 500)
    );
  }, [queuedLogs]);

  React.useEffect(() => {
    if (!open) {
      setLogTypes(DEFAULT_LOG_TYPES);
      setSelectedLogType(_.head(DEFAULT_LOG_TYPES));
    }
  }, [open]);

  function onLogTypeSelect(newLogType) {
    setLogs([]);
    setSearch('');
    setSelectedLogType(newLogType);
  }

  function onClose(resetState = true, closeDialog = true) {
    if (resetState) {
      setLogs([]);
      setIsPaused(false);
      setSearch('');
    }
    if (stream) {
      console.log('closing logs stream');
      stream.cancel();
      setStream(null);
    }

    if (closeDialog) {
      close();
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
      <DialogContent>
        <DialogContentText>{langs('LOGS')}</DialogContentText>
        <Styled.FormContainer>
          <ConfigurationInput onChange={setSearch} value={search} placeholder="Search..." />
          <Styled.Button onClick={() => setIsPaused(!isPaused)}>
            {isPaused ? langs('CONTINUE_LOG_STREAMING') : langs('STOP_LOG_STREAMING')}
          </Styled.Button>
          <Styled.Button onClick={() => setLogs([])}>{langs('CLEAR_LOGS')}</Styled.Button>
        </Styled.FormContainer>
        <Styled.Tabs onChange={(e, newValue) => onLogTypeSelect(newValue)} value={selectedLogType}>
          {_.map(logTypes, (logType, index) => (
            <Styled.Tab key={index} label={_.startCase(_.get(LOG_NAME_MAPPER, logType, logType))} value={logType} />
          ))}
        </Styled.Tabs>
        <Styled.LogsContainer ref={ref} onScroll={scrollEvent}>
          {_.orderBy(logs, ['timestamp'])
            .filter((log) => log.message.toLowerCase().includes(search.toLowerCase()))
            .map((log, index) => (
              <div key={index}>
                <Styled.Log level={log.level}>{log.message}</Styled.Log>
              </div>
            ))}
        </Styled.LogsContainer>
      </DialogContent>
    </Dialog>
  );
}

ServiceLogsAction.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      deploymentId: PropTypes.string,
      searchParams: PropTypes.instanceOf(URLSearchParams)
    })
  )
};

export default ServiceLogsAction;
