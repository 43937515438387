import PropTypes from 'prop-types';
import React from 'react';

import NavbarLinkIconAndLabel from './NavbarLinkIconAndLabel';

import * as Styled from '../NavbarLink.styles';

function NavbarLinkTitle({label, isNestedLinksMode, isNestedLinksOpen}) {
  return (
    <>
      {isNestedLinksMode && isNestedLinksOpen && <Styled.StyledExpandLess />}
      {isNestedLinksMode && !isNestedLinksOpen && <Styled.StyledExpandMore />}
      {!isNestedLinksMode && <Styled.Spacer />}
      <NavbarLinkIconAndLabel label={label} isHeader />
    </>
  );
}

NavbarLinkTitle.propTypes = {
  isNestedLinksMode: PropTypes.bool,
  isNestedLinksOpen: PropTypes.bool,
  label: PropTypes.string.isRequired
};

export default NavbarLinkTitle;
