import styled, {css} from 'styled-components';
import * as theme from '../../theme';

export const Container = styled.div`
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translateX(100%);
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12);

  top: 0;
  right: 0;
  left: auto;

  height: 100%;

  z-index: 1200;

  position: fixed;
  background: ${theme.backgrounds.light};

  ${(props) =>
    props.open &&
    css`
      transform: translateX(0);
    `};

  ${(props) =>
    props.padded &&
    css`
      padding: 1rem;
    `};

  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Backdrop = styled.div`
  position: fixed;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  top: 0;
  right: 0;

  z-index: 100;
`;
