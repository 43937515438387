const Roles = {
  BASIC: 'basic',
  MANAGE_BEACONS: 'manage_beacons',
  MANAGE_COLLECTORS: 'manage_collectors',
  MANAGE_COMMANDS: 'manage_commands',
  MANAGE_CONFIGURATIONS: 'manage_configurations',
  MANAGE_TASKS: 'manage_tasks',
  MANAGE_TENANTS: 'manage_tenants',
  SHOW_CHERRY_PICKS: 'show_cherry_picks',
  SHOW_COLLECTORS: 'show_collectors',
  SHOW_CONFIGURATIONS: 'show_configurations',
  SHOW_DEMO: 'show_demo',
  SHOW_IMAGES: 'show_images',
  SHOW_TASKS: 'show_tasks',
  SHOW_TENANTS: 'show_tenants'
};

export default Roles;
