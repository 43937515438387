import _ from 'lodash';
import React from 'react';

import context from '../../../context';
import langs from '../../../localization/langs';

import DEFAULTS from './Parts/consts';
import FiltersRow from './Parts/FiltersRow/FiltersRow';
import SchemaGraph from './Parts/SchemaGraph/SchemaGraph';

function SelfSchemaViewer() {
  const notifications = context.Notifications.useNotifications();

  const user = context.User.useUser();

  const [schema, setSchema] = React.useState();

  const [selectedEnvs, setSelectedEnvs] = React.useState([]);
  const [selectedRoles, setSelectedRoles] = React.useState([]);
  const [selectedServices, setSelectedServices] = React.useState(DEFAULTS.SERVICES);

  React.useEffect(() => {
    user.rbac.schema.self
      .get()
      .then((schema) => {
        const defaultEnv = _.head(user.rbac.schema.getEnvsNamesFrom(schema));
        setSchema(schema);
        setSelectedEnvs([defaultEnv]);
      })
      .catch((error) => {
        console.error(error);
        notifications.error(langs('ERROR_RETRIEVING_SCHEMA'));
      });
  }, []);

  const envs = React.useMemo(() => user.rbac.schema.getEnvsNamesFrom(schema), [schema]);
  const graphData = React.useMemo(
    () =>
      schema
        ? user.rbac.schema.transform.userGraph(
            user.email,
            user.rbac.schema.transform.asGraph(schema, selectedEnvs, selectedServices, true, selectedRoles)
          )
        : {groups: {}, timestamp: Date.now()},
    [schema, selectedEnvs, selectedRoles, selectedServices]
  );

  return (
    <div>
      {_.isEmpty(schema) ? (
        langs('LOADING')
      ) : (
        <>
          <FiltersRow
            envs={envs}
            selectedEnvs={selectedEnvs}
            selectedServices={selectedServices}
            setSelectedEnvs={setSelectedEnvs}
            setSelectedServices={setSelectedServices}
          />
          <SchemaGraph
            key={graphData.timestamp}
            graphData={graphData}
            onNodeClick={(node) =>
              setSelectedRoles((prevSelectedRoles) => {
                const nodeId = _.parseInt(node.id, 10);
                return _.includes(prevSelectedRoles, nodeId) ? _.without(prevSelectedRoles, nodeId) : [nodeId];
              })
            }
          />
        </>
      )}
    </div>
  );
}

export default SelfSchemaViewer;
