import React from 'react';

import langs from '../../../../localization/langs';
import servicesApi from '../../../../services/services';

import ServiceBaseAction from './ServiceBaseAction';

const CONFIG = {
  action: langs('DEPLOYMENTS_ACTIONS_RESTART'),
  func: servicesApi.deployments.restart,
  taskRedirect: false
};

export default function ServiceRestartAction(props) {
  return <ServiceBaseAction {...props} config={CONFIG} />;
}
