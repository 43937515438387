import _ from 'lodash';

import {ENFORCEMENT_LEVEL} from '../../../consts/rbac/rbac';

import utils from './utils';

export function getMissingPermissions(serviceName, getRbacPermissions, permissions, env) {
  const fullRequestedPermissionNames = utils.wrapPermissionNames(serviceName, permissions, env);
  const permissionsDiff = _.difference(fullRequestedPermissionNames, getRbacPermissions());

  if (window.RBAC?._verboseMode) {
    window.RBAC._verboseData.add(fullRequestedPermissionNames);
    if (!_.isEmpty(permissionsDiff)) {
      window.RBAC._verboseData.addMissing(permissionsDiff);
    }
  }

  return permissionsDiff;
}

export function hasPermission(serviceName, getRbacPermissions, permission, env) {
  return hasPermissions(serviceName, getRbacPermissions, [permission], env);
}

export function hasPermissions(serviceName, getRbacPermissions, permissions, env) {
  if (_.isString(permissions)) {
    permissions = permissions.split(',');
  }
  return _.isEmpty(getMissingPermissions(serviceName, getRbacPermissions, permissions, env));
}

export function isEnabled(serviceFlagValue) {
  return serviceFlagValue === ENFORCEMENT_LEVEL.FULL;
}
