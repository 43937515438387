import _ from 'lodash';
import React from 'react';

import consts from '../consts';

const SPECIAL_KEYS = [
  consts.keyboard.ALT,
  consts.keyboard.CTRL,
  consts.keyboard.CONTROL,
  consts.keyboard.META,
  consts.keyboard.SHIFT
];

function useHotKeys(hotKeys, callback) {
  const listener = React.useCallback(
    (event) => {
      const {altKey, ctrlKey, metaKey, shiftKey, key} = event;
      const lowerKey = _.toLower(key);

      if (_.includes(SPECIAL_KEYS, lowerKey)) {
        return;
      }

      const isHotKey = _.find(hotKeys, (hotKey) => {
        const [specialKey, regKey] = _.includes(hotKey, '+') ? _.split(hotKey, '+') : [null, hotKey];
        if (lowerKey !== _.toLower(regKey)) {
          return false;
        }

        return (
          _.isEmpty(specialKey) ||
          (specialKey === consts.keyboard.ALT && altKey) ||
          (specialKey === consts.keyboard.CTRL && ctrlKey) ||
          (specialKey === consts.keyboard.CONTROL && ctrlKey) ||
          (specialKey === consts.keyboard.SHIFT && shiftKey) ||
          (specialKey === consts.keyboard.META && metaKey)
        );
      });

      if (isHotKey) {
        event.preventDefault();
        callback(event);
      }
    },
    [hotKeys, callback]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', listener);
    return () => window.removeEventListener('keydown', listener);
  }, [hotKeys, callback]);
}

export default useHotKeys;
