import envs from './envs';
import keyboard from './keyboard';
import rbac from './rbac/rbac';

const DEFAULT_INTEGRATION_PARAMS = {owner: 'shared', redisIsCluster: 'False', redisPort: '6379'};
const DEMO_ENV = 'demo1';
const ENVIRONMENTS = [DEMO_ENV, 'dev1', 'prod', 'prod4', 'prod5', 'prod7', 'qa1'];
const EXTERNAL_FULLSTORY_URL = process.env.REACT_APP_EXTERNAL_FULLSTORY_URL;
const EXTERNAL_GITHUB_AUTH_URL = process.env.REACT_APP_EXTERNAL_GITHUB_AUTH_URL;
const EXTERNAL_GRAFANA_URL = process.env.REACT_APP_EXTERNAL_GRAFANA_URL;
const EXTERNAL_JIRA_URL = process.env.REACT_APP_EXTERNAL_JIRA_URL;
const EXTERNAL_OPSGENIE_URL = process.env.REACT_APP_EXTERNAL_OPSGENIE_URL;
const EXTERNAL_PENDO_URL = process.env.REACT_APP_EXTERNAL_PENDO_URL;
const EXTERNAL_PROMETHEUS_URL = process.env.REACT_APP_EXTERNAL_PROMETHEUS_URL;

const HTTP_STATUS_CODES = {
  CONFLICT: 409
};

const COLLECTORS = 'collectors';
const TENANTS = 'tenants';
const DEPLOYMENTS = 'deployments';
const SERVICE_NAMES = {COLLECTORS, DEPLOYMENTS, TENANTS};
const API_PREFIX_BY_SERVICE_NAME = {
  [COLLECTORS]: 'cms',
  [DEPLOYMENTS]: 'mtms',
  [TENANTS]: 'maestro'
};

const BOOLEAN_MAPPER = {
  false: false,
  true: true
};

const FILTER_OPERATORS = {
  EQUAL: '[eq]',
  GREATER: '[gt]',
  GREATER_OR_EQUAL: '[ge]',
  LIKE: '[like]',
  LOWER: '[lt]',
  LOWER_OR_EQUAL: '[le]',
  NOT_EQUAL: '[ne]',
  NOT_LIKE: '[nlike]',
  OVERLAP: '[overlap]',
  REGEX: '[regex]'
};

const TOP_PANEL_HEIGHT = 74;
const SIDE_PANEL_WIDTH = 200;
const SIDE_PANEL_MAX_WIDTH = 300;
const MAIN_GRID_TOP_PADDING = 30;
const MAIN_GRID_BOTTOM_PADDING = 15;

const R_VERSION_PREFIX = 'R-';
const RC_VERSION_PREFIX = 'RC-';

const TABLE_CELL_WIDTH = 120;

const STYLED_TABLE_CELL = {minWidth: TABLE_CELL_WIDTH};
const NON_STYLED_TABLE_CELL = {minWidth: null};

const URL_PREFIX_GITHUB_PULL_REQUEST = 'https://github.com/ArmisSecurity/armis/pull/';
const URL_PREFIX_JIRA_TICKET = 'https://armis-security.atlassian.net/browse/';

export default {
  API_PREFIX_BY_SERVICE_NAME,
  BOOLEAN_MAPPER,
  DEFAULT_INTEGRATION_PARAMS,
  DEMO_ENV,
  ENVIRONMENTS,
  EXTERNAL_FULLSTORY_URL,
  EXTERNAL_GITHUB_AUTH_URL,
  EXTERNAL_GRAFANA_URL,
  EXTERNAL_JIRA_URL,
  EXTERNAL_OPSGENIE_URL,
  EXTERNAL_PENDO_URL,
  EXTERNAL_PROMETHEUS_URL,
  FILTER_OPERATORS,
  HTTP_STATUS_CODES,
  MAIN_GRID_BOTTOM_PADDING,
  MAIN_GRID_TOP_PADDING,
  NON_STYLED_TABLE_CELL,
  R_VERSION_PREFIX,
  RC_VERSION_PREFIX,
  SERVICE_NAMES,
  SIDE_PANEL_MAX_WIDTH,
  SIDE_PANEL_WIDTH,
  STYLED_TABLE_CELL,
  TABLE_CELL_WIDTH,
  TOP_PANEL_HEIGHT,
  URL_PREFIX_GITHUB_PULL_REQUEST,
  URL_PREFIX_JIRA_TICKET,
  envs,
  keyboard,
  rbac
};
