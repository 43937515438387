import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../../localization/langs';

import {Form, Input} from '../../../../components/Base';

import * as Styled from '../ServiceForm.styles';

const FIELDS = {
  DB_NAME: 'dbName',
  RDS_NAME: 'rdsName'
};

function ServiceDBInfo({value, onChange, ...props}) {
  function fieldValueChange(field, newValue) {
    const defaultValue = _.transform(FIELDS, (res, val) => (res[val] = false), {});
    onChange(_.assign(defaultValue, value, {[field]: newValue}));
  }

  return (
    <Styled.StyledPaper {...props}>
      <Form.Label>
        {langs('CHOOSE_DB_PARAMS')}
        <Styled.Optional>{` (${langs('OPTIONAL')})`}</Styled.Optional>
      </Form.Label>
      <Styled.FormFieldsContainer>
        <Form.Field>
          <Styled.FormFieldTitle>{langs('DB_NAME')}</Styled.FormFieldTitle>
          <Form.FieldWrapper>
            <Input
              type="text"
              value={value[FIELDS.DB_NAME]}
              onChange={(e) => fieldValueChange(FIELDS.DB_NAME, e.target.value)}
            />
          </Form.FieldWrapper>
        </Form.Field>
        <Form.Field>
          <Styled.FormFieldTitle>{langs('RDS_NAME')}</Styled.FormFieldTitle>
          <Form.FieldWrapper>
            <Input
              type="text"
              value={value[FIELDS.RDS_NAME]}
              onChange={(e) => fieldValueChange(FIELDS.RDS_NAME, e.target.value)}
            />
          </Form.FieldWrapper>
        </Form.Field>
      </Styled.FormFieldsContainer>
    </Styled.StyledPaper>
  );
}

ServiceDBInfo.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired
};

export default ServiceDBInfo;
