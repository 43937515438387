import _ from 'lodash';

import staticData from '../../models/staticData';

const CMS_PERMISSIONS =
  staticData.RBAC.SERVICE_PERMISSIONS.CMS.PROTO_FIELDS_TO_PERMISSIONS.CreateTaskRequest.command_type;
const MAESTRO_PERMISSIONS =
  staticData.RBAC.SERVICE_PERMISSIONS.MAESTRO.PROTO_FIELDS_TO_PERMISSIONS.CreateTaskRequest.command_type;
const MTMS_PERMISSIONS = staticData.RBAC.SERVICE_PERMISSIONS.MTMS;

const STATIC_DATA_SHELL = 'SHELL';
const STATIC_DATA_UPDATE_FIRMWARE = 'UPDATE_FIRMWARE';
const STATIC_DATA_TO_OPS_COMMANDS = {
  [STATIC_DATA_SHELL]: 'SHELL_COMMAND',
  [STATIC_DATA_UPDATE_FIRMWARE]: 'FIRMWARE_UPGRADE'
};

function buildPermissionsLookup(permissionsList) {
  return _.reduce(
    permissionsList,
    (result, current) => {
      const permissions = [current.permission];
      const key = _.get(STATIC_DATA_TO_OPS_COMMANDS, current.value, current.value);
      if (current.value === STATIC_DATA_SHELL) {
        permissions.push(current.control_field.values_to_permissions.FIELD_ACTIONS);
      }
      return _.assign(result, {[key]: permissions});
    },
    {}
  );
}

const cmsPermissionsLookup = buildPermissionsLookup(CMS_PERMISSIONS);
const maestroPermissionsLookup = buildPermissionsLookup(MAESTRO_PERMISSIONS);
const mtmsPermissionsLookup = {
  envs: [MTMS_PERMISSIONS.MT_ENVS_DEPLOY],
  namespaces: [MTMS_PERMISSIONS.MT_NAMESPACES_DEPLOY],
  services: [MTMS_PERMISSIONS.MT_SERVICES_DEPLOY],
  stacks: [MTMS_PERMISSIONS.MT_STACKS_DEPLOY]
};

export default {
  cms: cmsPermissionsLookup,
  maestro: maestroPermissionsLookup,
  mtms: mtmsPermissionsLookup
};
