import React from 'react';

import consts from '../../consts';
import hooks from '../../hooks';

import MosheCursor from '../../assets/cursors/moshe-removebg-preview-min-32.svg';
import TmpCursor from '../../assets/cursors/tmp-cursor.cur';

function AppCursor({children}) {
  const [cursor, setCursor] = React.useState('default');

  hooks.useHotKeys([`${consts.keyboard.ALT}+®`], () => {
    if (cursor === 'default') {
      setCursor('url(' + TmpCursor + '), auto');
    } else {
      setCursor('default');
    }
  });

  hooks.useHotKeys([`${consts.keyboard.ALT}+˚`], () => {
    if (cursor === 'default') {
      setCursor('url(' + MosheCursor + '), auto');
    } else {
      setCursor('default');
    }
  });

  return <div style={{cursor}}>{children}</div>;
}

export default AppCursor;
