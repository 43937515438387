import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../localization/langs';

import Task from './Task/Task';

import * as Styled from './TaskSection.styles';

function TaskSection({entityName, statusLabel, tasks, isActive, setSelectedTaskId, onChange, selectedTaskId}) {
  return (
    <Styled.Container>
      <Styled.Title>
        {tasks.length} {langs('TASKS')} {statusLabel}
      </Styled.Title>
      {_.map(tasks, (task) => (
        <Task
          entityName={entityName}
          command={task.command}
          commandType={task.commandType}
          countCollectors={task.count}
          countFailed={task.countFailed}
          countPending={task.countPending}
          countRunning={task.countRunning}
          countSucceeded={task.countSucceeded}
          creationDate={task.creationDate}
          isActive={isActive}
          isSelected={selectedTaskId === task.id}
          key={task.id}
          onClick={() => setSelectedTaskId(task.id)}
          onChange={onChange}
          taskId={task.id}
          taskStatus={task.status}
          triggeredBy={task.userFullName}
        />
      ))}
    </Styled.Container>
  );
}

TaskSection.propTypes = {
  entityName: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  selectedTaskId: PropTypes.number,
  setSelectedTaskId: PropTypes.func.isRequired,
  statusLabel: PropTypes.string.isRequired,
  tasks: PropTypes.array.isRequired
};

export default TaskSection;
