import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {_ as agGridUtils} from 'ag-grid-community';

import JiraIcon from '../../../assets/icons/jira-icon.svg';

import * as Styled from './TenantNameColumn.styled';

function onClick({nativeEvent}) {
  agGridUtils.stopPropagationForAgGrid(nativeEvent);
}

function TenantNameColumn({value: {tenantName, patches}}) {
  return (
    <Styled.Anchor
      target="_blank"
      rel="noopener noreferrer"
      href={`https://${tenantName}.armis.com/login/sso`}
      onClick={onClick}
    >
      {tenantName}
      {!_.isEmpty(patches) && <Styled.Img src={JiraIcon} />}
    </Styled.Anchor>
  );
}

TenantNameColumn.propTypes = {
  value: PropTypes.shape({
    patches: PropTypes.array,
    tenantName: PropTypes.string.isRequired
  }).isRequired
};

export default TenantNameColumn;
