import PropTypes from 'prop-types';
import React from 'react';

import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

import useStopMenuItem from './useStopMenuItem';

import {TooltipMenuItem} from '../../Base';

function StopMenuItem({tenantId, onChange}) {
  const [getDef, dialog] = useStopMenuItem(onChange);
  const tenant = {tenantId};
  const def = getDef(tenant);

  return (
    <TooltipMenuItem
      dialog={dialog}
      disabled={def.disabled}
      icon={<LocalHospitalIcon />}
      name={def.name}
      onClick={def.onClick}
      tooltip={def.tooltip}
    />
  );
}

StopMenuItem.propTypes = {
  onChange: PropTypes.func.isRequired,
  tenantId: PropTypes.string.isRequired
};

export default StopMenuItem;
