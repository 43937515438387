import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import langs from '../../../../localization/langs';

import {Form, Input} from '../../../../components/Base';

function TenantName({tenantNamePrefixes, selectedPrefix, setSelectedPrefix, setName, name}) {
  return (
    <Form.Field>
      <Form.Label>{langs('TENANT_NAME')}</Form.Label>
      <Form.FieldWrapper>
        {tenantNamePrefixes.length === 1 ? (
          <span>{selectedPrefix}</span>
        ) : (
          <Select
            value={selectedPrefix}
            onChange={(event) => setSelectedPrefix(event.target.value)}
            style={{fontSize: 14}}
            disableUnderline
          >
            {_.map(tenantNamePrefixes, (prefix) => (
              <MenuItem key={prefix} value={prefix} style={{fontSize: 14}}>
                {prefix}
              </MenuItem>
            ))}
          </Select>
        )}
        <Input type="text" value={name} required onChange={(e) => setName(e.target.value)} />
      </Form.FieldWrapper>
    </Form.Field>
  );
}

TenantName.propTypes = {
  name: PropTypes.string.isRequired,
  selectedPrefix: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  setSelectedPrefix: PropTypes.func.isRequired,
  tenantNamePrefixes: PropTypes.array.isRequired
};

export default TenantName;
