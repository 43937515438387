import React from 'react';
import PropTypes from 'prop-types';

import langs from '../../../localization/langs';

import * as Styled from './ActionColumn.styles';

function ActionColumn({value: {isDeleted}}) {
  return <Styled.Action isDeleted={isDeleted}>{isDeleted ? langs('DELETED') : langs('CREATED')}</Styled.Action>;
}

ActionColumn.propTypes = {
  value: PropTypes.shape({
    isDeleted: PropTypes.bool
  }).isRequired
};

export default ActionColumn;
