import styled from 'styled-components';

import {createTheme} from '@material-ui/core/styles';
import {Drawer as BaseDrawer} from '../../Base';

import theme from '../../../theme';

export const CollectorTableTheme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: '1.25rem'
      }
    },
    MuiTableCell: {
      head: {
        border: 'none',
        color: `${theme.palette.text.secondary}`,
        fontSize: 12,
        padding: 0,
        paddingLeft: 8
      },
      root: {
        padding: '8px',
        whiteSpace: 'nowrap'
      }
    }
  }
});

export const Container = styled.div`
  padding: 10px;
`;

export const filterCellStyle = {padding: 0, paddingBottom: 6};

export const Drawer = styled(BaseDrawer).attrs({padded: true, style: {minWidth: 700}, withBackdrop: true})``;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
`;
