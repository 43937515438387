import _ from 'lodash';
import React from 'react';

import Button from '@material-ui/core/Button';
import MuiTable from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import context from '../../../context';
import langs from '../../../localization/langs';
import services from '../../../services';
import DateField from '../../Base/DateField';

import ActivateBeaconModal from './Parts/ActivateBeaconModal';

import * as Styled from './Table.styles';

const COLUMNS = ['rpiId', 'ip', 'vendor', 'orgName', 'beaconUid', 'productSerial', 'managePort', 'lastSeen'];

function Table() {
  const [beacons, setBeacons] = React.useState([]);
  const [beaconId, setBeaconId] = React.useState(null);

  const user = context.User.useUser();
  const allowedProvision = user.rbac.cms.allowed.beacon.provision();

  React.useEffect(() => {
    services.collectors.beacons.list().then(({data}) => setBeacons(data.items));
  }, []);

  function closeModal() {
    setBeaconId(null);
  }

  return (
    <div>
      <ActivateBeaconModal beaconId={beaconId} onClose={closeModal} isOpen={!_.isNull(beaconId)} />

      <TableContainer component={Paper}>
        <MuiTable size="small">
          <TableHead>
            <TableRow>
              {_.map(COLUMNS, (col) => (
                <TableCell key={col}>{col}</TableCell>
              ))}
              <TableCell>{langs('ACTIONS')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(beacons, (row) => (
              <TableRow key={row.id}>
                <TableCell component="td" scope="row">
                  {row.rpiId}
                </TableCell>
                <TableCell component="td" scope="row">
                  {_.get(row, 'facts.defaultIpv4Address')}
                </TableCell>
                <TableCell component="td" scope="row">
                  {_.get(row, 'facts.systemVendor')}
                </TableCell>
                <TableCell component="td" scope="row">
                  {row.orgName}
                </TableCell>
                <Styled.DenseCell component="td" scope="row">
                  {row.beaconUid}
                </Styled.DenseCell>
                <Styled.DenseCell component="td" scope="row">
                  {row.productSerial}
                </Styled.DenseCell>
                <TableCell component="td" scope="row">
                  {row.managePort}
                </TableCell>
                <TableCell component="td" scope="row">
                  <DateField date={row.lastSeen} />
                </TableCell>
                <TableCell component="td" scope="row">
                  <Button
                    disabled={!allowedProvision}
                    size="small"
                    variant="text"
                    color="secondary"
                    onClick={() => setBeaconId(row.id)}
                  >
                    {langs('PROVISION')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </div>
  );
}

export default Table;
