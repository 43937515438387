import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../localization/langs';

import enums from './enums';

import * as Styled from './ConfigurationPreview.styles';

function ConfigurationPreview({value}) {
  return (
    <Styled.Container>
      {_.map(value, (operation) => (
        <div key={operation.name}>
          {operation.operation === enums.OPERATIONS.DELETE ? (
            <div>
              {langs('DELETE_CONFIGURATION', {
                key: operation.name
              })}
            </div>
          ) : operation.operation === enums.OPERATIONS.CREATE ? (
            <div>
              {langs('CREATE_CONFIGURATION', {
                inheritanceLevel: operation.inheritanceLevel,
                key: operation.name,
                value: JSON.stringify(operation.value)
              })}
            </div>
          ) : operation.operation === enums.OPERATIONS.UPDATE ? (
            <div>
              {langs('UPDATE_CONFIGURATION', {
                from: JSON.stringify(operation.oldValue),
                key: operation.name,
                to: JSON.stringify(operation.value)
              })}
            </div>
          ) : null}
        </div>
      ))}
    </Styled.Container>
  );
}

ConfigurationPreview.propTypes = {
  value: PropTypes.array.isRequired
};

export default ConfigurationPreview;
