import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {Checkbox, FormControl, FormGroup, FormLabel} from '@material-ui/core';

import langs from '../../../localization/langs';
import models from '../../../models';

import * as Styles from './BranchTypeSelector.styles';

function BranchTypeSelector({selectedBranchTypes, setSelectedBranchTypes}) {
  function handleBranchTypeChange(event) {
    if (event.target.checked) {
      setSelectedBranchTypes([...selectedBranchTypes, event.target.name]);
    } else {
      setSelectedBranchTypes(_.reject(selectedBranchTypes, (type) => type === event.target.name));
    }
  }

  function isChecked(type) {
    return selectedBranchTypes.includes(type);
  }

  return (
    <FormControl margin="normal">
      <FormLabel>{langs('SELECT_BRANCH_TYPE')}</FormLabel>
      <FormGroup row>
        {_.map(models.staticData.ARTIFACT.BRANCH_TYPES, (value, type) => (
          <Styles.FormControlLabel
            key={type}
            control={<Checkbox checked={isChecked(type)} onChange={handleBranchTypeChange} name={type} />}
            label={langs(type)}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

BranchTypeSelector.propTypes = {
  selectedBranchTypes: PropTypes.array.isRequired,
  setSelectedBranchTypes: PropTypes.func.isRequired
};

export default BranchTypeSelector;
