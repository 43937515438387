import styled from 'styled-components';

import * as theme from '../../../theme';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
`;

export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

export const Description = styled.span`
  color: ${theme.text.secondary};
  font-size: 12px;
`;
