import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import AddIcon from '@material-ui/icons/Add';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import SwapVertIcon from '@material-ui/icons/SwapVert';

import List from './Parts/List';

import * as Styled from './ListSelection.styles';

function ListSelection({items, selectedItems, onChange, upperListRender, lowerListRender, ...props}) {
  const itemsToSelect = _.reject(items, ({id}) => _.find(selectedItems, {id}));

  function itemSelected(item) {
    const isRemoved = _.find(selectedItems, {id: item.id});
    if (isRemoved) {
      onChange(_.reject(selectedItems, {id: item.id}));
    } else {
      onChange([...selectedItems, item]);
    }
  }

  return (
    <Styled.Container {...props}>
      <List items={itemsToSelect} icon={AddIcon} onChange={itemSelected} render={upperListRender} />
      <Styled.SwapContainer>
        <SwapVertIcon fontSize="large" />
      </Styled.SwapContainer>
      <List items={selectedItems} icon={DeleteOutlinedIcon} onChange={itemSelected} render={lowerListRender} />
    </Styled.Container>
  );
}

ListSelection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  lowerListRender: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  upperListRender: PropTypes.func
};

export default ListSelection;
