import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './Breadcrumb.styles';

function Breadcrumb({sections, withBorder = true, ...props}) {
  function renderSection(section, key) {
    if (_.isString(section)) {
      return <Styled.Item key={key}>{section}</Styled.Item>;
    }

    if (!_.has(section, 'url')) {
      return <Styled.Item key={key}>{section.title}</Styled.Item>;
    }

    return (
      <Styled.Link key={key} to={section.url}>
        {section.title}
      </Styled.Link>
    );
  }

  return (
    <Styled.Container withBorder={withBorder} {...props}>
      {_.map(sections, renderSection)}
    </Styled.Container>
  );
}

Breadcrumb.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string
      }),
      PropTypes.string
    ])
  ),
  withBorder: PropTypes.bool
};

export default Breadcrumb;
