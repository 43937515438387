import _ from 'lodash';
import React from 'react';

import consts from '../consts';
import context from '../context';
import hooks from '../hooks';
import langs from '../localization/langs';
import models from '../models';
import services from '../services';

import Configuration from '../components/Configuration/Configuration';
import enums from '../components/Configuration/enums';
import TenantsArtifactInput from '../components/Tenants/TenantsArtifactInput';
import utils from '../components/Configuration/utils';

const ACTION_VALUE_READ_CONFIG = 'read_config';
const CATEGORY_PERMISSIONS_CREATE = consts.rbac.permissions.maestro.tenant.configuration.create.categories;
const CATEGORY_PERMISSIONS_EDIT = consts.rbac.permissions.maestro.tenant.configuration.edit.categories;
const CATEGORY_PERMISSIONS_READ = consts.rbac.permissions.maestro.tenant.configuration.read.categories;

const DISPLAY_SETTINGS = {
  showConfigurationKeySearch: true
};

const categories = [
  {
    id: 'FIRMWARE',
    name: langs('FIRMWARE_CONFIGURATION'),
    permissions: {
      create: CATEGORY_PERMISSIONS_CREATE.firmware,
      edit: CATEGORY_PERMISSIONS_EDIT.firmware,
      read: CATEGORY_PERMISSIONS_READ.firmware
    }
  },
  {
    id: 'APP_DEPLOYMENT',
    name: langs('APP_DEPLOYMENT_CONFIGURATION'),
    permissions: {
      create: CATEGORY_PERMISSIONS_CREATE.appDeployment,
      edit: CATEGORY_PERMISSIONS_EDIT.appDeployment,
      read: CATEGORY_PERMISSIONS_READ.appDeployment
    },
    subCategories: [
      {id: enums.SUB_CATEGORY.PIPELINE, name: langs('PIPELINE')},
      {id: enums.SUB_CATEGORY.FE_SERVER, name: langs('FE_SERVER')},
      {id: enums.SUB_CATEGORY.GENERAL, name: langs('GENERAL')},
      {id: enums.SUB_CATEGORY.CONTAINER_CONFIG, name: langs('CONTAINER_CONFIG')},
      {id: enums.SUB_CATEGORY.POSTGRES, name: langs('POSTGRES')}
    ]
  },
  {
    id: 'TERRAFORM',
    name: langs('TERRAFORM_CONFIGURATION'),
    permissions: {
      create: CATEGORY_PERMISSIONS_CREATE.terraform,
      edit: CATEGORY_PERMISSIONS_EDIT.terraform,
      read: CATEGORY_PERMISSIONS_READ.terraform
    }
  },
  {
    id: 'TMS',
    name: langs('TMS_CONFIGURATION'),
    permissions: {
      create: CATEGORY_PERMISSIONS_CREATE.tms,
      edit: CATEGORY_PERMISSIONS_EDIT.tms,
      read: CATEGORY_PERMISSIONS_READ.tms
    }
  }
];

const extendedTypes = {
  [models.configuration.TYPES.ARTIFACT_ID]: TenantsArtifactInput
};

function TenantsConfiguration() {
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [envs, setEnvs] = React.useState([]);
  const [tenants, setTenants] = React.useState([]);
  const [configuration, setConfiguration] = React.useState([]);

  const [category, setCategory] = hooks.useSearchQueryFilter(
    'category',
    consts.FILTER_OPERATORS.EQUAL,
    _.head(categories).id
  );
  const [envId, setEnvId] = hooks.useSearchQueryFilter('envId', consts.FILTER_OPERATORS.EQUAL);
  const [tenantId, setTenantId] = hooks.useSearchQueryFilter('tenantId', consts.FILTER_OPERATORS.EQUAL);

  const notifications = context.Notifications.useNotifications();
  const user = context.User.useUser();

  React.useEffect(() => {
    getEnvs();
  }, []);

  React.useEffect(() => {
    onEnvSelect(tenantId);
    setIsFirstLoad(false);
  }, []);

  React.useEffect(() => {
    if (isFirstLoad) {
      return;
    }
    onEnvSelect();
  }, [envId]);

  React.useEffect(() => {
    if (isFirstLoad) {
      return;
    }
    getConfiguration();
  }, [category, tenantId]);

  const wrappedCategories = React.useMemo(
    () =>
      _.map(categories, (category) =>
        _.assign(
          {
            permissionFuncs: {
              create: (labels) => user.rbac.maestro.hasConfigPermissions(category.permissions.create, labels),
              edit: (labels) => user.rbac.maestro.hasConfigPermissions(category.permissions.edit, labels),
              read: (labels) => user.rbac.maestro.hasConfigPermissions(category.permissions.read, labels)
            }
          },
          category
        )
      ),
    [user.rbac.maestro]
  );

  async function getEnvs() {
    try {
      const envsResponse = await services.tenants.getEnvs({action: ACTION_VALUE_READ_CONFIG});
      const envItems = _.get(envsResponse, 'data.items', []);
      setEnvs(envItems);
    } catch (ex) {
      notifications.error(ex.message);
    }
  }

  async function onEnvSelect(selectedTenantId = null) {
    setTenantId(selectedTenantId);

    try {
      setIsLoading(true);
      const [newTenants, newConfiguration] = await Promise.all([
        getTenants(),
        services.tenants.configuration.get(category, envId, selectedTenantId)
      ]);

      const tenantItems = _.get(newTenants, 'data.items', []);
      setTenants(
        _.map(tenantItems, ({tenantId, tenantName}) => ({
          id: tenantId,
          name: tenantName
        }))
      );

      const configurationItems = _.get(newConfiguration, 'data.items', []);
      setConfiguration(_.map(configurationItems, (item) => _.defaults({value: JSON.parse(item.value)}, item)));
    } catch (ex) {
      setTenants([]);
      setConfiguration([]);
      notifications.error(ex.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSaveConfiguration(operations) {
    const promises = _.map(operations, (operation) => {
      if (operation.operation === enums.OPERATIONS.DELETE) {
        return services.tenants.configuration.deleteValue(operation.configurationValueId);
      }
      if (operation.operation === enums.OPERATIONS.UPDATE) {
        return services.tenants.configuration.update(operation.configurationValueId, operation.value);
      }
      if (operation.operation === enums.OPERATIONS.CREATE) {
        return services.tenants.configuration.createValue(operation);
      }
    });

    try {
      await Promise.all(promises);
      await getConfiguration();
    } catch (ex) {
      notifications.error(ex.message);
    }
  }

  async function onAddConfigurationKey(...params) {
    try {
      await services.tenants.configuration.createKey(...params);
      return getConfiguration();
    } catch (ex) {
      console.error(ex.stack);
      notifications.error(ex.message);
    }
  }

  async function onDeleteConfigurationKeyValue(configurationValueId) {
    try {
      await services.tenants.configuration.deleteValue(configurationValueId);
      return getConfiguration();
    } catch (ex) {
      console.error(ex.stack);
      notifications.error(ex.message);
    }
  }

  async function onGetConfigurationKeyVersions(configurationKeyId) {
    try {
      const {
        data: {items: versions}
      } = await services.tenants.configuration.getVersions(envId, tenantId, configurationKeyId);
      return _.map(versions, (version) =>
        _.defaults(
          {
            level: utils.getLevelFromLabels(labelDefinitions, version.labels),
            value: _.isEmpty(version.value) ? '' : JSON.parse(version.value)
          },
          version
        )
      );
    } catch (ex) {
      console.error(ex.stack);
      notifications.error(ex.message);
    }
  }

  async function getTenants() {
    if (_.isEmpty(envId)) {
      return [];
    }
    return services.tenants.getByEnv(envId);
  }

  async function getConfiguration() {
    try {
      setIsLoading(true);
      const response = await services.tenants.configuration.get(category, envId, tenantId);
      const items = _.get(response, 'data.items', []);
      setConfiguration(_.map(items, (item) => _.defaults({value: JSON.parse(item.value)}, item)));
    } catch (ex) {
      setConfiguration([]);
      notifications.error(ex.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function getAffectedTenants() {
    let data;
    if (_.isEmpty(tenantId)) {
      const response = await services.tenants.getByEnv(envId);
      data = _.get(response, ['data', 'items'], []);
    } else {
      const response = await services.tenants.getById(tenantId);
      data = [_.get(response, 'data', [])];
    }
    return {
      displayValues: _.map(data, (tenant) => _.pick(tenant, ['environmentName', 'tenantName'])),
      entities: data
    };
  }

  async function getConfigurationKeys(keyName) {
    const {
      data: {items}
    } = await services.tenants.configuration.getConfigurationKeys(keyName);
    return _.map(items, (item) => _.assign({name: item.key}, item));
  }

  if (_.isEmpty(envs)) {
    return null;
  }

  const env = _.find(envs, {id: envId});
  const tenant = _.find(tenants, {id: tenantId});
  const labelDefinitions = [
    {
      defaultPermissionsFunc: user.rbac.maestro.allowed.tenant.configuration.edit.all.all,
      key: 'envId',
      label: 'ENV',
      onChange: setEnvId,
      options: envs,
      permissionsFunc: user.rbac.maestro.allowed.tenant.configuration.edit.all.envs,
      value: env,
      width: 2
    },
    {
      key: 'tenantId',
      label: 'TENANT',
      onChange: setTenantId,
      options: tenants,
      permissionsFunc: user.rbac.maestro.allowed.tenant.configuration.edit.all.tenants,
      value: tenant,
      width: 2
    }
  ];

  return (
    <Configuration
      categories={wrappedCategories}
      configuration={configuration}
      displaySettings={DISPLAY_SETTINGS}
      extendedTypes={extendedTypes}
      getAffectedEntities={getAffectedTenants}
      getConfigurationKeys={getConfigurationKeys}
      isLoading={isLoading}
      labelDefinitions={labelDefinitions}
      onAddConfigurationKey={onAddConfigurationKey}
      onChangeCategory={setCategory}
      onDeleteConfigurationKeyValue={onDeleteConfigurationKeyValue}
      onGetConfigurationKeyVersions={onGetConfigurationKeyVersions}
      onSaveConfiguration={onSaveConfiguration}
      permissionFuncs={{
        create: user.rbac.maestro.allowed.tenant.configuration.create.create,
        edit: user.rbac.maestro.allowed.tenant.configuration.edit.edit,
        read: user.rbac.maestro.allowed.tenant.configuration.read.read
      }}
      selectedCategory={category}
    />
  );
}

export default TenantsConfiguration;
