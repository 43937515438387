import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactIs from 'react-is';
import {AgGridColumn} from 'ag-grid-react';

import EditableColumn from './columns/EditableColumn';

function Column() {
  return null;
}

function getFilterType(filter) {
  if (_.isBoolean(filter) && filter) {
    return 'agTextColumnFilter';
  }
  if (isSetFilter(filter)) {
    return 'agSetColumnFilter';
  }
  return filter;
}

function isSetFilter(filter) {
  return _.isArray(filter) || _.isFunction(filter);
}

Column.propTypes = {
  editablePermissions: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  exportFunc: PropTypes.func,
  rbacControlObj: PropTypes.shape({
    getMissingPermissionsStr: PropTypes.func.isRequired,
    hasPermissions: PropTypes.func.isRequired
  }),
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]),
  renderProps: PropTypes.object,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  ...AgGridColumn.propTypes
};

Column.toColDef = (
  {
    editable,
    editablePermissions,
    filter,
    filterParams,
    onCellClicked,
    rbacControlObj,
    render,
    renderProps,
    title,
    tooltip,
    value,
    ...props
  },
  gridApi,
  onRowClicked
) => {
  const colDef = {
    autoHeight: true,
    filter: getFilterType(filter),
    filterParams: {
      defaultToNothingSelected: true,
      refreshValuesOnOpen: true,
      suppressAndOrCondition: true,
      values: isSetFilter(filter) ? filter : undefined,
      ...filterParams
    },
    gridApi,
    headerName: title,
    resizable: true,
    ...props
  };

  const isAllowedEdit =
    editablePermissions && rbacControlObj ? rbacControlObj.hasPermissions(editablePermissions) : true;

  if (_.isFunction(value)) {
    colDef.valueGetter = (params) => value(params.data, params);
  } else {
    colDef.field = value;
  }

  if (!colDef.colId && _.isString(value)) {
    colDef.colId = value;
  }

  if (editable && isAllowedEdit) {
    colDef.editable = true;
    if (!render) {
      render = EditableColumn;
    }
    if (isSetFilter(editable)) {
      colDef.cellEditor = 'agRichSelectCellEditor';
      colDef.cellEditorParams = {values: editable};
    }
  } else {
    colDef.onCellClicked = onCellClicked || onRowClicked;
  }

  if (!isAllowedEdit) {
    colDef.editable = false;
    colDef.missingPermissions = rbacControlObj.getMissingPermissionsStr(editablePermissions);
    if (!render) {
      render = EditableColumn;
    }
  }

  if (ReactIs.isValidElementType(render)) {
    colDef.cellRendererFramework = render;
  } else if (_.isFunction(render)) {
    colDef.cellRenderer = render;
  }

  if (renderProps) {
    colDef.cellRendererParams = renderProps;
  }

  if (_.isUndefined(tooltip)) {
    colDef.tooltipValueGetter = colDef.valueGetter;
    colDef.tooltipField = colDef.field;
  } else if (_.isFunction(tooltip) && !colDef.tooltipValueGetter) {
    colDef.tooltipValueGetter = (params) => tooltip(params.data, params);
  } else if (_.isString(tooltip) && !colDef.tooltipField) {
    colDef.tooltipField = tooltip;
  }

  return colDef;
};

export default Column;
