import client from '../client';

import * as artifactUtils from '../artifact.utils';

const MTMS_ARTIFACTS_URL = 'mtms/api/v1/artifacts';

export async function listArtifacts({
  like = '',
  branchTypes = null,
  isLatestBuild = true,
  length = 100,
  orderBy = 'creationDate:desc'
}) {
  const params = artifactUtils.getParams(like, branchTypes, length, orderBy, isLatestBuild);
  return client.get(MTMS_ARTIFACTS_URL, params);
}

export function getArtifactByBranch(branch) {
  return client.get(`${MTMS_ARTIFACTS_URL}?branch=${branch}&orderBy=id:desc&length=1`);
}
