import consts from '../../../consts';
import _ from 'lodash';

const FILTER_TYPE_TO_OPERATOR = {
  contains: consts.FILTER_OPERATORS.LIKE,
  endsWith: consts.FILTER_OPERATORS.LIKE,
  equals: consts.FILTER_OPERATORS.EQUAL,
  greaterThan: consts.FILTER_OPERATORS.GREATER,
  greaterThanOrEqual: consts.FILTER_OPERATORS.GREATER_OR_EQUAL,
  lessThan: consts.FILTER_OPERATORS.LOWER,
  lessThanOrEqual: consts.FILTER_OPERATORS.LOWER_OR_EQUAL,
  notContains: consts.FILTER_OPERATORS.NOT_LIKE,
  notEqual: consts.FILTER_OPERATORS.NOT_EQUAL,
  set: consts.FILTER_OPERATORS.EQUAL,
  startsWith: consts.FILTER_OPERATORS.LIKE
};
const FILTER_TYPE_TO_VALUE_MODIFIER = {
  contains: (filter) => `%${filter}%`,
  default: _.identity,
  endsWith: (filter) => `%${filter}`,
  notContains: (filter) => `%${filter}%`,
  startsWith: (filter) => `${filter}%`
};
const NUMERIC_FILTER_TYPES = {
  EQUALS: 'equals',
  GREATER_OR_EQUAL: 'greaterThanOrEqual',
  LESS_OR_EQUAL: 'lessThanOrEqual',
  RANGE: 'inRange'
};
const OPERATOR_TO_FILTER_TYPE = {
  [consts.FILTER_OPERATORS.EQUAL]: 'equals',
  [consts.FILTER_OPERATORS.GREATER]: 'greaterThan',
  [consts.FILTER_OPERATORS.GREATER_OR_EQUAL]: 'greaterThanOrEqual',
  [consts.FILTER_OPERATORS.LIKE]: 'contains',
  [consts.FILTER_OPERATORS.LOWER]: 'lessThan',
  [consts.FILTER_OPERATORS.LOWER_OR_EQUAL]: 'lessThanOrEqual',
  [consts.FILTER_OPERATORS.NOT_EQUAL]: 'notEqual',
  [consts.FILTER_OPERATORS.NOT_LIKE]: 'notContains'
};

export default {
  FILTER_TYPE_TO_OPERATOR,
  FILTER_TYPE_TO_VALUE_MODIFIER,
  NUMERIC_FILTER_TYPES,
  OPERATOR_TO_FILTER_TYPE
};
