import styled, {css} from 'styled-components';

import SvgIcon from '@material-ui/core/SvgIcon';

import * as theme from '../../theme';

const IconButtonStyled = styled(SvgIcon)`
  cursor: pointer;
  &:hover {
    color: ${theme.buttons.normal.hover};
  }
  &:active {
    color: ${theme.link.hover};
  }

  ${({disabled}) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
      color: ${theme.text.secondary};
    `};
`;

export default IconButtonStyled;
