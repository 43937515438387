import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Switch from '@material-ui/core/Switch';

import {DynamicInput} from '../Base';

import langs from '../../localization/langs';

import * as Styled from './ConfigurationFilters.styles';

const SEPARATOR = <span> | </span>;

function ConfigurationFilters({value, onChange, ...props}) {
  function onFiltersChange(newFilters) {
    onChange(_.assign({}, value, newFilters));
  }

  const {showEmptyValues, showInheritedValues, text} = value;
  return (
    <Styled.Container {...props}>
      {SEPARATOR}
      <Styled.Wrapper>
        <Styled.SubTitle>{langs('SHOW_EMPTY')}</Styled.SubTitle>
        <Switch
          size="small"
          checked={showEmptyValues}
          onChange={({target: {checked}}) => onFiltersChange({showEmptyValues: checked})}
        />
      </Styled.Wrapper>
      {SEPARATOR}
      <Styled.Wrapper>
        <Styled.SubTitle>{langs('INHERITS')}</Styled.SubTitle>
        <Switch
          size="small"
          checked={showInheritedValues}
          onChange={({target: {checked}}) => onFiltersChange({showInheritedValues: checked})}
        />
      </Styled.Wrapper>
      <Styled.Wrapper>
        <DynamicInput
          type="STRING"
          value={text}
          placeholder={langs('SEARCH_WITH_DOTS')}
          onChange={(search) => onFiltersChange({text: search})}
        />
      </Styled.Wrapper>
    </Styled.Container>
  );
}

ConfigurationFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired
};

export default ConfigurationFilters;
