import hooks from './';

const PERSISTENT_COLUMNS_SUFFIX = '_additional_columns';

export default function usePersistentColumns(tableName, defaultValue = []) {
  const storageKey = `${tableName}${PERSISTENT_COLUMNS_SUFFIX}`;

  function getColumns(columns) {
    try {
      return JSON.parse(columns);
    } catch (e) {
      return [];
    }
  }

  function setColumns(columns) {
    return JSON.stringify(columns);
  }

  return hooks.useLocalStorage(storageKey, defaultValue, setColumns, getColumns);
}
