import demo from './enDemo';
import rbac from './rbac';
import services from './enServices';
import tenantStage from './tenantStage';

export default {
  ABORT: 'Abort',
  ACTION: 'Action',
  ACTIONS: 'Actions',
  ACTIVATE_COLLECTOR: 'Activate Collector',
  ACTIVATED: 'Activated',
  ACTIVE: 'Active',
  ADD: 'Add',
  ADD_COMMAND: 'Add Command',
  ADD_KEY_FOR: 'Add Key For',
  ADD_NEW_COMMAND: 'Add New Command',
  ADD_NEW_CONFIGURATION: 'Add New Configuration',
  ADD_NEW_NAMESPACE: 'Add New Namespace',
  ADD_NEW_SERVICE: 'Add New Service',
  ADD_NEW_STACK: 'Add New Stack',
  ADD_NEW_TENANT: 'Add New Tenant',
  ADD_TO: 'Add To',
  ADVANCED_OPTIONS: 'Advanced Options',
  ADVANCED_SEARCH: 'Advanced Search',
  AFFECTED: 'Affected',
  AGENT_STATUS: 'Agent Status',
  ALERTS_RAISED: '<%=count%> Alerts raised',
  ALL: 'All',
  ALL_STATUSES: 'All statuses',
  ALREADY_SUBSCRIBED: 'You are currently subscribed to this tenant.',
  APN_NAME: 'APN name',
  APN_PASSWORD: 'APN Password',
  APN_USER: 'APN User',
  APP_CONFIG: 'App Config',
  APP_DEPLOYMENT: 'App Deployment',
  APP_DEPLOYMENT_CONFIGURATION: 'App Deployment Configuration',
  APP_DEPLOYMENT_FAILED: 'App Deployment Failed',
  APP_DEPLOYMENT_REQUIRED: 'App Deployment Required',
  APP_DEPLOYMENT_RUNNING: 'Deployment Running',
  APP_VERSION: 'App version',
  APP_VERSION_LAST_UPDATED: 'App version (last updated on <%=date%>)',
  APP_VERSION_UPGRADE: 'App Version Upgrade',
  APPLICATION: 'Application',
  APPLICATION_VERSION: 'Application Version',
  APPLICATION_VERSION_MAJOR: 'Major Version',
  APPLY_CHANGES: 'Apply Changes',
  APPLY_CONFIGURATION: 'Apply Configuration',
  APPROVERS: 'Approvers',
  ARE_YOU_SURE: 'Are you sure?',
  ARE_YOU_SURE_COMMAND_ARCHIVE: 'Are you sure you want to archive this command?',
  AVAILABILITY_STATUS_ACTIVE: 'Active',
  AVAILABILITY_STATUS_INACTIVE: 'Inactive',
  AVAILABILITY_STATUS_UNKNOWN: 'Unknown',
  BACK: 'Back',
  BEACONS: 'Beacons',
  BRANCH: 'Branch',
  BUILD_ID: 'Build ID',
  BUTTON_ADD: 'Add',
  BUTTON_APPROVE: 'Approve',
  BUTTON_BACK: 'Back',
  BUTTON_CANCEL: 'Cancel',
  BUTTON_CLOSE: 'Close',
  BUTTON_CONTINUOUS_DELIVERY_DISABLE: 'Disable Continuous Delivery',
  BUTTON_CONTINUOUS_DELIVERY_ENABLE: 'Enable Continuous Delivery',
  BUTTON_CREATE: 'Create',
  BUTTON_DECLINE: 'Decline',
  BUTTON_DELETE: 'Delete',
  BUTTON_DESTROY: 'Destroy',
  BUTTON_DISCARD_CHANGES: 'Discard changes',
  BUTTON_EDIT: 'Edit',
  BUTTON_EDIT_SA: 'Edit SA',
  BUTTON_EDIT_STAGE: 'Edit Stage',
  BUTTON_EXPORT: 'Export',
  BUTTON_MONITORING_DISABLE: 'Disable Monitoring',
  BUTTON_MONITORING_ENABLE: 'Enable Monitoring',
  BUTTON_NEXT: 'Next',
  BUTTON_OK: 'OK',
  BUTTON_SAVE: 'Save',
  BUTTON_SAVE_CHANGES: 'Save Changes',
  BUTTON_START: 'Start',
  BUTTON_STOP: 'Stop',
  BUTTON_SUBSCRIBE: 'Subscribe',
  BUTTON_UNSUBSCRIBE: 'Unsubscribe',
  BUTTON_YES: 'Yes',
  CANCEL: 'Cancel',
  CANCELED: 'Canceled',
  CATEGORY: 'Category',
  CHERRY_PICKS: 'Cherry Picks',
  CHOOSE_BRANCH: 'Choose a Branch',
  CHOOSE_CONTAINERS: 'Choose Containers (default: All)',
  CHOOSE_DATA_SOURCE: 'Choose Data Sources',
  CHOOSE_DB_PARAMS: 'Choose DB Params',
  CHOOSE_NAMESPACE: 'Choose a Namespace',
  CHOOSE_SERVICES: 'Choose Services (default: All)',
  CHOOSE_STACKS: 'Choose Stacks',
  CHOOSE_TASK_TYPE: 'Choose Task Type',
  CHOOSE_TENANT: 'Choose a Tenant',
  CHOOSE_TENANTS: 'Choose Tenants (default: All)',
  CHOOSE_TYPE_TO_SUBSRIBE: 'Choose type to subscribe',
  CLASSIC: 'Classic',
  CLEAR_LOGS: 'Clear Logs',
  CLEAR_SELECTION: 'Clear Selection',
  CLICK_ON_REROUTE_MESSAGE: 'Click on the reroute button in order to provision this collector',
  CLOSE: 'Close',
  CLUSTER_CONFIGURATION: 'Cluster Configuration',
  CLUSTER_MEMBER: 'Cluster Member',
  CMS_CONFIGURATION: 'CMS Configuration',
  COLLAPSE_ALL: 'Collapse All',
  COLLECTOR_CONFIGURATION: 'Collector Configuration',
  COLLECTOR_DELETION_DESCRIPTION: 'Please insert the following format: <tenant name>:<collector number>',
  COLLECTOR_DELETION_TITLE: 'Collector deletion',
  COLLECTOR_ID: 'Collector ID: <%=collectorId%>',
  COLLECTOR_NUMBER: 'Collector Number',
  COLLECTOR_PROVISION: 'Collector Provision',
  COLLECTOR_STAGE_UNKNOWN: 'Collector Stage Unknown',
  COLLECTOR_STATUS: 'Collector Status',
  COLLECTORS: 'Collectors',
  COLLECTORS_CONNECTED: '<%=count%> Collectors connected',
  COLLECTORS_DISCONNECTED: '<%=count%> Collectors disconnected',
  COLLECTORS_DISCONNECTED_SHORT: '<%=count%> Disconnected',
  COLLECTORS_MISMATCH: '<%=count%> collectors mismatch',
  COLUMNS: 'Columns',
  COMMAND: 'Command',
  COMMAND_ERROR: 'Error',
  COMMAND_OUTPUT: 'Command Output',
  COMMAND_STATUS: 'CMD Status',
  COMMANDS: 'Commands',
  COMMENT: 'Comment',
  COMMENTS: '<%=count%> comments',
  COMMIT_ID: 'Commit ID',
  COMPACT: 'Compact',
  COMPLETED: 'Completed',
  COMPLETED_RECENTLY: 'Completed Recently',
  CONFIG_ACTION_DIALOG_WARNING_ALL: 'This action will apply on <%=count%> entities:',
  CONFIG_ACTION_DIALOG_WARNING_ONE: 'This action will apply on the following entity:',
  CONFIG_ACTION_DIALOG_WARNING_TOP: 'This action will apply on <%=count%> entities, sample:',
  CONFIG_NAME: 'Config Name',
  CONFIG_VALUE: 'Config Value',
  CONFIGURATION: 'Configuration',
  CONFIGURATION_ADDED_SUCCESSFULLY: 'Configuration Added Successfully',
  CONFIGURATION_KEY_NAME: 'Configuration Key Name',
  CONFIGURATION_PARAMS: 'Configuration Params',
  CONFIGURATION_SEARCH_NO_KEY_ERROR: 'Configuration search must have a key filter',
  CONFIGURATION_TYPE: 'Configuration Type',
  CONFIGURATION_VALUE_ID: 'Configuration Value Id',
  CONFIGURATIONS: 'Config',
  CONFIGURE: 'Configure',
  CONFIGURE_ADD: 'Add config for <%=level%>',
  CONFIGURE_ALL: 'Configure ALL',
  CONFIRM: 'Confirm',
  CONFIRM_TASK: 'Confirm Task',
  CONFIRM_TASK_TEXT: 'Are You sure you want to run <%=task%> on <%=hosts%> hosts?',
  CONTAINER_CONFIG: 'Container Config',
  CONTINUE: 'Continue',
  CONTINUE_LOG_STREAMING: 'Continue Log Streaming',
  CONTINUOUS_DELIVERY: 'Continuous Delivery',
  COPIED: 'Copied',
  COPY_SERVICE_HOST: 'Copy Service Host',
  COPY_TASK_URL: 'Copy Task Url',
  COPY_TO_CLIPBOARD: 'Copy to clipboard',
  COPY_VALUE_TO_CLIPBOARD: 'Copy value to clipboard',
  CORALOGIX_MONITOR: 'Enable Coralogix Log Collection',
  COULD_NOT_FETCH_DATA_FOR_CURSOR: 'Could not fetch data for this cursor',
  COUNT_FILTER_APPLIED: '<%=count%> filters applied',
  CREATE_CONFIGURATION: 'Create <%=key%> with value <%=value%> (specific for <%=inheritanceLevel%>)',
  CREATE_RMQ_RELAY: 'Create New RMQ Relay',
  CREATED: 'Created',
  CREATED_BY: 'Created By',
  CREATION_DATE: 'Creation Date',
  CURRENT_OWNER: 'Current Owner',
  CURRENT_STAGE: 'Current Stage',
  CURRENTLY_UNAVAILABLE: 'currently unavailable',
  CUSTOMER_CONFIGURATION: 'Customer Configuration',
  DASHBOARD: 'Dashboard',
  DB_NAME: 'DB Name',
  DECOMMISSION: 'Decommission',
  DECOMMISSIONED: 'Decommissioned',
  DELETE: 'Delete',
  DELETE_CONFIGURATION: 'Delete <%=key%>',
  DELETE_TENANT: 'Delete Tenant',
  DELETE_VALUE: 'Delete value',
  DELETED: 'Deleted',
  DELETING: 'Deleting',
  DELIVERY_STATUS_DELAYED: 'Delayed',
  DELIVERY_STATUS_NEW: 'New',
  DELIVERY_STATUS_ORDERED_WITH_SUPPLIER: 'Ordered with Supplier',
  DELIVERY_STATUS_RECEIVED: 'Received',
  DELIVERY_STATUS_SHIPPED: 'Shipped',
  DELIVERY_STATUS_UNKNOWN: '',
  DEPLOY: 'Deploy',
  DEPLOY_COLLECTORS: 'Deploy Collectors',
  DEPLOY_CONFIG: 'Deploy Config',
  DEPLOY_LIFECYCLE: 'Deploy Lifecycle',
  DEPLOY_LOCKED: 'Deploy Locked',
  DEPLOY_NEEDED: 'Deploy Needed',
  DEPLOY_TENANT: 'Deploy Tenant',
  DEPLOYED_TASKS: '<%=count%> Deployed tasks completed',
  DEPLOYMENT_NOT_REQUIRED: 'Deployment not required',
  DEPLOYMENT_REQUIRED: 'Deployment required',
  DEPLOYMENT_TYPE_COLLECTORS: 'Collectors Deployment',
  DEPLOYMENT_TYPE_MULTI_TENANT: 'MT Deployment',
  DEPLOYMENT_TYPE_SINGLE_TENANT: 'ST Deployment',
  DESCRIPTION: 'Description',
  DETAILS: 'details',
  DISABLE_TEMPLATING: 'Disable Templating',
  DNS_SERVERS: 'DNS Servers',
  DONE: 'Done',
  DUPLICATE_SELECTED_COMMAND: 'Duplicate selected command',
  EDIT_OWNER: 'Edit Owner',
  EDIT_STAGE: 'Edit Stage',
  EDITABLE_TEXT_INPUT_PLACEHOLDER: 'Enter value...',
  ENABLE_CONTINUOUS_DELIVERY_ST: 'Enable Continuous Delivery for ST',
  ENABLE_TEMPLATING: 'Enable Templating',
  END_HOUR: 'End Hour',
  ENTER_VALUE: 'Enter value...',
  ENTER_YOUR_NOTES: 'Enter your notes',
  ENV_MISMATCH: 'Environments Mismatch',
  ENV_MISMATCH_FOR_TENANTS_MESSAGE:
    'Cannot display tenant details for mismatching environments: The requested tenant is not on <%=currentOpsEnv%>',
  ENV_VARS: 'Environment Variables',
  ENVIRONMENT: 'Environment',
  ENVIRONMENTS: 'Environments',
  ERROR: 'Error',
  ERROR_APPROVE_PR: 'Approving pull request failed',
  ERROR_ARCHIVE_COMMAND: 'Could not archive command',
  ERROR_CANNOT_SHOW_TENANT_MENU: 'Could not show menu for requested tenant',
  ERROR_CANNOT_VALIDATE_EXISTING_COLLECTOR_NUMBER: 'Cannot validate collector number existence. Please try again',
  ERROR_COLLECTOR_NUMBER_TOO_LONG: 'Collector number cannot be more than <%=maxLength%> chars',
  ERROR_COULD_NOT_CREATE: 'Error: could not create tenant (status code <%=status%>) <%=errorMessage%>',
  ERROR_CREATING_CSV_MESSAGE: 'Error: could not create CSV',
  ERROR_DECLINE_PR: 'Declining pull request failed',
  ERROR_DESTROY: 'Could not destroy tenant. Make sure stage is valid for deletion',
  ERROR_EDIT_TABLE_CELL: 'Failed to update value',
  ERROR_EMPTY_COLLECTOR_NUMBER: 'Collector number cannot be empty',
  ERROR_EXISTING_COLLECTOR_NUMBER: 'Collector number already exists',
  ERROR_FETCHING_COMMAND_OUTPUT: 'Failed to fetch command output',
  ERROR_FETCHING_TASK_OUTPUT: 'Failed to fetch task output',
  ERROR_FORMAT_INTEGRATION_PARAMS: 'Failed to format integration params',
  ERROR_INVALID_SERVICE_CRON: 'Service cron expression must be a valid expression',
  ERROR_INVALID_SERVICE_DESCRIPTION: 'Service description must be longer then 3 characters',
  ERROR_INVALID_SERVICE_NAME:
    'Service name must be all lower case, between 2 to 40 characters and no special characters except "-"',
  ERROR_INVALID_SERVICE_OWNER: 'Service must have an owner',
  ERROR_INVALID_SERVICE_STACKS: 'Service must be attached to at least one stack',
  ERROR_INVALID_TENANT_NAME: 'Tenant name must be all lower case and between 2 to 24 characters (excluding the prefix)',
  ERROR_INVALID_VERSION_RANGE:
    'Version range must be in the following format ([><==]<release_type>-<major>.<minor>-S<sprint>.<micro>)',
  ERROR_LOADING_STAGES: 'Failed to load stages',
  ERROR_LOCK_VERSION: 'Updating tenant version lock failed',
  ERROR_MISSING_ENV: 'Please choose an environment',
  ERROR_MISSING_TENANT_NAME: 'Please choose a tenant name',
  ERROR_SELECT_ALL: 'Failed to select all hosts',
  ERROR_START_TENANT: 'Starting tenant failed',
  ERROR_STOP_TENANT: 'Stopping tenant failed',
  ERROR_TENANT_NAME_CONTAINS_INVALID_WORDS: 'Tenant name includes an invalid word',
  ERROR_UPDATE_CONTINUOUS_DELIVERY: 'Could not update Continuous delivery',
  ERROR_UPDATE_MONITORING: 'Updating tenant monitoring failed',
  ERROR_UPDATE_SA: 'Updating tenant SA failed',
  ERROR_UPDATE_STAGE: 'Updating tenant stage failed',
  ERROR_UPDATE_SUBSCRIPTION: 'Updating tenant subscription failed',
  ERROR_UPDATE_VIP: 'Updating tenant VIP status failed',
  ERROR_VALIDATE_PULL_REQUEST: 'Failed to validate pull request',
  EXECUTE: 'Execute',
  EXPAND: 'Expand',
  EXPAND_ALL: 'Expand All',
  EXPAND_SINGLES: 'Expand groups with 1 row',
  EXPORT_CSV: 'Export CSV',
  FAILED: 'Failed',
  FAILED_GET_ARTIFACTS: 'Failed to get artifacts',
  FAILED_GET_COMMANDS: 'Failed to get commands',
  FAILED_GET_PULL_REQUESTS: 'Failed to get pull requests',
  FAILED_GET_TENANTS: 'Failed to get tenants',
  FE_SERVER: 'Fe Server',
  FEATURE_BRANCH: 'Feature Branch',
  FIELD_ACTIONS: 'Field Actions',
  FILTER: 'Filter',
  FINISH: 'Finish',
  FIRMWARE_CONFIGURATION: 'Firmware Configuration',
  FIRMWARE_UPDATE: 'Firmware Upgrade',
  FIRMWARE_UPGRADE: 'Firmware Upgrade',
  FIRMWARE_VERSION: 'Firmware Version',
  FIRMWARE_VERSION_UPGRADE: 'Firmware Version Upgrade',
  FOUND_TENANTS_OUT_OF: '<%=foundTenants%> tenants out of <%=totalTenants%> total',
  FROM: 'From',
  FULL_VIEW: 'Full View',
  FW_UPGRADE_FAILED: 'FW Upgrade Failed',
  FW_UPGRADE_REQUIRED: 'FW Upgrade Required',
  FW_UPGRADE_RUNNING: 'FW Upgrade Running',
  FW_VERSION: 'FW Version',
  FW_VERSION_ID: 'FW Version ID',
  GENERAL: 'General',
  GET_KALI_IMAGE: 'Get Kali Linux Attack Simulator VM (Valid for 4 weeks)',
  GITHUB_AUTHENTICATION_FAILED: 'Unable to authenticate with GitHub. \nPlease contact the DevOps team.',
  GITHUB_AUTHENTICATION_LOADING: 'Authenticating GitHub Token...',
  HAS_NOTES: 'Has notes',
  HEALTH: 'Health',
  HEALTH_STATUS: 'Health Status',
  HELLO: 'Hello, <%=name%>',
  HELM: 'Helm',
  HELM_TASKS: 'Helm Tasks',
  HIGH: 'High',
  HISTORY: 'History',
  HOME: 'Home',
  HOSTS_SELECTION_STATUS: 'all <%=count%> are selected',
  ID: 'ID',
  IMAGES: 'Images',
  IN_PROGRESS: 'In Progress',
  IN_THE_LAST_WEEK: 'In the last week',
  INACTIVE: 'Inactive',
  INFO: 'Info',
  INHERIT: 'Inherit',
  INHERITANCE_LEVEL: 'Inheritance Level',
  INHERITED_FROM: 'Inherited From',
  INHERITS: 'Inherits',
  INSTANCE_TYPE: 'Instance Type',
  INTEGRATION: 'Integration',
  INTEGRATIONS: 'Integrations',
  INTEGRATIONS_DOWN: '<%=count%> with failures and warnings',
  INTEGRATIONS_UP: '<%=count%> integrations are up',
  IPMI_CONFIGURATION: 'IPMI Configuration',
  IPMI_DEFAULT_GW: 'IPMI Default Gateway',
  IPMI_IP_ADDRESS: 'IPMI IP Address',
  IPMI_NETMASK: 'IPMI Netmask',
  IS: 'is',
  IS_NOT: 'not',
  IS_NOT_PART_OF_THIS_ENVIRONMENT: 'is not part of this environment',
  IS_ONE_OF: 'is one of',
  JIRA_TICKET: 'Jira Ticket',
  KEY: 'Key',
  KEY_CATEGORY: 'Category',
  KEY_IS_NOT_VALUE: "<%=key%> isn't <%=value%>",
  KEY_IS_VALUE: '<%=key%> is <%=value%>',
  KEY_MATCHES_REGEX: '<%=key%> matches <%=value%>',
  KEY_SUB_CATEGORY: 'Sub Category',
  LAST_ACTIVITY: 'Last Activity',
  LAST_DEPLOYMENT: 'Last Deployment',
  LAST_EDITED_ON: 'Last edited on <%=date%> by <%=user%>',
  LAST_SEEN: 'Last Seen',
  LAST_SUCCESSFUL_SNAPSHOT: 'Last Successful Snapshot',
  LAST_UPDATE: 'Last Update',
  LAST_UPDATE_BY: 'Last Update By',
  LAST_UPDATED: 'Last updated',
  LAST_WEEK: 'Last Week',
  LEGEND: 'Legend',
  LOAD_MORE_CONTENT: 'Load more content...',
  LOADING: 'Loading...',
  LOCK: 'Lock',
  LOG_TYPES: 'Log Types',
  LOGOUT: 'Logout',
  LOGS: 'Logs',
  LOW: 'Low',
  LTE_CONFIGURATION: 'LTE Configuration',
  MAJOR_RELEASES: 'Major Releases',
  MARK_AS_OPERATIONAL: 'Mark as operational',
  MASTER: 'Master',
  MEDIUM: 'Medium',
  MISSING_TICKET_ID: 'Ticket ID Missing',
  MODIFICATION_DATE: 'Modification Date',
  MONITORING_DOWN: 'Monitoring is disabled',
  MONITORING_UP: 'Monitoring is enabled',
  MORE: 'More',
  MT_APP_DEPLOYMENT: 'MT App Deployment',
  NA: 'N/A',
  NAME: 'Name',
  NEVER: 'Never',
  NEW_RELEASE: 'New Release',
  NEW_RELEASE_CANDIDATE: 'New Release Candidate',
  NEW_RELEASE_MAJOR: 'New Major Release',
  NEW_RELEASE_MINOR: 'New Minor Release (CP)',
  NEW_TASK: 'New Task',
  NEW_TENANT: 'New Tenant',
  NO: 'No',
  NO_DESCRIPTION: 'No Description',
  NO_PARAMETERS_MESSAGE: 'No parameters were found for this command, please close this dialog',
  NODE_INFO: 'Node info',
  NONE: 'None',
  NOTES: 'Notes',
  NOTES_AND_COMMENTS: 'Notes & Comments',
  NOTIFICATIONS: 'notifications',
  NTP_SERVERS: 'NTP Servers',
  NUMBER: 'Number',
  OF: 'Of',
  OFFLINE: 'Offline',
  OFFLINE_COLLECTORS: 'Offline Collectors',
  ONLINE: 'Online',
  ONLINE_COLLECTORS: 'Online Collectors',
  ONLY: 'Only',
  OPEN_CONSOLE: 'Open console',
  OPEN_DATA_FLOW: 'Open Data Flow',
  OPEN_GRAFANA: 'Open Grafana',
  OPERATIONAL: 'Operational',
  OPS_INITIALIZING: 'Initializing',
  OPTIONAL: 'Optional',
  OR: 'or',
  OTHER_MORE: 'Other (<%=count%> more)',
  OUTPUT: 'Output',
  PAGE_NOT_FOUND: 'PAGE NOT FOUND',
  PARAMETERS: 'Parameters',
  PARENT_FAILED: 'Parent Failed',
  PASTE_VALUES_FROM_CLIPBOARD: 'Paste Values From Clipboard',
  PATCHES: 'Patches',
  PAUSED: 'Paused',
  PENDING: 'Pending',
  PIPELINE: 'Pipeline',
  POSTGRES: 'Postgres',
  PR: 'PR',
  PR_ID: 'PR ID',
  PRE_PROVISIONED: 'Pre-Provisioned',
  PRIORITY: 'Priority',
  PROGRESS_STATUS: 'Progress Status',
  PROMETHEUS_MONITOR: 'Enable Prometheus Metrics',
  PROVISION: 'Provision',
  PROVISION_FAILED: 'Provision Failed',
  PROVISION_PARAMS_CONFIGURATION: 'Provision Configuration',
  PROVISIONED: 'Provisioned',
  PROVISIONING: 'Provisioning',
  QA_SPRINT: 'QA Sprint',
  RDS_NAME: 'RDS Name',
  REASON: 'Reason',
  RECENT_SEARCH: 'Recent Searches',
  REFRESH: 'refresh',
  REFRESH_TABLE: 'Refresh',
  REGRESSION: 'Regression',
  REGULAR: 'Regular',
  RELEASE: 'Release',
  RELEASE_APPROVE_DESCRIPTION: 'This will approve the PR and merge it into the release',
  RELEASE_CANDIDATE: 'Release Candidate',
  RELEASE_CANDIDATES: 'Release Candidates',
  RELEASE_DECLINE_DESCRIPTION: 'This will decline the PR and close it',
  RELEASE_FORM_DEPLOYMENTS_DESCRIPTION: 'Select the deployment type and the environments.',
  RELEASE_FORM_DEPLOYMENTS_LABEL: 'Select Deployment Type',
  RELEASE_FORM_LATEST_RC: 'Latest RC',
  RELEASE_FORM_MAJOR_TARGETS_DESCRIPTION: 'The versions that will be deployed to.',
  RELEASE_FORM_MAJOR_TARGETS_LABEL: 'Select Major Targets',
  RELEASE_FORM_NEW_RC_NAME: 'New RC Name',
  RELEASE_FORM_PULL_REQUESTS_DESCRIPTION:
    'At least one pull request is mandatory. Can continue only if all pull requests are valid.',
  RELEASE_FORM_PULL_REQUESTS_LABEL: 'Select Pull Requests',
  RELEASE_FORM_REASON_DESCRIPTION: 'Why this release is needed.',
  RELEASE_FORM_REASON_LABEL: 'Enter Reason',
  RELEASE_FORM_SOLVE_CONFLICTS: 'Please solve the conflicts, and follow the instructions to cherry-pick manually',
  RELEASE_FORM_URGENCY_DESCRIPTION:
    'High - Close production version immediately\n Medium - Close version tomorrow morning\n Low - No urgency\n Regression - Part of issues found in regression testing',
  RELEASE_FORM_URGENCY_LABEL: 'Urgency',
  RELEASE_ID: 'Release id',
  RELEASE_NAME: 'Release Name',
  RELEASE_REQUEST: 'Release Request',
  RELEASES: 'Releases',
  REQUESTED_BY: 'Requested By',
  REQUIRED: 'Required',
  REROUTE: 'Reroute',
  REROUTE_COLLECTOR: 'Reroute Collector',
  RESTORE_COLUMNS_VIEW: 'Restore Columns View',
  RESULTS: 'Results: <%=count%>',
  RMQ_RELAY: 'RMQ relay',
  RMQ_RELAY_FROM: 'Current RMQ Relay from: <%=relayInfo%>',
  RMQ_RELAY_INCOMING_DATA: 'Incoming data from',
  RMQ_RELAY_INFO: 'RMQ Relay Info',
  RMQ_RELAY_OUTGOING_DATA: 'Outgoing data to',
  RMQ_RELAY_STOP_SUCCESS: 'RMQ Relay stopped successfully',
  ROLLBACK: 'Rollback',
  RUN_COMMANDS: 'Run Commands',
  RUN_REPLAY: 'Run Replay',
  RUNNING: 'Running',
  SA: 'SA',
  SALESFORCE_ACCOUNT_TYPE_CUSTOMER: 'Customer',
  SALESFORCE_ACCOUNT_TYPE_DISTRIBUTOR: 'Distributor',
  SALESFORCE_ACCOUNT_TYPE_FORMER_CUSTOMER: 'Former Customer',
  SALESFORCE_ACCOUNT_TYPE_OTHER: 'Other',
  SALESFORCE_ACCOUNT_TYPE_PAID_POV: 'Paid PoV',
  SALESFORCE_ACCOUNT_TYPE_PARTNER: 'Partner',
  SALESFORCE_ACCOUNT_TYPE_PROSPECT: 'Prospect',
  SALESFORCE_ACCOUNT_TYPE_UNKNOWN: 'Unknown',
  SAVE_SEARCH: 'Save Search',
  SAVED_SEARCH: 'Saved Search',
  SEARCH: 'Search',
  SEARCH_CONFIGURATION_KEY: 'Search Key',
  SEARCH_FOR_COMMANDS: 'Search for commands ...',
  SEARCH_FOR_TENANTS: 'Search for tenants...',
  SEARCH_FOR_VERSIONS: 'Search for versions ...',
  SEARCH_WITH_DOTS: 'Search...',
  SECRETS: 'Secrets',
  SECRETS_CONFIGURATION: 'Secrets',
  SELECT_ALL_REMOTE_HOSTS: 'Select All <%=hostsCount%> <%=hostLabel%>',
  SELECT_ALL_WITH_TOTAL: 'Select all <%=total%>',
  SELECT_BRANCH_TYPE: 'Select Branch Type',
  SELECT_COMMAND_TO_RUN: 'Select Command to Run',
  SELECT_SOURCE_TENANT_FOR_RELAY: 'Select source tenant for relay',
  SELECT_VERSION_FOR_DEPLOYMENT: 'Select Version For Deployment',
  SELECTED: 'Selected',
  SERVER_CONNECTION: 'Connection to tenant server',
  SERVICE_DESCRIPTION: 'Description',
  SERVICE_NAME: 'Name',
  SET_AS_DEFAULT_SEARCH: 'Change to my default search',
  SET_AS_REGULAR: 'Set as Regular',
  SET_AS_VIP: 'Set as VIP',
  SETTINGS: 'Settings',
  SHELL_COMMAND: 'Shell Command',
  SHOW_EMPTY: 'Show Empty',
  SHOW_LOGS: 'Show Logs',
  SHOW_SUCCESSORS_AND_PREDECESSORS: 'Show successors and predecessors',
  SHOW_VERSIONS: 'Show Versions',
  SHRAGA: 'Shraga - deprecated',
  SIZE: 'Size',
  SLIDING_WINDOW_SIZE: 'Sliding Window Size',
  SOURCE_PR_IDS: 'Source PR IDs',
  SPRINT_MUST_BE_A_WHOLE_NUMBER: 'Sprint must be a whole number',
  ST_DEPLOYMENT_FULL: 'Full ST Deployment',
  ST_DEPLOYMENT_PARTIAL: 'Partial ST Deployment (Squad)',
  STAGE: 'Stage',
  START_HOUR: 'Start Hour',
  STARTED: 'Started',
  STATIC_GATEWAY_ADDRESS: 'Static Gateway Address',
  STATIC_IP_ADDRESS: 'Static IP Address',
  STATIC_NETMASK_ADDRESS: 'Static Netmask Address',
  STATUS: 'Status',
  STOP_AND_CREATE_RMQ_RELAY: 'Stop and Create New RMQ Relay',
  STOP_LOG_STREAMING: 'Stop Log Streaming',
  STOP_RMQ_RELAY: 'Stop RMQ Relay',
  SUB_CATEGORY: 'Sub Category',
  SUCCESS: 'Success',
  SUCCESS_MESSAGE_EDIT_TABLE_CELL: 'Value updated successfully',
  SYSTEM: 'System',
  TASK: 'Task',
  TASK_ACTIVITY_UNKNOWN: 'No Status',
  TASK_ID: 'Task Id',
  TASK_STATUS: 'Task Status',
  TASK_TYPE: 'Task Type',
  TASKS: 'Tasks',
  TASKS_HISTORY: 'Tasks History',
  TEMPLATE: 'Template',
  TENANT: 'Tenant',
  TENANT_CLONE_EXISTING_TENANT: 'Clone Existing Tenant',
  TENANT_COPY_APPLICATION_CONFIG: 'Copy application config',
  TENANT_COPY_DATA: 'Load initial data from',
  TENANT_COPY_FF: 'Copy Feature Flags from',
  TENANT_DELETION_TITLE: 'Tenant deletion',
  TENANT_DETAILS: 'Tenant Details',
  TENANT_ID: 'Tenant ID: <%=tenantId%>',
  TENANT_INDUSTRY: 'Industry',
  TENANT_INDUSTRY_NAICS: 'NAICS Industry',
  TENANT_INSERT_NAME: 'Please insert tenant name',
  TENANT_IS_PATCHED: 'Tenant is Patched',
  TENANT_LIKE: 'I want tenant like',
  TENANT_MONITORING: 'Monitoring',
  TENANT_MULTI_TENANT: 'Multi Tenant',
  TENANT_NAME: 'Tenant Name',
  TENANT_PATCHED: 'Tenant Patched',
  TENANT_RELAY_STOPPED: 'Tenant Relay Stopped',
  TENANT_SAME_SIZE: 'Make my tenant as the same size as',
  TENANT_SERVER_IS_DOWN: 'Tenant server is down',
  TENANT_SERVER_IS_UP: 'Tenant server is up',
  TENANT_STATUS: 'Tenant Status',
  TENANT_STEAM_DATA: 'Stream Data from',
  TENANT_STOP_TITLE: 'Tenant Stop',
  TENANT_TIER: 'Multi-tenant Namespace',
  TENANT_VERSION: "Tenant's Version",
  TENANTS: 'Tenants',
  TERRAFORM: 'Terraform',
  TERRAFORM_APPLY: 'Terraform Apply',
  TERRAFORM_CONFIGURATION: 'Terraform Configuration',
  TERRAFORM_PLAN: 'Terraform Plan',
  TERRAFORM_STATUS: 'Terraform Status',
  TEST: 'Test',
  TF_STATUS_HARD_DRIFT: 'Hard Drift',
  TF_STATUS_PLAN_FAILED: 'Plan Failed',
  TF_STATUS_SOFT_DRIFT: 'Soft Drift',
  TF_STATUS_TAG_DRIFT: 'Tag Drift',
  TF_STATUS_UNKNOWN: 'Unknown',
  TF_STATUS_UP_TO_DATE: 'Up To Date',
  TITLE: 'title',
  TMS_CONFIGURATION: 'TMS Configuration',
  TRIGGERED_BY: 'Triggered By',
  TYPE: 'Type',
  UNINSTALL: 'Uninstall',
  UNLOCK: 'Unlock',
  UP_TO_DATE: 'Up To Date',
  UPDATE_CONFIGURATION: 'Change <%=key%> from <%=from%> to <%=to%>',
  UPGRADE_REQUIRED: 'Upgrade Required',
  URGENCY: 'Urgency',
  USE_EXISTING_MT_NAMESPACE: 'Use Existing MT Namespace',
  USE_KAFKA: 'Use Kafka',
  USE_NEW_MT_NAMESPACE: 'Create New MT Namespace',
  USE_REDIS: 'Use Redis',
  USER_CHANGES: 'User Changes',
  USER_CHANGES_DETECTED: '<%=count%> User changes detected',
  VALUE: 'Value',
  VERSION: 'Version',
  VERSION_MAJOR: 'Major Version',
  VERSION_RANGE: 'Version Range',
  VERSION_RANGE_TOOLTIP:
    'Please provide a valid range ([><==]<release_type>-<major>.<minor>-S<sprint>.<micro>), ' +
    'for example: ">=R-22.2-S154.32" OR "<R-22.2-S151.35" OR ">R-22.2-S154.32" OR "==R-22.2-S154.32"',
  VERSIONS: 'Versions',
  VIEW_LOG: 'View log',
  VIEW_POD_LOG: 'View Pod log',
  VIP: 'VIP',
  WAITING: 'Waiting…',
  WAITING_FOR_PARENT: 'Waiting for parent',
  WIFI_CONFIGURATION: 'Wifi Configuration',
  WIFI_NAME: 'Wifi Name',
  WIFI_PASSWORD: 'Wifi Password',
  WORKER_ANSIBLE: 'Worker Ansible',
  WORKER_COMMAND: 'Worker Command',
  YES: 'Yes',
  ...demo,
  ...rbac,
  ...services,
  ...tenantStage
};
