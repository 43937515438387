import _ from 'lodash';

import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import services from '../../../services';
import useMenuItem from '../../AGTable/hooks/useMenuItem';
import roles from '../../../roles';

export default function useStartMenuItem(onChange = _.noop) {
  const notifications = context.Notifications.useNotifications();

  async function onConfirm({tenantId}) {
    try {
      await services.tenants.start(tenantId);
      onChange();
    } catch (error) {
      notifications.error(langs('ERROR_START_TENANT'));
    }
  }

  const user = context.User.useUser();
  return useMenuItem(
    onConfirm,
    langs('BUTTON_START'),
    'outline_play_arrow_white_full_24dp',
    user.rbac.maestro.isEnabled() ? consts.rbac.permissions.maestro.tenant.start : roles.MANAGE_TENANTS,
    user.rbac.maestro
  );
}
