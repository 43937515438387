import _ from 'lodash';

import consts from '../../../consts';
import langs from '../../../localization/langs';
import models from '../../../models';
import modelToQuery from '../../../utils/agGridUtils/filterUtils/modelToQuery';
import queryUtils from '../../../utils/agGridUtils/queryUtils';
import services from '../../../services/services';

import {DEFAULT_CONTEXT_MENU} from '../../../components/AGTable/Table';

const DIALOG = {
  CONFIGURATION: 'CONFIGURATION',
  DEPLOY: 'DEPLOY',
  HELM: {
    DEPLOY: 'HELM_DEPLOY',
    DIFF: 'HELM_DIFF',
    HISTORY: 'HELM_HISTORY',
    ROLLBACK: 'HELM_ROLLBACK',
    TEMPLATE: 'HELM_TEMPLATE',
    UNINSTALL: 'HELM_UNINSTALL'
  },
  LOGS: 'LOGS',
  REPLICATE: 'REPLICATE',
  RESTART: 'RESTART',
  STOP: 'STOP'
};

const LEVEL_TO_NAME = {
  ENV: 'environmentName',
  NAMESPACE: 'namespaceName',
  SERVICE: 'serviceName',
  STACK: 'stackName'
};

const GROUP_KEY_TO_PERMISSIONS_GROUP = {
  [models.deployments.fields.ENVIRONMENT_NAME]: consts.rbac.permissions.mtms.envs,
  [models.deployments.fields.NAMESPACE_NAME]: consts.rbac.permissions.mtms.namespaces,
  [models.deployments.fields.SERVICE_NAME]: consts.rbac.permissions.mtms.services,
  [models.deployments.fields.STACK_NAME]: consts.rbac.permissions.mtms.stacks
};

function getContextMenuItems(
  params,
  openDialog,
  groupByKey,
  rbacControlObj,
  isMultipleSelection,
  isServiceContext,
  history,
  getUrl,
  getContextMenuTasks
) {
  const env = _.get(params, ['node', 'data', models.deployments.fields.ENVIRONMENT_NAME]);
  const permissions = _.get(GROUP_KEY_TO_PERMISSIONS_GROUP, groupByKey, {});
  const row = params?.node?.data;
  return _.compact(
    _.concat(
      [
        ...getContextMenuTasks(row),
        !isMultipleSelection
          ? {
              action: async () => openDialog(row, DIALOG.RESTART),
              disabled: !rbacControlObj.mtms.hasPermission(permissions.restart, env),
              name: langs('DEPLOYMENTS_ACTIONS_RESTART')
            }
          : null,
        isServiceContext && !isMultipleSelection
          ? {
              action: async () => redirectToConfiguration(history, getUrl, row),
              disabled: !rbacControlObj.opsWeb.hasPermission(
                consts.rbac.permissions.opsWeb.view.multiTenant.config,
                env
              ),
              name: langs('CONFIGURE')
            }
          : null,
        !isServiceContext && !isMultipleSelection
          ? {
              action: async () => openDialog(row, DIALOG.CONFIGURATION),
              disabled: !rbacControlObj.mtms.hasPermission(
                consts.rbac.permissions.mtms.configuration.create.categories.appConfig,
                env
              ),
              name: `${langs('CONFIGURE_ADD', {level: _.capitalize(_.replace(groupByKey, 'Name', ''))})}`
            }
          : null,
        isServiceContext && !isMultipleSelection
          ? {
              action: async () => openDialog(row, DIALOG.LOGS),
              disabled: !rbacControlObj.mtms.hasPermission(permissions.showLogs, env),
              name: langs('SHOW_LOGS')
            }
          : null,
        isServiceContext && !isMultipleSelection
          ? {
              action: async () => copyServiceHost(row),
              name: langs('COPY_SERVICE_HOST')
            }
          : null,
        'separator'
      ],
      DEFAULT_CONTEXT_MENU
    )
  );
}

async function copyServiceHost(row) {
  const service_host = `https://${row.serviceName}-${row.namespaceName}.${row.environmentName}.k8s.armis.com`;
  await navigator.clipboard.writeText(service_host);
}

async function redirectToConfiguration(history, getUrl, row) {
  const url = getUrl('services.configuration', null, {
    environmentId: row.environmentId,
    namespaceId: row.namespaceId,
    serviceId: row.serviceId
  });
  history.push(url);
}

async function getDataCount(urlParams, groupBy) {
  urlParams.set('groupBy', _.join(groupBy, ','));
  let items = [];
  try {
    items = (await services.deployments.count(urlParams)).data.items;
  } catch (error) {
    console.log(error);
  }
  return items;
}

async function getHeadChartsData(field, queryString) {
  const urlParams = new URLSearchParams(queryString);
  let items = await getDataCount(urlParams, [field]);
  return {
    data: _.map(items, ({value: count, key: [name]}) => ({count, name}))
  };
}

async function getRowData(urlParams, groupBy) {
  let items = await getDataCount(urlParams, groupBy);
  items = _.map(items, ({value: count, key}) => {
    const item = {count, searchParams: new URLSearchParams('length=-1')};
    return _.transform(
      groupBy,
      (res, groupByKey, index) => {
        const value = key[index];
        res.searchParams.set(groupByKey, value);
        res[groupByKey] = value;
      },
      item
    );
  });

  return {count: _.size(items), items};
}

async function getDataList(urlParams) {
  try {
    const {data} = await services.deployments.list(urlParams);
    return data;
  } catch (e) {
    console.error(e);
  }
}

async function getRows(params) {
  const groupBy = params.api.groupBy;
  const urlParams = queryUtils.getUrlSearchParams(params.api, params.request, 'serviceName:asc');
  let data;
  if (_.last(groupBy) === models.deployments.fields.SERVICE_NAME) {
    data = await getDataList(urlParams);
  } else {
    data = await getRowData(urlParams, groupBy);
  }
  params.success({rowCount: _.toNumber(data.count), rowData: data.items});
}

async function getFilterData(colDef) {
  const filterModel = colDef.gridApi.getFilterModel();
  const filterSearchQuery = modelToQuery.getQuery(colDef.gridApi, _.omit(filterModel, colDef.colId));
  const searchParams = new URLSearchParams(filterSearchQuery);
  searchParams.set('groupBy', colDef.colId);
  const {
    data: {items}
  } = await services.deployments.count(searchParams);
  return _.map(items, ({key: [name]}) => name);
}

function updateFilters(gridApi) {
  const filterModel = gridApi.getFilterModel();
  _.forEach(gridApi.getColumnDefs(), async ({colId, filter}) => {
    if (!filter) {
      return;
    }
    if (filterModel[colId]) {
      return;
    }
    const filterInstance = gridApi.getFilterInstance(colId);
    if (filterInstance.getFilterType() !== 'set') {
      return;
    }
    filterInstance.refreshFilterValues();
  });
}

async function getAffectedEntities(levelValueArray) {
  const levelQuery = _.reduce(
    levelValueArray,
    (acc, levelData) => {
      const levelQuery = `${LEVEL_TO_NAME[levelData.level]}[eq]=${levelData.name}&`;
      return acc + levelQuery;
    },
    ''
  );
  const query = `${levelQuery}length=-1`;
  const response = await services.deployments.list(query);
  return response.data.items;
}

export default {
  DIALOG,
  getAffectedEntities,
  getContextMenuItems,
  getFilterData,
  getHeadChartsData,
  getRows,
  updateFilters
};
