import React from 'react';
import {useLocation} from 'react-router-dom';

import hooks from './hooks';
import services from './services';

const tenantsRouteServicesByMode = {
  compact: services.collectors.getTenantsFromAllEnv
};

function withTenants(Component, tenantsRouteMode) {
  return function ComponentWithTenants(props) {
    const {pathname} = useLocation();
    const tenantsService = getTenantsService(pathname, tenantsRouteMode);
    const [tenantsResponse, isLoading, error, reloadTenants] = hooks.useGetResult(tenantsService, []);

    const tenants = extractTenants(tenantsResponse);

    return (
      <Component
        tenants={tenants}
        isLoadingTenants={isLoading}
        errorLoadingTenants={error}
        reloadTenants={reloadTenants}
        {...props}
      />
    );
  };
}

function getTenantsService(pathname, tenantsRouteMode) {
  if (pathname.includes('collectors') || pathname.includes('configuration')) {
    return services.collectors.getCmsTenants;
  }

  return tenantsRouteServicesByMode[tenantsRouteMode];
}

function extractTenants(tenantsResponse) {
  if (tenantsResponse && tenantsResponse.items) {
    return tenantsResponse.items;
  }

  if (tenantsResponse && tenantsResponse.tenants) {
    return tenantsResponse.tenants;
  }

  return [];
}

export default withTenants;
