import styled from 'styled-components';

import ConfigurationInput from '../../../Base/ConfigurationInput';
import IPAddressInput from '../../../Base/IPAddressInput';

export const NetworkConfigurationInput = styled(ConfigurationInput)`
  margin: 4px 0;
  padding: 1px;
`;

export const NetworkIPAddressInput = styled(IPAddressInput)`
  margin: 4px 0;
  padding: 1px;
`;
