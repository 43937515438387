import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {Grid, LinearProgress} from '@material-ui/core';

import langs from '../../../../localization/langs';

import NetworkConfigurationInputGroup from './NetworkConfigurationInputGroup';

import * as Styled from './NetworkConfigurationForm.styles';

const FIELDS = {
  APN_NAME: 'apnName',
  APN_PASSWORD: 'apnPassword',
  APN_USER: 'apnUser',
  CLUSTER_MEMBER: 'clusterMember',
  DNS: 'dnsNameservers',
  GW: 'staticIpGateway',
  IP: 'staticIpAddress',
  IPMI_GATEWAY: 'ipmiDefaultGw',
  IPMI_IP: 'ipmiIpAddress',
  IPMI_NETMASK: 'ipmiNetmask',
  NETMASK: 'staticIpNetmask',
  NTP: 'ntpServers',
  WIFI_NAME: 'wifiName',
  WIFI_PASSWORD: 'wifiPassword'
};

function NetworkConfigurationForm({
  collectorNumberConfig,
  ipmiConfiguration,
  networkConfiguration,
  wifiConfiguration,
  lteConfiguration,
  setCollectorNumber,
  setNetworkConfiguration,
  setIpmiConfiguration,
  setWifiConfiguration,
  setLteConfiguration,
  clusterConfiguration,
  setClusterConfiguration
}) {
  const {number: collectorNumber, errorMessage, isLoading} = collectorNumberConfig;

  function networkGetter(name) {
    const value = _.get(networkConfiguration, name, '');

    return _.isArray(value) ? _.join(value, ',') : value;
  }

  function networkSetter(name, isArray = false) {
    return (value) => setNetworkConfiguration({...networkConfiguration, [name]: isArray ? value.split(',') : value});
  }

  function ipmiGetter(name) {
    return _.get(ipmiConfiguration, name, '');
  }

  function ipmiSetter(name) {
    return (value) => setIpmiConfiguration({...ipmiConfiguration, [name]: value});
  }

  function wifiGetter(name) {
    return _.get(wifiConfiguration, name, '');
  }

  function wifiSetter(name) {
    return (value) => setWifiConfiguration({...wifiConfiguration, [name]: value});
  }

  function lteGetter(name) {
    return _.get(lteConfiguration, name, '');
  }

  function lteSetter(name) {
    return (value) => setLteConfiguration({...lteConfiguration, [name]: value});
  }

  function clusterGetter(name) {
    return _.get(clusterConfiguration, name, '');
  }

  function clusterSetter(name) {
    return (value) => setClusterConfiguration({...clusterConfiguration, [name]: value});
  }

  async function pasteValuesFromClipboard() {
    const clipboardText = await navigator.clipboard.readText();
    let collectorNumberFromClipboard, dns, ntp;
    const clipboardNetworkConfig = {};
    [
      collectorNumberFromClipboard,
      clipboardNetworkConfig[FIELDS.IP],
      clipboardNetworkConfig[FIELDS.NETMASK],
      clipboardNetworkConfig[FIELDS.GW],
      dns,
      ntp
    ] = clipboardText.split('\t');
    if (collectorNumberFromClipboard) {
      setCollectorNumber(collectorNumberFromClipboard);
    }
    if (dns) {
      clipboardNetworkConfig[FIELDS.DNS] = _.replace(dns, /['"]/g, '').split(' ');
    }
    if (ntp) {
      clipboardNetworkConfig[FIELDS.NTP] = _.replace(ntp, /['"]/g, '').split(' ');
    }
    setNetworkConfiguration(clipboardNetworkConfig);
  }

  const inputCollectorConfigurations = [
    {
      defaultOpen: true,
      inputs: [
        {
          onChange: setCollectorNumber,
          title: 'COLLECTOR_NUMBER',
          type: 'collectorNumber',
          validationFunc: (
            <>
              {isLoading && <LinearProgress />}
              {!_.isEmpty(errorMessage) && (
                <Styled.CollectorNumberErrorMessage>{errorMessage}</Styled.CollectorNumberErrorMessage>
              )}
            </>
          ),
          value: collectorNumber?.toString() ?? ''
        },
        {
          onChange: networkSetter(FIELDS.IP),
          title: 'STATIC_IP_ADDRESS',
          type: 'ipAddress',
          value: networkGetter(FIELDS.IP)
        },
        {
          onChange: networkSetter(FIELDS.NETMASK),
          title: 'STATIC_NETMASK_ADDRESS',
          type: 'ipAddress',
          value: networkGetter(FIELDS.NETMASK)
        },
        {
          onChange: networkSetter(FIELDS.GW),
          title: 'STATIC_GATEWAY_ADDRESS',
          type: 'ipAddress',
          value: networkGetter(FIELDS.GW)
        },
        {
          onChange: networkSetter(FIELDS.DNS, true),
          title: 'DNS_SERVERS',
          type: 'ipAddress',
          value: networkGetter(FIELDS.DNS)
        },
        {
          onChange: networkSetter(FIELDS.NTP, true),
          title: 'NTP_SERVERS',
          type: 'generalConfiguration',
          value: networkGetter(FIELDS.NTP)
        }
      ],
      title: 'COLLECTOR_CONFIGURATION'
    },
    {
      inputs: [
        {
          onChange: ipmiSetter(FIELDS.IPMI_IP),
          title: 'IPMI_IP_ADDRESS',
          type: 'ipAddress',
          value: ipmiGetter(FIELDS.IPMI_IP)
        },
        {
          onChange: ipmiSetter(FIELDS.IPMI_NETMASK),
          title: 'IPMI_NETMASK',
          type: 'ipAddress',
          value: ipmiGetter(FIELDS.IPMI_NETMASK)
        },
        {
          onChange: ipmiSetter(FIELDS.IPMI_GATEWAY),
          title: 'IPMI_DEFAULT_GW',
          type: 'ipAddress',
          value: ipmiGetter(FIELDS.IPMI_GATEWAY)
        }
      ],
      title: 'IPMI_CONFIGURATION'
    },
    {
      inputs: [
        {
          onChange: wifiSetter(FIELDS.WIFI_NAME),
          title: 'WIFI_NAME',
          type: 'generalConfiguration',
          value: wifiGetter(FIELDS.WIFI_NAME)
        },
        {
          onChange: wifiSetter(FIELDS.WIFI_PASSWORD),
          title: 'WIFI_PASSWORD',
          type: 'generalConfiguration',
          value: wifiGetter(FIELDS.WIFI_PASSWORD)
        }
      ],
      title: 'WIFI_CONFIGURATION'
    },
    {
      inputs: [
        {
          onChange: lteSetter(FIELDS.APN_NAME),
          title: 'APN_NAME',
          type: 'generalConfiguration',
          value: lteGetter(FIELDS.APN_NAME)
        },
        {
          onChange: lteSetter(FIELDS.APN_USER),
          title: 'APN_USER',
          type: 'generalConfiguration',
          value: lteGetter(FIELDS.APN_USER)
        },
        {
          onChange: lteSetter(FIELDS.APN_PASSWORD),
          title: 'APN_PASSWORD',
          type: 'generalConfiguration',
          value: lteGetter(FIELDS.APN_PASSWORD)
        }
      ],
      title: 'LTE_CONFIGURATION'
    },
    {
      inputs: [
        {
          onChange: clusterSetter(FIELDS.CLUSTER_MEMBER),
          title: 'CLUSTER_MEMBER',
          type: 'collectorNumber',
          value: clusterGetter(FIELDS.CLUSTER_MEMBER)
        }
      ],
      title: 'CLUSTER_CONFIGURATION'
    }
  ];

  return (
    <>
      <Grid container>
        <Styled.PasteValuesButton size="small" color="secondary" onClick={pasteValuesFromClipboard}>
          {langs('PASTE_VALUES_FROM_CLIPBOARD')}
        </Styled.PasteValuesButton>
      </Grid>
      {inputCollectorConfigurations.map((settings) => (
        <NetworkConfigurationInputGroup {...settings} />
      ))}
    </>
  );
}

NetworkConfigurationForm.propTypes = {
  clusterConfiguration: PropTypes.object,
  collectorNumberConfig: PropTypes.shape({
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    number: PropTypes.string
  }).isRequired,
  ipmiConfiguration: PropTypes.object,
  lteConfiguration: PropTypes.object,
  networkConfiguration: PropTypes.object,
  setClusterConfiguration: PropTypes.func.isRequired,
  setCollectorNumber: PropTypes.func.isRequired,
  setIpmiConfiguration: PropTypes.func.isRequired,
  setLteConfiguration: PropTypes.func.isRequired,
  setNetworkConfiguration: PropTypes.func.isRequired,
  setWifiConfiguration: PropTypes.func.isRequired,
  wifiConfiguration: PropTypes.object
};

export default NetworkConfigurationForm;
