import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './Input.styles';

function Input({...props}) {
  return <Styled.Input {...props} />;
}

Input.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password']).isRequired,
  value: PropTypes.string
};

export default Input;
