import {Link} from 'react-router-dom';
import styled from 'styled-components';

import * as theme from '../../../theme';

export const Container = styled(Link)`
  height: 34px;
  width: 84px;
  display: flex;
  position: relative;
`;

export const Logo = styled.img`
  width: 76px;
  margin-left: -16px;
`;

export const Title = styled.span`
  position: absolute;
  bottom: 1px;
  right: 4px;
  font-size: 9px;
  color: ${theme.TopPanel.text};
  user-select: none;
`;
