import React from 'react';

import LinkInput from '../components/Images/LinkInput';

import langs from '../localization/langs';
import services from '../services';

import * as Styled from './Images.styles';

function Images() {
  const [imageUrls, setImageUrls] = React.useState({});

  function setImageUrl(imageType, imageUrl) {
    setImageUrls({...imageUrls, [imageType]: imageUrl});
  }

  React.useEffect(function fetchImageUrls() {
    services.collectors.images.getKaliImage().then(({data}) => setImageUrl('kali', data.imageUrl));
  }, []);

  return (
    <Styled.Container>
      <Styled.Title>{langs('GET_KALI_IMAGE')}</Styled.Title>
      <Styled.Form>
        <LinkInput value={imageUrls.kali || langs('LOADING')} />
      </Styled.Form>
    </Styled.Container>
  );
}

export default Images;
