const CLUSTER_PREFIXES = {
  DEMO: 'demo',
  DEV: 'dev',
  PROD: 'prod',
  QA: 'qa'
};

export default {
  CLUSTER_PREFIXES
};
