import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../localization/langs';
import services from '../../../services';
import task from '../../../models/task';

import * as Styled from './Task.styles';

function ActionCell({taskStatus, isActive, taskId, onChange}) {
  async function pauseTask(event) {
    event.stopPropagation();
    services.tasks.pauseTask(taskId).then(onChange);
  }

  async function continueTask(event) {
    event.stopPropagation();
    services.tasks.continueTask(taskId).then(onChange);
  }

  return (
    <>
      <Styled.Title>
        {langs('TASK')}: {taskId}
      </Styled.Title>
      {isActive &&
      [task.taskStatus.RUNNING, task.taskStatus.PENDING, task.taskStatus.WAITING_FOR_PARENT].includes(taskStatus) ? (
        <Styled.IconButton onClick={pauseTask}>
          <Styled.PauseIcon />
        </Styled.IconButton>
      ) : isActive && taskStatus === task.taskStatus.PAUSED ? (
        <Styled.IconButton onClick={continueTask}>
          <Styled.PlayArrowIcon />
        </Styled.IconButton>
      ) : null}
    </>
  );
}

ActionCell.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  taskId: PropTypes.number.isRequired,
  taskStatus: PropTypes.string.isRequired
};

export default ActionCell;
