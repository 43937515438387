import _ from 'lodash';

import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import roles from '../../../roles';

import useMenuItem from '../../../components/AGTable/hooks/useMenuItem';

import collectorUtils from '../collectorUtils';

export default function useMarkOperationalCollector(collectors, nameSuffix) {
  const user = context.User.useUser();

  const title = `${langs('MARK_AS_OPERATIONAL')}${nameSuffix}`;

  function onConfirm(collector) {
    return collectorUtils.markCollectorsAsOperational(_.isEmpty(collectors) ? [collector] : collectors);
  }

  const permission = user.rbac.cms.isEnabled()
    ? consts.rbac.permissions.cms.collectors.markOperational
    : roles.MANAGE_COLLECTORS;

  return useMenuItem(onConfirm, title, 'outline_verified_user_white_24dp', permission, user.rbac.cms);
}
