import _ from 'lodash';

import client from '../client';

export async function create({
  branch,
  clone,
  configuration,
  env,
  industry,
  isMonitored,
  multiTenant,
  owner,
  singleTenant,
  stage,
  tenantName
}) {
  return client.post('/maestro/api/v2/tenants', {
    branch,
    clone,
    configuration,
    env,
    industry,
    isMonitored,
    multiTenant,
    owner,
    singleTenant,
    stage,
    tenantName
  });
}

export async function count(groupBy, qs) {
  return client.get(`/maestro/api/v1/tenants/_count?groupBy=${groupBy}&${qs}`);
}

export async function destroy_v2(tenantId) {
  return client.delete(`/maestro/api/v1/tenants/${tenantId}/_destroy`);
}

export async function getByEnv(envId) {
  return client.get(`/maestro/api/v1/tenants?env_id=${envId}&tenantId[ne]=null&length=-1`);
}

export async function getById(tenantId) {
  let apiResult = await getByQuery({searchQuery: `tenant_id[eq]=${tenantId}`});
  let tenants = _.get(apiResult, ['data', 'items']);
  if (_.isEmpty(tenants)) {
    throw new Error(`Failed to fetch data for tenant! (id=${tenantId}`);
  }
  return {data: tenants[0]};
}

export async function getByQuery({length = 20, from, orderBy, searchQuery = ''}) {
  const fromQuery = from ? `&from=${from}` : '';
  const orderByQuery = orderBy ? `&orderBy=${orderBy}` : '';

  return client.get(`/maestro/api/v1/tenants?length=${length}${fromQuery}${orderByQuery}&${searchQuery}`);
}

export async function getCompact(length = -1) {
  return client.get(`/maestro/api/v1/tenants/_compact`, {length});
}

export async function getCommandStatusByTenant(tenantId) {
  return client.get(
    `maestro/api/v1/commands_status?length=100&hostId[eq]=${tenantId}&orderBy=CommandStatusCreationDate:desc`
  );
}

export async function getTaskOutput(tenantId, taskId) {
  return client.get(`/maestro/api/v1/tenants/${tenantId}/task/${taskId}/output`);
}

export async function updateById(tenantId, options) {
  return client.patch(`/maestro/api/v1/tenants/${tenantId}`, options);
}

export async function stop(tenantId) {
  return client.post(`/maestro/api/v1/tenants/${tenantId}/_stop`);
}

export async function start(tenantId) {
  return client.post(`/maestro/api/v1/tenants/${tenantId}/_start`);
}
