import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import * as Styled from './IPAddressInput.styles';

const IP_REGEX = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

function isIPAddressValid(ipAddress) {
  for (let ip of ipAddress.split(',')) {
    if (!ip.match(IP_REGEX)) {
      return false;
    }
  }
  return true;
}

function IPAddressInput({value, onChange, ...props}) {
  const [isError, setIsError] = React.useState(false);
  const [ipAddress, setIpAddress] = React.useState('');

  React.useEffect(() => {
    if (isIPAddressValid(ipAddress)) {
      onChange(ipAddress);
      setIsError(false);
    } else {
      setIsError(!_.isEmpty(ipAddress));
    }
  }, [ipAddress]);

  React.useEffect(() => {
    setIpAddress(value);
  }, [value]);

  return (
    <form noValidate autoComplete="off">
      <Styled.IpInput
        value={ipAddress}
        error={isError}
        onChange={({target: {value}}) => setIpAddress(value)}
        fullWidth
        {...props}
      />
    </form>
  );
}

IPAddressInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired])
};

export default IPAddressInput;
