import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';

import consts from '../../../../consts';
import context from '../../../../context';
import langs from '../../../../localization/langs';
import services from '../../../../services';

import ProtectedComponent from '../../../Rbac/ProtectedComponent';
import {StatusLine} from '../../../Base';

import * as Styled from './RmqRelayLine.styles';

function RmqRelayLine({value, onChange, relaySource, icon}) {
  const notifications = context.Notifications.useNotifications();
  const user = context.User.useUser();

  async function handleStopRmqRelay() {
    try {
      const {data} = await services.tenants.getByQuery({searchQuery: `tenant_name=${relaySource}`});
      const tenantId = _.get(_.head(data.items), 'tenantId');

      await services.tenants.stopTenantRmqRelay(tenantId);
      notifications.success(langs('RMQ_RELAY_STOP_SUCCESS'));
      onChange();
    } catch (error) {
      notifications.error(error.message);
      console.error(error.message);
    }
  }

  return (
    <Styled.ActionLine>
      <StatusLine icon={icon}>{value}</StatusLine>
      <ProtectedComponent
        permissions={[consts.rbac.permissions.maestro.tenant.rmq_relay_stop]}
        rbacControlObj={user.rbac.maestro}
      >
        <IconButton onClick={handleStopRmqRelay}>
          <Styled.CancelSmall />
        </IconButton>
      </ProtectedComponent>
    </Styled.ActionLine>
  );
}

RmqRelayLine.propTypes = {
  icon: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  relaySource: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default RmqRelayLine;
