import styled from 'styled-components';

import * as theme from '../../../theme';

export const Container = styled.div`
  width: 780px;
  height: 650px;
  display: flex;
  position: absolute;
  top: 42px;
  z-index: ${theme.zIndex.advancedSearch};
`;

export const NavBar = styled.div`
  width: 200px;
  height: 100%;
  padding: 49px 0 0 32px;
  background: ${theme.backgrounds.black};
`;

export const Tab = styled.div`
  color: ${({isActive}) => (isActive ? theme.navbar.activeText : theme.navbar.text)};
  opacity: ${({isDisabled}) => (isDisabled ? 0.5 : 1)};
  font-weight: 600;
  font-size: 16px;
  user-select: none;
  margin-bottom: 12px;
  cursor: ${({isDisabled}) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

export const Content = styled.div`
  width: calc(100% - 200px);
  height: 100%;
  padding: 49px 32px 0 32px;
  background: ${theme.backgrounds.primary};
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: ${theme.backgrounds.black};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${theme.backgrounds.title};
  }
`;
