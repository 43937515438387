import PropTypes from 'prop-types';
import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import * as Styled from './ProgressBar.styles';

function ProgressBar({isLoading = true}) {
  return <Styled.Container>{isLoading && <LinearProgress />}</Styled.Container>;
}

ProgressBar.propTypes = {
  isLoading: PropTypes.bool
};

export default ProgressBar;
