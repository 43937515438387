import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './FormConfigurationInput.styles';

function FormConfigurationInput({onChange, type, value}) {
  switch (type) {
    case 'ipAddress':
      return <Styled.NetworkIPAddressInput onChange={onChange} value={value} />;
    case 'collectorNumber':
      return <Styled.NetworkConfigurationInput type="number" onChange={onChange} value={value} />;
    default:
      return <Styled.NetworkConfigurationInput onChange={onChange} value={value} />;
  }
}

FormConfigurationInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.func.isRequired
};

export default FormConfigurationInput;
