import _ from 'lodash';

import {SERVICE_NAMES_BE, SERVICE_NAMES_FE} from '../../../consts/rbac/rbac';
import {composePermissionName} from './utils';

export function getAddPermissions(viewAsRbacPermissions, rbacPermissions, setViewAsRbacPermissions, allEnvs) {
  return (permissions, envNames, serviceNames) => {
    if (_.isEmpty(permissions)) {
      console.log(
        "%c'RBAC.permissions.add(permissions:[string], envNames:[string], serviceNames:[string])': adds specific permissions",
        'color: green'
      );
      return;
    }
    if (_.isEmpty(envNames)) {
      envNames = allEnvs;
    }

    if (_.isEmpty(serviceNames)) {
      serviceNames = _.values(SERVICE_NAMES_FE);
    }

    let addPermissionsList = [];
    _.forEach(serviceNames, (serviceName) =>
      _.forEach(envNames, (envName) =>
        _.forEach(permissions, (permission) =>
          addPermissionsList.push(composePermissionName(serviceName, envName, permission))
        )
      )
    );

    const currentPermissions = _.isEmpty(viewAsRbacPermissions) ? rbacPermissions : viewAsRbacPermissions;

    const newPermissions = {
      permissions: _.concat(currentPermissions.permissions, addPermissionsList),
      roles: currentPermissions.roles
    };
    console.log(`Added ${_.size(addPermissionsList)} permissions.`);
    setViewAsRbacPermissions(newPermissions);
  };
}

export function getRemovePermission(viewAsRbacPermissions, setViewAsRbacPermissions) {
  return (fullPermissionName) => {
    if (_.isEmpty(viewAsRbacPermissions)) {
      return;
    }
    const newPermissions = {
      permissions: _.without(viewAsRbacPermissions.permissions, fullPermissionName),
      roles: viewAsRbacPermissions.roles
    };
    console.log(`Removed permission ${fullPermissionName}`);
    setViewAsRbacPermissions(newPermissions);
  };
}

export function getSetViewAsPermissions(getViewAsRbacPermissions, allEnvs) {
  return (userGroups, envNames, serviceNames) => {
    if (_.isEmpty(userGroups)) {
      console.log(
        "%c'RBAC.viewAs.set(userGroups:[string], envNames:[string], serviceNames:[string])': sets active user groups",
        'color: green'
      );
      return;
    }
    if (_.isEmpty(envNames)) {
      envNames = allEnvs;
    }
    if (_.isEmpty(serviceNames)) {
      serviceNames = _.values(SERVICE_NAMES_BE);
    }
    getViewAsRbacPermissions(envNames, serviceNames, userGroups);
  };
}

function filterPermissions(sourceArray, targetArray, comparisonIndex) {
  return _.filter(sourceArray, (permission) => {
    let parts = _.split(permission, '.');
    return _.includes(targetArray, parts[comparisonIndex]);
  });
}

export class verbosePermissions {
  constructor() {
    this.missingPermissions = new Set();
    this.permissions = new Set();
  }

  add(permissions) {
    if (_.isString(permissions)) {
      this.permissions.add(permissions);
    } else {
      permissions.forEach((permission) => this.permissions.add(permission));
    }
  }

  addMissing(permissions) {
    if (_.isString(permissions)) {
      this.missingPermissions.add(permissions);
    } else {
      permissions.forEach((permission) => this.missingPermissions.add(permission));
    }
  }

  clear() {
    this.missingPermissions.clear();
    this.permissions.clear();
  }

  log(serviceNames, envNames) {
    let permissions = Array.from(this.permissions);
    let missingPermissions = Array.from(this.missingPermissions);

    if (!_.isEmpty(serviceNames)) {
      missingPermissions = filterPermissions(missingPermissions, serviceNames, 0);
      permissions = filterPermissions(permissions, serviceNames, 0);
    }

    if (!_.isEmpty(envNames)) {
      missingPermissions = filterPermissions(missingPermissions, envNames, 1);
      permissions = filterPermissions(permissions, envNames, 1);
    }

    console.log('Requested permissions', permissions);
    console.log('Missing permissions', missingPermissions);
  }
}
