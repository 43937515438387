import PropTypes from 'prop-types';
import React from 'react';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import command from '../../../models/command';
import context from '../../../context';
import langs from '../../../localization/langs';
import models from '../../../models';

import {DateField} from '../../Base';

import * as Styled from './TenantTaskSection.styles';

export default function TenantTaskSection({tenantTasks, setSelectedCommandStatus, statusLabel}) {
  const {getUrl} = context.Navigation.useNavigation();

  return (
    <Styled.TaskSectionContainer>
      <Styled.Title>
        {tenantTasks.length} {langs('TASKS')} {statusLabel}
      </Styled.Title>
      {tenantTasks.map((tenantTask) => {
        const [Icon, text, hasLog] = models.command.getCommandIconAndTextByStatus(tenantTask.status);
        const commandType = command.getTextByType(tenantTask.commandType);

        return (
          <Styled.Row key={tenantTask.taskId}>
            <Styled.StatusColumn>
              <Icon />
              <Styled.Link to={getUrl('tenants.tasks', null, {taskId: tenantTask.taskId})}>{commandType}</Styled.Link>
            </Styled.StatusColumn>
            <Styled.ViewLogsColumn>
              {hasLog && (
                <Styled.Button
                  onClick={() => setSelectedCommandStatus(tenantTask)}
                  startIcon={<DescriptionOutlinedIcon />}
                >
                  {langs('VIEW_LOG')}
                </Styled.Button>
              )}
            </Styled.ViewLogsColumn>
            <Styled.DateColumn>
              <DateField date={tenantTask.creationDate} prefix={`${text} `} />
            </Styled.DateColumn>
          </Styled.Row>
        );
      })}
    </Styled.TaskSectionContainer>
  );
}

TenantTaskSection.propTypes = {
  setSelectedCommandStatus: PropTypes.func.isRequired,
  statusLabel: PropTypes.string.isRequired,
  tenantTasks: PropTypes.array.isRequired
};
