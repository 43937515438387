import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import consts from '../../consts';
import useSearchQueryFilter from '../../hooks/useSearchQueryFilter';

import * as Styled from './ChipsSearchOptions.styles';

function ChipsSearchOptions({options, urlQueryKey, filterOperator, ...props}) {
  const [urlQueryFilter, setUrlQueryFilter] = useSearchQueryFilter(urlQueryKey, filterOperator);
  const urlQueryValue = urlQueryFilter ? urlQueryFilter.split(',') : [];

  function onClickChip(event, key) {
    event.preventDefault();

    if (urlQueryValue.includes(key)) {
      return setUrlQueryFilter(urlQueryValue.filter((option) => option !== key));
    }
    if (options.length > 1 && isAllOtherOptionsSelected(urlQueryValue, options)) {
      return setUrlQueryFilter([]);
    }
    setUrlQueryFilter([...urlQueryValue, key]);
  }

  return (
    <Styled.Group {...props}>
      {_.map(options, (option) => (
        <Styled.ChipButton
          type="button"
          key={option.key}
          isSelected={urlQueryValue.includes(option.key)}
          onClick={(event) => onClickChip(event, option.key)}
        >
          {option.value}
        </Styled.ChipButton>
      ))}
    </Styled.Group>
  );
}

ChipsSearchOptions.Group = Styled.Group;
ChipsSearchOptions.Button = Styled.ChipButton;

function isAllOtherOptionsSelected(chosenOptions, possibleOptions) {
  return chosenOptions.length === possibleOptions.length - 1;
}

ChipsSearchOptions.propTypes = {
  filterOperator: PropTypes.oneOf(_.values(consts.FILTER_OPERATORS)).isRequired,
  isScrollable: PropTypes.bool,
  options: PropTypes.array.isRequired,
  urlQueryKey: PropTypes.string
};

export default ChipsSearchOptions;
