import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {SERVICE_NAMES_BE} from '../../../../../consts/rbac/rbac';
import langs from '../../../../../localization/langs';

import DEFAULTS from '../consts';
import ListFilter from './ListFilter';
import UserFilter from './UserFilter';

import * as Styled from './FiltersRow.styles';

const ALL_SERVICES_NAMES = _.values(SERVICE_NAMES_BE);

function getDisplayString(arr) {
  return _.join(arr, ', ');
}

function FiltersRow({
  envs,
  groups,
  isSingleGroupMode,
  oktaUserLogin,
  roles,
  selectedEnvs,
  selectedGroups,
  selectedRoles,
  selectedServices,
  setOktaUserLogin,
  setSelectedEnvs,
  setSelectedGroups,
  setSelectedRoles,
  setSelectedServices,
  setViewMode,
  viewMode
}) {
  const groupSelectionLabel = isSingleGroupMode
    ? langs('SELECTED_OKTA_GROUP_NAME', {selectedGroup: _.head(selectedGroups)})
    : langs('SELECTED_OKTA_GROUP_NAMES', {selectedGroups: getDisplayString(selectedGroups)});
  const onSelectedGroupChange = (value) => (isSingleGroupMode ? setSelectedGroups([value]) : setSelectedGroups(value));
  const getRolesLabel = () => {
    const selectedRoleNames = _.map(selectedRoles, (roleId) => _.find(roles, {key: roleId}).value);
    const selectedRolesString = _.join(selectedRoleNames, ',');
    return langs('SELECTED_ROLES', {selectedRoles: selectedRolesString});
  };

  return (
    <Styled.Container>
      {setViewMode && (
        <ListFilter
          label={langs('VIEW_MODE', {viewMode: _.startCase(viewMode)})}
          onChange={setViewMode}
          options={_.values(DEFAULTS.VIEW_MODE)}
          value={viewMode}
        />
      )}
      {setSelectedGroups && (
        <ListFilter
          label={groupSelectionLabel}
          multiple={!isSingleGroupMode}
          onChange={onSelectedGroupChange}
          options={groups}
          value={selectedGroups}
        />
      )}
      {setSelectedServices && (
        <ListFilter
          label={langs('SELECTED_SERVICES', {selectedServices: getDisplayString(selectedServices)})}
          multiple={true}
          onChange={setSelectedServices}
          options={ALL_SERVICES_NAMES}
          value={selectedServices}
        />
      )}
      {setSelectedEnvs && (
        <ListFilter
          label={langs('SELECTED_ENVS', {selectedEnvs: getDisplayString(selectedEnvs)})}
          multiple={true}
          onChange={setSelectedEnvs}
          options={envs}
          value={selectedEnvs}
        />
      )}
      {setSelectedRoles && (
        <ListFilter
          label={getRolesLabel()}
          onChange={(value) => setSelectedRoles([value])}
          options={_.orderBy(roles)}
          value={selectedRoles}
        />
      )}
      {setOktaUserLogin && <UserFilter onChange={setOktaUserLogin} selectedUser={oktaUserLogin} />}
    </Styled.Container>
  );
}

FiltersRow.propTypes = {
  envs: PropTypes.arrayOf(PropTypes.string),
  groups: PropTypes.arrayOf(PropTypes.string),
  isSingleGroupMode: PropTypes.bool,
  oktaUserLogin: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  selectedEnvs: PropTypes.arrayOf(PropTypes.string),
  selectedGroups: PropTypes.arrayOf(PropTypes.string),
  selectedRoles: PropTypes.arrayOf(PropTypes.string),
  selectedServices: PropTypes.arrayOf(PropTypes.string),
  setOktaUserLogin: PropTypes.func,
  setSelectedEnvs: PropTypes.func,
  setSelectedGroups: PropTypes.func,
  setSelectedRoles: PropTypes.func,
  setSelectedServices: PropTypes.func,
  setViewMode: PropTypes.func,
  viewMode: PropTypes.string
};

export default FiltersRow;
