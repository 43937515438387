import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React from 'react';

function DateColumn({value: {creationDate}}) {
  return <span>{format(new Date(creationDate), 'dd/MMM/yyyy HH:mm:ss')}</span>;
}

DateColumn.propTypes = {
  value: PropTypes.shape({
    creationDate: PropTypes.string
  }).isRequired
};

export default DateColumn;
