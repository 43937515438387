import _ from 'lodash';

import client from '../client';
import consts from '../../consts';
import {isLocalhost} from '../../utils/urlUtils';

const API_BASE = 'tenant-seed/api/v1';
const API_SEED_CONFIG = 'seed-config';
const API_SEED_METADATA = 'seed-metadata';
const HOST_LIVE = `https://ops-${consts.DEMO_ENV}.armis.com`;
const HOST_LOCAL = '';

async function getAllMetadata() {
  const url = getUrl(API_SEED_METADATA);
  const {
    data: {items}
  } = await client.get(url);
  return items;
}

async function getConfig(tenantId) {
  const url = getUrl(API_SEED_CONFIG, tenantId);
  const {data} = await client.get(url);
  return data;
}

function getUrl(...pathParts) {
  const host = isLocalhost() ? HOST_LOCAL : HOST_LIVE;
  const urlParts = [host, API_BASE, ...pathParts];
  return _.join(urlParts, '/');
}

async function getMetadata(tenantId) {
  const url = getUrl(API_SEED_METADATA, tenantId);
  const {data} = await client.get(url);
  return data;
}

async function setMetadata(metadata) {
  const url = getUrl(API_SEED_METADATA);
  return client.post(url, metadata);
}

export default {
  getAllMetadata,
  getConfig,
  getMetadata,
  setMetadata
};
