import styled from 'styled-components';

import MuiButton from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';

import * as theme from '../../theme';

export const CONFIGURATIONS_ACTIONS_HEIGHT = 104;

export const Search = styled(InputBase)`
  padding: 0 10px;
  background-color: ${theme.backgrounds.primary};
  font-size: 14px;
`;

export const Container = styled(Grid)`
  margin-bottom: 0;
  padding: 10px 10px 0 10px;
  height: ${CONFIGURATIONS_ACTIONS_HEIGHT}px;
  border-bottom: 1px ${theme.backgrounds.primary} solid;
`;

export const Title = styled(Grid)`
  flex-grow: 1;
  font-size: 20px;
`;

export const ActionsWrapper = styled(Grid)`
  display: flex;
`;

export const NavBarWrapper = styled(Grid)`
  display: flex;
  width: 100%;
  margin-top: 20px;
`;

export const AddParameterButton = styled(MuiButton).attrs({size: 'small', variant: 'text'})`
  margin-right: 10px;
  border: 1px solid ${theme.text.primary};
`;

export const ApplyButton = styled(MuiButton).attrs((props) => ({
  color: 'secondary',
  size: 'small',
  variant: 'contained'
}))`
  color: ${({$allselected}) => $allselected && theme.buttons.normal.text};
  background-color: ${({$allselected}) => $allselected && theme.statusColors.error};
  &:hover {
    background-color: ${({$allselected}) => $allselected && theme.statusColors.error};
  }
`;
