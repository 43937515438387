import styled from 'styled-components';

import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {InputBase} from '@material-ui/core';

import * as theme from '../../theme';

const SIDE_PADDING = 8;

export const DialogContent = styled(MuiDialogContent)`
  padding: ${SIDE_PADDING}px;
  min-height: 200px;
`;

export const NewTaskDialogTitle = styled(DialogTitle)`
  padding: 16px ${SIDE_PADDING}px;
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 10px;
`;

export const Label = styled.div`
  margin-right: 8px;
`;

export const Command = styled.div`
  padding-left: 2px;
  margin-right: 8px;
  font-family: monospace;
  border: solid;
  border-radius: 4px;
`;

export const Parameter = styled.div`
  margin-right: 8px;
  font-family: monospace;
`;

export const Parameters = styled.div`
  margin-left: 1rem;
`;

export const ConfigurationInputContainer = styled.div`
  width: 50px;
`;

export const ConfigurationInput = styled(InputBase)`
  background-color: ${theme.backgrounds.primary};
  padding: 5px;
  font-size: 14px;
  width: 100%;

  & .MuiInputBase-input {
    padding: 0;
  }
`;

export const newTaskModalPaperProps = {
  style: {backgroundColor: theme.modal.background, maxWidth: 750, padding: 20}
};
