import _ from 'lodash';
import React from 'react';

import consts from '../consts';

import useUrlSearchParams from './useUrlSearchParams';

function getInitialOperatorValue(key, filter, defaultOperator) {
  let result = defaultOperator;

  _.forEach(consts.FILTER_OPERATORS, (operator) => {
    if (filter.get(`${key}${operator}`)) {
      result = operator;
    }
  });

  return result;
}

function useSearchQueryFilter(key, defaultOperator, defaultValue, useQueryOp = false) {
  const [filter, setFilter] = useUrlSearchParams();
  const [operator, setOperator] = React.useState(
    useQueryOp ? getInitialOperatorValue(key, filter, defaultOperator) : defaultOperator
  );

  function getFilterKey(overrideOperator) {
    return `${key}${overrideOperator || operator}`;
  }

  function setFilterFn(value) {
    const filterValue = _.isArray(value) ? _.join(value, ',') : value;
    setFilter([[getFilterKey(), filterValue]]);
  }

  function setOperatorFn(newOperator) {
    const filterKey = getFilterKey();
    const filterValue = filter.get(filterKey) || defaultValue;
    const newFilterKey = getFilterKey(newOperator);
    setFilter([[newFilterKey, filterValue]], [filterKey]);
    setOperator(newOperator);
  }

  return [filter.get(getFilterKey()) || defaultValue, setFilterFn, operator || defaultOperator, setOperatorFn];
}

export default useSearchQueryFilter;
