import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import {Form, Input} from '../../../components/Base';

import consts from './SeedTenantDialog.consts';
import context from '../../../context';
import demoUtils from '../demoUtils';
import hooks from '../../../hooks';
import langs from '../../../localization/langs';
import models from '../../../models';
import services from '../../../services';

import DemoModulesMultipleChoiceInput from '../../../components/Demo/DemoModulesMultipleChoiceInput';
import TaskParametersForm from '../../../components/Base/forms/TaskParametersForm';

function SeedTenantDialog({config, close, isNewSeed, onCreated, open, tenant}) {
  const [commandsResponse, isLoadingCommands] = hooks.useGetResult(services.tenants.getShellCommands);

  const [params, setParams] = React.useState(demoUtils.getSeedParams(tenant, isNewSeed, config));
  const [advanced, setAdvanced] = React.useState(!params.vertical);
  const [timezone, setTimezone] = React.useState(params.timezone);
  const [vertical, setVertical] = React.useState(params.vertical);

  const notifications = context.Notifications.useNotifications();
  const user = context.User.useUser();

  const command = isLoadingCommands ? null : demoUtils.getSeedCommand(commandsResponse.items);
  const title = `${consts.SEED_COMMAND_DESCRIPTION}: ${tenant.tenantName}`;

  const extended_types = {
    [models.configuration.TYPES.MULTIPLE_CHOICE]: (props) =>
      config && <DemoModulesMultipleChoiceInput config={config} {...props} />
  };

  async function createTask() {
    try {
      await demoUtils.statSeed(tenant, command, params, user);
    } catch (exception) {
      notifications.error(langs('DEMO_ACTIONS_SEED_ERROR'));
      console.error(langs('DEMO_ACTIONS_SEED_ERROR'), exception);
    }
    onCreated(tenant);
    close();
  }

  function onDialogClose(event, reason) {
    if (reason !== 'backdropClick') {
      return;
    }
    close();
  }

  React.useEffect(() => {
    const newParams = demoUtils.getSeedParams(tenant, isNewSeed, config);
    setParams(newParams);
    setAdvanced(!newParams.vertical);
    setTimezone(newParams.timezone);
    setVertical(newParams.vertical);
  }, [tenant, config, isNewSeed]);

  React.useEffect(() => {
    if (!config) {
      return;
    }
    setParams(demoUtils.setVerticalModules(params, vertical, config));
  }, [vertical]);

  React.useEffect(() => {
    setParams({...params, timezone});
  }, [timezone]);

  return (
    <Dialog open={open} onClose={onDialogClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {langs('DEMO_ACTIONS_SEED_DIALOG_TEXT1', tenant)}
          <br />
          {langs('DEMO_ACTIONS_SEED_DIALOG_TEXT2', tenant)}
        </DialogContentText>
        {command && config ? (
          <>
            <Form.Field>
              <Form.Label>{langs('DEMO_TABLE_COLUMN_VERTICAL')}</Form.Label>
              <Form.FieldWrapper>
                <Select value={vertical} onChange={(event) => setVertical(event.target.value)}>
                  {_(config.defaults.verticals)
                    .keys()
                    .sortBy()
                    .map((key) => (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    ))
                    .value()}
                </Select>
              </Form.FieldWrapper>
            </Form.Field>

            <Form.Field>
              <Form.Label>{langs('DEMO_TABLE_COLUMN_TIMEZONE')}</Form.Label>
              <Form.FieldWrapper>
                <Input type="text" value={timezone} onChange={({target: {value}}) => setTimezone(value)} />
              </Form.FieldWrapper>
            </Form.Field>

            <Form.Field>
              <Form.Label>{langs('DEMO_ACTIONS_SEED_DIALOG_ADVANCED')}</Form.Label>
              <Form.FieldWrapper>
                <Switch checked={advanced} onChange={({target: {checked}}) => setAdvanced(checked)} />
              </Form.FieldWrapper>
            </Form.Field>

            {advanced && (
              <TaskParametersForm
                flat
                parameters={demoUtils.getCommandParameters(command, config)}
                parametersValues={params}
                setParametersValues={setParams}
                extendedTypes={extended_types}
              />
            )}
          </>
        ) : isLoadingCommands ? (
          <DialogContentText>{langs('LOADING')}</DialogContentText>
        ) : (
          <DialogContentText>
            {langs('DEMO_ACTIONS_SEED_DIALOG_CANT_FIND', {command: consts.SEED_COMMAND_DESCRIPTION})}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{langs('BUTTON_CANCEL')}</Button>
        <Button color="primary" variant="contained" onClick={createTask}>
          {langs('BUTTON_OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SeedTenantDialog.propTypes = {
  close: PropTypes.func.isRequired,
  config: PropTypes.object,
  isNewSeed: PropTypes.bool,
  onCreated: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tenant: PropTypes.object
};

export default SeedTenantDialog;
