import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import {Button} from '@material-ui/core';

function Action({children, disabled, icon: Icon, onClick, title, tooltip}) {
  const button = (
    <Button color="inherit" disabled={disabled} onClick={onClick} size="small" startIcon={<Icon />} variant="text">
      {title}
    </Button>
  );
  return (
    <>
      {tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button}
      {children}
    </>
  );
}

Action.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string
};

export default Action;
