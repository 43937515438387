import commandModel from './command';

export const taskStatus = {
  CANCELED: 'CANCELED',
  FAILED: 'FAILED',
  PAUSED: 'PAUSED',
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  SUCCESS: 'SUCCESS',
  WAITING_FOR_PARENT: 'WAITING_FOR_PARENT'
};

export const completedTaskStatuses = [taskStatus.CANCELED, taskStatus.FAILED, taskStatus.SUCCESS];

const fields = {
  STATUS: 'status',
  TYPE: 'commandType'
};

export default {
  completedTaskStatuses,
  fields,
  getTextByType: commandModel.getTextByType,
  taskStatus,
  taskType: commandModel.commandType
};
