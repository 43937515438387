import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './IconedLabel.styles';

function IconedLabel({children, icon, ...props}) {
  return (
    <Styled.Container {...props}>
      {icon} <span>{children}</span>
    </Styled.Container>
  );
}

IconedLabel.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired
};

export default IconedLabel;
