import React from 'react';

import NavBar from './Parts/NavBar';
import Search from './Parts/Search';
import UserInfo from './Parts/UserInfo';

import * as Styled from './Layout.styles';

function Layout({children}) {
  return (
    <Styled.Container>
      <Styled.TopPanel>
        <Styled.StyledLogo />
        <Search />
        <UserInfo />
      </Styled.TopPanel>
      <Styled.SidePanelAndMain>
        <Styled.SidePanel>
          <NavBar />
        </Styled.SidePanel>
        <Styled.MaxWidthContainer>
          <Styled.Main>
            <Styled.MainGrid>
              <Styled.MainGridItem>
                <Styled.MainView>{children}</Styled.MainView>
              </Styled.MainGridItem>
            </Styled.MainGrid>
          </Styled.Main>
        </Styled.MaxWidthContainer>
      </Styled.SidePanelAndMain>
    </Styled.Container>
  );
}

export default Layout;
