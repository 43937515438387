import styled from 'styled-components';

export const Header = styled.div`
  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const Version = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
`;

export const Icon = styled.div`
  margin-right: 0.5rem;
`;

export const Value = styled.div`
  flex: 1;
  font-size: 1.3rem;
  margin-right: 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
