import _ from 'lodash';
import moment from 'moment';

import consts from '../../../consts';
import filterConsts from './filterConsts';

function getDateFilter({dateFrom, dateTo, type}, field) {
  switch (type) {
    case filterConsts.NUMERIC_FILTER_TYPES.EQUALS:
      dateTo = moment
        .utc(dateFrom)
        .add(1, 'd')
        .toISOString();
      return getDateFilter({dateFrom, dateTo, type: filterConsts.NUMERIC_FILTER_TYPES.RANGE}, field);

    case filterConsts.NUMERIC_FILTER_TYPES.RANGE:
      const cond1 = getFilterExpression(field, consts.FILTER_OPERATORS.GREATER_OR_EQUAL, sanitizeValue(dateFrom));
      const cond2 = getFilterExpression(field, consts.FILTER_OPERATORS.LOWER_OR_EQUAL, sanitizeValue(dateTo));
      return `${cond1}&${cond2}`;

    default:
      return getTextFilter({freeTextSearch: dateFrom, type}, field);
  }
}

function getFilterExpression(field, operator, value) {
  return `${field}${operator}=${value}`;
}

function getNumberFilter(filterObj, field) {
  if (filterObj.type === filterConsts.NUMERIC_FILTER_TYPES.RANGE) {
    const cond1 = getFilterExpression(field, consts.FILTER_OPERATORS.GREATER_OR_EQUAL, sanitizeValue(filterObj.filter));
    const cond2 = getFilterExpression(field, consts.FILTER_OPERATORS.LOWER_OR_EQUAL, sanitizeValue(filterObj.filterTo));
    return `${cond1}&${cond2}`;
  }
  return getTextFilter(filterObj, field);
}

function getQuery(api, filterModel) {
  const columns = api.getColumnDefs();
  return _.map(filterModel, (filterObj, field) => {
    const column = _.find(columns, {colId: field});
    if (!column) {
      console.warn(`Column not found for field "${field}".`);
    }

    let handler;
    if (column && column.isBoolean) {
      handler = FILTER_TYPE_TO_HANDLER.boolean;
    } else {
      handler = FILTER_TYPE_TO_HANDLER[filterObj.filterType];
    }

    if (!handler) {
      console.warn(`Ignoring unsupported filter type "${filterObj.filterType}" for field "${field}".`);
    }
    return handler(filterObj, field);
  }).join('&');
}

function getSetFilter({values}, field) {
  const operator = filterConsts.FILTER_TYPE_TO_OPERATOR.set;
  const valueModifier = filterConsts.FILTER_TYPE_TO_VALUE_MODIFIER.default;
  const value = _.map(values, (v) => sanitizeValue(v, valueModifier));
  return getFilterExpression(field, operator, `JSON(${JSON.stringify(value)})`);
}

function getTextFilter({filter, type}, field) {
  const operator = filterConsts.FILTER_TYPE_TO_OPERATOR[type];
  const valueModifier =
    filterConsts.FILTER_TYPE_TO_VALUE_MODIFIER[type] || filterConsts.FILTER_TYPE_TO_VALUE_MODIFIER.default;
  const value = sanitizeValue(filter, valueModifier);
  return getFilterExpression(field, operator, value);
}

function getBooleanFilter({values}, field) {
  const operator = filterConsts.FILTER_TYPE_TO_OPERATOR.equals;
  return getFilterExpression(field, operator, `JSON(${JSON.stringify(values)})`);
}

function sanitizeValue(filter, valueModifier = _.identity) {
  return `${encodeURIComponent(valueModifier(filter))}`;
}

const FILTER_TYPE_TO_HANDLER = {
  boolean: getBooleanFilter,
  date: getDateFilter,
  number: getNumberFilter,
  set: getSetFilter,
  text: getTextFilter
};
export default {getQuery};
