import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import configurationUtils from '../Configuration/utils';
import context from '../../../context';
import dataUtils from './dataUtils';
import langs from '../../../localization/langs';
import servicesLib from '../../../services';

import AddConfigurationKey from '../../../components/Configuration/AddConfigurationKey/AddConfigurationKey';

const LEVELS = _.concat(configurationUtils.levelNames, 'STACK');

function getSelectedValues(user, selectedRow) {
  return [
    {
      key: 'serviceId',
      label: 'SERVICE',
      permissionsFunc: user.rbac.mtms.allowed.configuration.create.all.services,
      value: {name: _.get(selectedRow, 'serviceName', null)}
    },
    {
      key: 'stackId',
      label: 'STACK',
      value: {name: _.get(selectedRow, 'stackName', null)}
    },
    {
      key: 'environmentId',
      label: 'ENV',
      permissionsFunc: user.rbac.mtms.allowed.configuration.create.all.envs,
      value: {name: _.get(selectedRow, 'environmentName', null)}
    },
    {
      key: 'namespaceId',
      label: 'NAMESPACE',
      permissionsFunc: user.rbac.mtms.allowed.configuration.create.all.namespaces,
      value: {name: _.get(selectedRow, 'namespaceName', null)}
    }
  ];
}

function DeploymentsAddConfiguration({close, open, row}) {
  const notifications = context.Notifications.useNotifications();
  const user = context.User.useUser();

  async function onAddConfigurationKey(...params) {
    const affectedEntitiesIndex = _.findIndex(params, 'entities');
    const affectedEntities = _.get(params[affectedEntitiesIndex], 'entities', []);
    params[affectedEntitiesIndex] = _.map(affectedEntities, ({deploymentId}) => _.toInteger(deploymentId));
    try {
      await servicesLib.services.configurationV2.createKeyByQuery(...params);
      notifications.success(langs('CONFIGURATION_ADDED_SUCCESSFULLY'));
    } catch (ex) {
      console.error(ex.stack);
      notifications.error(ex.message);
    }
    return null;
  }

  async function getAffectedEntities(levelValueArray) {
    const entities = await dataUtils.getAffectedEntities(levelValueArray);
    return {
      displayValues: _.map(entities, (item) =>
        _.pick(item, ['environmentName', 'namespaceName', 'stackName', 'serviceName'])
      ),
      entities
    };
  }

  const wrappedCategories = React.useMemo(
    () =>
      _.map([_.find(configurationUtils.categories, {id: 'APP'})], (category) =>
        _.assign(
          {
            permissionsFuncs: {
              create: (labels) => user.rbac.maestro.hasConfigPermissions(category.permissions.create, labels),
              delete: (labels) => user.rbac.maestro.hasConfigPermissions(category.permissions.delete, labels),
              edit: (labels) => user.rbac.maestro.hasConfigPermissions(category.permissions.edit, labels)
            }
          },
          category
        )
      ),
    [user.rbac.mtms]
  );

  return (
    <AddConfigurationKey
      onClose={close}
      value={open}
      onChange={onAddConfigurationKey}
      levels={LEVELS}
      categories={wrappedCategories}
      displaySettings={configurationUtils.displaySettings}
      row={row}
      selectedValues={getSelectedValues(user, row)}
      getAffectedEntities={getAffectedEntities}
    />
  );
}

DeploymentsAddConfiguration.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  row: PropTypes.object
};

export default DeploymentsAddConfiguration;
