import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import SearchIcon from '@material-ui/icons/Search';

import enums from './InternalNavBar.enums';
import langs from '../../localization/langs';

import * as Styled from './InternalNavBar.styles';

function InternalNavBar({
  options,
  value,
  onChange,
  disabled,
  vertical = false,
  search = false,
  all = true,
  placeholder = langs('SEARCH'),
  ...props
}) {
  const [searchValue, setSearchValue] = React.useState('');
  const orientation = vertical ? enums.ORIENTATION.VERTICAL : enums.ORIENTATION.HORIZONTAL;

  React.useEffect(() => {
    setSearchValue('');
  }, [options]);

  function handleChange(event, newValue) {
    onChange(newValue);
  }

  const filteredOptions = options.filter(({name}) => name.toLowerCase().includes(searchValue.toLowerCase()));
  return (
    <Styled.Container {...props}>
      {search && (
        <Styled.SearchContainer elevation={0}>
          <Styled.IconButton size="small">
            <SearchIcon color="inherit" fontSize="small" />
          </Styled.IconButton>
          <Styled.Search
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder={`${placeholder} ...`}
          />
        </Styled.SearchContainer>
      )}
      <Styled.Tabs
        scrollButtons="off"
        selectionFollowsFocus
        onChange={handleChange}
        orientation={orientation}
        value={_.isEmpty(options) || value == null ? 0 : value}
        variant="scrollable"
      >
        {all && <Styled.Tab label="ALL" disabled={disabled} orientation={orientation} />}
        {filteredOptions.map(({disabled: optionDisabled, id, name}) => (
          <Styled.Tab
            key={id}
            label={name}
            value={id}
            disabled={disabled || optionDisabled}
            orientation={orientation}
          />
        ))}
      </Styled.Tabs>
    </Styled.Container>
  );
}

InternalNavBar.propTypes = {
  all: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  placeholder: PropTypes.string,
  search: PropTypes.bool,
  value: PropTypes.string,
  vertical: PropTypes.bool
};

export default InternalNavBar;
