import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import NetworkConfigurationForm from './NetworkConfigurationForm';
import RerouteWarning from './RerouteWarning';
import TextSearchInput from '../../../TextSearchInput';

import context from '../../../../context';
import langs from '../../../../localization/langs';
import services from '../../../../services';

import * as Styled from './ActivateBeaconModal.styles';

const MAX_COLLECTOR_NUMBER_CHARS = 6;
const INITIAL_COLLECTOR_NUMBER_CONFIG = {
  errorMessage: '',
  isLoading: false,
  number: null
};

function isEmptyCollectorNumber(collectorNumber) {
  return _.isEmpty(collectorNumber);
}

function isCollectorNumberTooLong(collectorNumber) {
  return _.size(collectorNumber) > MAX_COLLECTOR_NUMBER_CHARS;
}

async function isCollectorNumberAlreadyExists(tenantId, collectorNumber) {
  try {
    const collectorNumberResponse = await services.collectors.listCollectors({
      length: 1,
      searchQuery: encodeURI(`tenantId[eq]=${tenantId}&collectorNumber[eq]=${collectorNumber}`)
    });

    return _.get(collectorNumberResponse, 'data.count', 0) > 0;
  } catch (error) {
    return false;
  }
}

function ActivateBeaconModal({beaconId, isOpen, onClose}) {
  const [collectorNumberConfig, setCollectorNumberConfig] = React.useState(INITIAL_COLLECTOR_NUMBER_CONFIG);
  const [networkConfiguration, setNetworkConfiguration] = React.useState({});
  const [ipmiConfiguration, setIpmiConfiguration] = React.useState({});
  const [wifiConfiguration, setWifiConfiguration] = React.useState({});
  const [lteConfiguration, setLteConfiguration] = React.useState({});
  const [clusterConfiguration, setClusterConfiguration] = React.useState({});
  const [tenant, setTenant] = React.useState(null);
  const [tenants, setTenants] = React.useState([]);

  const {opsEnv} = context.Navigation.useNavigation();

  const isTenantSelected = !_.isNull(tenant);
  const isEnvsMatch = _.isEqual(opsEnv, tenant?.environment);

  const {number: collectorNumber} = collectorNumberConfig;

  function setCollectorNumber(number) {
    setCollectorNumberConfig(_.defaults({number}, collectorNumberConfig));
  }

  React.useEffect(() => {
    async function getNextCollectorNumber() {
      if (_.isEmpty(tenant)) {
        return;
      }

      try {
        const response = await services.collectors.getNextCollectorNumber(tenant.id);
        const suggestedNumber = _.get(response, 'data.result');

        setCollectorNumber(suggestedNumber.toString());
      } catch (error) {
        console.error(error);
      }
    }

    getNextCollectorNumber();
  }, [tenant?.id]);

  React.useEffect(() => {
    services.collectors.getTenantsFromAllEnv().then(({data}) => setTenants(data.items));
  }, []);

  React.useEffect(
    function resetModal() {
      if (isOpen) {
        return;
      }

      setNetworkConfiguration(_.stubObject());
      setCollectorNumberConfig(INITIAL_COLLECTOR_NUMBER_CONFIG);
      setTenant(null);
    },
    [isOpen]
  );

  async function reroute() {
    await services.collectors.beacons.reroute(beaconId, tenant.environment);
    onClose();
  }

  async function activate() {
    if (isEmptyCollectorNumber(collectorNumber)) {
      setCollectorNumberConfig(
        _.defaults({errorMessage: langs('ERROR_EMPTY_COLLECTOR_NUMBER')}, collectorNumberConfig)
      );
      return;
    }

    if (isCollectorNumberTooLong(collectorNumber)) {
      setCollectorNumberConfig(
        _.defaults(
          {errorMessage: langs('ERROR_COLLECTOR_NUMBER_TOO_LONG', {maxLength: MAX_COLLECTOR_NUMBER_CHARS})},
          collectorNumberConfig
        )
      );
      return;
    }

    setCollectorNumberConfig(_.defaults({errorMessage: '', isLoading: true}, collectorNumberConfig));

    const isCollectorNumberExists = await isCollectorNumberAlreadyExists(tenant.id, collectorNumber);

    if (isCollectorNumberExists) {
      setCollectorNumberConfig(
        _.defaults({errorMessage: langs('ERROR_EXISTING_COLLECTOR_NUMBER'), isLoading: false}, collectorNumberConfig)
      );
      return;
    }

    try {
      await services.collectors.beacons.activate(
        beaconId,
        collectorNumber,
        tenant.id,
        networkConfiguration,
        ipmiConfiguration,
        wifiConfiguration,
        lteConfiguration,
        clusterConfiguration
      );
      onClose();
    } catch (error) {
      console.error(error);
      setCollectorNumberConfig(
        _.defaults(
          {errorMessage: langs('ERROR_CANNOT_VALIDATE_EXISTING_COLLECTOR_NUMBER'), isLoading: false},
          collectorNumberConfig
        )
      );
    }
  }

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>
        {langs('ACTIVATE_COLLECTOR')} {collectorNumber}
      </DialogTitle>
      <DialogContent>
        <Styled.DialogContainer>
          <Grid item xs={4}>
            {langs('TENANT_NAME')}
          </Grid>
          <Grid item xs={8}>
            <TextSearchInput
              disableClearable={true}
              getOptionLabel={({name, environment}) => `${name} - (${environment})`}
              options={tenants}
              setValue={setTenant}
              value={tenant}
            />
          </Grid>
        </Styled.DialogContainer>
        {isEnvsMatch && isTenantSelected ? (
          <NetworkConfigurationForm
            collectorNumberConfig={collectorNumberConfig}
            networkConfiguration={networkConfiguration}
            setCollectorNumber={setCollectorNumber}
            setNetworkConfiguration={setNetworkConfiguration}
            ipmiConfiguration={ipmiConfiguration}
            setIpmiConfiguration={setIpmiConfiguration}
            wifiConfiguration={wifiConfiguration}
            setWifiConfiguration={setWifiConfiguration}
            lteConfiguration={lteConfiguration}
            setLteConfiguration={setLteConfiguration}
            clusterConfiguration={clusterConfiguration}
            setClusterConfiguration={setClusterConfiguration}
          />
        ) : !isEnvsMatch && isTenantSelected ? (
          <RerouteWarning tenantName={tenant.name} />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={reroute} disabled={!isTenantSelected || isEnvsMatch}>
          {langs('REROUTE')}
        </Button>
        <Button color="secondary" onClick={onClose}>
          {langs('CANCEL')}
        </Button>
        <Button color="secondary" disabled={!(isEnvsMatch && isTenantSelected)} onClick={activate}>
          {langs('ADD')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ActivateBeaconModal.propTypes = {
  beaconId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ActivateBeaconModal;
