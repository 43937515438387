import React from 'react';
import {useHistory} from 'react-router-dom';

import context from '../../../context';
import consts from '../../../consts';
import langs from '../../../localization/langs';
import services from '../../../services';

import {Breadcrumb} from '../../../components/Base';
import ProtectedView from '../../../components/Rbac/ProtectedView';
import StacksForm from '../../../components/Services/StacksForm';

import * as Styled from './StackAdd.styles';

const breadcrumb = [{title: langs('MULTI_TENANT'), url: '/multitenant'}, langs('ADD_NEW_STACK')];

function StackAdd() {
  const history = useHistory();
  const {getUrl} = context.Navigation.useNavigation();
  const notifications = context.Notifications.useNotifications();
  const user = context.User.useUser();

  async function createNewStack(id, name, stackServices) {
    try {
      const {
        data: {id: newStackId}
      } = await services.services.stacks.createStack(name);
      await services.services.stacks.updateStack(newStackId, name, stackServices);
      history.push(getUrl('services.stacks', null, {name}));
    } catch (err) {
      console.error(err.stack);
      notifications.error(langs('STACKS_FAILED_TO_CREATE'));
    }
  }

  return (
    <Styled.Container>
      <ProtectedView permissions={[consts.rbac.permissions.mtms.stacks.create]} rbacControlObj={user.rbac.mtms}>
        <Styled.FormContainer>
          <Breadcrumb sections={breadcrumb} withBorder={false} />
          <StacksForm onSubmit={createNewStack} />
        </Styled.FormContainer>
      </ProtectedView>
    </Styled.Container>
  );
}

export default StackAdd;
