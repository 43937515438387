import styled from 'styled-components';

import {Paper} from '@material-ui/core';

import {Drawer as BaseDrawer} from '../../../components/Base';

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
`;

export const Drawer = styled(BaseDrawer).attrs({padded: true, style: {minWidth: 700}, withBackdrop: true})``;

export const StyledPaper = styled(Paper)`
  padding: 10px;
`;
