import _ from 'lodash';
import React from 'react';

import consts from '../../../consts';
import context from '../../../context';
import hooks from '../../../hooks';
import servicesLib from '../../../services';

import Configuration from '../../../components/Configuration/Configuration';
import configurationUtils from '../../../components/Configuration/utils';
import enums from '../../../components/Configuration/enums';
import utils from './utils';
import dataUtils from '../Deployments/dataUtils';

function ServicesConfiguration() {
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showTemplatedValues, setShowTemplatedValues] = React.useState(false);
  const [services, setServices] = React.useState([]);
  const [envs, setEnvs] = React.useState([]);
  const [namespaces, setNamespaces] = React.useState([]);
  const [configuration, setConfiguration] = React.useState([]);

  const [category, setCategory] = hooks.useSearchQueryFilter(
    'category',
    consts.FILTER_OPERATORS.EQUAL,
    _.head(utils.categories).id
  );
  const [serviceId, setServiceId] = hooks.useSearchQueryFilter('serviceId', consts.FILTER_OPERATORS.EQUAL);
  const [envId, setEnvId] = hooks.useSearchQueryFilter('environmentId', consts.FILTER_OPERATORS.EQUAL);
  const [namespaceId, setNamespaceId] = hooks.useSearchQueryFilter('namespaceId', consts.FILTER_OPERATORS.EQUAL);

  const notifications = context.Notifications.useNotifications();
  const user = context.User.useUser();

  React.useEffect(() => {
    setServiceItems();
    onServiceSelect(envId, namespaceId);
    setIsFirstLoad(false);
  }, []);

  React.useEffect(() => {
    if (isFirstLoad) {
      return;
    }
    onServiceSelect();
  }, [serviceId]);

  React.useEffect(() => {
    if (isFirstLoad) {
      return;
    }
    onEnvSelect();
  }, [envId]);

  React.useEffect(() => {
    if (isFirstLoad) {
      return;
    }
    getConfiguration();
  }, [category, namespaceId, showTemplatedValues]);

  const wrappedCategories = React.useMemo(
    () =>
      _.map(utils.categories, (category) =>
        _.assign(
          {
            permissionFuncs: {
              create: (labels) => user.rbac.mtms.hasConfigPermissions(category.permissions.create, labels),
              edit: (labels) => user.rbac.mtms.hasConfigPermissions(category.permissions.edit, labels),
              read: (labels) => user.rbac.mtms.hasConfigPermissions(category.permissions.read, labels)
            }
          },
          category
        )
      ),
    [user.rbac.mtms]
  );

  async function setServiceItems() {
    try {
      const servicesResponse = await servicesLib.services.services.list({length: -1});
      const serviceItems = _.get(servicesResponse, 'data.items', []);
      setServices(_.map(serviceItems, ({id, name}) => ({id, name})));
    } catch (ex) {
      notifications.error(ex.message);
    }
  }

  function setEnvItems(envResponse) {
    const envItems = _.get(envResponse, 'data.items', []);
    setEnvs(_.map(envItems, ({key: [id, name]}) => ({id: _.toString(id), name})));
  }

  function setNamespaceItems(namespaceResponse) {
    const namespaceItems = _.get(namespaceResponse, 'data.items', []);
    setNamespaces(_.map(namespaceItems, ({key: [id, name]}) => ({id: _.toString(id), name})));
  }

  function setConfigurationItems(configurationResponse) {
    const configurationItems = _.get(configurationResponse, 'data.items', []);
    setConfiguration(_.map(configurationItems, (item) => _.defaults({value: JSON.parse(item.value)}, item)));
  }

  async function onServiceSelect(selectedEnvId = null, selectedNamespaceId = null) {
    setEnvId(selectedEnvId);
    setNamespaceId(selectedNamespaceId);

    const promises = [
      getEnvs(),
      _.isEmpty(selectedEnvId) && _.isEmpty(selectedNamespaceId) ? Promise.resolve([]) : getNamespaces(),
      servicesLib.services.configurationV2.get(category, serviceId, selectedEnvId, selectedNamespaceId)
    ];

    try {
      setIsLoading(true);
      const [newEnvs, newNamespaces, newConfiguration] = await Promise.all(promises);
      setEnvItems(newEnvs);
      setNamespaceItems(newNamespaces);
      setConfigurationItems(newConfiguration);
    } catch (ex) {
      setEnvs([]);
      setNamespaces([]);
      setConfiguration([]);
      notifications.error(ex.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function onEnvSelect() {
    setNamespaceId(null);

    const promises = [getNamespaces(), servicesLib.services.configurationV2.get(category, serviceId, envId)];

    try {
      setIsLoading(true);
      const [newNamespaces, newConfiguration] = await Promise.all(promises);
      setNamespaceItems(newNamespaces);
      setConfigurationItems(newConfiguration);
    } catch (ex) {
      setNamespaces([]);
      setConfiguration([]);
      notifications.error(ex.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSaveConfiguration(operations) {
    const promises = _.map(operations, (operation) => {
      if (operation.operation === enums.OPERATIONS.DELETE) {
        return servicesLib.services.configurationV2.deleteValue(operation.configurationValueId);
      }
      if (operation.operation === enums.OPERATIONS.UPDATE) {
        return servicesLib.services.configurationV2.updateValue(operation.configurationValueId, operation.value);
      }
      if (operation.operation === enums.OPERATIONS.CREATE) {
        return servicesLib.services.configurationV2.createValue(operation);
      }
      return Promise.resolve();
    });

    try {
      await Promise.all(promises);
      await getConfiguration();
    } catch (ex) {
      notifications.error(ex.message);
    }
  }

  async function onAddConfigurationKey(...params) {
    try {
      await servicesLib.services.configurationV2.createKey(...params);
      return getConfiguration();
    } catch (ex) {
      console.error(ex.stack);
      notifications.error(ex.message);
    }
  }

  async function onDeleteConfigurationKeyValue(configurationValueId) {
    try {
      await servicesLib.services.configurationV2.deleteValue(configurationValueId);
      return getConfiguration();
    } catch (ex) {
      console.error(ex.stack);
      notifications.error(ex.message);
    }
  }

  async function onGetConfigurationKeyVersions(configurationKeyId) {
    try {
      const {
        data: {items: versions}
      } = await servicesLib.services.configurationV2.getVersions(serviceId, envId, namespaceId, configurationKeyId);
      return _.map(versions, (version) =>
        _.defaults(
          {
            level: configurationUtils.getLevelFromLabels(labelDefinitions, version.labels),
            value: _.isEmpty(version.value) ? '' : JSON.parse(version.value)
          },
          version
        )
      );
    } catch (ex) {
      console.error(ex.stack);
      notifications.error(ex.message);
    }
  }

  async function getEnvs() {
    if (_.isEmpty(serviceId)) {
      return [];
    }
    return servicesLib.services.deployments.getEnvs();
  }

  async function getNamespaces() {
    if (_.isEmpty(envId)) {
      return [];
    }

    const searchParams = new URLSearchParams();
    searchParams.set('groupBy', 'namespaceId,namespaceName');
    searchParams.set('environmentId', _.toString(envId));
    searchParams.set('serviceId', _.toString(serviceId));
    return servicesLib.services.deployments.count(searchParams);
  }

  async function getConfiguration() {
    try {
      setIsLoading(true);
      const response = await servicesLib.services.configurationV2.get(
        category,
        serviceId,
        envId,
        namespaceId,
        showTemplatedValues
      );
      const items = _.get(response, 'data.items', []);
      setConfiguration(_.map(items, (item) => _.defaults({value: JSON.parse(item.value)}, item)));
    } catch (ex) {
      setConfiguration([]);
      notifications.error(ex.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function getAffectedEntities(levelValueArray) {
    const response = await dataUtils.getAffectedEntities(levelValueArray);
    return {
      displayValues: _.map(response, (item) =>
        _.pick(item, ['environmentName', 'namespaceName', 'stackName', 'serviceName'])
      ),
      entities: response
    };
  }

  async function getConfigurationKeys(keyName) {
    const {
      data: {items}
    } = await servicesLib.services.configurationV2.getConfigurationKeys(keyName);
    return _.map(items, (item) => _.assign({name: item.key}, item));
  }

  if (_.isEmpty(services)) {
    return null;
  }

  const service = _.find(services, {id: serviceId});
  const env = _.find(envs, {id: envId});
  const namespace = _.find(namespaces, {id: namespaceId});
  const labelDefinitions = [
    {
      defaultPermissionsFunc: user.rbac.mtms.allowed.configuration.edit.all.all,
      key: 'serviceId',
      label: 'SERVICE',
      onChange: setServiceId,
      options: services,
      permissionsFunc: user.rbac.mtms.allowed.configuration.edit.all.services,
      value: service,
      width: 2
    },
    {
      key: 'environmentId',
      label: 'ENV',
      onChange: setEnvId,
      options: envs,
      permissionsFunc: user.rbac.mtms.allowed.configuration.edit.all.envs,
      value: env,
      width: 2
    },
    {
      key: 'namespaceId',
      label: 'NAMESPACE',
      onChange: setNamespaceId,
      options: namespaces,
      permissionsFunc: user.rbac.mtms.allowed.configuration.edit.all.namespaces,
      value: namespace,
      width: 2
    }
  ];

  return (
    <Configuration
      categories={wrappedCategories}
      configuration={configuration}
      displaySettings={utils.displaySettings}
      getAffectedEntities={getAffectedEntities}
      getConfigurationKeys={getConfigurationKeys}
      isLoading={isLoading}
      labelDefinitions={labelDefinitions}
      onAddConfigurationKey={onAddConfigurationKey}
      onChangeCategory={setCategory}
      onDeleteConfigurationKeyValue={onDeleteConfigurationKeyValue}
      onGetConfigurationKeyVersions={onGetConfigurationKeyVersions}
      onChangeShowTemplatedValues={setShowTemplatedValues}
      onSaveConfiguration={onSaveConfiguration}
      permissionFuncs={{
        create: user.rbac.mtms.allowed.configuration.create.create,
        edit: user.rbac.mtms.allowed.configuration.edit.edit,
        read: user.rbac.mtms.allowed.configuration.read.read
      }}
      selectedCategory={category}
      showTemplatedValues={showTemplatedValues}
    />
  );
}

export default ServicesConfiguration;
