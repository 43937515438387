import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import MuiMenu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import context from '../../context';

import * as Styled from './SelectMenu.styles';

function SelectMenu({render, options, setIsLoading}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const user = context.User.useUser();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function isAllowed(permissions) {
    return _.isFunction(permissions) ? permissions() : user.hasRole(permissions);
  }

  function withLoading(onClickMenuItem) {
    return async () => {
      setIsLoading && setIsLoading(true);
      await onClickMenuItem();
      setIsLoading && setIsLoading(false);
    };
  }

  function renderLinkMenuItem(url, label, IconComponent, isHighlighted) {
    return (
      <Styled.MenuItem key={url} dense>
        <Styled.MenuLink to={url}>
          {IconComponent && (
            <Styled.ListItemIcon>
              <IconComponent fontSize="small" color={isHighlighted ? 'secondary' : 'inherit'} />
            </Styled.ListItemIcon>
          )}

          <Styled.ListItemText disableTypography primary={label} $isHighlighted={isHighlighted} />
        </Styled.MenuLink>
      </Styled.MenuItem>
    );
  }

  return (
    <div>
      {_.isFunction(render) ? (
        render(handleClick)
      ) : (
        <IconButton onClick={handleClick} color={'inherit'}>
          <MoreVertIcon />
        </IconButton>
      )}
      <MuiMenu
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
        transformOrigin={{horizontal: 'center', vertical: 'top'}}
      >
        {options.map(
          ({label, onClick, iconComponent: IconComponent, permissions, url = null, isHighlighted = false}, index) =>
            url ? (
              renderLinkMenuItem(url, label, IconComponent, isHighlighted)
            ) : (
              <Styled.MenuItem key={index} dense onClick={withLoading(onClick)} disabled={!isAllowed(permissions)}>
                {IconComponent && (
                  <Styled.ListItemIcon>
                    <IconComponent fontSize="small" />
                  </Styled.ListItemIcon>
                )}

                <ListItemText disableTypography primary={label} />
              </Styled.MenuItem>
            )
        )}
      </MuiMenu>
    </div>
  );
}

SelectMenu.propTypes = {
  options: PropTypes.array.isRequired,
  render: PropTypes.func,
  setIsLoading: PropTypes.func
};

export default SelectMenu;
