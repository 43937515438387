import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './TextSearchInput.styles';

function TextSearchInput({
  className,
  options,
  title,
  value,
  setValue,
  label = 'name',
  getOptionLabel,
  inputValue,
  onInputChange,
  onKeyDown,
  inputPlaceholder = '',
  ...props
}) {
  function getInputOptionLabel(option) {
    if (_.isEmpty(option)) {
      return 'null';
    }
    if (getOptionLabel) {
      return getOptionLabel(option);
    }
    if (label) {
      return option[label];
    }

    return option;
  }

  return (
    <Styled.Container className={className}>
      <Styled.AutoComplete
        PaperComponent={Styled.Paper}
        options={options}
        getOptionLabel={getInputOptionLabel}
        getOptionSelected={(option, value) => option.id === value.id}
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => (
          <Styled.AutocompleteTextField
            {...params}
            label={title}
            placeholder={inputPlaceholder}
            onKeyDown={onKeyDown}
          />
        )}
        inputValue={inputValue}
        onInputChange={onInputChange}
        {...props}
      />
    </Styled.Container>
  );
}

TextSearchInput.propTypes = {
  className: PropTypes.string,
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  inputPlaceholder: PropTypes.string,
  inputValue: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  onInputChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onOpen: PropTypes.func,
  options: PropTypes.array,
  setValue: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.any
};

export default TextSearchInput;
