import styled from 'styled-components';

import {Button as MuiButton} from '@material-ui/core';

import * as theme from '../../../../theme';

export const clickedNodeStyle = {
  backgroundColor: theme.statusBackground.info,
  lineColor: theme.statusBackground.info
};

export const Container = styled.div`
  background-color: ${theme.backgrounds.primary};
  display: flex;
  gap: 26px;
  height: 200px;
  padding: 10px;
  justify-content: space-evenly;
`;

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const Button = styled(MuiButton).attrs({color: 'secondary', variant: 'outlined'})``;

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
