import _ from 'lodash';
import React from 'react';

import {Notification, SEVERITY} from '../components/Base';

import * as Styled from './Notifications.styles';

export const NotificationsContext = React.createContext(null);

export const NotificationsContextProvider = ({children}) => {
  const [active, setActive] = React.useState([]);

  function trigger(message, severity = SEVERITY.INFO) {
    setActive((active) => [{message, severity}, ...active]);
  }

  const context = {
    error: (message) => trigger(message, SEVERITY.ERROR),
    info: (message) => trigger(message, SEVERITY.INFO),
    success: (message) => trigger(message, SEVERITY.SUCCESS),
    warning: (message) => trigger(message, SEVERITY.WARNING)
  };

  function closeNotification(message) {
    setActive((active) => _.reject(active, (current) => _.isEqual(current, message)));
  }

  return (
    <NotificationsContext.Provider value={context}>
      <Styled.Container>
        {active.map((message, index) => (
          <Notification
            key={message.message}
            severity={message.severity}
            show={true}
            onClick={() => closeNotification(message)}
          >
            {message.message}
          </Notification>
        ))}
      </Styled.Container>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => React.useContext(NotificationsContext);
