import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@material-ui/core/Chip';

import consts from '../consts';
import context from '../context';
import hooks from '../hooks';
import langs from '../localization/langs';
import utils from '../utils';

import TextSearchInput from './TextSearchInput';

import * as Styled from './AutocompleteTableFilter.styles';

const FETCH_OPTIONS_DEFAULT_OPERATOR = consts.FILTER_OPERATORS.LIKE;
const FETCH_OPTIONS_TIMEOUT_MS = 100;

function getSelectedOptionsIds(selected) {
  if (_.isArray(selected)) {
    return _(selected)
      .map('id')
      .join(',');
  }
  return _.get(selected, 'id');
}

function AutocompleteTableFilter({id, isTranslateLabels = false}) {
  const [inputValue, setInputValue] = React.useState('');

  const {fetchOptions, options, currentFilters, setUrlQuery, isLoading} = context.TableFilters.useTableFilters();
  const freeTextFilter = currentFilters.get(`${id}${FETCH_OPTIONS_DEFAULT_OPERATOR}`);

  const filters = utils.tableFilters.getFilterValue(freeTextFilter, isTranslateLabels);
  const fetchFilterOptions = hooks.useDebounce(
    (query) => fetchOptions(id, query, FETCH_OPTIONS_DEFAULT_OPERATOR),
    FETCH_OPTIONS_TIMEOUT_MS
  );

  function getOptions() {
    const optionsForFilter = _.get(options, id, []);

    if (isTranslateLabels) {
      return _.map(optionsForFilter, ({id, name}) => ({id, name: langs(name)}));
    }
    return optionsForFilter;
  }

  function onInputChange(event, newValue) {
    if (!event || event.type !== 'change') {
      return;
    }

    setInputValue(newValue);
    fetchFilterOptions(newValue);
  }

  function onSelectOption(selected) {
    setUrlQuery([[`${id}${FETCH_OPTIONS_DEFAULT_OPERATOR}`, getSelectedOptionsIds(selected)]]);
  }

  function onEnterKeyPress(event) {
    if (_.get(event, 'key') !== 'Enter') {
      return;
    }

    const value = utils.tableFilters.getFilterByOperator(inputValue, FETCH_OPTIONS_DEFAULT_OPERATOR);
    const newFilters = _.join(_.concat(getSelectedOptionsIds(filters), value), ',');
    setUrlQuery([[`${id}${FETCH_OPTIONS_DEFAULT_OPERATOR}`, newFilters]]);
  }

  function renderTags(tags, getTagProps) {
    const handleLikeTags = (tag) => tag.name.replace(/%|!/g, '');
    return _.map(tags, (tag, index) => <Chip size="small" label={handleLikeTags(tag)} {...getTagProps({index})} />);
  }

  return (
    <Styled.Container>
      <TextSearchInput
        multiple
        value={filters}
        options={getOptions()}
        setValue={onSelectOption}
        limitTags={1}
        inputValue={inputValue}
        onInputChange={onInputChange}
        size="small"
        onKeyDown={onEnterKeyPress}
        clearOnBlur={false}
        renderTags={renderTags}
        onOpen={() => fetchOptions(id, '', FETCH_OPTIONS_DEFAULT_OPERATOR)}
        loading={isLoading}
        disableClearable={true}
      />
    </Styled.Container>
  );
}

AutocompleteTableFilter.propTypes = {
  id: PropTypes.string.isRequired,
  isTranslateLabels: PropTypes.bool
};

export default AutocompleteTableFilter;
