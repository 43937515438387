import _ from 'lodash';

import consts from '../../consts';
import client from '../client';
import models from '../../models';

export async function archiveShellCommand(id) {
  return client.patch(`/maestro/api/v1/commands/${id}`, {status: 'ARCHIVED'});
}

export async function createShellCommand(command, description) {
  return client.post(`/maestro/api/v1/commands`, {
    command,
    commandType: models.command.commandType.SHELL_COMMAND,
    description,
    status: 'ACTIVE'
  });
}

export async function getAppDeployParameters() {
  const {data} = await client.get(
    `/maestro/api/v1/commands?commandType${consts.FILTER_OPERATORS.EQUAL}=${models.command.commandType.APP_DEPLOYMENT}`
  );
  return data.items[0].parameters;
}

export async function getTerraformCommand(command) {
  const {data} = await client.get(
    `/maestro/api/v1/commands?commandType${consts.FILTER_OPERATORS.EQUAL}=${models.command.commandType.TERRAFORM}&command${consts.FILTER_OPERATORS.EQUAL}=${command}`
  );
  return _.get(data, 'items[0]');
}

export async function getAppDeploymentCommand(command) {
  const {data} = await client.get(
    `/maestro/api/v1/commands?commandType${consts.FILTER_OPERATORS.EQUAL}=${models.command.commandType.APP_DEPLOYMENT}&command${consts.FILTER_OPERATORS.EQUAL}=${command}`
  );
  return _.get(data, 'items[0]');
}

export async function getRmqRelayCommand(command) {
  const {data} = await client.get(
    `/maestro/api/v1/commands?commandType${consts.FILTER_OPERATORS.EQUAL}=${models.command.commandType.WORKER_ANSIBLE}&command${consts.FILTER_OPERATORS.EQUAL}=${command}`
  );
  return _.get(data, 'items[0]');
}

export async function getTenantRmqRelay(tenant) {
  const {data} = await client.get(`/maestro/api/v1/tenants/${tenant}/shovels`);
  return _.get(data, 'items');
}

export async function stopTenantRmqRelay(tenant) {
  const {data} = await client.delete(`/maestro/api/v1/tenants/${tenant}/shovels`);
  return _.get(data, 'items[0]');
}

export async function getApplicationVersions() {
  return client.get('/maestro/api/v1/tenants/_count?groupBy=application_version');
}

export async function getEnvs(action) {
  const params = action ? action : {};
  return client.get('/maestro/api/v1/environments', params);
}

export async function getAllFilterOptions(field, query) {
  return client.get(`/maestro/api/v1/tenants/_count?${query}&groupBy=${field}&length=-1`);
}

export async function getShellCommands() {
  return client.get(
    `/maestro/api/v1/commands?length=-1&status${consts.FILTER_OPERATORS.EQUAL}=ACTIVE&commandType${consts.FILTER_OPERATORS.EQUAL}=${models.command.commandType.SHELL_COMMAND}`
  );
}

export async function getUsers(length = -1) {
  return client.get(`/maestro/api/v1/users?orderBy=fullName&length=${length}`);
}

export async function getDataFlow(params) {
  return client.get(`/maestro/api/v1/data-flows`, params);
}
