import _ from 'lodash';
import React from 'react';

import consts from '../../consts';
import context from '../../context';
import hooks from '../../hooks';
import {fields} from '../../models/collector';
import {getApplicationVersions} from '../../services/collectors/collectors';
import {SEARCH_TYPES} from '../../context/Search/utils';

import constantSearchOptions from './searchOptions';

function useCollectorsView(tenants) {
  const [applicationVersions, setApplicationVersions] = React.useState([]);

  const {searchOptions, resetSearchOptions, setOnTextSearch} = context.Search.useSearch();
  const [, setSearchQuery] = hooks.useUrlSearchParams();

  React.useEffect(() => {
    async function fetchAppVersions() {
      const appVersionsResponse = await getApplicationVersions();
      setApplicationVersions(
        appVersionsResponse.data.filter(({name}) => name).map(({name}) => ({key: name, value: name}))
      );
    }
    fetchAppVersions();
  }, []);

  React.useEffect(
    function configureSearchOptions() {
      if (!tenants.length || !applicationVersions.length) {
        return;
      }

      resetSearchOptions([
        ...constantSearchOptions,
        {
          key: fields.TENANT_NAME,
          options: tenants.map(({name}) => ({key: name, value: name})),
          supportedOperators: [consts.FILTER_OPERATORS.LIKE, consts.FILTER_OPERATORS.NOT_LIKE],
          title: 'Tenant',
          type: SEARCH_TYPES.TEXT
        },
        {
          key: fields.APPLICATION_VERSION,
          options: applicationVersions,
          supportedOperators: [consts.FILTER_OPERATORS.LIKE, consts.FILTER_OPERATORS.NOT_LIKE],
          title: 'Application Versions',
          type: SEARCH_TYPES.TEXT
        }
      ]);
    },
    [tenants, applicationVersions]
  );

  React.useEffect(() => {
    function getSearchParams(searchValue) {
      const [part1, part2] = _.split(searchValue, ':');

      if (!_.isEmpty(part1) && !_.isEmpty(part2)) {
        return {collectorNumber: part2, tenantName: part1};
      }
      if (!_.isNaN(_.toNumber(part1))) {
        return {collectorNumber: part1, tenantName: ''};
      }
      return {collectorNumber: '', tenantName: part1};
    }

    function onTextSearch(searchValue) {
      const {tenantName, collectorNumber} = getSearchParams(searchValue);
      setSearchQuery([
        [`${fields.TENANT_NAME}${consts.FILTER_OPERATORS.LIKE}`, _.isEmpty(tenantName) ? '' : `%${tenantName}%`],
        [`${fields.COLLECTOR_NUMBER}${consts.FILTER_OPERATORS.EQUAL}`, collectorNumber]
      ]);
    }

    setOnTextSearch(onTextSearch);
  }, [searchOptions]);

  return tenants;
}

export default useCollectorsView;
