import _ from 'lodash';
import Cytoscape from 'cytoscape';
import CytoscapeComponent from 'react-cytoscapejs';
import klay from 'cytoscape-klay';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../../../localization/langs';

import * as Styled from './SchemaGraph.styles';

const LAYOUT_VERTICAL = {
  directed: true,
  klay: {
    direction: 'DOWN',
    fixedAlignment: 'BALANCED',
    layoutHierarchy: true,
    spacing: 20
  },
  name: 'klay',
  nodeDimensionsIncludeLabels: true,
  padding: 10,
  spacingFactor: 1.3
};

const LAYOUT_HORIZONTAL = _.assign({}, LAYOUT_VERTICAL, {klay: {direction: 'RIGHT'}});

Cytoscape.use(klay);

function SchemaGraph({graphData, onNodeClick, vertical = true}) {
  const normalizedElements = CytoscapeComponent.normalizeElements({
    edges: graphData.edges.map((data) => ({data})),
    nodes: graphData.vertices.map((data) => ({data}))
  });

  const cy = React.useRef(null);

  const measuredRef = React.useCallback((cyRef) => {
    if (_.isNull(cyRef)) {
      return;
    }
    cy.current = cyRef;

    if (_.isFunction(onNodeClick)) {
      cy.current.once('click', 'node[type= "role"]', function onClick(event) {
        const node = event.target.data();
        console.log('shit', node);
        onNodeClick(node);
      });
    }
  }, []);

  return _.isEmpty(graphData) ? (
    <div>{langs('LOADING')}</div>
  ) : (
    <Styled.Cytoscape
      layout={vertical ? LAYOUT_VERTICAL : LAYOUT_HORIZONTAL}
      cy={measuredRef}
      elements={normalizedElements}
    />
  );
}

SchemaGraph.propTypes = {
  graphData: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    vertices: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired,
  onNodeClick: PropTypes.func,
  vertical: PropTypes.bool
};

export default SchemaGraph;
