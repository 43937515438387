import moment from 'moment';

import filterConsts from './filterUtils/filterConsts';
import langs from '../../localization/langs';

import DateColumn from '../../components/AGTable/columns/DateColumn';

const BASIC_COL = {
  filter: true,
  floatingFilter: true,
  sortable: true
};
const NULLABLE_COL = {
  ...BASIC_COL,
  render: ({value}) => (value ? '✓' : '✕')
};
const BOOL_COL = {
  ...NULLABLE_COL,
  isBoolean: true
};
const DATE_FILTER_OPTIONS = [
  filterConsts.NUMERIC_FILTER_TYPES.GREATER_OR_EQUAL,
  filterConsts.NUMERIC_FILTER_TYPES.RANGE,
  filterConsts.NUMERIC_FILTER_TYPES.EQUALS,
  filterConsts.NUMERIC_FILTER_TYPES.LESS_OR_EQUAL
];
const DATE_COL = {
  ...BASIC_COL,
  filter: 'agDateColumnFilter',
  filterParams: {filterOptions: DATE_FILTER_OPTIONS},
  render: DateColumn,
  tooltip: (data, {value}) => moment(value).toString()
};
const ENUM_COL = {
  ...BASIC_COL,
  filterParams: {valueFormatter: ({value}) => langs(value)},
  render: ({value}) => langs(value),
  tooltip: (data, {value}) => langs(value)
};
const NUMBER_COL = {
  ...BASIC_COL,
  filter: 'agNumberColumnFilter'
};
export default {
  BASIC_COL,
  BOOL_COL,
  DATE_COL,
  ENUM_COL,
  NULLABLE_COL,
  NUMBER_COL
};
