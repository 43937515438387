import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Switch from '@material-ui/core/Switch';

import langs from '../../../localization/langs';

import ArrayOfStringsInput from './ArrayOfStringsInput';

import models from '../../../models';
import utils from './DynamicInput.utils';

import * as Styled from './DynamicInput.styles';

function DynamicInput({type, value, onChange, disabled, extendedTypes, ...props}) {
  function handleChange({target: {value}}) {
    onChange(value);
  }

  const renderExtendedType = _.get(extendedTypes, type);
  if (!_.isNil(renderExtendedType)) {
    return renderExtendedType({disabled, onChange, value});
  }

  return (
    <Styled.Container>
      {type === models.configuration.TYPES.BOOLEAN ? (
        <Switch
          checked={value}
          disabled={disabled}
          onChange={({target: {checked}}) => onChange(checked)}
          size="small"
          {...props}
        />
      ) : type === models.configuration.TYPES.SECRET ? (
        <Styled.Input fullWidth disabled={disabled} type="password" value={value} onChange={handleChange} {...props} />
      ) : type === models.configuration.TYPES.STRING ? (
        <Styled.Input fullWidth disabled={disabled} value={value ?? undefined} onChange={handleChange} {...props} />
      ) : type === models.configuration.TYPES.INTEGER ? (
        <Styled.Input
          fullWidth
          disabled={disabled}
          onChange={({target}) => onChange(utils.castToInteger(target.value))}
          type="number"
          value={value ?? undefined}
          {...props}
        />
      ) : type === models.configuration.TYPES.FLOAT ? (
        <Styled.Input
          fullWidth
          disabled={disabled}
          onChange={({target}) => onChange(utils.castToFloat(target.value))}
          type="number"
          value={value}
          {...props}
        />
      ) : type === models.configuration.TYPES.TEXT || type === models.configuration.TYPES.YAML ? (
        <Styled.Input disabled={disabled} multiline rows={2} value={value} onChange={handleChange} {...props} />
      ) : type === models.configuration.TYPES.DATE_RANGE ? (
        <div>
          <Styled.Label>{langs('START_HOUR')}</Styled.Label>
          <Styled.Input
            fullWidth
            disabled={disabled}
            onChange={({target}) => onChange({end: value.end, start: utils.castToInteger(target.value)})}
            type="number"
            value={value.start}
            {...props}
          />
          <Styled.Label>{langs('END_HOUR')}</Styled.Label>
          <Styled.Input
            fullWidth
            disabled={disabled}
            onChange={({target}) => onChange({end: utils.castToInteger(target.value), start: value.start})}
            type="number"
            value={value.end}
            {...props}
          />
        </div>
      ) : type === models.configuration.TYPES.CONTAINER ? (
        <div>
          <Styled.Label>image</Styled.Label>
          <Styled.Input
            fullWidth
            disabled={disabled}
            onChange={({target}) => onChange({image: target.value, tag: value.tag})}
            value={value?.image}
            {...props}
          />
          <Styled.Label>tag</Styled.Label>
          <Styled.Input
            fullWidth
            disabled={disabled}
            onChange={({target}) => onChange({image: value.image, tag: target.value})}
            value={value?.tag}
            {...props}
          />
        </div>
      ) : type === models.configuration.TYPES.ARRAY_OF_STRINGS ? (
        <ArrayOfStringsInput disabled={disabled} value={value ?? []} onChange={onChange} {...props} />
      ) : (
        <Styled.Input
          fullWidth
          disabled={disabled}
          onChange={({target: {value}}) => onChange(utils.castToJson(value))}
          value={utils.castToString(value)}
          {...props}
        />
      )}
    </Styled.Container>
  );
}

DynamicInput.propTypes = {
  config: PropTypes.any,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  extendedTypes: PropTypes.objectOf(PropTypes.elementType),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.any
};

export default DynamicInput;
