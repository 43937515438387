import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './Paper.styles';

function Paper({children, ...props}) {
  return <Styled.Container {...props}>{children}</Styled.Container>;
}

Paper.propTypes = {
  children: PropTypes.node,
  padding: PropTypes.number
};

export default Paper;
