import _ from 'lodash';
import React from 'react';

import context from '../../../context';
import langs from '../../../localization/langs';

import DEFAULTS from './Parts/consts';
import FiltersRow from './Parts/FiltersRow/FiltersRow';
import SchemaGraph from './Parts/SchemaGraph/SchemaGraph';

const EMPTY_GRAPH_DATA = {
  edges: [],
  vertices: []
};

function FullSchemaViewer() {
  const notifications = context.Notifications.useNotifications();
  const user = context.User.useUser();

  const [schema, setSchema] = React.useState();

  const [oktaUserLogin, setOktaUserLogin] = React.useState('');
  const [selectedEnvs, setSelectedEnvs] = React.useState([]);
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [selectedRoles, setSelectedRoles] = React.useState([]);
  const [selectedServices, setSelectedServices] = React.useState(DEFAULTS.SERVICES);
  const [viewMode, setViewMode] = React.useState(DEFAULTS.VIEW_MODE.GROUPS);

  const isSingleGroupMode = _.isEmpty(oktaUserLogin);

  const isGroupsMode = viewMode === DEFAULTS.VIEW_MODE.GROUPS;

  const getGraphData = () => {
    if (isGroupsMode) {
      return isSingleGroupMode
        ? _.get(transformedSchema, ['groups', _.head(selectedGroups)], EMPTY_GRAPH_DATA)
        : user.rbac.schema.transform.userGraph(oktaUserLogin, transformedSchema, false, selectedGroups);
    } else if (viewMode === DEFAULTS.VIEW_MODE.ROLES) {
      return _.get(transformedSchema, ['roles', _.head(selectedRoles)], EMPTY_GRAPH_DATA);
    }
  };

  React.useEffect(() => {
    setSchema(null);
    user.rbac.schema
      .get(oktaUserLogin)
      .then((schema) => {
        const defaultEnv = _.head(user.rbac.schema.getEnvsNamesFrom(schema));
        setSchema(schema);
        setSelectedEnvs([defaultEnv]);
      })
      .catch((error) => {
        console.error(error);
        notifications.error(langs('ERROR_RETRIEVING_SCHEMA'));
      });
  }, [oktaUserLogin]);

  const envs = React.useMemo(() => user.rbac.schema.getEnvsNamesFrom(schema), [schema]);

  const transformedSchema = React.useMemo(
    () =>
      schema
        ? user.rbac.schema.transform.asGraph(schema, selectedEnvs, selectedServices, true, selectedRoles)
        : {groups: {}, timestamp: Date.now()},
    [schema, selectedEnvs, selectedGroups, selectedRoles, selectedServices]
  );

  const graphData = getGraphData();

  const roleOptions = React.useMemo(
    () =>
      _.transform(
        transformedSchema.roles,
        (options, role) =>
          options.push({
            key: role.id,
            value: role.name
          }),
        []
      ),
    [transformedSchema]
  );

  React.useEffect(() => {
    const userGroups = _.keys(transformedSchema.groups);
    if (!_.isEmpty(userGroups) && _.isEmpty(selectedGroups)) {
      setSelectedGroups([_.head(userGroups)]);
    }
  }, [graphData]);

  const onNodeClick = isGroupsMode
    ? (node) => {
        setSelectedRoles((prevSelectedRoles) => {
          const nodeId = _.parseInt(node.id, 10);
          return _.includes(prevSelectedRoles, nodeId) ? _.without(prevSelectedRoles, nodeId) : [nodeId];
        });
      }
    : null;

  return (
    <div>
      {_.isEmpty(schema) ? (
        langs('LOADING')
      ) : (
        <>
          {isGroupsMode && (
            <FiltersRow
              envs={envs}
              groups={_.keys(transformedSchema.groups)}
              isSingleGroupMode={isSingleGroupMode}
              oktaUserLogin={oktaUserLogin}
              selectedEnvs={selectedEnvs}
              selectedGroups={selectedGroups}
              selectedServices={selectedServices}
              setOktaUserLogin={setOktaUserLogin}
              setSelectedEnvs={setSelectedEnvs}
              setSelectedGroups={setSelectedGroups}
              setSelectedServices={setSelectedServices}
              setViewMode={setViewMode}
              viewMode={viewMode}
            />
          )}
          {viewMode === DEFAULTS.VIEW_MODE.ROLES && (
            <FiltersRow
              oktaUserLogin={oktaUserLogin}
              roles={roleOptions}
              selectedRoles={selectedRoles}
              setOktaUserLogin={setOktaUserLogin}
              setSelectedRoles={setSelectedRoles}
              setViewMode={setViewMode}
              viewMode={viewMode}
            />
          )}
          <SchemaGraph
            key={transformedSchema.timestamp}
            graphData={graphData}
            onNodeClick={onNodeClick}
            vertical={isGroupsMode}
          />
        </>
      )}
    </div>
  );
}

export default FullSchemaViewer;
