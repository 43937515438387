import consts from '../../../../consts';

import {envAwareHasPermission, getEnvNameFromLabels, hasAllPermission} from './common';

const PERMISSIONS = consts.rbac.permissions.maestro;

function getDirectAccessors(maestroAccessors) {
  return {
    allowed: {
      tenant: {
        configuration: {
          create: {
            all: {
              all: () =>
                hasAllPermission(
                  maestroAccessors.hasPermission,
                  PERMISSIONS.tenant.configuration.create.all.all,
                  maestroAccessors.allEnvs
                ),
              envs: (labels) =>
                maestroAccessors.hasPermission(
                  PERMISSIONS.tenant.configuration.create.all.envs,
                  getEnvNameFromLabels(labels)
                ),
              tenants: (labels) =>
                maestroAccessors.hasPermission(
                  PERMISSIONS.tenant.configuration.create.all.tenants,
                  getEnvNameFromLabels(labels)
                )
            },
            create: (labels) =>
              envAwareHasPermission(
                maestroAccessors.hasPermission,
                PERMISSIONS.tenant.configuration.create.create,
                labels,
                maestroAccessors.allEnvs
              )
          },
          delete: {
            all: {
              all: () =>
                hasAllPermission(
                  maestroAccessors.hasPermission,
                  PERMISSIONS.tenant.configuration.delete.all.all,
                  maestroAccessors.allEnvs
                ),
              envs: (labels) =>
                maestroAccessors.hasPermission(
                  PERMISSIONS.tenant.configuration.delete.all.envs,
                  getEnvNameFromLabels(labels)
                ),
              tenants: (labels) =>
                maestroAccessors.hasPermission(
                  PERMISSIONS.tenant.configuration.delete.all.tenants,
                  getEnvNameFromLabels(labels)
                )
            },
            delete: (labels) =>
              envAwareHasPermission(
                maestroAccessors.hasPermission,
                PERMISSIONS.tenant.configuration.delete.delete,
                labels,
                maestroAccessors.allEnvs
              )
          },
          edit: {
            all: {
              all: () =>
                hasAllPermission(
                  maestroAccessors.hasPermission,
                  PERMISSIONS.tenant.configuration.edit.all.all,
                  maestroAccessors.allEnvs
                ),
              envs: (labels) =>
                maestroAccessors.hasPermission(
                  PERMISSIONS.tenant.configuration.edit.all.envs,
                  getEnvNameFromLabels(labels)
                ),
              tenants: (labels) =>
                maestroAccessors.hasPermission(
                  PERMISSIONS.tenant.configuration.edit.all.tenants,
                  getEnvNameFromLabels(labels)
                )
            },
            edit: (labels) =>
              envAwareHasPermission(
                maestroAccessors.hasPermission,
                PERMISSIONS.tenant.configuration.edit.edit,
                labels,
                maestroAccessors.allEnvs
              )
          },
          read: {
            all: {
              all: () =>
                hasAllPermission(maestroAccessors.hasPermission, PERMISSIONS.tenant.configuration.read.all.all),
              envs: (labels) =>
                maestroAccessors.hasPermission(
                  PERMISSIONS.tenant.configuration.read.all.envs,
                  getEnvNameFromLabels(labels)
                ),
              tenants: (labels) =>
                maestroAccessors.hasPermission(
                  PERMISSIONS.tenant.configuration.read.all.tenants,
                  getEnvNameFromLabels(labels)
                )
            },
            read: (labels) =>
              envAwareHasPermission(
                maestroAccessors.hasPermission,
                PERMISSIONS.tenant.configuration.read.read,
                labels,
                maestroAccessors.allEnvs
              )
          }
        },
        create: () => maestroAccessors.hasPermission(PERMISSIONS.tenant.create),
        destroy: () => maestroAccessors.hasPermission(PERMISSIONS.tenant.destroy),
        edit: {
          monitoring: () => maestroAccessors.hasPermission(PERMISSIONS.tenant.edit.monitoring),
          stage: () => maestroAccessors.hasPermission(PERMISSIONS.tenant.edit.stage)
        },
        stop: () => maestroAccessors.hasPermission(PERMISSIONS.tenant.stop),
        task: {
          runAll: () => maestroAccessors.hasPermission(PERMISSIONS.tenant.task.runAll),
          runElevated: {
            critical: () => maestroAccessors.hasPermission(PERMISSIONS.tenant.task.runElevated.critical),
            high: () => maestroAccessors.hasPermission(PERMISSIONS.tenant.task.runElevated.high),
            low: () => maestroAccessors.hasPermission(PERMISSIONS.tenant.task.runElevated.low),
            medium: () => maestroAccessors.hasPermission(PERMISSIONS.tenant.task.runElevated.medium)
          },
          runFieldAction: () => maestroAccessors.hasPermission(PERMISSIONS.task.runFieldAction),
          runNonElevated: () => maestroAccessors.hasPermission(PERMISSIONS.tenant.task.runNonElevated),
          runShellCommand: () => maestroAccessors.hasPermission(PERMISSIONS.tenant.task.runShellCommand)
        },
        update: () => maestroAccessors.hasPermission(PERMISSIONS.tenant.update)
      }
    }
  };
}

export default {
  getDirectAccessors
};
