import styled from 'styled-components';

import * as theme from '../../../theme';

import {Paper} from '../../Base';

export const StatsPaper = styled(Paper)`
  padding: 2rem;
  font-size: 1rem;
  line-height: initial;
  color: ${theme.text.primary}c2;

  & > *:not(:last-child) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & hr {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
`;
