import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../context';
import langs from '../../localization/langs';
import services from '../../services';

import ServiceForm from './ServiceForm/ServiceForm';

import * as Styled from './ServicePreview.styles';

function ServicePreview({serviceId, onClose}) {
  const notifications = context.Notifications.useNotifications();

  async function updateService(id, name, owner, description, versionRange, dbInfo, useRedis, stacks, chart, cron) {
    try {
      const stackIds = _.map(stacks, 'id');
      await services.services.services.update(id, name, owner, description, versionRange, stackIds, chart, cron);
      notifications.success(langs('SERVICES_SUCCESSFULLY_UPDATED'));
      onClose();
    } catch (err) {
      console.error(err.stack);
      notifications.error(langs('SERVICES_FAILED_TO_UPDATE'));
    }
  }

  if (_.isEmpty(serviceId)) {
    return null;
  }
  return (
    <>
      <Styled.Title>{langs('SERVICES_EDIT_SERVICE')}</Styled.Title>
      <ServiceForm serviceId={serviceId} onSubmit={updateService} />
    </>
  );
}

ServicePreview.propTypes = {
  onClose: PropTypes.func,
  serviceId: PropTypes.string
};

export default ServicePreview;
