import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: row;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  user-select: none;
`;

export const Wrapper = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
