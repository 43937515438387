import PropTypes from 'prop-types';
import React from 'react';

import models from '../../../../models';

import {DynamicInput, Form, Paper} from '../../../../components/Base';

function ReasonInput({reason, setReason}) {
  return (
    <Paper style={{paddingBlock: '1em'}}>
      <Form.Field>
        <DynamicInput onChange={setReason} type={models.configuration.TYPES.TEXT} value={reason} />
      </Form.Field>
    </Paper>
  );
}

ReasonInput.propTypes = {
  reason: PropTypes.string.isRequired,
  setReason: PropTypes.func.isRequired
};

export default ReasonInput;
