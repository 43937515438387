import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../localization/langs';

import * as Styled from './StackedBarChart.styles';

function StackedBarChart({value, onClick, top = 5, skipColors = 0}) {
  const values = _(value)
    .orderBy(['count'], ['desc'])
    .take(top)
    .value();
  const otherValues = _(value)
    .orderBy(['count'], ['desc'])
    .drop(top)
    .value();
  const countOther = _.sumBy(otherValues, 'count');

  if (countOther > 0) {
    values.push({
      count: countOther,
      label: langs('OTHER_MORE', {count: otherValues.length}),
      name: _.map(otherValues, 'name')
    });
  }

  return (
    <div>
      <Styled.Container>
        {_.map(values, ({count, name, label}, index) => (
          <Styled.Bar
            active={index === 0}
            key={index}
            onClick={() => onClick(name)}
            label={label}
            value={count}
            colorId={index + skipColors}
          />
        ))}
      </Styled.Container>
      <div>
        {_.map(values, ({count, name, label}, index) => (
          <div key={index}>
            <Styled.Label active={index === 0} onClick={() => onClick(name)}>
              <Styled.Dot colorId={index + skipColors} />
              <Styled.Percent active={index === 0}>{count.toLocaleString()}</Styled.Percent> {label}
            </Styled.Label>
          </div>
        ))}
      </div>
    </div>
  );
}

StackedBarChart.propTypes = {
  onClick: PropTypes.func.isRequired,
  skipColors: PropTypes.number,
  top: PropTypes.number,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default StackedBarChart;
