import _ from 'lodash';

export const NONE_ROLE_PREFIX = 'none-role-';
export const ROLE_PREFIX = 'is-role-';

export function groupAnsibleLog(log) {
  let roleIndex = 0;
  const groupedLog = _.transform(
    log,
    (res, record) => {
      const uuid = _.get(record, 'uuid');
      const parent_uuid = _.get(record, 'parent_uuid');
      const role = _.get(record, 'event_data.role');

      if (_.isEmpty(role) && !_.isEmpty(res[`${roleIndex};${NONE_ROLE_PREFIX}${parent_uuid}`])) {
        res[`${roleIndex};${NONE_ROLE_PREFIX}${parent_uuid}`].push(record);
      } else if (_.isEmpty(role)) {
        roleIndex++;
        (res[`${roleIndex};${NONE_ROLE_PREFIX}${uuid}`] = []).push(record);
      } else if (res[`${roleIndex};${ROLE_PREFIX}${role}`]) {
        res[`${roleIndex};${ROLE_PREFIX}${role}`].push(record);
      } else {
        roleIndex++;
        (res[`${roleIndex};${ROLE_PREFIX}${role}`] = []).push(record);
      }
    },
    {}
  );

  return _.transform(
    groupedLog,
    (res, groupedRoles, roleName) => {
      let fileIndex = 0;
      res[roleName] = _.transform(
        groupedRoles,
        (innerRes, record) => {
          record = enrichWithIncludeFile(groupedRoles, record);

          const uuid = _.get(record, 'uuid');
          const parent_uuid = _.get(record, 'parent_uuid');
          const includedFile = _.get(record, 'event_data.included_file');
          const taskPath = _.head(_.split(_.get(record, 'event_data.task_path'), ':'));

          if (!_.isEmpty(includedFile) && !_.isEmpty(innerRes[`${fileIndex};${includedFile}`])) {
            innerRes[`${fileIndex};${includedFile}`].push(record);
          } else if (!_.isEmpty(includedFile)) {
            fileIndex++;
            (innerRes[`${fileIndex};${includedFile}`] = []).push(record);
          } else if (!_.isEmpty(innerRes[`${fileIndex};${taskPath}`])) {
            innerRes[`${fileIndex};${taskPath}`].push(record);
          } else if (!_.isEmpty(innerRes[`${fileIndex};${parent_uuid}`])) {
            innerRes[`${fileIndex};${parent_uuid}`].push(record);
          } else {
            fileIndex++;
            (innerRes[`${fileIndex};${uuid}`] = []).push(record);
          }
        },
        {}
      );
    },
    {}
  );
}

function enrichWithIncludeFile(records, recordToSearch) {
  const uuid = _.get(recordToSearch, 'uuid');
  const parentUuid = _.get(recordToSearch, 'parent_uuid');

  const matchedRecord = _.find(records, (record) => {
    const includedFile = _.get(record, 'event_data.included_file');
    return (record.parent_uuid === uuid || record.parent_uuid === parentUuid) && !_.isEmpty(includedFile);
  });

  if (!_.isEmpty(matchedRecord)) {
    const newIncludeFile = _.get(matchedRecord, 'event_data.included_file');
    const eventData = _.assign({}, recordToSearch.event_data, {included_file: newIncludeFile});
    return _.assign({}, recordToSearch, {event_data: eventData});
  }
  return recordToSearch;
}
