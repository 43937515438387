import styled, {css} from 'styled-components';

import * as theme from '../../theme';

export const Lightbox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background: ${theme.modal.lightboxBackground};

  backdrop-filter: blur(1px);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  background: ${theme.modal.background};
  min-width: 300px;
  min-height: 150px;
  position: relative;
  display: flex;
  flex-direction: column;

  ${(props) =>
    !props.noPadding &&
    css`
      padding: 1rem;
    `};
`;

export const Close = styled.div`
  position: absolute;

  right: 1rem;
  top: 1rem;

  cursor: pointer;
  color: ${theme.modal.closeButtonColor};

  &:hover {
    color: ${theme.link.hover};
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  opacity: 0.9;
  letter-spacing: -0.5px;
`;

export const Body = styled.div`
  flex: 1;
`;

export const Navigation = styled.div`
  display: flex;
`;

export const Spacer = styled.div`
  flex: 1;
`;
