import {ENFORCEMENT_LEVEL, SERVICE_NAMES_BE, SERVICE_NAMES_FE} from '../../../consts/rbac/rbac';
import {getRbacAccessors} from './accessors';
import {hasPermission} from './base';
import {registerWindowRbacFunctions} from './windowFunctions';
import {
  composeUserPermissionsGraph,
  getEnvNamesFromSchema,
  transformSchemaAsGraph,
  transformSchemaAsGroups,
  transformSchemaAsRaw
} from './schemaUtils/schemaUtils';

export default {
  composeUserPermissionsGraph,
  consts: {
    ENFORCEMENT_LEVEL,
    SERVICE_NAMES: {
      BE: SERVICE_NAMES_BE,
      FE: SERVICE_NAMES_FE
    }
  },
  getEnvNamesFromSchema,
  getRbacAccessors,
  hasPermission,
  registerWindowRbacFunctions,
  transformSchemaAsGraph,
  transformSchemaAsGroups,
  transformSchemaAsRaw
};
