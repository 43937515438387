import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../context';
import hooks from '../../hooks';
import langs from '../../localization/langs';
import services from '../../services';

import {Button, Form, Input, ListSelection} from '../../components/Base';

import * as Styled from './StacksForm.styles';

function StacksForm({stackId, onSubmit}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [stackName, setStackName] = React.useState('');
  const [selectedServices, setSelectedServices] = React.useState([]);

  const notifications = context.Notifications.useNotifications();

  React.useEffect(() => {
    if (_.isEmpty(stackId)) {
      return;
    }
    services.services.stacks
      .getById(stackId)
      .then(({data: {name, stackServices}}) => {
        setStackName(name);
        setSelectedServices(_.map(stackServices, ({serviceId, serviceName}) => ({id: serviceId, name: serviceName})));
      })
      .catch((err) => {
        console.error(err.stack);
        notifications.error(langs('STACKS_FAILED_TO_LOAD'));
      });
  }, []);

  const [servicesResponse] = hooks.useGetResult(() => services.services.services.list({length: -1}), []);

  async function onFormSubmit() {
    const formattedServices = _.map(selectedServices, ({id, name}) => ({serviceId: id, serviceName: name}));
    try {
      setIsLoading(true);
      await onSubmit(stackId, stackName, formattedServices);
    } catch (err) {
      console.error(err.stack);
    } finally {
      setIsLoading(false);
    }
  }

  const allServices = _.map(servicesResponse?.items, ({id, name}) => ({id, name}));
  return (
    <Form>
      <Form.Field>
        <Form.Label>{langs('STACKS_FIELD_NAME')}</Form.Label>
        <Input
          type="text"
          value={stackName}
          onChange={(e) => setStackName(e.target.value)}
          disabled={!_.isEmpty(stackId)}
        />
      </Form.Field>
      <Form.Field>
        <Form.Label>{langs('STACKS_FIELD_SERVICES')}</Form.Label>
        <ListSelection selectedItems={selectedServices} items={allServices} onChange={setSelectedServices} />
      </Form.Field>
      <Styled.BottomBar>
        <Button disabled={_.isEmpty(stackName) || isLoading} loading={isLoading} onClick={onFormSubmit}>
          {langs('BUTTON_SAVE')}
        </Button>
      </Styled.BottomBar>
    </Form>
  );
}

StacksForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  stackId: PropTypes.string
};

export default StacksForm;
