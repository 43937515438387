import _ from 'lodash';
import React from 'react';

import {useTheme} from '@material-ui/core/styles';

import hooks from '../../../hooks';
import context from '../../../context';
import consts from '../../../consts';
import langs from '../../../localization/langs';
import services from '../../../services';
import staticData from '../../../models/staticData';
import tenant from '../../../models/tenant';

import {SEARCH_TYPES} from '../../../context/Search/utils';

function useCompactMode(tenants) {
  const [applicationVersions, setApplicationVersions] = React.useState([]);
  const [selectedTenant, setSelectedTenant] = React.useState(null);

  const theme = useTheme();
  const environments = context.Environments.useEnvironments();
  const {resetSearchOptions, setOnTextSearch} = context.Search.useSearch();
  const [searchQuery, setSearchQuery, getSearchQueryParam, setUrlSearchParams] = hooks.useUrlSearchParams();

  const taskId = getSearchQueryParam('taskId', consts.FILTER_OPERATORS.EQUAL);

  React.useEffect(() => {
    async function fetchAppVersions() {
      const appVersionsResponse = await services.tenants.getApplicationVersions();
      return appVersionsResponse.data.filter(({name}) => name).map(({name}) => ({key: name, value: name}));
    }

    fetchAppVersions().then(setApplicationVersions);
  }, []);

  React.useEffect(
    function configureSearchOptions() {
      if (!tenants.length) {
        return;
      }

      resetSearchOptions([
        {
          key: 'environmentType',
          options: [
            {key: 'staging', value: 'Staging'},
            {key: 'development', value: 'Development'},
            {key: 'test', value: 'Test'},
            {key: 'production', value: 'Production'}
          ],
          title: 'Environment Type',
          type: SEARCH_TYPES.CHIPS
        },
        {
          key: 'environmentName',
          options: _.map(environments, ({name}) => ({key: name, value: _.capitalize(name)})),
          title: 'Environment Name',
          type: SEARCH_TYPES.CHIPS
        },
        {
          key: 'stage',
          options: _.map(staticData.TENANT.STAGES_TRANSLATED, (value) => ({key: value, value})),
          title: 'Stage',
          type: SEARCH_TYPES.CHIPS
        },
        {
          key: 'status',
          options: [
            {key: 'AVAILABILITY_STATUS_ACTIVE', value: 'Active'},
            {key: 'AVAILABILITY_STATUS_INACTIVE', value: 'Inactive'}
          ],
          title: 'Status',
          type: SEARCH_TYPES.CHIPS
        },
        {
          key: tenant.fields.NAME,
          options: tenants.map((tenant) => ({key: tenant.name, value: tenant.name})),
          title: langs('TENANT_NAME'),
          type: SEARCH_TYPES.TEXT
        },
        {
          key: 'applicationVersion',
          options: applicationVersions,
          title: langs('APP_VERSION'),
          type: SEARCH_TYPES.TEXT
        },
        {
          key: 'patches',
          title: langs('TENANT_PATCHED'),
          type: SEARCH_TYPES.BOOLEAN,
          useNullComparison: true
        }
      ]);
    },
    [tenants]
  );

  React.useEffect(() => {
    function onTextSearch(searchValue) {
      setSearchQuery([
        [`${tenant.fields.NAME}${consts.FILTER_OPERATORS.LIKE}`, _.isEmpty(searchValue) ? '' : `%${searchValue}%`]
      ]);
    }
    setOnTextSearch(onTextSearch);
  }, []);

  return {
    searchQuery,
    selectedTenant,
    setSelectedTenant,
    setUrlSearchParams,
    taskId,
    theme
  };
}

export default useCompactMode;
