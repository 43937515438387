import _ from 'lodash';
import React from 'react';

import context from '../../../context';
import langs from '../../../localization/langs';

import {ConfirmationModal} from '../../Base';

function hasPermissions(user, permission, environmentName, rbacControlObject) {
  if (rbacControlObject) {
    return rbacControlObject.hasPermissions(permission, environmentName);
  }
  if (_.isUndefined(permission)) {
    return true;
  }
  if (_.isBoolean(permission)) {
    return permission;
  }
  return user.hasRole(permission);
}

export default function useMenuItem(onConfirm, name, icon, permission, rbacControlObject, Modal = ConfirmationModal) {
  const [row, setRow] = React.useState(null);
  const user = context.User.useUser();

  const handleConfirm = React.useCallback(async () => {
    await onConfirm(row);
    setRow(null);
  }, [row]);

  function getDef(row) {
    const environmentName = _.get(row, 'environmentName');
    const isAllowed = hasPermissions(user, permission, environmentName, rbacControlObject);
    const missingPermissions = rbacControlObject
      ? rbacControlObject.getMissingPermissionsStr(permission, environmentName)
      : '';

    return {
      action: () => setRow(row),
      disabled: !isAllowed,
      icon,
      name: _.isFunction(name) ? name(row) : name,
      tooltip: isAllowed ? null : langs('INSUFFICIENT_PERMISSIONS', {missingPermissions})
    };
  }

  return [getDef, <Modal open={!!row} onConfirm={handleConfirm} onCancel={() => setRow(null)} />, row];
}
