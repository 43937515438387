import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import langs from '../../../../localization/langs';

import * as Styled from './List.styles';

function List({items, icon, onChange, render, ...props}) {
  const [search, setSearch] = React.useState('');

  function renderItem(item) {
    return (
      <Styled.ListItem key={item.id} onClick={() => onChange(item)}>
        <Styled.IconButtonStyled component={icon} />
        <span>{item.name}</span>
      </Styled.ListItem>
    );
  }

  return (
    <Styled.Container {...props}>
      <Styled.Input
        type="text"
        placeholder={langs('SEARCH_WITH_DOTS')}
        value={search}
        onChange={(event) => setSearch(event.currentTarget.value)}
      />
      <Styled.List>
        {_(items)
          .filter(({name}) => _.includes(name, search))
          .orderBy('name')
          .map((item) => (_.isFunction(render) ? render(item) : renderItem(item)))
          .value()}
      </Styled.List>
    </Styled.Container>
  );
}

List.propTypes = {
  icon: PropTypes.elementType.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  render: PropTypes.func
};

export default List;
