import React from 'react';

import useInterval from '@rooks/use-interval';

import context from '../../context';
import langs from '../../localization/langs';

const REFRESH_INTERVAL = 5_000;

function useCommandOutput(autoRefresh, fetchOutput) {
  const [output, setOutput] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const notifications = context.Notifications.useNotifications();

  async function fetchCommandOutput() {
    try {
      setIsLoading(true);

      const {data} = await fetchOutput();

      setOutput(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      notifications.error(langs('ERROR_FETCHING_COMMAND_OUTPUT'));
    }
  }

  const {start: startInterval, stop: stopInterval} = useInterval(fetchCommandOutput, REFRESH_INTERVAL);

  React.useEffect(() => {
    async function fetchOutputWithInterval() {
      await fetchCommandOutput();

      if (autoRefresh) {
        startInterval();
      }
    }

    fetchOutputWithInterval();

    return () => {
      stopInterval();
      setOutput('');
    };
  }, []);

  return {isLoading, output};
}

export default useCommandOutput;
