import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import consts from '../../../../../consts';

import ExternalLinkColumn from '../../../../AGTable/columns/ExternalLinkColumn';

function JiraTickets({value}) {
  if (!value) {
    return null;
  }
  const jiraTickets = value.split(',');
  return _.map(jiraTickets, (jiraTicket, index) => {
    return <ExternalLinkColumn key={index} value={jiraTicket} urlPrefix={consts.URL_PREFIX_JIRA_TICKET} />;
  });
}

JiraTickets.propTypes = {
  value: PropTypes.string
};

export default JiraTickets;
