import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import columnDefs from '../../../utils/agGridUtils/columnDefs';
import langs from '../../../localization/langs';
import models from '../../../models';
import utils from './table.utils';

import AgTable from '../../../components/AGTable/Table';
import CollectorNumberAg from './Parts/Columns/CollectorNumberAg';
import Column from '../../../components/AGTable/Column';
import IntegrationsList from './Parts/Columns/IntegrationsList';
import useUrlSearch from '../../AGTable/hooks/useUrlSearch';

function Table({
  getContextMenuItems,
  getRows,
  isSingleTenantView,
  selectedCollectors,
  setCollectorPreview,
  setSelectedCollectors,
  setTableApi,
  tableApi,
  taskId
}) {
  const [filterModel, onFilterChanged] = useUrlSearch(tableApi);

  const asyncFilterProps = {...columnDefs.BASIC_COL, filter: utils.fetchFilterOptions};

  return (
    <AgTable
      data={getRows}
      filterModel={filterModel}
      getContextMenuItems={getContextMenuItems}
      onFilterChanged={onFilterChanged}
      onRowClicked={({data}) => setCollectorPreview(data)}
      onSelect={setSelectedCollectors}
      pagination
      selected={selectedCollectors}
      setTableApi={setTableApi}
      rowIdField="collectorId"
    >
      <Column
        {...columnDefs.NUMBER_COL}
        colId={models.collector.fields.COLLECTOR_NUMBER}
        exportFunc={({collectorNumber}) => collectorNumber}
        title={langs('NUMBER')}
        render={CollectorNumberAg}
        tooltip={(data) => langs('COLLECTOR_ID', data)}
        value={_.identity}
      />
      <Column
        {...columnDefs.ENUM_COL}
        filter={_.values(models.staticData.COLLECTOR.TASK_ACTIVITY_STATUS)}
        title={langs('STATUS')}
        value={models.collector.fields.TASK_ACTIVITY_STATUS}
      />
      <Column
        {...columnDefs.ENUM_COL}
        hide
        filter={_.values(models.staticData.COLLECTOR.AVAILABILITY_STATUS)}
        title={langs('COLLECTOR_STATUS')}
        value={models.collector.fields.COLLECTOR_STATUS}
      />
      <Column
        {...columnDefs.ENUM_COL}
        filter={_.values(models.staticData.COLLECTOR.STAGE)}
        title={langs('STAGE')}
        value={models.collector.fields.STAGE}
      />
      <Column
        {...asyncFilterProps}
        hide={isSingleTenantView}
        title={langs('TENANT_NAME')}
        value={models.collector.fields.TENANT_NAME}
        tooltip={(data) => langs('TENANT_ID', data)}
      />
      <Column
        {...asyncFilterProps}
        colId="applicationVersion"
        title={langs('APP_VERSION')}
        value={models.collector.fields.FACTS_APPLICATION_VERSION}
      />
      <Column
        sortable
        title={langs('FW_VERSION')}
        colId={models.collector.fields.FIRMWARE_VERSION_ID}
        value={({firmwareBranch, firmwareBuildId}) => `${firmwareBranch}/${firmwareBuildId}`}
      />
      <Column {...columnDefs.DATE_COL} title={langs('LAST_SEEN')} value={models.collector.fields.LAST_SEEN} />
      <Column {...columnDefs.DATE_COL} title={langs('CREATION_DATE')} value={models.collector.fields.CREATION_DATE} />
      <Column {...asyncFilterProps} colId="defaultIpv4Address" hide title="IP" value="facts.defaultIpv4Address" />
      <Column {...columnDefs.NUMBER_COL} colId="processorCores" hide title="Core Count" value="facts.processorCores" />
      <Column
        {...columnDefs.NUMBER_COL}
        colId="numberOfInterfaces"
        hide
        title="Interface Count"
        value="facts.numberOfInterfaces"
      />
      <Column {...asyncFilterProps} colId="defaultIpv4Gateway" hide title="GW" value="facts.defaultIpv4Gateway" />
      <Column {...asyncFilterProps} colId="kernel" hide title="Kernel" value="facts.kernel" />
      <Column {...asyncFilterProps} colId="hostname" hide title="Hostname" value="facts.hostname" />
      <Column {...columnDefs.NUMBER_COL} colId="memtotalMb" hide title="Total Memory(mb)" value="facts.memtotalMb" />
      <Column
        {...asyncFilterProps}
        colId="defaultIpv4Macaddress"
        hide
        title="MAC"
        value="facts.defaultIpv4Macaddress"
      />
      <Column {...asyncFilterProps} colId="architecture" hide title="Architecture" value="facts.architecture" />
      <Column {...asyncFilterProps} colId="systemVendor" hide title="Vendor" value="facts.systemVendor" />
      <Column {...asyncFilterProps} colId="productSerial" hide title="Serial Number" value="facts.productSerial" />
      <Column
        {...columnDefs.NUMBER_COL}
        colId="processorCount"
        hide
        title="Processor Count"
        value="facts.processorCount"
      />
      <Column {...asyncFilterProps} colId="defaultIpv4Alias" hide title="IP interface" value="facts.defaultIpv4Alias" />
      <Column {...asyncFilterProps} colId="dnsServers" hide title="DNS" value="facts.dnsServers" />
      <Column {...asyncFilterProps} colId="ipmiSource" hide title="IPMI IP Source" value="facts.ipmiSource" />
      <Column {...asyncFilterProps} colId="ipmiIpAddress" hide title="IPMI IP Address" value="facts.ipmiIpAddress" />
      <Column {...asyncFilterProps} colId="ipmiNetmask" hide title="IPMI Netmask" value="facts.ipmiNetmask" />
      <Column {...asyncFilterProps} colId="ipmiGateway" hide title="IPMI Gateway" value="facts.ipmiGateway" />
      <Column {...asyncFilterProps} colId="osVersion" hide title="OS Version" value="facts.osVersion" />
      <Column {...columnDefs.BASIC_COL} hide suppressColumnsToolPanel title="Task ID" value="taskId" />
      <Column {...asyncFilterProps} colId="trackingNumber" hide title="Tracking Number" value="trackingNumber" />
      <Column
        {...columnDefs.ENUM_COL}
        colId="deliveryStatus"
        hide
        filter={_.values(models.staticData.COLLECTOR.DELIVERY_STATUS)}
        title="Delivery Status"
        value="deliveryStatus"
      />
      <Column {...asyncFilterProps} colId="sfAssetId" hide title="Salesforce Asset Id" value="sfAssetId" />
      <Column
        {...columnDefs.BASIC_COL}
        hide={!taskId}
        title={langs('COMMAND_STATUS')}
        value={models.collector.fields.COMMAND_STATUS}
      />
      <Column
        {...columnDefs.BASIC_COL}
        hide={!taskId}
        title={langs('OUTPUT')}
        value={models.collector.fields.COMMAND_OUTPUT}
      />
      <Column
        {...columnDefs.BASIC_COL}
        hide={!taskId}
        title={langs('ERROR')}
        value={models.collector.fields.COMMAND_ERROR}
      />
      <Column
        {...columnDefs.BASIC_COL}
        hide={!isSingleTenantView}
        colId="integrations"
        render={IntegrationsList}
        title={langs('INTEGRATIONS')}
        value={_.identity}
      />
    </AgTable>
  );
}

Table.propTypes = {
  getContextMenuItems: PropTypes.func.isRequired,
  getRows: PropTypes.func.isRequired,
  isSingleTenantView: PropTypes.bool.isRequired,
  selectedCollectors: PropTypes.array.isRequired,
  setCollectorPreview: PropTypes.func.isRequired,
  setSelectedCollectors: PropTypes.func.isRequired,
  setTableApi: PropTypes.func.isRequired,
  tableApi: PropTypes.object,
  taskId: PropTypes.string
};

export default Table;
