import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {Checkbox, FormControlLabel, Radio} from '@material-ui/core';

import langs from '../../../../localization/langs';
import services from '../../../../services';
import {DEPLOYMENT_TYPES} from '../utils';

import * as Styled from './Common.styles';

const FULL_ST_DEPLOYMENT = 'full_st_deployment';
const SQUAD_ST_DEPLOYMENT = 'squad_st_deployment';

function MultiTenantDeployment({
  deploymentTypesSelection,
  onChangeType,
  containersSelection,
  setContainersSelection,
  tenantsSelection,
  setTenantsSelection,
  isSquadDeployment,
  setIsSquadDeployment,
  tenants
}) {
  const [containers, setContainers] = React.useState([]);

  const isChecked = _.get(deploymentTypesSelection, [DEPLOYMENT_TYPES.SINGLE_TENANT.value, 'checked'], false);

  React.useEffect(() => {
    services.services.containers
      .list()
      .then(({data}) =>
        setContainers(
          _.map(data.items, (container, index) => ({
            id: index,
            name: container.name
          }))
        )
      )
      .catch((e) => {
        console.error(e);
        setContainers([]);
      });
  }, []);

  function onChangeRadio(event) {
    if (event.target.value === FULL_ST_DEPLOYMENT) {
      setContainersSelection([]);
    }
    setIsSquadDeployment((prevState) => !prevState);
  }

  function onChangeCheckbox(event) {
    if (!event.target.checked) {
      setTenantsSelection([]);
      setContainersSelection([]);
    }
    onChangeType(event.target.name, event.target.checked);
  }

  return (
    <Styled.DeploymentSection>
      <FormControlLabel
        control={
          <Checkbox checked={isChecked} onChange={onChangeCheckbox} name={DEPLOYMENT_TYPES.SINGLE_TENANT.value} />
        }
        label={DEPLOYMENT_TYPES.SINGLE_TENANT.displayName}
      />
      <Styled.TextSearch
        multiple
        options={tenants}
        value={tenantsSelection}
        label="tenantName"
        disabled={!isChecked}
        inputPlaceholder={langs('CHOOSE_TENANTS')}
        setValue={setTenantsSelection}
      />
      <Styled.RadioControl defaultValue={FULL_ST_DEPLOYMENT} onChange={onChangeRadio}>
        <FormControlLabel
          value={FULL_ST_DEPLOYMENT}
          control={<Radio />}
          label={langs('ST_DEPLOYMENT_FULL')}
          disabled={!isChecked}
        />
        <FormControlLabel
          value={SQUAD_ST_DEPLOYMENT}
          control={<Radio />}
          label={langs('ST_DEPLOYMENT_PARTIAL')}
          disabled={!isChecked}
        />
        <Styled.TextSearch
          multiple
          options={containers || []}
          value={containersSelection}
          disabled={!isSquadDeployment || !isChecked}
          label="name"
          inputPlaceholder={langs('CHOOSE_CONTAINERS')}
          setValue={setContainersSelection}
        />
      </Styled.RadioControl>
    </Styled.DeploymentSection>
  );
}

MultiTenantDeployment.propTypes = {
  containersSelection: PropTypes.array.isRequired,
  deploymentTypesSelection: PropTypes.object.isRequired,
  isSquadDeployment: PropTypes.bool.isRequired,
  onChangeType: PropTypes.func.isRequired,
  setContainersSelection: PropTypes.func.isRequired,
  setIsSquadDeployment: PropTypes.func.isRequired,
  setTenantsSelection: PropTypes.func.isRequired,
  tenants: PropTypes.array,
  tenantsSelection: PropTypes.array.isRequired
};

export default MultiTenantDeployment;
