import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import models from '../../../models';
import columnDefs from '../../../utils/agGridUtils/columnDefs';

import Column from '../../../components/AGTable/Column';
import Table from '../../../components/AGTable/Table';
import TenantNameColumn from '../../../components/AGTable/columns/TenantNameColumn';

const IS_DEPLOY_LOCKED = _.keys(consts.BOOLEAN_MAPPER);
const STAGE = _.values(models.staticData.TENANT.STAGES_TRANSLATED);
const TASK_STATUS = _.values(models.staticData.TENANT.TASK_ACTIVITY_STATUS);
const TENANT_STATUS = _.values(models.staticData.TENANT.AVAILABILITY_STATUS);
const TERRAFORM_STATUS = _.values(models.staticData.TERRAFORM.STATUS);

function CompactModeTable({
  data,
  fetchFilterOptions,
  filterModel,
  getContextMenuItems,
  onFieldChange,
  onFilterChanged,
  onTableRowClick,
  owners,
  selectedTenants,
  setSelectedTenants,
  setTableApi,
  taskId
}) {
  const asyncFilterProps = {...columnDefs.BASIC_COL, filter: fetchFilterOptions};
  const user = context.User.useUser();
  const environments = context.Environments.useEnvironments();

  function getOwnerName(email) {
    return owners[email] || owners[`${email}@armis.com`] || email;
  }

  return (
    <Table
      columnsDeps={[owners, user.rbac.maestro]}
      data={data}
      filterModel={filterModel}
      getContextMenuItems={getContextMenuItems}
      onFilterChanged={onFilterChanged}
      onRowClicked={onTableRowClick}
      onSelect={setSelectedTenants}
      pagination
      rbacControlObj={user.rbac.maestro}
      selected={selectedTenants}
      setTableApi={setTableApi}
      title={langs('TENANTS')}
      rowIdField="tenantId"
    >
      <Column
        hide
        {...columnDefs.NUMBER_COL}
        colId={models.tenant.fields.ID}
        title={langs('ID')}
        value={models.tenant.fields.ID}
      />
      <Column
        {...asyncFilterProps}
        colId="tenantName"
        render={TenantNameColumn}
        exportFunc={({tenantName}) => tenantName}
        suppressSizeToFit
        title={langs('NAME')}
        tooltip={({patches, tenantId}) => `${tenantId} ${!_.isEmpty(patches) ? `(${langs('TENANT_IS_PATCHED')})` : ''}`}
        value={_.identity}
      />
      <Column
        {...columnDefs.BASIC_COL}
        filter={_.map(environments, 'name')}
        title={langs('ENVIRONMENT')}
        value="environmentName"
      />
      <Column {...columnDefs.BASIC_COL} colId={models.tenant.fields.TIER} title="Group" value="tier" />
      <Column
        {...columnDefs.BASIC_COL}
        colId={models.tenant.fields.STAGE}
        editable={_.values(models.staticData.TENANT.STAGES_TRANSLATED)}
        editablePermissions={consts.rbac.permissions.maestro.tenant.edit.stage}
        onCellValueChanged={({data: {tenantId}, newValue}) =>
          onFieldChange(tenantId, models.tenant.fields.STAGE, newValue)
        }
        filter={STAGE}
        title={langs('STAGE')}
        value="stage"
      />
      <Column {...columnDefs.ENUM_COL} filter={TENANT_STATUS} title={langs('AGENT_STATUS')} value="status" />
      <Column {...columnDefs.ENUM_COL} filter={TASK_STATUS} title={langs('TASK_STATUS')} value="taskActivityStatus" />
      <Column
        {...columnDefs.ENUM_COL}
        filter={TERRAFORM_STATUS}
        title={langs('TERRAFORM_STATUS')}
        value="terraformStatus"
      />
      <Column {...asyncFilterProps} title={langs('VERSION')} value="applicationVersion" />
      <Column {...asyncFilterProps} hide title={langs('VERSION_MAJOR')} value="applicationVersionMajor" />
      <Column
        colId={models.tenant.fields.FIRMWARE_BRANCH_BUILD_ID}
        sortable
        title={langs('FW_VERSION')}
        value={({firmwareBranch, firmwareBuildId}) => `${firmwareBranch || ''}/${firmwareBuildId || ''}`}
      />
      <Column
        hide
        {...columnDefs.NUMBER_COL}
        colId={models.tenant.fields.FIRMWARE_VERSION_ID}
        title={langs('FW_VERSION_ID')}
        value={models.tenant.fields.FIRMWARE_VERSION_ID}
      />
      <Column {...asyncFilterProps} title={langs('INSTANCE_TYPE')} value="instanceType" />
      <Column {...columnDefs.DATE_COL} title={langs('LAST_DEPLOYMENT')} value="lastDeploymentDate" />
      <Column
        {...columnDefs.DATE_COL}
        hide
        title={langs('LAST_SUCCESSFUL_SNAPSHOT')}
        value="lastSuccessfulSnapshotDate"
      />
      <Column {...columnDefs.DATE_COL} title={langs('LAST_SEEN')} value="lastSeen" />
      <Column
        {...columnDefs.BASIC_COL}
        hide
        editable={models.staticData.TENANT.INDUSTRIES}
        filter={models.staticData.TENANT.INDUSTRIES}
        onCellValueChanged={({data: {tenantId}, newValue}) =>
          onFieldChange(tenantId, models.tenant.fields.INDUSTRY, newValue)
        }
        title={langs('TENANT_INDUSTRY')}
        value="industry"
      />
      <Column
        {...columnDefs.BOOL_COL}
        hide
        editable={IS_DEPLOY_LOCKED}
        filter={IS_DEPLOY_LOCKED}
        onCellValueChanged={({data: {tenantId}, newValue}) =>
          onFieldChange(tenantId, models.tenant.fields.IS_DEPLOY_LOCKED, consts.BOOLEAN_MAPPER[newValue])
        }
        title={langs('DEPLOY_LOCKED')}
        value="isDeployLocked"
      />
      <Column {...columnDefs.DATE_COL} hide title="Creation Date" value="creationDate" />
      <Column {...asyncFilterProps} hide title="AMI id" value="amiId" />
      <Column {...asyncFilterProps} hide title="Architecture" value="architecture" />
      <Column {...asyncFilterProps} hide title="Availability Zone" value="availabilityZone" />
      <Column {...asyncFilterProps} hide title="IP" value="defaultIpv4Address" />
      <Column {...asyncFilterProps} hide title="IP interface" value="defaultIpv4Alias" />
      <Column {...asyncFilterProps} hide title="GW" value="defaultIpv4Gateway" />
      <Column {...asyncFilterProps} hide title="MAC" value="defaultIpv4Macaddress" />
      <Column {...asyncFilterProps} hide title="DNS" value="dnsServers" />
      <Column {...asyncFilterProps} hide title="Env Type" value="environmentType" />
      <Column {...asyncFilterProps} hide title="Hostname" value="hostname" />
      <Column {...asyncFilterProps} hide title="IAM Role" value="iamInstanceProfileRole" />
      <Column {...asyncFilterProps} hide title="Instance Id" value="instanceId" />
      <Column {...asyncFilterProps} hide title="kernel" value="kernel" />
      <Column {...columnDefs.NUMBER_COL} hide title="Total Memory(mb)" value="memtotalMb" />
      <Column {...columnDefs.DATE_COL} hide title="Next Deployment" value="nextDeploymentDate" />
      <Column {...columnDefs.NUMBER_COL} hide title="Interface Count" value="numberOfInterfaces" />
      <Column {...asyncFilterProps} hide title="OS Version" value="osVersion" />
      <Column
        {...columnDefs.BASIC_COL}
        editable={_.values(owners)}
        hide
        field="owner"
        filter={_.keys(owners)}
        filterParams={{valueFormatter: ({value}) => getOwnerName(value)}}
        onCellValueChanged={({data: {tenantId}, newValue}) =>
          onFieldChange(tenantId, models.tenant.fields.OWNER, _.invert(owners)[newValue])
        }
        title="Owner"
        value={({owner}) => getOwnerName(owner)}
      />
      <Column {...columnDefs.NUMBER_COL} hide title="Core Count" value="processorCores" />
      <Column {...columnDefs.NUMBER_COL} hide title="Processor Count" value="processorCount" />
      <Column {...asyncFilterProps} hide title="Region" value="region" />
      <Column {...asyncFilterProps} hide title="Security Groups" value="securityGroups" />
      <Column {...asyncFilterProps} hide title="Vendor" value="systemVendor" />
      <Column {...asyncFilterProps} hide title="Salesforce Account ID" value="sfAccountId" />
      <Column
        {...columnDefs.ENUM_COL}
        hide
        filter={_.values(models.staticData.TENANT.SALESFORCE_ACCOUNT_TYPE)}
        title="Salesforce Account Type"
        value="sfAccountType"
      />
      <Column
        {...columnDefs.NULLABLE_COL}
        colId="patches"
        hide
        title="Patches"
        value={({patches}) => _.isArray(patches)}
      />
      <Column {...columnDefs.BASIC_COL} hide suppressColumnsToolPanel title="Task ID" value="taskId" />
      <Column {...columnDefs.BASIC_COL} hide={!taskId} title={langs('COMMAND_STATUS')} value="commandStatus" />
      <Column {...columnDefs.BASIC_COL} hide={!taskId} title={langs('OUTPUT')} value="commandOutput" />
      <Column {...columnDefs.BASIC_COL} hide={!taskId} title={langs('ERROR')} value="commandError" />
    </Table>
  );
}

CompactModeTable.propTypes = {
  data: Table.propTypes.data,
  fetchFilterOptions: PropTypes.func.isRequired,
  filterModel: Table.propTypes.filterModel,
  getContextMenuItems: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onFilterChanged: Table.propTypes.onFilterChanged.isRequired,
  onTableRowClick: PropTypes.func.isRequired,
  owners: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  selectedTenants: PropTypes.array.isRequired,
  setSelectedTenants: PropTypes.func.isRequired,
  setTableApi: Table.propTypes.setTableApi,
  taskId: PropTypes.string
};

export default CompactModeTable;
