import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../../localization/langs';

import {Form} from '../../../../components/Base';
import MultipleChoiceInput from '../../../../components/Base/inputs/MultipleChoiceInput';

import * as Styled from '../ServiceForm.styles';

const SELECTION_OPTIONS = {
  USE_REDIS: langs('USE_REDIS')
};

function ServiceRedisInfo({value, onChange, ...props}) {
  function getSelectionValue() {
    return _.transform(
      value,
      (res, val, key) => {
        if (!val) {
          return true;
        }
        res.push(SELECTION_OPTIONS[_.toUpper(_.snakeCase(key))]);
      },
      []
    );
  }

  function onChangeSelection(selection) {
    const newValue = _.transform(
      SELECTION_OPTIONS,
      (res, val, key) => {
        res[_.camelCase(key)] = _.includes(selection, val);
      },
      {}
    );
    onChange(newValue);
  }

  return (
    <Styled.StyledPaper {...props}>
      <Form.Label>{langs('CHOOSE_DATA_SOURCE')}</Form.Label>
      <MultipleChoiceInput
        onChange={onChangeSelection}
        options={_.values(SELECTION_OPTIONS)}
        value={getSelectionValue()}
      />
    </Styled.StyledPaper>
  );
}

ServiceRedisInfo.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired
};

export default ServiceRedisInfo;
