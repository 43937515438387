import _ from 'lodash';
import React from 'react';
import * as Router from 'react-router-dom';

function useUrlSearchParams() {
  const history = Router.useHistory();
  const location = Router.useLocation();

  const [query, setQuery] = React.useState(new URLSearchParams(location.search));
  query.sort();

  React.useEffect(
    function matchSearchQueryToLocation() {
      if (isLocationAndQueryMatch(location, query)) {
        return;
      }
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.sort();
      setQuery(urlSearchParams);
    },
    [location.search]
  );

  function setUrlQuery(keyValuePairs = [], obsoleteKeys = []) {
    if (_.isEmpty(keyValuePairs)) {
      return;
    }

    const urlSearchParams = new URLSearchParams(location.search);

    for (const key of obsoleteKeys) {
      urlSearchParams.delete(key);
    }

    for (const [key, value] of keyValuePairs) {
      if (_.isEmpty(_.toString(value))) {
        urlSearchParams.delete(key);
      } else {
        const storedValue = _.isString(value) ? value : encodeURI(JSON.stringify(value));
        urlSearchParams.set(key, storedValue);
      }
    }

    setUrlSearchParams(urlSearchParams);
  }

  function setUrlSearchParams(urlSearchParams) {
    if (_.isString(urlSearchParams)) {
      urlSearchParams = new URLSearchParams(urlSearchParams);
    }
    urlSearchParams.sort();
    if (urlSearchParams.toString() === query.toString()) {
      return;
    }
    setQuery(urlSearchParams);
    history.push(`?${urlSearchParams.toString()}`);
  }

  function getUrlQueryParam(key, operator) {
    const queryKey = `${key}${operator}`;
    return query.get(queryKey);
  }

  return [query, setUrlQuery, getUrlQueryParam, setUrlSearchParams];
}

function isLocationAndQueryMatch(location, query) {
  return _.trimStart(location.search, '?') === query.toString();
}

export default useUrlSearchParams;
