import styled from 'styled-components';

import MUIIconButton from '@material-ui/core/IconButton';

import * as theme from '../../../theme';

export const Container = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  width: 500px;
  height: 100%;
  background-color: ${theme.backgrounds.light1};
  position: relative;
  border: solid 1px ${theme.searchBar.caret};
`;

export const Input = styled.input`
  flex: 1;
  font-size: 1rem;
  border: 0;
  padding-left: 1rem;

  background: transparent;
  color: ${theme.searchBar.text};

  max-width: ${({isShowShortcut}) => (isShowShortcut ? '360px' : '470px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::placeholder {
    color: ${theme.searchBar.text};
  }

  &:focus {
    outline: none;
  }
`;

export const ShortcutText = styled.span`
  color: ${theme.searchBar.text};
  position: absolute;
  right: 30px;
`;

export const IconButton = styled(MUIIconButton)`
  color: ${theme.searchBar.caret};
  padding: 0;
  border-radius: 0;
  position: absolute;
  right: 0px;
  height: 28px;
  width: 29px;
  &:hover {
    background-color: ${theme.backgrounds.light1};
  }
`;
