import styled from 'styled-components';

import RadioGroup from '@material-ui/core/RadioGroup';

import TextSearchInput from '../../../../components/TextSearchInput';

import * as theme from '../../../../theme';

export const TextSearch = styled(TextSearchInput)`
  padding-left: 2rem;
`;

export const RadioControl = styled(RadioGroup)`
  padding-left: 2rem;
`;

export const DeploymentSection = styled.div`
  padding-bottom: 1rem;
`;

export const Link = styled.a.attrs((props) => ({
  rel: 'noreferrer',
  target: '_blank'
}))`
  color: ${theme.link.text};
  &:hover {
    color: ${theme.link.hover};
  }
`;
