import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../localization/langs';

import * as Styled from './Info.styles';

function getJiraLink(issueKey) {
  return (
    <Styled.LinkListItem>
      <Styled.Link href={`https://armis-security.atlassian.net/browse/${issueKey}`} target="_blank" rel="noreferrer">
        {issueKey}
      </Styled.Link>
    </Styled.LinkListItem>
  );
}

function getJiraLinks(issueKeys) {
  return (
    <Styled.LinkList>
      {_(issueKeys)
        .sortBy()
        .map((issueKey) => getJiraLink(issueKey))
        .value()}
    </Styled.LinkList>
  );
}

function Info({tenant}) {
  return (
    <Styled.Container>
      <Styled.Segment>
        <Styled.SegmentTitle>{langs('PATCHES')}</Styled.SegmentTitle>
        {getJiraLinks(tenant.patches)}
      </Styled.Segment>
    </Styled.Container>
  );
}

Info.propTypes = {
  tenant: PropTypes.shape({
    patches: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

export default Info;
