import PropTypes from 'prop-types';
import React from 'react';

import FullstoryIcon from '../../../assets/icons/fullstory-icon.png';
import GrafanaIcon from '../../../assets/icons/grafana-icon.svg';
import JiraIcon from '../../../assets/icons/jira-icon.svg';
import OpsGenieIcon from '../../../assets/icons/opsgenie-icon.svg';
import PendoIcon from '../../../assets/icons/pendo-icon.ico';
import PrometheusIcon from '../../../assets/icons/prometheus-icon.svg';

import utils from '../../../utils';

import * as Styled from './TenantLinks.styles';

function TenantLinks({tenantName}) {
  return (
    <>
      <a href={utils.tenant.getGrafanaLink(tenantName)} target="_blank" rel="noopener noreferrer">
        <Styled.ImageButton src={GrafanaIcon} />
      </a>
      <a href={utils.tenant.getJiraLink(tenantName)} target="_blank" rel="noopener noreferrer">
        <Styled.ImageButton src={JiraIcon} />
      </a>
      <a href={utils.tenant.getPrometheusLink(tenantName)} target="_blank" rel="noopener noreferrer">
        <Styled.ImageButton src={PrometheusIcon} />
      </a>
      <a href={utils.tenant.getFullStoryLink(tenantName)} target="_blank" rel="noopener noreferrer">
        <Styled.ImageButton src={FullstoryIcon} />
      </a>
      <a href={utils.tenant.getPendoLink(tenantName)} target="_blank" rel="noopener noreferrer">
        <Styled.ImageButton src={PendoIcon} />
      </a>
      <a href={utils.tenant.getOpsGenieLink(tenantName)} target="_blank" rel="noopener noreferrer">
        <Styled.ImageButton src={OpsGenieIcon} />
      </a>
    </>
  );
}

TenantLinks.propTypes = {
  tenantName: PropTypes.string.isRequired
};

export default TenantLinks;
