import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../localization/langs';

import * as Styled from './ConfigurationNavigation.styles';

function ConfigurationNavigation({displaySettings, labelDefinitions}) {
  function getBreadcrumbs() {
    return _.transform(
      labelDefinitions,
      (res, {label, value}) => {
        const name = _.get(value, 'name');
        if (_.isEmpty(name)) {
          return true;
        }
        res.push({label, name});
      },
      [{label: 'ROOT', name: '~'}]
    );
  }

  function getCurrentLabelDefinition() {
    const currentLabelDef = _.find(labelDefinitions, ({value}) => _.isEmpty(_.get(value, 'name')));
    return _.isEmpty(currentLabelDef) ? _.last(labelDefinitions) : currentLabelDef;
  }

  function isBreadcrumbClickable(selectedLabel) {
    const labelIndex = _.findIndex(labelDefinitions, {label: selectedLabel});
    const lastValueIndex = _.findLastIndex(labelDefinitions, ({value}) => !_.isEmpty(value));
    return labelIndex + 1 === lastValueIndex;
  }

  function onBreadcrumbClick(selectedLabel) {
    const lastValueIndex = _.findLastIndex(labelDefinitions, ({value}) => !_.isEmpty(value));
    if (isBreadcrumbClickable(selectedLabel)) {
      _.nth(labelDefinitions, lastValueIndex).onChange(null);
    }
  }

  const breadCrumbs = getBreadcrumbs();
  const {label, value, options, onChange} = getCurrentLabelDefinition();

  return (
    <Styled.FullHeightGrid item xs={3}>
      <Styled.BreadCrumbs>
        {_.map(breadCrumbs, ({label: breadcrumbLabel, name: breadcrumbName}, index) => (
          <span key={breadcrumbLabel}>
            <Styled.BreadCrumb
              $isClickable={isBreadcrumbClickable(breadcrumbLabel)}
              onClick={() => onBreadcrumbClick(breadcrumbLabel)}
            >
              {breadcrumbName}
            </Styled.BreadCrumb>
            {index !== _.size(labelDefinitions) && <span> / </span>}
          </span>
        ))}
      </Styled.BreadCrumbs>
      <Styled.StyledInternalNavBar
        all={displaySettings.showAllLabel}
        search
        vertical
        onChange={onChange}
        options={_.orderBy(options, ['name'])}
        value={_.get(value, 'id')}
        placeholder={`${langs('SEARCH')} ${_.toLower(label)}`}
      />
    </Styled.FullHeightGrid>
  );
}

ConfigurationNavigation.propTypes = {
  displaySettings: PropTypes.shape({
    showAddNewKeyDefaultValue: PropTypes.bool,
    showAllLabel: PropTypes.bool,
    showConfigurationKeySearch: PropTypes.bool,
    showCreatedByCol: PropTypes.bool,
    showEmptyFilter: PropTypes.bool,
    showInheritCol: PropTypes.bool,
    showLastUpdateCol: PropTypes.bool,
    titleizeSubCategories: PropTypes.bool
  }),
  labelDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          disabled: PropTypes.bool,
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      ).isRequired,
      value: PropTypes.object,
      width: PropTypes.number.isRequired
    })
  ).isRequired
};

export default ConfigurationNavigation;
