import styled from 'styled-components';

import Chip from '@material-ui/core/Chip';

import TextSearch from '../../TextSearch';

import * as theme from '../../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

export const TitleOperatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const Title = styled.div`
  color: ${theme.navbar.text};
  font-weight: 600;
  font-size: 16px;
  user-select: none;
  margin-right: 1px;
`;

export const Operator = styled(Chip)`
  border: 0;
  color: ${theme.navbar.text};
  font-weight: 100;
  font-size: 14px;
  user-select: none;
  height: unset;
  width: unset;
  padding: 0;
  transition: unset;
  text-decoration: underline;
  &:hover,
  &:active {
    background-color: transparent;
  }
`;

export const StyledTextSearch = styled(TextSearch)`
  margin-top: 10px;
  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }
  .MuiInput-root {
    padding: 0;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0;
    background-color: ${theme.backgrounds.lighter};
  }
  input,
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'][class*='MuiInput-marginDense'] .MuiAutocomplete-input:first-child {
    padding-left: 10px;
  }
  .MuiInput-input {
    background-color: ${theme.backgrounds.lighter};
  }
  .MuiAutocomplete-endAdornment {
    top: unset;
    right: 0;
    bottom: 0;
    background-color: ${theme.backgrounds.lighter};
  }
`;
