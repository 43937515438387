import styled from 'styled-components';

import * as theme from '../../../theme';

export const Container = styled.div``;

export const Title = styled.div`
  cursor: pointer;
  margin-bottom: ${({isOpen}) => (isOpen ? '7px' : '0')};

  height: 18px;
  padding-bottom: 5px;
  border-bottom: 3px solid ${({color}) => (color ? color : theme.statusBackground.success)};
  user-select: none;
  color: ${({color}) => (color ? color : theme.statusBackground.success)};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-weight: bold;
`;
