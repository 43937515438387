import _ from 'lodash';

import enums from './enums';

function isDeleteOperation(levels, change, remoteValue) {
  const changeInheritanceLevelIndex = _.findIndex(levels, (level) => level === change.inheritanceLevel);
  const remoteInheritanceLevelIndex = _.findIndex(levels, (level) => level === remoteValue.inheritanceLevel);
  if (changeInheritanceLevelIndex === -1 || remoteInheritanceLevelIndex === -1) {
    return false;
  }
  return changeInheritanceLevelIndex + 1 === remoteInheritanceLevelIndex;
}

function isNoOp(change, remoteValue) {
  return _.isEqual(change.value, remoteValue.value) && _.isEqual(change.inheritanceLevel, remoteValue.inheritanceLevel);
}

function getLevel(selectedValues) {
  const values = _.reject(selectedValues, (selectedValue) => _.isEmpty(selectedValue.value));
  if (_.isEmpty(values)) {
    return enums.LEVELS.ALL;
  }
  return _.get(_.last(values), 'label');
}

function getSelectedLabel(selectedValues) {
  const values = _.reject(selectedValues, (selectedValue) => _.isEmpty(selectedValue.value));
  return _.last(values);
}

function makeOperation(levels, change, name, remoteValue, selectedValues) {
  if (change.inheritanceLevel !== remoteValue.inheritanceLevel) {
    if (isDeleteOperation(levels, change, remoteValue)) {
      return {
        configurationValueId: remoteValue.configurationValueId,
        mainCategory: change.mainCategory,
        name,
        operation: enums.OPERATIONS.DELETE,
        subCategory: change.subCategory
      };
    }
    return {
      inheritanceLevel: change.inheritanceLevel,
      labels: selectedValues,
      mainCategory: change.mainCategory,
      name,
      operation: enums.OPERATIONS.CREATE,
      subCategory: change.subCategory,
      value: change.value
    };
  } else if (isNoOp(change, remoteValue)) {
    return {
      mainCategory: change.mainCategory,
      name,
      operation: enums.OPERATIONS.NOOP,
      subCategory: change.subCategory
    };
  }
  return {
    configurationValueId: remoteValue.configurationValueId,
    mainCategory: change.mainCategory,
    name,
    oldValue: remoteValue.value,
    operation: enums.OPERATIONS.UPDATE,
    subCategory: change.subCategory,
    value: change.value
  };
}

function createChangeKey(key, category) {
  return `${key};${category}`;
}

function parseChangeKey(changeKey) {
  const [key, category] = _.split(changeKey, ';');
  return {category, key};
}

function getRowInheritance(changes, row) {
  const changeKey = createChangeKey(row.key, row.keySubCategory);
  return _.get(changes, `["${changeKey}"].inheritanceLevel`, row.inheritanceLevel);
}

function getRowValue(changes, row) {
  const changeKey = createChangeKey(row.key, row.keySubCategory);
  return _.get(changes, `["${changeKey}"].value`, row.value);
}

function filterRows(filters, rows, inheritanceLevel, getInheritance, getValue, operations) {
  return _.filter(rows, (row) => filterRow(filters, row, inheritanceLevel, getInheritance, getValue, operations));
}

function filterRow(
  {showEmptyValues, showInheritedValues, text},
  row,
  inheritanceLevel,
  getInheritance,
  getValue,
  operations
) {
  const rowValue = getValue(row);
  const isInOperation = _.find(operations, {name: row.key});
  const showEmptyMatch =
    showEmptyValues || isInOperation ? true : _.isString(rowValue) ? !_.isEmpty(rowValue) : !_.isNil(rowValue);
  const inheritanceMatch = showInheritedValues ? true : inheritanceLevel === getInheritance(row);
  const freetextMatch = _.isEmpty(text)
    ? true
    : _.includes(_.toLower(row.key), text) ||
      _.includes(_.toLower(row.description), text) ||
      _.includes(_.toLower(row.createdBy), text) ||
      _.includes(_.toLower(rowValue), text);
  return showEmptyMatch && inheritanceMatch && freetextMatch;
}

function getKeyedSelectedValues(selectedValues) {
  return _.transform(
    selectedValues,
    (res, selectedValue) => (res[selectedValue.key] = _.get(selectedValue, 'value.id')),
    {}
  );
}

function getLevelFromLabels(labelDefinitions, labels) {
  const priority = _.size(labels);
  if (priority === 0) {
    return enums.LEVELS.ALL;
  }
  return _.get(_.nth(labelDefinitions, priority - 1), 'label', enums.LEVELS.ALL);
}

export default {
  createChangeKey,
  filterRows,
  getKeyedSelectedValues,
  getLevel,
  getLevelFromLabels,
  getRowInheritance,
  getRowValue,
  getSelectedLabel,
  makeOperation,
  parseChangeKey
};
