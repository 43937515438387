import _ from 'lodash';
import React from 'react';

import collectorUtils from '../collectorUtils';
import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import roles from '../../../roles';

import ConfirmationTextboxModal from '../../../components/Base/ConfirmationTextboxModal';
import useMenuItem from '../../../components/AGTable/hooks/useMenuItem';

export default function useDelete(collectors, tableApi) {
  const user = context.User.useUser();

  async function onConfirm(collector) {
    await collectorUtils.deleteCollector(collector);

    const gridApi = tableApi?.gridApi;
    if (gridApi) {
      gridApi.refreshServerSideStore();
    }
  }

  const permission = user.rbac.cms.isEnabled()
    ? consts.rbac.permissions.cms.collectors.markOperational
    : roles.MANAGE_COLLECTORS;

  const [getDef, Modal, row] = useMenuItem(
    onConfirm,
    langs('DELETE'),
    'outline_delete_white_24dp',
    permission,
    user.rbac.cms,
    ConfirmationTextboxModal
  );

  function isSingleCollector(row) {
    const collectorANumber = _.get(row, 'collectorNumber');
    const collectorBNumber = _.get(_.head(collectors), 'collectorNumber');
    return (
      _.isEmpty(collectors) ||
      (collectors.length === 1 && collectorANumber && collectorBNumber && collectorANumber === collectorBNumber)
    );
  }

  function getDeleteDef(row) {
    const def = getDef(row);
    return _.defaults({disabled: def.disabled || !isSingleCollector(row)}, def);
  }

  const tenantName = _.get(row, 'tenantName', '');
  const collectorNumber = _.get(row, 'collectorNumber', '');
  const ExtendedModal = React.cloneElement(Modal, {
    description: langs('COLLECTOR_DELETION_DESCRIPTION'),
    matchText: `${tenantName}:${collectorNumber}`,
    title: langs('COLLECTOR_DELETION_TITLE')
  });
  return [getDeleteDef, ExtendedModal];
}
