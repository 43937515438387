import _ from 'lodash';
import papaparse from 'papaparse';

import consts from '../consts';
import langs from '../localization/langs';
import services from '../services';
import utils from './index';

const MIME_CSV = 'text/csv;charset=utf-8;';

const EXPORT_SERVICE_BY_SERVICE_NAME = {
  [consts.SERVICE_NAMES.COLLECTORS]: services.collectors.listCollectors,
  [consts.SERVICE_NAMES.TENANTS]: services.tenants.getByQuery
};

export function getDownloadLinkUrl(data, columns = null) {
  data = prepareData(data);
  let blobData = papaparse.unparse(data, {columns});
  let blob = new Blob([blobData], {type: MIME_CSV});
  return URL.createObjectURL(blob);
}

function prepareData(data) {
  return _.map(data, (row) => _.mapValues(row, prepareValue));
}

function prepareValue(value) {
  return _.isObject(value) ? JSON.stringify(value) : value;
}

export function flattenObjectArray(array) {
  return _.map(array, (item) => flatten(item));
}

export function flatten(obj, prefix = '', res = {}) {
  return Object.entries(obj).reduce((accumulator, [key, val]) => {
    const accPrefix = `${prefix}${key}`;
    if (_.isObject(val)) {
      flatten(val, `${accPrefix}.`, accumulator);
    } else {
      res[accPrefix] = val;
    }
    return accumulator;
  }, res);
}

function downloadCsv(linkHref, fileName) {
  const link = document.createElement('a');

  link.href = linkHref;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function exportToCsv(serviceName, searchQuery, notifyError = null) {
  const currentService = _.get(EXPORT_SERVICE_BY_SERVICE_NAME, serviceName, services.collectors.listCollectors);
  try {
    const serviceResponse = await currentService({length: -1, searchQuery});
    const linkHref = utils.csv.getDownloadLinkUrl(flattenObjectArray(serviceResponse?.data?.items ?? []));

    downloadCsv(linkHref, serviceName.toLowerCase());
  } catch (error) {
    if (notifyError) {
      notifyError(langs('ERROR_CREATING_CSV_MESSAGE'));
    }
  }
}
