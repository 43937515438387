import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {Dialog} from '@material-ui/core';

import context from '../../context';
import langs from '../../localization/langs';

import ConfirmTask from './ConfirmTask';

import * as Styled from './NewTaskWidget.styles';

function StubSelection({onNext}) {
  React.useEffect(() => {
    onNext();
  }, []);

  return <div />;
}

StubSelection.propTypes = {
  onNext: PropTypes.func
};

export default function useNewTask(task, hosts, idAttribute, getSelectionComponentProps) {
  const [host, setHost] = React.useState(null);
  const [command, setCommand] = React.useState({id: task.commandId});
  const [configurationValue, setConfigurationValue] = React.useState(null);
  const [dialogTitle, setDialogTitle] = React.useState('');
  const [isConfirm, setIsConfirm] = React.useState(false);
  const [parametersValues, setParametersValues] = React.useState({});
  const [slidingWindowSize, setSlidingWindowSize] = React.useState(60);
  const user = context.User.useUser();

  React.useEffect(() => {
    if (!host) {
      setIsConfirm(false);
      setDialogTitle('');
    }
  }, [host]);

  function close() {
    setHost(null);
  }

  const hasSelectionComponent = !_.isNil(task?.selectionComponent);
  const SelectionComponent = hasSelectionComponent ? task.selectionComponent : StubSelection;

  function getDef(host, rbacControlObject) {
    const environmentName = host.environmentName;
    const hasPermission =
      rbacControlObject && task.permissions
        ? rbacControlObject.hasPermissions(task.permissions, environmentName)
        : true;
    const missingPermissions = rbacControlObject
      ? rbacControlObject.getMissingPermissionsStr(task.permissions, environmentName)
      : '';
    return {
      action: () => setHost(host),
      disabled: !hasPermission || (!rbacControlObject.isEnabled() && !!task.role && !user.hasRole(task.role)),
      icon: task.icon,
      name: task.name,
      tooltip: hasPermission ? task.tooltip : langs('INSUFFICIENT_PERMISSIONS', {missingPermissions})
    };
  }

  const selectedHosts = _.isEmpty(hosts) && host ? [host] : hosts;
  const selectionComponentProps = _.isFunction(getSelectionComponentProps)
    ? getSelectionComponentProps(selectedHosts)
    : {};

  const dialog = (
    <Dialog
      open={!!host}
      onClose={close}
      maxWidth="md"
      fullWidth
      PaperProps={Styled.newTaskModalPaperProps}
      scroll="paper"
    >
      {dialogTitle && <Styled.NewTaskDialogTitle>{dialogTitle}</Styled.NewTaskDialogTitle>}
      {isConfirm ? (
        <ConfirmTask
          {...selectionComponentProps}
          command={command}
          closeModal={close}
          setDialogTitle={setDialogTitle}
          taskType={task.type}
          slidingWindowSize={slidingWindowSize}
          setSlidingWindowSize={setSlidingWindowSize}
          parametersValues={parametersValues}
          taskOption={task}
          configurationValue={configurationValue}
          selectedHosts={selectedHosts}
          idAttribute={idAttribute}
        />
      ) : (
        <SelectionComponent
          {...selectionComponentProps}
          closeModal={close}
          parametersValues={parametersValues}
          setDialogTitle={setDialogTitle}
          setParametersValues={setParametersValues}
          setSlidingWindowSize={setSlidingWindowSize}
          slidingWindowSize={slidingWindowSize}
          taskOption={task}
          setCommand={setCommand}
          command={command}
          onNext={() => setIsConfirm(true)}
          configurationValue={configurationValue}
          setConfigurationValue={setConfigurationValue}
          hosts={selectedHosts}
        />
      )}
    </Dialog>
  );

  return [getDef, dialog];
}
