import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Button from '@material-ui/core/Button';
import CommentIcon from '@material-ui/icons/Comment';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';

import langs from '../../localization/langs';

import * as Styled from './AddNewCommand.styles';

function AddNewCommand({onAddNewCommandResponse, createShellCommandApi, selectedCommand}) {
  const [customCommand, setCustomCommand] = React.useState('');
  const [customCommandDescription, setCustomCommandDescription] = React.useState('');
  const [isValidCommand, setIsValidCommand] = React.useState(true);
  const [isValidDescription, setIsValidDescription] = React.useState(true);

  function onDuplicateCommand() {
    if (selectedCommand) {
      setCustomCommand(selectedCommand.command);
      setCustomCommandDescription(selectedCommand.description);
    }
  }

  async function addCustomCommand() {
    setIsValidCommand(!_.isEmpty(customCommand));
    setIsValidDescription(!_.isEmpty(customCommandDescription));

    if (_.isEmpty(customCommandDescription) || _.isEmpty(customCommand)) {
      return;
    }

    const createCommandResponse = await createShellCommandApi(customCommand, customCommandDescription);

    onAddNewCommandResponse(customCommand, customCommandDescription, createCommandResponse);

    setCustomCommand('');
    setCustomCommandDescription('');
  }

  return (
    <div>
      <FormControl fullWidth>
        <Styled.Title>{langs('ADD_NEW_COMMAND')}</Styled.Title>
        <Styled.Input
          onChange={(event) => setCustomCommand(event.target.value)}
          value={customCommand}
          error={!isValidCommand}
          startAdornment={
            <Styled.StartAdornment>
              <ArrowForwardIosIcon fontSize={'inherit'} color={'inherit'} />
            </Styled.StartAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Styled.EndAdornment onClick={addCustomCommand}>
                + {langs('ADD_TO')}
                <br />
                {langs('COMMANDS')}
              </Styled.EndAdornment>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl fullWidth>
        <Styled.DescriptionInput
          onChange={(event) => setCustomCommandDescription(event.target.value)}
          value={customCommandDescription}
          error={!isValidDescription}
          placeholder={langs('DESCRIPTION')}
          startAdornment={
            <Styled.StartAdornment>
              <CommentIcon fontSize={'inherit'} color={'inherit'} />
            </Styled.StartAdornment>
          }
        />
      </FormControl>
      <Button size="small" startIcon={<Styled.DuplicateCommand />} onClick={onDuplicateCommand}>
        {langs('DUPLICATE_SELECTED_COMMAND')}
      </Button>
    </div>
  );
}

AddNewCommand.propTypes = {
  createShellCommandApi: PropTypes.func.isRequired,
  onAddNewCommandResponse: PropTypes.func.isRequired,
  selectedCommand: PropTypes.object
};

export default AddNewCommand;
