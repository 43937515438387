import _ from 'lodash';

import consts from '../../consts';
import services from '../../services';
import tableUtils from '../../components/Collectors/Table/table.utils';

async function getRows(params, tenantId) {
  const queryString = _.isEmpty(tenantId) ? '' : `tenantId${consts.FILTER_OPERATORS.EQUAL}=${tenantId}`;
  const collectors = await tableUtils.handleSearchQueryChange(params.api, params.request, queryString);
  if (!_.isEmpty(tenantId)) {
    const integrations = await services.collectors.getCollectorsIntegrations(tenantId);
    _.forEach(integrations?.data?.collectors, ({id, integrations}) => {
      const collector = _.find(collectors?.rowData, {collectorNumber: id});
      _.assign(collector, {integrations});
    });
  }
  params.success(collectors);
}

async function decommissionCollectors(collectors) {
  return Promise.all(
    _.map(collectors, ({collectorId, tenantId}) => services.collectors.decommission(tenantId, collectorId))
  );
}

async function deleteCollector(collector) {
  const tenantId = _.get(collector, 'tenantId');
  const collectorId = _.get(collector, 'collectorId');
  return services.collectors.deleteCollector(tenantId, collectorId);
}

async function markCollectorsAsOperational(collectors) {
  return Promise.all(
    _.map(collectors, ({collectorId, tenantId}) =>
      services.collectors.markCollectorAsOperational(tenantId, collectorId)
    )
  );
}

export default {
  decommissionCollectors,
  deleteCollector,
  getRows,
  markCollectorsAsOperational
};
