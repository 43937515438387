import consts from '../../../../consts';

const PERMISSIONS = consts.rbac.permissions.opsWeb;

function getDirectAccessors(opsWebAccessors) {
  return {
    allowed: {
      view: {
        cherryPicks: () => opsWebAccessors.hasPermission(PERMISSIONS.view.cherryPicks),
        collectors: {
          beacons: () => opsWebAccessors.hasPermission(PERMISSIONS.view.collectors.beacons),
          config: () => opsWebAccessors.hasPermission(PERMISSIONS.view.collectors.config),
          images: () => opsWebAccessors.hasPermission(PERMISSIONS.view.collectors.images),
          tasks: () => opsWebAccessors.hasPermission(PERMISSIONS.view.collectors.tasks),
          view: () => opsWebAccessors.hasPermission(PERMISSIONS.view.collectors.view)
        },
        demo: () => opsWebAccessors.hasPermission(PERMISSIONS.view.demo),
        multiTenant: {
          config: () => opsWebAccessors.hasPermission(PERMISSIONS.view.multiTenant.config),
          namespaces: () => opsWebAccessors.hasPermission(PERMISSIONS.view.multiTenant.namespaces),
          services: () => opsWebAccessors.hasPermission(PERMISSIONS.view.multiTenant.services),
          stacks: () => opsWebAccessors.hasPermission(PERMISSIONS.view.multiTenant.stacks),
          tasks: () => opsWebAccessors.hasPermission(PERMISSIONS.view.multiTenant.tasks),
          view: () => opsWebAccessors.hasPermission(PERMISSIONS.view.multiTenant.view)
        },
        tenants: {
          config: () => opsWebAccessors.hasPermission(PERMISSIONS.view.tenants.config),
          tasks: () => opsWebAccessors.hasPermission(PERMISSIONS.view.tenants.tasks),
          view: () => opsWebAccessors.hasPermission(PERMISSIONS.view.tenants.view)
        },
        viewAs: () => opsWebAccessors.hasPermission(PERMISSIONS.view.viewAs)
      }
    }
  };
}

export default {
  getDirectAccessors
};
