import _ from 'lodash';

import services from '../../../../services/services';

const MAX_DEPLOYMENT_REQUESTS = 50;

async function fetchDeploymentServices(row) {
  if (!row) {
    return [];
  }
  if (row.deploymentId) {
    return [row];
  }
  const {
    data: {items}
  } = await services.deployments.list(row.searchParams);
  return items;
}

async function fetchServices(rows) {
  if (_.every(rows, 'deploymentId')) {
    return rows;
  }
  if (_.size(rows) > MAX_DEPLOYMENT_REQUESTS) {
    return null;
  }
  const services = await Promise.all(_.map(rows, fetchDeploymentServices));
  return _.flatten(services);
}

export default {
  fetchDeploymentServices,
  fetchServices
};
