import React from 'react';
import PropTypes from 'prop-types';

import consts from '../../consts';
import context from '../../context';
import langs from '../../localization/langs';

import {ProgressBar} from '../Base';

import * as Styled from './GithubAuthWrapper.styles';

function GithubAuthWrapper({children}) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const user = context.User.useUser();

  const authenticationView = (
    <Styled.Container>
      <div>
        <Styled.Description>
          {isLoading ? langs('GITHUB_AUTHENTICATION_LOADING') : langs('GITHUB_AUTHENTICATION_FAILED')}
        </Styled.Description>
        <ProgressBar isLoading={isLoading} />
        {error && (
          <Styled.Error>
            {langs('ERROR')}: {error}
          </Styled.Error>
        )}
      </div>
    </Styled.Container>
  );

  React.useEffect(() => {
    if (!user.github.isAuthenticated) {
      user.github
        .validate()
        .then(({data}) => {
          user.github.setIsAuthenticated(data.isValid);
          if (!data.isValid) {
            window.location.replace(consts.EXTERNAL_GITHUB_AUTH_URL);
          }
        })
        .catch((e) => {
          console.error(e.message);
          setError(e?.response?.data || e.message);
          user.github.setIsAuthenticated(false);
          setIsLoading(false);
        });
    }
  }, [user.github.isAuthenticated]);

  return <div>{user.github.isAuthenticated ? children : authenticationView}</div>;
}

GithubAuthWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default GithubAuthWrapper;
