import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import hooks from '../../../../hooks';
import langs from '../../../../localization/langs';
import services from '../../../../services';

import {Form, Input} from '../../../../components/Base';

import * as Styled from '../ServiceForm.styles';

function ServiceVersionRange({value, onChange}) {
  const [isValid, setIsValid] = useState(true);
  const [versionRange, setVersionRange] = useState(value);

  React.useEffect(() => {
    setVersionRange(value);
    setIsValid(!_.isEmpty(value));
  }, [value]);

  const validateVersionRange = hooks.useDebounce(async (versionRange) => {
    try {
      onChange('');
      await services.services.services.validateVersionRange(versionRange);
      onChange(versionRange);
      setIsValid(true);
    } catch (err) {
      setIsValid(false);
    }
  }, 300);

  function onChangeVersionRange(newVersionRange) {
    setVersionRange(newVersionRange);
    validateVersionRange(newVersionRange);
  }

  return (
    <Form.Field>
      <Form.FieldWrapper>
        <Form.Label>{langs('VERSION_RANGE')}</Form.Label>
        <Tooltip placement="top-start" arrow title={langs('VERSION_RANGE_TOOLTIP')}>
          <Styled.StyledHelpIcon />
        </Tooltip>
      </Form.FieldWrapper>
      <Form.FieldWrapper>
        <Input
          type="text"
          required
          error={!isValid}
          value={versionRange}
          onChange={(e) => onChangeVersionRange(e.target.value)}
        />
      </Form.FieldWrapper>
      {!isValid && <Styled.Error>{langs('ERROR_INVALID_VERSION_RANGE')}</Styled.Error>}
    </Form.Field>
  );
}

ServiceVersionRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default ServiceVersionRange;
