import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../localization/langs';
import models from '../../../models';
import services from '../../../services';

import {Form, Input, Paper} from '../../../components/Base';

import * as Styled from './CreateVersion.styles';

function CreateVersion({branch, setBranch, requestType}) {
  const [releaseCandidates, setReleaseCandidates] = React.useState([]);

  React.useEffect(() => {
    services.tenants.artifact
      .listAll({isOpenForCp: true, length: 1, like: '%'})
      .then(({data: {items}}) => _.map(items, 'branch'))
      .then(setReleaseCandidates)
      .catch(console.error);
  }, []);

  React.useEffect(() => {
    if (requestType === models.releases.requestTypes.MAJOR) {
      setBranch(_.head(releaseCandidates));
    }
  }, [requestType, releaseCandidates]);

  return (
    <Paper padding={2} style={{paddingBlock: '1em'}}>
      <Form.Label>{langs('VERSION')}</Form.Label>
      <>
        <Styled.FieldWrapper>
          {langs('RELEASE_FORM_LATEST_RC')}:
          <Styled.FieldValue>
            {_.isEmpty(releaseCandidates) ? <Styled.CircularLoader /> : _.head(releaseCandidates)}
          </Styled.FieldValue>
        </Styled.FieldWrapper>
        {requestType === models.releases.requestTypes.RELEASE_CANDIDATE && (
          <span>
            {langs('RELEASE_FORM_NEW_RC_NAME')}:
            <Styled.FieldValue>
              <Input type="text" value={branch} required onChange={(e) => setBranch(e.target.value)} />
            </Styled.FieldValue>
          </span>
        )}
      </>
    </Paper>
  );
}

CreateVersion.propTypes = {
  branch: PropTypes.string,
  requestType: PropTypes.string.isRequired,
  setBranch: PropTypes.func.isRequired
};

export default CreateVersion;
