import styled from 'styled-components';

import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  details: {
    '& span': {
      marginRight: theme.spacing(3)
    }
  },

  header: {
    '& > h5': {
      paddingLeft: theme.spacing(1)
    },
    '& > svg': {
      color: theme.palette.success.main
    },

    fontSize: '1.5rem'
  },

  root: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },

  small: {
    color: '#CFD0CF',
    fontSize: '0.95em'
  }
}));

export const Subscribed = styled.div`
  display: flex;
  margin-left: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  opacity: 0.5;
`;
