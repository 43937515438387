import React from 'react';
import styled from 'styled-components';

import {InputBase, Paper} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';

import * as theme from '../../theme';

const TAB_HEIGHT = 32;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SearchContainer = styled(Paper)``;

export const IconButton = styled(MuiIconButton)`
  color: ${theme.navbar.button};
  margin-right: 2px;
`;

export const Search = styled(InputBase)`
  font-size: 14px;
`;

export const Tabs = withStyles((muiTheme) => ({
  indicator: {
    display: 'none'
  },
  root: {
    overflowY: 'auto'
  }
}))(MuiTabs);

export const Tab = withStyles((muiTheme) => ({
  root: {
    '&$selected': {
      backgroundColor: theme.backgrounds.primary
    },
    '&:hover': {
      color: muiTheme.palette.secondary.main
    },
    TAB_HEIGHT,
    lineHeight: 1,
    minHeight: TAB_HEIGHT,
    textAlign: 'left'
  },
  selected: {},
  wrapper: {
    alignItems: 'start'
  }
}))((props) => <MuiTab disableRipple {...props} />);
