import styled from 'styled-components';

import {Grid, Paper} from '@material-ui/core';

import consts from '../../consts';

import {PROGRESS_BAR_HEIGHT} from '../Base/ProgressBar.styles';

export const Container = styled(Paper)`
  white-space: nowrap;
  height: calc(
    100vh - ${consts.TOP_PANEL_HEIGHT}px - ${consts.MAIN_GRID_TOP_PADDING}px - ${consts.MAIN_GRID_BOTTOM_PADDING}px
  );
`;

export const MainGrid = styled(Grid)`
  height: calc(100% - ${PROGRESS_BAR_HEIGHT}px);
`;

export const ScrollableContainer = styled(Grid)`
  height: 100%;
  overflow-y: auto;
`;
