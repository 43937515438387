import styled from 'styled-components';

import CircularProgress from '@material-ui/core/CircularProgress';

import {Form} from '../../../components/Base';

import * as theme from '../../../theme';

export const FieldWrapper = styled(Form.ColumnsField)`
  color: ${theme.navbar.text};
  padding-top: 1em;
`;

export const FieldValue = styled.span`
  margin-left: 3px;
`;

export const CircularLoader = styled(CircularProgress).attrs({size: 14})`
  color: ${theme.text.secondary};
`;
