import client from '../client';

const MTMS_TASKS_URL = 'mtms/api/v1/tasks';

export async function count(searchParams) {
  return client.get(`/maestro/api/v1/services/deployments/_count?${searchParams}`);
}

export async function deploy(hostIds, branchName) {
  return client.post(MTMS_TASKS_URL, {commandId: 1, hostIds, parametersValues: {branch: JSON.stringify(branchName)}});
}

export async function getEnvs() {
  return client.get('/maestro/api/v1/services/deployments/_count', {groupBy: 'environmentId,environmentName'});
}

export async function getNamespaces(environmentId) {
  return client.get('/maestro/api/v1/services/deployments/_count', {
    environmentId,
    groupBy: 'namespaceId,namespaceName'
  });
}

export async function list(searchParams) {
  const url = `/maestro/api/v1/services/deployments?${searchParams}`;
  return client.get(url);
}

export async function restart(deployments) {
  await Promise.all(
    deployments.map(({deploymentId}) => client.post(`/mtms/api/v1/deployments/${deploymentId}/_restart`))
  );
}

export async function updateDeployments(hostId, branch, replicas) {
  return client.post(MTMS_TASKS_URL, {
    commandId: 1,
    configurationValue: {
      key_category: 'DEPLOY',
      key_name: 'replicas',
      key_sub_category: 'general',
      value: JSON.stringify({value: replicas})
    },
    hostIds: [hostId],
    parametersValues: {branch: JSON.stringify(branch)}
  });
}
