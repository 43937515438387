import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

function ObjectViewer({parameters}) {
  function renderItem(item) {
    return _.map(item, (value, key) => {
      if (_.isObject(value) || _.isArray(value)) {
        return (
          <li key={key}>
            {key}:{<ul>{renderItem(value)}</ul>}
          </li>
        );
      } else {
        return <li key={key}>{`${key}: ${value}`}</li>;
      }
    });
  }

  return <ul>{renderItem(parameters)}</ul>;
}

ObjectViewer.propTypes = {
  parameters: PropTypes.object.isRequired
};

export default ObjectViewer;
