import styled from 'styled-components';

import {Button} from '@material-ui/core';

export const SelectAll = styled(Button)`
  margin-left: 8px;
`;

export const Text = styled.span`
  margin-left: 10px;
  font-size: 16px;
  font-weight: normal;
`;
