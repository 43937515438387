import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../../localization/langs';

import * as Styled from './ConfigurationKeyNameCell.styles';

function ConfigurationKeyNameCell({description, keyName}) {
  return (
    <div>
      {!_.isEmpty(description) && (
        <Styled.Tooltip title={description || langs('NO_DESCRIPTION')}>
          <Styled.DescriptionIcon />
        </Styled.Tooltip>
      )}
      {keyName}
    </div>
  );
}

ConfigurationKeyNameCell.propTypes = {
  description: PropTypes.string,
  keyName: PropTypes.string.isRequired
};

export default ConfigurationKeyNameCell;
