import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import * as Styled from './Message.styles';

export const SEVERITY = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning'
};

const ICONS = {
  error: <ErrorOutlineOutlinedIcon />,
  info: <InfoOutlinedIcon />,
  success: <CheckCircleOutlinedIcon />,
  warning: <ReportProblemOutlinedIcon />
};

function Message({children, onClick, severity = SEVERITY.INFO}) {
  return (
    <Styled.Container severity={severity} onClick={onClick}>
      <Styled.Icon>{ICONS[severity]}</Styled.Icon>
      <Styled.Message>{children}</Styled.Message>
    </Styled.Container>
  );
}

Message.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  severity: PropTypes.oneOf(_.values(SEVERITY))
};

export default Message;
