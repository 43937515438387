import PropTypes from 'prop-types';
import React from 'react';

import SearchIcon from '@material-ui/icons/Search';
import {Grid} from '@material-ui/core';

import langs from '../../../../localization/langs';

import * as Styled from './AffectedEntity.styles';

function AffectedEntity({value, onChange, label}) {
  return (
    <Grid item container>
      <Styled.GridItemGrow>
        <Styled.Title>
          {langs('AFFECTED')} {label}
        </Styled.Title>
      </Styled.GridItemGrow>
      <Grid item>
        <Styled.IconButton>
          <SearchIcon color={'inherit'} fontSize={'small'} />
        </Styled.IconButton>
        <Styled.Input value={value} onChange={(event) => onChange(event.target.value)} placeholder={langs('SEARCH')} />
      </Grid>
    </Grid>
  );
}

AffectedEntity.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default AffectedEntity;
