import PropTypes from 'prop-types';
import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import task from '../../../models/task';

import * as Styled from './TaskHeader.styles';

function TaskHeader({progress, buffer, isActive, taskStatus}) {
  const classes = Styled.useStyles({isActive, taskStatus});

  return (
    <Styled.Container>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      {taskStatus === task.taskStatus.PAUSED && <Styled.PauseIcon>✋</Styled.PauseIcon>}
      {taskStatus === task.taskStatus.SUCCESS && <Styled.SuccessIcon />}
      {taskStatus === task.taskStatus.FAILED && <Styled.FailIcon />}
      <LinearProgress variant={'buffer'} classes={classes} value={progress} valueBuffer={buffer} />
    </Styled.Container>
  );
}

TaskHeader.propTypes = {
  buffer: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
  taskStatus: PropTypes.string.isRequired
};

export default TaskHeader;
