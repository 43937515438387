import styled, {css} from 'styled-components';

import * as Theme from '../../theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  ${(props) =>
    props.padded &&
    css`
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.2;
    `}

  ${(props) =>
    props.color &&
    css`
      & > svg {
        color: ${Theme.statusColors[props.color]};
      }
    `}
  
  ${(props) =>
    props.lowProfile &&
    css`
      font-size: 12px;
      margin: 0;
      padding: 0;
    `}
  & > svg {
    font-size: 1.3em;
  }

  & > span {
    margin-left: 0.5rem;
  }
`;
