import _ from 'lodash';
import React from 'react';

import hooks from '../../../hooks';
import modelToQuery from '../../../utils/agGridUtils/filterUtils/modelToQuery';
import queryToModel from '../../../utils/agGridUtils/filterUtils/queryToModel';

export default function useUrlSearch(tableApi, modifyParamsAfterFilterChange) {
  const [filterModel, setFilterModel] = React.useState();
  const [searchQuery, , , setUrlSearchParams] = hooks.useUrlSearchParams();

  function onFilterChanged({api}) {
    const filterModel = api.getFilterModel();
    let newSearchQuery = modelToQuery.getQuery(api, filterModel);
    if (modifyParamsAfterFilterChange) {
      newSearchQuery = modifyParamsAfterFilterChange(newSearchQuery);
    }
    setUrlSearchParams(newSearchQuery);
  }

  React.useEffect(() => {
    const gridApi = tableApi?.gridApi;

    if (!gridApi) {
      return;
    }

    queryToModel.getModel(gridApi, searchQuery).then((newFilterModel) => {
      if (_.isEmpty(newFilterModel) || !_.isEqual(newFilterModel, gridApi.getFilterModel())) {
        setFilterModel(newFilterModel);
      }
    });
  }, [searchQuery.toString(), tableApi]);

  return [filterModel, onFilterChanged];
}
