import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../../localization/langs';

import {Form} from '../../../../components/Base';

import * as Styled from '../ServiceForm.styles';

export const CHART_OPTIONS = {
  Common: 'common_microservice',
  Cron: 'cronejob',
  Other: 'other'
};

const ALLOW_SELECT_OPTIONS = [CHART_OPTIONS.Common, CHART_OPTIONS.Cron];

function ServiceChart({value, onChange}) {
  const valueForSelection = _.includes(ALLOW_SELECT_OPTIONS, value) ? value : CHART_OPTIONS.Other;

  return (
    <Form.Field>
      <Form.Label>{langs('SERVICES_FIELD_CHART')}</Form.Label>
      <Form.FieldWrapper>
        <Styled.Group>
          {_.map(CHART_OPTIONS, (optionValue, label) => (
            <Styled.Option
              disabled={valueForSelection === CHART_OPTIONS.Other || optionValue === CHART_OPTIONS.Other}
              type="button"
              key={optionValue}
              isSelected={valueForSelection === optionValue}
              onClick={() => onChange(optionValue)}
            >
              {label}
            </Styled.Option>
          ))}
        </Styled.Group>
      </Form.FieldWrapper>
    </Form.Field>
  );
}

ServiceChart.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default ServiceChart;
