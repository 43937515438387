import _ from 'lodash';

export function decorateSchema(transformedSchema) {
  return {
    findEnv: (service, name) => _.find(service.envs, {id: name}) || {roles: []},
    findGroup: (name) => _.find(transformedSchema.groups, {id: name}) || {services: []},
    findService: (group, name) => _.find(group.services, {id: name}) || {envs: []},
    ...transformedSchema
  };
}

export function getAllRolesUnselected(transformedSchema) {
  return _.map(_.sortBy(transformedSchema.roles, 'name'), (role) => ({
    id: role.id,
    name: role.name,
    selected: false
  }));
}

export function getLabelDefinitions(
  setSelectedGroup,
  transformedSchema,
  selectedGroup,
  setSelectedService,
  boundServices,
  selectedService,
  onEnvSelection,
  boundEnvs,
  selectedEnv
) {
  return [
    {
      key: 'groupName',
      label: 'GROUP NAME',
      onChange: setSelectedGroup,
      options: _.map(transformedSchema.groups, (group) => ({
        id: group.id,
        name: group.id
      })),
      value: selectedGroup && {id: selectedGroup, name: selectedGroup},
      width: 2
    },
    {
      key: 'serviceName',
      label: 'SERVICE',
      onChange: setSelectedService,
      options: boundServices,
      value: selectedService && {id: selectedService, name: selectedService},
      width: 2
    },
    {
      key: 'envName',
      label: 'ENV',
      onChange: onEnvSelection,
      options: boundEnvs,
      value: selectedEnv && {id: selectedEnv, name: selectedEnv},
      width: 2
    }
  ];
}

export function mapBoundEnvs(selectedService, isDirty, transformedSchema, selectedGroup) {
  return (
    (selectedService &&
      !isDirty &&
      _.map(transformedSchema.findService(transformedSchema.findGroup(selectedGroup), selectedService).envs, (env) => ({
        id: env.id,
        name: env.id
      }))) ||
    []
  );
}

export function mapBoundRoles(transformedSchema, selectedGroup, selectedService, env) {
  const envRoles = _.map(
    transformedSchema.findEnv(
      transformedSchema.findService(transformedSchema.findGroup(selectedGroup), selectedService),
      env
    ).roles,
    'id'
  );

  return _.map(_.sortBy(transformedSchema.roles, 'name'), (role) => ({
    id: role.id,
    name: role.name,
    selected: _.includes(envRoles, role.id)
  }));
}

export function mapBoundServices(selectedGroup, isDirty, transformedSchema) {
  return (
    (selectedGroup &&
      !isDirty &&
      _.map(transformedSchema.findGroup(selectedGroup).services, (service) => ({
        id: service.id,
        name: service.id
      }))) ||
    []
  );
}

export function updateRoleState(currentBinding, roleId, newValue) {
  const roleIndex = _.findIndex(currentBinding.roles, {id: roleId});
  return _.concat(
    _.slice(currentBinding.roles, 0, roleIndex),
    _.assign({}, currentBinding.roles[roleIndex], {selected: newValue}),
    _.slice(currentBinding.roles, roleIndex + 1)
  );
}
