import CytoscapeComponent from 'react-cytoscapejs';
import React from 'react';
import {useParams} from 'react-router-dom';

import context from '../../../context';
import langs from '../../../localization/langs';
import services from '../../../services';

import NodeInfo from './Parts/NodeInfo';

import * as searchOptions from './TenantsFlow.searchOptions';

import * as Styled from './TenantsFlow.styles';

const LAYOUT = {
  directed: true,
  name: 'breadthfirst',
  padding: 10,
  spacingFactor: 1.3
};

function TenantsFlow() {
  const [data, setData] = React.useState(null);
  const [selectedNode, setSelectedNode] = React.useState(null);

  const {tenantId} = useParams();
  const {resetSearchOptions, setOnTextSearch} = context.Search.useSearch();

  const {
    FLOWS: flows,
    QUEUE_TYPES: queueTypes,
    QUEUES_WITH_TRAFFIC_ONLY: queuesWithTrafficOnly
  } = searchOptions.useSearchOptions();

  const cy = React.useRef(null);

  React.useEffect(
    function configureSearchOptions() {
      resetSearchOptions(searchOptions.SEARCH_OPTIONS);
    },
    [tenantId]
  );

  React.useEffect(() => {
    setOnTextSearch(handleSubmit);
    setData(null);
    const minimumMessages = queuesWithTrafficOnly ? 1 : 0;
    services.tenants.getDataFlow({flows, minimumMessages, queueTypes, tenantId}).then(({data: response}) =>
      setData(
        CytoscapeComponent.normalizeElements({
          edges: response.edges.map((data) => ({data})),
          nodes: response.nodes.map((data) => ({data}))
        })
      )
    );
  }, [flows, queueTypes, queuesWithTrafficOnly, tenantId]);

  const measuredRef = React.useCallback((cyRef) => {
    if (cyRef == null) {
      return;
    }
    cy.current = cyRef;

    cyRef.on('click', 'node', function onClick(event) {
      setSelectedNode(event.target);
    });
  }, []);

  function handleSubmit(term) {
    if (cy && cy.current && term) {
      cy.current.$(`[id *= "${term}"]`).animate({style: Styled.searchedNodeStyle});
    }
  }

  return (
    <div>
      {data ? <Styled.Cytoscape layout={LAYOUT} cy={measuredRef} elements={data} /> : <div>{langs('LOADING')}</div>}
      {data && <NodeInfo node={selectedNode} layout={LAYOUT} />}
    </div>
  );
}

export default TenantsFlow;
