import * as beacons from './beacons';
import * as collectors from './collectors';
import * as images from './images';
import * as profile from './profile';

export default {
  beacons,
  ...collectors,
  images,
  profile
};
