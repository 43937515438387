import _ from 'lodash';
import React from 'react';

import context from '../../../context';
import {SEARCH_TYPES} from '../../../context/Search/utils';

import {BooleanSearchOption, ChipsSearchOptions, NullComparisonSearchOption} from '../../Base';
import TitleAndOperator from './TitleAndOperator';

import * as Styled from './AdvancedSearchTab.styles';

function AdvancedSearchTab() {
  const searchContext = context.Search.useSearch();

  const chipOptions = searchContext.searchOptions.filter(({type}) => type === SEARCH_TYPES.CHIPS);
  const textOptions = searchContext.searchOptions.filter(({type}) => type === SEARCH_TYPES.TEXT);
  const booleanOptions = searchContext.searchOptions.filter(({type}) => type === SEARCH_TYPES.BOOLEAN);

  return (
    <form>
      {_.map(chipOptions, (field) => (
        <Styled.Container key={field.key}>
          <TitleAndOperator
            fieldKey={field.key}
            title={field.title}
            supportedOperators={field.supportedOperators}
            filterOperator={field.filterOperator}
            setFilterOperator={searchContext.setFilterOperator}
          />
          <ChipsSearchOptions options={field.options} urlQueryKey={field.key} filterOperator={field.filterOperator} />
        </Styled.Container>
      ))}

      {_.map(textOptions, (field) => (
        <Styled.Container key={field.key}>
          <TitleAndOperator
            fieldKey={field.key}
            title={field.title}
            supportedOperators={field.supportedOperators}
            filterOperator={field.filterOperator}
            setFilterOperator={searchContext.setFilterOperator}
          />
          <Styled.StyledTextSearch
            options={field.options ? field.options.map((option) => ({id: option.key, name: option.value})) : []}
            urlQueryKey={field.key}
            filterOperator={field.filterOperator}
          />
        </Styled.Container>
      ))}

      {booleanOptions.map((field) =>
        field.useNullComparison ? (
          <NullComparisonSearchOption key={field.key} field={field} />
        ) : (
          <BooleanSearchOption key={field.key} field={field} />
        )
      )}
    </form>
  );
}

export default AdvancedSearchTab;
