import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../../localization/langs';

import {Form, Input} from '../../../../components/Base';

import * as Styled from '../ServiceForm.styles';
import * as validations from '../validations';

function ServiceCron({value, onChange}) {
  function isShowError() {
    if (_.isEmpty(value)) {
      return true;
    }
    return !validations.validateCronExpressions(value);
  }

  return (
    <Form.Field>
      <Form.Label>{langs('SERVICES_FIELD_CRON_EXP')}</Form.Label>
      <Form.FieldWrapper>
        <Input
          type="text"
          required
          error={isShowError()}
          value={value}
          placeholder="* * * * *"
          onChange={(e) => onChange(e.target.value)}
        />
      </Form.FieldWrapper>
      {isShowError() && <Styled.Error>{langs('ERROR_INVALID_SERVICE_CRON')}</Styled.Error>}
    </Form.Field>
  );
}

ServiceCron.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default ServiceCron;
