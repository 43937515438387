import {Link as RouterLink} from 'react-router-dom';
import styled, {css} from 'styled-components';

import * as theme from '../../theme';

const baseLink = css`
  color: ${theme.link.text};
  text-decoration: none;
  font-weight: ${({$dense}) => ($dense ? 'regular' : 'bold')};

  &:hover {
    color: ${theme.link.hover};
  }
`;

export const BaseLink = styled.a`
  ${baseLink}
`;
export const Link = styled(RouterLink)`
  ${baseLink}
`;
export const TextLink = styled.span`
  ${baseLink};
  cursor: pointer;
`;
