import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './TaskButton.styles';

function TaskButton({dialog, disabled, text, triggerValue, onClick, icon, rowKey, ...props}) {
  const [value, setValue] = React.useState(null);

  const close = () => setValue(null);
  const open = value !== null;
  const row = {[rowKey]: value};

  return (
    <>
      <Styled.Button disabled={disabled} onClick={() => setValue(onClick(triggerValue))} startIcon={icon} {...props}>
        {text}
      </Styled.Button>
      {dialog({close, open, rows: [row]})}
    </>
  );
}

TaskButton.propTypes = {
  dialog: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  rowKey: PropTypes.string,
  text: PropTypes.string,
  triggerValue: PropTypes.any
};

export default TaskButton;
