import styled from 'styled-components';

import {InputBase} from '@material-ui/core';

import * as theme from '../../theme';

export const IpInput = styled(InputBase)`
  border: 1px solid;
  border-color: ${({error}) => (error ? theme.statusColors.error : theme.backgrounds.primary)};
  background-color: ${theme.backgrounds.primary};
  padding: 0 5px;
`;
