const LEVELS = {
  ALL: 'ALL'
};

const OPERATIONS = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  NOOP: 'NOOP',
  UPDATE: 'UPDATE'
};

const KEY_CATEGORY = {
  APP_DEPLOYMENT: 'APP_DEPLOYMENT',
  CMS: 'CMS',
  FIRMWARE: 'FIRMWARE',
  PROVISION: 'PROVISION'
};

const SUB_CATEGORY = {
  CONTAINER_CONFIG: 'container_config',
  FE_SERVER: 'fe_server',
  GENERAL: 'general',
  PIPELINE: 'pipeline',
  POSTGRES: 'postgres'
};

export default {KEY_CATEGORY, LEVELS, OPERATIONS, SUB_CATEGORY};
