import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Switch from '@material-ui/core/Switch';
import TableRow from '@material-ui/core/TableRow';

import context from '../../../../context';
import enums from '../../enums';

import {ConfirmationModal, DynamicInput} from '../../../Base';
import ConfigurationKeyNameCell from './ConfigurationKeyNameCell';
import ConfigurationRowActions from './ConfigurationRowActions/ConfigurationRowActions';
import ConfigurationVersions from '../../ConfigurationVersions/ConfigurationVersions';

import * as Styled from './ConfigurationRow.styles';

function ConfigurationRow({
  configurationType,
  createdBy,
  creationDate,
  currentLevel,
  description,
  displaySettings,
  extendedTypes,
  inheritanceLevel,
  keyId,
  keyName,
  levels,
  mainCategory,
  onConfigurationChange,
  onDeleteConfigurationKeyValue,
  onGetConfigurationKeyVersions,
  onRollbackKeyVersion,
  permissionsFuncs,
  subCategory,
  value,
  valueId
}) {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [isVersionsModalOpen, setIsVersionsModalOpen] = React.useState(false);

  const user = context.User.useUser();
  const allowedEdit = permissionsFuncs ? permissionsFuncs.edit() : user.hasRole('manage_configurations');

  const isInherits = inheritanceLevel !== currentLevel;

  function onInheritanceChange(newIsInherits) {
    if (newIsInherits) {
      const levelIndex = _.findIndex(levels, (level) => level === inheritanceLevel);
      onConfigurationChange(keyName, subCategory, {
        inheritanceLevel: levels[levelIndex - 1],
        mainCategory,
        subCategory,
        value: ''
      });
    } else {
      onConfigurationChange(keyName, subCategory, {
        inheritanceLevel: currentLevel,
        mainCategory,
        subCategory,
        value
      });
    }
  }

  function createOrUpdate(value) {
    onConfigurationChange(keyName, subCategory, {
      inheritanceLevel: isInherits ? inheritanceLevel : currentLevel,
      mainCategory,
      subCategory,
      value
    });
  }

  function deleteConfigurationKeyValue() {
    if (!allowedEdit || isInherits) {
      return null;
    }
    return () => setIsConfirmModalOpen(true);
  }

  async function onConfirmDeleteConfigurationKeyValue() {
    await onDeleteConfigurationKeyValue(valueId);
    return false;
  }

  function getConfigurationKeyVersions() {
    return onGetConfigurationKeyVersions(keyId);
  }

  async function rollbackKeyVersion(newValue) {
    return onRollbackKeyVersion(keyName, subCategory, {
      inheritanceLevel: currentLevel,
      mainCategory,
      subCategory,
      value: newValue
    });
  }

  return (
    <>
      <TableRow>
        {displaySettings.showInheritCol && (
          <Styled.TableCell>
            <Switch
              disabled={currentLevel === enums.LEVELS.ALL || !allowedEdit}
              size="small"
              checked={isInherits}
              onChange={({target: {checked}}) => onInheritanceChange(checked)}
            />
          </Styled.TableCell>
        )}
        {displaySettings.showInheritCol && (
          <Styled.TableCell>{isInherits ? inheritanceLevel : currentLevel}</Styled.TableCell>
        )}
        <Styled.TableCell component="td" scope="row">
          <ConfigurationKeyNameCell description={description} keyName={keyName} />
        </Styled.TableCell>
        <Styled.TableCell>
          <DynamicInput
            disabled={(isInherits && currentLevel !== enums.LEVELS.ALL) || !allowedEdit}
            type={configurationType}
            value={value}
            onChange={createOrUpdate}
            extendedTypes={extendedTypes}
          />
        </Styled.TableCell>
        {displaySettings.showCreatedByCol && <Styled.TableCell>{createdBy}</Styled.TableCell>}
        {displaySettings.showLastUpdateCol && (
          <Styled.TableCell>
            <ConfigurationRowActions
              keyName={keyName}
              value={value}
              date={creationDate}
              actions={{
                deleteValue: deleteConfigurationKeyValue(),
                showVersions: () => setIsVersionsModalOpen(true)
              }}
              displaySettings={displaySettings}
            />
          </Styled.TableCell>
        )}
      </TableRow>
      <ConfirmationModal
        open={isConfirmModalOpen}
        onConfirm={onConfirmDeleteConfigurationKeyValue}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
      <ConfigurationVersions
        keyName={keyName}
        keyType={configurationType}
        extendedTypes={extendedTypes}
        isOpen={isVersionsModalOpen}
        rollbackKeyVersion={allowedEdit ? rollbackKeyVersion : null}
        getVersions={getConfigurationKeyVersions}
        onClose={() => setIsVersionsModalOpen(false)}
      />
    </>
  );
}

ConfigurationRow.propTypes = {
  configurationType: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  creationDate: PropTypes.string,
  currentLevel: PropTypes.string.isRequired,
  description: PropTypes.string,
  displaySettings: PropTypes.shape({
    showAllLabel: PropTypes.bool,
    showConfigurationKeySearch: PropTypes.bool,
    showCreatedByCol: PropTypes.bool,
    showEmptyFilter: PropTypes.bool,
    showInheritCol: PropTypes.bool,
    showLastUpdateCol: PropTypes.bool,
    titleizeSubCategories: PropTypes.bool
  }),
  extendedTypes: PropTypes.objectOf(PropTypes.elementType),
  inheritanceLevel: PropTypes.string.isRequired,
  keyId: PropTypes.number.isRequired,
  keyName: PropTypes.string.isRequired,
  levels: PropTypes.array.isRequired,
  mainCategory: PropTypes.string.isRequired,
  onConfigurationChange: PropTypes.func.isRequired,
  onDeleteConfigurationKeyValue: PropTypes.func.isRequired,
  onGetConfigurationKeyVersions: PropTypes.func.isRequired,
  onRollbackKeyVersion: PropTypes.func.isRequired,
  permissionsFuncs: PropTypes.shape({edit: PropTypes.func}),
  subCategory: PropTypes.string.isRequired,
  value: PropTypes.any,
  valueId: PropTypes.number.isRequired
};

export default ConfigurationRow;
