import PropTypes from 'prop-types';
import React from 'react';

import services from '../../services';

import ArtifactInput from '../Base/inputs/ArtifactInput';

function TenantsArtifactInput({value, isLatestBuild, onChange, disabled, useObject}) {
  return (
    <ArtifactInput
      disabled={disabled}
      isLatestBuild={isLatestBuild}
      onChange={onChange}
      value={value}
      getArtifact={services.tenants.artifact.getById}
      listArtifacts={services.tenants.artifact.listAll}
      useObject={useObject}
    />
  );
}

TenantsArtifactInput.propTypes = {
  disabled: PropTypes.bool,
  isLatestBuild: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  useObject: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
};

export default TenantsArtifactInput;
