import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from '../NavbarLink.styles';

function NavbarLinkIconAndLabel({label, isHeader}) {
  return (
    <Styled.IconAndLabel>
      {!isHeader && <Styled.Spacer />}
      <Styled.Label isHeader={isHeader}>{label}</Styled.Label>
    </Styled.IconAndLabel>
  );
}

NavbarLinkIconAndLabel.propTypes = {
  isHeader: PropTypes.bool,
  label: PropTypes.string.isRequired
};

export default NavbarLinkIconAndLabel;
