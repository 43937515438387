import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import ListSelection from './ListSelection/ListSelection';

import * as Styled from './CheckListSelection.styles';

function CheckListSelection({checkboxLabel, selectedItems, items, onChange}) {
  function checkListRender(item) {
    return (
      <Styled.CheckListItem key={item.id}>
        <Styled.ListItem onClick={() => itemSelected(item)}>
          <Styled.IconButtonStyled component={DeleteOutlinedIcon} />
          <span>{item.name}</span>
        </Styled.ListItem>
        <div>
          <span>{checkboxLabel}</span>
          <Styled.ListCheckbox onChange={() => itemChecked(item)} />
        </div>
      </Styled.CheckListItem>
    );
  }

  function itemChecked(item) {
    onChange(
      _.map(selectedItems, (selectedItem) => {
        if (selectedItem.id === item.id) {
          return {...selectedItem, checked: !selectedItem.checked};
        }
        return selectedItem;
      })
    );
  }

  function itemSelected({id}) {
    onChange(_.reject(selectedItems, {id}));
  }

  return (
    <ListSelection selectedItems={selectedItems} items={items} onChange={onChange} lowerListRender={checkListRender} />
  );
}

CheckListSelection.propTypes = {
  checkboxLabel: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default CheckListSelection;
