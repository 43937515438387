/*

#######################################################################
##                                                                   ##
##          THE CONTENTS OF THIS FILE ARE GENERATED.                 ##
##          DO NOT MAKE ANY MANUAL CHANGES TO THIS FILE.             ##
##                                                                   ##
#######################################################################

To update this file's contents, please edit configuration file (1) and then run command (2).

    -> (1) armis/services/ops_lib/tools/static_data_exporter/static_data_exporter_config.py
    -> (2) bazel run //armis/services/ops_lib/tools/static_data_exporter:static_data_exporter_runner

*/

/* eslint-disable */
/* prettier-ignore */

export default {
    "ARTIFACT": {
        "BRANCH_TYPES": {
            "FEATURE_BRANCH": "FEATURE_BRANCH",
            "MASTER": "MASTER",
            "RELEASE": "RELEASE",
            "RELEASE_CANDIDATE": "RELEASE_CANDIDATE"
        }
    },
    "CHERRY_PICKS_FIELDS": {
        "APPROVER_ID": "approver_id",
        "BRANCH_NAME": "branch_name",
        "COMMIT_ID": "commit_id",
        "CP_CREATION_DATE": "cp_creation_date",
        "DEPLOYMENT_TYPE": "deployment_type",
        "DEPLOY_ACTIONS": "deploy_actions",
        "ID": "id",
        "JIRA_TICKET": "jira_ticket",
        "PR_ID": "pr_id",
        "PR_URL": "pr_url",
        "REASON": "reason",
        "REQUESTED_USER_CHANNEL_NAME": "requested_user_channel_name",
        "REQUESTED_USER_ID": "requested_user_id",
        "REQUESTED_USER_NAME": "requested_user_name",
        "SOURCE_PR_ID": "source_pr_id",
        "SOURCE_PR_URL": "source_pr_url",
        "STATUS": "status",
        "THREAD_ID": "thread_id",
        "URGENCY": "urgency"
    },
    "COLLECTOR": {
        "AVAILABILITY_STATUS": {
            "ACTIVE": "ACTIVE",
            "AVAILABILITY_STATUS_UNKNOWN": "AVAILABILITY_STATUS_UNKNOWN",
            "INACTIVE": "INACTIVE"
        },
        "DELIVERY_STATUS": {
            "DELIVERY_STATUS_DELAYED": "DELIVERY_STATUS_DELAYED",
            "DELIVERY_STATUS_NEW": "DELIVERY_STATUS_NEW",
            "DELIVERY_STATUS_ORDERED_WITH_SUPPLIER": "DELIVERY_STATUS_ORDERED_WITH_SUPPLIER",
            "DELIVERY_STATUS_RECEIVED": "DELIVERY_STATUS_RECEIVED",
            "DELIVERY_STATUS_SHIPPED": "DELIVERY_STATUS_SHIPPED",
            "DELIVERY_STATUS_UNKNOWN": "DELIVERY_STATUS_UNKNOWN"
        },
        "STAGE": {
            "ACTIVATED": "ACTIVATED",
            "COLLECTOR_STAGE_UNKNOWN": "COLLECTOR_STAGE_UNKNOWN",
            "DECOMMISSIONED": "DECOMMISSIONED",
            "OPERATIONAL": "OPERATIONAL",
            "PRE_PROVISIONED": "PRE_PROVISIONED",
            "PROVISIONED": "PROVISIONED"
        },
        "TASK_ACTIVITY_STATUS": {
            "APP_DEPLOYMENT_FAILED": "APP_DEPLOYMENT_FAILED",
            "APP_DEPLOYMENT_RUNNING": "APP_DEPLOYMENT_RUNNING",
            "FW_UPGRADE_FAILED": "FW_UPGRADE_FAILED",
            "FW_UPGRADE_RUNNING": "FW_UPGRADE_RUNNING",
            "PROVISIONING": "PROVISIONING",
            "PROVISION_FAILED": "PROVISION_FAILED",
            "TASK_ACTIVITY_UNKNOWN": "TASK_ACTIVITY_UNKNOWN",
            "UPGRADE_REQUIRED": "UPGRADE_REQUIRED",
            "UP_TO_DATE": "UP_TO_DATE"
        }
    },
    "DEPLOYMENTS": {
        "RELEASE_CYCLE": {
            "RELEASE_CYCLE_MASTER": "RELEASE_CYCLE_MASTER",
            "RELEASE_CYCLE_RELEASE": "RELEASE_CYCLE_RELEASE",
            "RELEASE_CYCLE_UNKNOWN": "RELEASE_CYCLE_UNKNOWN"
        }
    },
    "RBAC": {
        "RBAC_ENFORCEMENT_LEVEL": {
            "DISABLE": "DISABLE",
            "FULL": "FULL",
            "LOG_ONLY": "LOG_ONLY"
        },
        "SERVICE_NAMES": {
            "CMS": "cms",
            "MAESTRO": "maestro",
            "MTMS": "mtms",
            "OPS_WEB": "ops-web",
            "RBAC": "rbac"
        },
        "SERVICE_PERMISSIONS": {
            "CMS": {
                "BEACON_MANAGE": "beacon_manage",
                "BEACON_PROVISION": "beacon_provision",
                "COLLECTOR_ACTIVATE": "collector_activate",
                "COLLECTOR_CREATE": "collector_create",
                "COLLECTOR_DECOMMISSION": "collector_decommission",
                "COLLECTOR_DELETE": "collector_delete",
                "COLLECTOR_MARK_OPERATIONAL": "collector_mark_operational",
                "CREATE_CONFIGURATION": "create_configuration",
                "CREATE_CONFIGURATION_CATEGORY_ALL": "create_configuration_category_all",
                "CREATE_CONFIGURATION_CATEGORY_APP_CONFIG": "create_configuration_category_app_config",
                "CREATE_CONFIGURATION_CATEGORY_APP_DEPLOYMENT": "create_configuration_category_app_deployment",
                "CREATE_CONFIGURATION_CATEGORY_CMS": "create_configuration_category_cms",
                "CREATE_CONFIGURATION_CATEGORY_DEPLOY_CONFIG": "create_configuration_category_deploy_config",
                "CREATE_CONFIGURATION_CATEGORY_FIRMWARE": "create_configuration_category_firmware",
                "CREATE_CONFIGURATION_CATEGORY_PROVISION": "create_configuration_category_provision",
                "CREATE_CONFIGURATION_CATEGORY_SECRETS": "create_configuration_category_secrets",
                "CREATE_CONFIGURATION_CATEGORY_TERRAFORM": "create_configuration_category_terraform",
                "CREATE_CONFIGURATION_CATEGORY_TMS": "create_configuration_category_tms",
                "CREATE_CONFIGURATION_LEVEL_ALL_ALL": "create_configuration_level_all_all",
                "CREATE_CONFIGURATION_LEVEL_ALL_COLLECTORS": "create_configuration_level_all_collectors",
                "CREATE_CONFIGURATION_LEVEL_ALL_ENVS": "create_configuration_level_all_envs",
                "CREATE_CONFIGURATION_LEVEL_ALL_NAMESPACES": "create_configuration_level_all_namespaces",
                "CREATE_CONFIGURATION_LEVEL_ALL_SERVICES": "create_configuration_level_all_services",
                "CREATE_CONFIGURATION_LEVEL_ALL_TENANTS": "create_configuration_level_all_tenants",
                "DELETE_CONFIGURATION": "delete_configuration",
                "DELETE_CONFIGURATION_CATEGORY_ALL": "delete_configuration_category_all",
                "DELETE_CONFIGURATION_CATEGORY_APP_CONFIG": "delete_configuration_category_app_config",
                "DELETE_CONFIGURATION_CATEGORY_APP_DEPLOYMENT": "delete_configuration_category_app_deployment",
                "DELETE_CONFIGURATION_CATEGORY_CMS": "delete_configuration_category_cms",
                "DELETE_CONFIGURATION_CATEGORY_DEPLOY_CONFIG": "delete_configuration_category_deploy_config",
                "DELETE_CONFIGURATION_CATEGORY_FIRMWARE": "delete_configuration_category_firmware",
                "DELETE_CONFIGURATION_CATEGORY_PROVISION": "delete_configuration_category_provision",
                "DELETE_CONFIGURATION_CATEGORY_SECRETS": "delete_configuration_category_secrets",
                "DELETE_CONFIGURATION_CATEGORY_TERRAFORM": "delete_configuration_category_terraform",
                "DELETE_CONFIGURATION_CATEGORY_TMS": "delete_configuration_category_tms",
                "DELETE_CONFIGURATION_LEVEL_ALL_ALL": "delete_configuration_level_all_all",
                "DELETE_CONFIGURATION_LEVEL_ALL_COLLECTORS": "delete_configuration_level_all_collectors",
                "DELETE_CONFIGURATION_LEVEL_ALL_ENVS": "delete_configuration_level_all_envs",
                "DELETE_CONFIGURATION_LEVEL_ALL_NAMESPACES": "delete_configuration_level_all_namespaces",
                "DELETE_CONFIGURATION_LEVEL_ALL_SERVICES": "delete_configuration_level_all_services",
                "DELETE_CONFIGURATION_LEVEL_ALL_TENANTS": "delete_configuration_level_all_tenants",
                "EDIT_CONFIGURATION": "edit_configuration",
                "EDIT_CONFIGURATION_CATEGORY_ALL": "edit_configuration_category_all",
                "EDIT_CONFIGURATION_CATEGORY_APP_CONFIG": "edit_configuration_category_app_config",
                "EDIT_CONFIGURATION_CATEGORY_APP_DEPLOYMENT": "edit_configuration_category_app_deployment",
                "EDIT_CONFIGURATION_CATEGORY_CMS": "edit_configuration_category_cms",
                "EDIT_CONFIGURATION_CATEGORY_DEPLOY_CONFIG": "edit_configuration_category_deploy_config",
                "EDIT_CONFIGURATION_CATEGORY_FIRMWARE": "edit_configuration_category_firmware",
                "EDIT_CONFIGURATION_CATEGORY_PROVISION": "edit_configuration_category_provision",
                "EDIT_CONFIGURATION_CATEGORY_SECRETS": "edit_configuration_category_secrets",
                "EDIT_CONFIGURATION_CATEGORY_TERRAFORM": "edit_configuration_category_terraform",
                "EDIT_CONFIGURATION_CATEGORY_TMS": "edit_configuration_category_tms",
                "EDIT_CONFIGURATION_LEVEL_ALL_ALL": "edit_configuration_level_all_all",
                "EDIT_CONFIGURATION_LEVEL_ALL_COLLECTORS": "edit_configuration_level_all_collectors",
                "EDIT_CONFIGURATION_LEVEL_ALL_ENVS": "edit_configuration_level_all_envs",
                "EDIT_CONFIGURATION_LEVEL_ALL_NAMESPACES": "edit_configuration_level_all_namespaces",
                "EDIT_CONFIGURATION_LEVEL_ALL_SERVICES": "edit_configuration_level_all_services",
                "EDIT_CONFIGURATION_LEVEL_ALL_TENANTS": "edit_configuration_level_all_tenants",
                "PROTO_FIELDS_TO_PERMISSIONS": {
                    "CreateTaskRequest": {
                        "command_type": [
                            {
                                "permission": "run_task_elevated_high",
                                "value": "APP_DEPLOYMENT"
                            },
                            {
                                "permission": "run_task_elevated_high",
                                "value": "MT_APP_DEPLOYMENT"
                            },
                            {
                                "permission": "run_task_elevated_low",
                                "value": "PROVISION"
                            },
                            {
                                "control_field": {
                                    "field_name": "category",
                                    "values_to_permissions": {
                                        "FIELD_ACTIONS": "run_task_field_action"
                                    }
                                },
                                "permission": "run_task_shell_command",
                                "value": "SHELL"
                            },
                            {
                                "permission": "run_task_elevated_low",
                                "value": "TERRAFORM"
                            },
                            {
                                "permission": "run_task_elevated_high",
                                "value": "UNKNOWN"
                            },
                            {
                                "permission": "run_task_elevated_high",
                                "value": "UPDATE_FIRMWARE"
                            },
                            {
                                "permission": "run_task_elevated_low",
                                "value": "WORKER_ANSIBLE"
                            },
                            {
                                "permission": "run_task_elevated_high",
                                "value": "WORKER_COMMAND"
                            }
                        ]
                    },
                    "collector_details": {
                        "stage": [
                            {
                                "permission": "collector_decommission",
                                "value": "DECOMMISSIONED"
                            },
                            {
                                "permission": "collector_mark_operational",
                                "value": "OPERATIONAL"
                            }
                        ]
                    }
                },
                "READ_CONFIGURATION_VALUES": "read_configuration_values",
                "READ_CONFIGURATION_VALUES_CATEGORY_ALL": "read_configuration_values_category_all",
                "READ_CONFIGURATION_VALUES_CATEGORY_APP_CONFIG": "read_configuration_values_category_app_config",
                "READ_CONFIGURATION_VALUES_CATEGORY_APP_DEPLOYMENT": "read_configuration_values_category_app_deployment",
                "READ_CONFIGURATION_VALUES_CATEGORY_CMS": "read_configuration_values_category_cms",
                "READ_CONFIGURATION_VALUES_CATEGORY_DEPLOY_CONFIG": "read_configuration_values_category_deploy_config",
                "READ_CONFIGURATION_VALUES_CATEGORY_FIRMWARE": "read_configuration_values_category_firmware",
                "READ_CONFIGURATION_VALUES_CATEGORY_PROVISION": "read_configuration_values_category_provision",
                "READ_CONFIGURATION_VALUES_CATEGORY_SECRETS": "read_configuration_values_category_secrets",
                "READ_CONFIGURATION_VALUES_CATEGORY_TERRAFORM": "read_configuration_values_category_terraform",
                "READ_CONFIGURATION_VALUES_CATEGORY_TMS": "read_configuration_values_category_tms",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_ALL": "read_configuration_values_level_all_all",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_COLLECTORS": "read_configuration_values_level_all_collectors",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_ENVS": "read_configuration_values_level_all_envs",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_NAMESPACES": "read_configuration_values_level_all_namespaces",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_SERVICES": "read_configuration_values_level_all_services",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_TENANTS": "read_configuration_values_level_all_tenants",
                "RUN_TASK_ALL": "run_task_all",
                "RUN_TASK_ELEVATED": "run_task_elevated",
                "RUN_TASK_ELEVATED_CRITICAL": "run_task_elevated_critical",
                "RUN_TASK_ELEVATED_HIGH": "run_task_elevated_high",
                "RUN_TASK_ELEVATED_LOW": "run_task_elevated_low",
                "RUN_TASK_ELEVATED_MEDIUM": "run_task_elevated_medium",
                "RUN_TASK_FIELD_ACTION": "run_task_field_action",
                "RUN_TASK_NON_ELEVATED": "run_task_non_elevated",
                "RUN_TASK_SHELL_COMMAND": "run_task_shell_command"
            },
            "MAESTRO": {
                "CREATE_CONFIGURATION": "create_configuration",
                "CREATE_CONFIGURATION_CATEGORY_ALL": "create_configuration_category_all",
                "CREATE_CONFIGURATION_CATEGORY_APP_CONFIG": "create_configuration_category_app_config",
                "CREATE_CONFIGURATION_CATEGORY_APP_DEPLOYMENT": "create_configuration_category_app_deployment",
                "CREATE_CONFIGURATION_CATEGORY_CMS": "create_configuration_category_cms",
                "CREATE_CONFIGURATION_CATEGORY_DEPLOY_CONFIG": "create_configuration_category_deploy_config",
                "CREATE_CONFIGURATION_CATEGORY_FIRMWARE": "create_configuration_category_firmware",
                "CREATE_CONFIGURATION_CATEGORY_PROVISION": "create_configuration_category_provision",
                "CREATE_CONFIGURATION_CATEGORY_SECRETS": "create_configuration_category_secrets",
                "CREATE_CONFIGURATION_CATEGORY_TERRAFORM": "create_configuration_category_terraform",
                "CREATE_CONFIGURATION_CATEGORY_TMS": "create_configuration_category_tms",
                "CREATE_CONFIGURATION_LEVEL_ALL_ALL": "create_configuration_level_all_all",
                "CREATE_CONFIGURATION_LEVEL_ALL_COLLECTORS": "create_configuration_level_all_collectors",
                "CREATE_CONFIGURATION_LEVEL_ALL_ENVS": "create_configuration_level_all_envs",
                "CREATE_CONFIGURATION_LEVEL_ALL_NAMESPACES": "create_configuration_level_all_namespaces",
                "CREATE_CONFIGURATION_LEVEL_ALL_SERVICES": "create_configuration_level_all_services",
                "CREATE_CONFIGURATION_LEVEL_ALL_TENANTS": "create_configuration_level_all_tenants",
                "DELETE_CONFIGURATION": "delete_configuration",
                "DELETE_CONFIGURATION_CATEGORY_ALL": "delete_configuration_category_all",
                "DELETE_CONFIGURATION_CATEGORY_APP_CONFIG": "delete_configuration_category_app_config",
                "DELETE_CONFIGURATION_CATEGORY_APP_DEPLOYMENT": "delete_configuration_category_app_deployment",
                "DELETE_CONFIGURATION_CATEGORY_CMS": "delete_configuration_category_cms",
                "DELETE_CONFIGURATION_CATEGORY_DEPLOY_CONFIG": "delete_configuration_category_deploy_config",
                "DELETE_CONFIGURATION_CATEGORY_FIRMWARE": "delete_configuration_category_firmware",
                "DELETE_CONFIGURATION_CATEGORY_PROVISION": "delete_configuration_category_provision",
                "DELETE_CONFIGURATION_CATEGORY_SECRETS": "delete_configuration_category_secrets",
                "DELETE_CONFIGURATION_CATEGORY_TERRAFORM": "delete_configuration_category_terraform",
                "DELETE_CONFIGURATION_CATEGORY_TMS": "delete_configuration_category_tms",
                "DELETE_CONFIGURATION_LEVEL_ALL_ALL": "delete_configuration_level_all_all",
                "DELETE_CONFIGURATION_LEVEL_ALL_COLLECTORS": "delete_configuration_level_all_collectors",
                "DELETE_CONFIGURATION_LEVEL_ALL_ENVS": "delete_configuration_level_all_envs",
                "DELETE_CONFIGURATION_LEVEL_ALL_NAMESPACES": "delete_configuration_level_all_namespaces",
                "DELETE_CONFIGURATION_LEVEL_ALL_SERVICES": "delete_configuration_level_all_services",
                "DELETE_CONFIGURATION_LEVEL_ALL_TENANTS": "delete_configuration_level_all_tenants",
                "EDIT_CONFIGURATION": "edit_configuration",
                "EDIT_CONFIGURATION_CATEGORY_ALL": "edit_configuration_category_all",
                "EDIT_CONFIGURATION_CATEGORY_APP_CONFIG": "edit_configuration_category_app_config",
                "EDIT_CONFIGURATION_CATEGORY_APP_DEPLOYMENT": "edit_configuration_category_app_deployment",
                "EDIT_CONFIGURATION_CATEGORY_CMS": "edit_configuration_category_cms",
                "EDIT_CONFIGURATION_CATEGORY_DEPLOY_CONFIG": "edit_configuration_category_deploy_config",
                "EDIT_CONFIGURATION_CATEGORY_FIRMWARE": "edit_configuration_category_firmware",
                "EDIT_CONFIGURATION_CATEGORY_PROVISION": "edit_configuration_category_provision",
                "EDIT_CONFIGURATION_CATEGORY_SECRETS": "edit_configuration_category_secrets",
                "EDIT_CONFIGURATION_CATEGORY_TERRAFORM": "edit_configuration_category_terraform",
                "EDIT_CONFIGURATION_CATEGORY_TMS": "edit_configuration_category_tms",
                "EDIT_CONFIGURATION_LEVEL_ALL_ALL": "edit_configuration_level_all_all",
                "EDIT_CONFIGURATION_LEVEL_ALL_COLLECTORS": "edit_configuration_level_all_collectors",
                "EDIT_CONFIGURATION_LEVEL_ALL_ENVS": "edit_configuration_level_all_envs",
                "EDIT_CONFIGURATION_LEVEL_ALL_NAMESPACES": "edit_configuration_level_all_namespaces",
                "EDIT_CONFIGURATION_LEVEL_ALL_SERVICES": "edit_configuration_level_all_services",
                "EDIT_CONFIGURATION_LEVEL_ALL_TENANTS": "edit_configuration_level_all_tenants",
                "MODIFY_IS_CD_ENABLED": "modify_is_cd_enabled",
                "MODIFY_MONITORING": "modify_monitoring",
                "MODIFY_STAGE": "modify_stage",
                "PROTO_FIELDS_TO_PERMISSIONS": {
                    "CreateTaskRequest": {
                        "command_type": [
                            {
                                "permission": "run_task_elevated_high",
                                "value": "APP_DEPLOYMENT"
                            },
                            {
                                "permission": "run_task_elevated_high",
                                "value": "MT_APP_DEPLOYMENT"
                            },
                            {
                                "permission": "run_task_elevated_low",
                                "value": "PROVISION"
                            },
                            {
                                "control_field": {
                                    "field_name": "category",
                                    "values_to_permissions": {
                                        "FIELD_ACTIONS": "run_task_field_action"
                                    }
                                },
                                "permission": "run_task_shell_command",
                                "value": "SHELL"
                            },
                            {
                                "permission": "run_task_elevated_low",
                                "value": "TERRAFORM"
                            },
                            {
                                "permission": "run_task_elevated_high",
                                "value": "UNKNOWN"
                            },
                            {
                                "permission": "run_task_elevated_high",
                                "value": "UPDATE_FIRMWARE"
                            },
                            {
                                "permission": "run_task_elevated_low",
                                "value": "WORKER_ANSIBLE"
                            },
                            {
                                "permission": "run_task_elevated_high",
                                "value": "WORKER_COMMAND"
                            }
                        ]
                    },
                    "tenant_update": {
                        "is_cd_enabled": "modify_is_cd_enabled",
                        "is_monitored": "modify_monitoring",
                        "stage": "modify_stage"
                    }
                },
                "READ_CONFIGURATION_VALUES": "read_configuration_values",
                "READ_CONFIGURATION_VALUES_CATEGORY_ALL": "read_configuration_values_category_all",
                "READ_CONFIGURATION_VALUES_CATEGORY_APP_CONFIG": "read_configuration_values_category_app_config",
                "READ_CONFIGURATION_VALUES_CATEGORY_APP_DEPLOYMENT": "read_configuration_values_category_app_deployment",
                "READ_CONFIGURATION_VALUES_CATEGORY_CMS": "read_configuration_values_category_cms",
                "READ_CONFIGURATION_VALUES_CATEGORY_DEPLOY_CONFIG": "read_configuration_values_category_deploy_config",
                "READ_CONFIGURATION_VALUES_CATEGORY_FIRMWARE": "read_configuration_values_category_firmware",
                "READ_CONFIGURATION_VALUES_CATEGORY_PROVISION": "read_configuration_values_category_provision",
                "READ_CONFIGURATION_VALUES_CATEGORY_SECRETS": "read_configuration_values_category_secrets",
                "READ_CONFIGURATION_VALUES_CATEGORY_TERRAFORM": "read_configuration_values_category_terraform",
                "READ_CONFIGURATION_VALUES_CATEGORY_TMS": "read_configuration_values_category_tms",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_ALL": "read_configuration_values_level_all_all",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_COLLECTORS": "read_configuration_values_level_all_collectors",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_ENVS": "read_configuration_values_level_all_envs",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_NAMESPACES": "read_configuration_values_level_all_namespaces",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_SERVICES": "read_configuration_values_level_all_services",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_TENANTS": "read_configuration_values_level_all_tenants",
                "RMQ_RELAY_STOP": "rmq_relay_stop",
                "RUN_TASK_ALL": "run_task_all",
                "RUN_TASK_ELEVATED": "run_task_elevated",
                "RUN_TASK_ELEVATED_CRITICAL": "run_task_elevated_critical",
                "RUN_TASK_ELEVATED_HIGH": "run_task_elevated_high",
                "RUN_TASK_ELEVATED_LOW": "run_task_elevated_low",
                "RUN_TASK_ELEVATED_MEDIUM": "run_task_elevated_medium",
                "RUN_TASK_FIELD_ACTION": "run_task_field_action",
                "RUN_TASK_NON_ELEVATED": "run_task_non_elevated",
                "RUN_TASK_SHELL_COMMAND": "run_task_shell_command",
                "TENANT_CREATE": "tenant_create",
                "TENANT_DESTROY": "tenant_destroy",
                "TENANT_READ": "tenant_read",
                "TENANT_STOP": "tenant_stop",
                "TENANT_UPDATE": "tenant_update"
            },
            "MTMS": {
                "CREATE_CONFIGURATION": "create_configuration",
                "CREATE_CONFIGURATION_CATEGORY_ALL": "create_configuration_category_all",
                "CREATE_CONFIGURATION_CATEGORY_APP_CONFIG": "create_configuration_category_app_config",
                "CREATE_CONFIGURATION_CATEGORY_APP_DEPLOYMENT": "create_configuration_category_app_deployment",
                "CREATE_CONFIGURATION_CATEGORY_CMS": "create_configuration_category_cms",
                "CREATE_CONFIGURATION_CATEGORY_DEPLOY_CONFIG": "create_configuration_category_deploy_config",
                "CREATE_CONFIGURATION_CATEGORY_FIRMWARE": "create_configuration_category_firmware",
                "CREATE_CONFIGURATION_CATEGORY_PROVISION": "create_configuration_category_provision",
                "CREATE_CONFIGURATION_CATEGORY_SECRETS": "create_configuration_category_secrets",
                "CREATE_CONFIGURATION_CATEGORY_TERRAFORM": "create_configuration_category_terraform",
                "CREATE_CONFIGURATION_CATEGORY_TMS": "create_configuration_category_tms",
                "CREATE_CONFIGURATION_LEVEL_ALL_ALL": "create_configuration_level_all_all",
                "CREATE_CONFIGURATION_LEVEL_ALL_COLLECTORS": "create_configuration_level_all_collectors",
                "CREATE_CONFIGURATION_LEVEL_ALL_ENVS": "create_configuration_level_all_envs",
                "CREATE_CONFIGURATION_LEVEL_ALL_NAMESPACES": "create_configuration_level_all_namespaces",
                "CREATE_CONFIGURATION_LEVEL_ALL_SERVICES": "create_configuration_level_all_services",
                "CREATE_CONFIGURATION_LEVEL_ALL_TENANTS": "create_configuration_level_all_tenants",
                "DELETE_CONFIGURATION": "delete_configuration",
                "DELETE_CONFIGURATION_CATEGORY_ALL": "delete_configuration_category_all",
                "DELETE_CONFIGURATION_CATEGORY_APP_CONFIG": "delete_configuration_category_app_config",
                "DELETE_CONFIGURATION_CATEGORY_APP_DEPLOYMENT": "delete_configuration_category_app_deployment",
                "DELETE_CONFIGURATION_CATEGORY_CMS": "delete_configuration_category_cms",
                "DELETE_CONFIGURATION_CATEGORY_DEPLOY_CONFIG": "delete_configuration_category_deploy_config",
                "DELETE_CONFIGURATION_CATEGORY_FIRMWARE": "delete_configuration_category_firmware",
                "DELETE_CONFIGURATION_CATEGORY_PROVISION": "delete_configuration_category_provision",
                "DELETE_CONFIGURATION_CATEGORY_SECRETS": "delete_configuration_category_secrets",
                "DELETE_CONFIGURATION_CATEGORY_TERRAFORM": "delete_configuration_category_terraform",
                "DELETE_CONFIGURATION_CATEGORY_TMS": "delete_configuration_category_tms",
                "DELETE_CONFIGURATION_LEVEL_ALL_ALL": "delete_configuration_level_all_all",
                "DELETE_CONFIGURATION_LEVEL_ALL_COLLECTORS": "delete_configuration_level_all_collectors",
                "DELETE_CONFIGURATION_LEVEL_ALL_ENVS": "delete_configuration_level_all_envs",
                "DELETE_CONFIGURATION_LEVEL_ALL_NAMESPACES": "delete_configuration_level_all_namespaces",
                "DELETE_CONFIGURATION_LEVEL_ALL_SERVICES": "delete_configuration_level_all_services",
                "DELETE_CONFIGURATION_LEVEL_ALL_TENANTS": "delete_configuration_level_all_tenants",
                "EDIT_CONFIGURATION": "edit_configuration",
                "EDIT_CONFIGURATION_CATEGORY_ALL": "edit_configuration_category_all",
                "EDIT_CONFIGURATION_CATEGORY_APP_CONFIG": "edit_configuration_category_app_config",
                "EDIT_CONFIGURATION_CATEGORY_APP_DEPLOYMENT": "edit_configuration_category_app_deployment",
                "EDIT_CONFIGURATION_CATEGORY_CMS": "edit_configuration_category_cms",
                "EDIT_CONFIGURATION_CATEGORY_DEPLOY_CONFIG": "edit_configuration_category_deploy_config",
                "EDIT_CONFIGURATION_CATEGORY_FIRMWARE": "edit_configuration_category_firmware",
                "EDIT_CONFIGURATION_CATEGORY_PROVISION": "edit_configuration_category_provision",
                "EDIT_CONFIGURATION_CATEGORY_SECRETS": "edit_configuration_category_secrets",
                "EDIT_CONFIGURATION_CATEGORY_TERRAFORM": "edit_configuration_category_terraform",
                "EDIT_CONFIGURATION_CATEGORY_TMS": "edit_configuration_category_tms",
                "EDIT_CONFIGURATION_LEVEL_ALL_ALL": "edit_configuration_level_all_all",
                "EDIT_CONFIGURATION_LEVEL_ALL_COLLECTORS": "edit_configuration_level_all_collectors",
                "EDIT_CONFIGURATION_LEVEL_ALL_ENVS": "edit_configuration_level_all_envs",
                "EDIT_CONFIGURATION_LEVEL_ALL_NAMESPACES": "edit_configuration_level_all_namespaces",
                "EDIT_CONFIGURATION_LEVEL_ALL_SERVICES": "edit_configuration_level_all_services",
                "EDIT_CONFIGURATION_LEVEL_ALL_TENANTS": "edit_configuration_level_all_tenants",
                "MT_DEPLOYMENTS_ALL": "mt_deployments_all",
                "MT_DEPLOYMENTS_CREATE": "mt_deployments_create",
                "MT_DEPLOYMENTS_DEPLOY": "mt_deployments_deploy",
                "MT_DEPLOYMENTS_READ": "mt_deployments_read",
                "MT_DEPLOYMENTS_RESTART": "mt_deployments_restart",
                "MT_DEPLOYMENTS_SCALE": "mt_deployments_scale",
                "MT_DEPLOYMENTS_SHOW_LOGS": "mt_deployments_show_logs",
                "MT_DEPLOYMENTS_STOP": "mt_deployments_stop",
                "MT_ENVS_ALL": "mt_envs_all",
                "MT_ENVS_DEPLOY": "mt_envs_deploy",
                "MT_ENVS_READ": "mt_envs_read",
                "MT_ENVS_RESTART": "mt_envs_restart",
                "MT_ENVS_SCALE": "mt_envs_scale",
                "MT_ENVS_STOP": "mt_envs_stop",
                "MT_NAMESPACES_ALL": "mt_namespaces_all",
                "MT_NAMESPACES_CREATE": "mt_namespaces_create",
                "MT_NAMESPACES_DEPLOY": "mt_namespaces_deploy",
                "MT_NAMESPACES_EDIT": "mt_namespaces_edit",
                "MT_NAMESPACES_READ": "mt_namespaces_read",
                "MT_NAMESPACES_RESTART": "mt_namespaces_restart",
                "MT_NAMESPACES_SCALE": "mt_namespaces_scale",
                "MT_NAMESPACES_STOP": "mt_namespaces_stop",
                "MT_SERVICES_ALL": "mt_services_all",
                "MT_SERVICES_CREATE": "mt_services_create",
                "MT_SERVICES_DEPLOY": "mt_services_deploy",
                "MT_SERVICES_EDIT": "mt_services_edit",
                "MT_SERVICES_READ": "mt_services_read",
                "MT_SERVICES_RESTART": "mt_services_restart",
                "MT_SERVICES_SCALE": "mt_services_scale",
                "MT_SERVICES_SHOW_LOGS": "mt_services_show_logs",
                "MT_SERVICES_STOP": "mt_services_stop",
                "MT_STACKS_ALL": "mt_stacks_all",
                "MT_STACKS_CREATE": "mt_stacks_create",
                "MT_STACKS_DEPLOY": "mt_stacks_deploy",
                "MT_STACKS_EDIT": "mt_stacks_edit",
                "MT_STACKS_READ": "mt_stacks_read",
                "MT_STACKS_RESTART": "mt_stacks_restart",
                "MT_STACKS_SCALE": "mt_stacks_scale",
                "MT_STACKS_STOP": "mt_stacks_stop",
                "READ_CONFIGURATION_VALUES": "read_configuration_values",
                "READ_CONFIGURATION_VALUES_CATEGORY_ALL": "read_configuration_values_category_all",
                "READ_CONFIGURATION_VALUES_CATEGORY_APP_CONFIG": "read_configuration_values_category_app_config",
                "READ_CONFIGURATION_VALUES_CATEGORY_APP_DEPLOYMENT": "read_configuration_values_category_app_deployment",
                "READ_CONFIGURATION_VALUES_CATEGORY_CMS": "read_configuration_values_category_cms",
                "READ_CONFIGURATION_VALUES_CATEGORY_DEPLOY_CONFIG": "read_configuration_values_category_deploy_config",
                "READ_CONFIGURATION_VALUES_CATEGORY_FIRMWARE": "read_configuration_values_category_firmware",
                "READ_CONFIGURATION_VALUES_CATEGORY_PROVISION": "read_configuration_values_category_provision",
                "READ_CONFIGURATION_VALUES_CATEGORY_SECRETS": "read_configuration_values_category_secrets",
                "READ_CONFIGURATION_VALUES_CATEGORY_TERRAFORM": "read_configuration_values_category_terraform",
                "READ_CONFIGURATION_VALUES_CATEGORY_TMS": "read_configuration_values_category_tms",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_ALL": "read_configuration_values_level_all_all",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_COLLECTORS": "read_configuration_values_level_all_collectors",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_ENVS": "read_configuration_values_level_all_envs",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_NAMESPACES": "read_configuration_values_level_all_namespaces",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_SERVICES": "read_configuration_values_level_all_services",
                "READ_CONFIGURATION_VALUES_LEVEL_ALL_TENANTS": "read_configuration_values_level_all_tenants"
            },
            "OPS_WEB": {
                "FE_SELECT_EFFECTIVE_ROLE_GROUPS": "fe_select_effective_role_groups",
                "VIEW_CHERRY_PICKS": "view_cherry_picks",
                "VIEW_COLLECTORS": "view_collectors",
                "VIEW_COLLECTORS_BEACONS": "view_collectors_beacons",
                "VIEW_COLLECTORS_CONFIG": "view_collectors_config",
                "VIEW_COLLECTORS_IMAGES": "view_collectors_images",
                "VIEW_COLLECTORS_TASKS": "view_collectors_tasks",
                "VIEW_DEMO": "view_demo",
                "VIEW_MT": "view_mt",
                "VIEW_MT_CONFIG": "view_mt_config",
                "VIEW_MT_LOGS": "view_mt_logs",
                "VIEW_MT_NAMESPACES": "view_mt_namespaces",
                "VIEW_MT_SERVICES": "view_mt_services",
                "VIEW_MT_STACKS": "view_mt_stacks",
                "VIEW_MT_TASKS": "view_mt_tasks",
                "VIEW_TENANTS": "view_tenants",
                "VIEW_TENANTS_CONFIG": "view_tenants_config",
                "VIEW_TENANTS_TASKS": "view_tenants_tasks"
            },
            "RBAC": {
                "EDIT_SCHEMA": "edit_schema",
                "EXPORT_SCHEMA": "export_schema"
            }
        }
    },
    "RELEASE_FIELDS": {
        "APPROVERS": "approvers",
        "BUILD_ID": "build_id",
        "CREATED_BY": "created_by",
        "DEPLOYMENT_PARAMS": "deployment_params",
        "DEPLOYMENT_TYPES": "deployment_types",
        "DEPLOY_NEEDED": "deploy_needed",
        "ID": "id",
        "JIRA_TICKET": "jira_ticket",
        "PR_ID": "pr_id",
        "REASON": "reason",
        "RELEASE_CREATION_DATE": "release_creation_date",
        "RELEASE_NAME": "release_name",
        "RELEASE_REQUEST_ID": "release_request_id",
        "SOURCE_PR_IDS": "source_pr_ids",
        "STATUS": "status",
        "URGENCY": "urgency"
    },
    "TENANT": {
        "ADDON": {
            "AVM": 0,
            "EWA": 1,
            "HEALTHCARE": 2,
            "OT": 3
        },
        "AVAILABILITY_STATUS": {
            "AVAILABILITY_STATUS_ACTIVE": "AVAILABILITY_STATUS_ACTIVE",
            "AVAILABILITY_STATUS_INACTIVE": "AVAILABILITY_STATUS_INACTIVE",
            "AVAILABILITY_STATUS_UNKNOWN": "AVAILABILITY_STATUS_UNKNOWN"
        },
        "DETAILS": {
            "ADDONS": "addons",
            "AMI_ID": "ami_id",
            "APPLICATION_VERSION": "application_version",
            "APPLICATION_VERSION_MAJOR": "application_version_major",
            "ARCHITECTURE": "architecture",
            "AVAILABILITY_ZONE": "availability_zone",
            "COMMAND_ERROR": "command_error",
            "COMMAND_EXIT_CODE": "command_exit_code",
            "COMMAND_OUTPUT": "command_output",
            "COMMAND_STATUS": "command_status",
            "COMMENT": "comment",
            "CREATION_DATE": "creation_date",
            "DEFAULT_IPV_4_ADDRESS": "default_ipv4_address",
            "DEFAULT_IPV_4_ALIAS": "default_ipv4_alias",
            "DEFAULT_IPV_4_CIDR": "default_ipv4_cidr",
            "DEFAULT_IPV_4_GATEWAY": "default_ipv4_gateway",
            "DEFAULT_IPV_4_MACADDRESS": "default_ipv4_macaddress",
            "DEMO_METADATA_END_TIME": "demo_metadata_end_time",
            "DEMO_METADATA_INITIATOR_EMAIL": "demo_metadata_initiator_email",
            "DEMO_METADATA_MODULES_TO_SEED": "demo_metadata_modules_to_seed",
            "DEMO_METADATA_OVERRIDE_VMS": "demo_metadata_override_vms",
            "DEMO_METADATA_START_TIME": "demo_metadata_start_time",
            "DEMO_METADATA_STATUS": "demo_metadata_status",
            "DEMO_METADATA_TABLES_TO_IGNORE": "demo_metadata_tables_to_ignore",
            "DEVICE_RETENTION_PERIOD_MONTHS": "device_retention_period_months",
            "DEVICE_RETENTION_PLAN_MONTHS": "device_retention_plan_months",
            "DNS_SERVERS": "dns_servers",
            "ENVIRONMENT_NAME": "environment_name",
            "ENVIRONMENT_TYPE": "environment_type",
            "ENV_ID": "env_id",
            "FIRMWARE_BRANCH": "firmware_branch",
            "FIRMWARE_BUILD_ID": "firmware_build_id",
            "FIRMWARE_VERSION": "firmware_version",
            "FIRMWARE_VERSION_ID": "firmware_version_id",
            "GROUP": "group",
            "HOSTNAME": "hostname",
            "IAM_INSTANCE_PROFILE_ROLE": "iam_instance_profile_role",
            "INDUSTRY": "industry",
            "INSTANCE_ID": "instance_id",
            "INSTANCE_TYPE": "instance_type",
            "IS_CD_ENABLED": "is_cd_enabled",
            "IS_DEPLOY_LOCKED": "is_deploy_locked",
            "IS_MONITORED": "is_monitored",
            "KERNEL": "kernel",
            "LAST_COMMENTED_BY": "last_commented_by",
            "LAST_COMMENTED_DATE": "last_commented_date",
            "LAST_DEPLOYMENT_DATE": "last_deployment_date",
            "LAST_SEEN": "last_seen",
            "LAST_SUCCESSFUL_SNAPSHOT_DATE": "last_successful_snapshot_date",
            "MEMTOTAL_MB": "memtotal_mb",
            "NEXT_DEPLOYMENT_DATE": "next_deployment_date",
            "NUMBER_OF_INTERFACES": "number_of_interfaces",
            "OS_VERSION": "os_version",
            "OWNER": "owner",
            "PACKAGE": "package",
            "PARTNER_ID": "partner_id",
            "PARTNER_NAME": "partner_name",
            "PATCHES": "patches",
            "PROCESSOR_CORES": "processor_cores",
            "PROCESSOR_COUNT": "processor_count",
            "REGION": "region",
            "SECURITY_GROUPS": "security_groups",
            "SF_ACCOUNT_ID": "sf_account_id",
            "SF_ACCOUNT_TYPE": "sf_account_type",
            "STAGE": "stage",
            "STATUS": "status",
            "SWAPTOTAL_MB": "swaptotal_mb",
            "SYSTEM_VENDOR": "system_vendor",
            "TASK_ACTIVITY_STATUS": "task_activity_status",
            "TENANT_ID": "tenant_id",
            "TENANT_NAME": "tenant_name",
            "TENANT_STATUS": "tenant_status",
            "TERRAFORM_STATUS": "terraform_status",
            "TIER": "tier"
        },
        "INDUSTRIES": [
            "Accommodation and Food Services",
            "Administrative and Support and Waste Management and Remediation Services",
            "Agriculture, Forestry, Fishing and Hunting",
            "Arts, Entertainment, and Recreation",
            "Construction",
            "Educational Services",
            "Finance and Insurance",
            "Health Care and Social Assistance",
            "Information",
            "Management of Companies and Enterprises",
            "Manufacturing",
            "Mining",
            "Other Services (except Public Administration)",
            "Professional, Scientific, and Technical Services",
            "Public Administration",
            "Real Estate Rental and Leasing",
            "Retail Trade",
            "Transportation and Warehousing",
            "Utilities",
            "Wholesale Trade"
        ],
        "PACKAGE": {
            "COLLECT": 1,
            "CONNECT": 0,
            "DETECT": 3,
            "INSPECT": 2
        },
        "SALESFORCE_ACCOUNT_TYPE": {
            "SALESFORCE_ACCOUNT_TYPE_CONTINGENT_PARTNER": "SALESFORCE_ACCOUNT_TYPE_CONTINGENT_PARTNER",
            "SALESFORCE_ACCOUNT_TYPE_CUSTOMER": "SALESFORCE_ACCOUNT_TYPE_CUSTOMER",
            "SALESFORCE_ACCOUNT_TYPE_DISTRIBUTOR": "SALESFORCE_ACCOUNT_TYPE_DISTRIBUTOR",
            "SALESFORCE_ACCOUNT_TYPE_FORMER_CUSTOMER": "SALESFORCE_ACCOUNT_TYPE_FORMER_CUSTOMER",
            "SALESFORCE_ACCOUNT_TYPE_INTEGRATOR": "SALESFORCE_ACCOUNT_TYPE_INTEGRATOR",
            "SALESFORCE_ACCOUNT_TYPE_OTHER": "SALESFORCE_ACCOUNT_TYPE_OTHER",
            "SALESFORCE_ACCOUNT_TYPE_PAID_POV": "SALESFORCE_ACCOUNT_TYPE_PAID_POV",
            "SALESFORCE_ACCOUNT_TYPE_PARTNER": "SALESFORCE_ACCOUNT_TYPE_PARTNER",
            "SALESFORCE_ACCOUNT_TYPE_PROSPECT": "SALESFORCE_ACCOUNT_TYPE_PROSPECT",
            "SALESFORCE_ACCOUNT_TYPE_PROSPECTIVE_PARTNER": "SALESFORCE_ACCOUNT_TYPE_PROSPECTIVE_PARTNER",
            "SALESFORCE_ACCOUNT_TYPE_UNKNOWN": "SALESFORCE_ACCOUNT_TYPE_UNKNOWN"
        },
        "STAGES": {
            "ACTIVE_CLIENT": "ACTIVE_CLIENT",
            "ARCHIVED": "ARCHIVED",
            "CLIENT_ARCHIVED": "CLIENT_ARCHIVED",
            "DEMO": "DEMO",
            "DEVELOPMENT": "DEVELOPMENT",
            "MANAGED_BY_PARTNER": "MANAGED_BY_PARTNER",
            "OPERATIONAL": "OPERATIONAL",
            "POC": "POC",
            "POST_POC": "POST_POC",
            "PRE_POC": "PRE_POC",
            "STOPPED": "STOPPED",
            "TERMINATED": "TERMINATED",
            "TEST": "TEST"
        },
        "STAGES_TRANSLATED": {
            "ACTIVE_CLIENT": "Active Client",
            "ARCHIVED": "Archived",
            "CLIENT_ARCHIVED": "Client Archived",
            "DEMO": "Demo",
            "DEVELOPMENT": "Development",
            "MANAGED_BY_PARTNER": "Managed By Partner",
            "OPERATIONAL": "Operational",
            "POC": "POC",
            "POST_POC": "Post-POC",
            "PRE_POC": "Pre-POC",
            "STOPPED": "Stopped",
            "TERMINATED": "Terminated",
            "TEST": "Test"
        },
        "STATUS": {
            "AMI_ID": "ami_id",
            "APPLICATION_VERSION": "application_version",
            "APP_DEPLOYMENT_VERSION_ID": "app_deployment_version_id",
            "ARCHITECTURE": "architecture",
            "AVAILABILITY_ZONE": "availability_zone",
            "COMMENT": "comment",
            "DEFAULT_IPV_4_ADDRESS": "default_ipv4_address",
            "DEFAULT_IPV_4_ALIAS": "default_ipv4_alias",
            "DEFAULT_IPV_4_CIDR": "default_ipv4_cidr",
            "DEFAULT_IPV_4_GATEWAY": "default_ipv4_gateway",
            "DEFAULT_IPV_4_MACADDRESS": "default_ipv4_macaddress",
            "DEMO_METADATA_END_TIME": "demo_metadata_end_time",
            "DEMO_METADATA_INITIATOR_EMAIL": "demo_metadata_initiator_email",
            "DEMO_METADATA_MODULES_TO_SEED": "demo_metadata_modules_to_seed",
            "DEMO_METADATA_OVERRIDE_VMS": "demo_metadata_override_vms",
            "DEMO_METADATA_START_TIME": "demo_metadata_start_time",
            "DEMO_METADATA_STATUS": "demo_metadata_status",
            "DEMO_METADATA_TABLES_TO_IGNORE": "demo_metadata_tables_to_ignore",
            "DEVICE_RETENTION_PERIOD_MONTHS": "device_retention_period_months",
            "DEVICE_RETENTION_PLAN_MONTHS": "device_retention_plan_months",
            "DNS_SERVERS": "dns_servers",
            "FIRMWARE_VERSION_ID": "firmware_version_id",
            "HOSTNAME": "hostname",
            "IAM_INSTANCE_PROFILE_ROLE": "iam_instance_profile_role",
            "INDUSTRY": "industry",
            "INSTANCE_ID": "instance_id",
            "INSTANCE_TYPE": "instance_type",
            "IS_MONITORED": "is_monitored",
            "KERNEL": "kernel",
            "LAST_COMMENTED_BY": "last_commented_by",
            "LAST_COMMENTED_DATE": "last_commented_date",
            "LAST_DEPLOYMENT_DATE": "last_deployment_date",
            "LAST_SUCCESSFUL_SNAPSHOT_DATE": "last_successful_snapshot_date",
            "MEMTOTAL_MB": "memtotal_mb",
            "NEXT_DEPLOYMENT_DATE": "next_deployment_date",
            "NUMBER_OF_INTERFACES": "number_of_interfaces",
            "OS_VERSION": "os_version",
            "PROCESSOR_CORES": "processor_cores",
            "PROCESSOR_COUNT": "processor_count",
            "REGION": "region",
            "SECURITY_GROUPS": "security_groups",
            "SF_ACCOUNT_ID": "sf_account_id",
            "SF_ACCOUNT_TYPE": "sf_account_type",
            "STATUS": "status",
            "SWAPTOTAL_MB": "swaptotal_mb",
            "SYSTEM_VENDOR": "system_vendor"
        },
        "TASK_ACTIVITY_STATUS": {
            "APP_DEPLOYMENT_FAILED": "APP_DEPLOYMENT_FAILED",
            "APP_DEPLOYMENT_REQUIRED": "APP_DEPLOYMENT_REQUIRED",
            "APP_DEPLOYMENT_RUNNING": "APP_DEPLOYMENT_RUNNING",
            "FW_UPGRADE_FAILED": "FW_UPGRADE_FAILED",
            "FW_UPGRADE_REQUIRED": "FW_UPGRADE_REQUIRED",
            "FW_UPGRADE_RUNNING": "FW_UPGRADE_RUNNING",
            "TASK_ACTIVITY_UNKNOWN": "TASK_ACTIVITY_UNKNOWN",
            "UP_TO_DATE": "UP_TO_DATE"
        }
    },
    "TERRAFORM": {
        "STATUS": {
            "TF_STATUS_HARD_DRIFT": "TF_STATUS_HARD_DRIFT",
            "TF_STATUS_PLAN_FAILED": "TF_STATUS_PLAN_FAILED",
            "TF_STATUS_SOFT_DRIFT": "TF_STATUS_SOFT_DRIFT",
            "TF_STATUS_TAG_DRIFT": "TF_STATUS_TAG_DRIFT",
            "TF_STATUS_UNKNOWN": "TF_STATUS_UNKNOWN",
            "TF_STATUS_UP_TO_DATE": "TF_STATUS_UP_TO_DATE"
        }
    }
};
