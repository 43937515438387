import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import consts from '../../../consts';
import langs from '../../../localization/langs';
import models from '../../../models';
import newTenantFormUtils from './newTenantFormUtils';

import {Form} from '../../../components/Base';

import * as Styled from './styles';

export default function useIndustry(cluster) {
  const [industry, setIndustry] = React.useState('');

  const isIndustryRequired = newTenantFormUtils.checkCluster(cluster, consts.envs.CLUSTER_PREFIXES.PROD);
  const isIndustryFilled = !isIndustryRequired || !!industry;
  const render = isIndustryRequired && (
    <Form.Field>
      <Form.FlexLabel>
        <span>{langs('TENANT_INDUSTRY_NAICS')}</span>
        <span>
          (
          <Styled.Link href="https://www.naics.com/naics-drilldown-table/" target="_blank" rel="noreferrer">
            {langs('DETAILS')}
          </Styled.Link>
          )
        </span>
      </Form.FlexLabel>
      <Select required value={industry} onChange={(e) => setIndustry(e.target.value)}>
        {models.staticData.TENANT.INDUSTRIES.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </Form.Field>
  );
  return [industry, setIndustry, isIndustryFilled, render];
}
