import React from 'react';

import hooks from '../../hooks';

import Button from './Button';

function withAsyncButton(Component) {
  function WithLoadingComponent({onClick, ...props}) {
    const [loading, setLoading] = React.useState(false);
    const getIsMounted = hooks.useIsMounted();

    if (onClick) {
      const newOnClick = async (event, data) => {
        setLoading(true);
        try {
          await onClick(event, data);
        } catch (error) {
          throw error;
        } finally {
          if (getIsMounted()) {
            setLoading(false);
          }
        }
      };

      if (loading) {
        return <Component loading {...props} />;
      }

      return <Component onClick={newOnClick} {...props} />;
    }

    return <Component {...props} />;
  }

  WithLoadingComponent.propTypes = Component.propTypes;

  return WithLoadingComponent;
}

export default withAsyncButton(Button);
