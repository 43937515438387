import _ from 'lodash';

import consts from '../../consts';
import models from '../../models';
import langs from '../../localization/langs';
import {SEARCH_TYPES} from '../../context/Search/utils';

const options = [
  {
    key: models.collector.fields.COLLECTOR_STATUS,
    options: _.map(models.collector.collectorStatusOptions, (key) => ({
      key,
      value: langs(key)
    })),
    supportedOperators: [consts.FILTER_OPERATORS.LIKE, consts.FILTER_OPERATORS.NOT_LIKE],
    title: langs('COLLECTOR_STATUS'),
    type: SEARCH_TYPES.CHIPS
  },
  {
    key: models.collector.fields.STAGE,
    options: _.map(models.collector.collectorStageOptions, (key) => ({
      key,
      value: langs(key)
    })),
    supportedOperators: [consts.FILTER_OPERATORS.LIKE, consts.FILTER_OPERATORS.NOT_LIKE],
    title: langs('STAGE'),
    type: SEARCH_TYPES.CHIPS
  },
  {
    key: models.collector.fields.TASK_ACTIVITY_STATUS,
    options: _.map(models.staticData.TENANT.TASK_ACTIVITY_STATUS, (key, label) => ({
      key: key.toString(),
      value: langs(label)
    })),
    supportedOperators: [consts.FILTER_OPERATORS.LIKE, consts.FILTER_OPERATORS.NOT_LIKE],
    title: langs('TASK_STATUS'),
    type: SEARCH_TYPES.TEXT
  }
];

export default options;
