import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../../context';
import langs from '../../../localization/langs';
import services from '../../../services';

import {Button} from '../../../components/Base';

import ServiceChart, {CHART_OPTIONS} from './parts/ServiceChart';
import ServiceCron from './parts/ServiceCron';
import ServiceDBInfo from './parts/ServiceDBInfo';
import ServiceRedisInfo from './parts/ServiceRedisInfo';
import ServiceDescription from './parts/ServiceDescription';
import ServiceVersionRange from './parts/ServiceVersionRange';
import ServiceName from './parts/ServiceName';
import ServiceOwner from './parts/ServiceOwner';
import ServiceStacks from './parts/ServiceStacks';

import * as Styled from './ServiceForm.styles';
import * as validations from './validations';

const CRON_EXPRESSION_CONFIG_KEY = 'jobSchedule';

function ServiceForm({serviceId, onSubmit}) {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [versionRange, setVersionRange] = React.useState('');
  const [versionRangeInitialValue, setVersionRangeInitialValue] = React.useState('');
  const [owner, setOwner] = React.useState('');
  const [chart, setChart] = React.useState(CHART_OPTIONS.Common);
  const [cron, setCron] = React.useState('');
  const [dbInfo, setDBInfo] = React.useState({});
  const [useRedis, setUseRedis] = React.useState(false);
  const [stacks, setStacks] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const notifications = context.Notifications.useNotifications();

  const isEditMode = Boolean(serviceId);

  React.useEffect(() => {
    if (_.isEmpty(serviceId)) {
      return;
    }
    getServiceInfo();
  }, []);

  async function getServiceInfo() {
    try {
      const [serviceInfo, stacksInfo, configInfo] = await Promise.all([
        services.services.services.getById(serviceId),
        services.services.services.getStacks(serviceId),
        services.services.configurationV2.get('DEPLOY', serviceId)
      ]);

      const {
        data: {name, owner, supportedVersionRange, description, chart}
      } = serviceInfo;
      const {
        data: {items: stacks}
      } = stacksInfo;
      const {
        data: {items: configs}
      } = configInfo;

      setName(name);
      setDescription(description);
      setVersionRange(supportedVersionRange);
      setVersionRangeInitialValue(supportedVersionRange);
      setOwner(owner);
      setStacks(stacks);
      setChart(chart);
      setCron(getCronExpressionValue(configs));
    } catch (err) {
      console.error(err.stack);
      notifications.error(langs('SERVICES_FAILED_TO_LOAD'));
    }
  }

  function onChangeRedisInfo({useRedis}) {
    setUseRedis(useRedis);
  }

  function onChangeChart(newChart) {
    setChart(newChart);
    if (newChart !== CHART_OPTIONS.Cron) {
      setCron('');
    }
  }

  function getCronExpressionValue(configs) {
    const cronExpressionKey = _.find(configs, {key: CRON_EXPRESSION_CONFIG_KEY});
    const cronExpressionValue = _.get(cronExpressionKey, 'value', '""');
    return JSON.parse(cronExpressionValue);
  }

  function isDisableSubmit() {
    const isNameValid = !_.isEmpty(name) && validations.validateServiceName(name);
    const isOwnerValid = !_.isEmpty(owner);
    const isDescriptionValid = !_.isEmpty(description) && validations.validateServiceDescription(description);
    const isVersionRangeValid = !_.isEmpty(versionRange);
    const isDBInfoValid =
      _.isEmpty(dbInfo) || _.every(dbInfo, (info) => !_.isEmpty(info)) || _.every(dbInfo, _.isEmpty);
    const isStacksValid = !_.isEmpty(stacks);
    const isChartValid = !_.isEmpty(chart);
    const isCroneValid =
      chart !== CHART_OPTIONS.Cron ||
      (chart === CHART_OPTIONS.Cron && !_.isEmpty(cron) && validations.validateCronExpressions(cron));

    if (isEditMode) {
      return (
        !isNameValid ||
        !isOwnerValid ||
        !isDescriptionValid ||
        !isVersionRangeValid ||
        !isStacksValid ||
        !isChartValid ||
        !isCroneValid
      );
    }
    return (
      !isNameValid ||
      !isOwnerValid ||
      !isDescriptionValid ||
      !isVersionRangeValid ||
      !isDBInfoValid ||
      !isStacksValid ||
      !isChartValid ||
      !isCroneValid
    );
  }

  async function onFormSubmit() {
    try {
      setIsLoading(true);
      await onSubmit(serviceId, name, owner, description, versionRange, dbInfo, useRedis, stacks, chart, cron);
    } catch (err) {
      console.error(err.stack);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Styled.Container>
      <Styled.Form onSubmit={onFormSubmit} isEditMode={isEditMode}>
        <ServiceName value={name} onChange={setName} isDisabled={isEditMode} />
        <ServiceOwner value={owner} onChange={setOwner} />
        <ServiceDescription value={description} onChange={setDescription} />
        <ServiceVersionRange value={versionRangeInitialValue} onChange={setVersionRange} />
        <ServiceChart value={chart} onChange={onChangeChart} />
        {chart === CHART_OPTIONS.Cron && <ServiceCron onChange={setCron} value={cron} />}
        {!isEditMode && <ServiceDBInfo value={dbInfo} onChange={setDBInfo} isEditMode={isEditMode} />}
        {!isEditMode && <ServiceRedisInfo value={{useRedis}} onChange={onChangeRedisInfo} isEditMode={isEditMode} />}
        <ServiceStacks value={stacks} onChange={setStacks} isEditMode={isEditMode} />
        <Styled.BottomBar>
          <Button disabled={isDisableSubmit()} loading={isLoading} type="submit">
            {isEditMode ? langs('BUTTON_SAVE') : langs('BUTTON_ADD')}
          </Button>
        </Styled.BottomBar>
      </Styled.Form>
    </Styled.Container>
  );
}

ServiceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  serviceId: PropTypes.string
};

export default ServiceForm;
