import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import consts from '../consts';
import hooks from '../hooks';

import TextSearchInput from './TextSearchInput';

function getFieldValuesAsIdAndNamePairs(valueIds, options) {
  if (!valueIds) {
    return [];
  }

  const optionsByIds = _.keyBy(options, 'id');
  return valueIds
    .split(',')
    .filter((valueId) => !_.isUndefined(optionsByIds[valueId]))
    .map((valueId) => ({id: valueId, name: _.get(optionsByIds, [valueId, 'name'])}));
}

export default function TextSearch({options, label, urlQueryKey, filterOperator, ...props}) {
  const [valueIds, setValueIds] = hooks.useSearchQueryFilter(urlQueryKey, filterOperator);
  const fieldValuePairs = getFieldValuesAsIdAndNamePairs(valueIds, options);

  function onSelectOption(selected) {
    const valueIdsArr = _.split(valueIds, ',');

    const selectedFilters = _.map(selected, 'id');
    const currentLikeFilters = _.filter(valueIdsArr, (filter) => _.startsWith(filter, '%'));
    setValueIds(_.concat(currentLikeFilters, selectedFilters));
  }

  return (
    <TextSearchInput
      multiple={true}
      options={options}
      value={fieldValuePairs}
      title={label}
      setValue={onSelectOption}
      {...props}
    />
  );
}

TextSearch.propTypes = {
  filterOperator: PropTypes.oneOf(_.values(consts.FILTER_OPERATORS)),
  label: PropTypes.string,
  options: PropTypes.array,
  urlQueryKey: PropTypes.string
};
