import _ from 'lodash';
import React from 'react';

import langs from '../../../localization/langs';

import AdvancedSearchTab from './AdvancedSearchTab';

import * as Styled from './AdvancedSearch.styles';

const TABS = {
  TAB_ADVANCED_SEARCH: {isDisabled: false, text: 'ADVANCED_SEARCH'},
  TAB_RECENT_SEARCH: {isDisabled: true, text: 'RECENT_SEARCH'},
  TAB_SAVED_SEARCH: {isDisabled: true, text: 'SAVED_SEARCH'}
};

function AdvancedSearch() {
  const [selectedTab, setSelectedTab] = React.useState(TABS.TAB_ADVANCED_SEARCH);

  function changeTab(tab) {
    if (tab.isDisabled) {
      return;
    }
    setSelectedTab(tab);
  }

  return (
    <Styled.Container>
      <Styled.NavBar>
        {_.map(TABS, (tab, key) => (
          <Styled.Tab
            key={key}
            isActive={tab.text === selectedTab.text}
            isDisabled={tab.isDisabled}
            onClick={() => changeTab(tab)}
          >
            {langs(tab.text)}
          </Styled.Tab>
        ))}
      </Styled.NavBar>
      <Styled.Content>{selectedTab.text === TABS.TAB_ADVANCED_SEARCH.text && <AdvancedSearchTab />}</Styled.Content>
    </Styled.Container>
  );
}

export default AdvancedSearch;
