import PropTypes from 'prop-types';
import React from 'react';

import {Grid, ListItem, ListItemText} from '@material-ui/core';

import langs from '../../../../localization/langs';

import FormConfigurationInput from './FormConfigurationInput';

import * as Styled from './NetworkConfigurationInputGroup.styles';

function NetworkConfigurationInputGroup({title, inputs, defaultOpen = false}) {
  const [open, setOpen] = React.useState(defaultOpen);

  function handleClick() {
    setOpen((prevState) => !prevState);
  }

  return (
    <>
      <ListItem button onClick={handleClick} disableGutters>
        <Styled.TitleTypography color="textPrimary" variant="h6">
          {langs(title)}
        </Styled.TitleTypography>
        {open ? <Styled.CollapseExpandLess /> : <Styled.CollapseExpandMore />}
      </ListItem>
      <Styled.AdditionalSettingsCollapse in={open} timeout="auto" unmountOnExit>
        {inputs.map((input) => (
          <Grid container>
            <Grid item xs={4}>
              <ListItemText primary={langs(input.title)} />
            </Grid>
            <Grid item xs={8}>
              <FormConfigurationInput onChange={input.onChange} type={input.type} value={input.value} />
              {input.validationFunc}
            </Grid>
          </Grid>
        ))}
      </Styled.AdditionalSettingsCollapse>
    </>
  );
}

NetworkConfigurationInputGroup.propTypes = {
  defaultOpen: PropTypes.bool,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      title: PropTypes.string.isRequired,
      validationFunc: PropTypes.func,
      value: PropTypes.func.isRequired
    }).isRequired
  ),
  title: PropTypes.string.isRequired
};

export default NetworkConfigurationInputGroup;
