import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../../localization/langs';

import SingleBarChart from './SingleBarChart';

import * as Styled from './GroupedBarCharts.styles';

function GroupedBarCharts({countApi, bars}) {
  return (
    <Styled.Container container spacing={3}>
      {bars.map((field, id) => (
        <Styled.Box key={id}>
          <Styled.Title>{langs(field.title)}</Styled.Title>
          <SingleBarChart countApi={countApi} field={field.field} translateLabels={field.translate} skipColors={id} />
        </Styled.Box>
      ))}
    </Styled.Container>
  );
}

GroupedBarCharts.propTypes = {
  bars: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      title: PropTypes.string,
      translate: PropTypes.bool
    })
  ).isRequired,
  countApi: PropTypes.func.isRequired
};

export default GroupedBarCharts;
