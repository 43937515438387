import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import hooks from '../../../../hooks';
import langs from '../../../../localization/langs';
import services from '../../../../services';

import {Form, Paper} from '../../../../components/Base';
import MultipleChoiceInput from '../../../../components/Base/inputs/MultipleChoiceInput';
import TextSearchInput from '../../../../components/TextSearchInput';

const CLONE_TENANT_OPTIONS = {
  TENANT_LIKE: langs('TENANT_LIKE'),
  TENANT_STEAM_DATA: langs('TENANT_STEAM_DATA'),
  TENANT_COPY_DATA: langs('TENANT_COPY_DATA') // eslint-disable-line
};
const CONFIGURATION_CATEGORY = 'TERRAFORM';
const TENANT_SIZE_CONFIGURATIONS = {
  DB_VOLUME_SIZE: 'db_volume_size',
  INSTANCE_TYPE: 'instance_type',
  RMQ_VOLUME_SIZE: 'rmq_volume_size'
};

function CloneTenant({setCloneData, setTenantConfiguration}) {
  const [clonedTenant, setClonedTenant] = React.useState(null);
  const [cloneOptionsSelection, setCloneOptionsSelection] = React.useState([]);
  const [isCloneTenant, setIsCloneTenant] = React.useState(false);
  const [tenantsResponse, isLoadingTenants] = hooks.useGetResult(
    () =>
      services.tenants.getByQuery({
        length: -1,
        searchQuery: 'environmentName=prod&status=AVAILABILITY_STATUS_ACTIVE'
      }),
    []
  );

  React.useEffect(() => {
    if (isCloneTenant && !_.isEmpty(clonedTenant)) {
      getConfiguration();
      setCloneData(getCloneData(cloneOptionsSelection, clonedTenant, CLONE_TENANT_OPTIONS));
    }
  }, [isCloneTenant, clonedTenant, cloneOptionsSelection]);

  const cloneOptionsWithTenantNames = _.map(CLONE_TENANT_OPTIONS, (option) => {
    if (option !== CLONE_TENANT_OPTIONS.TENANT_LIKE) {
      return `${option} ${_.get(clonedTenant, 'tenantName', '')}`;
    }
    return option;
  });

  function onChangeCloneOptions(selection) {
    const isCloneTenant = _.includes(selection, CLONE_TENANT_OPTIONS.TENANT_LIKE);

    setCloneOptionsSelection(selection);
    setIsCloneTenant(isCloneTenant);
    if (!isCloneTenant) {
      setClonedTenant(null);
    }
  }

  async function getConfiguration() {
    try {
      const envId = _.get(clonedTenant, 'envId');
      const tenantId = _.get(clonedTenant, 'tenantId');

      const response = await services.tenants.configuration.get(CONFIGURATION_CATEGORY, envId, tenantId);
      const items = _.get(response, 'data.items', []);
      const allConfig = _.map(items, (item) => _.defaults({value: JSON.parse(item.value)}, item));
      const instance_type = _.get(_.find(allConfig, {key: TENANT_SIZE_CONFIGURATIONS.INSTANCE_TYPE}), 'value');
      const db_volume_size = _.get(_.find(allConfig, {key: TENANT_SIZE_CONFIGURATIONS.DB_VOLUME_SIZE}), 'value');
      const rmq_volume_size = _.get(_.find(allConfig, {key: TENANT_SIZE_CONFIGURATIONS.RMQ_VOLUME_SIZE}), 'value');
      const tenantSizeConfigurations = [
        {key_name: TENANT_SIZE_CONFIGURATIONS.INSTANCE_TYPE, value: JSON.stringify(instance_type)},
        {key_name: TENANT_SIZE_CONFIGURATIONS.DB_VOLUME_SIZE, value: JSON.stringify(db_volume_size)},
        {key_name: TENANT_SIZE_CONFIGURATIONS.RMQ_VOLUME_SIZE, value: JSON.stringify(rmq_volume_size)}
      ];
      setTenantConfiguration(!_.isEmpty(clonedTenant) ? tenantSizeConfigurations : []);
    } catch (ex) {
      setTenantConfiguration([]);
    }
  }

  function getCloneData(cloneOptionsSelection, clonedTenant, options) {
    let cloneData = {};
    const copyDatabase = _.some(cloneOptionsSelection, (value) => _.includes(value, options.TENANT_COPY_DATA));
    const rmqRelay = _.some(cloneOptionsSelection, (value) => _.includes(value, options.TENANT_STEAM_DATA));

    if (copyDatabase) {
      _.assign(cloneData, {database: {source: _.get(clonedTenant, 'tenantName')}});
    }
    if (rmqRelay) {
      _.assign(cloneData, {rmq: {source: _.get(clonedTenant, 'tenantName')}});
    }
    return _.isEmpty(cloneData) ? undefined : cloneData;
  }

  const tenants = _.sortBy(tenantsResponse?.items, 'tenantName');

  return (
    <Paper padding={2} style={{paddingBlock: '1em'}}>
      <Form.Label>{langs('TENANT_CLONE_EXISTING_TENANT')}</Form.Label>
      <Form.ColumnsField>
        <MultipleChoiceInput
          disabled={clonedTenant ? [] : cloneOptionsWithTenantNames.slice(1)}
          onChange={(value) => onChangeCloneOptions(value)}
          options={cloneOptionsWithTenantNames}
          value={cloneOptionsSelection}
        />

        <Form.RightColumnField>
          {isCloneTenant && (
            <TextSearchInput
              loading={isLoadingTenants}
              multiple={false}
              options={tenants || []}
              value={clonedTenant}
              disabled={!isCloneTenant}
              getOptionLabel={({tenantName}) => `${tenantName}`}
              inputPlaceholder={langs('CHOOSE_TENANT')}
              setValue={(value) => {
                setCloneOptionsSelection([CLONE_TENANT_OPTIONS.TENANT_LIKE]);
                setTenantConfiguration([]);
                setClonedTenant(value);
              }}
            />
          )}
        </Form.RightColumnField>
      </Form.ColumnsField>
    </Paper>
  );
}

CloneTenant.propTypes = {
  setCloneData: PropTypes.func.isRequired,
  setTenantConfiguration: PropTypes.func.isRequired
};

export default CloneTenant;
