import styled from 'styled-components';

import MuiButton from '@material-ui/core/Button';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import {withStyles} from '@material-ui/core/styles';

import * as theme from '../../../../theme';

const TAB_HEIGHT = 32;

export const Log = styled.pre`
  color: ${({level}) =>
    level === 'INFO'
      ? theme.terminalColors.green
      : level === 'WARNING' || level === 'WAR'
      ? theme.terminalColors.yellow
      : level === 'ERROR'
      ? theme.terminalColors.red
      : theme.terminalColors.cyan};
  line-height: 1;
  margin: 6px 0;
`;

export const Button = styled(MuiButton).attrs({variant: 'outlined'})`
  width: 260px;
`;

export const FormContainer = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 5px;
`;

export const LogsContainer = styled.div`
  height: 600px;
  overflow-y: auto;
`;

export const Tabs = withStyles(() => ({
  indicator: {
    display: 'none'
  },
  root: {
    borderBottom: `1px solid ${theme.backgrounds.primary}`,
    marginBottom: '10px',
    marginTop: '20px',
    minHeight: 'auto',
    overflowY: 'auto',
    width: '100%'
  }
}))(MuiTabs);

export const Tab = withStyles((muiTheme) => ({
  root: {
    '&$selected': {
      backgroundColor: theme.backgrounds.primary,
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      color: muiTheme.palette.secondary.main
    },
    '&:hover': {
      color: muiTheme.palette.secondary.main
    },
    '&:last-child': {
      borderRight: '0'
    },
    TAB_HEIGHT,
    lineHeight: 1,
    minHeight: TAB_HEIGHT,
    textAlign: 'left'
  },
  selected: {},
  wrapper: {
    alignItems: 'start'
  }
}))((props) => <MuiTab disableRipple {...props} />);
