import _ from 'lodash';

function getSearchQueryOrderBy(sortModel, defaultSortKey = 'lastSeen:desc') {
  if (_.isEmpty(sortModel)) {
    return defaultSortKey;
  }
  return _.map(sortModel, ({colId, sort}) => `${colId}:${sort}`).join(',');
}

export default {
  getSearchQueryOrderBy
};
