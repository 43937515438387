import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import DoneAll from '@material-ui/icons/DoneAll';

import langs from '../../../localization/langs';

import * as Styled from './CurrentSelection.styled';

function CurrentSelection({isLoading, pageSize, selectAll, selected, total}) {
  const size = _.size(selected);
  return (
    <>
      <Styled.Text>{isLoading ? langs('LOADING') : `${size} ${langs('SELECTED')}`}</Styled.Text>
      {total > pageSize && total !== size && (
        <Styled.SelectAll onClick={selectAll} endIcon={<DoneAll />}>
          {langs('SELECT_ALL_WITH_TOTAL', {total})}
        </Styled.SelectAll>
      )}
    </>
  );
}

CurrentSelection.propTypes = {
  isLoading: PropTypes.bool,
  pageSize: PropTypes.number.isRequired,
  selectAll: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired
};

export default CurrentSelection;
