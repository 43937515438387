import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import DestroyMenuItem from './DestroyMenuItem';
import MonitoringMenuItem from './MonitoringMenuItem';
import StopMenuItem from './StopMenuItem';

import context from '../../../context';
import roles from '../../../roles';

function OptionsMenu({tenantId, tenantName, isMonitored, branch, onChange}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = !_.isNil(anchorEl);

  const user = context.User.useUser();
  const hasManagePermissions = user.hasRole(roles.MANAGE_TENANTS) || user.rbac.maestro.isEnabled();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '22ch'
          }
        }}
      >
        <div>
          <MonitoringMenuItem tenantId={tenantId} onChange={onChange} isMonitored={isMonitored} />
          {hasManagePermissions && (
            <>
              <StopMenuItem tenantId={tenantId} onChange={onChange} />
              <DestroyMenuItem tenantId={tenantId} tenantName={tenantName} branch={branch} />
            </>
          )}
        </div>
      </Menu>
    </div>
  );
}

OptionsMenu.propTypes = {
  branch: PropTypes.string,
  isMonitored: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  tenantId: PropTypes.string.isRequired,
  tenantName: PropTypes.string.isRequired
};

export default OptionsMenu;
