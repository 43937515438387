import styled, {css} from 'styled-components';

import {Grid} from '@material-ui/core';

import * as theme from '../../../../theme';

export const Container = styled(Grid)`
  line-height: 1;
  justify-content: space-evenly;
`;

export const Title = styled.div`
  color: ${theme.default.palette.text.primary};
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
`;

const boxBorderHandler = () => css`
  @media only screen and (max-width: 1280px) {
    :nth-child(2) {
      border-right: none;
    }
  }

  @media only screen and (max-width: 600px) {
    :first-child {
      border-right: none;
    }
    :nth-child(3) {
      border-right: none;
    }
  }
`;

const boxBreakpoints = {
  'adjust-border': 'true',
  lg: 3,
  md: 6,
  sm: 6,
  xs: 12
};

export const Box = styled(Grid).attrs({...boxBreakpoints, item: true})`
  border-right: 1px solid ${theme.separators.light};

  :last-child {
    border-right: none;
  }

  ${(props) => props['adjust-border'] && boxBorderHandler()};
`;
