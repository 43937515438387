import styled from 'styled-components';

import TaskButton from '../../components/Tasks/TaskCommands/Parts/TaskButton';

export const DisplayModeButtons = styled.div`
  padding: 5px;
  display: flex;
  justify-content: flex-end;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PageTitle = styled.h2`
  text-align: center;
`;

export const Chip = styled.div`
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LogsButton = styled(TaskButton)`
  padding: 0;
`;
