import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './ColoredColumn.styles';

function ColoredColumn({value, colors, render}) {
  const displayValue = _.startCase(_.isFunction(render) ? render(value) : value);
  return (
    <Styled.Container colors={colors} value={value}>
      {displayValue}
    </Styled.Container>
  );
}

ColoredColumn.propTypes = {
  colors: PropTypes.object.isRequired,
  render: PropTypes.func,
  value: PropTypes.string.isRequired
};

export default ColoredColumn;
