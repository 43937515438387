import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import columnDefs from '../../../utils/agGridUtils/columnDefs';
import langs from '../../../localization/langs';
import models from '../../../models';
import tableUtils from './table.utils';

import Column from '../../AGTable/Column';
import CollectorNumberAg from './Parts/Columns/CollectorNumberAg';
import IntegrationsList from './Parts/Columns/IntegrationsList';
import AGTable from '../../AGTable/Table';

function TenantCollectorsTable({getRows}) {
  const asyncFilterProps = {...columnDefs.BASIC_COL, filter: tableUtils.fetchFilterOptions};

  return (
    <AGTable data={getRows} pagination title={langs('COLLECTORS')} filterModel={{}}>
      <Column
        {...columnDefs.NUMBER_COL}
        colId={models.collector.fields.COLLECTOR_NUMBER}
        exportFunc={({collectorNumber}) => collectorNumber}
        title={langs('NUMBER')}
        render={CollectorNumberAg}
        tooltip={(data) => langs('COLLECTOR_ID', data)}
        value={_.identity}
      />
      <Column
        {...columnDefs.ENUM_COL}
        filter={_.values(models.staticData.COLLECTOR.TASK_ACTIVITY_STATUS)}
        title={langs('STATUS')}
        value={models.collector.fields.TASK_ACTIVITY_STATUS}
      />
      <Column
        {...columnDefs.ENUM_COL}
        hide
        filter={_.values(models.staticData.COLLECTOR.AVAILABILITY_STATUS)}
        title={langs('COLLECTOR_STATUS')}
        value={models.collector.fields.COLLECTOR_STATUS}
      />
      <Column
        {...columnDefs.ENUM_COL}
        filter={_.values(models.staticData.COLLECTOR.STAGE)}
        title={langs('STAGE')}
        value={models.collector.fields.STAGE}
      />
      <Column
        {...asyncFilterProps}
        colId="applicationVersion"
        title={langs('APP_VERSION')}
        value={models.collector.fields.FACTS_APPLICATION_VERSION}
      />
      <Column
        {...columnDefs.BASIC_COL}
        hide
        colId="integrations"
        render={IntegrationsList}
        title={langs('INTEGRATIONS')}
        value={_.identity}
      />
    </AGTable>
  );
}

TenantCollectorsTable.propTypes = {
  getRows: PropTypes.func.isRequired
};

export default TenantCollectorsTable;
