import React from 'react';

function useGetResult(getRequest, dependencies = [], initialValue = null) {
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [result, setResult] = React.useState(initialValue);

  const reloadData = async () => {
    setIsLoading(true);
    try {
      const result = await getRequest();
      setResult(result.data);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    reloadData();
  }, dependencies);

  return [result, isLoading, error, reloadData];
}

export default useGetResult;
