import React from 'react';

import AddIcon from '@material-ui/icons/Add';

import columnDefs from '../../../utils/agGridUtils/columnDefs';
import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import models from '../../../models';
import tableUtils from '../table.utils';
import useUrlSearch from '../../../components/AGTable/hooks/useUrlSearch';

import AGTable from '../../../components/AGTable/Table';
import Column from '../../../components/AGTable/Column';
import OpsLink from '../../../components/OpsLink';
import {Button} from '../../../components/Base';
import ProtectedComponent from '../../../components/Rbac/ProtectedComponent';
import ServicePreview from '../../../components/Services/ServicePreview';

import * as Styled from '../Services/ServicesView.styles';

const SET_FILTER_COL = {
  ...columnDefs.BASIC_COL,
  filter: async ({colDef, success}) => success(await tableUtils.getServicesFilters(colDef)),
  filterParams: {refreshValuesOnOpen: false}
};

async function getRows(params) {
  params.success(await tableUtils.getServices(params.api, params.request));
}

function ServicesView() {
  const [tableApi, setTableApi] = React.useState();
  const [servicePreview, setServicePreview] = React.useState();

  const [filterModel, onFilterChanged] = useUrlSearch(tableApi);

  function closePreview() {
    setServicePreview(null);
  }

  const user = context.User.useUser();

  return (
    <div>
      <Styled.Drawer open={Boolean(servicePreview)} onClose={closePreview}>
        <ProtectedComponent permissions={[consts.rbac.permissions.mtms.services.edit]} rbacControlObj={user.rbac.mtms}>
          <ServicePreview serviceId={servicePreview?.id} onClose={closePreview} />
        </ProtectedComponent>
      </Styled.Drawer>
      <Styled.ActionContainer>
        <OpsLink to="services.servicesAdd">
          <Button text icon={<AddIcon />}>
            {langs('SERVICES_NEW_SERVICE')}
          </Button>
        </OpsLink>
      </Styled.ActionContainer>
      <Styled.StyledPaper>
        <AGTable
          data={getRows}
          filterModel={filterModel}
          onFilterChanged={onFilterChanged}
          onRowClicked={({data}) => user.rbac.mtms.allowed.stacks.edit() && setServicePreview(data)}
          pagination
          setTableApi={setTableApi}
          title={langs('SERVICES')}
        >
          <Column
            {...columnDefs.NUMBER_COL}
            colId="id"
            title={langs('SERVICES_FIELD_ID')}
            value={models.services.fields.ID}
          />
          <Column
            {...SET_FILTER_COL}
            colId="name"
            title={langs('SERVICES_FIELD_NAME')}
            value={models.services.fields.NAME}
          />
          <Column
            {...SET_FILTER_COL}
            colId="owner"
            title={langs('SERVICES_FIELD_OWNER')}
            value={models.services.fields.OWNER}
          />
          <Column
            {...SET_FILTER_COL}
            colId="description"
            title={langs('SERVICES_FIELD_DESCRIPTION')}
            value={models.services.fields.DESCRIPTION}
          />
          <Column
            {...SET_FILTER_COL}
            colId="supportedVersionRange"
            title={langs('SERVICES_FIELD_VERSION_RANGE')}
            value={models.services.fields.SUPPORTED_VERSION_RANGE}
          />
          <Column
            {...SET_FILTER_COL}
            colId="chart"
            title={langs('SERVICES_FIELD_CHART')}
            value={models.services.fields.CHART}
          />
        </AGTable>
      </Styled.StyledPaper>
    </div>
  );
}

export default ServicesView;
