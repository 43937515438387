import collectors from './collectors';
import rbac from './rbac';
import releases from './releases';
import seedService from './seedService';
import services from './services';
import slackbot from './slackbot';

import * as tasks from './tasks';
import tenants from './tenants';

export default {
  collectors,
  rbac,
  releases,
  seedService,
  services,
  slackbot,
  tasks,
  tenants
};
