import _ from 'lodash';
import React from 'react';

import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import services from '../../../services';
import task from '../../../models/task';
import useNewTask from '../../../components/NewTaskWidget/useNewTask';

import DeployTenantForm from '../../../components/Table/DeployTenantForm';
import RmqRelaySelector from '../../../components/NewTaskWidget/Parts/RmqRelaySelector';
import SelectCommand from '../../../components/NewTaskWidget/SelectCommand';
import SelectVersion from '../../../components/NewTaskWidget/SelectVersion';
import TerraformForm from '../../../components/Table/TerraformForm';

const COMMANDS = {
  APP_DEPLOYMENT_COMMAND: 'App Deploy',
  APPLY_CONFIGURATION_COMMAND: 'Apply Tenant Configuration',
  RMQ_RELAY: 'start_relay_on_tenant',
  TERRAFORM_APPLY: 'apply',
  TERRAFORM_PLAN: 'plan'
};

const BASE_TASK = {
  apiPrefix: consts.API_PREFIX_BY_SERVICE_NAME[consts.SERVICE_NAMES.TENANTS],
  tasksPage: 'tenants.tasks'
};
const TASKS = {
  APP_DEPLOYMENT: {
    ...BASE_TASK,
    icon: 'outline_play_arrow_white_24dp',
    name: langs('DEPLOY_TENANT'),
    permissions: consts.rbac.permissions.maestro.tenant.task.runElevated.high,
    selectionComponent: (props) => <DeployTenantForm appDeployCommand={COMMANDS.APP_DEPLOYMENT_COMMAND} {...props} />,
    type: task.taskType.APP_DEPLOYMENT
  },
  APP_DEPLOYMENT_CONFIGURATION: {
    ...BASE_TASK,
    icon: 'baseline_display_settings_white_24dp',
    name: langs('APPLY_CONFIGURATION'),
    permissions: consts.rbac.permissions.maestro.tenant.task.runElevated.high,
    selectionComponent: (props) => (
      <DeployTenantForm appDeployCommand={COMMANDS.APPLY_CONFIGURATION_COMMAND} {...props} />
    ),
    type: task.taskType.APP_DEPLOYMENT
  },
  DEPLOY_COLLECTORS: {
    apiPrefix: consts.API_PREFIX_BY_SERVICE_NAME[consts.SERVICE_NAMES.COLLECTORS],
    hostIdsField: 'tenantIds',
    icon: 'outline_router_white_24dp',
    name: langs('DEPLOY_COLLECTORS'),
    permissions: consts.rbac.permissions.maestro.tenant.task.runElevated.high,
    selectionComponent: (props) => (
      <SelectVersion
        getArtifactByBranchApi={services.collectors.getArtifactByBranch}
        listArtifactsApi={services.collectors.listArtifacts}
        setParametersValues={props.setParametersValues}
        setValue={(value) => props.setConfigurationValue({keyName: 'app_deployment_version_id', value})}
        onClick={(value) =>
          props.setParametersValues((prevState) => ({
            ...prevState,
            branch: _.get(value, 'branch')
          }))
        }
        {...props}
      />
    ),
    tasksPage: 'collectors.tasks',
    type: task.taskType.APP_DEPLOYMENT
  },
  FIRMWARE_UPGRADE: {
    ...BASE_TASK,
    icon: 'Ansible-Mark-Large-RGB-WhiteOutline',
    name: langs('FIRMWARE_UPGRADE'),
    permissions: consts.rbac.permissions.maestro.tenant.task.runElevated.high,
    type: task.taskType.FIRMWARE_UPGRADE
  },
  RMQ_RELAY: {
    ...BASE_TASK,
    icon: 'baseline_waves_white_24dp',
    name: langs('RMQ_RELAY'),
    permissions: consts.rbac.permissions.maestro.tenant.task.runElevated.low,
    selectionComponent: (props) => <RmqRelaySelector rmqRelayCommand={COMMANDS.RMQ_RELAY} {...props} />,
    type: task.taskType.WORKER_ANSIBLE
  },
  SHELL_COMMAND: {
    ...BASE_TASK,
    icon: 'outline_code_white_24dp',
    name: langs('SHELL_COMMAND'),
    permissions: consts.rbac.permissions.maestro.tenant.task.runShellCommand,
    selectionComponent: (props) => (
      <SelectCommand
        createShellCommandApi={services.tenants.createShellCommand}
        archiveShellCommandApi={services.tenants.archiveShellCommand}
        getShellCommands={services.tenants.getShellCommands}
        {...props}
      />
    ),
    type: task.taskType.SHELL_COMMAND
  },
  TERRAFORM_APPLY: {
    ...BASE_TASK,
    icon: 'Terraform_VerticalLogo_White_RGB',
    name: langs('TERRAFORM_APPLY'),
    permissions: consts.rbac.permissions.maestro.tenant.task.runElevated.low,
    selectionComponent: (props) => <TerraformForm terraformCommand={COMMANDS.TERRAFORM_APPLY} {...props} />,
    type: task.taskType.TERRAFORM
  },
  TERRAFORM_PLAN: {
    ...BASE_TASK,
    icon: 'Terraform_VerticalLogo_White_RGB',
    name: langs('TERRAFORM_PLAN'),
    permissions: consts.rbac.permissions.maestro.tenant.task.runElevated.low,
    selectionComponent: (props) => <TerraformForm terraformCommand={COMMANDS.TERRAFORM_PLAN} {...props} />,
    type: task.taskType.TERRAFORM
  }
};

function useNewTenantTask(task, tenants, getSelectionComponentProps) {
  return useNewTask(task, tenants, 'tenantId', getSelectionComponentProps);
}

function getProps(hosts) {
  const versions = _.transform(hosts, (res, {applicationVersion}) => res.add(applicationVersion), new Set());
  return _.size(versions) > 1 ? {} : {currentVersion: _.toArray(versions)[0]};
}

export default function useNewTaskActions(tenants) {
  const nameSuffix = _.isEmpty(tenants) ? '' : ` (${_.size(tenants).toLocaleString()})`;

  const [appDeploymentGetDef, appDeploymentDialog] = useNewTenantTask(TASKS.APP_DEPLOYMENT, tenants, getProps);
  const [deployCollectorsGetDef, deployCollectorsDialog] = useNewTenantTask(TASKS.DEPLOY_COLLECTORS, tenants, getProps);
  const [firmwareUpgradeGetDef, firmwareUpgradeDialog] = useNewTenantTask(TASKS.FIRMWARE_UPGRADE, tenants);
  const [rmqRelayGetDef, rmqRelayDialog] = useNewTenantTask(TASKS.RMQ_RELAY, tenants);
  const [shellCommandGetDef, shellCommandDialog] = useNewTenantTask(TASKS.SHELL_COMMAND, tenants);
  const [terraformApplyGetDef, terraformApplyDialog] = useNewTenantTask(TASKS.TERRAFORM_APPLY, tenants);
  const [terraformPlanGetDef, terraformPlanDialog] = useNewTenantTask(TASKS.TERRAFORM_PLAN, tenants);
  const [appDeploymentConfigGetDef, appDeploymentConfigDialog] = useNewTenantTask(
    TASKS.APP_DEPLOYMENT_CONFIGURATION,
    tenants,
    getProps
  );

  const dialogs = (
    <>
      {appDeploymentDialog}
      {appDeploymentConfigDialog}
      {deployCollectorsDialog}
      {firmwareUpgradeDialog}
      {shellCommandDialog}
      {terraformApplyDialog}
      {terraformPlanDialog}
      {rmqRelayDialog}
    </>
  );

  const user = context.User.useUser();

  function getDef(tenant) {
    return {
      icon: 'outline_play_arrow_white_24dp',
      name: `${langs('NEW_TASK')}${nameSuffix}`,
      subMenu: [
        appDeploymentGetDef(tenant, user.rbac.maestro),
        appDeploymentConfigGetDef(tenant, user.rbac.maestro),
        shellCommandGetDef(tenant, user.rbac.maestro),
        firmwareUpgradeGetDef(tenant, user.rbac.maestro),
        terraformPlanGetDef(tenant, user.rbac.maestro),
        terraformApplyGetDef(tenant, user.rbac.maestro),
        deployCollectorsGetDef(tenant, user.rbac.maestro),
        rmqRelayGetDef(tenant, user.rbac.maestro)
      ]
    };
  }

  return [getDef, dialogs];
}
