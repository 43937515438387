import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import IconButtonStyled from './IconButton.styles';

function IconButton({disabled, icon, onClick, title}) {
  return (
    <Tooltip title={title}>
      <IconButtonStyled component={icon} onClick={onClick} disabled={disabled} />
    </Tooltip>
  );
}

IconButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default IconButton;
