import styled from 'styled-components';

import MuiComputerIcon from '@material-ui/icons/Computer';
import MuiPersonIcon from '@material-ui/icons/Person';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiPauseIcon from '@material-ui/icons/Pause';
import MuiPlayArrowIcon from '@material-ui/icons/PlayArrow';
import {Grid, Typography} from '@material-ui/core';

import * as theme from '../../../theme';

export const ActionGrid = styled(Grid)`
  text-align: center;
`;

export const CommandTypeTitle = styled(Typography).attrs({color: 'textPrimary', component: 'span'})``;

export const Container = styled.div`
  background-color: ${({isSelected}) => (isSelected ? theme.backgrounds.primary : theme.backgrounds.lighter)};
  cursor: pointer;
  margin: 18px 0;
  border-radius: 0;
`;

export const InnerContainer = styled(Grid).attrs({container: true})`
  padding: 9px 15px;
`;

export const Title = styled(Typography).attrs({color: 'textSecondary', component: 'div', variant: 'body2'})`
  font-weight: 300;
  margin-bottom: 5px;
`;

export const ComputerIcon = styled(MuiComputerIcon)`
  font-size: 18px;
  margin-right: 6px;
  vertical-align: top;
`;

export const PersonIcon = styled(MuiPersonIcon)`
  font-size: 18px;
  margin-right: 6px;
  vertical-align: text-bottom;
`;

export const IconButton = styled(MuiIconButton).attrs({size: 'medium'})`
  background-color: ${theme.backgrounds.primary};
  margin-top: 5px;
`;

export const PauseIcon = styled(MuiPauseIcon)`
  font-size: 16px;
`;

export const PlayArrowIcon = styled(MuiPlayArrowIcon)`
  font-size: 16px;
`;

export const TaskStatus = styled(Typography).attrs({color: 'textPrimary', component: 'span', variant: 'body2'})``;
