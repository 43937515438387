import _ from 'lodash';
import React from 'react';
import {useHistory} from 'react-router-dom';

import context from '../../../context';
import consts from '../../../consts';
import langs from '../../../localization/langs';
import services from '../../../services';

import {Breadcrumb} from '../../../components/Base';
import ProtectedView from '../../../components/Rbac/ProtectedView';
import ServiceForm from '../../../components/Services/ServiceForm/ServiceForm';

import * as Styled from './ServiceAdd.styles';

const breadcrumb = [{title: langs('MULTI_TENANT'), url: '/multitenant'}, langs('ADD_NEW_SERVICE')];

function ServiceAdd() {
  const history = useHistory();
  const {getUrl} = context.Navigation.useNavigation();
  const notifications = context.Notifications.useNotifications();
  const user = context.User.useUser();

  async function createNewService(id, name, owner, description, versionRange, dbInfo, useRedis, stacks, chart, cron) {
    try {
      const stackIds = _.map(stacks, 'id');
      const {
        data: {id: newServiceId}
      } = await services.services.services.create(
        name,
        owner,
        description,
        versionRange,
        dbInfo,
        useRedis,
        stackIds,
        chart,
        cron
      );
      history.push(getUrl('services.services', null, {newServiceId}));
    } catch (err) {
      console.error(err.stack);
      notifications.error(langs('SERVICES_FAILED_TO_CREATE'));
    }
  }

  return (
    <Styled.Container>
      <ProtectedView permissions={[consts.rbac.permissions.mtms.services.create]} rbacControlObj={user.rbac.mtms}>
        <Styled.FormContainer>
          <Breadcrumb sections={breadcrumb} withBorder={false} />
          <ServiceForm onSubmit={createNewService} />
        </Styled.FormContainer>
      </ProtectedView>
    </Styled.Container>
  );
}

export default ServiceAdd;
