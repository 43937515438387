import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';

import consts from '../../consts';

import Logo from './Parts/Logo';

import * as theme from '../../theme';

const MAIN_GRID_TOP_PADDING = 30;
const TOP_PANEL_HEIGHT = 42;

export const Container = styled.div`
  height: 100vh;
`;

export const TopPanel = styled.div`
  height: ${TOP_PANEL_HEIGHT}px;
  background: ${theme.backgrounds.logo};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: relative;
`;

export const StyledLogo = styled(Logo)`
  margin-left: 25px;
`;

export const UserInfo = styled.div`
  width: 100px;
`;

export const SidePanelAndMain = styled.div`
  display: flex;
  height: calc(100vh - ${TOP_PANEL_HEIGHT}px);
`;

export const SidePanel = styled.div`
  height: 100%;
  width: ${consts.SIDE_PANEL_WIDTH}px;
  background: ${theme.backgrounds.primary};
`;

export const MaxWidthContainer = styled.div`
  height: 100%;
  width: calc(100% - ${consts.SIDE_PANEL_WIDTH}px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Main = styled.div`
  width: 100%;
  max-width: 2800px;
  height: 100%;
  overflow-x: auto;
`;

export const MainGrid = styled(Grid).attrs({container: true, justifyContent: 'center'})`
  height: 100%;
  overflow: auto;
  padding: ${MAIN_GRID_TOP_PADDING}px;
`;

export const MainGridItem = styled(Grid).attrs({container: true, item: true, xs: 12})``;

export const MainView = styled.div`
  width: 100%;
`;
