import PropTypes from 'prop-types';
import React from 'react';

import context from '../../../context';
import langs from '../../../localization/langs';
import services from '../../../services';

import * as Styled from './RmqRelayInfo.styles';

function RmqRelayInfo({relayInfo, tenantId, setRelayInfo, closeModal}) {
  const notifications = context.Notifications.useNotifications();

  async function stop(isCreateNew) {
    try {
      await services.tenants.stopTenantRmqRelay(tenantId);
      notifications.success(langs('TENANT_RELAY_STOPPED'));
      if (isCreateNew) {
        setRelayInfo(null);
      } else {
        closeModal();
      }
    } catch (error) {
      notifications.error(error.message);
      console.error(error.message);
    }
  }

  return (
    <Styled.Container $flat={false}>
      <Styled.Title>{langs('RMQ_RELAY_INFO')}</Styled.Title>
      <Styled.Info>{langs('RMQ_RELAY_FROM', {relayInfo})}</Styled.Info>
      <Styled.StopButton onClick={() => stop(false)}>{langs('STOP_RMQ_RELAY')}</Styled.StopButton>
      <Styled.StopButton onClick={() => stop(true)}>{langs('STOP_AND_CREATE_RMQ_RELAY')}</Styled.StopButton>
    </Styled.Container>
  );
}

RmqRelayInfo.propTypes = {
  closeModal: PropTypes.func.isRequired,
  relayInfo: PropTypes.string.isRequired,
  setRelayInfo: PropTypes.func.isRequired,
  tenantId: PropTypes.string.isRequired
};

export default RmqRelayInfo;
