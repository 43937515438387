import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './Status.styles';

function Status({value: {status}}) {
  return (
    <Styled.Container status={status}>
      <div>{_.startCase(status)}</div>
    </Styled.Container>
  );
}

Status.propTypes = {
  value: PropTypes.shape({
    status: PropTypes.string
  }).isRequired
};

export default Status;
