export const taskStatuses = {
  CANCELED: 'CANCELED',
  FAILED: 'FAILED',
  PAUSED: 'PAUSED',
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  SUCCESS: 'SUCCESS'
};

export const completedTaskStatuses = [taskStatuses.FAILED, taskStatuses.SUCCESS, taskStatuses.CANCELED];

export const collectorCommandStatuses = {
  FAILED: 'FAILED',
  PARENT_FAILED: 'PARENT_FAILED',
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  SUCCESS: 'SUCCESS',
  WAITING_FOR_PARENT: 'WAITING_FOR_PARENT'
};

export const collectorLifecycleStage = {
  DECOMMISSIONED: 'DECOMMISSIONED',
  OPERATIONAL: 'OPERATIONAL',
  PRE_PROVISIONED: 'PRE_PROVISIONED',
  PROVISIONED: 'PROVISIONED'
};
