import _ from 'lodash';
import React from 'react';

import context from '../context';
import langs from '../localization/langs';
import models from '../models';
import queryUtils from '../utils/agGridUtils/queryUtils';
import services from '../services';

import Table from '../components/Configuration/TableView/Table';

const ORDER_BY_DIRECTION = 'desc';
const ORDER_BY_FIELD = models.configuration.FIELDS.CREATION_DATE;
const ORDER_BY = `${ORDER_BY_FIELD}:${ORDER_BY_DIRECTION}`;

function TenantsTableConfiguration() {
  const notifications = context.Notifications.useNotifications();

  const getRows = React.useCallback(async (params) => {
    if (_.isEmpty(params.request.filterModel[models.configuration.FIELDS.KEY])) {
      notifications.error(langs('CONFIGURATION_SEARCH_NO_KEY_ERROR'));
      return params.success({rowCount: 0, rowData: []});
    }

    const query = queryUtils.getQueryParams(params.api, params.request, null, ORDER_BY);
    const response = await services.tenants.configuration.search(query);
    params.success(queryUtils.parseResponse(response));
  }, []);

  return <Table getRows={getRows} columns={['environmentName', 'tenantName', 'tenantId']} />;
}

export default TenantsTableConfiguration;
