import langs from '../../../localization/langs';
import models from '../../../models';

const DEPLOYMENT_TYPES = {
  COLLECTORS: {
    checked: false,
    displayName: langs('DEPLOYMENT_TYPE_COLLECTORS'),
    value: models.releases.deploymentTypes.COLLECTORS
  },
  MULTI_TENANT: {
    checked: false,
    displayName: langs('DEPLOYMENT_TYPE_MULTI_TENANT'),
    value: models.releases.deploymentTypes.MULTI_TENANT
  },
  SINGLE_TENANT: {
    checked: false,
    displayName: langs('DEPLOYMENT_TYPE_SINGLE_TENANT'),
    value: models.releases.deploymentTypes.SINGLE_TENANT
  }
};

export {DEPLOYMENT_TYPES};
