import React from 'react';

import assets from '../../../assets';
import langs from '../../../localization/langs';

import * as Styled from './InitializingOverlay.styles';

function InitializingOverlay() {
  return (
    <Styled.Container>
      <Styled.InternalContainer>
        <Styled.Logo src={assets.ArmisLogo} alt="Armis" />
        <Styled.Title>OPS</Styled.Title>
      </Styled.InternalContainer>
      <Styled.InternalContainer>
        <Styled.Message>{langs('OPS_INITIALIZING')}</Styled.Message>
        <Styled.Dots />
      </Styled.InternalContainer>
    </Styled.Container>
  );
}

export default InitializingOverlay;
