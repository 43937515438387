import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './DynamicInput.styles';

const SEPARATOR = '\n';

function ArrayOfStringsInput({disabled, onChange, value}) {
  return (
    <Styled.Input
      disabled={disabled}
      multiline
      minRows={_.size(value)}
      value={_.join(value, SEPARATOR)}
      onChange={({target}) => onChange(_.split(target.value, SEPARATOR))}
    />
  );
}

ArrayOfStringsInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired
};

export default ArrayOfStringsInput;
