import _ from 'lodash';
import React from 'react';

import actionsUtils from './actionsUtils';
import consts from '../../../../consts';
import context from '../../../../context';
import langs from '../../../../localization/langs';
import task from '../../../../models/task';
import useNewTask from '../../../../components/NewTaskWidget/useNewTask';

import HelmForm from '../../../../components/Table/HelmForm';
import ServiceReplicateAction from './ServiceReplicateAction';
import AffectedEntities from '../../../../components/Configuration/parts/AffectedEntities';

const BASE_TASK = {
  apiPrefix: consts.API_PREFIX_BY_SERVICE_NAME[consts.SERVICE_NAMES.DEPLOYMENTS],
  infoComponent: ({hosts}) => (
    <AffectedEntities
      value={_.map(hosts, (host) => _.pick(host, ['environmentName', 'namespaceName', 'stackName', 'serviceName']))}
    />
  ),
  modifyHosts: (selectedHosts) => actionsUtils.fetchServices(selectedHosts),
  tasksPage: 'services.tasks'
};

const COMMANDS = {
  ROLLBACK: 'rollback',
  TEMPLATE: 'template',
  UPGRADE: 'upgrade'
};

const TASKS = {
  DEPLOY: {
    ...BASE_TASK,
    commandId: 2,
    name: langs('DEPLOYMENTS_ACTIONS_DEPLOY'),
    permissions: consts.rbac.permissions.mtms.services.deploy,
    selectionComponent: (props) => <HelmForm helmCommand={COMMANDS.UPGRADE} isVersion {...props} />,
    type: task.taskType.HELM
  },
  HISTORY: {
    ...BASE_TASK,
    commandId: 4,
    name: langs('HISTORY'),
    permissions: consts.rbac.permissions.mtms.services.deploy,
    type: task.taskType.HELM
  },
  REPLICATE: {
    ...BASE_TASK,
    commandId: 1,
    name: langs('DEPLOYMENTS_ACTIONS_REPLICATE'),
    permissions: consts.rbac.permissions.mtms.services.scale,
    selectionComponent: (props) => <ServiceReplicateAction {...props} />,
    type: task.taskType.MT_APP_DEPLOYMENT
  },
  ROLLBACK: {
    ...BASE_TASK,
    commandId: 3,
    name: langs('ROLLBACK'),
    permissions: consts.rbac.permissions.mtms.services.deploy,
    selectionComponent: (props) => <HelmForm helmCommand={COMMANDS.ROLLBACK} {...props} />,
    type: task.taskType.HELM
  },
  STOP: {
    ...BASE_TASK,
    commandId: 1,
    getConfigurationValue: () => ({
      key_category: 'DEPLOY',
      key_name: 'replicas',
      key_sub_category: 'general',
      value: JSON.stringify({value: 0})
    }),
    getParametersValues: ({rows}) => {
      return {branch: _.get(_.head(rows), 'deploymentBranch', '')};
    },
    name: langs('DEPLOYMENTS_ACTIONS_STOP'),
    permissions: consts.rbac.permissions.mtms.services.stop,
    type: task.taskType.MT_APP_DEPLOYMENT
  },
  TEMPLATE: {
    ...BASE_TASK,
    commandId: 6,
    name: langs('TEMPLATE'),
    permissions: consts.rbac.permissions.mtms.services.deploy,
    selectionComponent: (props) => <HelmForm helmCommand={COMMANDS.TEMPLATE} isVersion {...props} />,
    type: task.taskType.HELM
  },
  UNINSTALL: {
    ...BASE_TASK,
    commandId: 5,
    name: langs('UNINSTALL'),
    permissions: consts.rbac.permissions.mtms.services.deploy,
    type: task.taskType.HELM
  }
};

function useNewDeploymentTask(task, deployments, getSelectionComponentProps) {
  return useNewTask(task, deployments, 'deploymentId', getSelectionComponentProps);
}

export default function useNewTaskActions(deployments) {
  const user = context.User.useUser();
  const nameSuffix = _.isEmpty(deployments) ? '' : ` (${_.size(deployments).toLocaleString()})`;

  const [helmHistoryGetDef, helmHistoryDialog] = useNewDeploymentTask(TASKS.HISTORY, deployments);
  const [helmTemplateGetDef, helmTemplateDialog] = useNewDeploymentTask(TASKS.TEMPLATE, deployments);
  const [helmRollbackGetDef, helmRollbackDialog] = useNewDeploymentTask(TASKS.ROLLBACK, deployments);
  const [helmUninstallGetDef, helmUninstallDialog] = useNewDeploymentTask(TASKS.UNINSTALL, deployments);

  const [helmDeploymentGetDef, helmDeploymentDialog] = useNewDeploymentTask(TASKS.DEPLOY, deployments);
  const [serviceReplicateGetDef, serviceReplicateDialog] = useNewDeploymentTask(TASKS.REPLICATE, deployments);
  const [serviceStopGetDef, serviceStopDialog] = useNewDeploymentTask(TASKS.STOP, deployments);

  const dialogs = (
    <>
      {helmDeploymentDialog}
      {serviceReplicateDialog}
      {serviceStopDialog}
      {helmHistoryDialog}
      {helmTemplateDialog}
      {helmRollbackDialog}
      {helmUninstallDialog}
    </>
  );

  function getDef(deployment, isServiceContext, isMultipleSelection) {
    return _.flatten(
      _.compact([
        helmDeploymentGetDef(deployment, user.rbac.mtms),
        isServiceContext &&
          !isMultipleSelection && [
            'separator',
            {
              icon: 'helm_logo_white',
              name: `${langs('HELM_TASKS')}${nameSuffix}`,
              subMenu: [
                helmHistoryGetDef(deployment, user.rbac.mtms),
                helmTemplateGetDef(deployment, user.rbac.mtms),
                helmRollbackGetDef(deployment, user.rbac.mtms),
                helmUninstallGetDef(deployment, user.rbac.mtms)
              ]
            },
            serviceReplicateGetDef(deployment, user.rbac.mtms),
            serviceStopGetDef(deployment, user.rbac.mtms),
            'separator'
          ]
      ])
    );
  }

  return [getDef, dialogs];
}
