const fields = {
  ENVIRONMENT_NAME: 'environmentName',
  NAMESPACE_NAME: 'namespaceName',
  RELEASE_CYCLE: 'releaseCycle',
  SERVICE_NAME: 'serviceName',
  STACK_NAME: 'stackName'
};

export default {
  fields
};
