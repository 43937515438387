import PropTypes from 'prop-types';
import React from 'react';

import {Paper} from '../../../components/Base';
import services from '../../../services';

import Notes from '../Parts/Notes';

function getInitialNote(tenant) {
  return {
    lastUpdate: tenant.lastCommentedDate,
    text: tenant.comment,
    user: tenant.lastCommentedBy
  };
}

function NotesAndComments({tenant, onUpdate}) {
  async function updateNotes(newNote) {
    const updateParams = {
      comment: newNote,
      lastCommentedDate: new Date().toISOString()
    };

    await services.tenants.updateById(tenant.tenantId, updateParams);
    await onUpdate();
  }

  return (
    <Paper padding={1}>
      <Notes initialNote={getInitialNote(tenant)} onSave={updateNotes} />
    </Paper>
  );
}

NotesAndComments.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  tenant: PropTypes.shape({
    comment: PropTypes.string,
    lastCommentedBy: PropTypes.string,
    lastCommentedDate: PropTypes.string,
    tenantId: PropTypes.string.isRequired
  }).isRequired
};

export default NotesAndComments;
