export const chartColors = ['#89c9b8', '#8096cb', '#cbc880', '#cb8098'];

export const TopPanel = {
  text: '#FFFFFF'
};

export const searchBar = {
  caret: '#606060',
  text: 'rgba(55, 55, 55, 0.5)'
};

export const text = {
  primary: '#FEFFFE',
  secondary: '#A9A9A9'
};

export const terminalColors = {
  black: '#A9A9A9',
  blue: '#2296F3',
  cyan: 'cyan',
  green: '#20DB7F',
  grey: '#A9A9A9',
  magenta: 'magenta',
  orchid: '#DA70D6',
  red: '#F44343',
  white: 'white',
  yellow: '#DBDB20'
};

export const backgrounds = {
  black: '#1A1A1A',
  dark: '#212121',
  envSelector: '#452b88',
  light: '#373737',
  light1: '#C8C8C8',
  lighter: '#3f3f3f',
  logo: '#372565',
  primary: '#282828',
  secondary: '#313131',
  title: '#B6B6B6'
};

export const chipBackgrounds = {
  primary: '#616161'
};

export const TaskDialog = {
  cancelButtonBackground: '#1d1d1d'
};

export const separators = {
  light: '#616161',
  primary: '#979797',
  transparent: '#ffffff1f'
};

export const navbar = {
  activeText: '#FDBA31',
  button: '#cfcfcf',
  caret: '#8b64fd',
  caretInverted: '#4100ff',
  placeHolder: '#ffffff50',
  separator: separators.primary,
  text: '#B6B6B6'
};

export const statusColors = {
  error: '#F44343',
  info: '#656565',
  success: '#20DB7F',
  warning: '#DBDB20'
};

export const statusBackground = {
  error: '#F44436',
  info: '#2296F3',
  success: '#4CAF50',
  warning: '#FF9800'
};

export const progressColors = {
  buffer: '#545454',
  done: '#282828',
  remaining: '#6d6d6d',
  remainingPaused: '#DBDB20',
  running: '#20DB7F'
};

export const buttons = {
  bold: {
    background: '#372564',
    hover: '#9586bc',
    text: 'white'
  },
  danger: {
    background: '#a72222',
    text: 'white'
  },
  normal: {
    background: '#0c0b0b',
    hover: '#9586bc',
    text: '#ffffff'
  },
  secondary: {
    background: '#2d2d2d',
    text: 'white'
  },
  secondaryText: {
    text: '#8f8f8f'
  }
};

export const link = {
  hover: '#8b64fd',
  text: 'white'
};

export const tabBar = {
  bottomBorder: '#434343',
  textColor: 'white'
};

export const inputBottomBorder = {
  active: '#fefffe',
  inactive: '#717171'
};

export const modal = {
  background: 'black',
  closeButtonColor: 'white',
  lightboxBackground: '#00000050'
};

export const textarea = {
  background: '#00000052',
  textColor: '#ffffff'
};

export const accordion = {
  background: '#ffffff10',
  text: text.primary
};

export const zIndex = {
  advancedSearch: 3,
  taskHeader: 2
};

const theme = {
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
      }
    },
    MuiChip: {
      root: {
        backgroundColor: chipBackgrounds.primary
      },
      sizeSmall: {
        height: 22
      }
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: '#8b64fd'
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: backgrounds.primary
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid rgba(255, 255, 255, 0.3)'
        }
      }
    },
    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: '#45327e'
      },
      colorPrimary: {
        backgroundColor: '#8b64fd'
      }
    }
  },
  palette: {
    background: {
      default: backgrounds.secondary,
      paper: backgrounds.light
    },
    primary: {
      main: '#2d2d2d'
    },
    secondary: {
      main: '#20c2db'
    },
    text,
    type: 'dark'
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    fontFamily: 'Proxima Nova'
  }
};

export const disabled = {
  backgroundColor: '#707070',
  color: '#a0a0a0'
};

export default theme;
