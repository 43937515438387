import axios from 'axios';

function del(url, data, headers) {
  return axios({
    data,
    headers,
    method: 'DELETE',
    url
  });
}

function get(url, params, headers) {
  return axios({
    headers,
    method: 'GET',
    params,
    url
  });
}

function post(url, data, headers) {
  return axios({
    data,
    headers,
    method: 'POST',
    url
  });
}

function put(url, data, headers) {
  return axios({
    data,
    headers,
    method: 'PUT',
    url
  });
}

function patch(url, data, headers) {
  return axios({
    data,
    headers,
    method: 'PATCH',
    url
  });
}

export default {
  all: axios.all,
  delete: del,
  get,
  patch,
  post,
  put,
  spread: axios.spread
};
