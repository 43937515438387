import styled from 'styled-components';
import React from 'react';

import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiInput from '@material-ui/core/Input';
import MuiInputAdornment from '@material-ui/core/InputAdornment';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import * as theme from '../../theme';

export const DialogContent = styled(MuiDialogContent)`
  padding: 8px;
`;

export const CommandsList = styled(MuiList).attrs({disablePadding: true, subheader: <li />})`
  background-color: ${theme.default.palette.primary.main};
  max-height: 300px;
  overflow: auto;
  position: relative;
  width: 100%;
`;

export const BackgroundColorContainer = styled.li`
  background-color: inherit;
`;

export const Commands = styled.ul`
  background-color: inherit;
  padding: 0;
`;

export const Input = styled(MuiInput)`
  font-size: 14px;
  padding-bottom: 4px;
  padding-top: 4px;
  background-color: ${theme.default.palette.primary.main};
`;

export const InputAdornment = styled(MuiInputAdornment)`
  color: ${theme.text.secondary};
  padding-left: 20px;
`;

export const Command = styled(MuiListItem).attrs({button: true, dense: true})`
  border-top: 1px solid ${theme.separators.light};
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > svg {
    visibility: hidden;
  }

  &:hover {
    > svg {
      visibility: visible;
    }
  }
`;

export const ListItemText = styled(MuiListItemText)`
  overflow: hidden;
  white-space: nowrap;
`;

export const VersionItemContent = styled.span`
  display: flex;
`;

export const VersionSegment = styled.span`
  display: block;
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 2px;
`;

export const TenantVersionIndicator = styled(Typography).attrs({
  color: 'textPrimary',
  component: 'span',
  variant: 'body2'
})``;

export const CommandDescription = styled(Typography).attrs({color: 'textPrimary', component: 'span', variant: 'body2'})`
  font-size: 13px;
  margin-right: 10px;
`;

export const ShellCommand = styled.div`
  color: ${theme.terminalColors.black};
  font-family: monospace;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 99%;
`;

export const ArchiveButton = styled(DeleteIcon)`
  font-size: 18px;
  color: ${theme.statusColors.info};
  &:hover {
    color: inherit;
  }
`;

export const CopyButton = styled(FileCopyIcon)`
  font-size: 16px;
  color: ${theme.statusColors.info};
  &:hover {
    color: inherit;
  }
`;

export const CommandActions = styled.div`
  max-width: 18px;
`;
