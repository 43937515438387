import _ from 'lodash';
import React from 'react';

import {DEFAULT_CONTEXT_MENU} from '../../../components/AGTable/Table';

import useDecommission from './useDecommission';
import useDelete from './useDelete';
import useMarkOperational from './useMarkOperational';
import useNewTaskActions from './useNewTaskActions';

export default function useActions(collectors, tableApi) {
  const nameSuffix = _.isEmpty(collectors) ? '' : ` (${_.size(collectors).toLocaleString()})`;
  const [decommissionCollectorsGetDef, decommissionCollectorsDialog] = useDecommission(collectors, nameSuffix);
  const [markOperationalCollectorsGetDef, markOperationalCollectorsDialog] = useMarkOperational(collectors, nameSuffix);
  const [deleteCollectorGetDef, deleteCollectorDialog] = useDelete(collectors, tableApi);
  const [newTaskGetDef, newTaskDialogs] = useNewTaskActions(collectors);

  const dialogs = (
    <>
      {deleteCollectorDialog}
      {decommissionCollectorsDialog}
      {markOperationalCollectorsDialog}
      {newTaskDialogs}
    </>
  );

  function getContextMenuItems(params, collector) {
    return _.concat(
      [
        newTaskGetDef(collector),
        'separator',
        deleteCollectorGetDef(collector),
        decommissionCollectorsGetDef(collector),
        markOperationalCollectorsGetDef(collector),
        'separator'
      ],
      DEFAULT_CONTEXT_MENU
    );
  }

  return [dialogs, getContextMenuItems];
}
