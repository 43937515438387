import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './CheckboxHeader.styled';

function CheckboxHeader({api}) {
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    function onSelectionChange() {
      const selected = api.getSelectedRows();
      const total = api.getDisplayedRowCount();
      const pageSize = api.paginationGetPageSize();
      setChecked(_.size(selected) === Math.min(total, pageSize));
    }

    api.eventService.addEventListener('selectionChanged', onSelectionChange);
    return () => api.eventService.removeEventListener('selectionChanged', onSelectionChange);
  }, [api]);

  const onChange = React.useCallback(
    ({target: {checked}}) => {
      if (!api) {
        return;
      }
      _.forEach(api.getRenderedNodes(), (node) => node.setSelected(checked));
    },
    [api]
  );

  return <Styled.Checkbox checked={checked} onChange={onChange} />;
}

CheckboxHeader.propTypes = {
  api: PropTypes.object
};

export default CheckboxHeader;
