import styled, {css} from 'styled-components';

import {makeStyles} from '@material-ui/core';

import icons from '../icons';
import task from '../../../models/task';

import * as theme from '../../../theme';

export const useStyles = makeStyles({
  bar1Buffer: {
    backgroundColor: ({isActive}) => (isActive ? theme.progressColors.running : theme.progressColors.done)
  },
  colorPrimary: {
    backgroundColor: theme.progressColors.buffer
  },
  dashed: {
    animation: 'unset'
  },
  dashedColorPrimary: {
    backgroundColor: ({taskStatus}) =>
      taskStatus === task.taskStatus.PAUSED ? theme.progressColors.remainingPaused : theme.progressColors.remaining,
    backgroundImage: 'unset'
  },
  root: {
    height: 5
  }
});

export const Container = styled.div`
  position: relative;
`;

function getIconStyles() {
  return css`
    background-color: rgb(47, 47, 47);
    border-radius: 50px;
    font-size: 26px;
    height: 25px;
    left: -10px;
    position: absolute;
    top: -10px;
    width: 25px;
    z-index: ${theme.zIndex.taskHeader};
  `;
}

export const SuccessIcon = styled(icons.Success).attrs({role: 'img'})`
  ${getIconStyles()};
`;

export const FailIcon = styled(icons.Failed).attrs({role: 'img'})`
  ${getIconStyles()};
`;

export const PauseIcon = styled.span.attrs({role: 'img'})`
  ${getIconStyles()};
  background-color: ${theme.progressColors.done};
  font-size: 16px;
  height: 30px;
  padding: 6px 7px 6px 8px;
  text-align: center;
  width: 30px;
`;
