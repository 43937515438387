import * as Environments from './Environments';
import * as Navigation from './Navigation';
import * as Notifications from './Notifications';
import * as Search from './Search';
import * as TableFilters from './TableFilters';
import * as User from './User/User';

export default {
  Environments,
  Navigation,
  Notifications,
  Search,
  TableFilters,
  User
};
