import React from 'react';

import AddIcon from '@material-ui/icons/Add';

import consts from '../../../consts';
import {Button} from '../../../components/Base';
import langs from '../../../localization/langs';
import OpsLink from '../../../components/OpsLink';

export default function AddDemoTenant() {
  return (
    <OpsLink to="tenants.add" params={{cluster: consts.DEMO_ENV}}>
      <Button text icon={<AddIcon />}>
        {langs('DEMO_NEW_TENANT')}
      </Button>
    </OpsLink>
  );
}
