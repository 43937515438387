import _ from 'lodash';

import client from '../client';
import tenants from '../tenants/index';
import staticData from '../../models/staticData';

const SERVICE_NAMES = [
  staticData.RBAC.SERVICE_NAMES.CMS,
  staticData.RBAC.SERVICE_NAMES.MAESTRO,
  staticData.RBAC.SERVICE_NAMES.MTMS,
  staticData.RBAC.SERVICE_NAMES.OPS_WEB,
  staticData.RBAC.SERVICE_NAMES.RBAC
];

async function getEnvs() {
  return tenants.getEnvs({action: 'rbac'}).then(({data}) => _.map(_.get(data, 'items', []), 'name'));
}

async function getPermissions() {
  return client.get('/rbac/api/v1/permissions', {
    serviceNames: JSON.stringify(SERVICE_NAMES)
  });
}

async function getPermissionsSchema(oktaLogin) {
  const params = _.isEmpty(oktaLogin) ? {} : {userLogin: encodeURIComponent(oktaLogin)};
  return client.get('/rbac/api/v1/permissions/schema', params);
}

async function getSelfPermissionsSchema() {
  return client.get('/rbac/api/v1/permissions/self-schema');
}

async function getViewAsPermissions(envNames, serviceNames, userGroups) {
  return client.get('/rbac/api/v1/fe-permissions-view-as', {
    viewAsEnvNames: JSON.stringify(envNames),
    viewAsServiceNames: JSON.stringify(serviceNames),
    viewAsUserGroups: JSON.stringify(userGroups)
  });
}

async function getRbacStates() {
  return client
    .all([
      client.get('/api/v1/rbac/enforcement-state'),
      client.get('/cms/api/v1/rbac/enforcement-state'),
      client.get('/maestro/api/v1/rbac/enforcement-state'),
      client.get('/mtms/api/v1/rbac/enforcement-state')
    ])
    .then(
      client.spread((opsData, cmsData, maestroData, mtmsData) => {
        return {
          cms: cmsData.data,
          maestro: maestroData.data,
          mtms: mtmsData.data,
          opsWeb: opsData.data,
          rbac: staticData.RBAC.RBAC_ENFORCEMENT_LEVEL.FULL
        };
      })
    );
}

async function listIdpGroups() {
  return client.get('/rbac/api/v1/list-idp-groups');
}

async function deleteGroupBinding(groupName, serviceName, envName) {
  const params = {envName, groupName, serviceName};
  return client.delete('/rbac/api/v1/group-binding', params);
}

async function saveGroupToRoleBinding(groupName, serviceName, envName, roleIds) {
  const params = {envName, groupName, roleIds, serviceName};
  return client.patch('/rbac/api/v1/group-binding', params);
}

export default {
  deleteGroupBinding,
  getEnvs,
  getPermissions,
  getPermissionsSchema,
  getSelfPermissionsSchema,
  getStates: {
    all: getRbacStates
  },
  getViewAsPermissions,
  listIdpGroups,
  saveGroupToRoleBinding
};
