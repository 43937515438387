import client from '../client';

const defaultExpiry = 4 * 7 * 24 * 3600; // 4 weeks

async function getImage(imageName, expiry) {
  return client.get(`/cms/api/v1/images/${imageName}?expiry=${expiry}`);
}

export async function getKaliImage(expiry = defaultExpiry) {
  return getImage('kali_linux_attack_simulator_vm', expiry);
}
