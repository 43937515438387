import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../context';
import langs from '../../localization/langs';
import services from '../../services/services';

import SelectParameters from '../NewTaskWidget/SelectParameters';
import SelectVersion from '../NewTaskWidget/SelectVersion';

function HelmForm({helmCommand, setCommand, setParametersValues, isVersion = false, ...props}) {
  const [parameters, setParameters] = React.useState([]);
  const notifications = context.Notifications.useNotifications();

  React.useEffect(() => {
    services.services.getHelmCommand(helmCommand).then((command) => {
      if (_.isNil(command)) {
        notifications.error(langs('FAILED_GET_COMMANDS'));
        return;
      }
      setParameters(command.parameters);
      setCommand(command);
    });
  }, []);

  return isVersion ? (
    <SelectVersion
      getArtifactByBranchApi={services.artifacts.getArtifactByBranch}
      listArtifactsApi={services.artifacts.listArtifacts}
      parameters={parameters}
      setParametersValues={setParametersValues}
      onClick={(value) =>
        setParametersValues((prevState) => ({
          ...prevState,
          chart_version_id: _.get(value, 'id')
        }))
      }
      {...props}
    />
  ) : (
    <SelectParameters parameters={parameters} setParametersValues={setParametersValues} {...props} />
  );
}

HelmForm.propTypes = {
  helmCommand: PropTypes.string,
  isVersion: PropTypes.bool,
  setCommand: PropTypes.func,
  setParametersValues: PropTypes.func
};

export default HelmForm;
