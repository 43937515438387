import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import WarningIcon from '@material-ui/icons/Warning';

import context from '../../../../context';
import langs from '../../../../localization/langs';

import * as Styled from './RerouteWarning.styles';

function RerouteWarning({tenantName}) {
  const {opsEnv} = context.Navigation.useNavigation();

  return (
    <Grid container xs={12}>
      <Grid item container xs={2} justifyContent="center" alignContent="center">
        <Styled.IconContainer item>
          <WarningIcon fontSize="large" color="inherit" />
        </Styled.IconContainer>
      </Grid>
      <Grid item xs={10}>
        <div>
          {langs('TENANT')} <code>{tenantName}</code> {langs('IS_NOT_PART_OF_THIS_ENVIRONMENT')} ({opsEnv})
          <br />
          {langs('CLICK_ON_REROUTE_MESSAGE')}
        </div>
      </Grid>
    </Grid>
  );
}

RerouteWarning.propTypes = {
  tenantName: PropTypes.string
};

export default RerouteWarning;
