const ALT = 'alt';
const CONTROL = 'control';
const CTRL = 'ctrl';
const ESCAPE = 'escape';
const META = 'meta';
const SHIFT = 'shift';

export default {
  ALT,
  CONTROL,
  CTRL,
  ESCAPE,
  META,
  SHIFT
};
