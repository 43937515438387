import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {useLocation} from 'react-router-dom';

import Collapse from '@material-ui/core/Collapse';

import langs from '../../localization/langs';

import NavbarLinkIconAndLabel from './parts/NavbarLinkIconAndLabel';
import NavbarLinkTitle from './parts/NavbarLinkTitle';

import * as Styled from './NavbarLink.styles';

const DUMMY_URL_PREFIX = 'https://ops.com';

function NavbarLink({url, label, nestedLinks}) {
  const [isNestedLinksOpen, setIsNestedLinksOpen] = React.useState(false);

  const location = useLocation();
  const isNestedLinksMode = !_.isNil(nestedLinks);

  React.useEffect(() => {
    const locationPathName = location.pathname;
    const urlPathName = new URL(`${DUMMY_URL_PREFIX}${url}`).pathname;
    setIsNestedLinksOpen(isNestedLinksOpen || _.includes(locationPathName, urlPathName));
  }, [location]);

  function getNestedLinksWithMainLink() {
    if (!isNestedLinksMode || !url) {
      return nestedLinks;
    }

    return [
      {
        exact: true,
        label: langs('DASHBOARD'),
        url
      },
      ...nestedLinks
    ];
  }

  const nestedLinksWithMainLink = getNestedLinksWithMainLink();
  return (
    <Styled.Wrapper>
      {isNestedLinksMode ? (
        <Styled.MenuButton onClick={() => setIsNestedLinksOpen(!isNestedLinksOpen)}>
          <NavbarLinkTitle label={label} isNestedLinksOpen={isNestedLinksOpen} isNestedLinksMode={isNestedLinksMode} />
        </Styled.MenuButton>
      ) : (
        <Styled.NavigationButton to={url} isActive={() => false}>
          <NavbarLinkTitle label={label} isNestedLinksOpen={isNestedLinksOpen} isNestedLinksMode={isNestedLinksMode} />
        </Styled.NavigationButton>
      )}

      <Collapse in={isNestedLinksMode && isNestedLinksOpen} timeout="auto" unmountOnExit>
        {nestedLinksWithMainLink?.map((nestedLink) =>
          _.startsWith(nestedLink.url, 'http') ? (
            <a key={nestedLink.url} href={nestedLink.url}>
              <NavbarLinkIconAndLabel label={nestedLink.label} />
            </a>
          ) : (
            <Styled.NavigationButton key={nestedLink.url} to={nestedLink.url} exact={nestedLink.exact}>
              <NavbarLinkIconAndLabel label={nestedLink.label} />
            </Styled.NavigationButton>
          )
        )}
      </Collapse>
    </Styled.Wrapper>
  );
}

NavbarLink.propTypes = {
  label: PropTypes.string.isRequired,
  nestedLinks: PropTypes.array,
  url: PropTypes.string
};

export default NavbarLink;
