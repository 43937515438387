import styled from 'styled-components';

import SvgIcon from '@material-ui/core/SvgIcon';

import * as theme from '../../../../theme';

export const Container = styled.div`
  width: 100%;
  height: 250px;
  background-color: ${theme.backgrounds.primary};
  border: 1px solid ${theme.backgrounds.primary};
`;

export const Input = styled.input`
  width: 100%;
  height: 28px;
  outline: none;
  font-size: 0.8rem;
  padding-left: 10px;
  border: solid 1px ${theme.searchBar.caret};
  background-color: ${theme.backgrounds.lighter};
  color: ${theme.text.primary};
`;

export const List = styled.ul`
  list-style: none;
  margin-top: 10px;
  padding-left: 10px;
  height: 212px;
  overflow: auto;
  margin-bottom: 0;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: ${theme.backgrounds.black};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${theme.backgrounds.title};
  }
`;

export const ListItem = styled.li`
  display: flex;
  gap: 5px;
  cursor: pointer;
  user-select: none;
`;

export const IconButtonStyled = styled(SvgIcon)`
  font-size: 20px;
`;
