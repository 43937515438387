import _ from 'lodash';

import client from '../client';

export async function get(category, serviceId, envId, namespaceId, isTemplated) {
  let route;
  const hasEnvId = !_.isEmpty(envId);
  const hasNamespaceId = !_.isEmpty(namespaceId);
  const hasServiceId = !_.isEmpty(serviceId);

  if (hasServiceId && hasEnvId && hasNamespaceId) {
    route = `/mtms/api/v1/services/${serviceId}/environments/${envId}/namespaces/${namespaceId}/configuration`;
  } else if (hasServiceId && hasEnvId) {
    route = `/mtms/api/v1/services/${serviceId}/environments/${envId}/configuration`;
  } else if (hasServiceId) {
    route = `/mtms/api/v1/services/${serviceId}/configuration`;
  } else {
    route = '/mtms/api/v1/configuration';
  }

  if (isTemplated) {
    return client.get(route, {category, template: true});
  }
  return client.get(route, {category});
}

export async function createKey(key, keyCategory, keySubCategory, type, defaultValue, description, labels) {
  return client.post(`/mtms/api/v1/configuration_key`, {
    defaultValue: JSON.stringify({value: defaultValue}),
    description,
    key,
    keyCategory,
    keySubCategory,
    labels: _(labels)
      .mapKeys((value, key) => _.snakeCase(key))
      .omitBy(_.isEmpty)
      .value(),
    type
  });
}

export async function createKeyByQuery(
  key,
  keyCategory,
  keySubCategory,
  type,
  defaultValue,
  description,
  labels,
  hostIds
) {
  return client.post(`/mtms/api/v1/configuration/_by_query`, {
    category: keyCategory,
    configType: type,
    description,
    hostIds,
    key,
    subCategory: keySubCategory,
    value: JSON.stringify({value: defaultValue})
  });
}

export async function createValue({name, value, labels, mainCategory, subCategory}) {
  return client.post(`/mtms/api/v1/configuration_value`, {
    keyCategory: mainCategory,
    keyName: name,
    keySubCategory: subCategory,
    labels: _(labels)
      .mapKeys((value, key) => _.snakeCase(key))
      .omitBy(_.isEmpty)
      .value(),
    value: JSON.stringify({value})
  });
}

export async function updateValue(configurationValueId, value) {
  return client.patch(`/mtms/api/v1/configuration_value/${configurationValueId}`, {
    value: JSON.stringify({value})
  });
}

export async function deleteValue(configurationValueId) {
  return client.delete(`/mtms/api/v1/configuration_value/${configurationValueId}`);
}

export async function getVersions(serviceId, envId, namespaceId, configurationKeyId) {
  let route;
  if (!_.isEmpty(serviceId) && !_.isEmpty(envId) && !_.isEmpty(namespaceId)) {
    route = `/mtms/api/v1/services/${serviceId}/environments/${envId}/namespaces/${namespaceId}/configuration_key/${configurationKeyId}/versions`;
  } else if (!_.isEmpty(serviceId) && !_.isEmpty(envId)) {
    route = `/mtms/api/v1/services/${serviceId}/environments/${envId}/configuration_key/${configurationKeyId}/versions`;
  } else if (!_.isEmpty(serviceId)) {
    route = `/mtms/api/v1/services/${serviceId}/configuration_key/${configurationKeyId}/versions`;
  } else {
    route = `/mtms/api/v1/configuration_key/${configurationKeyId}/versions`;
  }
  return client.get(route);
}

export async function getConfigurationKeys(keyName) {
  return client.get(`/mtms/api/v1/configuration_keys?key[like]=%${keyName}%&length=10`);
}
