import staticData from '../../models/staticData';

import {appendAttribute} from './common';

const MTMS_PERMISSIONS = staticData.RBAC.SERVICE_PERMISSIONS.MTMS;

export default {
  configuration: {
    create: {
      all: {
        all: appendAttribute(
          MTMS_PERMISSIONS.CREATE_CONFIGURATION,
          MTMS_PERMISSIONS.CREATE_CONFIGURATION_LEVEL_ALL_ALL
        ),
        envs: appendAttribute(
          MTMS_PERMISSIONS.CREATE_CONFIGURATION,
          MTMS_PERMISSIONS.CREATE_CONFIGURATION_LEVEL_ALL_ENVS
        ),
        namespaces: appendAttribute(
          MTMS_PERMISSIONS.CREATE_CONFIGURATION,
          MTMS_PERMISSIONS.CREATE_CONFIGURATION_LEVEL_ALL_NAMESPACES
        ),
        services: appendAttribute(
          MTMS_PERMISSIONS.CREATE_CONFIGURATION,
          MTMS_PERMISSIONS.CREATE_CONFIGURATION_LEVEL_ALL_SERVICES
        )
      },
      categories: {
        appConfig: appendAttribute(
          MTMS_PERMISSIONS.CREATE_CONFIGURATION,
          MTMS_PERMISSIONS.CREATE_CONFIGURATION_CATEGORY_APP_CONFIG
        ),
        deployConfig: appendAttribute(
          MTMS_PERMISSIONS.CREATE_CONFIGURATION,
          MTMS_PERMISSIONS.CREATE_CONFIGURATION_CATEGORY_DEPLOY_CONFIG
        ),
        secrets: appendAttribute(
          MTMS_PERMISSIONS.CREATE_CONFIGURATION,
          MTMS_PERMISSIONS.CREATE_CONFIGURATION_CATEGORY_SECRETS
        )
      },
      create: MTMS_PERMISSIONS.CREATE_CONFIGURATION
    },
    delete: {
      all: {
        all: appendAttribute(
          MTMS_PERMISSIONS.DELETE_CONFIGURATION,
          MTMS_PERMISSIONS.DELETE_CONFIGURATION_LEVEL_ALL_ALL
        ),
        envs: appendAttribute(
          MTMS_PERMISSIONS.DELETE_CONFIGURATION,
          MTMS_PERMISSIONS.DELETE_CONFIGURATION_LEVEL_ALL_ENVS
        ),
        namespaces: appendAttribute(
          MTMS_PERMISSIONS.DELETE_CONFIGURATION,
          MTMS_PERMISSIONS.DELETE_CONFIGURATION_LEVEL_ALL_NAMESPACES
        ),
        services: appendAttribute(
          MTMS_PERMISSIONS.DELETE_CONFIGURATION,
          MTMS_PERMISSIONS.DELETE_CONFIGURATION_LEVEL_ALL_SERVICES
        )
      },
      categories: {
        appConfig: appendAttribute(
          MTMS_PERMISSIONS.DELETE_CONFIGURATION,
          MTMS_PERMISSIONS.DELETE_CONFIGURATION_CATEGORY_APP_CONFIG
        ),
        deployConfig: appendAttribute(
          MTMS_PERMISSIONS.DELETE_CONFIGURATION,
          MTMS_PERMISSIONS.DELETE_CONFIGURATION_CATEGORY_DEPLOY_CONFIG
        ),
        secrets: appendAttribute(
          MTMS_PERMISSIONS.DELETE_CONFIGURATION,
          MTMS_PERMISSIONS.DELETE_CONFIGURATION_CATEGORY_SECRETS
        )
      },
      delete: MTMS_PERMISSIONS.DELETE_CONFIGURATION
    },
    edit: {
      all: {
        all: appendAttribute(MTMS_PERMISSIONS.EDIT_CONFIGURATION, MTMS_PERMISSIONS.EDIT_CONFIGURATION_LEVEL_ALL_ALL),
        envs: appendAttribute(MTMS_PERMISSIONS.EDIT_CONFIGURATION, MTMS_PERMISSIONS.EDIT_CONFIGURATION_LEVEL_ALL_ENVS),
        namespaces: appendAttribute(
          MTMS_PERMISSIONS.EDIT_CONFIGURATION,
          MTMS_PERMISSIONS.EDIT_CONFIGURATION_LEVEL_ALL_NAMESPACES
        ),
        services: appendAttribute(
          MTMS_PERMISSIONS.EDIT_CONFIGURATION,
          MTMS_PERMISSIONS.EDIT_CONFIGURATION_LEVEL_ALL_SERVICES
        )
      },
      categories: {
        appConfig: appendAttribute(
          MTMS_PERMISSIONS.EDIT_CONFIGURATION,
          MTMS_PERMISSIONS.EDIT_CONFIGURATION_CATEGORY_APP_CONFIG
        ),
        deployConfig: appendAttribute(
          MTMS_PERMISSIONS.EDIT_CONFIGURATION,
          MTMS_PERMISSIONS.EDIT_CONFIGURATION_CATEGORY_DEPLOY_CONFIG
        ),
        secrets: appendAttribute(
          MTMS_PERMISSIONS.EDIT_CONFIGURATION,
          MTMS_PERMISSIONS.EDIT_CONFIGURATION_CATEGORY_SECRETS
        )
      },
      edit: MTMS_PERMISSIONS.EDIT_CONFIGURATION
    },
    read: {
      all: {
        all: appendAttribute(
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES_LEVEL_ALL_ALL
        ),
        envs: appendAttribute(
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES_LEVEL_ALL_ENVS
        ),
        namespaces: appendAttribute(
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES_LEVEL_ALL_NAMESPACES
        ),
        services: appendAttribute(
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES_LEVEL_ALL_SERVICES
        )
      },
      categories: {
        appConfig: appendAttribute(
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES_CATEGORY_APP_CONFIG
        ),
        deployConfig: appendAttribute(
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES_CATEGORY_DEPLOY_CONFIG
        ),
        secrets: appendAttribute(
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
          MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES_CATEGORY_SECRETS
        )
      },
      read: MTMS_PERMISSIONS.READ_CONFIGURATION_VALUES
    }
  },
  envs: {
    deploy: MTMS_PERMISSIONS.MT_ENVS_DEPLOY,
    read: MTMS_PERMISSIONS.MT_ENVS_READ,
    restart: MTMS_PERMISSIONS.MT_ENVS_RESTART,
    scale: MTMS_PERMISSIONS.MT_ENVS_SCALE,
    stop: MTMS_PERMISSIONS.MT_ENVS_STOP
  },
  namespaces: {
    create: MTMS_PERMISSIONS.MT_NAMESPACES_CREATE,
    deploy: MTMS_PERMISSIONS.MT_NAMESPACES_DEPLOY,
    edit: MTMS_PERMISSIONS.MT_NAMESPACES_EDIT,
    read: MTMS_PERMISSIONS.MT_NAMESPACES_READ,
    restart: MTMS_PERMISSIONS.MT_NAMESPACES_RESTART,
    scale: MTMS_PERMISSIONS.MT_NAMESPACES_SCALE,
    stop: MTMS_PERMISSIONS.MT_NAMESPACES_STOP
  },
  services: {
    create: MTMS_PERMISSIONS.MT_SERVICES_CREATE,
    deploy: MTMS_PERMISSIONS.MT_SERVICES_DEPLOY,
    edit: MTMS_PERMISSIONS.MT_SERVICES_EDIT,
    read: MTMS_PERMISSIONS.MT_SERVICES_READ,
    restart: MTMS_PERMISSIONS.MT_SERVICES_RESTART,
    scale: MTMS_PERMISSIONS.MT_SERVICES_SCALE,
    showLogs: MTMS_PERMISSIONS.MT_SERVICES_SHOW_LOGS,
    stop: MTMS_PERMISSIONS.MT_SERVICES_STOP
  },
  stacks: {
    create: MTMS_PERMISSIONS.MT_STACKS_CREATE,
    deploy: MTMS_PERMISSIONS.MT_STACKS_DEPLOY,
    edit: MTMS_PERMISSIONS.MT_STACKS_EDIT,
    read: MTMS_PERMISSIONS.MT_STACKS_READ,
    restart: MTMS_PERMISSIONS.MT_STACKS_RESTART,
    scale: MTMS_PERMISSIONS.MT_STACKS_SCALE,
    stop: MTMS_PERMISSIONS.MT_STACKS_STOP
  }
};
