import styled from 'styled-components';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';

import * as theme from '../../theme';

const Failed = styled(ErrorIcon)`
  color: ${theme.statusColors.error};
  font-size: 16px;
  vertical-align: text-bottom;
  margin-right: 4px;
`;

const Pending = styled(HourglassEmptyOutlinedIcon)`
  color: ${theme.statusColors.warning};
  font-size: 16px;
  margin-right: 4px;
  vertical-align: text-bottom;
`;

const Running = styled(CircularProgress).attrs({color: 'secondary', size: 16})`
  color: ${theme.text.primary};
  font-size: 14px;
  margin-right: 6px;
  vertical-align: text-bottom;
`;

const Success = styled(CheckCircleIcon)`
  color: ${theme.statusColors.success};
  font-size: 16px;
  margin-right: 4px;
  vertical-align: text-bottom;
`;

export default {
  Failed,
  Pending,
  Running,
  Success
};
