import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../context';
import langs from '../../localization/langs';
import services from '../../services';

import StacksForm from './StacksForm';

import * as Styled from './StackPreview.styles';

function StackPreview({stackId, onClose}) {
  const notifications = context.Notifications.useNotifications();

  async function updateStack(id, name, stackServices) {
    try {
      await services.services.stacks.updateStack(id, stackServices);
      notifications.success(langs('STACKS_SUCCESSFULLY_UPDATED'));
      onClose();
    } catch (err) {
      console.error(err.stack);
      notifications.error(langs('STACKS_FAILED_TO_UPDATE'));
    }
  }

  if (_.isEmpty(stackId)) {
    return null;
  }
  return (
    <>
      <Styled.Title>{langs('STACKS_EDIT_STACK')}</Styled.Title>
      <StacksForm stackId={stackId} onSubmit={updateStack} />
    </>
  );
}

StackPreview.propTypes = {
  onClose: PropTypes.func,
  stackId: PropTypes.string
};

export default StackPreview;
