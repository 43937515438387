import PropTypes from 'prop-types';
import React from 'react';

import Grid from '@material-ui/core/Grid';

import GroupedBarCharts from './Parts/GroupedBarCharts';

import * as Styled from './HeadCharts.styles';

function HeadCharts({countApi, bars}) {
  return (
    <Styled.Container container spacing={2}>
      <Grid item xs={12}>
        <Styled.FullWidthPaper>
          <GroupedBarCharts bars={bars} countApi={countApi} />
        </Styled.FullWidthPaper>
      </Grid>
    </Styled.Container>
  );
}

HeadCharts.propTypes = {
  bars: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      title: PropTypes.string,
      translate: PropTypes.bool
    })
  ).isRequired,
  countApi: PropTypes.func.isRequired
};

export default HeadCharts;
