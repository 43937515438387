import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import models from '../../../../../models';

import * as Styled from './IntegrationsList.styles';

function getDisplayStatus(status) {
  return !!_.parseInt(status, 10)
    ? models.collector.collectorStatusOptions.ACTIVE
    : models.collector.collectorStatusOptions.INACTIVE;
}

function getIntegrationsByStatus(integrationsByType, integrationType) {
  const integrationsByStatus = _.groupBy(integrationsByType, 'status');

  return _.transform(
    integrationsByStatus,
    (result, itemsByStatus, status) => {
      const integrationsCount = _.size(itemsByStatus);
      result.push({
        key: `${integrationType}-${status}`,
        label: `${integrationType}${integrationsCount > 1 ? ` (${integrationsCount})` : ''}`,
        status: getDisplayStatus(status)
      });
    },
    []
  );
}

function getIntegrationItems(integrations) {
  const integrationsByType = _.groupBy(integrations, 'type');
  let result = [];
  _.forEach(
    _.orderBy(_.keys(integrationsByType)),
    (integrationType) =>
      (result = _.concat(result, getIntegrationsByStatus(integrationsByType[integrationType], integrationType)))
  );
  return result;
}

function IntegrationsList({value: {integrations}}) {
  if (_.isEmpty(integrations)) {
    return null;
  }
  return (
    <Styled.Container>
      {_.map(getIntegrationItems(integrations), (integrationItem) => {
        return <Styled.Item label={integrationItem.label} key={integrationItem.key} status={integrationItem.status} />;
      })}
    </Styled.Container>
  );
}

IntegrationsList.propTypes = {
  value: PropTypes.shape({
    integrations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
      })
    )
  })
};

export default IntegrationsList;
