import * as collector from './collector';
import * as command from './command';
import configuration from './configuration';
import deployments from './deployments';
import namespaces from './namespaces';
import releases from './releases';
import services from './services';
import stacks from './stacks';
import staticData from './staticData';
import task from './task';
import tenant from './tenant';

export default {
  collector,
  command,
  configuration,
  deployments,
  namespaces,
  releases,
  services,
  stacks,
  staticData,
  task,
  tenant
};
