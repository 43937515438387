import _ from 'lodash';
import React from 'react';
import {useHistory} from 'react-router-dom';

import context from '../../../../context';
import dataUtils from '../dataUtils';
import models from '../../../../models';

import DeploymentsAddConfiguration from '../DeploymentsAddConfiguration';
import ServiceLogsAction from './ServiceLogsAction';
import ServiceRestartAction from './ServiceRestartAction';
import useNewTaskActions from './useNewTaskActions';

export default function useActions(deployments, groupByKey) {
  const history = useHistory();
  const {getUrl} = context.Navigation.useNavigation();
  const user = context.User.useUser();

  const [newTaskGetDef, newTaskDialogs] = useNewTaskActions(deployments);
  const [dialog, setDialog] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const isMultipleSelection = _.size(deployments) > 1;
  const isServiceContext = groupByKey === models.deployments.fields.SERVICE_NAME;

  const openDialog = React.useCallback(
    (newSelectedRow, newDialog) => {
      setDialog(newDialog);
      setSelectedRow(newSelectedRow);
    },
    [setDialog, setSelectedRow]
  );

  const closeDialog = React.useCallback(() => openDialog(null, null), [openDialog]);

  const dialogs = (
    <>
      {newTaskDialogs}
      <ServiceRestartAction close={closeDialog} open={dialog === dataUtils.DIALOG.RESTART} rows={[selectedRow]} />
      <ServiceLogsAction close={closeDialog} open={dialog === dataUtils.DIALOG.LOGS} rows={[selectedRow]} />
      <DeploymentsAddConfiguration
        close={closeDialog}
        open={dialog === dataUtils.DIALOG.CONFIGURATION}
        row={selectedRow}
      />
    </>
  );

  function getContextMenuTasks(deployment) {
    return newTaskGetDef(deployment, isServiceContext, isMultipleSelection);
  }

  const getContextMenuItems = React.useCallback(
    (params) => {
      return dataUtils.getContextMenuItems(
        params,
        openDialog,
        groupByKey,
        user.rbac,
        isMultipleSelection,
        isServiceContext,
        history,
        getUrl,
        getContextMenuTasks
      );
    },
    [openDialog, groupByKey, deployments]
  );
  return [dialogs, getContextMenuItems];
}
