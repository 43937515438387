import _ from 'lodash';

import consts from '../../../consts';
import langs from '../../../localization/langs';

const LOWER_CASE_REGEX = /^([a-z0-9]+)(-?[a-z0-9]+)*$/;
const TENANT_NAME_INVALID_WORDS_ALL_TENANTS = [
  'admin',
  'ansible',
  'armis',
  'demo',
  'development',
  'elb',
  'env',
  'environment',
  'error',
  'feature',
  'git',
  'jenkins',
  'master',
  'origin',
  'pov',
  'prod',
  'root',
  'server',
  'service',
  'staging',
  'tenant',
  'terraform',
  'vitron'
];
const TENANT_NAME_INVALID_WORDS_PROD_TENANTS = ['test'];
const TENANT_NAME_MAX_LENGTH = 23;
const TENANT_NAME_MIN_LENGTH = 2;

function validateNonEmpty(tenant) {
  return !_.isEmpty(tenant);
}

function validateLength(tenant) {
  const size = _.size(tenant);
  return _.inRange(size, TENANT_NAME_MIN_LENGTH, TENANT_NAME_MAX_LENGTH + 1);
}

function validateRegexp(tenant) {
  return LOWER_CASE_REGEX.test(tenant);
}

function validateReservedTerms(terms) {
  return (tenant) => _.every(terms, (term) => !_.includes(tenant, term));
}

function whenProd(tenant, cluster) {
  return !cluster || _.includes(_.toLower(cluster), consts.envs.CLUSTER_PREFIXES.PROD);
}

const VALIDATIONS = [
  {
    func: validateNonEmpty,
    message: langs('ERROR_MISSING_TENANT_NAME')
  },
  {
    func: validateLength,
    message: langs('ERROR_INVALID_TENANT_NAME')
  },
  {
    func: validateRegexp,
    message: langs('ERROR_INVALID_TENANT_NAME')
  },
  {
    func: validateReservedTerms(TENANT_NAME_INVALID_WORDS_ALL_TENANTS),
    message: langs('ERROR_TENANT_NAME_CONTAINS_INVALID_WORDS')
  },
  {
    func: validateReservedTerms(TENANT_NAME_INVALID_WORDS_PROD_TENANTS),
    message: langs('ERROR_TENANT_NAME_CONTAINS_INVALID_WORDS'),
    when: whenProd
  }
];

export function validateTenantName(tenant, cluster) {
  const validations = _.reject(VALIDATIONS, ({when}) => _.isFunction(when) && !when(tenant, cluster));
  const cond = _.cond(_.map(validations, ({func, message}) => [_.negate(func), _.constant(message)]));
  const message = cond(tenant);
  return {isValid: !message, message};
}
