import styled from 'styled-components';

import Refresh from '@material-ui/icons/Refresh';

import * as theme from '../../../../theme';
import {Link} from './Common.styles';

export const RefreshIcon = styled(Refresh).attrs((props) => ({
  rel: 'noreferrer',
  target: '_blank'
}))`
  color: ${theme.text.primary};
  font-size: 16px;
  margin-right: 4px;
  vertical-align: text-bottom;
  cursor: pointer;
`;

export {Link};
