import styled from 'styled-components';

import MuiIconButton from '@material-ui/core/IconButton';
import {Grid, InputBase, Typography} from '@material-ui/core';

export const Title = styled(Typography).attrs({color: 'textSecondary', variant: 'body2'})`
  flex-grow: 1;
`;

export const IconButton = styled(MuiIconButton).attrs({size: 'small'})`
  color: #cfcfcf;
  margin-right: 2px;
`;

export const Input = styled(InputBase).attrs({margin: 'none'})`
  font-size: 15px;
  width: 70px;
`;

export const GridItemGrow = styled(Grid)`
  flex-grow: 1;
`;
