import styled from 'styled-components';

import * as Styled from '../../Base/Link.styles';

export const Anchor = styled(Styled.BaseLink)`
  display: inline-flex;
  align-items: center;
`;

export const Img = styled.img`
  height: 20px;
  width: 20px;
  margin-left: 0.5rem;
`;
