import _ from 'lodash';

import consts from '../../consts';
import {SEARCH_TYPES} from '../../context/Search/utils';
import langs from '../../localization/langs';
import models from '../../models';

export const options = [
  {
    key: models.task.fields.STATUS,
    options: _.map(models.task.taskStatus, (key) => ({key, value: langs(key)})),
    supportedOperators: [consts.FILTER_OPERATORS.LIKE, consts.FILTER_OPERATORS.NOT_LIKE],
    title: langs('TASK_STATUS'),
    type: SEARCH_TYPES.CHIPS
  },
  {
    key: models.task.fields.TYPE,
    options: _.map(models.task.taskType, (key, label) => ({key: key.toString(), value: langs(label)})),
    supportedOperators: [consts.FILTER_OPERATORS.EQUAL, consts.FILTER_OPERATORS.NOT_EQUAL],
    title: langs('TASK_TYPE'),
    type: SEARCH_TYPES.CHIPS
  }
];
