import PropTypes from 'prop-types';
import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import consts from '../../consts';
import hooks from '../../hooks';

function BooleanSearchOption({field}) {
  const [currentSearchQuery, setSearchQueryOption] = hooks.useSearchQueryFilter(
    field.key,
    consts.FILTER_OPERATORS.EQUAL
  );

  function onChange() {
    if (currentSearchQuery) {
      setSearchQueryOption(null);

      return;
    }

    setSearchQueryOption('true');
  }

  return (
    <FormControlLabel
      key={field.key}
      control={<Checkbox checked={Boolean(currentSearchQuery)} onChange={onChange} />}
      label={field.title}
    />
  );
}

BooleanSearchOption.propTypes = {
  field: PropTypes.object.isRequired
};

export default BooleanSearchOption;
