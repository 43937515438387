import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import * as Styled from './ListFilter.styles';

function ListFilter({label, multiple, onChange, options, value}) {
  const [showMenu, setShowMenu] = React.useState(false);

  function handleChange(event) {
    onChange(event.target.value);
    event.stopPropagation();
  }

  function toggle() {
    setShowMenu(!showMenu);
  }

  const classes = Styled.useStyles();

  const sortedOptions = _.sortBy(options, _.lowerCase);

  const getMenuItem = (option) => {
    if (_.isString(option)) {
      option = {key: option, value: option};
    }
    return (
      <MenuItem key={option.key} value={option.key} classes={{root: classes.menu}}>
        {option.value}
      </MenuItem>
    );
  };

  return (
    <Styled.Container>
      <Styled.Label onClick={() => setShowMenu(true)}>{label}</Styled.Label>
      <Select
        onClick={toggle}
        multiple={multiple}
        onChange={handleChange}
        onClose={() => setShowMenu(false)}
        open={showMenu}
        renderValue={_.stubString}
        value={value}
      >
        {_.map(sortedOptions, getMenuItem)}
      </Select>
    </Styled.Container>
  );
}

ListFilter.propTypes = {
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        value: PropTypes.string
      }),
      PropTypes.string
    ])
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired
};

export default ListFilter;
