import styled from 'styled-components';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import * as theme from '../theme';
import FormControl from '@material-ui/core/FormControl';

import BasePaper from './Base/Paper';

export const AutocompleteTextField = styled(TextField).attrs({
  InputLabelProps: {style: {fontSize: 14}},
  margin: 'none'
})`
  & .MuiInput-root {
    padding: 0 5px;
  }
  & .MuiInput-underline-341 {
    border-bottom: none;
  }
`;

export const AutoComplete = styled(Autocomplete).attrs({size: 'small'})`
  background-color: ${theme.backgrounds.primary};

  input {
    min-width: 0 !important;
  }
`;

export const Container = styled(FormControl)`
  width: 100%;
`;

export const Paper = styled(BasePaper).attrs({padding: 0})`
  background-color: ${theme.backgrounds.dark};
  min-width: max-content;
`;
