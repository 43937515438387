import styled from 'styled-components';

import TableCell from '@material-ui/core/TableCell';

import * as theme from '../../../theme';

export const HeaderCell = styled(TableCell)`
  color: ${theme.default.palette.text.secondary};
  text-transform: uppercase;
  padding: 8px;
`;

export const SubTitle = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Container = styled.div`
  margin: 30px 0;
  padding: 0 24px;
`;
