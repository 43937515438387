import styled from 'styled-components';

import * as theme from '../../theme';

export const Container = styled.div`
  background: ${theme.backgrounds.light};
  padding: ${(props) => (props.padding != null ? `${props.padding}rem` : '0.5rem')};

  & > hr {
    border: none;
    border-top: 1px ${theme.separators.light} solid;
    opacity: 0.7;
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;
