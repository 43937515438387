import _ from 'lodash';

import modelToQuery from '../../utils/agGridUtils/filterUtils/modelToQuery';
import queryUtils from '../../utils/agGridUtils/queryUtils';
import services from '../../services';

const DEFAULT_NAMESPACES_SORT_KEY = 'namespace_id';
const DEFAULT_SERVICES_SORT_KEY = 'name';
const DEFAULT_STACKS_SORT_KEY = 'name';

async function getNamespaces(api, request, searchQuery) {
  const query = queryUtils.getQueryParams(api, request, searchQuery, DEFAULT_NAMESPACES_SORT_KEY);
  const response = await services.services.namespaces.list(query);
  return queryUtils.parseResponse(response);
}

async function getNamespacesFilters(colDef) {
  return getFilterData('namespaces', colDef);
}

async function getServices(api, request, searchQuery) {
  const query = queryUtils.getQueryParams(api, request, searchQuery, DEFAULT_SERVICES_SORT_KEY);
  const response = await services.services.services.list(query);
  return queryUtils.parseResponse(response);
}

async function getServicesFilters(colDef) {
  return getFilterData('services', colDef);
}

async function getStacks(api, request, searchQuery) {
  const query = queryUtils.getQueryParams(api, request, searchQuery, DEFAULT_STACKS_SORT_KEY);
  const response = await services.services.stacks.list(query);
  return queryUtils.parseResponse(response);
}

async function getStacksFilters(colDef) {
  return getFilterData('stacks', colDef);
}

async function getFilterData(serviceName, colDef) {
  const filterModel = colDef.gridApi.getFilterModel();
  const filterSearchQuery = modelToQuery.getQuery(colDef.gridApi, _.omit(filterModel, colDef.colId));
  const searchParams = new URLSearchParams(filterSearchQuery);
  searchParams.set('groupBy', colDef.colId);

  const {
    data: {items}
  } = await services.services[serviceName].count(searchParams);
  return _.map(items, ({key: [name]}) => name);
}

export default {
  getNamespaces,
  getNamespacesFilters,
  getServices,
  getServicesFilters,
  getStacks,
  getStacksFilters
};
