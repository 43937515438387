import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import enums from './enums';

import ConfigurationForm from './ConfigurationForm';
import ConfigurationNavigation from './ConfigurationNavigation';
import ProgressBar from '../Base/Progressbar';

import * as Styled from './Configuration.styles';

const DEFAULT_DISPLAY_SETTINGS = {
  showAddNewKeyDefaultValue: true,
  showAllLabel: true,
  showConfigurationKeySearch: false,
  showCreatedByCol: true,
  showEmptyFilter: false,
  showInheritCol: true,
  showLastUpdateCol: true,
  titleizeSubCategories: false
};

function Configuration({
  categories,
  configuration,
  displaySettings,
  extendedTypes,
  getAffectedEntities,
  getConfigurationKeys,
  isLoading,
  labelDefinitions,
  onAddConfigurationKey,
  onChangeCategory,
  onChangeShowTemplatedValues,
  onDeleteConfigurationKeyValue,
  onGetConfigurationKeyVersions,
  onSaveConfiguration,
  permissionFuncs,
  selectedCategory,
  showTemplatedValues
}) {
  const mergedDisplaySettings = _.assign({}, DEFAULT_DISPLAY_SETTINGS, displaySettings);
  const levels = [enums.LEVELS.ALL, ..._.map(labelDefinitions, 'label')];
  const selectedValues = _.map(labelDefinitions, (labelDefinition) =>
    _.pick(labelDefinition, ['defaultPermissionsFunc', 'key', 'label', 'permissionsFunc', 'value'])
  );

  return (
    <Styled.Container>
      <ProgressBar isLoading={isLoading} />
      <Styled.MainGrid container>
        <ConfigurationNavigation displaySettings={mergedDisplaySettings} labelDefinitions={labelDefinitions} />
        <Styled.ScrollableContainer item xs={9}>
          <ConfigurationForm
            categories={categories}
            configuration={configuration}
            displaySettings={mergedDisplaySettings}
            extendedTypes={extendedTypes}
            getAffectedEntities={getAffectedEntities}
            getConfigurationKeys={getConfigurationKeys}
            isLoading={isLoading}
            levels={levels}
            onAddConfigurationKey={onAddConfigurationKey}
            onDeleteConfigurationKeyValue={onDeleteConfigurationKeyValue}
            onGetConfigurationKeyVersions={onGetConfigurationKeyVersions}
            onChangeCategory={onChangeCategory}
            onChangeShowTemplatedValues={onChangeShowTemplatedValues}
            onSaveConfiguration={onSaveConfiguration}
            permissionFuncs={permissionFuncs}
            selectedCategory={selectedCategory}
            selectedValues={selectedValues}
            showTemplatedValues={showTemplatedValues}
          />
        </Styled.ScrollableContainer>
      </Styled.MainGrid>
    </Styled.Container>
  );
}

Configuration.propTypes = {
  categories: PropTypes.array.isRequired,
  configuration: PropTypes.array.isRequired,
  displaySettings: PropTypes.shape({
    showAddNewKeyDefaultValue: PropTypes.bool,
    showAllLabel: PropTypes.bool,
    showConfigurationKeySearch: PropTypes.bool,
    showCreatedByCol: PropTypes.bool,
    showEmptyFilter: PropTypes.bool,
    showInheritCol: PropTypes.bool,
    showLastUpdateCol: PropTypes.bool,
    titleizeSubCategories: PropTypes.bool
  }),
  extendedTypes: PropTypes.objectOf(PropTypes.elementType),
  getAffectedEntities: PropTypes.func.isRequired,
  getConfigurationKeys: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  labelDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      options: PropTypes.array.isRequired,
      permissionsFunc: PropTypes.func,
      value: PropTypes.object,
      width: PropTypes.number.isRequired
    })
  ).isRequired,
  onAddConfigurationKey: PropTypes.func,
  onChangeCategory: PropTypes.func.isRequired,
  onChangeShowTemplatedValues: PropTypes.func,
  onDeleteConfigurationKeyValue: PropTypes.func.isRequired,
  onGetConfigurationKeyVersions: PropTypes.func.isRequired,
  onSaveConfiguration: PropTypes.func.isRequired,
  permissionFuncs: PropTypes.shape({
    create: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    read: PropTypes.func.isRequired
  }),
  selectedCategory: PropTypes.string.isRequired,
  showTemplatedValues: PropTypes.bool
};

export default Configuration;
