import React from 'react';

import CompactMode from './CompactMode/CompactModeView';
import HeadCharts from '../../components/Charts/HeadCharts/HeadCharts';

import models from '../../models';
import services from '../../services';

function TenantsView() {
  return (
    <div>
      <HeadCharts
        countApi={services.tenants.count}
        bars={[
          {field: models.tenant.fields.TASK_ACTIVITY_STATUS, title: 'STATUS', translate: true},
          {field: models.tenant.fields.APPLICATION_VERSION_MAJOR, title: 'APPLICATION_VERSION_MAJOR'},
          {field: models.tenant.fields.FIRMWARE_VERSION_ID, title: 'FIRMWARE_VERSION'},
          {field: models.tenant.fields.ENVIRONMENT_NAME, title: 'ENVIRONMENT'}
        ]}
      />

      <CompactMode />
    </div>
  );
}

TenantsView.propTypes = {};

export default TenantsView;
