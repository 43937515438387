import _ from 'lodash';

import consts from '../../../consts';

import {getMissingPermissions, hasPermission, hasPermissions, isEnabled} from './base';

import {envAwareHasPermission, hasAllPermission} from './services/common';
import cms from './services/cms';
import maestro from './services/maestro';
import mtms from './services/mtms';
import opsWeb from './services/opsWeb';
import rbac from './services/rbac';

const SERVICE_TO_CUSTOM_PROPS = {
  [consts.rbac.SERVICE_NAMES_FE.cms]: cms,
  [consts.rbac.SERVICE_NAMES_FE.maestro]: maestro,
  [consts.rbac.SERVICE_NAMES_FE.mtms]: mtms,
  [consts.rbac.SERVICE_NAMES_FE.opsWeb]: opsWeb,
  [consts.rbac.SERVICE_NAMES_FE.rbac]: rbac
};

function getDirectAccessorsByService(serviceName, serviceAccessors) {
  const customServicePropsGetter = _.get(SERVICE_TO_CUSTOM_PROPS, serviceName);
  return customServicePropsGetter ? customServicePropsGetter.getDirectAccessors(serviceAccessors) : {};
}

function getRbacAccessorsByService(serviceName, getRbacState, getRbacPermissions, allEnvs) {
  const boundHasPermission = (permission, env) =>
    !isEnabled(getRbacState(serviceName)) || hasPermission(serviceName, getRbacPermissions, permission, env);
  let accessors = {
    allEnvs,
    getMissingPermissions: (permissions, env) =>
      getMissingPermissions(serviceName, getRbacPermissions, permissions, env),
    getMissingPermissionsStr: (permissions, env) =>
      _.join(getMissingPermissions(serviceName, getRbacPermissions, permissions, env), ','),
    hasAllPermission: (permissions) => {
      return hasAllPermission(boundHasPermission, permissions, allEnvs);
    },
    hasConfigPermissions: (permissions, labels) =>
      !isEnabled(getRbacState(serviceName)) || envAwareHasPermission(boundHasPermission, permissions, labels, allEnvs),
    hasPermission: boundHasPermission,
    hasPermissions: (permissions, env) =>
      !isEnabled(getRbacState(serviceName)) || hasPermissions(serviceName, getRbacPermissions, permissions, env),
    isEnabled: () => isEnabled(getRbacState(serviceName)),
    permissionsList: consts.rbac.permissions[serviceName]
  };

  return _.assign(accessors, getDirectAccessorsByService(serviceName, accessors));
}

export function getRbacAccessors(serviceNames, getRbacState, getRbacPermissions, allEnvs) {
  return _.transform(
    serviceNames,
    (result, serviceName) =>
      (result[serviceName] = getRbacAccessorsByService(serviceName, getRbacState, getRbacPermissions, allEnvs)),
    {}
  );
}
