import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';

import context from '../../context';

class AxiosInterceptor extends React.Component {
  requestInterceptor = null;
  static contextType = context.User.UserContext;

  constructor(props) {
    super(props);
    this.handleRequest = this.handleRequest.bind(this);
    this.handleResponseError = this.handleResponseError.bind(this);
  }

  componentDidMount() {
    this.requestInterceptor = axios.interceptors.request.use(this.handleRequest);
    this.responseInterceptor = axios.interceptors.response.use((response) => response, this.handleResponseError);
  }

  componentWillUnmount() {
    axios.interceptors.response.eject(this.responseInterceptor);
    axios.interceptors.request.eject(this.requestInterceptor);
  }

  handleResponseError(error) {
    if (error.response.status === 401) {
      this.startAuthenticationFlow();
    } else {
      return Promise.reject(error);
    }
  }

  handleRequest(config) {
    const accessToken = this.context.accessToken;
    if (accessToken == null) {
      this.startAuthenticationFlow();
    }

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`
    };

    return config;
  }

  render() {
    return this.props.children;
  }

  startAuthenticationFlow() {
    window.location = `${process.env.REACT_APP_ARMIS_OPS_AUTH_URL || '/'}?prevUrl=${window.location.href}`;
  }
}

AxiosInterceptor.propTypes = {
  authState: PropTypes.object
};

export default AxiosInterceptor;
