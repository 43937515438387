import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../../localization/langs';
import services from '../../../../services';

import {Form, Paper} from '../../../../components/Base';
import ArtifactInput from '../../../../components/Base/inputs/ArtifactInput';
import MultipleChoiceInput from '../../../../components/Base/inputs/MultipleChoiceInput';

function TenantBranch({branch, setBranch, branchSelection, setBranchSelection, options}) {
  return (
    <Paper padding={2} style={{paddingBlock: '1em'}}>
      <Form.Label>{langs('BRANCH')}</Form.Label>
      <Form.Field style={{paddingTop: '1em'}}>
        <ArtifactInput
          onChange={setBranch}
          value={branch}
          getArtifact={services.tenants.artifact.getById}
          listArtifacts={services.tenants.artifact.listAll}
          isLatestBuild
          useObject
        />
        <MultipleChoiceInput onChange={setBranchSelection} options={options} value={branchSelection} />
      </Form.Field>
    </Paper>
  );
}

TenantBranch.propTypes = {
  branch: PropTypes.object,
  branchSelection: PropTypes.array,
  options: PropTypes.array.isRequired,
  setBranch: PropTypes.func.isRequired,
  setBranchSelection: PropTypes.func.isRequired
};

export default TenantBranch;
