import _ from 'lodash';
import React from 'react';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';

import consts from '../../consts';
import context from '../../context';
import langs from '../../localization/langs';
import services from '../../services';

import {Drawer} from '../../components/Base';
import ServiceLogsAction from '../Services/Deployments/actions/ServiceLogsAction';
import TaskCommands from '../../components/Tasks/TaskCommands/TaskCommands';
import TaskSection from '../../components/Tasks/TaskSection';
import useTasksView from './useTasksView';

import * as searchOptions from './searchOptions';
import * as Styled from './TasksView.styles';
import {SEARCH_TYPES} from '../../context/Search/utils';

function MultiTenant() {
  const searchContext = context.Search.useSearch();
  React.useEffect(() => {
    async function setSearchOptions() {
      const {data: users} = await services.tenants.getUsers();
      searchContext.resetSearchOptions([
        ...searchOptions.options,
        {
          key: 'userFullName',
          options: users.items.map(({fullName}) => ({key: fullName, value: fullName})),
          supportedOperators: [consts.FILTER_OPERATORS.LIKE],
          title: langs('TRIGGERED_BY'),
          type: SEARCH_TYPES.TEXT
        }
      ]);
    }
    setSearchOptions();
  }, []);

  const {
    closeDrawer,
    fetchTasks,
    onSetPage,
    onSetRowsPerPage,
    page,
    rowsPerPage,
    selectedTask,
    selectedTaskId,
    setSelectedTaskId,
    tasksCompleted,
    tasksInProgress,
    totalItems
  } = useTasksView(consts.API_PREFIX_BY_SERVICE_NAME.deployments);

  return (
    <div>
      <Drawer open={Boolean(selectedTask)} onClose={closeDrawer} withBackdrop={true}>
        <TaskCommands
          task={selectedTask}
          entityIdGetter={(commandStatus) => commandStatus.metadata.deployment_id}
          entityName={langs('DEPLOYMENTS')}
          onClose={closeDrawer}
          renderLabel={({metadata}) => (
            <Tooltip title={metadata.service_name}>
              <Styled.Chip>{metadata.service_name}</Styled.Chip>
            </Tooltip>
          )}
          apiPrefix={consts.API_PREFIX_BY_SERVICE_NAME.deployments}
          fetchOutput={(command, taskId) => services.services.tasks.getOutput(command.metadata.deployment_id, taskId)}
          sectionGetter={({metadata}) => `${metadata.cluster_name} / ${metadata.namespace_name}`}
          tableRoute="services.main"
          outputTitleGetter={(command) => _.get(command, 'metadata.service_name')}
          disableReplay
        >
          <Styled.LogsButton
            dialog={ServiceLogsAction}
            icon={<DescriptionOutlinedIcon />}
            onClick={(commandStatus) => commandStatus.metadata.deployment_id}
            rowKey={'deploymentId'}
            text={langs('VIEW_POD_LOG')}
          />
        </TaskCommands>
      </Drawer>
      <div>
        {!_.isEmpty(tasksInProgress) && (
          <TaskSection
            isActive={true}
            onChange={fetchTasks}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={setSelectedTaskId}
            tasks={tasksInProgress}
            statusLabel={langs('IN_PROGRESS')}
            entityName={langs('DEPLOYMENTS')}
          />
        )}
        <TaskSection
          isActive={false}
          selectedTaskId={selectedTaskId}
          setSelectedTaskId={setSelectedTaskId}
          tasks={tasksCompleted}
          statusLabel={langs('COMPLETED_RECENTLY')}
          entityName={langs('DEPLOYMENTS')}
        />
      </div>
      <div>
        <TablePagination
          component={Styled.PaginationContainer}
          count={totalItems}
          page={page}
          onPageChange={onSetPage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onSetRowsPerPage}
        />
      </div>
    </div>
  );
}

export default MultiTenant;
