import styled from 'styled-components';

import MuiChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MuiDeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiPauseIcon from '@material-ui/icons/Pause';
import MuiPlayArrowIcon from '@material-ui/icons/PlayArrow';
import MuiReplayIcon from '@material-ui/icons/Replay';
import MuiTocIcon from '@material-ui/icons/Toc';
import {Grid} from '@material-ui/core';

import * as theme from '../../../../theme';

export const Container = styled(Grid).attrs({
  container: true,
  item: true,
  justifyContent: 'space-between',
  spacing: 1,
  xs: 12
})`
  margin-bottom: 10px;
`;

export const ActionsButtons = styled(Grid).attrs({item: true})``;

export const IconButton = styled(MuiIconButton).attrs({size: 'small'})`
  background-color: ${theme.backgrounds.secondary};
  margin-left: 10px;
`;

export const BackButton = styled(MuiIconButton).attrs({size: 'small'})`
  background-color: ${theme.backgrounds.secondary};
  height: 30px;
  width: 30px;
`;

export const PauseIcon = styled(MuiPauseIcon)`
  font-size: 24px;
`;

export const PlayArrowIcon = styled(MuiPlayArrowIcon)`
  font-size: 24px;
`;

export const TocIcon = styled(MuiTocIcon)`
  font-size: 24px;
`;

export const ReplayIcon = styled(MuiReplayIcon)`
  font-size: 24px;
`;

export const ChevronLeftIcon = styled(MuiChevronLeftIcon)`
  font-size: 24px;
`;

export const DeleteRoundedIcon = styled(MuiDeleteRoundedIcon)`
  font-size: 24px;
`;
