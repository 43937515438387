import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../../localization/langs';

import {Form, Input} from '../../../../components/Base';

import * as Styled from '../ServiceForm.styles';
import * as validations from '../validations';

function ServiceName({value, onChange, isDisabled = false}) {
  function isShowError() {
    if (_.isEmpty(value)) {
      return true;
    }
    return !validations.validateServiceName(value);
  }

  return (
    <Form.Field>
      <Form.Label>{langs('SERVICE_NAME')}</Form.Label>
      <Form.FieldWrapper>
        <Input
          disabled={isDisabled}
          type="text"
          required
          error={isShowError()}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </Form.FieldWrapper>
      {isShowError() && <Styled.Error>{langs('ERROR_INVALID_SERVICE_NAME')}</Styled.Error>}
    </Form.Field>
  );
}

ServiceName.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default ServiceName;
