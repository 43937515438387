import * as configurationV2 from './configurationV2';
import * as containers from './containers';
import * as deployments from './deployments';
import * as environments from './environments';
import * as artifacts from './artifacts';
import * as logs from './logs';
import * as namespaces from './namespaces';
import * as services from './services';
import * as stacks from './stacks';
import * as tasks from './tasks';
import * as teams from './teams';

export default {
  artifacts,
  configurationV2,
  containers,
  deployments,
  environments,
  logs,
  namespaces,
  services,
  stacks,
  tasks,
  teams
};
