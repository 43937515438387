import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './Task.styles';

function Status({count, text, iconComponent: IconComponent}) {
  return (
    <div>
      {count ? (
        <Styled.TaskStatus>
          <IconComponent />
          {count}
          {text}
        </Styled.TaskStatus>
      ) : null}
    </div>
  );
}

Status.propTypes = {
  count: PropTypes.number,
  iconComponent: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired
};

export default Status;
