import client from '../client';

export async function list() {
  return client.get('/cms/api/v1/beacons?status%5Beq%5D=ONLINE&orderBy=lastSeen:desc&length=-1');
}

export async function activate(
  beaconId,
  collectorNumber,
  tenantId,
  networkParams,
  ipmiParams,
  wifiParams,
  lteParams,
  clusterParams
) {
  return client.post(`/cms/api/v1/tenants/${tenantId}/collectors/_activate`, {
    beaconId,
    clusterParams,
    collectorNumber,
    ipmiParams,
    lteParams,
    networkParams,
    wifiParams
  });
}

export async function reroute(beaconId, env) {
  return client.post(`/cms/api/v1/beacons/${beaconId}/_reroute`, {
    env
  });
}
