import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../../localization/langs';

import {Form, Input} from '../../../../components/Base';
import * as validations from '../validations';
import * as Styled from '../ServiceForm.styles';

function ServiceDescription({value, onChange}) {
  function isShowError() {
    if (_.isEmpty(value)) {
      return true;
    }
    return !validations.validateServiceDescription(value);
  }
  return (
    <Form.Field>
      <Form.Label>{langs('SERVICE_DESCRIPTION')}</Form.Label>
      <Form.FieldWrapper>
        <Input type="text" required error={isShowError()} value={value} onChange={(e) => onChange(e.target.value)} />
      </Form.FieldWrapper>
      {isShowError() && <Styled.Error>{langs('ERROR_INVALID_SERVICE_DESCRIPTION')}</Styled.Error>}
    </Form.Field>
  );
}

ServiceDescription.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default ServiceDescription;
