import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import langs from '../../../../localization/langs';

import {Button} from '../../../../components/Base';

function StepBottomBar({disable, handleSubmit, index, setActiveStep, size}) {
  const [isLoading, setIsLoading] = React.useState(false);

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  async function submit(event) {
    setIsLoading(true);
    await handleSubmit(event);
    setIsLoading(false);
  }

  return (
    <Box sx={{mb: 2}}>
      <div>
        {index === size - 1 ? (
          <Button variant="contained" onClick={submit} sx={{mr: 1, mt: 1}} disabled={disable} loading={isLoading}>
            {langs('FINISH')}
          </Button>
        ) : (
          <Button variant="contained" onClick={handleNext} sx={{mr: 1, mt: 1}} disabled={disable}>
            {langs('CONTINUE')}
          </Button>
        )}
        <Button disabled={index === 0} onClick={handleBack} sx={{mr: 1, mt: 1}}>
          {langs('BACK')}
        </Button>
      </div>
    </Box>
  );
}

StepBottomBar.propTypes = {
  disable: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired
};

export default StepBottomBar;
