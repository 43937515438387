import PropTypes from 'prop-types';
import React from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import IconedLabel from './IconedLabel';

const STATUS = {
  error: {color: 'error', icon: <CancelIcon />},
  success: {color: 'success', icon: <CheckIcon />},
  undefined: {color: 'info', icon: <FiberManualRecordIcon />},
  warning: {color: 'warning', icon: <ErrorIcon />}
};

function StatusLine({status, children, icon, disabled = false, ...props}) {
  let currentStatus = STATUS[status] || STATUS[undefined];

  return (
    <IconedLabel color={currentStatus.color} icon={icon || currentStatus.icon} disabled={disabled} {...props}>
      {children}
    </IconedLabel>
  );
}

StatusLine.STATUS = STATUS;

StatusLine.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  status: PropTypes.oneOf(Object.keys(STATUS))
};

export default StatusLine;
