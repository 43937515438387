import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import langs from '../../../localization/langs';

import ActionColumn from './ActionColumn';
import AGTable from '../../AGTable/Table';
import Column from '../../AGTable/Column';
import {ConfirmationModal} from '../../Base';
import DateColumn from './DateColumn';
import ValueColumn from './ValueColumn';

function ConfigurationVersions({keyName, keyType, extendedTypes, getVersions, isOpen, rollbackKeyVersion, onClose}) {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const selectedVersion = useRef();

  const getRows = React.useCallback(async (params) => {
    const versions = await getVersions();
    const rowCount = _.size(versions);
    const rowData = versions || [];
    params.success({rowCount, rowData});
  }, []);

  function getContextMenuItems(params, version) {
    if (version.isDeleted) {
      return [];
    }
    return [
      {
        action: () => {
          selectedVersion.current = version;
          setIsConfirmModalOpen(true);
        },
        icon: 'outline_revert_white_24dp',
        name: langs('ROLLBACK')
      }
    ];
  }

  function close() {
    selectedVersion.current = null;
    setIsConfirmModalOpen(false);
  }

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
        <DialogContent>
          <AGTable
            rowIdField="id"
            data={getRows}
            pagination
            title={`${keyName} ${langs('VERSIONS')}`}
            toolPanels={[]}
            getContextMenuItems={getContextMenuItems}
            filterModel={{}}
          >
            <Column colId="creationDate" render={DateColumn} title={langs('CREATION_DATE')} value={_.identity} />
            <Column colId="level" title={langs('INHERITANCE_LEVEL')} value="level" />
            <Column
              colId="value"
              render={ValueColumn}
              title={langs('VALUE')}
              value={_.identity}
              renderProps={{extendedTypes, type: keyType}}
              tooltip={({value}) => JSON.stringify(value)}
            />
            <Column colId="createdBy" title={langs('CREATED_BY')} value="createdBy" />
            <Column colId="action" render={ActionColumn} title={langs('ACTION')} value={_.identity} />
          </AGTable>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>
            {langs('CLOSE')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationModal
        open={isConfirmModalOpen}
        description={`${langs('ROLLBACK')} key ${keyName} to ${JSON.stringify(selectedVersion.current?.value)}`}
        onConfirm={async () => {
          await rollbackKeyVersion(selectedVersion.current.value);
          close();
        }}
        onCancel={close}
      />
    </>
  );
}

ConfigurationVersions.propTypes = {
  extendedTypes: PropTypes.objectOf(PropTypes.elementType),
  getVersions: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  keyName: PropTypes.string.isRequired,
  keyType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  rollbackKeyVersion: PropTypes.func
};

export default ConfigurationVersions;
