import styled from 'styled-components';

import * as theme from '../../theme';

import Input from './Input';

export const StyledInput = styled(Input)`
  margin-bottom: 20px;
  border-bottom: ${({$isError}) => ($isError ? `1px ${theme.statusColors.error} solid` : undefined)};
`;
