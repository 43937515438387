import styled, {css} from 'styled-components';

import * as theme from '../../theme';

function selectedChipStyles() {
  return css`
    background-color: ${theme.chipBackgrounds.primary};
  `;
}

export const Group = styled.div`
  display: flex;
  column-gap: 5px;
  padding: 5px 0;
  overflow: ${({isScrollable}) => (isScrollable ? 'auto' : 'visible')};
  position: relative;
  right: 5px;
  flex-wrap: wrap;
`;

export const ChipButton = styled.button`
  color: ${theme.navbar.text};
  background: unset;
  border: 0;
  padding: 4px;
  margin: 2px;
  font-family: inherit;
  font-size: 14px;
  border-radius: 15px;
  white-space: nowrap;
  outline: none;
  user-select: none;
  &:hover {
    background-color: ${theme.chipBackgrounds.primary}50;
  }
  ${({isSelected}) => isSelected && selectedChipStyles()}
`;
