import PropTypes from 'prop-types';
import React from 'react';

import useVisibilitySensor from '@rooks/use-visibility-sensor';

import langs from '../../localization/langs';

import Button from './Button';

import * as Styled from './InfiniteLoader.styles';

function InfiniteLoader({onLoadMore}) {
  const loadingNode = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const {isVisible} = useVisibilitySensor(loadingNode, {
    intervalCheck: false,
    partialVisibility: true,
    resizeCheck: true,
    scrollCheck: true
  });

  React.useEffect(() => {
    if (isVisible) {
      setIsLoading(true);
      onLoadMore()
        .then((result) => {
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [isVisible]);

  return (
    <Styled.Container ref={loadingNode}>
      <Button onClick={onLoadMore} size="large" disabled={isLoading}>
        {isLoading ? langs('LOADING') : langs('LOAD_MORE_CONTENT')}
      </Button>
    </Styled.Container>
  );
}

InfiniteLoader.propTypes = {
  onLoadMore: PropTypes.func.isRequired
};

export default InfiniteLoader;
