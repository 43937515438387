import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import useDestroyMenuItem from './useDestroyMenuItem';

import {TooltipMenuItem} from '../../Base';

function DestroyMenuItem({tenantId, tenantName, branch}) {
  const [getDef, dialog] = useDestroyMenuItem();
  const tenant = {applicationVersion: branch, tenantId, tenantName};
  const def = getDef(tenant);

  const disabled = def.disabled || _.isEmpty(branch);

  return (
    <TooltipMenuItem
      dialog={dialog}
      disabled={disabled}
      icon={<DeleteOutlineIcon />}
      name={def.name}
      onClick={def.action}
      tooltip={def.tooltip}
    />
  );
}

DestroyMenuItem.propTypes = {
  branch: PropTypes.string,
  tenantId: PropTypes.string.isRequired,
  tenantName: PropTypes.string.isRequired
};

export default DestroyMenuItem;
