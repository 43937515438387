import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';

import consts from '../../consts';
import context from '../../context';
import langs from '../../localization/langs';
import models from '../../models';
import utils from '../../utils';

import BranchTypeSelector from './Parts/BranchTypeSelector';
import TaskDialogActions from './TaskDialogActions';
import {TaskParametersForm, ProgressBar} from '../Base';

import * as Styled from './Select.styles';

function getDefaultBranchTypeSelection() {
  const env = utils.urlUtils.getCurrentEnv();
  if (env.startsWith(consts.envs.CLUSTER_PREFIXES.PROD)) {
    return [models.staticData.ARTIFACT.BRANCH_TYPES.RELEASE];
  }
  if (env.startsWith(consts.envs.CLUSTER_PREFIXES.QA)) {
    return [models.staticData.ARTIFACT.BRANCH_TYPES.RELEASE, models.staticData.ARTIFACT.BRANCH_TYPES.RELEASE_CANDIDATE];
  }
  return _.values(models.staticData.ARTIFACT.BRANCH_TYPES);
}

function SelectVersion({
  setDialogTitle,
  closeModal,
  onBack,
  onNext,
  setValue = _.stubFalse,
  onClick = _.stubFalse,
  currentVersion,
  parametersValues,
  setParametersValues,
  parameters,
  listArtifactsApi,
  getArtifactByBranchApi
}) {
  const [artifacts, setArtifacts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState('');
  const [branchTypes, setBranchTypes] = React.useState(getDefaultBranchTypeSelection());
  const [selected, setSelected] = React.useState(null);
  const notifications = context.Notifications.useNotifications();

  async function listArtifacts() {
    setIsLoading(true);
    let artifacts = null;
    let currentArtifact = null;
    try {
      [{data: artifacts}, {data: currentArtifact}] = await Promise.all([
        listArtifactsApi({branchTypes, like: `%${searchValue.toLowerCase()}%`}),
        getArtifactByBranchApi(currentVersion)
      ]);
    } catch (e) {
      console.error(e);
      notifications.error(e.message);
      return;
    } finally {
      setIsLoading(false);
    }

    if (_.isEmpty(currentArtifact.items)) {
      setArtifacts(artifacts.items);
    } else {
      setArtifacts(_.uniqBy([currentArtifact.items[0], ...artifacts.items], 'id'));
    }
  }

  React.useEffect(() => {
    listArtifacts();
  }, [searchValue, branchTypes]);

  React.useEffect(function applyDialogTitle() {
    setDialogTitle(langs('SELECT_VERSION_FOR_DEPLOYMENT'));
  });

  function renderListItemContent({component, branch, buildId}) {
    return (
      <Styled.VersionItemContent>
        {!_.isEmpty(component) && <Styled.VersionSegment>{component} /</Styled.VersionSegment>}
        <Styled.VersionSegment>{branch}</Styled.VersionSegment>
        <Styled.VersionSegment>#{buildId}</Styled.VersionSegment>
        {branch === currentVersion && (
          <Styled.TenantVersionIndicator>- {langs('TENANT_VERSION')}</Styled.TenantVersionIndicator>
        )}
      </Styled.VersionItemContent>
    );
  }

  return (
    <div>
      <Styled.DialogContent>
        <FormControl fullWidth={true}>
          <Styled.Input
            onChange={(event) => setSearchValue(event.target.value)}
            value={searchValue}
            placeholder={langs('SEARCH_FOR_VERSIONS')}
            startAdornment={
              <Styled.InputAdornment position="start">
                <SearchIcon fontSize={'inherit'} color={'inherit'} />
              </Styled.InputAdornment>
            }
          />
          <BranchTypeSelector setSelectedBranchTypes={setBranchTypes} selectedBranchTypes={branchTypes} />
          <ProgressBar isLoading={isLoading} />
          <Styled.CommandsList>
            <Styled.BackgroundColorContainer>
              <Styled.Commands>
                {artifacts.map((version, index) => (
                  <Styled.Command
                    key={index}
                    selected={version.id === selected}
                    onClick={() => {
                      setSelected(version.id);
                      setValue(JSON.stringify({value: version.id}));
                      onClick(version);
                    }}
                  >
                    <Styled.ListItemText secondary={renderListItemContent(version)} />
                  </Styled.Command>
                ))}
              </Styled.Commands>
            </Styled.BackgroundColorContainer>
          </Styled.CommandsList>
        </FormControl>
      </Styled.DialogContent>
      {!_.isEmpty(parameters) && !_.every(parameters, {hidden: true}) && (
        <TaskParametersForm
          parameters={parameters}
          parametersValues={parametersValues}
          setParametersValues={setParametersValues}
        />
      )}
      <TaskDialogActions onBack={onBack} onNext={onNext} onCancel={closeModal} isAllowedNextStep={Boolean(selected)} />
    </div>
  );
}

SelectVersion.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentVersion: PropTypes.string,
  getArtifactByBranchApi: PropTypes.func,
  listArtifactsApi: PropTypes.func,
  onBack: PropTypes.func,
  onClick: PropTypes.func,
  onNext: PropTypes.func.isRequired,
  parameters: PropTypes.array,
  parametersValues: PropTypes.object,
  setDialogTitle: PropTypes.func.isRequired,
  setParametersValues: PropTypes.func,
  setValue: PropTypes.func
};

export default SelectVersion;
