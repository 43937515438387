import _ from 'lodash';

import modelToQuery from './filterUtils/modelToQuery';
import orderByUtils from './orderByUtils';

function getQueryParams(api, request, searchQuery, defaultSortKey) {
  const searchQueryOrderBy = orderByUtils.getSearchQueryOrderBy(request.sortModel, defaultSortKey);
  const searchQueryFilters = modelToQuery.getQuery(api, request.filterModel);

  return {
    from: request.startRow,
    length: request.endRow - request.startRow,
    orderBy: searchQueryOrderBy,
    searchQuery: searchQuery ? `${searchQuery}&${searchQueryFilters}` : searchQueryFilters
  };
}

function getUrlSearchParams(api, request, defaultSortKey) {
  const queryParams = getQueryParams(api, request, null, defaultSortKey);

  const urlSearchParams = new URLSearchParams(queryParams.searchQuery);
  urlSearchParams.set('orderBy', queryParams.orderBy);
  urlSearchParams.set('from', _.toString(queryParams.from));
  urlSearchParams.set('length', _.toString(queryParams.length));

  return urlSearchParams;
}

function parseResponse(response) {
  const rowCount = _.toNumber(response.data.count) || 0;
  const rowData = response.data.items || [];
  return {rowCount, rowData};
}

export default {
  getQueryParams,
  getUrlSearchParams,
  parseResponse
};
