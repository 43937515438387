import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import actionsUtils from './actionsUtils';
import context from '../../../../context';
import langs from '../../../../localization/langs';

import AffectedEntities from '../../../../components/Configuration/parts/AffectedEntities';

function isInvalidService({missingSecrets}) {
  return !_.isEmpty(missingSecrets);
}

function ServiceBaseAction({config, close, open, rows}) {
  const [services, setServices] = React.useState([]);

  const history = useHistory();
  const {getUrl} = context.Navigation.useNavigation();

  const performAction = React.useCallback(async () => {
    const result = await config.func(services);
    if (config.taskRedirect) {
      history.push(getUrl('services.tasks', {}, {taskId: result.data.id}));
    } else {
      closeFunc();
    }
  }, [config.func, services]);

  React.useEffect(() => {
    if (open && !_.isEmpty(rows)) {
      actionsUtils.fetchServices(rows).then(setServices);
    }
  }, [open, rows]);

  const closeFunc = React.useCallback(() => {
    setServices([]);
    close();
  }, [close, setServices]);

  function onDialogClose(event, reason) {
    if (reason !== 'backdropClick') {
      return;
    }
    closeFunc();
  }

  return (
    <Dialog open={open} onClose={onDialogClose} fullWidth>
      <DialogTitle>{langs('DEPLOYMENTS_ACTION_DIALOG_TITLE', {action: config.action})}</DialogTitle>
      {!_.isNull(services) && _.isEmpty(services) ? (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      ) : (
        <>
          {config.content}
          <AffectedEntities
            value={_.map(services, (item) =>
              _.pick(item, ['environmentName', 'namespaceName', 'stackName', 'serviceName'])
            )}
          />
          <DialogActions>
            <Button onClick={closeFunc}>{langs('BUTTON_CANCEL')}</Button>
            <Button
              disabled={config.disabled || _.some(services, isInvalidService) || _.isNull(services)}
              color="primary"
              variant="contained"
              onClick={performAction}
            >
              {langs('BUTTON_OK')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

ServiceBaseAction.propTypes = {
  close: PropTypes.func.isRequired,
  config: PropTypes.shape({
    action: PropTypes.string,
    content: PropTypes.any,
    disabled: PropTypes.bool,
    func: PropTypes.func,
    taskRedirect: PropTypes.bool
  }),
  open: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      deploymentId: PropTypes.string,
      searchParams: PropTypes.instanceOf(URLSearchParams)
    })
  )
};

export default ServiceBaseAction;
