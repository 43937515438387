import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';

import consts from '../../consts';
import hooks from '../../hooks';
import langs from '../../localization/langs';

import * as Styled from './NullComparisonSearchOption.styles';

function NullComparisonSearchOption({field}) {
  const [currentSearchQuery, setSearchQueryOption, filterOperator, setFilterOperator] = hooks.useSearchQueryFilter(
    field.key,
    consts.FILTER_OPERATORS.NOT_EQUAL,
    undefined,
    true
  );

  function onChange() {
    if (!_.isEmpty(currentSearchQuery)) {
      setSearchQueryOption(null);
      return;
    }
    setSearchQueryOption('null');
  }

  function onFilterChange() {
    if (filterOperator === consts.FILTER_OPERATORS.EQUAL) {
      setFilterOperator(consts.FILTER_OPERATORS.NOT_EQUAL);
      return;
    }
    setFilterOperator(consts.FILTER_OPERATORS.EQUAL);
  }

  function getFilterText() {
    if (filterOperator === consts.FILTER_OPERATORS.NOT_EQUAL) {
      return langs('IS');
    }
    return langs('IS_NOT');
  }

  return (
    <Styled.Container>
      <Styled.NoMarginFormControlLabel
        control={<Checkbox checked={!_.isEmpty(currentSearchQuery)} onChange={onChange} />}
      />
      <Styled.Filter onClick={onFilterChange}>{getFilterText()}</Styled.Filter>
      <Styled.Title>{field.title}</Styled.Title>
    </Styled.Container>
  );
}

NullComparisonSearchOption.propTypes = {
  field: PropTypes.object.isRequired
};

export default NullComparisonSearchOption;
