import _ from 'lodash';
import client from './client';
import task from '../models/task';

export async function listTasks(from, length, apiPrefix, qs) {
  return client.get(`/${apiPrefix}/api/v1/tasks?from=${from}&length=${length}&${qs}`);
}

export async function listCommandStatus(taskId, apiPrefix) {
  return client.get(`/${apiPrefix}/api/v1/tasks/${taskId}/commands_status`);
}

export async function pauseTask(taskId, apiPrefix) {
  return client.patch(`/${apiPrefix}/api/v1/tasks/${taskId}`, {status: task.taskStatus.PAUSED});
}

export async function continueTask(taskId, apiPrefix) {
  return client.patch(`/${apiPrefix}/api/v1/tasks/${taskId}`, {status: task.taskStatus.RUNNING});
}

export async function cancelTask(taskId, apiPrefix) {
  return client.patch(`/${apiPrefix}/api/v1/tasks/${taskId}`, {status: task.taskStatus.CANCELED});
}

export async function create(
  hostIds,
  commandId,
  commandType,
  apiPrefix,
  slidingWindowSize = 20,
  hostIdsField = 'hostIds',
  configurationValue = null,
  parametersValues = {}
) {
  return client.post(`/${apiPrefix}/api/v1/tasks`, {
    commandId,
    commandType,
    configurationValue,
    failThreshold: 10,
    [hostIdsField]: hostIds,
    parametersValues: _.mapValues(parametersValues, JSON.stringify),
    slidingWindowSize
  });
}
