import _ from 'lodash';
import React from 'react';
import * as Router from 'react-router-dom';

import useOutsideClick from '@rooks/use-outside-click';

import consts from '../../../consts';
import context from '../../../context';
import hooks from '../../../hooks';

import AdvancedSearch from './AdvancedSearch';
import SimpleSearch from './SimpleSearch';

import * as Styled from './Search.styles';

function Search() {
  const [isShowAdvancedOptions, setIsShowAdvancedOptions] = React.useState(false);

  const location = Router.useLocation();
  const {searchOptions, resetSearchOptions, setOnTextSearch} = context.Search.useSearch();

  const ref = React.useRef();
  const inputRef = React.useRef();

  React.useEffect(() => {
    resetSearchOptions();
    setOnTextSearch(null);
    setIsShowAdvancedOptions(false);
  }, [location.pathname]);

  hooks.useHotKeys([`${consts.keyboard.META}+k`], () => {
    setIsShowAdvancedOptions(true);
    inputRef.current.focus();
    inputRef.current.setSelectionRange(0, inputRef.current.value.length);
  });

  hooks.useHotKeys([consts.keyboard.ESCAPE], () => {
    setIsShowAdvancedOptions(false);
  });

  useOutsideClick(ref, (event) => {
    if (event.target.className === 'MuiAutocomplete-option') {
      return;
    }
    setIsShowAdvancedOptions(false);
  });

  function toggleAdvancedOptions(onlyClose) {
    if (onlyClose) {
      setIsShowAdvancedOptions(false);
    } else {
      setIsShowAdvancedOptions(!isShowAdvancedOptions);
    }
  }

  return (
    <Styled.Container ref={ref}>
      <SimpleSearch ref={inputRef} onClick={toggleAdvancedOptions} />
      {isShowAdvancedOptions && !_.isEmpty(searchOptions) && <AdvancedSearch />}
    </Styled.Container>
  );
}

export default Search;
