import styled from 'styled-components';

import MuiButton from '@material-ui/core/Button';

export const Container = styled.div`
  margin: 10px 8px;
`;

export const Title = styled.div`
  font-size: 20px;
`;

export const Info = styled.div`
  display: flex;
  flex: 0 0 100%;
  padding: 8px 0;
`;

export const StopButton = styled(MuiButton).attrs({
  variant: 'contained'
})`
  :not(last-child) {
    margin-right: 8px;
  }
`;
