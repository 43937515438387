import styled from 'styled-components';

import {Collapse, Typography} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';

export const TitleTypography = styled(Typography)`
  margin-left: 5px;
`;

export const CollapseExpandLess = styled(ExpandLess)`
  margin-left: auto;
`;

export const CollapseExpandMore = styled(ExpandMore)`
  margin-left: auto;
`;

export const AdditionalSettingsCollapse = styled(Collapse)`
  margin-left: 10px;
  width: 97%;
`;
