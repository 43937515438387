import styled from 'styled-components';

import CollectorChip from '../../../../Base/CollectorChip';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Item = styled(CollectorChip)`
  margin-right: 5px;
`;
