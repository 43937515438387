import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import consts from '../../../consts';
import langs from '../../../localization/langs';

import * as Styled from './AdvancedSearchTab.styles';

const FILTER_OPERATOR_LABEL_IDS = {
  [consts.FILTER_OPERATORS.EQUAL]: 'IS',
  [consts.FILTER_OPERATORS.NOT_EQUAL]: 'IS_NOT',
  [consts.FILTER_OPERATORS.LIKE]: 'IS',
  [consts.FILTER_OPERATORS.NOT_LIKE]: 'IS_NOT'
};

function toggleOperator(current) {
  if (current === consts.FILTER_OPERATORS.LIKE) {
    return consts.FILTER_OPERATORS.NOT_LIKE;
  }
  if (current === consts.FILTER_OPERATORS.NOT_LIKE) {
    return consts.FILTER_OPERATORS.LIKE;
  }
  if (current === consts.FILTER_OPERATORS.EQUAL) {
    return consts.FILTER_OPERATORS.NOT_EQUAL;
  }
  return consts.FILTER_OPERATORS.EQUAL;
}

function TitleAndOperator({fieldKey, title, supportedOperators, filterOperator, setFilterOperator}) {
  function toggleFilterOperator() {
    if (_.isEmpty(supportedOperators)) {
      return;
    }
    const nextOperator = toggleOperator(filterOperator);
    setFilterOperator(fieldKey, nextOperator, filterOperator);
  }

  return (
    <Styled.TitleOperatorWrapper>
      <Styled.Title>{title}</Styled.Title>
      {!_.isEmpty(supportedOperators) && (
        <Styled.Operator
          variant="outlined"
          onClick={toggleFilterOperator}
          label={langs(FILTER_OPERATOR_LABEL_IDS[filterOperator])}
        />
      )}
    </Styled.TitleOperatorWrapper>
  );
}

TitleAndOperator.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  filterOperator: PropTypes.string.isRequired,
  setFilterOperator: PropTypes.func,
  supportedOperators: PropTypes.array,
  title: PropTypes.string.isRequired
};

export default TitleAndOperator;
