import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  ListItemText
} from '@material-ui/core';

import langs from '../../../../localization/langs';
import servicesApi from '../../../../services';
import {DEPLOYMENT_TYPES} from '../utils';

import {Form, Paper} from '../../../../components/Base';

import CollectorsDeployment from './CollectorsDeployment';
import MultiTenantDeployment from './MultiTenantDeployment';
import SingleTenantDeployment from './SingleTenantDeployment';

import * as Styled from './Common.styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
function DeploymentTypeSelector({
  servicesSelection,
  setServicesSelection,
  tenantsSelection,
  setTenantsSelection,
  containersSelection,
  setContainersSelection,
  setIsDeploymentsValid,
  deploymentTypesSelection,
  setDeploymentTypesSelection,
  isSquadDeployment,
  setIsSquadDeployment
}) {
  const [envs, setEnvs] = React.useState([]);
  const [tenants, setTenants] = React.useState([]);
  const [isDeployment, setIsDeployment] = React.useState(false);
  const [envsSelection, setEnvsSelection] = React.useState([]);

  React.useEffect(() => {
    fetchTenants(envsSelection).then(setTenants);
  }, [envsSelection, setTenants]);

  React.useEffect(() => {
    const isSquadDeploymentValid = isDeploymentSectionValid(isSquadDeployment, containersSelection);
    const isMTDeploymentValid = isDeploymentSectionValid(
      _.get(deploymentTypesSelection, [DEPLOYMENT_TYPES.MULTI_TENANT.value, 'checked'], false),
      servicesSelection
    );
    const isSTDeploymentValid = isDeploymentSectionValid(
      _.get(deploymentTypesSelection, [DEPLOYMENT_TYPES.SINGLE_TENANT.value, 'checked'], false),
      tenantsSelection,
      isSquadDeploymentValid
    );
    const isCollectorsDeploymentValid = isDeploymentSectionValid(
      _.get(deploymentTypesSelection, [DEPLOYMENT_TYPES.COLLECTORS.value, 'checked'], false),
      tenantsSelection
    );
    setIsDeploymentsValid(
      isDeploymentSectionValid(
        isDeployment,
        deploymentTypesSelection,
        isMTDeploymentValid && isSTDeploymentValid && isCollectorsDeploymentValid
      )
    );
  }, [
    isDeployment,
    isSquadDeployment,
    deploymentTypesSelection,
    servicesSelection,
    tenantsSelection,
    containersSelection
  ]);

  React.useEffect(() => {
    fetchEnvs().then(setEnvs);
  }, [setEnvs]);

  function isDeploymentSectionValid(checked, selection, additionalValidation = true) {
    return !checked || (checked && !_.isEmpty(selection) && additionalValidation);
  }

  function handleTypeChange(name, checked) {
    setDeploymentTypesSelection(
      _.assign({}, deploymentTypesSelection, {
        [name]: {...DEPLOYMENT_TYPES[name], checked: checked}
      })
    );
  }

  function handleEnvChange(event) {
    setEnvsSelection(event.target.value);
  }

  async function fetchEnvs() {
    const {
      data: {items}
    } = await servicesApi.services.deployments.getEnvs();
    return _.map(items, ({key}) => key[1]);
  }

  async function fetchTenants(envs) {
    let sq = 'environmentType=production';
    if (!_.isEmpty(envs)) {
      sq = `&environmentName=${envs}`;
    }
    const {
      data: {items}
    } = await servicesApi.tenants.getByQuery({length: -1, searchQuery: sq});

    return _.map(items, (item) => ({id: item.tenantId, tenantName: item.tenantName}));
  }

  return (
    <Paper>
      <Form.Field>
        <Styled.DeploymentSection>
          <FormControlLabel
            control={<Switch onChange={() => setIsDeployment(!isDeployment)} color="secondary" />}
            label={isDeployment ? langs('DEPLOYMENT_REQUIRED') : langs('DEPLOYMENT_NOT_REQUIRED')}
          />
        </Styled.DeploymentSection>
        {isDeployment && (
          <FormGroup>
            <Styled.DeploymentSection>
              <InputLabel>{langs('ENVIRONMENTS')}</InputLabel>
              <Select
                multiple
                displayEmpty
                value={envsSelection}
                onChange={handleEnvChange}
                label="Envs"
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>{langs('ALL')}</em>;
                  }

                  return selected.join(', ');
                }}
                MenuProps={MenuProps}
              >
                {envs.map((name) => (
                  <MenuItem key={name} value={name} style={{maxHeight: '40px'}}>
                    <Checkbox checked={envsSelection.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </Styled.DeploymentSection>
            <MultiTenantDeployment
              envsSelection={envsSelection}
              deploymentTypesSelection={deploymentTypesSelection}
              onChangeType={handleTypeChange}
              servicesSelection={servicesSelection}
              setServicesSelection={setServicesSelection}
            />
            <SingleTenantDeployment
              deploymentTypesSelection={deploymentTypesSelection}
              onChangeType={handleTypeChange}
              tenantsSelection={tenantsSelection}
              setTenantsSelection={setTenantsSelection}
              containersSelection={containersSelection}
              setContainersSelection={setContainersSelection}
              isSquadDeployment={isSquadDeployment}
              setIsSquadDeployment={setIsSquadDeployment}
              tenants={tenants}
            />
            <CollectorsDeployment
              deploymentTypesSelection={deploymentTypesSelection}
              onChangeType={handleTypeChange}
              tenantsSelection={tenantsSelection}
              setTenantsSelection={setTenantsSelection}
              tenants={tenants}
            />
          </FormGroup>
        )}
      </Form.Field>
    </Paper>
  );
}

DeploymentTypeSelector.propTypes = {
  containersSelection: PropTypes.array,
  deploymentTypesSelection: PropTypes.object,
  isSquadDeployment: PropTypes.bool.isRequired,
  servicesSelection: PropTypes.array,
  setContainersSelection: PropTypes.func.isRequired,
  setDeploymentTypesSelection: PropTypes.func,
  setIsDeploymentsValid: PropTypes.func.isRequired,
  setIsSquadDeployment: PropTypes.func.isRequired,
  setServicesSelection: PropTypes.func.isRequired,
  setTenantsSelection: PropTypes.func.isRequired,
  tenantsSelection: PropTypes.array.isRequired
};

export default DeploymentTypeSelector;
