import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../localization/langs';

import * as Styled from './ConfirmationModal.styles';

function ConfirmationModal({open, onCancel, onConfirm, title = langs('ARE_YOU_SURE'), description = null, children}) {
  async function onConfirmClick(event) {
    const result = await onConfirm(event);
    if (result === false) {
      return;
    }
    onCancel();
  }

  return (
    <Styled.Modal open={open} onClose={onCancel}>
      <h3>{title}</h3>
      {description && <p>{description}</p>}
      {children}
      <Styled.Navigation>
        <Styled.Button onClick={onCancel}>{langs('BUTTON_CANCEL')}</Styled.Button>
        <Styled.AsyncButton bold onClick={onConfirmClick}>
          {langs('BUTTON_YES')}
        </Styled.AsyncButton>
      </Styled.Navigation>
    </Styled.Modal>
  );
}

ConfirmationModal.propTypes = {
  description: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string
};

export default ConfirmationModal;
