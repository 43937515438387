import _ from 'lodash';
import React from 'react';
import {useParams} from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import hooks from '../../hooks';
import langs from '../../localization/langs';
import services from '../../services';

import CollectorsView from '../Collectors/CollectorsView';
import {Breadcrumb, Paper, Tabs} from '../../components/Base';
import TenantHeader from '../../components/Tenants/Parts/TenantHeader';
import TenantStats from '../../components/Tenants/Parts/TenantStats';
import TenantRelay from '../../components/Tenants/Parts/TenantRelay/TenantRelay';
import TenantVersion from '../../components/Tenants/Parts/TenantVersion';
import PreviewTabs from '../../components/Tenants/PreviewTabs';

import * as Styled from './Tenant.styles';

const MISSING_VALUE = '-';

const TABS = {
  COLLECTORS: {disabled: false, key: '1', title: langs('COLLECTORS')},
  HISTORY: {disabled: true, key: '2', title: langs('TASKS_HISTORY')},
  INFO: {disabled: false, key: '0', title: langs('INFO')},
  NOTES: {disabled: false, key: '3', title: langs('NOTES_AND_COMMENTS')},
  USER_CHANGES: {disabled: true, key: '4', title: langs('USER_CHANGES')}
};

function Tenant() {
  const params = useParams();
  const [selected, setSelected] = React.useState(TABS.INFO.key);
  const [result, , , reloadData] = hooks.useGetResult(() => services.tenants.getById(params.tenantId), [
    params.tenantId
  ]);

  if (_.isEmpty(result)) {
    return (
      <Container>
        <LinearProgress />
      </Container>
    );
  }

  const tenant = result;
  const breadcrumb = [{title: 'All Tenants', url: '/tenants'}, tenant.tenantName];

  const tenantSa = _.get(tenant, 'owner', MISSING_VALUE);
  const priority = _.get(tenant, 'operationalData.isVip', false);
  const hasNotes = _.has(tenant, 'note');

  return (
    <div>
      <Breadcrumb sections={breadcrumb} />
      <TenantHeader tenant={tenant} isFullViewLink={false} onUpdate={reloadData} />

      <Styled.Subheader>
        <span>
          {langs('STAGE')}: {tenant.stage}
        </span>
        <span>
          {langs('PRIORITY')}: {priority ? langs('VIP') : langs('REGULAR')}
        </span>
        <span>
          {langs('SA')}: {tenantSa}
        </span>
        <span>{hasNotes && langs('HAS_NOTES')}</span>
      </Styled.Subheader>

      {/* Stats */}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TenantStats tenant={tenant} />
        </Grid>
        <Grid item xs={4}>
          <TenantVersion tenant={tenant} onUpdate={reloadData} />
        </Grid>
        <Grid item xs={4}>
          <TenantRelay tenant={tenant} />
        </Grid>
      </Grid>

      <Tabs selected={selected} onChange={setSelected} style={{marginBottom: '1rem', marginTop: '2rem'}}>
        {_(TABS)
          .orderBy('key')
          .map((tab) => (
            <Tabs.Tab key={tab.key} id={tab.key} disabled={tab.disabled}>
              {tab.title}
            </Tabs.Tab>
          ))
          .value()}
      </Tabs>

      {selected === TABS.INFO.key && <PreviewTabs.Info tenant={tenant} />}

      {selected === TABS.COLLECTORS.key && (
        <Paper>
          <CollectorsView tenantId={params.tenantId} />
        </Paper>
      )}

      {selected === TABS.NOTES.key && <PreviewTabs.NotesAndComments tenant={tenant} onUpdate={reloadData} />}
    </div>
  );
}

export default Tenant;
