import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import consts from '../../../../../consts';

import ExternalLinkColumn from '../../../../AGTable/columns/ExternalLinkColumn';

function PullRequests({value}) {
  return _.map(value, (pr, index) => {
    return <ExternalLinkColumn key={index} value={pr} urlPrefix={consts.URL_PREFIX_GITHUB_PULL_REQUEST} />;
  });
}

PullRequests.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number)
};

export default PullRequests;
