import PropTypes from 'prop-types';
import React from 'react';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {Grid} from '@material-ui/core';

import command from '../../../../models/command';
import context from '../../../../context';
import langs from '../../../../localization/langs';

import * as Styled from './TaskDescription.styles';

function TaskDescription({id, commandType, creationDate, tableRoute}) {
  const {getUrl} = context.Navigation.useNavigation();

  function copyTaskUrl() {
    const navigationParent = tableRoute.split('.')[0];
    const url = getUrl(`${navigationParent}.tasks`, {}, {taskId: id});
    navigator.clipboard.writeText(`${window.location.host}${url}`);
  }

  return (
    <Grid item xs={12}>
      <Typography color={'textSecondary'} variant={'body2'}>
        {`${langs('STARTED')} `}
        {formatDistanceToNow(new Date(creationDate), {
          addSuffix: true
        })}
      </Typography>
      <Typography color={'textPrimary'} variant={'h5'}>
        {command.getTextByType(commandType)}
      </Typography>
      <Styled.Container>
        <Typography color={'textSecondary'} variant={'body2'}>
          {langs('TASK_ID')}: {id}
        </Typography>
        <Tooltip title={langs('COPY_TASK_URL')}>
          <Styled.CopyButton onClick={copyTaskUrl} />
        </Tooltip>
      </Styled.Container>
    </Grid>
  );
}

TaskDescription.propTypes = {
  commandType: PropTypes.number,
  creationDate: PropTypes.string,
  id: PropTypes.number,
  tableRoute: PropTypes.string
};

export default TaskDescription;
