import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import consts from '../../../../consts';
import hooks from '../../../../hooks';
import langs from '../../../../localization/langs';
import services from '../../../../services';

import formUtils from '../newTenantFormUtils';

import {Form} from '../../../../components/Base';

const CLUSTERS_REGEX = `(${_(consts.envs.CLUSTER_PREFIXES)
  .values()
  .join('|')}).*`;

function TenantEnv({cluster, defaultClusterName, setCluster, setSelectedPrefix}) {
  const [clustersResponse, isLoadingClusters] = hooks.useGetResult(
    () => services.tenants.configuration.getClusters(),
    []
  );

  const clusters = _.sortBy(
    _.filter(
      _.get(clustersResponse, 'items'),
      (cluster) => cluster.name.match(CLUSTERS_REGEX) && cluster.name !== 'dev'
    ),
    'name'
  );

  React.useEffect(() => {
    if (!defaultClusterName) {
      return;
    }
    let defaultCluster = _.find(clusters, {name: defaultClusterName});
    if (!defaultCluster) {
      return;
    }
    formUtils.handleClusterChanged(defaultCluster, setCluster, setSelectedPrefix);
  }, [clusters]);

  return (
    <Form.Field>
      <Form.Label>{langs('ENVIRONMENT')}</Form.Label>
      {isLoadingClusters ? (
        <Select disabled value="0">
          <MenuItem value="0">{langs('LOADING')}</MenuItem>
        </Select>
      ) : (
        <Select
          required
          value={cluster ?? ''}
          onChange={(e) => formUtils.handleClusterChanged(e.target.value, setCluster, setSelectedPrefix)}
          disabled={!!defaultClusterName}
        >
          {clusters.map((cluster) => (
            <MenuItem key={cluster.id} value={cluster}>
              {cluster.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </Form.Field>
  );
}

TenantEnv.propTypes = {
  cluster: PropTypes.object,
  defaultClusterName: PropTypes.string,
  setCluster: PropTypes.func.isRequired,
  setSelectedPrefix: PropTypes.func.isRequired
};

export default TenantEnv;
