import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import ActionsMenu from './ActionsMenu';

import * as Styled from './ConfigurationRowActions.styles';

function ConfigurationRowActions({keyName, value, date, actions, displaySettings}) {
  function getTooltipTitle() {
    const formattedDate = format(new Date(date), 'uuuu-MMM-dd HH:mm');
    const dateTimeDistance = formatDistanceToNow(new Date(date), {
      addSuffix: true
    });
    return <Styled.Date>{`${formattedDate} (${dateTimeDistance})`}</Styled.Date>;
  }

  return (
    <Styled.Container>
      <Tooltip title={getTooltipTitle()} placement={'top'} arrow>
        <Styled.DateIcon />
      </Tooltip>
      <ActionsMenu keyName={keyName} value={value} actions={actions} displaySettings={displaySettings} />
    </Styled.Container>
  );
}

ConfigurationRowActions.propTypes = {
  actions: PropTypes.shape({
    deleteValue: PropTypes.func,
    showVersions: PropTypes.func
  }),
  date: PropTypes.string,
  displaySettings: PropTypes.shape({
    showAllLabel: PropTypes.bool,
    showConfigurationKeySearch: PropTypes.bool,
    showCreatedByCol: PropTypes.bool,
    showEmptyFilter: PropTypes.bool,
    showInheritCol: PropTypes.bool,
    showLastUpdateCol: PropTypes.bool,
    titleizeSubCategories: PropTypes.bool
  }),
  keyName: PropTypes.string,
  value: PropTypes.any
};

export default ConfigurationRowActions;
