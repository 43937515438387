import _ from 'lodash';

import {ENFORCEMENT_LEVEL, SERVICE_NAMES_FE} from '../../../consts/rbac/rbac';
import {getFedRampPermissions} from './fedRamp';
import {
  getAddPermissions,
  getRemovePermission,
  getSetViewAsPermissions,
  verbosePermissions
} from './windowFunctionsHelpers';

export function registerWindowRbacFunctions(
  allEnvs,
  allowedExportSchema,
  getSchema,
  getViewAsRbacPermissions,
  hasFullRbacControl,
  rbacPermissions,
  rbacState,
  rbacStateOverride,
  rbacVerboseState,
  setRbacStateOverride,
  setRbacVerboseState,
  setViewAsRbacPermissions,
  viewAsRbacPermissions
) {
  const clearState = (serviceName) => {
    console.log(`Clear RBAC override for service ${serviceName}`);
    setRbacStateOverride(_.omit(rbacStateOverride, serviceName));
  };

  const disableState = (serviceName) => {
    console.log(`Disable RBAC for service ${serviceName}`);
    setRbacStateOverride(_.assign({}, rbacStateOverride, {[serviceName]: ENFORCEMENT_LEVEL.DISABLE}));
  };

  const enableState = (serviceName) => {
    console.log(`Enable RBAC for service ${serviceName}`);
    setRbacStateOverride(_.assign({}, rbacStateOverride, {[serviceName]: ENFORCEMENT_LEVEL.FULL}));
  };

  const showPermissions = (envName, serviceName) => {
    let permissions = rbacPermissions.permissions;
    const envNameFilter = envName ? envName : 'all environments';
    const serviceNameFilter = serviceName ? serviceName : 'all services';
    if (envName) {
      permissions = _.filter(permissions, (value) => _.split(value, '.')[1] === envName);
    }
    if (serviceName) {
      permissions = _.filter(permissions, (value) => _.startsWith(value, serviceName));
    }
    console.log(`Permissions list for ${envNameFilter} and ${serviceNameFilter}`);
    console.table(permissions);
  };

  const getBoundEnvShowPermissions = (envName) =>
    _.assign(
      {
        all: () => showPermissions(envName)
      },
      _.transform(
        SERVICE_NAMES_FE,
        (envPermissions, serviceName) => (envPermissions[serviceName] = () => showPermissions(envName, serviceName)),
        {}
      )
    );

  const getBoundServiceShowPermissions = (serviceName) =>
    _.assign(
      {
        all: () => showPermissions(serviceName)
      },
      _.transform(
        allEnvs,
        (servicePermissions, envName) => (servicePermissions[envName] = () => showPermissions(envName, serviceName)),
        {}
      )
    );

  let windowRbacObj = {
    _verboseData: new verbosePermissions(),
    _verboseMode: rbacVerboseState,
    permissions: {
      showValues: {
        all: () => showPermissions(),
        env: _.transform(
          allEnvs,
          (allEnvPermissions, envName) => (allEnvPermissions[envName] = getBoundEnvShowPermissions(envName)),
          {}
        ),
        service: _.transform(
          SERVICE_NAMES_FE,
          (allServicePermissions, serviceName) =>
            (allServicePermissions[serviceName] = getBoundServiceShowPermissions(serviceName)),
          {}
        )
      }
    },
    state: {
      isActive: () => !_.isEmpty(rbacStateOverride),
      showValues: {
        original: () => console.log('RBAC state original values', rbacState),
        override: () => console.log('RBAC state override values ', rbacStateOverride)
      }
    },
    verbose: {
      clear: () => window.RBAC._verboseData.clear(),
      disable: () => {
        window.RBAC._verboseData.clear();
        setRbacVerboseState(false);
      },
      enable: () => setRbacVerboseState(true),
      show: (servicesNames, envNames) => window.RBAC._verboseData.log(servicesNames, envNames),
      state: () => console.log(`RBAC is ${rbacVerboseState ? '' : 'not '}verbose`)
    },
    viewAs: {
      isActive: () => !_.isNil(viewAsRbacPermissions)
    }
  };

  if (hasFullRbacControl) {
    windowRbacObj.permissions = _.assign(windowRbacObj.permissions, {
      add: getAddPermissions(viewAsRbacPermissions, rbacPermissions, setViewAsRbacPermissions, allEnvs),
      remove: getRemovePermission(viewAsRbacPermissions, setViewAsRbacPermissions)
    });

    windowRbacObj = _.assign(windowRbacObj, {
      fedrampEmulation: {
        disable: () => setViewAsRbacPermissions(null),
        enable: () => setViewAsRbacPermissions(getFedRampPermissions(allEnvs))
      },
      state: {
        clear: {
          all: () => setRbacStateOverride({}),
          [SERVICE_NAMES_FE.cms]: () => clearState(SERVICE_NAMES_FE.cms),
          [SERVICE_NAMES_FE.maestro]: () => clearState(SERVICE_NAMES_FE.maestro),
          [SERVICE_NAMES_FE.mtms]: () => clearState(SERVICE_NAMES_FE.mtms),
          [SERVICE_NAMES_FE.opsWeb]: () => clearState(SERVICE_NAMES_FE.opsWeb)
        },
        disable: {
          all: () => {
            console.log('Disabled RBAC for all services');
            setRbacStateOverride(_.mapValues(SERVICE_NAMES_FE, () => ENFORCEMENT_LEVEL.DISABLE));
          },
          [SERVICE_NAMES_FE.cms]: () => disableState(SERVICE_NAMES_FE.cms),
          [SERVICE_NAMES_FE.maestro]: () => disableState(SERVICE_NAMES_FE.maestro),
          [SERVICE_NAMES_FE.mtms]: () => disableState(SERVICE_NAMES_FE.mtms),
          [SERVICE_NAMES_FE.opsWeb]: () => disableState(SERVICE_NAMES_FE.opsWeb)
        },
        enable: {
          all: () => {
            console.log('Enabled RBAC for all services');
            setRbacStateOverride(_.mapValues(SERVICE_NAMES_FE, () => ENFORCEMENT_LEVEL.FULL));
          },
          [SERVICE_NAMES_FE.cms]: () => enableState(SERVICE_NAMES_FE.cms),
          [SERVICE_NAMES_FE.maestro]: () => enableState(SERVICE_NAMES_FE.maestro),
          [SERVICE_NAMES_FE.mtms]: () => enableState(SERVICE_NAMES_FE.mtms),
          [SERVICE_NAMES_FE.opsWeb]: () => enableState(SERVICE_NAMES_FE.opsWeb)
        },
        isActive: () => !_.isEmpty(rbacStateOverride),
        showValues: {
          original: () => console.log('RBAC state original values', rbacState),
          override: () => console.log('RBAC state override values ', rbacStateOverride)
        }
      },
      viewAs: {
        clear: () => {
          console.log('View as RBAC permissions cleared.');
          setViewAsRbacPermissions(null);
        },
        isActive: () => !_.isNil(viewAsRbacPermissions),
        set: getSetViewAsPermissions(getViewAsRbacPermissions, allEnvs),
        showValues: () => {
          console.log('View as RBAC permissions:');
          console.table(viewAsRbacPermissions.permissions);
        }
      }
    });
  }

  if (allowedExportSchema) {
    windowRbacObj.schema = {
      get: {
        asGraph: () => getSchema.asGraph(),
        asGroups: () => getSchema.asGroups(),
        raw: () => getSchema.asRaw()
      }
    };
  }

  window.RBAC = windowRbacObj;
}
