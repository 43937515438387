import styled from 'styled-components';

import * as theme from '../../../theme';

export const Bar = styled.div`
  background-color: ${({colorId}) => theme.chartColors[colorId % theme.chartColors.length]};
  color: ${theme.default.palette.text.secondary};
  cursor: pointer;
  display: flex;
  flex: ${({value}) => value};
  height: 6px;
`;

export const Label = styled.div`
  cursor: pointer;
  line-height: 1.5;
  opacity: ${({active}) => (active ? 1 : 0.7)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    font-weight: bold;
  }
`;

export const Container = styled.div`
  border-radius: 4px;
  display: flex;
  margin-bottom: 10px;
  overflow: hidden;
`;

export const Percent = styled.div`
  min-width: 60px;
  display: inline-block;
  font-weight: bold;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({colorId}) => theme.chartColors[colorId % theme.chartColors.length]};
  display: inline-block;
  margin-right: 4px;
  border-radius: 50%;
`;
