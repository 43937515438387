import client from '../client';

import * as artifactUtils from '../artifact.utils';

export async function listAll({
  like = '',
  branchTypes = null,
  isLatestBuild = true,
  isOpenForCp = false,
  length = 100,
  orderBy = 'creationDate:desc',
  creationDate = null,
  branchGreater = ''
}) {
  const params = artifactUtils.getParams(
    like,
    branchTypes,
    length,
    orderBy,
    isLatestBuild,
    isOpenForCp,
    creationDate,
    branchGreater
  );
  return client.get('/maestro/api/v1/artifacts', params);
}

export async function getById(id) {
  return client.get(`/maestro/api/v1/artifacts/${id}`);
}

export function getByBranch(branch) {
  return client.get(`/maestro/api/v1/artifacts?branch=${branch}&orderBy=id:desc&length=1`);
}
