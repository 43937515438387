import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {AgGridReact} from 'ag-grid-react';
import {LicenseManager} from 'ag-grid-enterprise';
import AutorenewOutlinedIcon from '@material-ui/icons/Autorenew';
import TablePagination from '@material-ui/core/TablePagination';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';

import langs from '../../localization/langs';
import useAutoExpand from './hooks/useAutoExpand';
import useCsvExportMenuItem from './hooks/useCsvExportMenuItem';
import useRefresh from './hooks/useRefresh';
import useSelection from './hooks/useSelection';

import Action from './Action';
import Column from './Column';

import * as Styled from './Table.styles';

import './Table.scss';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);

const DEFAULT_CONTEXT_MENU = ['copy', 'copyWithHeaders', 'paste'];
const DEFAULT_PAGE_SIZE = 20;
const TOOL_PANELS = {
  COLUMNS: {
    iconKey: 'columns',
    id: 'columns',
    labelDefault: 'Columns',
    labelKey: 'columns',
    toolPanel: 'agColumnsToolPanel',
    toolPanelParams: {
      suppressPivotMode: true,
      suppressRowGroups: true,
      suppressValues: true
    }
  },
  FILTERS: {
    iconKey: 'filter',
    id: 'filters',
    labelDefault: 'Filters',
    labelKey: 'filters',
    toolPanel: 'agFiltersToolPanel',
    toolPanelParams: {
      suppressFilterSearch: true
    }
  }
};
const ROWS_PER_PAGE_OPTIONS = [20, 50, 100, 250, 500];

function autoResize({api}) {
  api.sizeColumnsToFit();
}

function applyMenuItemIcons(items) {
  return _.map(items, (item) => {
    if (_.isString(item)) {
      return item;
    }
    if (item.icon) {
      item = _.defaults({icon: getIconImg(item.icon)}, item);
    }
    if (item.customIcon) {
      item = _.defaults({icon: item.customIcon}, item);
    }
    if (item.subMenu) {
      item.subMenu = applyMenuItemIcons(item.subMenu);
    }
    return item;
  });
}

function getIconImg(icon) {
  return `<img alt="${icon}" width="18" src="/icons/${icon}.png"/>`;
}

function processCellForExport({column, value}) {
  const exportFunc = column.colDef.exportFunc;
  return exportFunc ? exportFunc(value) : value;
}

function Table({
  actions,
  autoGroupColumnDef,
  autoRefresh,
  children,
  columnsDeps = [],
  data,
  dataDeps = [],
  filterModel,
  getContextMenuItems,
  onFilterChanged,
  onRowClicked,
  onSelect,
  pagination,
  paginationPageSize,
  rbacControlObj,
  selected,
  setTableApi,
  title,
  rowIdField,
  toolPanelProps = {},
  toolPanels = [TOOL_PANELS.COLUMNS, TOOL_PANELS.FILTERS],
  ...props
}) {
  const [gridApi, setGridApi] = React.useState();
  const [gridColumnApi, setGridColumnApi] = React.useState(null);

  const [dataCount, setDataCount] = React.useState(-1);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(paginationPageSize || DEFAULT_PAGE_SIZE);

  const isServerSideSource = _.isFunction(data);
  const refresh = useRefresh(autoRefresh, dataDeps, gridApi);
  const autoExpandAction = useAutoExpand(!!autoGroupColumnDef, gridApi);

  const exportCsvMenuItem = useCsvExportMenuItem(gridApi);
  const [selectionColDef, selectionComp, resetSelection, onSelectionChanged] = useSelection(
    gridApi,
    onSelect,
    pageSize,
    selected,
    rowIdField
  );

  React.useEffect(() => {
    if (gridApi && isServerSideSource && !_.isUndefined(filterModel)) {
      gridApi.setFilterModel(filterModel);
      gridApi.setServerSideDatasource({getRows: data});
    }
  }, [data, isServerSideSource, gridApi, filterModel]);

  React.useEffect(() => {
    if (setTableApi) {
      setTableApi({gridApi, refresh});
    }
  }, [gridApi, setTableApi, refresh]);

  React.useEffect(() => {
    if (!gridApi) {
      return;
    }
    gridApi.setColumnDefs(getColumnDefs());
    if (autoGroupColumnDef) {
      gridApi.setAutoGroupColumnDef(Column.toColDef(autoGroupColumnDef, gridApi, onRowClicked));
    }
  }, [...columnsDeps, gridApi]);

  React.useEffect(() => {
    if (!_.isEmpty(selected) && resetSelection) {
      resetSelection();
    }
  }, [...dataDeps, resetSelection]);

  React.useEffect(() => {
    if (!gridApi || _.isUndefined(filterModel)) {
      return;
    }
    gridApi.setFilterModel(filterModel);
  }, [filterModel, gridApi]);

  React.useEffect(() => {
    if (!gridApi) {
      return;
    }
    gridApi.paginationGoToPage(page);
  }, [page]);

  React.useEffect(() => {
    if (!gridApi) {
      return;
    }
    setPage(0);
    gridApi.paginationSetPageSize(pageSize);
  }, [pageSize]);

  const getContextMenuItemsWithIcons = React.useMemo(() => {
    if (!getContextMenuItems) {
      return () => applyMenuItemIcons([...DEFAULT_CONTEXT_MENU, 'separator', exportCsvMenuItem]);
    }
    return (params) => {
      const data = params?.node?.data || {};
      const items = getContextMenuItems(params, data);
      return applyMenuItemIcons([...items, 'separator', exportCsvMenuItem]);
    };
  }, [gridApi, getContextMenuItems]);

  const resetColumnsView = React.useCallback(() => {
    gridApi.setColumnDefs(getColumnDefs());
    gridColumnApi.resetColumnState();
  }, [gridColumnApi]);

  function filterChangeHandler(event) {
    if (resetSelection) {
      resetSelection();
    }
    if (onFilterChanged) {
      onFilterChanged(event);
    }
  }

  function getColumnDefs() {
    const columnDefs = React.Children.map(children, (child) =>
      Column.toColDef(_.assign({rbacControlObj}, child.props), gridApi, onRowClicked)
    );
    if (selectionColDef) {
      columnDefs.unshift(selectionColDef);
    }
    return columnDefs;
  }

  function onGridReady({api, columnApi}) {
    setGridApi(api);
    setGridColumnApi(columnApi);
  }

  function onModelUpdated({api}) {
    autoResize({api});
    setDataCount(api.getDisplayedRowCount());
  }

  function getPage() {
    const totalData = gridApi?.getDisplayedRowCount() || 0;
    return page > totalData / pageSize ? 0 : page;
  }

  return (
    <div className="ag-theme-custom">
      <Styled.Header>
        {title}
        {selectionComp}
        <Styled.Spacer />
        {actions && actions()}
        {autoExpandAction}
        {(autoExpandAction || actions) && <Styled.Separator orientation="vertical" flexItem variant="middle" />}
        <Action title={langs('RESTORE_COLUMNS_VIEW')} icon={ViewColumnIcon} onClick={resetColumnsView} />
        <Action title={langs('REFRESH_TABLE')} icon={AutorenewOutlinedIcon} onClick={refresh} />
      </Styled.Header>
      <AgGridReact
        {...props}
        cacheBlockSize={_.max(ROWS_PER_PAGE_OPTIONS)}
        componentWrappingElement="span"
        defaultCsvExportParams={{processCellCallback: processCellForExport}}
        defaultExcelExportParams={{processCellCallback: processCellForExport}}
        domLayout="autoHeight"
        getContextMenuItems={getContextMenuItemsWithIcons}
        onModelUpdated={onModelUpdated}
        onFilterChanged={filterChangeHandler}
        onGridReady={onGridReady}
        onGridSizeChanged={autoResize}
        onSelectionChanged={onSelectionChanged}
        onViewportChanged={autoResize}
        pagination={_.isNumber(paginationPageSize) || pagination}
        paginationPageSize={pageSize}
        processCellForClipboard={processCellForExport}
        rowData={isServerSideSource ? undefined : data}
        rowModelType={isServerSideSource ? 'serverSide' : undefined}
        rowSelection="multiple"
        serverSideStoreType={isServerSideSource ? 'partial' : undefined}
        sideBar={_.isEmpty(toolPanels) ? undefined : {...toolPanelProps, toolPanels}}
        suppressCellSelection
        suppressRowClickSelection
        suppressPaginationPanel
        suppressScrollOnNewData
        tooltipShowDelay={0}
      />
      <TablePagination
        component="div"
        count={dataCount}
        page={getPage()}
        onPageChange={(event, newPage) => setPage(newPage)}
        rowsPerPage={pageSize}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        onRowsPerPageChange={(event) => setPageSize(_.toNumber(event.target.value))}
      />
    </div>
  );
}

Table.propTypes = {
  actions: PropTypes.func,
  autoGroupColumnDef: PropTypes.object,
  autoRefresh: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  children: PropTypes.oneOfType([Column, PropTypes.arrayOf(Column)]),
  columnsDeps: PropTypes.array,
  data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object.isRequired), PropTypes.func]).isRequired,
  dataDeps: PropTypes.array,
  filterModel: PropTypes.object,
  getContextMenuItems: PropTypes.func,
  onFilterChanged: PropTypes.func,
  onSelect: PropTypes.func,
  rbacControlObj: PropTypes.shape({
    getMissingPermissionsStr: PropTypes.func.isRequired,
    hasPermissions: PropTypes.func.isRequired
  }),
  rowIdField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  selected: PropTypes.arrayOf(PropTypes.object),
  setTableApi: PropTypes.func,
  title: PropTypes.string,
  toolPanelProps: PropTypes.object,
  toolPanels: PropTypes.arrayOf(PropTypes.oneOf(_.values(TOOL_PANELS))),
  ...AgGridReact.propTypes
};

export {DEFAULT_CONTEXT_MENU, TOOL_PANELS};
export default Table;
