import styled from 'styled-components';
import * as theme from '../../../theme';

export const Container = styled.div``;

export const Link = styled.a`
  color: ${theme.link.text};
  &:hover {
    color: ${theme.link.hover};
  }
`;

export const LinkList = styled.div`
  padding-left: 1rem;
`;

export const LinkListItem = styled.div`
  font-size: 14px;
  margin-bottom: 0.25rem;
`;

export const Segment = styled.div`
  background: ${theme.backgrounds.light};
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
`;

export const SegmentTitle = styled.h3`
  margin-bottom: 0.25rem;
  margin-top: 0;
`;
