const CUSTOM_MODULES = '__CUSTOM__';
const DEFAULT_PARAMETERS = {log_level: 'INFO'};
const DEFAULT_TIMEZONE = 'EST';
const HIDDEN_PARAMETERS = new Set(['initiator']);
const PARTNER_TENANT_PREFIX = ['integration-', 'partner-', 'dev-'];
const PARTNER_TENANTS = new Set(['demo-checkpoint', 'demo-partner', 'demo-training']);
const SEED_COMMAND_DESCRIPTION = 'Run demo seed';

export default {
  CUSTOM_MODULES,
  DEFAULT_PARAMETERS,
  DEFAULT_TIMEZONE,
  HIDDEN_PARAMETERS,
  PARTNER_TENANT_PREFIX,
  PARTNER_TENANTS,
  SEED_COMMAND_DESCRIPTION
};
