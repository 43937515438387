import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import * as Styled from './CollapseItem.styles';

function CollapseItem({title, children, isOpen, defaultIsOpen = false, titleColor, ...props}) {
  const [isCollapseOpen, setIsCollapseOpen] = React.useState(defaultIsOpen);

  React.useEffect(() => {
    if (_.isNil(isOpen)) {
      return;
    }

    if (isOpen) {
      setIsCollapseOpen(true);
    } else {
      setIsCollapseOpen(false);
    }
  }, [isOpen]);

  return (
    <Styled.Container {...props}>
      <Styled.Title color={titleColor} isOpen={isCollapseOpen} onClick={() => setIsCollapseOpen(!isCollapseOpen)}>
        {isCollapseOpen ? <ExpandLess /> : <ExpandMore />}
        {title}
      </Styled.Title>
      <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Styled.Container>
  );
}

CollapseItem.propTypes = {
  defaultIsOpen: PropTypes.bool,
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string
};

export default CollapseItem;
