import _ from 'lodash';
import React from 'react';

import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';

import consts from '../../consts';
import context from '../../context';
import langs from '../../localization/langs';
import services from '../../services';
import useTasksView from './useTasksView';

import {Drawer} from '../../components/Base';
import {SEARCH_TYPES} from '../../context/Search/utils';
import TaskCommands from '../../components/Tasks/TaskCommands/TaskCommands';
import TaskSection from '../../components/Tasks/TaskSection';

import * as searchOptions from './searchOptions';
import * as Styled from './TasksView.styles';

function TenantsTasks() {
  const searchContext = context.Search.useSearch();

  React.useEffect(() => {
    async function setSearchOptions() {
      searchContext.resetSearchOptions(searchOptions.options);
      const {data: tenants} = await services.tenants.getCompact();
      const {data: users} = await services.tenants.getUsers();

      searchContext.resetSearchOptions([
        ...searchOptions.options,
        {
          key: 'hostIds',
          options: tenants.items.map(({id, name}) => ({key: id, value: name})),
          supportedOperators: [consts.FILTER_OPERATORS.OVERLAP],
          title: langs('TENANT'),
          type: SEARCH_TYPES.TEXT
        },
        {
          key: 'userFullName',
          options: users.items.map(({fullName}) => ({key: fullName, value: fullName})),
          supportedOperators: [consts.FILTER_OPERATORS.LIKE],
          title: langs('TRIGGERED_BY'),
          type: SEARCH_TYPES.TEXT
        }
      ]);
    }
    setSearchOptions();
  }, []);

  const {
    closeDrawer,
    fetchTasks,
    onSetPage,
    onSetRowsPerPage,
    page,
    rowsPerPage,
    selectedTask,
    selectedTaskId,
    setSelectedTaskId,
    tasksCompleted,
    tasksInProgress,
    totalItems
  } = useTasksView(consts.API_PREFIX_BY_SERVICE_NAME.tenants);

  return (
    <div>
      <Drawer anchor="right" open={Boolean(selectedTask)} onClose={closeDrawer} withBackdrop={true}>
        <TaskCommands
          apiPrefix={consts.API_PREFIX_BY_SERVICE_NAME.tenants}
          entityName={langs('TENANTS')}
          fetchOutput={({tenantId}, taskId) => services.tenants.getTaskOutput(tenantId, taskId)}
          onClose={closeDrawer}
          renderLabel={({tenantName}) => (
            <Tooltip title={tenantName}>
              <Styled.Chip>{tenantName}</Styled.Chip>
            </Tooltip>
          )}
          sectionGetter={() => 'tenants'}
          selectedCommandConfigIdKey="tenantId"
          tableRoute="tenants.withCustomFilter"
          outputTitleGetter={(command) => _.get(command, 'tenantName')}
          task={selectedTask}
        />
      </Drawer>

      <div>
        {!_.isEmpty(tasksInProgress) && (
          <TaskSection
            isActive={true}
            onChange={fetchTasks}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={setSelectedTaskId}
            tasks={tasksInProgress}
            statusLabel={langs('IN_PROGRESS')}
            entityName={langs('TENANTS')}
          />
        )}
        <TaskSection
          isActive={false}
          selectedTaskId={selectedTaskId}
          setSelectedTaskId={setSelectedTaskId}
          tasks={tasksCompleted}
          statusLabel={langs('COMPLETED_RECENTLY')}
          entityName={langs('TENANTS')}
        />
      </div>
      <TablePagination
        component={Styled.PaginationContainer}
        count={totalItems}
        page={page}
        onPageChange={onSetPage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onSetRowsPerPage}
      />
    </div>
  );
}

export default TenantsTasks;
