import consts from '../../../../consts';

import {getEnvNameFromLabels} from './common';

const PERMISSIONS = consts.rbac.permissions.cms;

function getDirectAccessors(cmsAccessors) {
  return {
    allowed: {
      beacon: {
        manage: () => cmsAccessors.hasPermission(PERMISSIONS.beacon.manage),
        provision: () => cmsAccessors.hasPermission(PERMISSIONS.beacon.provision)
      },
      collectors: {
        configuration: {
          create: {
            all: {
              all: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.configuration.create.all.all),
              collectors: (labels) =>
                cmsAccessors.hasPermission(
                  PERMISSIONS.collectors.configuration.create.all.collectors,
                  getEnvNameFromLabels(labels)
                ),
              tenants: (labels) =>
                cmsAccessors.hasPermission(
                  PERMISSIONS.collectors.configuration.create.all.tenants,
                  getEnvNameFromLabels(labels)
                )
            },
            create: (labels) =>
              cmsAccessors.hasPermission(
                PERMISSIONS.collectors.configuration.create.create,
                getEnvNameFromLabels(labels)
              )
          },
          delete: {
            all: {
              all: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.configuration.delete.all.all),
              collectors: (labels) =>
                cmsAccessors.hasPermission(
                  PERMISSIONS.collectors.configuration.delete.all.collectors,
                  getEnvNameFromLabels(labels)
                ),
              tenants: (labels) =>
                cmsAccessors.hasPermission(
                  PERMISSIONS.collectors.configuration.delete.all.tenants,
                  getEnvNameFromLabels(labels)
                )
            },
            delete: (labels) =>
              cmsAccessors.hasPermission(
                PERMISSIONS.collectors.configuration.delete.delete,
                getEnvNameFromLabels(labels)
              )
          },
          edit: {
            all: {
              all: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.configuration.edit.all.all),
              collectors: (labels) =>
                cmsAccessors.hasPermission(
                  PERMISSIONS.collectors.configuration.edit.all.collectors,
                  getEnvNameFromLabels(labels)
                ),
              tenants: (labels) =>
                cmsAccessors.hasPermission(
                  PERMISSIONS.collectors.configuration.edit.all.tenants,
                  getEnvNameFromLabels(labels)
                )
            },
            edit: (labels) =>
              cmsAccessors.hasPermission(PERMISSIONS.collectors.configuration.edit.edit, getEnvNameFromLabels(labels))
          },
          read: {
            all: {
              all: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.configuration.read.all.all),
              collectors: (labels) =>
                cmsAccessors.hasPermission(
                  PERMISSIONS.collectors.configuration.read.all.collectors,
                  getEnvNameFromLabels(labels)
                ),
              tenants: (labels) =>
                cmsAccessors.hasPermission(
                  PERMISSIONS.collectors.configuration.read.all.tenants,
                  getEnvNameFromLabels(labels)
                )
            },
            read: (labels) =>
              cmsAccessors.hasPermission(PERMISSIONS.collectors.configuration.read.create, getEnvNameFromLabels(labels))
          }
        },
        decommission: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.decommission),
        markOperational: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.markOperational),
        task: {
          runAll: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.task.runAll),
          runElevated: {
            critical: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.task.runElevated.critical),
            high: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.task.runElevated.high),
            low: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.task.runElevated.low),
            medium: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.task.runElevated.medium)
          },
          runFieldAction: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.task.runFieldAction),
          runNonElevated: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.task.runNonElevated),
          runShellCommand: () => cmsAccessors.hasPermission(PERMISSIONS.collectors.task.runShellCommand)
        }
      }
    },
    hasConfigPermissions: (permissions) => {
      return cmsAccessors.hasPermissions(permissions);
    }
  };
}

export default {
  getDirectAccessors
};
