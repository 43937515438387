import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export const Container = styled.div`
  margin: 10px;
`;

export const Form = styled(Paper).attrs({component: 'form'})`
  align-items: center;
  display: flex;
  padding: 2px 4px;
  width: 600px;
`;

export const Title = styled(Typography).attrs({color: 'textSecondary', variant: 'h5'})`
  margin-bottom: 10px;
`;
