import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './JiraTicket.styles';

const URL_PREFIX = 'https://armis-security.atlassian.net/browse/';

function JiraTicket({value: {jira_ticket: jiraTicket}}) {
  return (
    <Styled.Link href={`${URL_PREFIX}${jiraTicket}`} target="_blank" rel="noreferrer">
      {jiraTicket}
    </Styled.Link>
  );
}

JiraTicket.propTypes = {
  value: PropTypes.shape({
    jira_ticket: PropTypes.string
  }).isRequired
};

export default JiraTicket;
