import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router-dom';

import Replay from '@material-ui/icons/Replay';

import {commandType as commandTypeMap} from '../models/command';
import context from '../context';
import langs from '../localization/langs';
import permissionsLookup from '../consts/rbac/tasks';
import services from '../services';

import {ConfirmationModal} from './Base';
import ProtectedComponent from './Rbac/ProtectedComponent';

import * as Styled from './ReplayTask.styles';

function ReplayTask({taskId, hostIds, apiPrefix, tasksPage}) {
  const [taskData, setTaskData] = React.useState({});
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);

  const {getUrl} = context.Navigation.useNavigation();
  const history = useHistory();
  const notifications = context.Notifications.useNotifications();
  const user = context.User.useUser();

  React.useEffect(() => {
    getTask(taskId)
      .then(setTaskData)
      .catch((error) => {
        notifications.error(error.message);
        console.error(error.message);
      });
  }, [taskId]);

  async function getTask(taskId) {
    const query = `task_id[eq]=${taskId}`;
    const {data} = await services.tasks.listTasks(0, 1, apiPrefix, query);
    return _.head(data.items);
  }

  function getPermissionByType(apiPrefix, commandTypeId) {
    const commandType = _.invert(commandTypeMap)[commandTypeId];
    return _.get(permissionsLookup, [apiPrefix, commandType]);
  }

  async function replayTask() {
    try {
      const parameters = JSON.parse(_.get(taskData, 'parametersValues', '{}'));
      const {data} = await services.tasks.create(
        hostIds,
        taskData.commandId,
        taskData.commandType,
        apiPrefix,
        taskData.slidingWindowSize,
        'hostIds',
        null,
        parameters
      );
      history.push(getUrl(tasksPage, null, {taskId: data.id}));
    } catch (ex) {
      notifications.error(ex.response.statusText);
      console.error(ex);
    }
  }

  return (
    <ProtectedComponent
      permissions={!_.isEmpty(taskData) ? getPermissionByType(apiPrefix, taskData.commandType) : []}
      rbacControlObj={user.rbac[apiPrefix]}
    >
      <Styled.ReplayButton
        startIcon={<Replay />}
        onClick={() => setIsConfirmModalOpen(true)}
        disabled={_.isEmpty(hostIds)}
      >
        {langs('RUN_REPLAY')}
      </Styled.ReplayButton>
      <ConfirmationModal
        open={isConfirmModalOpen}
        title={langs('ARE_YOU_SURE')}
        onConfirm={replayTask}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
    </ProtectedComponent>
  );
}

ReplayTask.propTypes = {
  apiPrefix: PropTypes.string.isRequired,
  hostIds: PropTypes.array.isRequired,
  taskId: PropTypes.string.isRequired,
  tasksPage: PropTypes.string.isRequired
};

export default ReplayTask;
