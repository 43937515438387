export default {
  DEMO: 'Demo',
  DEMO_ACTIONS_NEW_SEED: 'New seed',
  DEMO_ACTIONS_RESEED: 'Re-seed',
  DEMO_ACTIONS_SEED_DIALOG_ADVANCED: 'Advanced',
  DEMO_ACTIONS_SEED_DIALOG_CANT_FIND: 'Can\'t find command: "<%=command%>"',
  DEMO_ACTIONS_SEED_DIALOG_TEXT1: 'You\'re about to seed demo data to "<%=tenantName%>".',
  DEMO_ACTIONS_SEED_DIALOG_TEXT2: 'This action will delete existing data.',
  DEMO_ACTIONS_SEED_ERROR: "Can't start seeding, see logs for details",
  DEMO_MODULES_CUSTOM: 'Custom',
  DEMO_NEW_TENANT: 'New Demo Tenant',
  DEMO_REFRESH_ERROR: "Can't fetch data, see logs for details",
  DEMO_SEED_OUTPUT: 'Seed Output',
  DEMO_SEED_OUTPUT_STARTING: 'Starting…',
  DEMO_SEED_STATUS_ABORTED: 'Aborted',
  DEMO_SEED_STATUS_ERROR_KNOWN: 'Error',
  DEMO_SEED_STATUS_ERROR_UNKNOWN: 'Unexpected error',
  DEMO_SEED_STATUS_PENDING: 'Pending',
  DEMO_SEED_STATUS_SEEDING: 'Seeding',
  DEMO_SEED_STATUS_SUCCESS: 'Success',
  DEMO_TABLE_COLUMN_ACTIONS: 'Actions',
  DEMO_TABLE_COLUMN_DURATION: 'Duration',
  DEMO_TABLE_COLUMN_INITIATOR_EMAIL: 'Initiator',
  DEMO_TABLE_COLUMN_MODULES_TO_SEED: 'Modules',
  DEMO_TABLE_COLUMN_OVERRIDE_VMS: 'Override VMS',
  DEMO_TABLE_COLUMN_START_TIME: 'Start Time',
  DEMO_TABLE_COLUMN_STATUS: 'Status',
  DEMO_TABLE_COLUMN_TABLES_TO_IGNORE: 'Tables to Ignore',
  DEMO_TABLE_COLUMN_TIMEZONE: 'Timezone',
  DEMO_TABLE_COLUMN_VERTICAL: 'Vertical',
  DEMO_TABLE_TITLE: 'Demo Tenants'
};
