import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../../localization/langs';

import {Form, Paper} from '../../../../components/Base';
import MultipleChoiceInput from '../../../../components/Base/inputs/MultipleChoiceInput';

const TENANT_MONITORING_OPTIONS = {
  CORALOGIX_MONITOR: langs('CORALOGIX_MONITOR'),
  PROMETHEUS_MONITOR: langs('PROMETHEUS_MONITOR')
};

function MonitorTenant({setMonitorData}) {
  const [monitorTenantSelection, setMonitorTenantSelection] = React.useState([]);

  React.useEffect(() => {
    setMonitorData(getMonitorData(monitorTenantSelection, TENANT_MONITORING_OPTIONS));
  }, [monitorTenantSelection]);

  function getMonitorData(monitorTenantSelection, options) {
    const enableCoralogix = _.some(monitorTenantSelection, (value) =>
      _.includes(value, TENANT_MONITORING_OPTIONS.CORALOGIX_MONITOR)
    );
    const isMonitored = _.includes(monitorTenantSelection, options.PROMETHEUS_MONITOR);
    return {
      enableCoralogix,
      isMonitored
    };
  }
  return (
    <Paper padding={2} style={{paddingBlock: '1em'}}>
      <Form.Label>{langs('TENANT_MONITORING')}</Form.Label>
      <Form.Field>
        <MultipleChoiceInput
          onChange={setMonitorTenantSelection}
          options={_.toArray(TENANT_MONITORING_OPTIONS)}
          value={monitorTenantSelection}
        />
      </Form.Field>
    </Paper>
  );
}

MonitorTenant.propTypes = {
  setMonitorData: PropTypes.func.isRequired
};

export default MonitorTenant;
