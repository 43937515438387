import _ from 'lodash';

import models from '../../../models';
import modelToQuery from '../../../utils/agGridUtils/filterUtils/modelToQuery';
import queryUtils from '../../../utils/agGridUtils/queryUtils';
import services from '../../../services';

import * as theme from '../../../theme';

const ORDER_BY_DIRECTION = 'desc';
const ORDER_BY_FIELD = models.staticData.RELEASE_FIELDS.RELEASE_CREATION_DATE;
const ORDER_BY = `${ORDER_BY_FIELD}:${ORDER_BY_DIRECTION}`;

const FINAL_STAGE_STATUSES = [
  models.releases.statusTypes.DECLINED,
  models.releases.statusTypes.DEPLOYED,
  models.releases.statusTypes.MERGED
];

const STATUS_COLORS = {
  [models.releases.statusTypes.BUILD_FAILED]: theme.statusColors.error,
  [models.releases.statusTypes.BUILD_FINISHED_SUCCESSFULLY]: null,
  [models.releases.statusTypes.BUILD_IS_RUNNING]: null,
  [models.releases.statusTypes.CANDIDATE]: null,
  [models.releases.statusTypes.CP_FAILED]: theme.statusColors.error,
  [models.releases.statusTypes.DECLINED]: theme.statusColors.error,
  [models.releases.statusTypes.DEPLOYED]: theme.statusColors.success,
  [models.releases.statusTypes.HAS_CONFLICTS]: theme.statusColors.error,
  [models.releases.statusTypes.MERGED]: theme.statusColors.success,
  [models.releases.statusTypes.UNKNOWN]: theme.statusColors.warning
};

const URGENCY_COLORS = {
  [models.releases.urgencyTypes.HIGH]: theme.statusColors.error,
  [models.releases.urgencyTypes.LOW]: theme.statusColors.success,
  [models.releases.urgencyTypes.MEDIUM]: null,
  [models.releases.urgencyTypes.REGRESSION]: null,
  [models.releases.urgencyTypes.UNKNOWN]: theme.statusColors.warning
};

export async function getReleases(api, request) {
  const query = queryUtils.getQueryParams(api, request, null, ORDER_BY);
  const response = await services.releases.getReleases(query);
  return queryUtils.parseResponse(response);
}

async function fetchFilterOptions({colDef, success}) {
  const filterModel = colDef.gridApi.getFilterModel();
  const filterSearchQuery = modelToQuery.getQuery(colDef.gridApi, filterModel);
  const response = await services.releases.getAllFilterOptions(colDef.colId, filterSearchQuery);
  success(_.map(response.data, 'name'));
}

export default {
  FINAL_STAGE_STATUSES,
  STATUS_COLORS,
  URGENCY_COLORS,
  fetchFilterOptions,
  getReleases
};
