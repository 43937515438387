import React from 'react';

import DemoActions from './DemoActions';
import DemoTenants from './DemoTenants';

export default function DemoView() {
  return (
    <div>
      <DemoActions />
      <DemoTenants />
    </div>
  );
}
