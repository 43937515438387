import _ from 'lodash';
import React from 'react';

import CheckboxHeader from '../parts/CheckboxHeader';
import CurrentSelection from '../parts/CurrentSelection';

export default function useSelection(gridApi, onSelect, pageSize, selected, rowIdField) {
  const [isLoading, setIsLoading] = React.useState(false);
  const totalCount = gridApi?.getDisplayedRowCount() || 0;
  const selectAllRowsAcrossPages = React.useCallback(() => {
    setIsLoading(true);
    const request = {endRow: totalCount, filterModel: gridApi.getFilterModel(), startRow: 0};
    _.forEach(gridApi.getRenderedNodes(), (node) => node.setSelected(true));
    gridApi.getModel().datasource.getRows({
      api: gridApi,
      request,
      success: ({rowData}) => {
        onSelect(rowData);
        setIsLoading(false);
      }
    });
  }, [gridApi, onSelect, totalCount]);

  const resetSelection = React.useCallback(() => {
    _.forEach(gridApi.getSelectedNodes(), (node) => node.setSelected(false));
  }, [gridApi]);

  const onSelectionChanged = React.useCallback(
    ({api}) => {
      // All selected rows in the table isn't saved in the api's state, so we have to
      // calculate the selected/deselected rows manually for both single/multi page selection.
      // This method calculates the deselected rendered rows, and uses it to calculate the difference between all selected
      // and deselected rows, then adds the current page newly selected rows.
      // Finally, if deselection is needed when all rows multipage are selected, deselect all and not just page
      const renderedRows = _.map(api.getRenderedNodes(), 'data');
      const deselectedRenderedRows = _.differenceBy(renderedRows, api.getSelectedRows(), rowIdField);
      const selectedDeselectedDiff = _.differenceBy(selected, deselectedRenderedRows, rowIdField);
      const selectedRows = _.unionBy(api.getSelectedRows(), selectedDeselectedDiff, rowIdField);
      onSelect(_.isEmpty(api.getSelectedRows()) ? api.getSelectedRows() : selectedRows);
    },
    [onSelect, pageSize, totalCount, selected]
  );

  if (!onSelect) {
    return [null, null, undefined, undefined];
  }

  const selectionColDef = {
    checkboxSelection: true,
    colId: 'selection',
    field: null,
    headerComponentFramework: CheckboxHeader,
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressSizeToFit: true,
    width: 40
  };
  const selectionComp = (
    <CurrentSelection
      isLoading={isLoading}
      pageSize={pageSize}
      selectAll={selectAllRowsAcrossPages}
      selected={selected}
      total={totalCount}
    />
  );

  return [selectionColDef, selectionComp, resetSelection, onSelectionChanged];
}
