import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {Checkbox, FormControlLabel} from '@material-ui/core';

import langs from '../../../../localization/langs';
import {DEPLOYMENT_TYPES} from '../utils';

import * as Styled from './Common.styles';

function CollectorsDeployment({
  deploymentTypesSelection,
  onChangeType,
  tenants,
  tenantsSelection,
  setTenantsSelection
}) {
  const isChecked = _.get(deploymentTypesSelection, [DEPLOYMENT_TYPES.COLLECTORS.value, 'checked'], false);

  function onChangeCheckbox(event) {
    if (!event.target.checked) {
      setTenantsSelection([]);
    }
    onChangeType(event.target.name, event.target.checked);
  }

  return (
    <Styled.DeploymentSection>
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={onChangeCheckbox} name={DEPLOYMENT_TYPES.COLLECTORS.value} />}
        label={DEPLOYMENT_TYPES.COLLECTORS.displayName}
      />
      <Styled.TextSearch
        multiple
        options={tenants}
        value={tenantsSelection}
        label="tenantName"
        disabled={!isChecked}
        inputPlaceholder={langs('CHOOSE_TENANTS')}
        setValue={setTenantsSelection}
      />
    </Styled.DeploymentSection>
  );
}

CollectorsDeployment.propTypes = {
  deploymentTypesSelection: PropTypes.object,
  onChangeType: PropTypes.func.isRequired,
  setTenantsSelection: PropTypes.func.isRequired,
  tenants: PropTypes.array,
  tenantsSelection: PropTypes.array
};

export default CollectorsDeployment;
