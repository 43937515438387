import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router-dom';

import context from '../../context';
import langs from '../../localization/langs';
import models from '../../models';
import services from '../../services';

import ProgressBar from '../Base/Progressbar';
import TaskDialogActions from './TaskDialogActions';

import * as Styled from './NewTaskWidget.styles';

function ConfirmTask({
  closeModal,
  command,
  configurationValue,
  idAttribute,
  onBack,
  parametersValues,
  selectedHosts,
  setDialogTitle,
  setSlidingWindowSize,
  slidingWindowSize,
  taskOption,
  ...props
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [hosts, setHosts] = React.useState([]);

  React.useEffect(() => {
    if (_.isFunction(taskOption.modifyHosts)) {
      taskOption
        .modifyHosts(selectedHosts)
        .then(setHosts)
        .catch((e) => console.error(e));
    } else {
      setHosts(selectedHosts);
    }
  }, [selectedHosts]);

  const history = useHistory();
  const {getUrl} = context.Navigation.useNavigation();
  const notifications = context.Notifications.useNotifications();

  React.useEffect(() => setDialogTitle(langs('CONFIRM_TASK')), [setDialogTitle]);

  async function confirm() {
    setIsLoading(true);
    try {
      const {data} = await services.tasks.create(
        _.map(hosts, idAttribute),
        command?.id,
        taskOption.type,
        taskOption.apiPrefix,
        slidingWindowSize,
        taskOption.hostIdsField || 'hostIds',
        _.isEmpty(configurationValue) && _.isFunction(taskOption.getConfigurationValue)
          ? taskOption.getConfigurationValue(props)
          : configurationValue,
        _.isEmpty(parametersValues) && _.isFunction(taskOption.getParametersValues)
          ? taskOption.getParametersValues(props)
          : parametersValues
      );
      history.push(getUrl(taskOption.tasksPage, null, {taskId: data.id}));
    } catch (ex) {
      notifications.error(ex.response.statusText);
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  function onChangeSlidingWindowSize(event) {
    const inputElementValue = parseInt(event.currentTarget.value);
    setSlidingWindowSize(isNaN(inputElementValue) ? 0 : inputElementValue);
  }

  return (
    <>
      <Styled.DialogContent>
        {langs('CONFIRM_TASK_TEXT', {
          hosts: hosts.length,
          task: models.task.getTextByType(taskOption.type)
        })}
        <Styled.FieldContainer>
          <Styled.Label>{langs('SLIDING_WINDOW_SIZE')}: </Styled.Label>
          <Styled.ConfigurationInputContainer>
            <Styled.ConfigurationInput
              onChange={onChangeSlidingWindowSize}
              value={slidingWindowSize}
              type="number"
              label={langs('SIZE')}
            />
          </Styled.ConfigurationInputContainer>
        </Styled.FieldContainer>
        {!_.isEmpty(command?.command) && (
          <Styled.Label>
            {langs('COMMAND')}:<Styled.Command>{command?.command}</Styled.Command>
          </Styled.Label>
        )}
        {!_.isEmpty(parametersValues) && (
          <Styled.FieldContainer>
            {langs('PARAMETERS')}:
            <Styled.Parameters>
              {_.map(_.entries(parametersValues), ([key, value]) => (
                <Styled.Parameter key={key}>
                  {key} : {JSON.stringify(value)}
                </Styled.Parameter>
              ))}
            </Styled.Parameters>
          </Styled.FieldContainer>
        )}
        {!_.isEmpty(hosts) && _.isFunction(taskOption.infoComponent) && <taskOption.infoComponent hosts={hosts} />}
      </Styled.DialogContent>

      <ProgressBar isLoading={isLoading} />

      <TaskDialogActions onBack={onBack} onCancel={closeModal} onNext={confirm} labelNext={langs('CONFIRM')} />
    </>
  );
}

ConfirmTask.propTypes = {
  closeModal: PropTypes.func.isRequired,
  command: PropTypes.object,
  configurationValue: PropTypes.object,
  idAttribute: PropTypes.string,
  onBack: PropTypes.func,
  parametersValues: PropTypes.object,
  selectedHosts: PropTypes.array,
  setDialogTitle: PropTypes.func.isRequired,
  setSlidingWindowSize: PropTypes.func.isRequired,
  slidingWindowSize: PropTypes.number.isRequired,
  taskOption: PropTypes.object
};

export default ConfirmTask;
