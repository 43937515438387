import PropTypes from 'prop-types';
import React from 'react';

import CheckIcon from '@material-ui/icons/Check';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import {StatusLine} from '../../Base';

import langs from '../../../localization/langs';

import MonitoringStatus from './MonitoringStatus';

import * as Styled from './TenantStats.styles';

const MISSING_VALUE = '-';

function TenantStats({tenant = {}}) {
  return (
    <Styled.StatsPaper>
      <MonitoringStatus status={!!tenant.isMonitored} />
      <hr />
      <StatusLine disabled icon={<FiberManualRecordIcon />}>
        {langs('COLLECTORS_CONNECTED', {count: MISSING_VALUE})}
      </StatusLine>
      <StatusLine disabled icon={<FiberManualRecordIcon />}>
        {langs('COLLECTORS_DISCONNECTED', {count: MISSING_VALUE})}
      </StatusLine>
      <hr />
      <StatusLine disabled icon={<CheckIcon />}>
        {langs('INTEGRATIONS_UP', {count: MISSING_VALUE})}
      </StatusLine>
      <StatusLine disabled>{langs('INTEGRATIONS_DOWN', {count: MISSING_VALUE})}</StatusLine>
    </Styled.StatsPaper>
  );
}

TenantStats.propTypes = {
  tenant: PropTypes.object.isRequired
};

export default TenantStats;
