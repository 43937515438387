import _ from 'lodash';

import consts from '../../consts';
import langs from '../../localization/langs';
import staticData from '../../models/staticData';
import {SEARCH_TYPES} from '../../context/Search/utils';

const COMMON_SEARCH_TERMS = `environmentName${consts.FILTER_OPERATORS.LIKE}=${consts.DEMO_ENV}&status${consts.FILTER_OPERATORS.LIKE}=AVAILABILITY_STATUS_ACTIVE`;
const STAGES = [staticData.TENANT.STAGES_TRANSLATED.DEMO, staticData.TENANT.STAGES_TRANSLATED.TEST];
const FILTER_CONFIG = [
  {
    key: 'stage',
    options: _.map(STAGES, (stage) => ({key: stage, value: stage})),
    supportedOperators: [consts.FILTER_OPERATORS.EQUAL],
    title: langs('STAGE'),
    type: SEARCH_TYPES.CHIPS
  }
];
export default {
  COMMON_SEARCH_TERMS,
  FILTER_CONFIG
};
