import _ from 'lodash';

import apiConsts from '../../consts';
import consts from './actions/SeedTenantDialog.consts';
import langs from '../../localization/langs';
import services from '../../services';

function createTask(tenant, command, params, {email}) {
  return services.tasks.create(
    [tenant.tenantId],
    command.id,
    command.commandType,
    apiConsts.API_PREFIX_BY_SERVICE_NAME.tenants,
    20,
    'hostIds',
    null,
    {initiator: email, ...params}
  );
}

function getCommandParameters(command, config) {
  return _.transform(
    command.parameters,
    (res, parameter) => {
      if (consts.HIDDEN_PARAMETERS.has(parameter.name)) {
        return;
      }
      if (parameter.name === 'modules') {
        res.push(_.defaults({disabled: config.defaults.always}, parameter));
      } else {
        res.push(parameter);
      }
    },
    []
  );
}

function getModulesFromVertical(vertical, config) {
  return _.concat(config.defaults.verticals[vertical], config.defaults.always);
}

function getSeedCommand(commands) {
  return _.find(commands, {description: consts.SEED_COMMAND_DESCRIPTION});
}

function getSeedParams(tenant, isNewSeed, config) {
  let {
    modulesToSeed: modules = [],
    overrideVms: override_vms = false,
    tablesToIgnore: tables_to_ignore = [],
    timezone = consts.DEFAULT_TIMEZONE,
    vertical = ''
  } = isNewSeed ? {} : tenant;

  if (config && _.isEmpty(modules) && !vertical) {
    vertical = _(config.defaults.verticals)
      .keys()
      .sortBy()
      .first();
    modules = getModulesFromVertical(vertical, config);
  }

  if (config && isPartnerTenant(tenant)) {
    tables_to_ignore = _.uniq(_.concat(tables_to_ignore, config.partner_tables));
  }
  return {modules, override_vms, tables_to_ignore, timezone, vertical, ...consts.DEFAULT_PARAMETERS};
}

async function getSeedTask(tenantId) {
  const {data} = await services.tasks.listTasks(
    0,
    1,
    apiConsts.API_PREFIX_BY_SERVICE_NAME.tenants,
    `host_ids[overlap]=${tenantId}&description[eq]=${consts.SEED_COMMAND_DESCRIPTION}`
  );
  return _.first(data.items);
}

function isPartnerTenant({tenantName}) {
  return (
    _.some(consts.PARTNER_TENANT_PREFIX, (prefix) => _.startsWith(tenantName, prefix)) ||
    consts.PARTNER_TENANTS.has(tenantName)
  );
}

async function setMetadata({tenantId}, params, {email}, log = langs('DEMO_SEED_OUTPUT_STARTING')) {
  return services.seedService.setMetadata({
    end_time: null,
    initiator_email: email,
    log,
    modules_to_seed: params.modules,
    override_vms: params.override_vms,
    start_time: new Date().toISOString(),
    status: 'SEED_STATUS_PENDING',
    tables_to_ignore: params.tables_to_ignore,
    tenant_id: tenantId,
    timezone: params.timezone,
    vertical: params.vertical
  });
}

function setVerticalModules(params, vertical, config) {
  const modules = getModulesFromVertical(vertical, config);
  return {...params, modules, vertical};
}

async function statSeed(tenant, command, params, user) {
  await createTask(tenant, command, params, user);
  await setMetadata(tenant, params, user);
}

export default {
  getCommandParameters,
  getSeedCommand,
  getSeedParams,
  getSeedTask,
  setVerticalModules,
  statSeed
};
