import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

function DeployNeeded({data: {deployNeeded}}) {
  return <div>{_.startCase(_.isNil(deployNeeded) ? false : deployNeeded.toString())}</div>;
}

DeployNeeded.propTypes = {
  data: PropTypes.shape({
    deployNeeded: PropTypes.bool.isRequired
  }).isRequired
};

export default DeployNeeded;
