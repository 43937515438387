import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import PropTypes from 'prop-types';
import React from 'react';

function DateColumn({value, prefix = ''}) {
  if (!value) {
    return null;
  }
  const date = new Date(value);
  return (
    <span>
      {prefix}
      {formatDistanceToNow(date, {addSuffix: true})}
    </span>
  );
}

DateColumn.propTypes = {
  prefix: PropTypes.string,
  value: PropTypes.string
};

export default DateColumn;
