import PropTypes from 'prop-types';
import React from 'react';

import {FormControl, FormControlLabel, Radio, RadioGroup} from '@material-ui/core';

import langs from '../../../../localization/langs';
import models from '../../../../models';

function UrgencySelector({setUrgency, urgency}) {
  return (
    <FormControl>
      <RadioGroup row value={urgency} onChange={(e) => setUrgency(e.target.value)}>
        <FormControlLabel value={models.releases.urgencyTypes.HIGH} control={<Radio />} label={langs('HIGH')} />
        <FormControlLabel value={models.releases.urgencyTypes.MEDIUM} control={<Radio />} label={langs('MEDIUM')} />
        <FormControlLabel value={models.releases.urgencyTypes.LOW} control={<Radio />} label={langs('LOW')} />
        <FormControlLabel
          value={models.releases.urgencyTypes.REGRESSION}
          control={<Radio />}
          label={langs('REGRESSION')}
        />
      </RadioGroup>
    </FormControl>
  );
}

UrgencySelector.propTypes = {
  setUrgency: PropTypes.func.isRequired,
  urgency: PropTypes.string.isRequired
};

export default UrgencySelector;
