import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './Tabs.styles';

const Tabs = ({selected, onChange, children, ...props}) => {
  return (
    <Styled.Tabs {...props}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          onClick: () => child.props.onClick || onChange(child.props.id),
          selected: child.props.selected || child.props.id === selected
        })
      )}
    </Styled.Tabs>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired
};

Tabs.Tab = Styled.Tab;

Tabs.Tab.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool
};

export default Tabs;
