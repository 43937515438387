import styled from 'styled-components';

export const Field = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
`;

export const ColumnsField = styled(Field)`
  flex-direction: row;
  position: relative;
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Form = styled.form``;

export const Label = styled.label`
  opacity: 0.45;
  margin-bottom: 0.2rem;
  font-size: 1rem;
`;

export const RightColumnField = styled(Field)`
  left: 0;
  margin-left: 15rem;
  position: absolute;
  top: 0.5rem;
  width: 30%;
`;

export const Error = styled.div`
  color: red;
`;

export const FlexLabel = styled(Label)`
  display: inline-flex;
  gap: 4px;
`;

export const BottomBar = styled.div`
  text-align: right;

  & > button {
    min-width: 150px;
  }
`;
