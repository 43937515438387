import styled from 'styled-components';

import {Typography} from '@material-ui/core';

export const Container = styled.div`
  padding: 20px 30px;
`;

export const Title = styled(Typography).attrs({variant: 'h6'})`
  margin-bottom: 10px;
`;
