import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {Grid, Typography} from '@material-ui/core';

import langs from '../../../localization/langs';

import ActionCell from './ActionCell';
import TaskHeader from './TaskHeader';
import DateField from '../../Base/DateField';
import ProgressCell from './ProgressCell';

import commandModel from '../../../models/command';

import * as Styled from './Task.styles';

function Task({
  command,
  commandType,
  countFailed,
  countPending,
  countSucceeded,
  countRunning,
  creationDate,
  isActive,
  isSelected,
  onClick,
  onChange,
  taskId,
  taskStatus,
  triggeredBy,
  entityName
}) {
  const totalServiceItems = _.sum([countFailed, countRunning, countPending, countSucceeded, 0]);
  const progress = (_.sum([countSucceeded, countFailed, 0]) * 100) / totalServiceItems;
  const buffer = progress + (countRunning * 100) / totalServiceItems;

  return (
    <Styled.Container onClick={onClick} isSelected={isSelected}>
      <TaskHeader progress={progress} buffer={buffer} isActive={isActive} taskStatus={taskStatus} />

      <Styled.InnerContainer>
        <Grid xs={5} item>
          <Styled.Title>
            <DateField date={creationDate} />
          </Styled.Title>

          <Styled.CommandTypeTitle>
            {commandModel.getTextByType(commandType)}
            <Typography variant="body2" color="textSecondary" noWrap>
              {command}
            </Typography>
          </Styled.CommandTypeTitle>
        </Grid>

        <Grid xs={2} item>
          <Styled.Title>{langs('TRIGGERED_BY')}</Styled.Title>
          <Typography color="textPrimary" variant="body2">
            {triggeredBy ? <Styled.PersonIcon /> : <Styled.ComputerIcon />}
            {triggeredBy || langs('SYSTEM')}
          </Typography>
        </Grid>

        <Grid xs={2} item>
          <Styled.Title>
            {langs('AFFECTED')} {entityName}
          </Styled.Title>
          <Typography color="textPrimary" variant="body2">
            {totalServiceItems.toLocaleString()} {entityName}
          </Typography>
        </Grid>

        <Grid xs={2} item>
          <ProgressCell
            countFailed={countFailed}
            countPending={countPending}
            countRunning={countRunning}
            progress={progress}
            taskStatus={taskStatus}
          />
        </Grid>

        <Styled.ActionGrid xs={1} item>
          <ActionCell taskStatus={taskStatus} isActive={isActive} onChange={onChange} taskId={taskId} />
        </Styled.ActionGrid>
      </Styled.InnerContainer>
    </Styled.Container>
  );
}

Task.propTypes = {
  command: PropTypes.string.isRequired,
  commandType: PropTypes.number.isRequired,
  countFailed: PropTypes.number,
  countPending: PropTypes.number,
  countRunning: PropTypes.number,
  countSucceeded: PropTypes.number,
  creationDate: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  taskId: PropTypes.number.isRequired,
  taskStatus: PropTypes.string.isRequired,
  triggeredBy: PropTypes.string
};

export default Task;
