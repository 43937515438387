import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {Tooltip} from '@material-ui/core';

import langs from '../../../localization/langs';

import * as Styled from './EditableColumn.styled';

function EditableColumn({colDef, value}) {
  let hoverIcon = <Styled.HoverIcon fontSize="inherit" />;

  if (colDef.missingPermissions) {
    hoverIcon = (
      <Tooltip title={langs('INSUFFICIENT_PERMISSIONS', {missingPermissions: colDef.missingPermissions})}>
        <Styled.DisabledHoverIcon fontSize="inherit" />
      </Tooltip>
    );
  }

  return (
    <Styled.Wrapper>
      {_.toString(value)}
      {hoverIcon}
    </Styled.Wrapper>
  );
}

EditableColumn.propTypes = {
  colDef: PropTypes.any,
  value: PropTypes.any
};

export default EditableColumn;
