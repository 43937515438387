import {BrowserRouter} from 'react-router-dom';
import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import createTheme from '@material-ui/core/styles/createTheme';
import {StylesProvider, ThemeProvider as MuiThemeProvider} from '@material-ui/styles';

import {ThemeProvider} from 'styled-components';

import AppRoutes from '../../routes';
import AppTheme from '../../theme';
import context from '../../context';

import AxiosInterceptor from './AxiosInterceptor';
import AppCursor from './AppCursor';
import Layout from './Layout';

function App() {
  const theme = React.useMemo(() => createTheme(AppTheme), []);

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <AppCursor>
            <BrowserRouter>
              <context.Search.SearchContextProvider>
                <CssBaseline />
                <context.User.UserContextProvider>
                  <context.Environments.EnvironmentsContextProvider>
                    <AxiosInterceptor>
                      <context.Notifications.NotificationsContextProvider>
                        <context.Navigation.NavigationContextProvider>
                          <Layout>
                            <AppRoutes />
                          </Layout>
                        </context.Navigation.NavigationContextProvider>
                      </context.Notifications.NotificationsContextProvider>
                    </AxiosInterceptor>
                  </context.Environments.EnvironmentsContextProvider>
                </context.User.UserContextProvider>
              </context.Search.SearchContextProvider>
            </BrowserRouter>
          </AppCursor>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
