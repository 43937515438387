import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../../localization/langs';

import Legend from './Legend';
import Table from './Table';

import * as Styled from './NodeInfo.styles';

function openLagPanel(consumerGroup) {
  const dashboard = 'https://grafana.dev.armis.com/d/b7cLET_ik/multi-tenant-kafka-consumer-producer-metrics?';
  const params = new URLSearchParams({
    from: 'now-6h',
    orgId: 1,
    refresh: '60s',
    'var-consumergroup': consumerGroup,
    'var-env': 'prod',
    'var-namespace': 'All',
    viewPanel: 2
  });
  window.open(`${dashboard}${params}`);
}

function NodeInfo({node, layout}) {
  const nodeData = node?.data();
  window.node = node;

  function showSuccessorsAndPredecessors() {
    const nodes = node.successors();
    nodes.merge(node.predecessors());
    select(nodes);
  }

  function removeNode() {
    node.remove();
    const cy = node.cy();
    cy.layout(layout).run();
  }

  function select(nodes) {
    nodes.merge(node);
    node.animate({style: Styled.clickedNodeStyle});
    const cy = node.cy();

    cy.nodes().forEach((otherNode) => {
      if (!nodes.includes(otherNode)) {
        otherNode.remove();
      }
    });

    cy.layout(layout).run();
  }

  return (
    <Styled.Container>
      <Styled.Column>
        <Styled.Title>{langs('NODE_INFO')}</Styled.Title>
        {nodeData && <Table data={nodeData} />}
      </Styled.Column>
      <Styled.Column>
        <Styled.Title>{langs('ACTIONS')}</Styled.Title>
        <Styled.Actions>
          <div>
            <Styled.Button onClick={showSuccessorsAndPredecessors} disabled={!node}>
              {langs('SHOW_SUCCESSORS_AND_PREDECESSORS')}
            </Styled.Button>
          </div>
          <div>
            <Styled.Button onClick={() => openLagPanel(nodeData?.consumerGroup)} disabled={!node}>
              {langs('OPEN_GRAFANA')}
            </Styled.Button>
          </div>
          <div>
            <Styled.Button onClick={removeNode} disabled={!node}>
              Remove Node
            </Styled.Button>
          </div>
        </Styled.Actions>
      </Styled.Column>
      <Styled.Column>
        <Styled.Title>{langs('LEGEND')}</Styled.Title>
        <Legend />
      </Styled.Column>
    </Styled.Container>
  );
}

NodeInfo.propTypes = {
  layout: PropTypes.object.isRequired,
  node: PropTypes.object
};

export default NodeInfo;
