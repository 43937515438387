import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../context';
import langs from '../../localization/langs';
import services from '../../services';

import SelectVersion from '../NewTaskWidget/SelectVersion';

function DeployTenantForm({appDeployCommand, setCommand, setConfigurationValue, setParametersValues, ...props}) {
  const [parameters, setParameters] = React.useState([]);
  const notifications = context.Notifications.useNotifications();

  React.useEffect(() => {
    services.tenants.getAppDeploymentCommand(appDeployCommand).then((command) => {
      if (_.isNil(command)) {
        notifications.error(langs('FAILED_GET_COMMANDS'));
        return;
      }
      setParameters(command.parameters);
      setCommand(command);
    });
  }, []);

  return (
    <SelectVersion
      getArtifactByBranchApi={services.tenants.artifact.getByBranch}
      listArtifactsApi={services.tenants.artifact.listAll}
      parameters={parameters}
      setParametersValues={setParametersValues}
      setValue={(value) => setConfigurationValue({keyName: 'app_deployment_version_id', value})}
      onClick={(value) =>
        setParametersValues((prevState) => ({
          ...prevState,
          branch: _.get(value, 'branch')
        }))
      }
      {...props}
    />
  );
}

DeployTenantForm.propTypes = {
  appDeployCommand: PropTypes.string,
  setCommand: PropTypes.func,
  setConfigurationValue: PropTypes.func,
  setParametersValues: PropTypes.func
};

export default DeployTenantForm;
