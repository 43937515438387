import styled from 'styled-components';

import TextareaAutosize from 'react-textarea-autosize';

import * as theme from '../../theme';

export const Textarea = styled(TextareaAutosize)`
  background: ${theme.textarea.background};
  color: ${theme.textarea.textColor};
  border: 0;

  width: 100%;
  resize: none;

  font-size: 0.8rem;
  padding: 0.4rem;
`;
