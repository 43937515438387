export default {
  ADD_GROUP_SERVICE_ENV_BINDING: 'Add Binding',
  CANCEL_EDIT_BINDING: 'All changes will be lost.',
  DELETE_GROUP_SERVICE_ENV_BINDING: 'Remove Binding',
  DELETE_GROUP_SERVICE_ENV_BINDING_CONFIRMATION: 'Remove binding <%=groupName%>.<%=serviceName%>.<%=envName%>',
  DIALOG_ADD_BINDING_ENV_NAME: 'Environment Name',
  DIALOG_ADD_BINDING_GROUP_NAME: 'IDP Group Name',
  DIALOG_ADD_BINDING_SERVICE_NAME: 'Armis Service Name',
  DIALOG_ADD_BINDING_TITLE: 'Add a new binding',
  ERROR_RETRIEVING_SCHEMA: 'Failed to retrieve schema',
  INSUFFICIENT_PERMISSIONS: 'Insufficient Permissions (<%=missingPermissions%>)',
  RBAC: 'RBAC',
  RBAC_SCHEMA_EDITOR: 'RBAC Schema Editor',
  RBAC_SCHEMA_SELF_VIEW: 'RBAC Schema for User',
  RBAC_SCHEMA_VIEWER: 'RBAC Schema Viewer',
  SAVE_GROUP_SERVICE_ENV_BINDING_CONFIRMATION: 'Save binding <%=groupName%>.<%=serviceName%>.<%=envName%>',
  SELECTED_ENVS: 'Selected Environments: <%=selectedEnvs%>',
  SELECTED_OKTA_GROUP_NAME: 'Selected Group: <%=selectedGroup%>',
  SELECTED_OKTA_GROUP_NAMES: 'Selected Groups: <%=selectedGroups%>',
  SELECTED_OKTA_LOGIN: 'Selected OKTA User:',
  SELECTED_OKTA_LOGIN_SET: 'Select',
  SELECTED_ROLES: 'Selected Roles: <%=selectedRoles%>',
  SELECTED_SERVICES: 'Selected Services: <%=selectedServices%>',
  VIEW_MODE: 'View Mode: <%=viewMode%>'
};
