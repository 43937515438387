import styled from 'styled-components';

import {createTheme} from '@material-ui/core/styles';

import * as theme from '../../../theme';

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
`;

export const TableContainer = styled.div`
  padding: 10px;
`;

export const deploymentsTableTheme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: '1.25rem'
      }
    },
    MuiTableCell: {
      head: {
        border: 'none',
        color: `${theme.default.palette.text.secondary}`,
        fontSize: 12,
        padding: 0,
        paddingLeft: 8
      },
      root: {
        padding: '8px',
        whiteSpace: 'nowrap'
      }
    }
  }
});
