import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './ConfigurationInput.styles';

function ConfigurationInput({value, onChange, ...props}) {
  return <Styled.Container value={value} onChange={({target: {value}}) => onChange(value)} fullWidth {...props} />;
}

ConfigurationInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired])
};

export default ConfigurationInput;
