import styled from 'styled-components';

import {Button, Grid} from '@material-ui/core';

import * as theme from '../../../../theme';

export const GrowingContainer = styled(Grid).attrs({item: true})`
  flex-grow: 1;
`;

export const CollectorNumberErrorMessage = styled.div`
  color: ${theme.statusColors.error};
`;

export const PasteValuesButton = styled(Button)`
  margin-left: auto;
`;
