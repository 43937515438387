import styled from 'styled-components';

import {Typography, Dialog as MuiDialog} from '@material-ui/core';

import {Link as BaseLink} from '../../Base';

import {Button as MuiButton} from '@material-ui/core';
import * as theme from '../../../theme';

export const TenantName = styled(Typography).attrs({color: 'textSecondary', component: 'div', variant: 'h6'})`
  margin-bottom: 10px;
`;

export const Title = styled(Typography).attrs({component: 'div', variant: 'h5'})`
  margin-bottom: 10px;
`;

export const Dialog = styled(MuiDialog).attrs({fullWidth: true, maxWidth: 'md'})`
  margin: 4px 0;
`;

export const Link = styled(BaseLink).attrs({$dense: true})``;

export const Button = styled(MuiButton).attrs({size: 'small'})`
  padding: 0;
`;

export const Flex = styled.div`
  display: flex;
`;

export const Row = styled(Flex)`
  border-bottom: 1px ${theme.separators.transparent} solid;
  padding: 5px;
`;

export const StatusColumn = styled(Flex)`
  flex: 5;
`;

export const ViewLogsColumn = styled(Flex)`
  justify-content: center;
  flex-grow: 1;
`;

export const DateColumn = styled(Flex)`
  justify-content: flex-end;
  flex: 4;
`;
