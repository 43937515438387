import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {DynamicInput} from '../../Base';

import * as Styled from './ValueColumn.styles';

function ValueColumn({type, extendedTypes, value: {value}}) {
  return (
    <Styled.Container>
      <DynamicInput disabled type={type} value={value} onChange={_.stubTrue} extendedTypes={extendedTypes} />
    </Styled.Container>
  );
}

ValueColumn.propTypes = {
  extendedTypes: PropTypes.objectOf(PropTypes.elementType).isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.shape({
    value: PropTypes.any
  }).isRequired
};

export default ValueColumn;
