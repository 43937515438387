import _ from 'lodash';
import client from './client';

async function getReleases({length, from, orderBy, searchQuery = ''}) {
  const fromQuery = from ? `&from=${from}` : '';
  const orderByQuery = orderBy ? `&orderBy=${orderBy}` : '';
  const searchByQuery = _.isEmpty(searchQuery) ? '' : `&${searchQuery}`;

  return client.get(`/release/api/v1/releases?length=${length}${fromQuery}${orderByQuery}${searchByQuery}`);
}

async function listPullRequests(search, state = 'closed', merged = true) {
  return client.get('/release/api/v1/pull-requests', {
    merged,
    search,
    state
  });
}

export async function create({
  deploymentParams,
  deploymentTypes,
  jiraTicket,
  prIds,
  reason,
  releases,
  requestType,
  urgency
}) {
  return client.post('/release/api/v1/release-requests', {
    deploymentParams,
    deploymentTypes,
    jiraTicket,
    prIds,
    reason,
    releases,
    requestType,
    urgency
  });
}

async function validatePullRequest(pullRequestId) {
  return client.post(`/release/api/v1/pull-requests/${pullRequestId}/_validate`);
}

export async function getAllFilterOptions(field, query) {
  return client.get(`/release/api/v1/releases/_count?${query}&groupBy=${field}&length=-1`);
}

async function submitReviewRequest(prId, isApproved) {
  return client.post(`/release/api/v1/pull-requests/${prId}/_submit_review`, {isApproved});
}

async function validateGithubToken() {
  return client.post('/release/api/v1/users/github-token/_validate');
}

export default {
  create,
  getAllFilterOptions,
  getReleases,
  listPullRequests,
  submitReviewRequest,
  validateGithubToken,
  validatePullRequest
};
