import styled from 'styled-components';

import {Grid, Paper} from '@material-ui/core';

import {PROGRESS_BAR_HEIGHT} from '../../../Base/ProgressBar.styles';

const EDITOR_ACTIONS_PADDING_OFFSET = 30;
const ITEMS_GRID_TOP_PADDING = 35;
const MAIN_GRID_BOTTOM_PADDING = 15;
const MAIN_GRID_TOP_PADDING = 30;
const TOP_PANEL_HEIGHT = 74;

export const Container = styled(Paper)`
  white-space: nowrap;
  height: calc(100vh - ${TOP_PANEL_HEIGHT}px - ${MAIN_GRID_TOP_PADDING}px - ${MAIN_GRID_BOTTOM_PADDING}px);
`;

export const ItemsGrid = styled(Grid)`
  height: 100%;
  padding-top: ${ITEMS_GRID_TOP_PADDING}px;
  overflow-y: none;
`;

export const MainGrid = styled(Grid)`
  height: calc(100% - ${PROGRESS_BAR_HEIGHT}px);
`;

export const ScrollableContainer = styled(Grid)`
  overflow-y: auto;
  height: calc(100% - ${ITEMS_GRID_TOP_PADDING + EDITOR_ACTIONS_PADDING_OFFSET}px);
  width: 100%;
`;
