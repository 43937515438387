import styled, {css} from 'styled-components';

import * as theme from '../../theme';

export const Tabs = styled.div`
  display: flex;
  border-bottom: 1px ${theme.tabBar.bottomBorder} solid;
  font-weight: 500;

  margin-bottom: 0.5rem;

  overflow-y: auto;
`;

export const Tab = styled.div`
  min-width: 100px;

  text-align: center;
  text-transform: capitalize;

  cursor: pointer;
  color: ${theme.tabBar.textColor};
  opacity: 0.4;

  padding: 0.2rem 1rem;

  user-select: none;

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.2 !important;
    `};

  ${(props) =>
    props.selected &&
    css`
      opacity: 1;
      border-bottom: 3px ${theme.tabBar.textColor} solid;
    `};

  ${(props) =>
    !props.selected &&
    css`
      &:hover {
        opacity: 0.7;
      }
    `};
`;
