import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import hooks from '../../../../hooks';
import langs from '../../../../localization/langs';
import services from '../../../../services';

import {Form, Paper} from '../../../../components/Base';
import TextSearchInput from '../../../../components/TextSearchInput';

function CopyTenantConfig({setCopyConfigData}) {
  const [copyConfigTenant, setCopyConfigTenant] = React.useState(null);
  const [isCopyConfig, setIsCopyConfig] = React.useState(false);
  const [tenantsResponse, isLoadingTenants] = hooks.useGetResult(
    () =>
      services.tenants.getByQuery({
        length: -1,
        searchQuery: 'environmentName=dev1&status=AVAILABILITY_STATUS_ACTIVE'
      }),
    []
  );

  React.useEffect(() => {
    if (isCopyConfig && !_.isEmpty(copyConfigTenant)) {
      setCopyConfigData({app_deployment_config: {source: _.get(copyConfigTenant, 'tenantName')}});
    } else {
      setCopyConfigData(null);
    }
  }, [isCopyConfig, copyConfigTenant]);

  const tenants = _.sortBy(tenantsResponse?.items, 'tenantName');
  return (
    <Paper padding={2} style={{paddingBlock: '1em'}}>
      <Form.Label>{langs('TENANT_COPY_APPLICATION_CONFIG')}</Form.Label>
      <Form.ColumnsField>
        <FormControlLabel
          label={`${langs('TENANT_COPY_APPLICATION_CONFIG')} ${_.lowerCase(langs('FROM'))}`}
          control={<Checkbox checked={isCopyConfig} onChange={({target: {checked}}) => setIsCopyConfig(checked)} />}
        />

        <Form.RightColumnField>
          {isCopyConfig && (
            <TextSearchInput
              loading={isLoadingTenants}
              multiple={false}
              options={tenants || []}
              value={copyConfigTenant}
              disabled={!isCopyConfig}
              getOptionLabel={({tenantName}) => tenantName}
              inputPlaceholder={langs('CHOOSE_TENANT')}
              setValue={(value) => setCopyConfigTenant(value)}
            />
          )}
        </Form.RightColumnField>
      </Form.ColumnsField>
    </Paper>
  );
}

CopyTenantConfig.propTypes = {
  setCopyConfigData: PropTypes.func.isRequired
};

export default CopyTenantConfig;
