import _ from 'lodash';
import {format} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import {AsyncButton, Button, Textarea} from '../../Base';

import langs from '../../../localization/langs';

import * as Styled from './Notes.styles';

function Notes({initialNote, onSave}) {
  const [dirty, setDirty] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [note, setNote] = React.useState(initialNote);

  React.useEffect(() => {
    setNote(initialNote);
  }, [initialNote]);

  function onChange(event) {
    setNote({...note, text: event.target.value});
    if (!dirty) {
      setDirty(true);
    }
  }

  function discard() {
    setDirty(false);
    setNote(initialNote);
  }

  async function save() {
    setIsSaving(true);
    await onSave(note.text);
    setIsSaving(false);
    setDirty(false);
  }

  const metadata = _.isEmpty(note)
    ? ''
    : langs('LAST_EDITED_ON', {
        date: note.lastUpdate ? format(new Date(note.lastUpdate), 'PPpp') : langs('NA'),
        user: note.user
      });

  return (
    <Styled.Container>
      <Styled.HeaderAction>
        <Styled.Header>{langs('NOTES')}</Styled.Header>
        {dirty ? (
          <>
            <Button text size="small" disabled={!dirty || isSaving} onClick={discard}>
              {langs('BUTTON_DISCARD_CHANGES')}
            </Button>
            <AsyncButton size="small" onClick={save}>
              {langs('BUTTON_SAVE_CHANGES')}
            </AsyncButton>
          </>
        ) : (
          <Styled.Author>{metadata}</Styled.Author>
        )}
      </Styled.HeaderAction>
      <Textarea
        minRows={3}
        onChange={onChange}
        value={_.isEmpty(note) ? '' : note.text}
        placeholder={langs('ENTER_YOUR_NOTES')}
        readOnly={isSaving}
      />
    </Styled.Container>
  );
}

Notes.propTypes = {
  initialNote: PropTypes.shape({
    lastUpdate: PropTypes.string,
    text: PropTypes.string,
    user: PropTypes.string
  }),
  onSave: PropTypes.func.isRequired
};

export default Notes;
