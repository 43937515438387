import _ from 'lodash';

function getPermissions(envName) {
  return [
    `cms.${envName}.beacon_manage`,
    `cms.${envName}.beacon_provision`,
    `cms.${envName}.collector_decommission`,
    `cms.${envName}.collector_delete`,
    `cms.${envName}.collector_mark_operational`,
    `cms.${envName}.edit_configuration`,
    `cms.${envName}.edit_configuration.edit_configuration_category_all`,
    `cms.${envName}.edit_configuration.edit_configuration_category_app_deployment`,
    `cms.${envName}.edit_configuration.edit_configuration_category_cms`,
    `cms.${envName}.edit_configuration.edit_configuration_category_firmware`,
    `cms.${envName}.edit_configuration.edit_configuration_category_provision`,
    `cms.${envName}.edit_configuration.edit_configuration_level_all_all`,
    `cms.${envName}.edit_configuration.edit_configuration_level_all_collectors`,
    `cms.${envName}.edit_configuration.edit_configuration_level_all_tenants`,
    `cms.${envName}.run_task_elevated`,
    `cms.${envName}.run_task_elevated_low`,
    `cms.${envName}.run_task_elevated_medium`,
    `cms.${envName}.run_task_field_action`,
    `cms.${envName}.run_task_non_elevated`,
    `maestro.${envName}.edit_configuration`,
    `maestro.${envName}.edit_configuration.edit_configuration_category_all`,
    `maestro.${envName}.edit_configuration.edit_configuration_category_app_deployment`,
    `maestro.${envName}.edit_configuration.edit_configuration_category_firmware`,
    `maestro.${envName}.edit_configuration.edit_configuration_category_terraform`,
    `maestro.${envName}.edit_configuration.edit_configuration_category_tms`,
    `maestro.${envName}.edit_configuration.edit_configuration_level_all_all`,
    `maestro.${envName}.edit_configuration.edit_configuration_level_all_envs`,
    `maestro.${envName}.edit_configuration.edit_configuration_level_all_tenants`,
    `maestro.${envName}.run_task_elevated`,
    `maestro.${envName}.run_task_elevated_low`,
    `maestro.${envName}.run_task_elevated_medium`,
    `maestro.${envName}.run_task_field_action`,
    `maestro.${envName}.run_task_non_elevated`,
    `maestro.${envName}.tenant_create`,
    `maestro.${envName}.tenant_destroy`,
    `maestro.${envName}.tenant_read`,
    `maestro.${envName}.tenant_stop`,
    `maestro.${envName}.tenant_update`,
    `mtms.${envName}.edit_configuration`,
    `mtms.${envName}.edit_configuration.edit_configuration_category_all`,
    `mtms.${envName}.edit_configuration.edit_configuration_category_secrets`,
    `mtms.${envName}.edit_configuration.edit_configuration_level_all_all`,
    `mtms.${envName}.edit_configuration.edit_configuration_level_all_envs`,
    `mtms.${envName}.edit_configuration.edit_configuration_level_all_namespaces`,
    `mtms.${envName}.mt_envs_all`,
    `mtms.${envName}.mt_envs_deploy`,
    `mtms.${envName}.mt_envs_read`,
    `mtms.${envName}.mt_envs_restart`,
    `mtms.${envName}.mt_envs_scale`,
    `mtms.${envName}.mt_envs_stop`,
    `mtms.${envName}.mt_namespaces_all`,
    `mtms.${envName}.mt_namespaces_create`,
    `mtms.${envName}.mt_namespaces_deploy`,
    `mtms.${envName}.mt_namespaces_edit`,
    `mtms.${envName}.mt_namespaces_read`,
    `mtms.${envName}.mt_namespaces_restart`,
    `mtms.${envName}.mt_namespaces_scale`,
    `mtms.${envName}.mt_namespaces_stop`,
    `mtms.${envName}.mt_services_all`,
    `mtms.${envName}.mt_services_create`,
    `mtms.${envName}.mt_services_deploy`,
    `mtms.${envName}.mt_services_edit`,
    `mtms.${envName}.mt_services_read`,
    `mtms.${envName}.mt_services_restart`,
    `mtms.${envName}.mt_services_scale`,
    `mtms.${envName}.mt_services_show_logs`,
    `mtms.${envName}.mt_services_stop`,
    `mtms.${envName}.mt_stacks_all`,
    `mtms.${envName}.mt_stacks_create`,
    `mtms.${envName}.mt_stacks_deploy`,
    `mtms.${envName}.mt_stacks_edit`,
    `mtms.${envName}.mt_stacks_read`,
    `mtms.${envName}.mt_stacks_restart`,
    `mtms.${envName}.mt_stacks_scale`,
    `mtms.${envName}.mt_stacks_stop`,
    `ops-web.${envName}.view_collectors`,
    `ops-web.${envName}.view_collectors_config`,
    `ops-web.${envName}.view_collectors_images`,
    `ops-web.${envName}.view_collectors_tasks`,
    `ops-web.${envName}.view_mt`,
    `ops-web.${envName}.view_mt_config`,
    `ops-web.${envName}.view_mt_namespaces`,
    `ops-web.${envName}.view_mt_services`,
    `ops-web.${envName}.view_mt_stacks`,
    `ops-web.${envName}.view_mt_tasks`,
    `ops-web.${envName}.view_tenants`,
    `ops-web.${envName}.view_tenants_config`,
    `ops-web.${envName}.view_tenants_tasks`
  ];
}

export function getFedRampPermissions(envNames) {
  const permissions = _(envNames)
    .map(getPermissions)
    .flatten()
    .value();
  return {permissions, roles: []};
}
