import React from 'react';

import context from '../../../context';

import AccountCircle from './AccountCircle';
import EnvSelector from './EnvSelector';

import * as Styled from './UserInfo.styles';

function overrideActive() {
  return window.RBAC && (window.RBAC.state.isActive() || window.RBAC.viewAs.isActive());
}

function UserInfo() {
  const user = context.User.useUser();
  return (
    <Styled.Container>
      {user.rbac.opsWeb.isEnabled() && user.rbac.opsWeb.allowed.view.viewAs() && (
        <Styled.RbacOverrideState active={overrideActive()}> RBAC Override Active</Styled.RbacOverrideState>
      )}
      <EnvSelector />
      <Styled.Email>{user.email}</Styled.Email>
      <AccountCircle />
    </Styled.Container>
  );
}

export default UserInfo;
