import styled from 'styled-components';

import * as theme from '../../../../../theme';

export const Link = styled.a`
  color: ${theme.link.text};
  &:hover {
    color: ${theme.link.hover};
  }
`;
