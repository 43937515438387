import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import consts from '../consts';
import hooks from '../hooks';

export const TableFiltersContext = React.createContext(null);

export function TableFiltersContextProvider({getFilters, children}) {
  const [options, setOptions] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  const [currentFilters, setUrlQuery] = hooks.useUrlSearchParams();

  async function fetchOptions(field, query, filterOperator = consts.FILTER_OPERATORS.LIKE) {
    try {
      if (filterOperator === consts.FILTER_OPERATORS.LIKE) {
        query = `%${query}%`;
      }
      setIsLoading(true);
      const response = await getFilters(field, query, filterOperator);
      const filters = _.get(response, 'data', []);

      const filtersValue = _(filters)
        .map(({name}) => ({id: _.toString(name), name: _.toString(name)}))
        .reject((filter) => _.isEmpty(filter.id))
        .value();

      setOptions(_.assign({}, options, {[field]: filtersValue}));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const tableFiltersContext = {currentFilters, fetchOptions, isLoading, options, setUrlQuery};

  return <TableFiltersContext.Provider value={tableFiltersContext}>{children}</TableFiltersContext.Provider>;
}

TableFiltersContextProvider.propTypes = {
  getFilters: PropTypes.func.isRequired
};

export const useTableFilters = () => React.useContext(TableFiltersContext);
