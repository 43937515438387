import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {Checkbox, FormControlLabel} from '@material-ui/core';

import langs from '../../../../localization/langs';
import servicesApi from '../../../../services';
import {DEPLOYMENT_TYPES} from '../utils';

import * as Styled from './Common.styles';

function MultiTenantDeployment({
  envsSelection,
  deploymentTypesSelection,
  onChangeType,
  servicesSelection,
  setServicesSelection
}) {
  const [services, setServices] = React.useState([]);
  const isChecked = _.get(deploymentTypesSelection, [DEPLOYMENT_TYPES.MULTI_TENANT.value, 'checked'], false);

  React.useEffect(() => {
    fetchServices(envsSelection).then(setServices);
  }, [envsSelection, setServices]);

  function onChangeCheckbox(event) {
    if (!event.target.checked) {
      setServicesSelection([]);
    }
    onChangeType(event.target.name, event.target.checked);
  }

  async function fetchServices(envs) {
    let searchParams = 'length=-1';
    if (!_.isEmpty(envs)) {
      searchParams += `&environmentName=${envs}`;
    }
    const {
      data: {items}
    } = await servicesApi.services.deployments.list(searchParams);
    return _.uniqBy(
      _.map(items, (item) => ({id: item.deploymentId, serviceName: item.serviceName})),
      'serviceName'
    );
  }

  return (
    <Styled.DeploymentSection>
      <FormControlLabel
        control={
          <Checkbox checked={isChecked} onChange={onChangeCheckbox} name={DEPLOYMENT_TYPES.MULTI_TENANT.value} />
        }
        label={DEPLOYMENT_TYPES.MULTI_TENANT.displayName}
      />
      <Styled.TextSearch
        multiple
        options={services}
        value={servicesSelection}
        label="serviceName"
        disabled={!isChecked}
        inputPlaceholder={langs('CHOOSE_SERVICES')}
        setValue={setServicesSelection}
      />
    </Styled.DeploymentSection>
  );
}

MultiTenantDeployment.propTypes = {
  deploymentTypesSelection: PropTypes.object,
  envsSelection: PropTypes.array,
  onChangeType: PropTypes.func,
  servicesSelection: PropTypes.array,
  setServicesSelection: PropTypes.func
};

export default MultiTenantDeployment;
