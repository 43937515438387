import _ from 'lodash';
import React from 'react';

import {Button, Paper} from '@material-ui/core';
import {ThemeProvider, useTheme} from '@material-ui/core/styles';

import columnDefs from '../../../utils/agGridUtils/columnDefs';
import consts from '../../../consts';
import dataUtils from './dataUtils';
import hooks from '../../../hooks';
import langs from '../../../localization/langs';
import models from '../../../models';

import Column from '../../../components/AGTable/Column';
import HeadCharts from '../../../components/Charts/HeadCharts/HeadCharts';
import ReplayTask from '../../../components/ReplayTask';
import Table from '../../../components/AGTable/Table';
import useActions from './actions/useActions';
import useUrlSearch from '../../../components/AGTable/hooks/useUrlSearch';

import * as Styled from './DeploymentsView.styles';

const SET_FILTER_COL = {
  ...columnDefs.BASIC_COL,
  filter: async ({colDef, success}) => success(await dataUtils.getFilterData(colDef)),
  filterParams: {refreshValuesOnOpen: false}
};

const GROUP_BY_CONFIG = [
  {key: models.deployments.fields.ENVIRONMENT_NAME, name: langs('DEPLOYMENTS_FIELD_ENV')},
  {key: models.deployments.fields.NAMESPACE_NAME, name: langs('DEPLOYMENTS_FIELD_NAMESPACE')},
  {key: models.deployments.fields.STACK_NAME, name: langs('DEPLOYMENTS_FIELD_STACK')},
  {key: models.deployments.fields.SERVICE_NAME, name: langs('DEPLOYMENTS_FIELD_SERVICE')}
];
const GROUP_BY_SIZE = _.size(GROUP_BY_CONFIG);
const GROUP_INDEX_MAIN = _.findIndex(GROUP_BY_CONFIG, {key: models.deployments.fields.SERVICE_NAME});
const GROUP_INDEX_NS = _.findIndex(GROUP_BY_CONFIG, {key: models.deployments.fields.NAMESPACE_NAME});
const GROUP_INDEX_STACK = _.findIndex(GROUP_BY_CONFIG, {key: models.deployments.fields.STACK_NAME});

function getGroupBy(groupByKey) {
  const groupBy = [];
  for (const {key} of GROUP_BY_CONFIG) {
    groupBy.push(key);
    if (key === groupByKey) {
      break;
    }
  }
  return groupBy;
}

function DeploymentsView() {
  const [searchQuery, , , setUrlSearchParams] = hooks.useUrlSearchParams();
  const [tableApi, setTableApi] = React.useState();
  const [selectedDeployments, setsSelectedDeployments] = React.useState([]);
  const [taskId] = hooks.useSearchQueryFilter('taskId', consts.FILTER_OPERATORS.EQUAL);

  const gridApi = tableApi?.gridApi;
  const groupByKey = searchQuery.get('groupBy') || GROUP_BY_CONFIG[GROUP_INDEX_MAIN].key;
  const [dialogs, getContextMenuItems] = useActions(selectedDeployments, groupByKey);
  const theme = useTheme();

  theme.overrides = {...theme.overrides, ...Styled.deploymentsTableTheme.overrides};

  function modifyParamsAfterFilterChange(newSearchQuery) {
    const newUrlSearchParams = new URLSearchParams(newSearchQuery);
    newUrlSearchParams.set('groupBy', groupByKey);
    dataUtils.updateFilters(gridApi);
    return newUrlSearchParams;
  }

  const [filterModel, onFilterChanged] = useUrlSearch(tableApi, modifyParamsAfterFilterChange);

  React.useEffect(() => {
    if (!gridApi) {
      return;
    }
    gridApi.groupBy = getGroupBy(groupByKey);
  }, [gridApi, groupByKey]);

  React.useEffect(() => {
    if (!gridApi) {
      return;
    }
    const filtersPanel = gridApi.getToolPanelInstance('filters');
    filtersPanel.expandFilters();
  }, [gridApi, searchQuery]);

  const groupBy = getGroupBy(groupByKey);
  const groupBySize = _.size(groupBy);

  const onCellClicked = React.useCallback(
    (nextGroupByKey, currentGroupByValue) => {
      const urlSearchParams = new URLSearchParams(searchQuery);
      urlSearchParams.set('groupBy', nextGroupByKey);
      urlSearchParams.set(`${groupByKey}${consts.FILTER_OPERATORS.LIKE}`, currentGroupByValue);
      setUrlSearchParams(urlSearchParams);
    },
    [groupByKey, searchQuery.toString()]
  );

  function changeScope(nextGroupByKey) {
    const urlSearchParams = new URLSearchParams(searchQuery);
    urlSearchParams.set('groupBy', nextGroupByKey);
    const nextGroupByKeyIndex = _.findIndex(GROUP_BY_CONFIG, {key: nextGroupByKey});
    _.forEach(_.slice(GROUP_BY_CONFIG, nextGroupByKeyIndex), ({key}) => {
      urlSearchParams.delete(`${key}${consts.FILTER_OPERATORS.LIKE}`);
    });
    setUrlSearchParams(urlSearchParams);
  }

  function getUniqueDeploymentName(row) {
    let name = row.environmentName;
    if (!_.isNil(row.namespaceName)) {
      name = `${name}/${row.namespaceName}`;
    }
    if (!_.isNil(row.stackName)) {
      name = `${name}/${row.stackName}`;
    }
    if (!_.isNil(row.deploymentId)) {
      name = `${name}/${row.deploymentId}`;
    }
    return name;
  }

  return (
    <>
      <HeadCharts
        countApi={dataUtils.getHeadChartsData}
        bars={[
          {field: models.deployments.fields.NAMESPACE_NAME, title: 'DEPLOYMENTS_FIELD_NAMESPACE'},
          {field: models.deployments.fields.SERVICE_NAME, title: 'DEPLOYMENTS_FIELD_SERVICE'},
          {field: models.deployments.fields.STACK_NAME, title: 'DEPLOYMENTS_FIELD_STACK'}
        ]}
      />
      <Styled.ActionContainer>
        {taskId && groupByKey === models.deployments.fields.SERVICE_NAME && (
          <ReplayTask
            taskId={taskId}
            hostIds={_.map(selectedDeployments, (deployment) => _.toInteger(deployment.deploymentId))}
            apiPrefix={consts.API_PREFIX_BY_SERVICE_NAME.deployments}
            tasksPage={'services.tasks'}
          />
        )}
      </Styled.ActionContainer>
      <Paper>
        <ThemeProvider theme={theme}>
          <Styled.TableContainer>
            <Table
              actions={() =>
                _.map(GROUP_BY_CONFIG, ({name, key}, index) => (
                  <Button
                    key={key}
                    color={key === groupByKey ? 'secondary' : undefined}
                    onClick={() => changeScope(key)}
                  >
                    {name}
                  </Button>
                ))
              }
              dataDeps={[groupByKey]}
              columnsDeps={[groupBySize, tableApi]}
              data={dataUtils.getRows}
              filterModel={filterModel}
              getContextMenuItems={getContextMenuItems}
              onFilterChanged={onFilterChanged}
              selected={selectedDeployments}
              onSelect={setsSelectedDeployments}
              pagination
              paginationPageSize={50}
              setTableApi={setTableApi}
              title={langs('DEPLOYMENTS')}
              toolPanelProps={{defaultToolPanel: 'filters', position: 'left'}}
              rowIdField={getUniqueDeploymentName}
            >
              <Column
                {...SET_FILTER_COL}
                onCellClicked={({value}) => onCellClicked(models.deployments.fields.NAMESPACE_NAME, value)}
                title={langs('DEPLOYMENTS_FIELD_ENV')}
                value={models.deployments.fields.ENVIRONMENT_NAME}
                minWidth={120}
              />
              <Column
                {...SET_FILTER_COL}
                hide={groupBySize <= GROUP_INDEX_NS}
                onCellClicked={({value}) => onCellClicked(models.deployments.fields.STACK_NAME, value)}
                title={langs('DEPLOYMENTS_FIELD_NAMESPACE')}
                value={models.deployments.fields.NAMESPACE_NAME}
                minWidth={150}
              />
              <Column
                {...SET_FILTER_COL}
                hide={groupBySize <= GROUP_INDEX_STACK}
                onCellClicked={({value}) => onCellClicked(models.deployments.fields.SERVICE_NAME, value)}
                title={langs('DEPLOYMENTS_FIELD_STACK')}
                value={models.deployments.fields.STACK_NAME}
                minWidth={200}
              />
              <Column
                hide={groupBySize === GROUP_BY_SIZE}
                title={langs('DEPLOYMENTS_FIELD_COUNT')}
                value={({count}) => (count ? count.toLocaleString() : '')}
              />
              <Column
                {...SET_FILTER_COL}
                hide={groupBySize <= GROUP_INDEX_MAIN}
                title={langs('DEPLOYMENTS_FIELD_SERVICE')}
                value={models.deployments.fields.SERVICE_NAME}
                minWidth={200}
              />
              <Column
                {...SET_FILTER_COL}
                hide={groupBySize <= GROUP_INDEX_MAIN}
                title={langs('DEPLOYMENTS_FIELD_BRANCH')}
                value="deploymentBranch"
              />
              <Column
                {...columnDefs.ENUM_COL}
                hide={groupBySize === GROUP_BY_SIZE}
                onCellClicked={({value}) => onCellClicked(models.deployments.fields.RELEASE_CYCLE, value)}
                filter={_.values(models.staticData.DEPLOYMENTS.RELEASE_CYCLE)}
                title={langs('RELEASE_CYCLE')}
                value={models.deployments.fields.RELEASE_CYCLE}
              />
              <Column
                {...columnDefs.BASIC_COL}
                title={langs('DEPLOYMENTS_FIELD_DEPLOYMENT_ID')}
                value="deploymentId"
                hide
              />
              <Column
                {...columnDefs.DATE_COL}
                title={langs('DEPLOYMENTS_FIELD_DEPLOYMENT_DATE')}
                value="deploymentDate"
              />
              <Column
                {...SET_FILTER_COL}
                hide={groupBySize <= GROUP_INDEX_MAIN}
                title={langs('DEPLOYMENTS_FIELD_OWNER')}
                value="serviceOwner"
              />
              <Column {...SET_FILTER_COL} title={langs('DEPLOYMENTS_FIELD_DESCRIPTION')} value="description" hide />
            </Table>
            {dialogs}
          </Styled.TableContainer>
        </ThemeProvider>
      </Paper>
    </>
  );
}

export default DeploymentsView;
