import PropTypes from 'prop-types';
import React from 'react';

import services from '../../services';

import ArtifactInput from '../Base/inputs/ArtifactInput';

function CollectorsArtifactInput({value, onChange, disabled}) {
  return (
    <ArtifactInput
      disabled={disabled}
      onChange={onChange}
      value={value}
      getArtifact={services.collectors.getArtifact}
      listArtifacts={services.collectors.listArtifacts}
    />
  );
}

CollectorsArtifactInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired
};

export default CollectorsArtifactInput;
