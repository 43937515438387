import React from 'react';

import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import models from '../../../models';
import roles from '../../../roles';
import staticData from '../../../models/staticData';
import NavbarLink from '../../NavbarLink';
import Visible from '../../Base/Visible';

import * as Styled from './NavBar.styles';

function NavBar() {
  const {getUrl} = context.Navigation.useNavigation();
  const user = context.User.useUser();
  const rbacEnabled = user.rbac.opsWeb.isEnabled();

  const NAVBAR_LINKS = [
    {
      label: langs('TENANTS'),
      nestedLinks: [
        {
          label: langs('TASKS'),
          permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.tenants.tasks : undefined,
          role: roles.SHOW_TASKS,
          url: getUrl('tenants.tasks')
        },
        {
          label: langs('CONFIGURATIONS'),
          permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.tenants.config : undefined,
          role: roles.SHOW_CONFIGURATIONS,
          url: getUrl('tenants.configuration')
        }
      ],
      permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.tenants.view : undefined,
      role: roles.SHOW_TENANTS,
      url: getUrl('tenants.main')
    },
    {
      label: langs('COLLECTORS'),
      nestedLinks: [
        {
          label: langs('TASKS'),
          permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.collectors.tasks : undefined,
          role: roles.SHOW_TASKS,
          url: getUrl('collectors.tasks')
        },
        {
          label: langs('CONFIGURATIONS'),
          permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.collectors.config : undefined,
          role: roles.SHOW_CONFIGURATIONS,
          url: getUrl('collectors.configuration')
        },
        {
          label: langs('BEACONS'),
          permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.collectors.beacons : undefined,
          role: roles.MANAGE_BEACONS,
          url: getUrl('collectors.beacons')
        },
        {
          label: langs('IMAGES'),
          permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.collectors.images : undefined,
          role: roles.SHOW_IMAGES,
          url: getUrl('collectors.images')
        }
      ],
      permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.collectors.view : undefined,
      role: roles.SHOW_COLLECTORS,
      url: getUrl('collectors.main')
    },
    {
      label: langs('MULTI_TENANT'),
      nestedLinks: [
        {
          label: langs('TASKS'),
          permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.multiTenant.tasks : undefined,
          role: roles.SHOW_TASKS,
          url: getUrl('services.tasks')
        },
        {
          label: langs('NAMESPACES'),
          permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.multiTenant.namespaces : undefined,
          role: roles.SHOW_TASKS,
          url: getUrl('services.namespaces')
        },
        {
          label: langs('STACKS'),
          permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.multiTenant.stacks : undefined,
          role: roles.SHOW_TASKS,
          url: getUrl('services.stacks')
        },
        {
          label: langs('SERVICES'),
          permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.multiTenant.tasks : undefined,
          role: roles.SHOW_TASKS,
          url: getUrl('services.services')
        },
        {
          label: langs('CONFIGURATIONS'),
          permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.multiTenant.config : undefined,
          role: roles.SHOW_CONFIGURATIONS,
          url: getUrl('services.configuration')
        }
      ],
      permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.multiTenant.view : undefined,
      role: roles.SHOW_TASKS,
      url: getUrl('services.main', {}, {groupBy: ['', models.deployments.fields.SERVICE_NAME]})
    },
    {
      label: langs('DEMO'),
      nestedLinks: [],
      permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.demo : undefined,
      role: roles.SHOW_DEMO,
      url: getUrl('demo.main', {}, {stage: [consts.FILTER_OPERATORS.EQUAL, staticData.TENANT.STAGES_TRANSLATED.DEMO]})
    },
    {
      label: langs('RELEASES'),
      nestedLinks: [
        {
          label: langs('SHRAGA'),
          permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.cherryPicks : undefined,
          role: roles.SHOW_CHERRY_PICKS,
          url: getUrl('releases.cherryPicks')
        }
      ],
      permissionFunc: rbacEnabled ? user.rbac.opsWeb.allowed.view.cherryPicks : undefined,
      role: roles.SHOW_CHERRY_PICKS,
      url: getUrl('releases.main')
    }
  ];

  return (
    <Styled.Container>
      {NAVBAR_LINKS.map(({url, label, nestedLinks, role, permissionFunc}) => (
        <Visible key={role} permissionFunc={permissionFunc} role={role}>
          <NavbarLink url={url} label={label} nestedLinks={nestedLinks} />
        </Visible>
      ))}
    </Styled.Container>
  );
}

export default NavBar;
