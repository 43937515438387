import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {DynamicInput} from '../index';

import langs from '../../../localization/langs';
import models from '../../../models';

import * as Styled from './TaskParametersForm.styles';

function getValue(parameter, parametersValues) {
  return parametersValues[parameter.name] ?? models.configuration.getDefaultValue(parameter);
}

function TaskParametersForm({flat, parameters, parametersValues, setParametersValues, extendedTypes}) {
  function onChange(parameter) {
    return (value) => setParametersValues((parametersValues) => ({...parametersValues, [parameter.name]: value}));
  }

  React.useEffect(() => {
    setParametersValues(
      _.transform(
        parameters,
        (result, parameter) => {
          result[parameter.name] = getValue(parameter, parametersValues);
        },
        {}
      )
    );
  }, []);

  return (
    <>
      <Styled.Title>{langs('PARAMETERS')}</Styled.Title>
      <Styled.Container $flat={flat}>
        {_.map(parameters, (parameter) => {
          if (parameter.hidden) {
            return null;
          }
          return (
            <React.Fragment key={parameter.name}>
              <span>{parameter.label || parameter.name}</span>
              <div>
                <DynamicInput
                  extendedTypes={extendedTypes}
                  onChange={onChange(parameter)}
                  type={parameter.type}
                  value={getValue(parameter, parametersValues)}
                  disabled={parameter.disabled}
                />
                <Styled.Description>
                  {parameter.description} {parameter.required ? ` (${langs('REQUIRED')})` : ''}
                </Styled.Description>
              </div>
            </React.Fragment>
          );
        })}
      </Styled.Container>
    </>
  );
}

TaskParametersForm.propTypes = {
  extendedTypes: PropTypes.objectOf(PropTypes.elementType),
  flat: PropTypes.bool,
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      config: PropTypes.any,
      description: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      required: PropTypes.bool,
      type: PropTypes.string.isRequired
    })
  ),
  parametersValues: PropTypes.object,
  setParametersValues: PropTypes.func
};

export default TaskParametersForm;
