import styled, {css} from 'styled-components';

import HelpIcon from '@material-ui/icons/Help';
import {Paper} from '@material-ui/core';

import {Form as BaseForm} from '../../../components/Base';

import * as theme from '../../../theme';

export const BottomBar = styled.div`
  text-align: right;

  & > button {
    min-width: 150px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Error = styled.div`
  color: ${theme.statusColors.error};
  margin-top: 5px;
`;

export const Form = styled.form`
  width: ${({isEditMode}) => (isEditMode ? '100%' : '60%')};
`;

export const FormFieldsContainer = styled.div`
  margin-top: 10px;
`;

export const FormFieldTitle = styled(BaseForm.Label)`
  opacity: 1;
`;

export const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px 0;
`;

export const Option = styled.button`
  width: 100px;
  height: 30px;
  box-sizing: border-box;
  color: ${theme.buttons.normal.text};
  border: 0;
  padding: 6px;
  font-size: 14px;
  border-radius: 15px;
  white-space: nowrap;
  outline: none;
  user-select: none;
  background-color: ${theme.chipBackgrounds.primary};
  ${({isSelected}) => isSelected && selectedOptionStyles()}
  &:hover {
    border: ${({disabled, isSelected}) =>
      disabled && !isSelected ? 'none' : `1px solid ${theme.buttons.normal.text}`};
    cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

function selectedOptionStyles() {
  return css`
    border: 1px solid ${theme.buttons.normal.text};
  `;
}

export const Optional = styled.span`
  font-style: italic;
`;

export const StyledPaper = styled(Paper)`
  padding: ${({isEditMode}) => (isEditMode ? '0' : '2rem')};
  padding-block: ${({isEditMode}) => (isEditMode ? '0' : '1rem')};
  margin-bottom: 1rem;
  box-shadow: none;
`;

export const StyledHelpIcon = styled(HelpIcon)`
  width: 17px;
  margin-left: 5px;
  position: relative;
  bottom: 2px;
`;
