import staticData from '../../models/staticData';

import {appendAttribute} from './common';

const CMS_PERMISSIONS = staticData.RBAC.SERVICE_PERMISSIONS.CMS;

export default {
  beacon: {
    manage: CMS_PERMISSIONS.BEACON_MANAGE,
    provision: CMS_PERMISSIONS.BEACON_PROVISION
  },
  collectors: {
    configuration: {
      create: {
        all: {
          all: appendAttribute(
            CMS_PERMISSIONS.CREATE_CONFIGURATION,
            CMS_PERMISSIONS.CREATE_CONFIGURATION_LEVEL_ALL_ALL
          ),
          collectors: appendAttribute(
            CMS_PERMISSIONS.CREATE_CONFIGURATION,
            CMS_PERMISSIONS.CREATE_CONFIGURATION_LEVEL_ALL_COLLECTORS
          ),
          tenants: appendAttribute(
            CMS_PERMISSIONS.CREATE_CONFIGURATION,
            CMS_PERMISSIONS.CREATE_CONFIGURATION_LEVEL_ALL_TENANTS
          )
        },
        categories: {
          appDeployment: appendAttribute(
            CMS_PERMISSIONS.CREATE_CONFIGURATION,
            CMS_PERMISSIONS.CREATE_CONFIGURATION_CATEGORY_APP_DEPLOYMENT
          ),
          cms: appendAttribute(CMS_PERMISSIONS.CREATE_CONFIGURATION, CMS_PERMISSIONS.CREATE_CONFIGURATION_CATEGORY_CMS),
          firmware: appendAttribute(
            CMS_PERMISSIONS.CREATE_CONFIGURATION,
            CMS_PERMISSIONS.CREATE_CONFIGURATION_CATEGORY_FIRMWARE
          ),
          provision: appendAttribute(
            CMS_PERMISSIONS.CREATE_CONFIGURATION,
            CMS_PERMISSIONS.CREATE_CONFIGURATION_CATEGORY_PROVISION
          )
        },
        create: CMS_PERMISSIONS.CREATE_CONFIGURATION
      },
      delete: {
        all: {
          all: appendAttribute(
            CMS_PERMISSIONS.DELETE_CONFIGURATION,
            CMS_PERMISSIONS.DELETE_CONFIGURATION_LEVEL_ALL_ALL
          ),
          collectors: appendAttribute(
            CMS_PERMISSIONS.DELETE_CONFIGURATION,
            CMS_PERMISSIONS.DELETE_CONFIGURATION_LEVEL_ALL_COLLECTORS
          ),
          tenants: appendAttribute(
            CMS_PERMISSIONS.DELETE_CONFIGURATION,
            CMS_PERMISSIONS.DELETE_CONFIGURATION_LEVEL_ALL_TENANTS
          )
        },
        categories: {
          appDeployment: appendAttribute(
            CMS_PERMISSIONS.DELETE_CONFIGURATION,
            CMS_PERMISSIONS.DELETE_CONFIGURATION_CATEGORY_APP_DEPLOYMENT
          ),
          cms: appendAttribute(CMS_PERMISSIONS.DELETE_CONFIGURATION, CMS_PERMISSIONS.DELETE_CONFIGURATION_CATEGORY_CMS),
          firmware: appendAttribute(
            CMS_PERMISSIONS.DELETE_CONFIGURATION,
            CMS_PERMISSIONS.DELETE_CONFIGURATION_CATEGORY_FIRMWARE
          ),
          provision: appendAttribute(
            CMS_PERMISSIONS.DELETE_CONFIGURATION,
            CMS_PERMISSIONS.DELETE_CONFIGURATION_CATEGORY_PROVISION
          )
        },
        delete: CMS_PERMISSIONS.DELETE_CONFIGURATION
      },
      edit: {
        all: {
          all: appendAttribute(CMS_PERMISSIONS.EDIT_CONFIGURATION, CMS_PERMISSIONS.EDIT_CONFIGURATION_LEVEL_ALL_ALL),
          collectors: appendAttribute(
            CMS_PERMISSIONS.EDIT_CONFIGURATION,
            CMS_PERMISSIONS.EDIT_CONFIGURATION_LEVEL_ALL_COLLECTORS
          ),
          tenants: appendAttribute(
            CMS_PERMISSIONS.EDIT_CONFIGURATION,
            CMS_PERMISSIONS.EDIT_CONFIGURATION_LEVEL_ALL_TENANTS
          )
        },
        categories: {
          appDeployment: appendAttribute(
            CMS_PERMISSIONS.EDIT_CONFIGURATION,
            CMS_PERMISSIONS.EDIT_CONFIGURATION_CATEGORY_APP_DEPLOYMENT
          ),
          cms: appendAttribute(CMS_PERMISSIONS.EDIT_CONFIGURATION, CMS_PERMISSIONS.EDIT_CONFIGURATION_CATEGORY_CMS),
          firmware: appendAttribute(
            CMS_PERMISSIONS.EDIT_CONFIGURATION,
            CMS_PERMISSIONS.EDIT_CONFIGURATION_CATEGORY_FIRMWARE
          ),
          provision: appendAttribute(
            CMS_PERMISSIONS.EDIT_CONFIGURATION,
            CMS_PERMISSIONS.EDIT_CONFIGURATION_CATEGORY_PROVISION
          )
        },
        edit: CMS_PERMISSIONS.EDIT_CONFIGURATION
      },
      read: {
        all: {
          all: appendAttribute(
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES_LEVEL_ALL_ALL
          ),
          collectors: appendAttribute(
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES_LEVEL_ALL_COLLECTORS
          ),
          tenants: appendAttribute(
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES_LEVEL_ALL_TENANTS
          )
        },
        categories: {
          appDeployment: appendAttribute(
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES_CATEGORY_APP_DEPLOYMENT
          ),
          cms: appendAttribute(
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES_CATEGORY_CMS
          ),
          firmware: appendAttribute(
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES_CATEGORY_FIRMWARE
          ),
          provision: appendAttribute(
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES,
            CMS_PERMISSIONS.READ_CONFIGURATION_VALUES_CATEGORY_PROVISION
          )
        },
        read: CMS_PERMISSIONS.READ_CONFIGURATION_VALUES
      }
    },
    decommission: CMS_PERMISSIONS.COLLECTOR_DECOMMISSION,
    markOperational: CMS_PERMISSIONS.COLLECTOR_MARK_OPERATIONAL,
    task: {
      runAll: CMS_PERMISSIONS.RUN_TASK_ALL,
      runElevated: {
        critical: CMS_PERMISSIONS.RUN_TASK_ELEVATED_CRITICAL,
        high: CMS_PERMISSIONS.RUN_TASK_ELEVATED_HIGH,
        low: CMS_PERMISSIONS.RUN_TASK_ELEVATED_LOW,
        medium: CMS_PERMISSIONS.RUN_TASK_ELEVATED_MEDIUM
      },
      runFieldAction: CMS_PERMISSIONS.RUN_TASK_FIELD_ACTION,
      runNonElevated: CMS_PERMISSIONS.RUN_TASK_NON_ELEVATED,
      runShellCommand: CMS_PERMISSIONS.RUN_TASK_SHELL_COMMAND
    }
  }
};
