/**
 * useIsMounted hook provides a boolean value indiciated if current component is still mounted.
 * This is great to use in async functions that might end after the component has been unmounted.
 */
import React from 'react';

const useIsMounted = () => {
  const ref = React.useRef(false);

  React.useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  return () => ref.current;
};

export default useIsMounted;
