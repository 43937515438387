import React from 'react';
import {useParams, useHistory} from 'react-router-dom';

import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';

import NewTenantForm from './NewTenantForm';
import {Breadcrumb} from '../../../components/Base';

const breadcrumb = [{title: langs('DASHBOARD'), url: '/tenants'}, langs('ADD_NEW_TENANT')];

function TenantAdd() {
  const {cluster} = useParams();
  const history = useHistory();
  const {getUrl} = context.Navigation.useNavigation();

  function goToTenantTasks(tenantId) {
    const url = getUrl('tenants.tasks', null, {hostIds: [consts.FILTER_OPERATORS.OVERLAP, tenantId]});
    history.push(url);
  }

  return (
    <div>
      <Breadcrumb sections={breadcrumb} withBorder={false} />
      <NewTenantForm onComplete={goToTenantTasks} defaultClusterName={cluster} />
    </div>
  );
}

export default TenantAdd;
