import _ from 'lodash';

import consts from '../consts';
import langs from '../localization/langs';

export function getFilterValue(filter, isTranslate = false) {
  if (_.isEmpty(filter)) {
    return [];
  }

  return _.map(filter.split(','), (option) => ({id: option, name: isTranslate ? langs(option) : option}));
}

export function getFiltersByOperator(value, operator) {
  const filters = _.split(value, ',');
  if (_.isEmpty(filters)) {
    return null;
  }

  return _(filters)
    .map((filter) => getFilterByOperator(filter, operator))
    .join(',');
}

export function getFilterByOperator(value, operator) {
  if (_.isEmpty(value)) {
    return null;
  }

  switch (operator) {
    case consts.FILTER_OPERATORS.LIKE:
      return `%${value}%`;
    default:
      return value;
  }
}

function getExactMatchName(option) {
  const name = _.get(option, 'name', '');
  return name.replaceAll('%', '');
}

export function getInputValue(freeTextFilter) {
  return getFilterValue(freeTextFilter)
    .map(getExactMatchName)
    .join(',');
}
