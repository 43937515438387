import _ from 'lodash';
import React from 'react';

import useInterval from '@rooks/use-interval';

const DEFAULT_REFRESH_INTERVAL_SECONDS = 60;

function getAutoRefreshIntervalSeconds(autoRefresh) {
  if (_.isNumber(autoRefresh)) {
    return autoRefresh;
  }
  return autoRefresh ? DEFAULT_REFRESH_INTERVAL_SECONDS : 0;
}

function useRefresh(autoRefresh, dataDeps, gridApi) {
  const refresh = React.useCallback(() => {
    if (gridApi) {
      gridApi.refreshServerSideStore();
    }
  }, [gridApi]);

  React.useEffect(() => {
    if (!_.isEmpty(dataDeps)) {
      refresh();
    }
  }, dataDeps);

  const refreshInterval = getAutoRefreshIntervalSeconds(autoRefresh) * 1000;
  const {start, stop} = useInterval(refresh, refreshInterval);

  React.useEffect(() => {
    if (autoRefresh) {
      start();
    } else {
      stop();
    }
    return stop;
  }, [autoRefresh]);

  return refresh;
}

export default useRefresh;
