import styled from 'styled-components';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import {Paper} from '../../../Base';

import * as theme from '../../../../theme';

export const StatsPaper = styled(Paper)`
  height: 18rem;
  padding-block: 2rem;
  font-size: 1rem;
  line-height: initial;
  color: ${theme.text.primary}c2;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Header = styled.div`
  color: ${theme.text.primary};
  margin-bottom: 0.3rem;
`;

export const RelayContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RelayList = styled.div`
  max-height: 40%;
  overflow-y: auto;
`;

export const Title = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;

export const RightArrow = styled(ArrowRightAltIcon)``;

export const LeftArrow = styled(ArrowRightAltIcon)`
  transform: rotate(180deg);
`;
