import _ from 'lodash';
import React from 'react';
import {useLocation} from 'react-router-dom';

import consts from '../consts';
import staticData from '../models/staticData';
import utils from '../utils';

export const NavigationContext = React.createContext(null);

function getUrlQuery(queryParams) {
  if (_.isEmpty(queryParams)) {
    return '';
  }

  const urlQuery = _.map(queryParams, (fieldValue, fieldName) => {
    const [operator, ...values] = _.isArray(fieldValue) ? fieldValue : [consts.FILTER_OPERATORS.EQUAL, fieldValue];
    return `${fieldName}${operator}=${values.join(',')}`;
  }).join('&');

  return `?${urlQuery}`;
}

export function NavigationContextProvider({children}) {
  const [opsEnv, setOpsEnv] = React.useState(utils.urlUtils.getCurrentEnv());

  const {pathname} = useLocation();

  React.useEffect(
    function updateOpsEnv() {
      const currentOpsEnv = utils.urlUtils.getCurrentEnv();
      if (opsEnv === currentOpsEnv) {
        return;
      }

      setOpsEnv(currentOpsEnv);
    },
    [pathname]
  );

  const ROUTE_URLS = {
    collectors: {
      add: '/collectors/add',
      beacons: '/collectors/beacons',
      configuration: '/collectors/configuration',
      images: '/collectors/images',
      main: `/collectors?collectorStatus${consts.FILTER_OPERATORS.LIKE}=ACTIVE`,
      tasks: '/collectors/tasks',
      withCustomFilter: '/collectors'
    },
    demo: {
      main:
        window.location.hostname === 'localhost' || opsEnv === consts.DEMO_ENV
          ? '/demo'
          : `https://ops-${consts.DEMO_ENV}.armis.com/demo`
    },
    rbac: {
      editor: '/rbac-admin/schema-editor',
      schema: '/rbac-admin/schema',
      self: '/rbac-admin/self-view'
    },
    releases: {
      add: '/releases/add',
      cherryPicks: '/releases/cherryPicks',
      main: '/releases'
    },
    services: {
      configuration: '/multitenant/configuration',
      logs: '/multitenant/logs',
      main: '/multitenant',
      namespaces: '/multitenant/namespaces',
      namespacesAdd: '/multitenant/namespaces/add',
      services: '/multitenant/services',
      servicesAdd: '/multitenant/services/add',
      stacks: '/multitenant/stacks',
      stacksAdd: '/multitenant/stacks/add',
      tasks: '/multitenant/tasks'
    },
    tenants: {
      add: ({cluster}) => `/tenants/add/${cluster || ''}`,
      configuration: '/tenants/configuration',
      configurationTable: '/tenants/configuration/table',
      dataFlow: ({tenantId}) => `/tenants/flow/${tenantId}`,
      main: `/tenants?${_.join(
        [
          `environmentName${consts.FILTER_OPERATORS.LIKE}=${opsEnv}`,
          `status${consts.FILTER_OPERATORS.LIKE}=${staticData.TENANT.AVAILABILITY_STATUS.AVAILABILITY_STATUS_ACTIVE}`
        ],
        '&'
      )}`,
      tasks: '/tenants/tasks',
      tenant: ({tenantId}) => `/tenants/${tenantId}`,
      withCustomFilter: '/tenants'
    }
  };

  function getUrl(to = '', params = {}, query = {}, process = null) {
    const urlValue = _.get(ROUTE_URLS, to, '');
    const url = _.isFunction(urlValue) ? urlValue(params) : urlValue;

    const urlQuery = getUrlQuery(query);
    const fullUrl = `${url}${urlQuery}`;

    return _.isFunction(process) ? process(fullUrl) : fullUrl;
  }

  return <NavigationContext.Provider value={{getUrl, opsEnv}}>{children}</NavigationContext.Provider>;
}

export const useNavigation = () => React.useContext(NavigationContext);
