import _ from 'lodash';

import client from '../client';
import consts from '../../consts';

export async function get(category, envId, tenantId) {
  let route;
  if (!_.isEmpty(envId) && !_.isEmpty(tenantId)) {
    route = `/maestro/api/v1/envs/${envId}/tenants/${tenantId}/configuration`;
  } else if (!_.isEmpty(envId)) {
    route = `/maestro/api/v1/envs/${envId}/configuration`;
  } else {
    route = '/maestro/api/v1/configuration';
  }
  return client.get(`${route}?category=${category}`);
}

export async function deleteValue(configurationValueId) {
  return client.delete(`/maestro/api/v1/configuration_value/${configurationValueId}`);
}

export async function update(configurationValueId, value) {
  return client.patch(`/maestro/api/v1/configuration_value/${configurationValueId}`, {value: JSON.stringify({value})});
}

export async function createKey(key, keyCategory, keySubCategory, type, defaultValue, description, labels) {
  return client.post(`/maestro/api/v1/configuration_key`, {
    defaultValue: JSON.stringify({value: defaultValue}),
    description,
    key,
    keyCategory,
    keySubCategory,
    labels: _(labels)
      .mapKeys((value, key) => _.snakeCase(key))
      .omitBy(_.isEmpty)
      .value(),
    type
  });
}

export async function createValue({name, value, labels, mainCategory, subCategory}) {
  return client.post(`/maestro/api/v1/configuration_value`, {
    keyCategory: mainCategory,
    keyName: name,
    keySubCategory: subCategory,
    labels: _(labels)
      .mapKeys((value, key) => _.snakeCase(key))
      .omitBy(_.isEmpty)
      .value(),
    value: JSON.stringify({value})
  });
}

export async function getClusters() {
  return client.get('/maestro/api/v1/environments');
}

export function evaluateParams(params, owner, name, cluster, environment = 'production') {
  const clusterName = _.get(cluster, 'name');
  const redisHost = _.get(cluster, 'params.stRedisConfig.host');
  const redisPassword = _.get(cluster, 'params.stRedisConfig.password');
  const redisPort = _.get(cluster, 'params.stRedisConfig.port');
  const redisIsCluster = _.get(cluster, 'params.stRedisConfig.isCluster');

  const templateValues = _.assignWith(
    {},
    consts.DEFAULT_INTEGRATION_PARAMS,
    {
      cluster: clusterName,
      environment,
      name,
      owner,
      redisHost,
      redisIsCluster,
      redisPassword,
      redisPort
    },
    (objValue, srcValue) => (_.isUndefined(srcValue) ? objValue : srcValue)
  );
  return _.template(params)(templateValues);
}

export async function getVersions(envId, tenantId, configurationKeyId) {
  let route;
  if (!_.isEmpty(envId) && !_.isEmpty(tenantId)) {
    route = `/maestro/api/v1/envs/${envId}/tenants/${tenantId}/configuration_key/${configurationKeyId}/versions`;
  } else if (!_.isEmpty(envId)) {
    route = `/maestro/api/v1/envs/${envId}/configuration_key/${configurationKeyId}/versions`;
  } else {
    route = `/maestro/api/v1/configuration_key/${configurationKeyId}/versions`;
  }
  return client.get(route);
}

export async function getConfigurationKeys(keyName) {
  return client.get(`/maestro/api/v1/configuration_keys?key[like]=%${keyName}%&length=10`);
}

export async function search({length = 20, from, orderBy, searchQuery = ''}) {
  const fromQuery = from ? `&from=${from}` : '';
  const orderByQuery = orderBy ? `&orderBy=${orderBy}` : '';

  return client.get(`/maestro/api/v1/configuration/_search?length=${length}${fromQuery}${orderByQuery}&${searchQuery}`);
}
