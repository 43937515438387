import _ from 'lodash';

import consts from '../consts';

const OPS = 'ops';
const PROD = 'prod';
const ARMIS_FED = 'armis-fed';

const {REACT_APP_OPS_ENV} = process.env;

export function composeURL(baseRoute, length, from, orderBy, orderDirection, filters) {
  const urlFilters = _(filters)
    .map((filter) => [
      `${filter.column.field}${consts.FILTER_OPERATORS.LIKE}=${encodeURIComponent(`%${filter.value}%`)}`
    ])
    .join('&');
  const args = _([
    length && `length=${length}`,
    from && `from=${from}`,
    orderBy && orderDirection && `orderBy=${orderBy}:${orderDirection}`,
    urlFilters
  ])
    .compact()
    .join('&');

  return _.isEmpty(args) ? baseRoute : `${baseRoute}?${args}`;
}

export function getCurrentEnv() {
  if (!_.isEmpty(REACT_APP_OPS_ENV)) {
    return REACT_APP_OPS_ENV;
  }

  if (
    _(window.location.host)
      .split('.')
      .nth(-2) === ARMIS_FED
  ) {
    return _(window.location.host)
      .split('.')
      .nth(-3);
  }
  const currentEnv = _(window.location.host)
    .split('.')
    .head()
    .substr(0, 9)
    .replace(`${OPS}-`, '');

  if (currentEnv === OPS) {
    return PROD;
  }
  return currentEnv;
}

export function isLocalhost() {
  return window.location.hostname === 'localhost';
}
