import _ from 'lodash';
import React from 'react';

import consts from '../consts';
import services from '../services';

export const EnvironmentsContext = React.createContext(null);

export const useEnvironments = () => React.useContext(EnvironmentsContext);

const ENVIRONMENT_TYPES_TO_IGNORE = ['operations', 'operations-test'];

export function EnvironmentsContextProvider({children}) {
  const [environments, setEnvironments] = React.useState(_.map(consts.ENVIRONMENTS, (name) => ({name})));

  React.useEffect(() => {
    services.services.environments
      .list({length: -1, searchQuery: `negativeType=${_.join(ENVIRONMENT_TYPES_TO_IGNORE, ',')}`})
      .then(({data: {items}}) => setEnvironments(items));
  }, []);

  return <EnvironmentsContext.Provider value={environments}>{children}</EnvironmentsContext.Provider>;
}
