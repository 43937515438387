import _ from 'lodash';
import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import langs from '../../localization/langs';

import * as baseComp from './protectedBase';
import * as Styled from './ProtectedComponent.styles';

function updateChild(childElement) {
  if (!React.isValidElement(childElement)) {
    return childElement;
  }
  return React.cloneElement(childElement, {disabled: true}, updateChildren(childElement.props.children));
}

function updateChildren(children) {
  if (_.isArray(children)) {
    return React.Children.map(children, updateChild);
  }
  return updateChild(children);
}

function ProtectedComponent({children, permissions, rbacControlObj}) {
  const isAllowed = React.useMemo(() => baseComp.isAllowed(permissions, rbacControlObj), [permissions, rbacControlObj]);

  if (isAllowed) {
    return <>{children}</>;
  }

  const missingPermissions = rbacControlObj.getMissingPermissionsStr(permissions);

  return (
    <Tooltip title={langs('INSUFFICIENT_PERMISSIONS', {missingPermissions})}>
      <Styled.Container>
        <Styled.Blocker />
        <Styled.Content>{updateChildren(children)}</Styled.Content>
      </Styled.Container>
    </Tooltip>
  );
}

ProtectedComponent.propTypes = baseComp.propTypes;

export default ProtectedComponent;
