import _ from 'lodash';
import React from 'react';

function useAutoScroll(ref, ...dependencies) {
  const [isPaused, setIsPaused] = React.useState(false);

  React.useEffect(() => {
    if (!_.isEmpty(ref.current)) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, dependencies);

  const scrollEvent = (e) => {
    if (isScrolled(e.target.scrollTop)) {
      setIsPaused(true);
    } else if (isPaused) {
      setIsPaused(false);
    }
  };

  function isScrolled(scrollPosition) {
    if (!_.isEmpty(ref.current)) {
      return scrollPosition < ref.current.scrollHeight - ref.current.clientHeight;
    }
    return false;
  }

  return [isPaused, setIsPaused, scrollEvent];
}

export default useAutoScroll;
