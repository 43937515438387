import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderAction = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  padding-right: 0.3rem;
  height: 34px;
`;

export const Header = styled.h3`
  margin: 0;
  flex: 1;
`;

export const Author = styled.div`
  font-size: 0.7rem;
  opacity: 0.8;
`;
