import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import {Button as MuiButton} from '@material-ui/core';
import MuiDivider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

export const Button = styled(MuiButton).attrs({size: 'small'})`
  padding: 0;
`;

export const Container = styled.div`
  margin-left: 30px;
`;

export const Divider = styled(MuiDivider)`
  margin: 6px 0;
`;

export const LogRow = styled(Grid).attrs({item: true})`
  text-align: center;
`;

export const Title = styled(Typography).attrs({color: 'textPrimary', variant: 'body2'})`
  float: right;
`;
