export const requestTypes = {
  MAJOR: 'REQUEST_TYPE_MAJOR',
  MINOR: 'REQUEST_TYPE_MINOR',
  RELEASE_CANDIDATE: 'REQUEST_TYPE_RELEASE_CANDIDATE'
};

export const urgencyTypes = {
  HIGH: 'URGENCY_HIGH',
  LOW: 'URGENCY_LOW',
  MEDIUM: 'URGENCY_MEDIUM',
  REGRESSION: 'URGENCY_REGRESSION',
  UNKNOWN: 'URGENCY_UNKNOWN'
};

export const deploymentTypes = {
  COLLECTORS: 'DEPLOYMENT_TYPE_COLLECTORS',
  MULTI_TENANT: 'DEPLOYMENT_TYPE_MULTI_TENANT',
  SINGLE_TENANT: 'DEPLOYMENT_TYPE_SINGLE_TENANT',
  SQUAD: 'DEPLOYMENT_TYPE_SQUAD'
};

export const statusTypes = {
  BUILD_FAILED: 'STATUS_BUILD_FAILED',
  BUILD_FINISHED_SUCCESSFULLY: 'STATUS_BUILD_FINISHED_SUCCESSFULLY',
  BUILD_IS_RUNNING: 'STATUS_BUILD_IS_RUNNING',
  CANDIDATE: 'STATUS_CANDIDATE',
  CLOSED: 'STATUS_CLOSED',
  DECLINED: 'STATUS_DECLINED',
  DEPLOYED: 'STATUS_DEPLOYED',
  MERGED: 'STATUS_MERGED',
  UNKNOWN: 'STATUS_UNKNOWN'
};

export default {
  deploymentTypes,
  requestTypes,
  statusTypes,
  urgencyTypes
};
