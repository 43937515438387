import styled from 'styled-components';
import {Link} from 'react-router-dom';

import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiMenuItem from '@material-ui/core/MenuItem';

import * as theme from '../../theme';

export const MenuItem = styled(MuiMenuItem)`
  padding: 0 12px;
  font-size: 14px;
`;

export const MenuLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
`;

export const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 30px;
`;

export const ListItemText = styled(MuiListItemText)`
  color: ${({$isHighlighted}) => ($isHighlighted ? theme.default.palette.secondary.main : theme.text.primary)};
  font-weight: ${({$isHighlighted}) => ($isHighlighted ? 'bold' : 'normal')};
`;
