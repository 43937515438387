import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../../../context';
import langs from '../../../../localization/langs';
import services from '../../../../services';

import RmqRelayLine from './RmqRelayLine';

import * as Styled from './TenantRelay.styles';

function TenantRelay({tenant = {}}) {
  const [rmqRelaySource, setRmqRelaySource] = React.useState(null);
  const [rmqRelayDestinations, setRmqRelayDestinations] = React.useState(null);

  const notifications = context.Notifications.useNotifications();

  const tenantId = tenant.tenantId;
  const tenantName = tenant.tenantName;

  React.useEffect(() => {
    getRmqRelay();
  }, [tenantId]);

  async function getRmqRelay() {
    try {
      const tenantRmqRelay = await services.tenants.getTenantRmqRelay(tenantId);
      const source = _.get(_.head(_.filter(tenantRmqRelay, {destination: tenantName})), 'source');
      const destinations = _.map(_.filter(tenantRmqRelay, {source: tenantName}), 'destination');
      setRmqRelaySource(source);
      setRmqRelayDestinations(destinations);
    } catch (error) {
      notifications.error(error.message);
      console.error(error.message);
    }
  }

  return (
    <Styled.StatsPaper>
      <Styled.Title>{langs('RMQ_RELAY_INFO')}</Styled.Title>
      <Styled.Container>
        <Styled.RelayContainer>
          <Styled.Header>{langs('RMQ_RELAY_INCOMING_DATA')}</Styled.Header>
          {rmqRelaySource && (
            <RmqRelayLine
              relaySource={tenantName}
              value={rmqRelaySource}
              onChange={getRmqRelay}
              icon={<Styled.LeftArrow />}
            />
          )}
        </Styled.RelayContainer>
        <Styled.RelayContainer>
          <Styled.Header>{langs('RMQ_RELAY_OUTGOING_DATA')}</Styled.Header>
          <Styled.RelayList>
            {_.map(rmqRelayDestinations, (destination) => (
              <RmqRelayLine
                key={destination}
                relaySource={destination}
                value={destination}
                onChange={getRmqRelay}
                icon={<Styled.RightArrow />}
              />
            ))}
          </Styled.RelayList>
        </Styled.RelayContainer>
      </Styled.Container>
    </Styled.StatsPaper>
  );
}

TenantRelay.propTypes = {
  tenant: PropTypes.shape({
    tenantId: PropTypes.string.isRequired,
    tenantName: PropTypes.string.isRequired
  })
};

export default TenantRelay;
