import _ from 'lodash';

function getAttributesMap(schema) {
  return _.transform(schema.attributes, (result, attribute) => (result[attribute.id] = attribute.name), {});
}

function getDerivedPermissionMap(schema) {
  const permissionsMap = getPermissionsMap(schema);
  return _.transform(
    schema.derivedPermissions,
    (result, derivedPermission) => {
      result[derivedPermission.id] = _.map(derivedPermission.childIds, (childId) => permissionsMap[childId]);
    },
    {}
  );
}

function getPermissionsMap(schema) {
  const attributesMap = getAttributesMap(schema);
  return _.transform(
    schema.permissions,
    (result, permission) => {
      result[permission.id] = _.assign({}, permission, {
        attributes: _.map(permission.attributeIds, (attributeId) => attributesMap[attributeId])
      });
    },
    {}
  );
}

function getRoleMap(schema) {
  return _.transform(
    schema.roles,
    (result, role) =>
      (result[role.id] = {
        id: role.id,
        name: role.name,
        permissionsIds: role.permissionIds
      }),
    {}
  );
}

export default {
  attributes: getAttributesMap,
  derivedPermissions: getDerivedPermissionMap,
  permissions: getPermissionsMap,
  roles: getRoleMap
};
