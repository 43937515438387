import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import langs from '../../../../localization/langs';
import services from '../../../../services/services';
import utils from '../../../../components/Base/inputs/DynamicInput.utils';

import TaskDialogActions from '../../../../components/NewTaskWidget/TaskDialogActions';

import * as Styled from './ServiceReplicateAction.styles';

export default function ServiceReplicateAction({
  onBack,
  onNext,
  hosts,
  setConfigurationValue = _.stubFalse,
  setParametersValues = _.stubFalse
}) {
  const [replicasValue, setReplicasValue] = React.useState('');
  const [currentReplicas, setCurrentReplicas] = React.useState('');
  const host = _.head(hosts);
  const serviceId = _.get(host, 'serviceId', null);
  const environmentId = _.get(host, 'environmentId', null);
  const namespaceId = _.get(host, 'namespaceId', null);

  React.useEffect(() => {
    if (!_.isEmpty(serviceId) && !_.isEmpty(environmentId) && !_.isEmpty(namespaceId)) {
      services.services.getHelmValues(serviceId, environmentId, namespaceId).then((helmValues) => {
        const replicas = _.get(helmValues, ['data', 'replicas'], 1);
        setCurrentReplicas(replicas);
      });
    }
  }, [serviceId, environmentId, namespaceId]);

  function next() {
    const value = JSON.stringify({value: replicasValue});
    setConfigurationValue({
      key_category: 'DEPLOY',
      key_name: 'replicas',
      key_sub_category: 'general',
      value
    });
    setParametersValues({branch: _.get(host, 'deploymentBranch', '')});
    onNext();
  }

  return (
    <div>
      <DialogContent>
        <DialogContentText>
          {langs('DEPLOYMENTS_ACTION_DIALOG_CURRENT_REPLICAS')}
          <Styled.CurrentValue> {currentReplicas}</Styled.CurrentValue>
        </DialogContentText>
        <DialogContentText>{langs('DEPLOYMENTS_ACTION_DIALOG_ENTER_REPLICAS')}</DialogContentText>
        <Styled.Input
          inputProps={{min: 0}}
          fullWidth
          onChange={({target}) => setReplicasValue(utils.castToInteger(target.value))}
          type="number"
          value={replicasValue}
          placeholder={langs('ENTER_VALUE')}
        />
      </DialogContent>
      <TaskDialogActions onNext={next} onCancel={onBack} isAllowedNextStep={_.isNumber(replicasValue)} />
    </div>
  );
}

ServiceReplicateAction.propTypes = {
  hosts: PropTypes.array.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  setConfigurationValue: PropTypes.func,
  setParametersValues: PropTypes.func
};
