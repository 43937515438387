import _ from 'lodash';
import React from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useOutsideClick from '@rooks/use-outside-click';

import context from '../../../context';

import * as Styled from './EnvSelector.styles';

const OPS_PROD_URL = 'https://ops.armis.com';
const PROD = 'prod';

function getOpsDomainByEnv(env) {
  if (env === PROD) {
    return OPS_PROD_URL;
  }
  return `https://ops-${env}.armis.com`;
}

function EnvSelector() {
  const [isOpen, setIsOpen] = React.useState(false);

  const ref = React.useRef();
  const {opsEnv} = context.Navigation.useNavigation();
  const environments = context.Environments.useEnvironments();

  useOutsideClick(ref, (event) => {
    setIsOpen(false);
  });

  return (
    <Styled.Container ref={ref}>
      <Styled.EnvSelector onClick={() => setIsOpen(!isOpen)}>
        {opsEnv}
        <ExpandMoreIcon fontSize="small" />
      </Styled.EnvSelector>
      {isOpen && (
        <Styled.Options>
          {_.map(environments, ({displayName, name}) => (
            <Styled.Option key={name} href={getOpsDomainByEnv(name)} $selected={name === opsEnv}>
              {name} <Styled.DisplayName>({displayName})</Styled.DisplayName>
            </Styled.Option>
          ))}
        </Styled.Options>
      )}
    </Styled.Container>
  );
}

export default EnvSelector;
