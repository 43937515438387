import {Link as RouterLink} from 'react-router-dom';
import styled, {css} from 'styled-components';

import * as theme from '../../theme';

export const Container = styled.div`
  padding-bottom: 0.4rem;
  margin-bottom: 1rem;

  & > *:not(:last-child) {
    &::after {
      content: '/';
      margin: 0 0.3rem;
    }
  }

  ${(props) =>
    props.withBorder &&
    css`
      border-bottom: 1px ${theme.separators.light} solid;
    `};
`;

export const Item = styled.span`
  color: white;
  text-decoration: none;
`;

export const Link = styled(RouterLink)`
  color: white;
  text-decoration: none;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;
