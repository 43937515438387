import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../../../../localization/langs';

import * as Styled from './UserFilter.styles';

function UserFilter({onChange, selectedUser}) {
  const [value, setValue] = React.useState(selectedUser);

  function onSelectUser(event) {
    if (_.get(event, 'key') !== 'Enter') {
      return;
    }
    onChange(value);
  }

  return (
    <Styled.Container>
      <Styled.Label>{langs('SELECTED_OKTA_LOGIN')}</Styled.Label>
      <Styled.TextInput value={value} onChange={(event) => setValue(event.target.value)} onKeyDown={onSelectUser} />
      <Styled.Button onClick={() => onChange(value)}>{langs('SELECTED_OKTA_LOGIN_SET')}</Styled.Button>
    </Styled.Container>
  );
}

UserFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedUser: PropTypes.string.isRequired
};

export default UserFilter;
