import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import context from '../../context';
import langs from '../../localization/langs';

import {Drawer, Tabs} from '../Base';
import PreviewTabs from './PreviewTabs';

import * as Styled from './TenantPreview.styles';

const TABS = {
  COLLECTORS: {
    disabled: ({environmentName}, opsEnv) => environmentName !== opsEnv,
    key: '2',
    title: langs('COLLECTORS')
  },
  HISTORY: {disabled: _.stubFalse, key: '0', title: langs('TASKS_HISTORY')},
  INFO: {disabled: _.stubFalse, key: '1', title: langs('INFO')},
  NOTES: {disabled: _.stubFalse, key: '3', title: langs('NOTES_AND_COMMENTS')},
  USER_CHANGES: {disabled: _.stubTrue, key: '4', title: langs('USER_CHANGES')}
};

export function TenantPreview({tenant, onUpdate, onClose}) {
  const [selected, setSelected] = React.useState(TABS.HISTORY.key);

  const {opsEnv} = context.Navigation.useNavigation();

  return (
    <div>
      <Styled.Menu>
        <IconButton onClick={onClose}>
          <ArrowBackIcon />
        </IconButton>
      </Styled.Menu>

      <h2>{_.get(tenant, 'tenantName', '')}</h2>

      <Tabs selected={selected} onChange={setSelected}>
        {_(TABS)
          .sortBy('key')
          .map((tab) => (
            <Tabs.Tab key={tab.key} id={tab.key} disabled={tab.disabled(tenant, opsEnv)}>
              {tab.title}
            </Tabs.Tab>
          ))
          .value()}
      </Tabs>

      {selected === TABS.COLLECTORS.key && <PreviewTabs.Collectors tenantId={tenant.tenantId} />}
      {selected === TABS.INFO.key && <PreviewTabs.Info tenant={tenant} />}
      {selected === TABS.NOTES.key && <PreviewTabs.NotesAndComments tenant={tenant} onUpdate={onUpdate} />}
      {selected === TABS.HISTORY.key && (
        <PreviewTabs.TasksHistory tenantId={tenant.tenantId} tenantName={tenant.tenantName} />
      )}
    </div>
  );
}

TenantPreview.propTypes = {
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  tenant: PropTypes.object.isRequired
};

function TenantPreviewDrawer({tenant, onClose}) {
  return (
    <Drawer open={!_.isEmpty(tenant)} padded style={{minWidth: '520px'}} onClose={onClose} withBackdrop>
      {tenant && <TenantPreview tenant={tenant} onClose={onClose} />}
    </Drawer>
  );
}

TenantPreviewDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  tenant: PropTypes.object
};

export default TenantPreviewDrawer;
