import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import langs from '../../localization/langs';

function DateField({date, prefix = ''}) {
  return (
    <div>
      {date ? (
        <Tooltip title={format(new Date(date), 'uuuu-MMM-dd HH:mm')} placement={'top'} arrow>
          <span>
            {prefix}
            {formatDistanceToNow(new Date(date), {
              addSuffix: true
            })}
          </span>
        </Tooltip>
      ) : (
        langs('NEVER')
      )}
    </div>
  );
}

DateField.propTypes = {
  date: PropTypes.string,
  prefix: PropTypes.string
};

export default DateField;
