import styled from 'styled-components';

import UIAsyncButton from './AsyncButton';
import UIModal from './Modal';

export const Modal = styled(UIModal)`
  justify-content: space-between;

  & > h3 {
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  & > p {
    text-align: center;
  }
`;

export const Navigation = styled(Modal.Navigation)`
  justify-content: space-between;
`;

export const Button = styled(Modal.Navigation.Button)`
  min-width: 100px;
`;

export const AsyncButton = styled(UIAsyncButton)`
  min-width: 100px;
`;
