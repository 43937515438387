import React from 'react';
import PropTypes from 'prop-types';

import {StatusLine} from '../../Base';

import langs from '../../../localization/langs';

function MonitoringStatus({status}) {
  if (status) {
    return <StatusLine status="success">{langs('MONITORING_UP')}</StatusLine>;
  }

  return <StatusLine status="error">{langs('MONITORING_DOWN')}</StatusLine>;
}

MonitoringStatus.propTypes = {
  status: PropTypes.bool.isRequired
};

export default MonitoringStatus;
