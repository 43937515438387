import styled from 'styled-components';

import Divider from '@material-ui/core/Divider';

export const Separator = styled(Divider)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  height: 46px;
  font-size: 20px;
  font-weight: bold;
  padding: 4px;
`;

export const Spacer = styled.div`
  flex: 1;
`;
