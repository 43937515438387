import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './Drawer.styles';

function Drawer({children, open, onClose, withBackdrop = false, padded = false, ...props}) {
  return (
    <>
      {withBackdrop && open && <Styled.Backdrop onClick={onClose} />}
      <Styled.Container open={open} padded={padded} {...props}>
        {children}
      </Styled.Container>
    </>
  );
}

Drawer.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  padded: PropTypes.bool,
  withBackdrop: PropTypes.bool
};

export default Drawer;
