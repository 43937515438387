import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../context';
import langs from '../../localization/langs';
import services from '../../services';

import NamespacesForm from './NamespacesForm';

import * as Styled from './NamespacePreview.styles';

function NamespacePreview({environmentId, namespaceId, onClose}) {
  const notifications = context.Notifications.useNotifications();

  async function updateNamespace(envId, id, name, stacks) {
    try {
      await services.services.namespaces.updateNamespace(envId, id, name, stacks);
      notifications.success(langs('NAMESPACES_SUCCESSFULLY_UPDATED'));
      onClose();
    } catch (err) {
      console.error(err.stack);
      notifications.error(langs('NAMESPACES_FAILED_TO_UPDATE'));
    }
  }

  if (_.isEmpty(environmentId) || _.isEmpty(namespaceId)) {
    return null;
  }
  return (
    <>
      <Styled.Title>{langs('NAMESPACES_EDIT_NAMESPACE')}</Styled.Title>
      <NamespacesForm environmentId={environmentId} namespaceId={namespaceId} onSubmit={updateNamespace} />
    </>
  );
}

NamespacePreview.propTypes = {
  environmentId: PropTypes.string,
  namespaceId: PropTypes.string,
  onClose: PropTypes.func
};

export default NamespacePreview;
