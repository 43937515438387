import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import langs from '../../../../localization/langs';

import ConfigurationSelect from '../../../Base/ConfigurationSelect';

import * as Styled from './AddBinding.styles';

function AddBinding({envs, groups, onClose, onConfirm, open, schema, services}) {
  const [selectedGroup, setSelectedGroup] = React.useState(_.size(groups) === 1 ? _.head(groups)?.id : '');
  const [selectedService, setSelectedService] = React.useState(_.size(services) === 1 ? _.head(services) : '');
  const [selectedEnv, setSelectedEnv] = React.useState('');

  React.useEffect(
    function() {
      setSelectedGroup(_.size(groups) === 1 ? _.head(groups).id : '');
      setSelectedService('');
      setSelectedEnv('');
    },
    [groups]
  );

  React.useEffect(
    function() {
      setSelectedService(_.size(services) === 1 ? _.head(services) : '');
      setSelectedEnv('');
    },
    [services]
  );

  function getEnvList() {
    if (!selectedService) {
      return envs;
    }

    const selectedGroupSchema = schema.findGroup(getSelectedGroupName());
    if (!selectedGroupSchema) {
      return envs;
    }

    const selectedServiceSchema = schema.findService(selectedGroupSchema, selectedService);
    if (!selectedServiceSchema) {
      return envs;
    }
    return _.xor(envs, _.map(selectedServiceSchema.envs, 'id'));
  }

  function getSelectedGroupName() {
    return _.find(groups, {id: selectedGroup}).name;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{langs('DIALOG_ADD_BINDING_TITLE')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Styled.Label>{langs('DIALOG_ADD_BINDING_GROUP_NAME')}</Styled.Label>
          </Grid>
          <Grid item xs={8}>
            <ConfigurationSelect
              onChange={setSelectedGroup}
              options={_.map(groups, (group) => ({label: group.name, value: group.id}))}
              value={selectedGroup}
            />
          </Grid>
          <Grid item xs={4}>
            <Styled.Label>{langs('DIALOG_ADD_BINDING_SERVICE_NAME')}</Styled.Label>
          </Grid>
          <Grid item xs={8}>
            <ConfigurationSelect
              onChange={setSelectedService}
              value={selectedService}
              options={_.map(services, (service) => ({label: service, value: service}))}
            />
          </Grid>
          <Grid item xs={4}>
            <Styled.Label>{langs('DIALOG_ADD_BINDING_ENV_NAME')}</Styled.Label>
          </Grid>
          <Grid item xs={8}>
            <ConfigurationSelect
              onChange={setSelectedEnv}
              value={selectedEnv}
              options={_.map(getEnvList(), (env) => ({label: env, value: env}))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {langs('CANCEL')}
        </Button>
        <Button
          color="secondary"
          disabled={!(selectedGroup && selectedService && selectedEnv)}
          onClick={() => onConfirm(getSelectedGroupName(), selectedService, selectedEnv)}
        >
          {langs('ADD')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddBinding.propTypes = {
  envs: PropTypes.arrayOf(PropTypes.string).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  schema: PropTypes.shape({
    findEnv: PropTypes.func.isRequired,
    findGroup: PropTypes.func.isRequired,
    findService: PropTypes.func.isRequired,
    groups: PropTypes.array.isRequired
  }).isRequired,
  services: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default AddBinding;
