import _ from 'lodash';

const FIELDS = {
  COMMENT: 'comment',
  CONFIGURATION_VALUE_ID: 'configurationValueId',
  CREATION_DATE: 'configurationValueCreationDate',
  ENVIRONMENT_NAME: 'environmentName',
  INHERITANCE_LEVEL: 'inheritanceLevel',
  KEY: 'key',
  KEY_CATEGORY: 'keyCategory',
  KEY_SUB_CATEGORY: 'keySubCategory',
  MODIFICATION_DATE: 'configurationValueModificationDate',
  TENANT_ID: 'tenantId',
  TENANT_NAME: 'tenantName',
  TYPE: 'type',
  VALUE_AS_STRING: 'valueString'
};

const TYPES = {
  ARRAY_OF_STRINGS: 'ARRAY_OF_STRINGS',
  ARTIFACT_ID: 'ARTIFACT_ID',
  BOOLEAN: 'BOOLEAN',
  CONTAINER: 'CONTAINER',
  DATE_RANGE: 'DATE_RANGE',
  FLOAT: 'FLOAT',
  INTEGER: 'INTEGER',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  SECRET: 'SECRET',
  STRING: 'STRING',
  TENANT_ID: 'TENANT_ID',
  TEXT: 'TEXT',
  YAML: 'YAML'
};

const DEFAULT_VALUES = {
  default: () => null,
  [TYPES.ARRAY_OF_STRINGS]: () => [],
  [TYPES.BOOLEAN]: () => false,
  [TYPES.MULTIPLE_CHOICE]: () => [],
  [TYPES.STRING]: () => '',
  [TYPES.TEXT]: () => ''
};

function getDefaultValue({defaultValue, type}) {
  if (_.isEmpty(defaultValue)) {
    const getter = DEFAULT_VALUES[type] || DEFAULT_VALUES.default;
    return getter();
  }
  return JSON.parse(defaultValue);
}

export default {
  FIELDS,
  TYPES,
  getDefaultValue
};
