import styled from 'styled-components';

import CancelIcon from '@material-ui/icons/Cancel';

export const ActionLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CancelSmall = styled(CancelIcon)`
  font-size: small;
`;
