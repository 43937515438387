import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import MuiButton from '@material-ui/core/Button';

import * as theme from '../../theme';

export const Button = styled(MuiButton).attrs({disableElevation: true})`
  padding: 8px;
  width: 120px;
`;

export const CancelButton = styled(Button)`
  background-color: ${theme.TaskDialog.cancelButtonBackground};
  flex-grow: 1;
`;

export const BackButton = styled(Button)`
  margin-right: 10px;
`;

export const NextButton = styled(Button).attrs({color: 'primary', variant: 'contained'})`
  margin-left: 10px;
`;

export const Container = styled(Grid).attrs({container: true})`
  margin-top: 10px;
`;

export const GrowingGrid = styled(Grid).attrs({item: true})`
  flex: 1;
`;
