import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Typography from '@material-ui/core/Typography';

import langs from '../../../../localization/langs';

import ObjectViewer from '../../../Base/ObjectViewer';

import * as Styled from './CommandInfo.styles';

function CommandInfo({command, parameters}) {
  return (
    <Styled.Container>
      <Styled.SectionTitle color={'textPrimary'} variant={'body2'}>
        {langs('COMMAND')}
      </Styled.SectionTitle>
      <Typography color={'textSecondary'} variant={'body2'} component="span">
        {command}
      </Typography>
      {!_.isEmpty(parameters) && (
        <>
          <Styled.SectionTitle color={'textPrimary'} variant={'body2'}>
            {langs('PARAMETERS')}
          </Styled.SectionTitle>
          <Typography color={'textSecondary'} variant={'body2'} component="span">
            <Styled.Parameters>{ObjectViewer({parameters})}</Styled.Parameters>
          </Typography>
        </>
      )}
    </Styled.Container>
  );
}

CommandInfo.propTypes = {
  command: PropTypes.string.isRequired,
  parameters: PropTypes.object
};

export default CommandInfo;
