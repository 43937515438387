import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import {Grid} from '@material-ui/core';

import langs from '../../../../localization/langs';
import models from '../../../../models';
import {completedTaskStatuses} from '../../enums';

import * as Styled from './TaskCommand.styles';

function isTaskCompleted(taskStatus) {
  return _.includes(completedTaskStatuses, taskStatus);
}

function TaskCommand({children, command, renderLabel, setSelectedId}) {
  const [Icon, text, hasLog] = models.command.getCommandIconAndTextByStatus(command.commandStatusStatus);

  return (
    <Styled.Container>
      <Styled.Divider />
      <Grid container justifyContent="space-between">
        <Grid item container alignItems="center" xs={3}>
          <Icon />
          {command.status}
          {renderLabel(command)}
        </Grid>
        <Styled.LogRow xs={2}>
          {hasLog && (
            <Styled.Button onClick={() => setSelectedId(command)} startIcon={<DescriptionOutlinedIcon />}>
              {langs('VIEW_LOG')}
            </Styled.Button>
          )}
        </Styled.LogRow>
        {React.Children.map(children, (child) => (
          <Grid item xs={2}>
            {React.cloneElement(child, {
              disabled: !isTaskCompleted(command.commandStatusStatus),
              triggerValue: command
            })}
          </Grid>
        ))}
        <Grid item xs={4}>
          {command.startDate && (
            <Styled.Title>
              {`${text} `}
              {formatDistanceToNow(new Date(command.startDate), {
                addSuffix: true
              })}
            </Styled.Title>
          )}
        </Grid>
      </Grid>
    </Styled.Container>
  );
}

TaskCommand.propTypes = {
  children: PropTypes.node,
  command: PropTypes.object.isRequired,
  renderLabel: PropTypes.func.isRequired,
  setSelectedId: PropTypes.func.isRequired
};

export default TaskCommand;
