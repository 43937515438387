import _ from 'lodash';

import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import services from '../../../services';
import useMenuItem from '../../AGTable/hooks/useMenuItem';

export default function useMonitoringMenuItem(onChange = _.noop) {
  const notifications = context.Notifications.useNotifications();

  async function onConfirm(tenant) {
    try {
      await services.tenants.updateById(tenant.tenantId, {isMonitored: !tenant.isMonitored});
      onChange();
    } catch (error) {
      notifications.error(langs('ERROR_UPDATE_MONITORING'));
    }
  }

  function getName(tenant) {
    return tenant.isMonitored ? langs('BUTTON_MONITORING_DISABLE') : langs('BUTTON_MONITORING_ENABLE');
  }
  const user = context.User.useUser();
  return useMenuItem(
    onConfirm,
    getName,
    'outline_monitor_white_24dp',
    consts.rbac.permissions.maestro.tenant.edit.monitoring,
    user.rbac.maestro
  );
}
