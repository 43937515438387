import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './CollectorChip.styles';

function CollectorChip({label, status, ...props}) {
  return (
    <Styled.Container {...props}>
      {status && <Styled.Dot status={status} />}
      {label}
    </Styled.Container>
  );
}

CollectorChip.propTypes = {
  label: PropTypes.string.isRequired,
  light: PropTypes.bool,
  status: PropTypes.string
};

export default CollectorChip;
