import _ from 'lodash';

import client from '../client';

export async function count(searchParams) {
  return client.get(`/maestro/api/v1/namespaces/_count?${searchParams}`);
}

export async function getById(environmentId, namespaceId) {
  return client.get(`/maestro/api/v1/clusters/${environmentId}/namespaces/${namespaceId}`);
}

export async function list({length = 20, from, orderBy, searchQuery = ''}) {
  const searchParams = new URLSearchParams(searchQuery);
  searchParams.set('length', _.toString(length));
  if (from) {
    searchParams.set('from', from);
  }
  if (orderBy) {
    searchParams.set('orderBy', orderBy);
  }
  return client.get(`/maestro/api/v1/namespaces?${searchParams}`);
}

export async function createNamespace(environmentId, name) {
  return client.post(`/maestro/api/v1/clusters/${environmentId}/namespaces`, {name});
}

export async function updateNamespace(environmentId, namespaceId, name, stacks) {
  return client.patch(`/maestro/api/v1/clusters/${environmentId}/namespaces/${namespaceId}`, {name, stacks});
}
