import _ from 'lodash';
import React from 'react';
import {useHistory} from 'react-router-dom';

import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import roles from '../../../roles';
import services from '../../../services';
import useMenuItem from '../../AGTable/hooks/useMenuItem';

import ConfirmationTextboxModal from '../../Base/ConfirmationTextboxModal.js';

export default function useDestroyMenuItem() {
  const history = useHistory();
  const notifications = context.Notifications.useNotifications();
  const {getUrl} = context.Navigation.useNavigation();

  async function onConfirm({tenantId}) {
    try {
      const result = await services.tenants.destroy_v2(tenantId);
      history.push(getUrl('tenants.tasks', null, {taskId: result.data.id}));
    } catch (error) {
      notifications.error(langs('ERROR_DESTROY'));
    }
  }

  const user = context.User.useUser();
  const [getDef, Modal, row] = useMenuItem(
    onConfirm,
    langs('BUTTON_DESTROY'),
    'outline_delete_white_24dp',
    user.rbac.maestro.isEnabled() ? consts.rbac.permissions.maestro.tenant.destroy : roles.MANAGE_TENANTS,
    user.rbac.maestro,
    ConfirmationTextboxModal
  );

  const tenantName = _.get(row, 'tenantName', '');
  const ExtendedModal = React.cloneElement(Modal, {
    description: langs('TENANT_INSERT_NAME'),
    matchText: tenantName,
    title: langs('TENANT_DELETION_TITLE')
  });
  return [getDef, ExtendedModal];
}
