import styled from 'styled-components';

import * as theme from '../../../theme';

export const Container = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Email = styled.span`
  color: ${theme.TopPanel.text};
  user-select: none;
  font-size: 14px;
  font-weight: 600;
`;

export const RbacOverrideState = styled.div`
  color: ${(props) => (props.active ? theme.terminalColors.blue : theme.backgrounds.envSelector)};
  font-size: 12px;
  position: absolute;
  right: 300px;
  width: 150px;
`;
