import React from 'react';

import AddDemoTenant from './actions/AddDemoTenant';

import * as Styled from '../Tenants/CompactMode/CompactMode.styles';

export default function DemoActions() {
  return (
    <Styled.ActionContainer>
      <AddDemoTenant />
    </Styled.ActionContainer>
  );
}
