import _ from 'lodash';
import React from 'react';

import {Dialog, Paper} from '@material-ui/core';
import {ThemeProvider, useTheme} from '@material-ui/core/styles';

import columnUtils from '../../utils/columnUtils';
import context from '../../context';
import demoSearchUtils from './demoSearchUtils';
import hooks from '../../hooks';
import langs from '../../localization/langs';
import services from '../../services';
import {handleSearchQueryChange} from '../Tenants/CompactMode/compactMode.utils';

import CommandOutput from '../../components/CommandOutput/CommandOutput';
import DemoTenantsTable from './DemoTenantsTable';
import SeedTenantDialog from './actions/SeedTenantDialog';

import * as Styled from '../Tenants/CompactMode/CompactMode.styles';

export default function DemoTenants() {
  const [tableApi, setTableApi] = React.useState();
  const theme = useTheme();
  const notifications = context.Notifications.useNotifications();
  const {resetSearchOptions, setOnTextSearch} = context.Search.useSearch();
  const [searchQuery] = hooks.useUrlSearchParams();
  const [seedTenantDialogOpen, setSeedTenantDialogOpen] = React.useState(false);
  const [seedTenantRow, setSeedTenantRow] = React.useState({isNewSeed: true, tenant: {}});
  const [selectedTenant, setSelectedTenant] = React.useState(null);

  function closeSeedDialog() {
    setSeedTenantDialogOpen(false);
  }

  function closeSeedView() {
    setSelectedTenant(null);
  }

  async function fetchSeedOutput() {
    try {
      const {log: commandOutput, status} = await services.seedService.getMetadata(selectedTenant?.tenantId);
      return {data: {commandOutput, status}};
    } catch (exception) {
      notifications.error(langs('DEMO_REFRESH_ERROR'));
      console.error(langs('DEMO_REFRESH_ERROR'), exception);
    }
  }

  const getRows = React.useCallback(
    async (params) => {
      try {
        const searchParams = `${demoSearchUtils.COMMON_SEARCH_TERMS}&${searchQuery.toString()}`;
        const urlParams = new URLSearchParams(searchParams);
        let {rowCount, rowData} = await handleSearchQueryChange(params.api, params.request, urlParams);
        const metadata = await services.seedService.getAllMetadata();
        const metadataPerTenantId = _.keyBy(metadata, 'tenant_id');

        rowData = _.map(rowData, ({tenantId, tenantName, applicationVersion}) => {
          const metadataCamelCase = _.mapKeys(metadataPerTenantId[tenantId], (value, key) => _.camelCase(key));
          return _.assign({applicationVersion, tenantId, tenantName}, metadataCamelCase);
        });
        params.success({rowCount, rowData});
      } catch (exception) {
        notifications.error(langs('DEMO_REFRESH_ERROR'));
        console.error(langs('DEMO_REFRESH_ERROR'), exception);
      }
    },
    [searchQuery]
  );

  async function onSeedActionClick(tenant, isNewSeed) {
    const {config} = await services.seedService.getConfig(tenant.tenantId);
    setSeedTenantDialogOpen(true);
    setSeedTenantRow({config, isNewSeed, tenant});
  }

  async function openSeedView(tenantId, tenantName) {
    setSelectedTenant({tenantId: parseInt(tenantId), tenantName});
  }

  const refresh = React.useCallback(() => {
    if (tableApi) {
      tableApi.refresh();
    }
  }, [tableApi]);

  React.useEffect(() => {
    setOnTextSearch(_.noop);
    resetSearchOptions(demoSearchUtils.FILTER_CONFIG);
  }, []);

  function getSeedOutputTitle(output) {
    return `${langs('DEMO_SEED_OUTPUT')} - ${selectedTenant?.tenantName} - ${columnUtils.withPrefix(
      'DEMO_',
      output?.status
    )}`;
  }

  return (
    <Paper>
      <SeedTenantDialog {...seedTenantRow} open={seedTenantDialogOpen} close={closeSeedDialog} onCreated={refresh} />
      <Dialog open={!!selectedTenant} onClose={closeSeedView} maxWidth="xl">
        <CommandOutput title={getSeedOutputTitle} fetchOutput={fetchSeedOutput} />
      </Dialog>
      <ThemeProvider theme={theme}>
        <Styled.TableContainer>
          <DemoTenantsTable
            data={getRows}
            dataDeps={[searchQuery]}
            onSeedActionClick={onSeedActionClick}
            onStatusClick={openSeedView}
            setTableApi={setTableApi}
          />
        </Styled.TableContainer>
      </ThemeProvider>
    </Paper>
  );
}
