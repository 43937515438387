import styled from 'styled-components';

import {Button as MuiButton} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import * as theme from '../../../../../theme';

export const Container = styled.div`
  padding-top: 5px;
`;

export const Button = styled(MuiButton).attrs({size: 'small'})`
  background-color: ${theme.backgrounds.primary};
  margin-left: 5px;
  padding: 0;
`;

export const Label = styled.div`
  display: inline-block;
`;

export const TextInput = styled(TextField).attrs({inputProps: {style: {height: '10px'}}})`
  background-color: ${theme.backgrounds.primary};
  font-size: 10pt;
  margin-left: 5px;
`;
