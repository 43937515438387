import _ from 'lodash';

import client from '../client';

export async function list({length = 20, from, orderBy, searchQuery = ''}) {
  const searchParams = new URLSearchParams(searchQuery);
  searchParams.set('length', _.toString(length));
  if (from) {
    searchParams.set('from', from);
  }
  if (orderBy) {
    searchParams.set('orderBy', orderBy);
  }
  return client.get(`/maestro/api/v1/environments?${searchParams}`);
}
