import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import DialogContent from '@material-ui/core/DialogContent';

import langs from '../../localization/langs';
import useCommandOutput from './useCommandOutput.hook';
import * as utils from './CommandOutput.utils';

import ProgressBar from '../Base/Progressbar';

import * as Styled from './CommandOutput.styles';

const LOG_STATUS = {
  FAIL: 'fail',
  SKIP: 'skip',
  SUCCESS: 'success'
};

function CommandOutput({autoRefresh = true, title = langs('COMMAND_OUTPUT'), fetchOutput}) {
  const [isAllOpen, setIsAllOpen] = React.useState();
  const {isLoading, output} = useCommandOutput(autoRefresh, fetchOutput);

  function renderAnsibleLogs(parsed) {
    const groupedLog = utils.groupAnsibleLog(parsed);

    let index = 1;
    return _.transform(
      groupedLog,
      (res, records, name) => {
        if (_.includes(name, utils.NONE_ROLE_PREFIX)) {
          res.push(...transformLevel1Records(records));
        } else {
          const title = _.last(_.split(name, utils.ROLE_PREFIX));
          const isIncludesFailedLogs = includesFailedLogs(records);
          const isOpen = isIncludesFailedLogs || isLastLog(groupedLog, index);
          res.push(
            <Styled.StyledCollapse
              key={name}
              title={title}
              isOpen={isAllOpen}
              defaultIsOpen={isOpen}
              isFail={isIncludesFailedLogs}
              isSuccess={!isIncludesFailedLogs}
            >
              {transformLevel1Records(records, isOpen, 1)}
            </Styled.StyledCollapse>
          );
        }
        index++;
      },
      []
    );
  }

  function transformLevel1Records(allRecords, isParentOpen, indent) {
    let index = 1;
    return _.transform(
      allRecords,
      (res, records, name) => {
        const title = _.get(_.head(records), 'event_data.task');

        if (_.size(records) === 1 || _.isEmpty(title)) {
          res.push(..._.map(records, (record) => renderAnsibleLog(record, indent)));
        } else {
          const logsStatus = getLogsStatus(records);
          const isOpen = logsStatus === LOG_STATUS.FAIL || (isParentOpen && isLastLog(allRecords, index));
          res.push(
            <Styled.StyledCollapse
              key={name}
              indent={indent}
              title={title}
              isOpen={isAllOpen}
              defaultIsOpen={isOpen}
              isFail={logsStatus === LOG_STATUS.FAIL}
              isSkip={logsStatus === LOG_STATUS.SKIP}
              isSuccess={logsStatus === LOG_STATUS.SUCCESS}
            >
              {_.map(records, (record) => renderAnsibleLog(record, indent + 1))}
            </Styled.StyledCollapse>
          );
        }
        index++;
      },
      []
    );
  }

  function isLastLog(logs, index) {
    return index === _.size(logs);
  }

  function includesFailedLogs(allRecords) {
    return _.find(allRecords, (records) => {
      const logsStatus = getLogsStatus(records);
      return logsStatus === LOG_STATUS.FAIL;
    });
  }

  function getLogsStatus(records) {
    let logsStatus = '';
    _.forEach(records, ({event}) => {
      if (_.includes(event, 'on_failed')) {
        logsStatus = LOG_STATUS.FAIL;
      }
      if (_.includes(event, 'on_skipped')) {
        logsStatus = _.isEmpty(logsStatus) ? LOG_STATUS.SKIP : logsStatus;
      }
      if (_.includes(event, 'on_ok')) {
        logsStatus = LOG_STATUS.SUCCESS;
      }
    });
    return logsStatus;
  }

  function renderAnsibleLog(log, indent) {
    let line = '';

    if (log.stdout) {
      if (log.event === 'runner_item_on_failed' && _.get(log, 'event_data.res.msg')) {
        line = `${line} \u001b[0;31m(item=${log.event_data.res.item}) => \n${log.event_data.res.msg}`;
        if (_.get(log, 'event_data.res.exception')) {
          line = `${line} ${log.event_data.res.exception}`;
        }
      } else if (log.event === 'runner_on_failed' && _.get(log, 'event_data.res.msg')) {
        line = `${line} \n${log?.event_data?.res?.stdout || ''}\n\u001b[0;31m${log.event_data.res.msg} `;
        if (_.get(log, 'event_data.res.stderr')) {
          line = `${line}\n\u001b[0;31m${log.event_data.res.stderr}`;
        }
        if (_.get(log, 'event_data.res.exception')) {
          line = `${line} ${log.event_data.res.exception}`;
        }
      } else {
        line = `${line} ${log.stdout} `;
      }
    }
    if (log.stderr) {
      line = `${line} ${log.stderr}`;
    }
    if (log?.event_data?.duration) {
      line = `${line}[${parseInt(log.event_data.duration, 10).toFixed(1)}s]`;
    }

    return line.split('\n').map((singleLine, key) => (
      <Styled.Line key={key} indent={indent}>
        <Styled.Terminal>{singleLine}</Styled.Terminal>
      </Styled.Line>
    ));
  }

  function isAnsibleLog(parsed) {
    return _.isArray(parsed) && _.isObject(_.head(parsed));
  }

  function isTerraformLog(parsed) {
    return _.isArray(parsed) && _.isString(_.head(parsed));
  }

  function getOutput(stream) {
    let parsed;
    try {
      parsed = JSON.parse(stream);
    } catch (e) {
      return (
        <pre>
          <Styled.Terminal>{stream}</Styled.Terminal>
        </pre>
      );
    }
    if (isAnsibleLog(parsed)) {
      return renderAnsibleLogs(parsed);
    }
    if (isTerraformLog(parsed)) {
      return parsed.map((line, key) => (
        <pre key={key}>
          <Styled.Terminal>{line}</Styled.Terminal>
        </pre>
      ));
    }

    return <Styled.Terminal>{stream}</Styled.Terminal>;
  }

  const titleText = _.isFunction(title) ? title(output) : title;
  return (
    <DialogContent>
      <div>
        <Styled.TitleContainer>
          <Styled.Title>{titleText}</Styled.Title>
          <Styled.ActionsContainer>
            <Styled.ActionButton onClick={() => setIsAllOpen(true)}>{langs('EXPAND_ALL')}</Styled.ActionButton>
            <Styled.ActionButton onClick={() => setIsAllOpen(false)}>{langs('COLLAPSE_ALL')}</Styled.ActionButton>
          </Styled.ActionsContainer>
        </Styled.TitleContainer>
        <Styled.Container>{!_.isEmpty(output?.commandOutput) && getOutput(output.commandOutput)}</Styled.Container>
        <Styled.Container>{!_.isEmpty(output?.commandError) && getOutput(output.commandError)}</Styled.Container>
      </div>
      {isLoading && <ProgressBar isLoading={isLoading} />}
    </DialogContent>
  );
}

CommandOutput.propTypes = {
  autoRefresh: PropTypes.bool,
  fetchOutput: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

export default CommandOutput;
