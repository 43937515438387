import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router-dom';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import LibraryAddCheckOutlined from '@material-ui/icons/LibraryAddCheckOutlined';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/SearchOutlined';

import context from '../../../../../context';
import IconedLabel from '../../../../Base/IconedLabel';
import langs from '../../../../../localization/langs';

import * as Styled from './ActionsMenu.styles';

function ActionsMenu({keyName, value, actions, displaySettings}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = !_.isNil(anchorEl);

  const history = useHistory();
  const {getUrl} = context.Navigation.useNavigation();

  function handleClick(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event) {
    event.stopPropagation();
    setAnchorEl(null);
  }

  function searchKey() {
    history.push(getUrl('tenants.configurationTable', null, {key: keyName}));
  }

  function runAction(action) {
    action();
    setAnchorEl(null);
  }

  return (
    <Styled.Container>
      <Styled.MenuIcon onClick={handleClick} />
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose} PaperProps={Styled.menuPaperProps}>
        <MenuItem onClick={() => runAction(() => navigator.clipboard.writeText(value))}>
          <IconedLabel icon={<FileCopyIcon />} lowProfile>
            {langs('COPY_VALUE_TO_CLIPBOARD')}
          </IconedLabel>
        </MenuItem>
        {_.isFunction(actions.deleteValue) && (
          <MenuItem onClick={() => runAction(actions.deleteValue)}>
            <IconedLabel icon={<DeleteOutlineIcon />} lowProfile>
              {langs('DELETE_VALUE')}
            </IconedLabel>
          </MenuItem>
        )}
        {_.isFunction(actions.showVersions) && (
          <MenuItem onClick={() => runAction(actions.showVersions)}>
            <IconedLabel icon={<LibraryAddCheckOutlined />} lowProfile>
              {langs('SHOW_VERSIONS')}
            </IconedLabel>
          </MenuItem>
        )}
        {displaySettings.showConfigurationKeySearch && (
          <MenuItem onClick={() => runAction(searchKey)}>
            <IconedLabel icon={<SearchIcon />} lowProfile>
              {langs('SEARCH_CONFIGURATION_KEY')}
            </IconedLabel>
          </MenuItem>
        )}
      </Menu>
    </Styled.Container>
  );
}

ActionsMenu.propTypes = {
  actions: PropTypes.shape({
    deleteValue: PropTypes.func,
    showVersions: PropTypes.func
  }),
  displaySettings: PropTypes.shape({
    showAllLabel: PropTypes.bool,
    showConfigurationKeySearch: PropTypes.bool,
    showCreatedByCol: PropTypes.bool,
    showEmptyFilter: PropTypes.bool,
    showInheritCol: PropTypes.bool,
    showLastUpdateCol: PropTypes.bool,
    titleizeSubCategories: PropTypes.bool
  }),
  keyName: PropTypes.string,
  value: PropTypes.any
};

export default ActionsMenu;
