import _ from 'lodash';
import styled from 'styled-components';

import * as theme from '../../../../../theme';

const colors = {
  BUILD_FAILED: theme.statusColors.error,
  DECLINED: theme.statusColors.info,
  HAS_CONFLICTS: theme.statusColors.warning,
  MERGED: theme.statusColors.success
};

export const Container = styled.div`
  color: ${(props) => _.get(colors, [props.status], null)};
`;
