import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

function ListColumn({value}) {
  if (!value) {
    return null;
  }
  return (
    <ul>
      {_.map(value, (item, index) => (
        <li key={`${item}-${index}`}>{item}</li>
      ))}
    </ul>
  );
}

ListColumn.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string)
};

export default ListColumn;
