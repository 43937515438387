export const fields = {
  APPLICATION_VERSION: 'applicationVersion',
  COLLECTOR_NUMBER: 'collectorNumber',
  COLLECTOR_STATUS: 'collectorStatus',
  COMMAND_ERROR: 'commandError',
  COMMAND_OUTPUT: 'commandOutput',
  COMMAND_STATUS: 'commandStatus',
  CREATION_DATE: 'collectorCreationDate',
  FACTS_APPLICATION_VERSION: 'facts.applicationVersion',
  FIRMWARE_VERSION_ID: 'firmwareVersionId',
  INTEGRATIONS: 'integrations',
  LAST_SEEN: 'lastSeen',
  STAGE: 'stage',
  TASK_ACTIVITY_STATUS: 'taskActivityStatus',
  TENANT_ID: 'tenantId',
  TENANT_NAME: 'tenantName'
};

export const collectorStageOptions = {
  ACTIVATED: 'ACTIVATED',
  DECOMMISSIONED: 'DECOMMISSIONED',
  OPERATIONAL: 'OPERATIONAL',
  PRE_PROVISIONED: 'PRE_PROVISIONED',
  PROVISIONED: 'PROVISIONED'
};

export const collectorStatusOptions = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export default {
  collectorStageOptions,
  collectorStatusOptions,
  fields
};
