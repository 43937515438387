import _ from 'lodash';

import maps from './maps';

function getGroups(schema) {
  const roleMap = maps.roles(schema);
  let groups = _.groupBy(schema.groups, 'name');
  groups = _.map(groups, (groupItems, groupKey) => {
    let services = _.groupBy(groupItems, 'serviceName');
    services = _.map(services, (serviceItems, serviceKey) => {
      let envs = _.groupBy(serviceItems, 'envName');
      envs = _.map(envs, (envItems, envKey) => {
        let roleIds = _.uniq(_.flatten(_.map(envItems, 'roleIds')));
        let roles = _.map(roleIds, (roleId) => ({id: roleId, name: roleMap[roleId].name}));
        return {id: envKey, roles};
      });
      return {envs, id: serviceKey};
    });
    return {id: groupKey, services};
  });
  return groups;
}

function getRoles(schema) {
  const derivedPermissionsMap = maps.derivedPermissions(schema);
  const permissionsMap = maps.permissions(schema);
  return _.transform(
    schema.roles,
    (result, role) => {
      let permissions = [];
      _.forEach(role.permissionIds, (permissionId) => {
        const derivedPermissions = _.get(derivedPermissionsMap, permissionId, []);
        permissions.push(_.assign({}, permissionsMap[permissionId], {derivedPermissions}));
      });
      result[role.id] = {
        id: role.id,
        name: role.name,
        permissions: _.flatten(permissions)
      };
    },
    {}
  );
}

export default {
  groups: getGroups,
  roles: getRoles
};
