import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import * as Styled from './RoleList.styles';

function getRoleRow(role, onRoleSelectionChange) {
  return (
    <TableRow key={role.id}>
      <Styled.TableCell>
        <Switch
          checked={role.selected}
          onChange={({target: {checked}}) => onRoleSelectionChange(role.id, checked)}
          size="small"
        />
      </Styled.TableCell>
      <Styled.TableCell>{role.name}</Styled.TableCell>
    </TableRow>
  );
}

function RoleList({onRoleSelectionChange, roles}) {
  return (
    <Table>
      <TableBody>{_.map(roles, (role) => getRoleRow(role, onRoleSelectionChange))}</TableBody>
    </Table>
  );
}

RoleList.propTypes = {
  onRoleSelectionChange: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired
    })
  )
};

export default RoleList;
