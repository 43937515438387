import styled from 'styled-components';

import {Button as MuiButton, Typography} from '@material-ui/core/index';

import {Flex} from '../../Collectors/Drawer/CollectorPreview.styles';
import {Link as BaseLink} from '../../Base';

import * as theme from '../../../theme';

export const TaskSectionContainer = styled.div`
  padding: 20px 7px;
`;

export const Title = styled(Typography).attrs({variant: 'h6'})`
  margin-bottom: 10px;
`;

export const Link = styled(BaseLink).attrs({$dense: true})``;

export const Button = styled(MuiButton).attrs({size: 'small'})`
  padding: 0;
`;

export const Row = styled(Flex)`
  border-bottom: 1px ${theme.separators.transparent} solid;
  padding: 5px;
`;

export const StatusColumn = styled(Flex)`
  flex: 5;
`;

export const ViewLogsColumn = styled(Flex)`
  justify-content: center;
  flex-grow: 1;
`;

export const DateColumn = styled(Flex)`
  flex: 4;
  justify-content: flex-end;
`;
