import staticData from '../../models/staticData';

const OPS_WEB_PERMISSIONS = staticData.RBAC.SERVICE_PERMISSIONS.OPS_WEB;

export default {
  view: {
    cherryPicks: OPS_WEB_PERMISSIONS.VIEW_CHERRY_PICKS,
    collectors: {
      beacons: OPS_WEB_PERMISSIONS.VIEW_COLLECTORS_BEACONS,
      config: OPS_WEB_PERMISSIONS.VIEW_COLLECTORS_CONFIG,
      images: OPS_WEB_PERMISSIONS.VIEW_COLLECTORS_IMAGES,
      tasks: OPS_WEB_PERMISSIONS.VIEW_COLLECTORS_TASKS,
      view: OPS_WEB_PERMISSIONS.VIEW_COLLECTORS
    },
    demo: OPS_WEB_PERMISSIONS.VIEW_DEMO,
    multiTenant: {
      config: OPS_WEB_PERMISSIONS.VIEW_MT_CONFIG,
      logs: OPS_WEB_PERMISSIONS.VIEW_MT_LOGS,
      namespaces: OPS_WEB_PERMISSIONS.VIEW_MT_NAMESPACES,
      services: OPS_WEB_PERMISSIONS.VIEW_MT_SERVICES,
      stacks: OPS_WEB_PERMISSIONS.VIEW_MT_STACKS,
      tasks: OPS_WEB_PERMISSIONS.VIEW_MT_TASKS,
      view: OPS_WEB_PERMISSIONS.VIEW_MT
    },
    tenants: {
      config: OPS_WEB_PERMISSIONS.VIEW_TENANTS_CONFIG,
      tasks: OPS_WEB_PERMISSIONS.VIEW_TENANTS_TASKS,
      view: OPS_WEB_PERMISSIONS.VIEW_TENANTS
    },
    viewAs: OPS_WEB_PERMISSIONS.FE_SELECT_EFFECTIVE_ROLE_GROUPS
  }
};
