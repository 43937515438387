import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const filter = createFilterOptions();

function AutoComplete({label, options, onChange, onInputChange}) {
  const [value, setValue] = React.useState(null);

  function onValueSelect(selectedValue, isNew) {
    if (isNew) {
      setValue({name: selectedValue});
      onChange(selectedValue, true);
    } else {
      setValue(selectedValue);
      onChange(selectedValue, false);
    }
  }

  return (
    <Autocomplete
      freeSolo
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          onValueSelect(newValue, true);
        } else if (newValue && newValue.inputValue) {
          onValueSelect(newValue.inputValue, true);
        } else {
          onValueSelect(newValue, false);
        }
      }}
      filterOptions={(options, params) => {
        const {inputValue} = params;
        const filtered = filter(options, params);
        const isExisting = _.some(options, {name: inputValue});
        if (!_.isEmpty(inputValue) && !isExisting) {
          filtered.push({inputValue, name: `Add "${inputValue}"`});
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.name;
      }}
      renderOption={(option) => <div>{option.name}</div>}
      renderInput={(params) => <TextField {...params} label={label} />}
      onInputChange={(event, newInputValue) => onInputChange(newInputValue)}
    />
  );
}

AutoComplete.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default AutoComplete;
