import _ from 'lodash';
import React from 'react';

import consts from '../../../../../consts';
import context from '../../../../../context';
import langs from '../../../../../localization/langs';
import services from '../../../../../services';
import useMenuItem from '../../../../AGTable/hooks/useMenuItem';
import roles from '../../../../../roles';

export default function useSubmitReviewMenuItem(isApprove, onChange = _.noop) {
  const notifications = context.Notifications.useNotifications();
  const user = context.User.useUser();

  async function onConfirm({prId}) {
    try {
      await services.releases.submitReviewRequest(prId, isApprove);
      onChange();
    } catch (error) {
      notifications.error(error.response.data);
      console.error(error.response.data);
    }
  }

  const [getDef, Modal] = useMenuItem(
    onConfirm,
    isApprove ? langs('BUTTON_APPROVE') : langs('BUTTON_DECLINE'),
    null,
    user.rbac.maestro.isEnabled() ? consts.rbac.permissions.maestro.tenant.start : roles.MANAGE_TENANTS,
    user.rbac.maestro
  );

  const description = isApprove ? langs('RELEASE_APPROVE_DESCRIPTION') : langs('RELEASE_DECLINE_DESCRIPTION');
  const ExtendedModal = React.cloneElement(Modal, {
    description: description,
    title: langs('ARE_YOU_SURE')
  });
  return [getDef, ExtendedModal];
}
