import PropTypes from 'prop-types';
import React from 'react';

import CloseIcon from '@material-ui/icons/Close';

import Button from './Button';

import * as Styled from './Modal.styles';

function Modal({open = false, onClose, children, noPadding = false, showClose = false, ...props}) {
  const ref = React.useRef();

  function handleClose() {
    if (open && onClose) {
      onClose();
    }
  }

  function noPropogation(event) {
    event.stopPropagation();
  }

  if (!open) {
    return <></>;
  }

  return (
    <Styled.Lightbox onClick={handleClose}>
      <Styled.Modal onClick={noPropogation} ref={ref} noPadding={noPadding} {...props}>
        {showClose && (
          <Styled.Close onClick={handleClose}>
            <CloseIcon />
          </Styled.Close>
        )}
        {children}
      </Styled.Modal>
    </Styled.Lightbox>
  );
}

Modal.Title = Styled.Title;
Modal.Body = Styled.Body;

Modal.Navigation = Styled.Navigation;
Modal.Navigation.Button = (props) => <Button secondary size="medium" {...props} />;
Modal.Navigation.Spacer = Styled.Spacer;

Modal.propTypes = {
  children: PropTypes.node,
  noPadding: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  showClose: PropTypes.bool
};

export default Modal;
