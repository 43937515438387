import {NavLink} from 'react-router-dom';
import styled, {css} from 'styled-components';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import * as theme from '../../theme';

export const Wrapper = styled.div`
  margin-bottom: 8px;
`;

function getMenuItemStyle() {
  return css`
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    text-decoration: none;
  `;
}

export const Label = styled.div`
  color: ${theme.navbar.text};
  font-weight: ${({isHeader}) => (isHeader ? '600' : '100')};
  font-size: 16px;
  user-select: none;
`;

export const MenuButton = styled.div`
  ${getMenuItemStyle()}
`;

export const NavigationButton = styled(NavLink)`
  ${getMenuItemStyle()}
  &.active {
    ${Label} {
      color: ${theme.navbar.activeText};
    }
  }
`;

export const IconAndLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const Spacer = styled.span`
  display: inline-block;
  width: 25px;
`;

export const StyledExpandLess = styled(ExpandLess)`
  color: ${theme.navbar.text};
`;

export const StyledExpandMore = styled(ExpandMore)`
  color: ${theme.navbar.text};
`;
