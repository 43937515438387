import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import consts from '../../../../consts';
import hooks from '../../../../hooks';
import langs from '../../../../localization/langs';

import {StackedBarChart} from '../../../Charts';

function SingleBarChart({field, countApi, translateLabels = false, skipColors = 0}) {
  const [hosts, setHosts] = React.useState([]);

  const [, setValue] = hooks.useSearchQueryFilter(field, consts.FILTER_OPERATORS.EQUAL);
  const [query] = hooks.useUrlSearchParams();
  const queryString = query.toString();

  React.useEffect(() => {
    countApi(field, queryString).then(({data: hostsResponse}) =>
      setHosts(
        _.map(hostsResponse, (stat) => {
          return {
            ...stat,
            label: translateLabels ? langs(stat.name) : _.isNull(stat.name) ? langs('NONE') : stat.name,
            name: _.toString(stat.name) || 'null'
          };
        })
      )
    );
  }, [countApi, field, queryString, translateLabels]);

  return <StackedBarChart value={hosts} onClick={setValue} skipColors={skipColors} />;
}

SingleBarChart.propTypes = {
  countApi: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  skipColors: PropTypes.number,
  translateLabels: PropTypes.bool
};

export default SingleBarChart;
