import React from 'react';

import assets from '../../../assets';
import context from '../../../context';

import * as Styled from './Logo.styles';

function Logo(props) {
  const {getUrl} = context.Navigation.useNavigation();

  return (
    <Styled.Container to={getUrl('tenants.main')} {...props}>
      <Styled.Logo src={assets.ArmisLogo} alt="Armis" />
      <Styled.Title>OPS</Styled.Title>
    </Styled.Container>
  );
}

export default Logo;
