import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import MultipleChoiceInput from '../Base/inputs/MultipleChoiceInput';

function DemoModulesMultipleChoiceInput({config, value, onChange, disabled}) {
  return (
    <MultipleChoiceInput
      disabled={disabled}
      onChange={onChange}
      value={value}
      options={_(config.modules)
        .compact()
        .reject((module) => _.split(module, '/').length > 2)
        .uniq()
        .value()}
    />
  );
}

DemoModulesMultipleChoiceInput.propTypes = {
  config: PropTypes.object.isRequired,
  disabled: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired
};

export default DemoModulesMultipleChoiceInput;
