import _ from 'lodash';
import React from 'react';

import consts from '../../consts';
import services from '../../services';

import rbac from './rbac/rbac';

import InitializingOverlay from '../../components/App/Parts/InitializingOverlay';

export const UserContext = React.createContext(null);

export const useUser = () => React.useContext(UserContext);

const ACCESS_TOKEN_COOKIE_NAME = 'access-token';
const IS_LOCAL_HOST = _.startsWith(_.toLower(window.location.host), 'localhost');

function getCookie(name) {
  return `; ${document.cookie}`
    .split(`; ${name}=`)
    .pop()
    .split(';')
    .shift();
}

function parseToken(token) {
  try {
    const base64Payload = _(token)
      .split('.')
      .nth(1)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const jsonPayload = _(atob(base64Payload))
      .split('')
      .map((char) => `%${('00' + char.charCodeAt(0).toString(16)).slice(-2)}`)
      .join('');
    return JSON.parse(decodeURIComponent(jsonPayload));
  } catch (err) {
    return {};
  }
}

export const UserContextProvider = ({children}) => {
  const [cmsPermissions, setCmsPermissions] = React.useState(null);
  const [rbacEnvs, setRbacEnvs] = React.useState(null);
  const [rbacPermissions, setRbacPermissions] = React.useState(null);
  const [viewAsRbacPermissions, setViewAsRbacPermissions] = React.useState();
  const [rbacState, setRbacState] = React.useState({});
  const [rbacStateOverride, setRbacStateOverride] = React.useState({});
  const [rbacVerboseState, setRbacVerboseState] = React.useState(false);
  const [isGithubAuthenticated, setIsGithubAuthenticated] = React.useState(false);

  async function validateGithubUser() {
    return services.releases.validateGithubToken();
  }

  React.useEffect(() => {
    services.collectors.profile.listPermissions().then(({data}) => setCmsPermissions(data));

    if (!IS_LOCAL_HOST) {
      services.rbac.getEnvs().then(setRbacEnvs);
      services.rbac.getPermissions().then(({data}) => setRbacPermissions(data));
      services.rbac.getStates.all().then(setRbacState);
    }
  }, []);

  const accessToken = getCookie(ACCESS_TOKEN_COOKIE_NAME);
  const isDev = process.env.NODE_ENV === 'development';
  const email = _.get(parseToken(accessToken), 'sub');

  function getViewAsRbacPermissions(envNames, serviceNames, userGroups) {
    services.rbac.getViewAsPermissions(envNames, serviceNames, userGroups).then(({data}) => {
      console.log('View as RBAC permissions set.');
      setViewAsRbacPermissions(data);
    });
  }

  function getRbacPermissions(ignoreViewAs = false) {
    if (!_.isEmpty(viewAsRbacPermissions) && !ignoreViewAs) {
      return _.get(viewAsRbacPermissions, 'permissions');
    }
    return _.get(rbacPermissions, 'permissions');
  }

  function getRbacState(serviceName) {
    if (!_.isEmpty(rbacStateOverride) && _.has(rbacStateOverride, serviceName)) {
      return rbacStateOverride[serviceName];
    }
    return _.get(rbacState, serviceName, rbac.consts.ENFORCEMENT_LEVEL.DISABLE);
  }

  function hasRole(roleName) {
    if (isDev) {
      return true;
    }
    return _.includes(_.get(cmsPermissions, 'roles'), roleName);
  }

  const hasViewAsPermissions = rbac.hasPermission(
    rbac.consts.SERVICE_NAMES.FE.opsWeb,
    () => getRbacPermissions(true),
    consts.rbac.permissions.opsWeb.view.viewAs
  );

  const allowedExportSchema = rbac.hasPermission(
    rbac.consts.SERVICE_NAMES.BE.rbac,
    () => getRbacPermissions(true),
    consts.rbac.permissions.rbac.schema.export
  );

  const getSchema = {
    asGraph: () => services.rbac.getPermissionsSchema().then(({data}) => rbac.transformSchemaAsGraph(data)),
    asGroups: () => services.rbac.getPermissionsSchema().then(({data}) => rbac.transformSchemaAsGroups(data)),
    asRaw: () => services.rbac.getPermissionsSchema().then(({data}) => rbac.transformSchemaAsRaw(data))
  };

  rbac.registerWindowRbacFunctions(
    rbacEnvs,
    allowedExportSchema,
    getSchema,
    getViewAsRbacPermissions,
    hasViewAsPermissions,
    rbacPermissions,
    rbacState,
    rbacStateOverride,
    rbacVerboseState,
    setRbacStateOverride,
    setRbacVerboseState,
    setViewAsRbacPermissions,
    viewAsRbacPermissions
  );

  const rbacAccessors = rbac.getRbacAccessors(
    _.values(rbac.consts.SERVICE_NAMES.FE),
    getRbacState,
    getRbacPermissions,
    rbacEnvs
  );

  const contextHolder = {
    ...cmsPermissions,
    accessToken,
    email,
    github: {
      isAuthenticated: isGithubAuthenticated,
      setIsAuthenticated: setIsGithubAuthenticated,
      validate: validateGithubUser
    },
    hasRole,
    rbac: {
      ...rbacAccessors,
      consts: {
        SERVICE_NAMES: rbac.consts.SERVICE_NAMES
      },
      grantedPermissions: () => getRbacPermissions(),
      schema: {
        get: (oktaLogin) => services.rbac.getPermissionsSchema(oktaLogin).then(({data}) => data),
        getEnvsNamesFrom: (schema) => rbac.getEnvNamesFromSchema(schema),
        self: {
          get: () => services.rbac.getSelfPermissionsSchema().then(({data}) => data)
        },
        transform: {
          asGraph: rbac.transformSchemaAsGraph,
          asGroups: rbac.transformSchemaAsGroups,
          userGraph: rbac.composeUserPermissionsGraph
        }
      }
    }
  };

  const isRbacActive = _.some(_.values(rbacState), (value) => value === consts.rbac.ENFORCEMENT_LEVEL.FULL);
  const isInitializing = IS_LOCAL_HOST
    ? false
    : _.isEmpty(rbacState) || (isRbacActive && (_.isNull(rbacPermissions) || _.isNull(rbacEnvs)));

  return (
    <UserContext.Provider value={contextHolder}>
      {isInitializing ? <InitializingOverlay /> : children}
    </UserContext.Provider>
  );
};
