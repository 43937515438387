import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './PullRequest.styles';

function PullRequest({value: {source_pr_url: sourcePrUrl}}) {
  const prNum = _(sourcePrUrl)
    .split('/')
    .last();

  return (
    <Styled.Link href={sourcePrUrl} target="_blank" rel="noreferrer">
      {prNum}
    </Styled.Link>
  );
}

PullRequest.propTypes = {
  value: PropTypes.shape({
    source_pr_url: PropTypes.string
  }).isRequired
};

export default PullRequest;
