import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  font-size: 16px;
  text-align: center;
`;
