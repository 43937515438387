import _ from 'lodash';

import consts from '../../consts';
import client from '../client';
import models from '../../models';

import * as artifactUtils from '../artifact.utils';

export async function getCmsTenants() {
  return client.get('/cms/api/v1/tenants');
}

export function getNextCollectorNumber(tenantId) {
  return client.get(`/cms/api/v1/tenants/${tenantId}/collectors/_next_collector_number`);
}

export async function getTenantsFromAllEnv() {
  return client.get('/cms/api/v1/tenants?all_envs=true');
}

export async function getCollectors(tenantId, length = -1, orderBy = 'collectorId') {
  return client.get(
    `/cms/api/v1/collectors?tenantId${consts.FILTER_OPERATORS.EQUAL}=${tenantId}&length=${length}&orderBy=${orderBy}`
  );
}

export async function getCollectorsIntegrations(tenantId) {
  return client.get(`/cms/api/v1/tenants/${tenantId}/collectors/integrations`);
}

export async function getConfiguration(category, tenantId, collectorId) {
  let route;
  if (!_.isEmpty(tenantId) && !_.isEmpty(collectorId)) {
    route = `/cms/api/v1/tenants/${tenantId}/collectors/${collectorId}/configuration`;
  } else if (!_.isEmpty(tenantId)) {
    route = `/cms/api/v1/tenants/${tenantId}/configuration`;
  } else {
    route = '/cms/api/v1/configuration';
  }
  return client.get(`${route}?category=${category}`);
}

export async function deleteConfigurationValue(configurationValueId) {
  return client.delete(`/cms/api/v1/configuration_value/${configurationValueId}`);
}

export async function updateConfiguration(configurationValueId, value) {
  return client.patch(`/cms/api/v1/configuration_value/${configurationValueId}`, {value: JSON.stringify({value})});
}

export async function createConfigurationValue({name, value, labels, mainCategory, subCategory}) {
  return client.post(`/cms/api/v1/configuration_value`, {
    keyCategory: mainCategory,
    keyName: name,
    keySubCategory: subCategory,
    labels: _(labels)
      .mapKeys((value, key) => _.snakeCase(key))
      .omitBy(_.isEmpty)
      .value(),
    value: JSON.stringify({value})
  });
}

export async function createConfigurationKey(
  key,
  keyCategory,
  keySubCategory,
  type,
  defaultValue,
  description,
  labels
) {
  return client.post(`/cms/api/v1/configuration_key`, {
    defaultValue: JSON.stringify({value: defaultValue}),
    description,
    key,
    keyCategory,
    keySubCategory,
    labels: _(labels)
      .mapKeys((value, key) => _.snakeCase(key))
      .omitBy(_.isEmpty)
      .value(),
    type
  });
}

export async function countCollectors(groupBy, qs) {
  return client.get(`/cms/api/v1/collectors/_count?groupBy=${groupBy}&${qs}`);
}

export async function getAllFilterOptions(field, query) {
  return client.get(`/cms/api/v1/collectors/_count?${query}&groupBy=${field}&length=-1`);
}

export async function decommission(tenantId, collectorId) {
  return client.patch(`/cms/api/v1/tenants/${tenantId}/collector/${collectorId}`, {
    stage: models.collector.collectorStageOptions.DECOMMISSIONED
  });
}

export async function deleteCollector(tenantId, collectorId) {
  return client.delete(`/cms/api/v1/tenants/${tenantId}/collectors/${collectorId}`);
}

export async function markCollectorAsOperational(tenantId, collectorId) {
  return client.patch(`/cms/api/v1/tenants/${tenantId}/collector/${collectorId}`, {
    stage: models.collector.collectorStageOptions.OPERATIONAL
  });
}

export async function listCollectors({length = 20, from, orderBy, searchQuery = ''}) {
  const fromQuery = from ? `&from=${from}` : '';
  const orderByQuery = orderBy ? `&orderBy=${orderBy}` : '';

  return client.get(`/cms/api/v1/collectors?length=${length}${fromQuery}${orderByQuery}&${searchQuery}`);
}

export async function listTenants() {
  return client.get('/cms/api/v1/tenants');
}

export async function listArtifacts({
  like = '',
  branchTypes = null,
  isLatestBuild = true,
  length = 100,
  orderBy = 'creationDate:desc'
}) {
  const params = artifactUtils.getParams(like, branchTypes, length, orderBy, isLatestBuild);
  return client.get('/cms/api/v1/artifacts', params);
}

export function getArtifactByBranch(branch) {
  return client.get(`/cms/api/v1/artifacts?branch=${branch}&orderBy=id:desc&length=1`);
}

export async function getArtifact(id) {
  return client.get(`/cms/api/v1/artifacts/${id}`);
}

export async function getCollectorsTaskOutput(tenantId, taskId, collectorId) {
  return client.get(`/cms/api/v1/tenants/${tenantId}/collector/${collectorId}/task/${taskId}/output`);
}

export async function getApplicationVersions() {
  return client.get(`/cms/api/v1/collectors/_count?groupBy=${models.collector.fields.APPLICATION_VERSION}`);
}

export async function createShellCommand(command, description) {
  return client.post(`/cms/api/v1/commands`, {
    command,
    commandType: models.command.commandType.SHELL_COMMAND,
    description,
    status: 'ACTIVE'
  });
}

export async function archiveShellCommand(id) {
  return client.patch(`/cms/api/v1/commands/${id}`, {status: 'ARCHIVED'});
}

export async function getShellCommands() {
  return client.get(
    `/cms/api/v1/commands?length=-1&status${consts.FILTER_OPERATORS.EQUAL}=ACTIVE&commandType${consts.FILTER_OPERATORS.EQUAL}=${models.command.commandType.SHELL_COMMAND}`
  );
}

export async function getFieldCommands() {
  return client.get(`/cms/api/v1/commands`, {
    length: '-1',
    [`status${consts.FILTER_OPERATORS.EQUAL}`]: 'ACTIVE',
    [`commandType${consts.FILTER_OPERATORS.EQUAL}`]: models.command.commandType.SHELL_COMMAND,
    [`category${consts.FILTER_OPERATORS.EQUAL}`]: models.command.category.FIELD_ACTIONS
  });
}

export async function getCommandStatusByCollectorId(collectorId, length = 100, orderBy = 'command_status_id:desc') {
  return client.get(`/cms/api/v1/commands_status?length=${length}&hostId[eq]=${collectorId}&orderBy=${orderBy}`);
}

export async function getVersions(tenantId, collectorId, configurationKeyId) {
  let route;
  if (!_.isEmpty(tenantId) && !_.isEmpty(collectorId)) {
    route = `/cms/api/v1/tenants/${tenantId}/collectors/${collectorId}/configuration_key/${configurationKeyId}/versions`;
  } else if (!_.isEmpty(tenantId)) {
    route = `/cms/api/v1/tenants/${tenantId}/configuration_key/${configurationKeyId}/versions`;
  } else {
    route = `/cms/api/v1/configuration_key/${configurationKeyId}/versions`;
  }
  return client.get(route);
}

export async function getConfigurationKeys(keyName) {
  return client.get(`/cms/api/v1/configuration_keys?key[like]=%${keyName}%&length=10`);
}
