import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import * as Styled from './ConfigurationSelect.styles';

function ConfigurationSelect({value, onChange, options}) {
  return (
    <FormControl fullWidth>
      <Styled.Select value={value} onChange={(event) => onChange(event.target.value)}>
        {_.map(options, (option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Styled.Select>
    </FormControl>
  );
}

ConfigurationSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    })
  ).isRequired,
  value: PropTypes.string.isRequired
};

export default ConfigurationSelect;
