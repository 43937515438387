import _ from 'lodash';
import React from 'react';

import consts from '../../../consts';
import services from '../../../services';

import AutocompleteTableFilter from '../../AutocompleteTableFilter';

import modelToQuery from '../../../utils/agGridUtils/filterUtils/modelToQuery';
import queryUtils from '../../../utils/agGridUtils/queryUtils';

function extractTenantsQueryString(value, tenants) {
  const tenantIds = _(tenants)
    .filter((tenant) => tenant.name.includes(value))
    .map((tenant) => tenant.id)
    .join(',');
  if (tenantIds) {
    return `tenantId${consts.FILTER_OPERATORS.EQUAL}=${tenantIds}`;
  }
  return `tenantId${consts.FILTER_OPERATORS.EQUAL}=0`;
}

function getFieldName(column) {
  return column.field.replace('facts.', '');
}

function extractQueryString(filters, tenants) {
  return _.map(filters, ({column, value}) => {
    const fieldName = getFieldName(column);
    if (value.includes(':')) {
      return `${fieldName}[${value.split(':')[0]}]=${value.split(':')[1]}`;
    }
    if (column.field === 'tenantName') {
      return extractTenantsQueryString(value, tenants);
    }
    if (column.type === 'numeric') {
      return `${fieldName}${consts.FILTER_OPERATORS.EQUAL}=${value}`;
    }
    return `${fieldName}${consts.FILTER_OPERATORS.LIKE}=${encodeURIComponent(`%${value}%`)}`;
  }).join('&');
}

function header(title, field, component, options) {
  return {
    cellStyle: consts.STYLED_TABLE_CELL,
    field,
    filterComponent: () => <AutocompleteTableFilter id={getFieldName({field})} />,
    headerStyle: consts.STYLED_TABLE_CELL,
    render: component,
    title,
    ...options
  };
}

function extractOrderBy(query) {
  if (query.orderBy) {
    return `${query.orderBy.field.replace('facts.', '')}:${query.orderDirection}`;
  }
  return 'lastSeen:desc';
}

async function handleQueryChange(query, tenants, collectorSelection, searchQuery) {
  const orderBy = extractOrderBy(query);
  const filters = extractQueryString(query.filters, tenants);
  const from = query.page * query.pageSize;
  const response = await services.collectors.listCollectors({
    from,
    length: query.pageSize,
    orderBy,
    searchQuery: `${searchQuery}&${filters}`
  });
  const data = _.map(response.data.items, (item) => ({
    tableData: {
      checked: _.map(collectorSelection, (selection) => selection.collectorId).includes(item.collectorId)
    },
    ...item
  }));
  return {
    data,
    page: query.page,
    totalCount: response.data.count
  };
}

export async function handleSearchQueryChange(api, request, searchQuery) {
  const query = queryUtils.getQueryParams(api, request, searchQuery);
  const response = await services.collectors.listCollectors(query);
  return queryUtils.parseResponse(response);
}

async function fetchFilterOptions({colDef, success}) {
  const filterModel = colDef.gridApi.getFilterModel();
  const filterSearchQuery = modelToQuery.getQuery(colDef.gridApi, filterModel);
  const response = await services.collectors.getAllFilterOptions(colDef.colId, filterSearchQuery);
  success(_.map(response.data, 'name'));
}

export default {
  fetchFilterOptions,
  getFieldName,
  handleQueryChange,
  handleSearchQueryChange,
  header
};
