import _ from 'lodash';

export function getEnvNameFromLabels(labels) {
  const env = _.find(labels, {label: 'ENV'});
  return _.get(env, ['value', 'name']);
}

export function envAwareHasPermission(hasPermission, requestedPermission, labels, envNames) {
  const env = getEnvNameFromLabels(labels);
  return env ? hasPermission(requestedPermission, env) : hasAllPermission(hasPermission, requestedPermission, envNames);
}

export function hasAllPermission(hasPermission, requestedPermission, envNames) {
  return _(envNames)
    .map((env) => hasPermission(requestedPermission, env))
    .every();
}
