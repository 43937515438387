import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import * as Styled from './Accordion.styles';

const TOGGLE_KEYS = ['Enter', ' '];
const TOGGLE_ARROWS = ['ArrowLeft', 'ArrowRight'];

function Accordion({children, show = false, title = null, onChange = null}) {
  const [visible, setVisible] = React.useState(show);

  React.useEffect(() => {
    setVisible(show);
  }, [show]);

  function toggleState(force) {
    const newState = _.isNil(force) ? !visible : force;

    if (_.isFunction(onChange)) {
      onChange(newState);
    } else {
      setVisible(newState);
    }
  }

  function handleKeyPress(event) {
    if (_.includes(TOGGLE_KEYS, event.key)) {
      event.stopPropagation();
      toggleState();
    }

    if (_.includes(TOGGLE_ARROWS, event.key)) {
      toggleState(event.key !== TOGGLE_ARROWS[0]);
    }
  }

  return (
    <>
      {!_.isEmpty(title) && (
        <Styled.Header tabIndex="0" onClick={() => toggleState()} onKeyDown={handleKeyPress}>
          {visible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          <Styled.Title>{title}</Styled.Title>
        </Styled.Header>
      )}
      {visible && <Styled.Container>{children}</Styled.Container>}
    </>
  );
}

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string
};

export default Accordion;
