import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import {ThemeProvider, useTheme} from '@material-ui/core/styles';

import CollectorPreview from '../../components/Collectors/Drawer/CollectorPreview';
import HeadCharts from '../../components/Charts/HeadCharts/HeadCharts';
import ReplayTask from '../../components/ReplayTask';
import Table from '../../components/Collectors/Table/Table';

import appConsts from '../../consts';
import collectorUtils from './collectorUtils';
import consts from '../../consts';
import hooks from '../../hooks';
import models from '../../models';
import services from '../../services';
import useActions from './actions/useActions';
import useCollectorsView from './useCollectorsView';
import withTenants from '../../withTenants.hoc';

import * as Styled from '../../components/Collectors/Table/Table.styles';

function CollectorsView({tenants, tenantId}) {
  useCollectorsView(tenants);
  const [tableApi, setTableApi] = React.useState();
  const [selectedCollectors, setSelectedCollectors] = React.useState([]);
  const [collectorPreview, setCollectorPreview] = React.useState(null);
  const [actionDialogs, getContextMenuItems] = useActions(selectedCollectors, tableApi);
  const [taskId] = hooks.useSearchQueryFilter('taskId', appConsts.FILTER_OPERATORS.EQUAL);
  const theme = useTheme();

  const isSingleTenantView = !_.isEmpty(tenantId);
  const isCollectorPreviewOpen = collectorPreview?.collectorId != null;
  theme.overrides = {...theme.overrides, ...Styled.CollectorTableTheme.overrides};

  const getRows = React.useCallback(async (params) => {
    return collectorUtils.getRows(params, tenantId);
  }, []);

  return (
    <div>
      {!isSingleTenantView && (
        <HeadCharts
          countApi={services.collectors.countCollectors}
          bars={[
            {field: models.collector.fields.TASK_ACTIVITY_STATUS, title: 'TASK_STATUS', translate: true},
            {field: models.collector.fields.APPLICATION_VERSION, title: 'APPLICATION_VERSION'},
            {field: models.collector.fields.FIRMWARE_VERSION_ID, title: 'FIRMWARE_VERSION'},
            {field: models.collector.fields.STAGE, title: 'STAGE', translate: true}
          ]}
        />
      )}
      <Styled.ActionContainer>
        {taskId && (
          <ReplayTask
            taskId={taskId}
            hostIds={_.map(selectedCollectors, (collector) => _.toInteger(collector.collectorId))}
            apiPrefix={consts.API_PREFIX_BY_SERVICE_NAME.collectors}
            tasksPage={'collectors.tasks'}
          />
        )}
      </Styled.ActionContainer>
      <Paper>
        <ThemeProvider theme={theme}>
          <Styled.Drawer open={isCollectorPreviewOpen} onClose={() => setCollectorPreview(null)}>
            <CollectorPreview
              collectorId={collectorPreview?.collectorId}
              collectorNumber={collectorPreview?.collectorNumber}
              tenantId={collectorPreview?.tenantId}
              tenantName={collectorPreview?.tenantName}
            />
          </Styled.Drawer>
          <Styled.Container>
            <Table
              getContextMenuItems={getContextMenuItems}
              getRows={getRows}
              isSingleTenantView={isSingleTenantView}
              selectedCollectors={selectedCollectors}
              setCollectorPreview={setCollectorPreview}
              setSelectedCollectors={setSelectedCollectors}
              setTableApi={setTableApi}
              tableApi={tableApi}
              taskId={taskId}
            />
            {actionDialogs}
          </Styled.Container>
        </ThemeProvider>
      </Paper>
    </div>
  );
}

CollectorsView.propTypes = {
  tenantId: PropTypes.string,
  tenants: PropTypes.array
};

export default withTenants(CollectorsView);
