import styled from 'styled-components';

import MoreVertIcon from '@material-ui/icons/MoreVert';

export const Container = styled.div`
  display: flex;
`;

export const MenuIcon = styled(MoreVertIcon)`
  font-size: 20px;
  cursor: pointer;
`;

export const menuPaperProps = {
  style: {
    width: '22ch'
  }
};
