import PropTypes from 'prop-types';
import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import IconedLabel from './IconedLabel';

function TooltipMenuItem({dialog, disabled, icon, onClick, name, tooltip}) {
  const menuItem = (
    <MenuItem disabled={disabled} onClick={onClick}>
      <IconedLabel icon={icon}>{name}</IconedLabel>
    </MenuItem>
  );

  const wrappedMenuItem = tooltip ? (
    <Tooltip title={tooltip}>
      <div>{menuItem}</div>
    </Tooltip>
  ) : (
    menuItem
  );

  return (
    <>
      {wrappedMenuItem}
      {dialog}
    </>
  );
}

TooltipMenuItem.propTypes = {
  dialog: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  tooltip: PropTypes.string
};

export default TooltipMenuItem;
