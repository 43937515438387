import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';

import {Form as BaseForm} from '../../../components/Base';

import * as theme from '../../../theme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Form = styled.form`
  width: 60%;
`;

export const Description = styled(Typography)`
  color: ${theme.text.secondary};
  font-size: 1em;
  white-space: pre-line;
`;

export const Error = styled(BaseForm.Error)`
  white-space: pre-line;
`;
