import styled from 'styled-components';

import {Select as MuiSelect} from '@material-ui/core';

import * as theme from '../../theme';

export const Select = styled(MuiSelect)`
  background-color: ${theme.backgrounds.primary};
  padding: 0 5px;
`;
