import styled from 'styled-components';

import EventOutlinedIcon from '@material-ui/icons/EventOutlined';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 7px;
`;

export const DateIcon = styled(EventOutlinedIcon)`
  font-size: 20px;
`;

export const Date = styled.span`
  font-size: 12px;
`;
