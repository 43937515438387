import styled from 'styled-components';

import InputBase from '@material-ui/core/InputBase';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiDivider from '@material-ui/core/Divider';

export const Input = styled(InputBase)`
  flex: 1;
  margin-left: 6px;
`;

export const IconButton = styled(MuiIconButton)`
  padding: 10px;
`;

export const Divider = styled(MuiDivider).attrs({orientation: 'vertical'})`
  height: 28px;
  margin: 4px;
`;
