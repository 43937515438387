import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../../context';
import langs from '../../../localization/langs';
import services from '../../../services';
import task from '../../../models/task';

import CommandOutput from '../../CommandOutput/CommandOutput';
import TenantTaskSection from '../Parts/TenantTaskSection';

import * as Styled from './TasksHistory.styles';

function TasksHistory({tenantId, tenantName}) {
  const [tenantTasks, setTenantTasks] = React.useState([]);
  const [selectedCommandStatus, setSelectedCommandStatus] = React.useState(null);

  const notifications = context.Notifications.useNotifications();

  React.useEffect(() => {
    services.tenants
      .getCommandStatusByTenant(tenantId)
      .then(({data: {items}}) => setTenantTasks(items))
      .catch((error) => {
        console.error(error);
        notifications.error(langs('ERROR_FETCHING_TASK_OUTPUT'));
        setTenantTasks([]);
      });
  }, [tenantId]);

  const tasksInProgress = _.filter(tenantTasks, (item) => !task.completedTaskStatuses.includes(item.status));
  const tasksCompleted = _.filter(tenantTasks, (item) => task.completedTaskStatuses.includes(item.status));
  return (
    <div>
      <Styled.Dialog onClose={() => setSelectedCommandStatus(null)} open={Boolean(selectedCommandStatus)}>
        <CommandOutput
          title={`${langs('COMMAND_OUTPUT')} - ${tenantName}`}
          fetchOutput={() => services.tenants.getTaskOutput(tenantId, selectedCommandStatus.taskId)}
        />
      </Styled.Dialog>
      <div>
        {!_.isEmpty(tasksInProgress) && (
          <TenantTaskSection
            tenantTasks={tasksInProgress}
            setSelectedCommandStatus={setSelectedCommandStatus}
            statusLabel={langs('IN_PROGRESS')}
          />
        )}
        {!_.isEmpty(tasksCompleted) && (
          <TenantTaskSection
            tenantTasks={tasksCompleted}
            setSelectedCommandStatus={setSelectedCommandStatus}
            statusLabel={langs('COMPLETED_RECENTLY')}
          />
        )}
      </div>
    </div>
  );
}

TasksHistory.propTypes = {
  tenantId: PropTypes.string.isRequired,
  tenantName: PropTypes.string.isRequired
};

export default TasksHistory;
