import React from 'react';

import * as Styled from '../TenantsFlow.styles';

function Legend() {
  return (
    <Styled.Cytoscape
      style={{height: '160px', width: '100%'}}
      layout={{
        name: 'grid'
      }}
      cy={(cy) => {
        cy.fit();
      }}
      elements={[
        {id: 'Integration', type: 'integration'},
        {id: 'RabbitMQ_Channel', type: 'rmq_channel'},
        {id: 'ST Pipeline', type: 'pipeline'},
        {label: 'ST Topic', type: 'st_topic'},
        {id: 'Lagging_ST_Topic', isLagging: 'true', label: 'Lagged_ST_Topic', type: 'st_topic'},
        {id: 'Lagging_MT_Topic', isLagging: 'true', label: 'Lagged_MT_Topic', type: 'mt_topic'},
        {id: 'MT Topic', type: 'mt_topic'},
        {id: 'Micro Service', type: 'service'},
        {id: 'Secor Service', type: 'secor'}
      ].map((node, i) => ({
        data: {
          id: node.id || i,
          label: node.label || node.type,
          ...node
        },
        position: {
          x: i * 110,
          y: 50
        }
      }))}
    />
  );
}

export default Legend;
