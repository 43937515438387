import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as Styled from '../Container.styles';

function MultipleChoiceInput({disabled = [], onChange, options, value, render}) {
  const disabledSet = new Set(disabled);
  const valuesSet = new Set(value);

  function onCheck(option, checked) {
    const newValue = checked ? _.concat(value, option) : _.without(value, option);
    onChange(newValue);
  }

  function getLabel(option) {
    if (_.isFunction(render)) {
      return render(option);
    }
    return option;
  }

  return (
    <Styled.FlexColumnsContainer>
      {options.map((option) => (
        <FormControlLabel
          label={getLabel(option)}
          key={option}
          control={
            <Checkbox
              checked={valuesSet.has(option)}
              disabled={disabledSet.has(option)}
              onChange={({target: {checked}}) => onCheck(option, checked)}
            />
          }
        />
      ))}
    </Styled.FlexColumnsContainer>
  );
}

MultipleChoiceInput.propTypes = {
  disabled: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  render: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default MultipleChoiceInput;
