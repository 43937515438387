import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import hooks from '../../../../hooks';
import langs from '../../../../localization/langs';
import services from '../../../../services';

import {CheckListSelection, Form, Paper} from '../../../../components/Base';
import TextSearchInput from '../../../../components/TextSearchInput';

const EXISTING_NAMESPACE = 'existing_namespace';
const NAMESPACES_SEARCH_QUERY = 'environmentName=dev1';
const NEW_NAMESPACE = 'new_namespace';

function MultiTenantCreate({namespace, setNamespace, selectedStacks, setSelectedStacks}) {
  const [isNewNamespace, setIsNewNamespace] = React.useState(false);

  const [namespacesResponse, isLoadingNamespaces] = hooks.useGetResult(
    () => services.services.namespaces.list({length: -1, searchQuery: NAMESPACES_SEARCH_QUERY}),
    []
  );
  const [stacksResponse] = hooks.useGetResult(() => services.services.stacks.list({length: -1}), []);
  const [mainStacksResponse] = hooks.useGetResult(
    () => services.services.deployments.count('groupBy=stackName&namespaceName%5Beq%5D=main'),
    []
  );

  const namespaces = _.sortBy(namespacesResponse?.items, 'name');
  const allStacks = _.sortBy(stacksResponse?.items, 'name');
  const mainStacks = _.sortBy(
    _.map(mainStacksResponse?.items, ({key, value}) => ({
      id: _.toString(_.find(allStacks, {name: _.head(key)})?.id),
      name: _.head(key)
    })),
    'tenantName'
  );
  const stacks = _.uniqBy(
    _.union(
      _.filter(allStacks, ({id, name}) => {
        return _.includes(name, '-dev');
      }),
      mainStacks
    ),
    'id'
  );

  React.useEffect(() => {
    if (!_.isEmpty(namespaces)) {
      setNamespace(_.find(namespaces, {name: 'main'}));
    }
  }, [namespacesResponse]);

  function onChangeRadio(event) {
    if (event.target.value === EXISTING_NAMESPACE) {
      setSelectedStacks([]);
      setNamespace(_.find(namespaces, {name: 'main'}));
    } else if (event.target.value === NEW_NAMESPACE) {
      setSelectedStacks(mainStacks);
      setNamespace(null);
    }
    setIsNewNamespace(!isNewNamespace);
  }

  return (
    <Paper padding={2} style={{paddingBlock: '1em'}}>
      <Form.Label>{langs('TENANT_MULTI_TENANT')}</Form.Label>
      <Form.Field>
        <RadioGroup defaultValue={EXISTING_NAMESPACE} onChange={onChangeRadio}>
          <FormControlLabel value={EXISTING_NAMESPACE} control={<Radio />} label={langs('USE_EXISTING_MT_NAMESPACE')} />
          <TextSearchInput
            loading={isLoadingNamespaces}
            multiple={false}
            options={namespaces || []}
            value={namespace}
            disabled={isNewNamespace}
            inputPlaceholder={langs('CHOOSE_NAMESPACE')}
            setValue={setNamespace}
          />
          <FormControlLabel value={NEW_NAMESPACE} control={<Radio />} label={langs('USE_NEW_MT_NAMESPACE')} />
        </RadioGroup>
        {isNewNamespace && (
          <CheckListSelection
            checkboxLabel={langs('CONTINUOUS_DELIVERY')}
            selectedItems={selectedStacks}
            items={stacks}
            onChange={setSelectedStacks}
          />
        )}
      </Form.Field>
    </Paper>
  );
}

MultiTenantCreate.propTypes = {
  namespace: PropTypes.object,
  selectedStacks: PropTypes.array.isRequired,
  setNamespace: PropTypes.func.isRequired,
  setSelectedStacks: PropTypes.func.isRequired
};

export default MultiTenantCreate;
