import _ from 'lodash';
import React from 'react';

import services from '../../../../services';

function useTaskCommands(apiPrefix, task, sectionGetter) {
  const [search, setSearch] = React.useState('');
  const [commands, setCommands] = React.useState([]);

  React.useEffect(() => {
    if (!task) {
      setSearch('');
    }
  }, [task]);

  React.useEffect(
    function fetchCommands() {
      if (!task) {
        return;
      }

      services.tasks.listCommandStatus(task.id, apiPrefix).then(({data}) =>
        setCommands(
          _(data.items)
            .map((command) => ({
              ...command,
              section: sectionGetter(command)
            }))
            .orderBy(['tenantId', 'collectorNumber'])
            .value()
        )
      );
    },
    [apiPrefix, task]
  );

  const commandsBySections = _(commands)
    .filter((command) => {
      return _.find(
        command,
        (commandVal) => _.isString(commandVal) && _.includes(_.toLower(commandVal), _.toLower(search))
      );
    })
    .groupBy('section')
    .value();
  return {commandsBySections, search, setSearch};
}

export default useTaskCommands;
