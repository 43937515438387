import _ from 'lodash';
import React from 'react';

export default function useLocalStorage(
  storageKey,
  defaultValue,
  setFunc = _.identity,
  getFunc = _.identity,
  session = false
) {
  let storage = session ? sessionStorage : localStorage;
  let savedValue = storage.getItem(storageKey);
  let defaultState = savedValue ? getFunc(savedValue) : defaultValue;
  let [value, setValue] = React.useState(defaultState);

  function saveValue(newValue) {
    storage.setItem(storageKey, setFunc(newValue));
    setValue(newValue);
  }

  return [value, saveValue];
}

export function useBoolLocalStorage(storageKey, defaultValue, session = false) {
  return useLocalStorage(storageKey, defaultValue, undefined, (value) => value === 'true', session);
}
