import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';

import consts from '../../../consts';
import context from '../../../context';
import hooks from '../../../hooks';
import langs from '../../../localization/langs';
import models from '../../../models';
import services from '../../../services';

import {Button, Form} from '../../../components/Base';
import CreateVersion from './CreateVersion';
import DeploymentTypeSelector from './parts/DeploymentTypeSelector';
import MajorTargetsSelector from './parts/MajorTargetsSelector';
import PullRequestsSelector from './parts/PullRequestsSelector';
import ReasonInput from './parts/ReasonInput';
import UrgencySelector from './parts/UrgencySelector';
import StepBottomBar from './parts/StepBottomBar';

import * as Styled from './ReleaseRequestForm.styles';
import * as StyledCommon from './parts/Common.styles';

const MANUAL_CP_INSTRUCTIONS_URL =
  'https://armis-security.atlassian.net/wiki/spaces/ARMIS/pages/3005579265/Manual+cherry-pick+to+release';

function ConflictsError() {
  return (
    <span>
      <br />
      {langs('RELEASE_FORM_SOLVE_CONFLICTS')}:
      <StyledCommon.Link href={MANUAL_CP_INSTRUCTIONS_URL}>
        <br />
        {MANUAL_CP_INSTRUCTIONS_URL}
      </StyledCommon.Link>
    </span>
  );
}

export function ReleaseRequestForm({onComplete}) {
  const [error, setError] = React.useState();

  const [urgency, setUrgency] = React.useState(models.releases.urgencyTypes.UNKNOWN);
  const [reason, setReason] = React.useState('');

  const [pullRequestsSelection, setPullRequestsSelection] = React.useState([]);
  const [majorTargetsSelection, setMajorTargetsSelection] = React.useState([]);
  const [ReleaseCandidateTargetsSelection, setReleaseCandidateTargetsSelection] = React.useState([]);

  const [servicesSelection, setServicesSelection] = React.useState([]);
  const [tenantsSelection, setTenantsSelection] = React.useState([]);
  const [containersSelection, setContainersSelection] = React.useState([]);

  const [deploymentTypesSelection, setDeploymentTypesSelection] = React.useState(null);
  const [isSquadDeployment, setIsSquadDeployment] = React.useState(false);

  const [isSubmitValid, setIsSubmitValid] = React.useState(false);
  const [isDeploymentsValid, setIsDeploymentsValid] = React.useState(false);

  const [rcVersion, setRcVersion] = React.useState('');

  const [activeStep, setActiveStep] = React.useState(0);

  const [requestType] = hooks.useSearchQueryFilter('requestType', consts.FILTER_OPERATORS.EQUAL);

  const notifications = context.Notifications.useNotifications();

  const releases = [...majorTargetsSelection, ...ReleaseCandidateTargetsSelection];

  const minorRequestSteps = [
    {
      content: <UrgencySelector setUrgency={setUrgency} urgency={urgency} />,
      description: langs('RELEASE_FORM_URGENCY_DESCRIPTION'),
      label: langs('RELEASE_FORM_URGENCY_LABEL'),
      valid: !_.isEmpty(urgency)
    },
    {
      content: (
        <PullRequestsSelector
          setPullRequestsSelection={setPullRequestsSelection}
          pullRequestsSelection={pullRequestsSelection}
        />
      ),
      description: langs('RELEASE_FORM_PULL_REQUESTS_DESCRIPTION'),
      label: langs('RELEASE_FORM_PULL_REQUESTS_LABEL'),
      valid: !_.isEmpty(pullRequestsSelection) && validatePrsJiraTickets(pullRequestsSelection)
    },
    {
      content: (
        <>
          <MajorTargetsSelector
            setMajorTargetsSelection={setReleaseCandidateTargetsSelection}
            majorTargetsSelection={ReleaseCandidateTargetsSelection}
            branchType="RELEASE_CANDIDATE"
            displayUsage={false}
            label={langs('RELEASE_CANDIDATES')}
          />
          <MajorTargetsSelector
            setMajorTargetsSelection={setMajorTargetsSelection}
            majorTargetsSelection={majorTargetsSelection}
            branchType="RELEASE"
            displayUsage
            label={langs('MAJOR_RELEASES')}
          />
        </>
      ),
      description: langs('RELEASE_FORM_MAJOR_TARGETS_DESCRIPTION'),
      label: langs('RELEASE_FORM_MAJOR_TARGETS_LABEL'),
      valid: !_.isEmpty(releases)
    },
    {
      content: <ReasonInput setReason={setReason} reason={reason} />,
      description: langs('RELEASE_FORM_REASON_DESCRIPTION'),
      label: langs('RELEASE_FORM_REASON_LABEL'),
      valid: !_.isEmpty(reason)
    },
    {
      content: (
        <DeploymentTypeSelector
          containersSelection={containersSelection}
          servicesSelection={servicesSelection}
          setContainersSelection={setContainersSelection}
          setServicesSelection={setServicesSelection}
          setTenantsSelection={setTenantsSelection}
          tenantsSelection={tenantsSelection}
          setIsDeploymentsValid={setIsDeploymentsValid}
          deploymentTypesSelection={deploymentTypesSelection}
          setDeploymentTypesSelection={setDeploymentTypesSelection}
          isSquadDeployment={isSquadDeployment}
          setIsSquadDeployment={setIsSquadDeployment}
        />
      ),
      description: langs('RELEASE_FORM_DEPLOYMENTS_DESCRIPTION'),
      label: langs('RELEASE_FORM_DEPLOYMENTS_LABEL'),
      valid: isDeploymentsValid
    }
  ];

  const isRCValid = !_.isEmpty(rcVersion);
  const isMinorRequestValid = _.every(minorRequestSteps, 'valid');

  React.useEffect(() => {
    setIsSubmitValid(
      (requestType === models.releases.requestTypes.MAJOR && isRCValid) ||
        (requestType === models.releases.requestTypes.MINOR && isMinorRequestValid) ||
        (requestType === models.releases.requestTypes.RELEASE_CANDIDATE && isRCValid)
    );
  }, [minorRequestSteps, isRCValid, isMinorRequestValid]);

  function handleStep(step) {
    return () => setActiveStep(step);
  }

  function validatePrsJiraTickets(prs) {
    return _.every(prs, (pr) => !_.isNil(pr.jiraTicket));
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await services.releases.create({
        deploymentParams: {
          containerNames: _.map(containersSelection, 'name'),
          deploymentIds: _.map(servicesSelection, 'id'),
          squad: isSquadDeployment,
          tenantIds: _.map(tenantsSelection, 'id')
        },
        deploymentTypes: _.map(_.filter(deploymentTypesSelection, 'value'), 'type'),
        jiraTicket: _.map(pullRequestsSelection, 'jiraTicket').join(', '),
        prIds: _.map(pullRequestsSelection, 'id'),
        reason,
        releases: _.isEmpty(releases) ? [rcVersion] : releases,
        requestType,
        urgency
      });
      await onComplete();
    } catch (error) {
      console.error(error);
      setError(error.response);
      notifications.error(error.response.data);
    }
  }

  return (
    <Styled.Container>
      <Styled.Form>
        {requestType === models.releases.requestTypes.MINOR ? (
          <Stepper activeStep={activeStep} orientation="vertical">
            {minorRequestSteps.map((step, index) => (
              <Step key={step.label}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {step.label}
                </StepButton>
                <StepContent>
                  <Styled.Description>{step.description}</Styled.Description>
                  {step.content}
                  <StepBottomBar
                    disable={index === minorRequestSteps.length - 1 ? !isSubmitValid : !step.valid}
                    index={index}
                    setActiveStep={setActiveStep}
                    handleSubmit={handleSubmit}
                    size={minorRequestSteps.length}
                  />
                </StepContent>
              </Step>
            ))}
          </Stepper>
        ) : (
          <div>
            <CreateVersion requestType={requestType} setBranch={setRcVersion} branch={rcVersion} />
            <Form.BottomBar>
              <Button disabled={!isSubmitValid} type="submit" onClick={handleSubmit}>
                {langs('BUTTON_CREATE')}
              </Button>
            </Form.BottomBar>
          </div>
        )}
        {error && (
          <Styled.Error>
            {`${langs('ERROR')} ${error.data}`}
            {error.status === consts.HTTP_STATUS_CODES.CONFLICT && <ConflictsError />}
          </Styled.Error>
        )}
      </Styled.Form>
    </Styled.Container>
  );
}

ReleaseRequestForm.propTypes = {
  onComplete: PropTypes.func.isRequired
};

export default ReleaseRequestForm;
