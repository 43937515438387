import _ from 'lodash';

const DEFAULT_LANG = 'en';

class Translator {
  constructor(externalDictionary, language = DEFAULT_LANG) {
    this.dictionary = _.defaultsDeep({}, externalDictionary);
    this.setLanguage(language);
  }

  setLanguage(language) {
    this.language = language;
  }

  translate(key, templateValues, fallback, language) {
    if (!key) {
      return '';
    }
    language = language || this.language;
    let value = _.get(this.dictionary, [language, key]);

    if (!_.isString(value)) {
      if (language === DEFAULT_LANG) {
        return fallback || `!${key}!`;
      }
      return this.translate(key, templateValues, fallback, DEFAULT_LANG);
    }

    if (value.indexOf('<%') > -1) {
      return value.replace(/<%=(.+?)%>/g, (match, templateKey) => templateValues[templateKey]);
    }

    return value;
  }
}

export default Translator;
