import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../../context';
import langs from '../../../localization/langs';
import services from '../../../services';

import TextSearchInput from '../../TextSearchInput';

function TenantInput({onChange, disabled}) {
  const [tenants, setTenants] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [search, setSearch] = React.useState('');

  const notifications = context.Notifications.useNotifications();

  async function fetchTenants(tenantSearch) {
    try {
      setTenants([]);
      setIsLoading(true);
      const {data} = await services.tenants.getByQuery({
        searchQuery: encodeURI(`environmentName[ne]=prod4&tenantName[like]=%${tenantSearch}%`)
      });
      setTenants(_.map(data.items, ({tenantId, tenantName}) => ({id: tenantId, name: tenantName})));
    } catch (e) {
      notifications.error(langs('FAILED_GET_TENANTS'));
      console.error(langs('FAILED_GET_TENANTS'), e);
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if (_.isEmpty(search)) {
      fetchTenants(search);
      return;
    }
    fetchTenants(search);
  }, [search]);

  function onInputChange(event, newValue) {
    setSearch(newValue);
  }

  function onSelectTenant(tenant) {
    onChange(tenant ? tenant.name : '');
  }

  return (
    <TextSearchInput
      disabled={disabled}
      inputValue={search}
      loading={isLoading}
      onInputChange={onInputChange}
      onOpen={() => fetchTenants(search)}
      options={tenants}
      setValue={onSelectTenant}
    />
  );
}

TenantInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default TenantInput;
