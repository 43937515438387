import _ from 'lodash';

import client from '../client';

export async function getCherryPicks({length, from, orderBy, searchQuery = ''}) {
  const fromQuery = from ? `&from=${from}` : '';
  const exclude = `&branch_name[lt]=RC-23.0-S167`;
  const orderByQuery = orderBy ? `&orderBy=${orderBy}` : '';
  const searchByQuery = _.isEmpty(searchQuery) ? '' : `&${searchQuery}`;

  return client.get(
    `/slackbot/api/v1/cherry_pick?length=${length}${fromQuery}${exclude}${orderByQuery}${searchByQuery}`
  );
}

export default {
  getCherryPicks
};
