import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {Checkbox, FormControlLabel, FormGroup, Tooltip} from '@material-ui/core';

import consts from '../../../../consts';
import context from '../../../../context';
import hooks from '../../../../hooks';
import icons from '../../../../components/Tasks/icons';
import langs from '../../../../localization/langs';
import services from '../../../../services';

import {Form, Paper} from '../../../../components/Base';
import TextSearchInput from '../../../../components/TextSearchInput';

import * as Styled from './PullRequestsSelector.styles';

const INPUT_REASON_TYPES = {
  CLEAR: 'clear',
  RESET: 'reset'
};
const PULL_REQUESTS_FIELDS = {
  ID: 'id',
  IS_VALID: 'isValid',
  OLD_ID: 'prId'
};

function PullRequestSelector({pullRequestsSelection, setPullRequestsSelection}) {
  const [pullRequests, setPullRequests] = React.useState([]);
  const [isLoadingPullRequests, setIsLoadingPullRequests] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const selectedPrsIds = React.useRef(_.map(pullRequestsSelection, PULL_REQUESTS_FIELDS.ID));

  const notifications = context.Notifications.useNotifications();

  const fetchPullRequests = React.useCallback(async (query) => {
    try {
      setPullRequests([]);
      setIsLoadingPullRequests(true);
      const {data} = await services.releases.listPullRequests(query);
      setPullRequests(
        _.map(data.items, (pr) =>
          _.mapKeys(pr, (value, key) => (key === PULL_REQUESTS_FIELDS.OLD_ID ? PULL_REQUESTS_FIELDS.ID : key))
        )
      );
    } catch (e) {
      notifications.error(langs('FAILED_GET_PULL_REQUESTS'));
      console.error(langs('FAILED_GET_PULL_REQUESTS'), e);
    } finally {
      setIsLoadingPullRequests(false);
    }
  }, []);
  const debouncedFetchPullRequests = hooks.useDebounce(fetchPullRequests);

  React.useEffect(() => {
    if (_.isEmpty(pullRequestsSelection)) {
      return;
    }
    const promises = _.map(pullRequestsSelection, (pr) => {
      if (!_.has(pr, PULL_REQUESTS_FIELDS.IS_VALID)) {
        const modifiedPr = _.assign({isValid: null}, pr);
        return getPrValidationStatus(modifiedPr);
      }
      return pr;
    });
    Promise.all(promises).then((prs) => setPullRequestsSelection(prs));
  }, [selectedPrsIds.current]);

  function onInputChange(event, newValue, reason) {
    if (reason === INPUT_REASON_TYPES.RESET) {
      return;
    }
    if (reason === INPUT_REASON_TYPES.CLEAR) {
      setPullRequestsSelection([]);
      return;
    }
    setSearch(newValue);
    debouncedFetchPullRequests(newValue);
  }

  function getPrLabel(pr) {
    const prLink = <Styled.Link href={`${consts.URL_PREFIX_GITHUB_PULL_REQUEST}${pr.id}`}>{`#${pr.id}`}</Styled.Link>;
    const ticketLink = pr.jiraTicket && (
      <Styled.Link href={`${consts.URL_PREFIX_JIRA_TICKET}${pr.jiraTicket}`}>{`(${pr.jiraTicket})`}</Styled.Link>
    );
    const validationIcon = getValidationIcon(pr);

    return (
      <div>
        {prLink} {pr.title} {ticketLink} {validationIcon}
      </div>
    );
  }

  function onRemove(item) {
    setPullRequestsSelection(_.reject(pullRequestsSelection, {id: item.id}));
  }

  async function getPrValidationStatus(pr) {
    const {
      data: {jiraTicket, isValid, errors}
    } = await services.releases.validatePullRequest(pr.id);
    return _.assign({}, pr, {errors, isValid, jiraTicket});
  }

  function updatePrState(pr) {
    setPullRequestsSelection((prevState) => _.map(prevState, (prevPr) => (prevPr.id === pr.id ? pr : prevPr)));
  }

  async function refreshPr(e, pr) {
    e.preventDefault();
    updatePrState(_.omit(_.find(pullRequestsSelection, {id: pr.id}), PULL_REQUESTS_FIELDS.IS_VALID));
    try {
      const modifiedPr = await getPrValidationStatus(pr);
      updatePrState(modifiedPr);
    } catch (e) {
      notifications.error(langs('ERROR_VALIDATE_PULL_REQUEST'));
      console.error(langs('ERROR_VALIDATE_PULL_REQUEST'), e);
    }
  }

  function getValidationIcon(pr) {
    if (!_.has(pr, PULL_REQUESTS_FIELDS.IS_VALID)) {
      return <icons.Running />;
    } else if (pr.isValid) {
      return <icons.Success />;
    }
    const errors = pr.errors && _.map(pr.errors, (error, index) => <div key={index}>{` ${error} `}</div>);
    return (
      <span>
        <Tooltip title={errors || ''} placement="right">
          <span>
            <icons.Failed />
          </span>
        </Tooltip>
        <Styled.RefreshIcon onClick={(e) => refreshPr(e, pr)} />
      </span>
    );
  }

  function setValue(prs) {
    selectedPrsIds.current = _.map(prs, PULL_REQUESTS_FIELDS.ID);
    setPullRequestsSelection(prs);
  }

  return (
    <Paper style={{paddingBlock: '1em'}}>
      <Form.Field>
        <TextSearchInput
          loading={isLoadingPullRequests}
          multiple
          options={pullRequests || []}
          value={pullRequestsSelection}
          onInputChange={onInputChange}
          inputValue={search}
          label={langs('TITLE')}
          setValue={setValue}
        />
        <FormGroup>
          {_.map(pullRequestsSelection, (pr) => (
            <div key={pr.id}>
              <FormControlLabel control={<Checkbox checked onChange={() => onRemove(pr)} />} label={getPrLabel(pr)} />
            </div>
          ))}
        </FormGroup>
      </Form.Field>
    </Paper>
  );
}

PullRequestSelector.propTypes = {
  pullRequestsSelection: PropTypes.array,
  setPullRequestsSelection: PropTypes.func.isRequired
};

export default PullRequestSelector;
