import PropTypes from 'prop-types';
import React from 'react';

import CollectorChip from '../../../../Base/CollectorChip';

function CollectorNumberAg({value: {collectorNumber, collectorStatus}}) {
  const collectorChipLabel = collectorNumber?.toString() ?? '';

  return <CollectorChip label={collectorChipLabel} status={collectorStatus} />;
}

CollectorNumberAg.propTypes = {
  value: PropTypes.shape({
    collectorNumber: PropTypes.string,
    collectorStatus: PropTypes.string
  })
};

export default CollectorNumberAg;
