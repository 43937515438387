import Proptypes from 'prop-types';
import React from 'react';

import context from '../context';

import {Link} from './Base';

function OpsLink({to = '', params = {}, query = {}, process = null, children, disabled}) {
  if (disabled) {
    return <>{children}</>;
  }

  const {getUrl} = context.Navigation.useNavigation();

  return <Link to={getUrl(to, params, query, process)}>{children}</Link>;
}

OpsLink.propTypes = {
  disabled: Proptypes.bool,
  params: Proptypes.shape({[Proptypes.string]: Proptypes.array}),
  process: Proptypes.func,
  query: Proptypes.object,
  to: Proptypes.string.isRequired
};

export default OpsLink;
