import _ from 'lodash';
import PropTypes from 'prop-types';

export function isAllowed(requestedPermissions, rbacControlObj) {
  if (_.isEmpty(requestedPermissions)) {
    return true;
  }

  return rbacControlObj.hasPermissions(requestedPermissions);
}

export const propTypes = {
  children: PropTypes.node.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  rbacControlObj: PropTypes.shape({
    hasPermission: PropTypes.func.isRequired,
    isEnabled: PropTypes.func.isRequired
  }).isRequired
};
