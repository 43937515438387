import PropTypes from 'prop-types';
import React from 'react';

import task from '../../../models/task';

import Status from './Status';

import icons from '../icons';
import langs from '../../../localization/langs';

import * as Styled from './Task.styles';

function ProgressCell({progress, countFailed, countRunning, countPending, taskStatus}) {
  return (
    <>
      <Styled.Title>{langs('PROGRESS_STATUS')}</Styled.Title>
      <Status count={parseFloat(progress.toFixed(0))} text={`% ${langs('COMPLETED')}`} iconComponent={icons.Success} />
      <Status count={countFailed} text={' Failed'} iconComponent={icons.Failed} />
      {!task.completedTaskStatuses.includes(taskStatus) && (
        <Status count={countRunning} text={` ${langs('RUNNING')}`} iconComponent={icons.Running} />
      )}
      <Status count={countPending} text={` ${langs('PENDING')}`} iconComponent={icons.Pending} />
    </>
  );
}

ProgressCell.propTypes = {
  countFailed: PropTypes.number,
  countPending: PropTypes.number,
  countRunning: PropTypes.number,
  progress: PropTypes.number,
  taskStatus: PropTypes.string
};

export default ProgressCell;
