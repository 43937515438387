import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import {Grid} from '@material-ui/core';

import langs from '../../../localization/langs';

import useTaskCommands from './useTaskCommads/useTaskCommands.hook';

import CommandOutput from '../../CommandOutput/CommandOutput';

import Actions from './Parts/Actions';
import AffectedEntity from './Parts/AffectedEntity';
import CommandInfo from './Parts/CommandInfo';
import TaskCommand from './Parts/TaskCommand';
import TaskDescription from './Parts/TaskDescription';

import * as Styled from './TaskCommands.styles';

function TaskCommands({
  children,
  task,
  onClose,
  fetchOutput,
  renderLabel,
  entityName,
  apiPrefix,
  tableRoute,
  sectionGetter,
  outputTitleGetter,
  ...props
}) {
  const {commandsBySections, search, setSearch} = useTaskCommands(apiPrefix, task, sectionGetter);

  const [selectedCommand, setSelectedCommand] = React.useState(null);

  if (!task) {
    return null;
  }

  return (
    <Styled.Container>
      <Dialog onClose={() => setSelectedCommand(null)} open={selectedCommand !== null} maxWidth="md" fullWidth>
        <CommandOutput
          title={`${langs('COMMAND_OUTPUT')} - ${outputTitleGetter(selectedCommand)}`}
          fetchOutput={() => fetchOutput(selectedCommand, task.id)}
          autoRefresh={selectedCommand?.commandStatusStatus === 'RUNNING'}
        />
      </Dialog>
      <Actions task={task} onClose={onClose} apiPrefix={apiPrefix} tableRoute={tableRoute} {...props} />
      <TaskDescription
        id={task.id}
        commandType={task.commandType}
        creationDate={task.creationDate}
        tableRoute={tableRoute}
      />
      <CommandInfo
        command={task.command}
        parameters={task.parametersValues ? JSON.parse(task.parametersValues) : null}
      />
      <AffectedEntity value={search} onChange={setSearch} label={entityName} />
      <Grid item xs={12}>
        <Divider />
        {_.map(commandsBySections, (commands, section) => (
          <div key={section}>
            <Styled.Section>{section}</Styled.Section>
            {commands.map((command, key) => (
              <TaskCommand
                children={children}
                key={key}
                setSelectedId={setSelectedCommand}
                command={command}
                renderLabel={renderLabel}
              />
            ))}
          </div>
        ))}
      </Grid>
    </Styled.Container>
  );
}

TaskCommands.propTypes = {
  apiPrefix: PropTypes.string.isRequired,
  children: PropTypes.node,
  entityIdGetter: PropTypes.func,
  entityName: PropTypes.string,
  fetchOutput: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  outputTitleGetter: PropTypes.func.isRequired,
  renderLabel: PropTypes.func,
  sectionGetter: PropTypes.func.isRequired,
  tableRoute: PropTypes.string.isRequired,
  task: PropTypes.object
};

export default TaskCommands;
