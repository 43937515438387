import Translator from './Translator';

import en from './dictionary/en';

const dictionary = {
  en
};

let translator = new Translator(dictionary);

export default translator.translate.bind(translator);
