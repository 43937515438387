import React from 'react';

import assets from '../../assets';
import langs from '../../localization/langs';

import * as baseComp from './protectedBase';
import * as Styled from './ProtectedView.styles';

function ProtectedView({children, permissions, rbacControlObj}) {
  const isAllowed = React.useMemo(() => baseComp.isAllowed(permissions, rbacControlObj), [permissions, rbacControlObj]);

  if (isAllowed) {
    return <>{children}</>;
  }

  const missingPermissions = rbacControlObj.getMissingPermissionsStr(permissions);
  const permissionsMessage = langs('INSUFFICIENT_PERMISSIONS', {missingPermissions});

  return (
    <Styled.Container>
      <Styled.Title>{permissionsMessage}</Styled.Title>
      <div>
        <img src={assets.UnallowedResource} alt={permissionsMessage} />
      </div>
    </Styled.Container>
  );
}

ProtectedView.propTypes = baseComp.propTypes;

export default ProtectedView;
