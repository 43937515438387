import consts from '../../../../consts';

const PERMISSIONS = consts.rbac.permissions.rbac;

function getDirectAccessors(rbacAccessors) {
  return {
    allowed: {
      schema: {
        edit: () => rbacAccessors.hasAllPermission(PERMISSIONS.schema.edit),
        export: () => rbacAccessors.hasAllPermission(PERMISSIONS.schema.export)
      }
    }
  };
}

export default {
  getDirectAccessors
};
