import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../../context';
import langs from '../../../localization/langs';

import TextSearchInput from '../../TextSearchInput';

function ArtifactInput({value, onChange, getArtifact, isLatestBuild = false, listArtifacts, disabled, useObject}) {
  const [artifacts, setArtifacts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [search, setSearch] = React.useState('');
  const [selectedArtifact, setSelectedArtifact] = React.useState({});
  const notifications = context.Notifications.useNotifications();

  async function fetchArtifacts(search) {
    try {
      setArtifacts([]);
      setIsLoading(true);
      const {data} = await listArtifacts({branchTypes: null, isLatestBuild, like: `%${search}%`});
      setArtifacts(data.items);
    } catch (e) {
      notifications.error(langs('FAILED_GET_ARTIFACTS'));
      console.error(langs('FAILED_GET_ARTIFACTS'), e);
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    const id = useObject ? value?.id : value;
    if (id) {
      getArtifact(id).then(({data}) => setSelectedArtifact(data));
    }
  }, [value]);

  React.useEffect(() => {
    if (_.isEmpty(search) || search === 'null') {
      return;
    }
    fetchArtifacts(search);
  }, [search]);

  function onInputChange(event, newValue) {
    setSearch(newValue);
  }

  function onSelectArtifact(artifact) {
    setSelectedArtifact(artifact);
    onChange(useObject ? artifact : artifact.id);
  }

  function renderOptionLabel({branch, buildId, component}) {
    if (_.isEmpty(component)) {
      return `${branch} #${buildId}`;
    }
    return `${component}/${branch} #${buildId}`;
  }

  return (
    <TextSearchInput
      disableClearable={true}
      disabled={disabled}
      getOptionLabel={renderOptionLabel}
      inputValue={search}
      loading={isLoading}
      onInputChange={onInputChange}
      onOpen={() => fetchArtifacts('%')}
      options={artifacts}
      setValue={onSelectArtifact}
      value={selectedArtifact}
    />
  );
}

ArtifactInput.propTypes = {
  disabled: PropTypes.bool,
  getArtifact: PropTypes.func.isRequired,
  isLatestBuild: PropTypes.bool,
  listArtifacts: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  useObject: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
};

export default ArtifactInput;
