import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import langs from '../../../localization/langs';

import * as Styled from './AffectedEntities.styles';

const SAMPLE = 10;

function getWarningText(services) {
  const count = _.size(services);

  if (_.isNull(services)) {
    return langs('DEPLOYMENTS_ACTION_DIALOG_WARNING_TOO_MANY');
  }
  if (count === 1) {
    return langs('DEPLOYMENTS_ACTION_DIALOG_WARNING_ONE');
  }
  if (count > SAMPLE) {
    return langs('DEPLOYMENTS_ACTION_DIALOG_WARNING_TOP', {count: count.toLocaleString()});
  }
  return langs('DEPLOYMENTS_ACTION_DIALOG_WARNING_ALL', {count: count.toLocaleString()});
}

function AffectedEntities({value}) {
  return (
    <DialogContent>
      <DialogContentText>{getWarningText(value)}</DialogContentText>
      <ul>
        {_.map(_.take(value, SAMPLE), (entity, index) => (
          <li key={index}>
            <Styled.EntityHierarchy>
              {_.map(_.toArray(entity), (value, index) => {
                if (index === _.size(_.toArray(entity)) - 1) {
                  return value;
                }
                return `${value} → `;
              })}
            </Styled.EntityHierarchy>
          </li>
        ))}
      </ul>
    </DialogContent>
  );
}

AffectedEntities.propTypes = {
  value: PropTypes.array
};

export default AffectedEntities;
