import PropTypes from 'prop-types';
import React from 'react';

import Grid from '@material-ui/core/Grid';

import ConfigurationPreview from './ConfigurationPreview';

import * as Styled from './ConfigurationFooter.styles';

function ConfigurationFooter({value}) {
  return (
    <Styled.Container container justifyContent="space-between">
      <Grid item>
        <ConfigurationPreview value={value} />
      </Grid>
    </Styled.Container>
  );
}

ConfigurationFooter.propTypes = {
  value: PropTypes.array.isRequired
};

export default ConfigurationFooter;
