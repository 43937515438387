import PropTypes from 'prop-types';
import React from 'react';

import context from '../../context';

function Visible({children, permissionFunc, role}) {
  const user = context.User.useUser();

  const allowed = permissionFunc ? permissionFunc() : user.hasRole(role);
  return <>{allowed && children}</>;
}

Visible.propTypes = {
  children: PropTypes.node.isRequired,
  permissionFunc: PropTypes.func,
  role: PropTypes.string.isRequired
};

export default Visible;
