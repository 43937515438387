import _ from 'lodash';
import React from 'react';

import consts from '../../consts';
import hooks from '../../hooks';

import * as SearchUtils from './utils';

export const SearchContext = React.createContext(null);

export const useSearch = () => React.useContext(SearchContext);

export const SearchContextProvider = ({children}) => {
  const [isSearchDisabled, setIsSearchDisabled] = React.useState(true);
  const [searchOptions, setSearchOptions] = React.useState([]);

  const onTextSearch = React.useRef(null);
  const [urlSearchParams, setUrlQuery] = hooks.useUrlSearchParams();

  const queryDescription = React.useMemo(() => SearchUtils.describe(searchOptions, urlSearchParams), [
    searchOptions,
    urlSearchParams
  ]);

  function setOnTextSearch(func) {
    if (_.isFunction(func)) {
      onTextSearch.current = func;
      setIsSearchDisabled(false);
    } else {
      setIsSearchDisabled(true);
    }
  }

  function resetSearchOptions(initialOptions = []) {
    setSearchOptions(
      _.map(initialOptions, (option) => ({
        ...option,
        filterOperator: _.get(option, 'supportedOperators[0]', consts.FILTER_OPERATORS.EQUAL)
      }))
    );
  }

  function setFilterOperator(key, currentOperator, previousOperator) {
    const searchOption = _.find(searchOptions, {key});
    if (_.isEmpty(searchOption?.supportedOperators) && !searchOption?.useNullComparison) {
      return;
    }

    const oldSearchValues = urlSearchParams.get(`${key}${previousOperator}`);

    if (!_.isEmpty(oldSearchValues)) {
      const emptyOldSearch = oldSearchValues.split(',').map(() => [`${key}${previousOperator}`, null]);
      const applyNextOperator = [[`${key}${currentOperator}`, oldSearchValues]];

      setUrlQuery([...emptyOldSearch, ...applyNextOperator]);
    }

    setSearchOptions(
      _.map(searchOptions, (searchOption) => {
        if (searchOption.key === key) {
          return {
            ...searchOption,
            filterOperator: currentOperator
          };
        }

        return searchOption;
      })
    );
  }

  const context = {
    isSearchDisabled,
    onTextSearch,
    queryDescription,
    resetSearchOptions,
    searchOptions,
    setFilterOperator,
    setOnTextSearch,
    setUrlQuery,
    urlSearchParams
  };

  return <SearchContext.Provider value={context}>{children}</SearchContext.Provider>;
};
