import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import langs from '../../localization/langs';
import utils from './utils';

import ConfigurationActions from './ConfigurationActions';
import ConfigurationFilters from './ConfigurationFilters';
import ConfigurationFooter from './ConfigurationFooter';
import ConfigurationTable from './ConfigurationTable/ConfigurationTable';

import * as Styled from './ConfigurationForm.styles';

function getDefaultFilters(displaySettings) {
  return {showEmptyValues: !displaySettings.showEmptyFilter, showInheritedValues: true, text: ''};
}

function ConfigurationForm({
  categories,
  configuration,
  displaySettings,
  extendedTypes,
  getAffectedEntities,
  getConfigurationKeys,
  isLoading,
  levels,
  onAddConfigurationKey,
  onChangeCategory,
  onChangeShowTemplatedValues,
  onDeleteConfigurationKeyValue,
  onGetConfigurationKeyVersions,
  onSaveConfiguration,
  permissionFuncs,
  selectedCategory,
  selectedValues,
  showTemplatedValues
}) {
  const [changes, setChanges] = React.useState({});
  const [filters, setFilters] = React.useState(getDefaultFilters(displaySettings));
  const [operations, setOperations] = React.useState([]);

  const keyedSelectedValues = utils.getKeyedSelectedValues(selectedValues);
  const selectedValuesIds = _(selectedValues)
    .map((selectedValue) => _.get(selectedValue, 'value.id'))
    .reject(_.isEmpty)
    .value();

  React.useEffect(
    function resetChangesAndOperations() {
      setChanges({});
      setOperations([]);
    },
    [JSON.stringify(selectedValuesIds), configuration]
  );

  React.useEffect(() => {
    const newOperations = _.map(changes, (operation, name) => {
      const {key, category} = utils.parseChangeKey(name);
      const remoteValue = _.find(configuration, {key, keySubCategory: category});
      if (_.isNil(remoteValue)) {
        return;
      }
      return utils.makeOperation(levels, operation, key, remoteValue, keyedSelectedValues);
    });
    setOperations(newOperations);
  }, [changes]);

  function onKeyChange(key, category, value) {
    const changeKey = utils.createChangeKey(key, category);

    const newInheritance = _.get(value, 'inheritanceLevel');
    const oldInheritance = _.get(changes, [changeKey, 'inheritanceLevel']);
    if (!_.isEmpty(oldInheritance) && newInheritance !== oldInheritance) {
      setChanges(_.omit(changes, changeKey));
    } else {
      setChanges({...changes, [changeKey]: value});
    }
  }

  function onRollbackKeyVersion(key, category, value) {
    const remoteValue = _.find(configuration, {key, keySubCategory: category});
    const operation = utils.makeOperation(levels, value, key, remoteValue, keyedSelectedValues);
    return onSaveConfiguration([operation]);
  }

  function onTemplatedChange() {
    onChangeShowTemplatedValues(!showTemplatedValues);
  }

  function getTemplatingComponent() {
    if (!showTemplatedValues && !onChangeShowTemplatedValues) {
      return null;
    }

    return showTemplatedValues ? (
      <Tooltip title={langs('DISABLE_TEMPLATING')}>
        <Styled.IconButtonStyled component={VisibilityOffIcon} onClick={onTemplatedChange} />
      </Tooltip>
    ) : (
      <Tooltip title={langs('ENABLE_TEMPLATING')}>
        <Styled.IconButtonStyled component={VisibilityIcon} onClick={onTemplatedChange} />
      </Tooltip>
    );
  }

  function getCategoryAllowEditPermissionFunc() {
    const category = _.find(categories, {id: selectedCategory});
    const selectedLabel = utils.getSelectedLabel(selectedValues);
    let labelPermissionsFunc = _.isFunction(selectedLabel?.permissionsFunc)
      ? selectedLabel.permissionsFunc
      : _.get(_.head(selectedValues), 'defaultPermissionsFunc', _.stubTrue);
    return () => labelPermissionsFunc(selectedValues) && category.permissionFuncs.edit(selectedValues);
  }

  const currentLevel = utils.getLevel(selectedValues);
  const subCategories = _(configuration)
    .map('keySubCategory')
    .uniq()
    .value();

  const selectedCategoryEditPermissionsFunc = getCategoryAllowEditPermissionFunc();
  return (
    <Styled.Container>
      <ConfigurationActions
        categories={categories}
        displaySettings={displaySettings}
        getAffectedEntities={getAffectedEntities}
        getConfigurationKeys={getConfigurationKeys}
        levels={levels}
        onAddConfigurationKey={onAddConfigurationKey}
        onChangeCategory={onChangeCategory}
        onSave={onSaveConfiguration}
        operations={operations}
        permissionFuncs={permissionFuncs}
        selectedCategory={selectedCategory}
        selectedValues={selectedValues}
      />
      {!isLoading && (
        <Styled.ScrollableContainer>
          <Styled.StyledFilters>
            {getTemplatingComponent()}
            <ConfigurationFilters value={filters} onChange={setFilters} />
          </Styled.StyledFilters>
          {_(subCategories)
            .map((subCategory) => {
              const rows = _.filter(configuration, {keySubCategory: subCategory});
              const filteredRows = utils.filterRows(
                filters,
                rows,
                currentLevel,
                utils.getRowInheritance.bind(null, changes),
                utils.getRowValue.bind(null, changes),
                operations
              );
              return {category: subCategory, rows: filteredRows};
            })
            .reject(({rows}) => _.isEmpty(rows))
            .map(({category, rows}) => (
              <ConfigurationTable
                key={category}
                levels={levels}
                changes={changes}
                currentLevel={currentLevel}
                onConfigurationChange={onKeyChange}
                onDeleteConfigurationKeyValue={onDeleteConfigurationKeyValue}
                onGetConfigurationKeyVersions={onGetConfigurationKeyVersions}
                onRollbackKeyVersion={onRollbackKeyVersion}
                mainCategory={selectedCategory}
                subCategory={category}
                permissionsFuncs={{edit: selectedCategoryEditPermissionsFunc}}
                rows={rows}
                extendedTypes={extendedTypes}
                displaySettings={displaySettings}
              />
            ))
            .value()}
          <ConfigurationFooter value={operations} />
        </Styled.ScrollableContainer>
      )}
    </Styled.Container>
  );
}

ConfigurationForm.propTypes = {
  categories: PropTypes.array.isRequired,
  configuration: PropTypes.array.isRequired,
  displaySettings: PropTypes.shape({
    showAddNewKeyDefaultValue: PropTypes.bool,
    showAllLabel: PropTypes.bool,
    showConfigurationKeySearch: PropTypes.bool,
    showCreatedByCol: PropTypes.bool,
    showEmptyFilter: PropTypes.bool,
    showInheritCol: PropTypes.bool,
    showLastUpdateCol: PropTypes.bool,
    titleizeSubCategories: PropTypes.bool
  }),
  extendedTypes: PropTypes.objectOf(PropTypes.elementType),
  getAffectedEntities: PropTypes.func.isRequired,
  getConfigurationKeys: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  levels: PropTypes.array.isRequired,
  onAddConfigurationKey: PropTypes.func,
  onChangeCategory: PropTypes.func.isRequired,
  onChangeShowTemplatedValues: PropTypes.func,
  onDeleteConfigurationKeyValue: PropTypes.func.isRequired,
  onGetConfigurationKeyVersions: PropTypes.func.isRequired,
  onSaveConfiguration: PropTypes.func.isRequired,
  permissionFuncs: PropTypes.shape({
    create: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    read: PropTypes.func.isRequired
  }),
  selectedCategory: PropTypes.string.isRequired,
  selectedValues: PropTypes.array.isRequired,
  showTemplatedValues: PropTypes.bool
};

export default ConfigurationForm;
