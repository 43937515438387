import models from '../../../models';
import queryUtils from '../../../utils/agGridUtils/queryUtils';
import services from '../../../services';

const ORDER_BY_DIRECTION = 'desc';
const ORDER_BY_FIELD = models.staticData.CHERRY_PICKS_FIELDS.CP_CREATION_DATE;
const ORDER_BY = `${ORDER_BY_FIELD}:${ORDER_BY_DIRECTION}`;

export async function getCherryPicks(api, request) {
  const query = queryUtils.getQueryParams(api, request, null, ORDER_BY);
  const response = await services.slackbot.getCherryPicks(query);
  return queryUtils.parseResponse(response);
}

export default {
  getCherryPicks
};
