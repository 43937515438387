import _ from 'lodash';

function castToInteger(value) {
  if (value === '') {
    return '';
  }
  return parseInt(value, 10);
}

function castToFloat(value) {
  if (value === '') {
    return '';
  }
  return parseFloat(value);
}

function castToString(value) {
  if (_.isString(value)) {
    return value;
  }
  try {
    return JSON.stringify(value);
  } catch (e) {
    return value;
  }
}

function castToJson(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

export default {castToFloat, castToInteger, castToJson, castToString};
