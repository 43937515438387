import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import hooks from '../../../../hooks';
import langs from '../../../../localization/langs';
import services from '../../../../services';

import {Form, ListSelection} from '../../../../components/Base';

import * as Styled from '../ServiceForm.styles';

function ServiceStacks({value, onChange, ...props}) {
  const [stacksResponse] = hooks.useGetResult(() => services.services.stacks.list({length: -1}), []);

  function isShowError() {
    return _.isEmpty(value);
  }

  const allStacks = _.map(stacksResponse?.items, ({id, name}) => ({id, name}));
  return (
    <Styled.StyledPaper {...props}>
      <Form.Label>{langs('CHOOSE_STACKS')}</Form.Label>
      {isShowError() && <Styled.Error>{langs('ERROR_INVALID_SERVICE_STACKS')}</Styled.Error>}
      <Styled.FormFieldsContainer>
        <ListSelection selectedItems={value} items={allStacks} onChange={onChange} />
      </Styled.FormFieldsContainer>
    </Styled.StyledPaper>
  );
}

ServiceStacks.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired
};

export default ServiceStacks;
