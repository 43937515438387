import styled from 'styled-components';

import * as theme from '../../../theme';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: absolute;
`;

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 0.75s infinite;
    content: '';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`;

export const InternalContainer = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 60px;
`;

export const Message = styled.div``;

export const Title = styled.span`
  color: ${theme.TopPanel.text};
  font-size: 9px;
  margin-left: 4px;
`;
