import styled from 'styled-components';

import Checkbox from '@material-ui/core/Checkbox';
import SvgIcon from '@material-ui/core/SvgIcon';

export const CheckListItem = styled.li`
  display: flex;
  justify-content: space-between;
`;

export const IconButtonStyled = styled(SvgIcon)`
  font-size: 20px;
`;

export const ListCheckbox = styled(Checkbox)`
  padding-block: 0px;
`;

export const ListItem = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
  user-select: none;
`;
