import styled from 'styled-components';

import models from '../../models';

import * as theme from '../../theme';

export const Container = styled.span`
  background-color: ${({light}) => (light ? theme.backgrounds.light : theme.backgrounds.primary)};
  border-radius: 8px;
  cursor: default;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  min-width: 65px;
  padding-left: 6px;
  padding-right: 6px;
  text-align: center;
`;

export const Dot = styled.div`
  background-color: ${({status}) =>
    status === models.collector.collectorStatusOptions.INACTIVE
      ? theme.statusColors.error
      : theme.statusColors.success};
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin-right: 8px;
  margin-bottom: 1px;
  width: 8px;
`;
