import * as artifact from './artifact';
import * as configuration from './configuration';
import * as misc from './misc';
import * as tenants from './tenants';

export default {
  artifact,
  configuration,
  ...misc,
  ...tenants
};
