import React from 'react';
import {useHistory} from 'react-router-dom';

import context from '../../../context';
import consts from '../../../consts';
import langs from '../../../localization/langs';
import services from '../../../services';

import {Breadcrumb} from '../../../components/Base';
import NamespacesForm from '../../../components/Services/NamespacesForm';
import ProtectedView from '../../../components/Rbac/ProtectedView';

import * as Styled from './NamespaceAdd.styles';

const breadcrumb = [{title: langs('MULTI_TENANT'), url: '/multitenant'}, langs('ADD_NEW_NAMESPACE')];

function StackAdd() {
  const history = useHistory();
  const {getUrl} = context.Navigation.useNavigation();
  const notifications = context.Notifications.useNotifications();
  const user = context.User.useUser();

  async function createNewNamespace(environmentId, namespaceId, name, stacks) {
    try {
      const {
        data: {id: newNamespaceId}
      } = await services.services.namespaces.createNamespace(environmentId, name);
      await services.services.namespaces.updateNamespace(environmentId, newNamespaceId, name, stacks);
      history.push(getUrl('services.namespaces', null, {namespaceName: name}));
    } catch (err) {
      console.error(err.stack);
      notifications.error(langs('NAMESPACES_FAILED_TO_CREATE'));
    }
  }

  return (
    <Styled.Container>
      <ProtectedView permissions={[consts.rbac.permissions.mtms.namespaces.create]} rbacControlObj={user.rbac.mtms}>
        <Styled.FormContainer>
          <Breadcrumb sections={breadcrumb} withBorder={false} />
          <NamespacesForm onSubmit={createNewNamespace} />
        </Styled.FormContainer>
      </ProtectedView>
    </Styled.Container>
  );
}

export default StackAdd;
