import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {_ as agGridUtils} from 'ag-grid-community';

import AddIcon from '@material-ui/icons/Add';
import {Paper} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/core/styles';

import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import modelToQuery from '../../../utils/agGridUtils/filterUtils/modelToQuery';
import services from '../../../services';

import useCompactMode from './useCompactMode';
import useContinuousDeliveryMenuItem from '../../../components/Tenants/OptionsMenu/useContinuousDeliveryMenuItem';
import useDestroyMenuItem from '../../../components/Tenants/OptionsMenu/useDestroyMenuItem';
import useMonitoringMenuItem from '../../../components/Tenants/OptionsMenu/useMonitoringMenuItem';
import useNewTaskActions from './useNewTaskActions';
import useStartMenuItem from '../../../components/Tenants/OptionsMenu/useStartMenuItem';
import useStopMenuItem from '../../../components/Tenants/OptionsMenu/useStopMenuItem';
import useUrlSearch from '../../../components/AGTable/hooks/useUrlSearch';
import withTenants from '../../../withTenants.hoc';
import {handleSearchQueryChange} from './compactMode.utils';

import CompactModeTable from './CompactModeTable';
import OpsLink from '../../../components/OpsLink';
import ProtectedComponent from '../../../components/Rbac/ProtectedComponent';
import ReplayTask from '../../../components/ReplayTask';
import TenantPreviewDrawer from '../../../components/Tenants/TenantPreview';
import {Button} from '../../../components/Base';

import {DEFAULT_CONTEXT_MENU} from '../../../components/AGTable/Table';

import * as Styled from './CompactMode.styles';

async function fetchOwners() {
  const users = await services.tenants.getUsers();
  const items = _.get(users, 'data.items', []);
  return _.transform(
    items,
    (res, {email, fullName}) => {
      res[email] = fullName;
    },
    {}
  );
}

function onFieldChange(tenantId, key, value) {
  services.tenants.updateById(tenantId, {[key]: value});
}

async function getRows(params) {
  params.success(await handleSearchQueryChange(params.api, params.request));
}

function CompactModeView({tenants}) {
  const {selectedTenant, setSelectedTenant, taskId, theme} = useCompactMode(tenants);
  const [selectedTenants, setSelectedTenants] = React.useState([]);
  const [tableApi, setTableApi] = React.useState();
  const [owners, setOwners] = React.useState({});
  const {getUrl} = context.Navigation.useNavigation();
  const history = useHistory();
  const [monitoringMenuItemGetDef, monitoringMenuItemDialog] = useMonitoringMenuItem();
  const [continuousDeliveryMenuItemGetDef, continuousDeliveryMenuItemDialog] = useContinuousDeliveryMenuItem();
  const [startMenuItemGetDef, startMenuItemDialog] = useStartMenuItem();
  const [stopMenuItemGetDef, stopMenuItemDialog] = useStopMenuItem();
  const [destroyMenuItemGetDef, destroyMenuItemDialog] = useDestroyMenuItem();
  const [filterModel, onFilterChanged] = useUrlSearch(tableApi);
  const [newTaskGetDef, newTaskDialogs] = useNewTaskActions(selectedTenants);

  const user = context.User.useUser();

  theme.overrides = {...theme.overrides, ...Styled.tenantsTableTheme.overrides};

  function onTableRowClick({data: tenant, event}) {
    if (agGridUtils.isStopPropagationForAgGrid(event)) {
      return;
    }
    setSelectedTenant(selectedTenant ? null : tenant);
  }

  async function fetchFilterOptions({colDef, success}) {
    const filterModel = colDef.gridApi.getFilterModel();
    const filterSearchQuery = modelToQuery.getQuery(colDef.gridApi, filterModel);
    const response = await services.tenants.getAllFilterOptions(colDef.colId, filterSearchQuery);
    success(_.map(response.data, 'name'));
  }

  React.useEffect(() => {
    fetchOwners().then(setOwners);
  }, []);

  function getContextMenuItems(params, tenant) {
    return _.concat(
      [
        newTaskGetDef(tenant),
        'separator',
        {
          action: () => window.open(`https://${tenant.tenantName}.armis.com`),
          icon: 'outline_open_in_browser_white_24dp',
          name: langs('OPEN_CONSOLE')
        },
        {
          action: () => history.push(getUrl('tenants.tenant', tenant)),
          icon: 'outline_fullscreen_white_24dp',
          name: langs('FULL_VIEW')
        },
        {
          action: () =>
            history.push(
              getUrl('tenants.dataFlow', tenant, {
                FLOWS: 'MULTI_TENANT,SINGLE_TENANT',
                QUEUE_TYPES: 'KAFKA_TOPIC',
                QUEUES_WITH_TRAFFIC_ONLY: true
              })
            ),
          icon: 'outline_account_tree_white_24dp',
          name: langs('OPEN_DATA_FLOW')
        },
        'separator',
        monitoringMenuItemGetDef(tenant),
        continuousDeliveryMenuItemGetDef(tenant),
        startMenuItemGetDef(tenant),
        stopMenuItemGetDef(tenant),
        destroyMenuItemGetDef(tenant),
        'separator'
      ],
      DEFAULT_CONTEXT_MENU
    );
  }

  return (
    <div>
      <TenantPreviewDrawer tenant={selectedTenant} onClose={() => setSelectedTenant(null)} />
      <Styled.ActionContainer>
        {taskId && (
          <ReplayTask
            taskId={taskId}
            hostIds={_.map(selectedTenants, (tenant) => _.toInteger(tenant.tenantId))}
            apiPrefix={consts.API_PREFIX_BY_SERVICE_NAME.tenants}
            tasksPage={'tenants.tasks'}
          />
        )}
        <ProtectedComponent
          permissions={[consts.rbac.permissions.maestro.tenant.create]}
          rbacControlObj={user.rbac.maestro}
        >
          <OpsLink to="tenants.add">
            <Button text icon={<AddIcon />}>
              {langs('NEW_TENANT')}
            </Button>
          </OpsLink>
        </ProtectedComponent>
      </Styled.ActionContainer>

      <Paper>
        <ThemeProvider theme={theme}>
          <Styled.TableContainer>
            <CompactModeTable
              data={getRows}
              fetchFilterOptions={fetchFilterOptions}
              filterModel={filterModel}
              getContextMenuItems={getContextMenuItems}
              onFieldChange={onFieldChange}
              onFilterChanged={onFilterChanged}
              onTableRowClick={onTableRowClick}
              owners={owners}
              selectedTenants={selectedTenants}
              setSelectedTenants={setSelectedTenants}
              setTableApi={setTableApi}
              taskId={taskId}
            />
            {continuousDeliveryMenuItemDialog}
            {monitoringMenuItemDialog}
            {startMenuItemDialog}
            {stopMenuItemDialog}
            {destroyMenuItemDialog}
            {newTaskDialogs}
          </Styled.TableContainer>
        </ThemeProvider>
      </Paper>
    </div>
  );
}

CompactModeView.propTypes = {
  tenants: PropTypes.array
};

export default withTenants(CompactModeView, 'compact');
