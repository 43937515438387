import {
  BottomBar,
  Error,
  Field,
  FieldWrapper,
  Form,
  Label,
  FlexLabel,
  RightColumnField,
  ColumnsField
} from './Form.styles';

Form.BottomBar = BottomBar;
Form.Error = Error;
Form.Field = Field;
Form.ColumnsField = ColumnsField;
Form.FieldWrapper = FieldWrapper;
Form.FlexLabel = FlexLabel;
Form.Label = Label;
Form.RightColumnField = RightColumnField;

export default Form;
