import _ from 'lodash';

import appConsts from '../../../consts';
import hooks from '../../../hooks';

import {SEARCH_TYPES} from '../../../context/Search/utils';

export const SEARCH_KEYS = {
  FLOWS: 'FLOWS',
  QUEUE_TYPES: 'QUEUE_TYPES',
  QUEUES_WITH_TRAFFIC_ONLY: 'QUEUES_WITH_TRAFFIC_ONLY'
};

export const SEARCH_OPTIONS = [
  {
    key: SEARCH_KEYS.FLOWS,
    options: [
      {key: 'MULTI_TENANT', value: 'Multi Tenant'},
      {key: 'SINGLE_TENANT', value: 'Single Tenant'},
      {key: 'COLLECTORS', value: 'Collectors'}
    ],
    title: 'Data Flows',
    type: SEARCH_TYPES.CHIPS
  },
  {
    key: SEARCH_KEYS.QUEUE_TYPES,
    options: [
      {key: 'KAFKA_TOPIC', value: 'Kafka Topic'},
      {key: 'RMQ_CHANNEL', value: 'Rabbit MQ Channel'}
    ],
    title: 'Queue Types',
    type: SEARCH_TYPES.CHIPS
  },
  {
    key: SEARCH_KEYS.QUEUES_WITH_TRAFFIC_ONLY,
    title: 'Queues With Traffic Only',
    type: SEARCH_TYPES.BOOLEAN
  }
];

export function useSearchOptions() {
  const [query] = hooks.useUrlSearchParams();
  return _.mapKeys(Object.fromEntries(query), (value, key) => key.replace(appConsts.FILTER_OPERATORS.EQUAL, ''));
}
