import staticData from '../../models/staticData';

import cms from './cms';
import maestro from './maestro';
import models from '../../models';
import mtms from './mtms';
import opsWeb from './opsWeb';
import tasks from './tasks';

export const ENFORCEMENT_LEVEL = models.staticData.RBAC.RBAC_ENFORCEMENT_LEVEL;

export const SERVICE_NAMES_FE = {
  cms: 'cms',
  maestro: 'maestro',
  mtms: 'mtms',
  opsWeb: 'opsWeb',
  rbac: 'rbac'
};

export const SERVICE_NAMES_BE = {
  [SERVICE_NAMES_FE.cms]: models.staticData.RBAC.SERVICE_NAMES.CMS,
  [SERVICE_NAMES_FE.maestro]: models.staticData.RBAC.SERVICE_NAMES.MAESTRO,
  [SERVICE_NAMES_FE.mtms]: models.staticData.RBAC.SERVICE_NAMES.MTMS,
  [SERVICE_NAMES_FE.opsWeb]: models.staticData.RBAC.SERVICE_NAMES.OPS_WEB,
  [SERVICE_NAMES_FE.rbac]: models.staticData.RBAC.SERVICE_NAMES.RBAC
};

export default {
  ENFORCEMENT_LEVEL,
  SERVICE_NAMES_BE,
  SERVICE_NAMES_FE,
  permissions: {
    cms,
    maestro,
    mtms,
    opsWeb,
    rbac: {
      schema: {
        edit: staticData.RBAC.SERVICE_PERMISSIONS.RBAC.EDIT_SCHEMA,
        export: staticData.RBAC.SERVICE_PERMISSIONS.RBAC.EXPORT_SCHEMA
      }
    },
    tasks
  }
};
