const fields = {
  APPLICATION_VERSION: 'applicationVersion',
  APPLICATION_VERSION_MAJOR: 'applicationVersionMajor',
  ENVIRONMENT_NAME: 'environmentName',
  FIRMWARE_BRANCH_BUILD_ID: 'firmwareBranchBuildId',
  FIRMWARE_VERSION_ID: 'firmwareVersionId',
  ID: 'tenantId',
  INDUSTRY: 'industry',
  IS_DEPLOY_LOCKED: 'isDeployLocked',
  NAME: 'tenantName',
  OWNER: 'owner',
  STAGE: 'stage',
  STATUS: 'status',
  TASK_ACTIVITY_STATUS: 'taskActivityStatus',
  TIER: 'tier'
};

export default {
  fields
};
