import styled from 'styled-components';
import {Link} from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import {Grid, InputBase, List} from '@material-ui/core';
import {createTheme} from '@material-ui/core/styles';

import * as theme from '../../../theme';

export const TableContainer = styled.div`
  padding: 10px;
`;

export const Input = styled(InputBase)`
  font-size: 12px;
`;

export const FilterContainer = styled.div`
  background-color: ${({disabled}) => (disabled ? theme.backgrounds.secondary : theme.backgrounds.primary)};
  border-radius: 4px;
  margin: 0 8px;
`;

export const tenantsTableTheme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: '1.25rem'
      }
    },
    MuiTableCell: {
      head: {
        border: 'none',
        color: `${theme.default.palette.text.secondary}`,
        fontSize: 12,
        padding: 0,
        paddingLeft: 8
      },
      root: {
        padding: '8px',
        whiteSpace: 'nowrap'
      }
    }
  }
});

export const Title = styled(Typography).attrs({color: 'textSecondary', component: 'span', variant: 'h6'})``;

export const TitleAndDownload = styled(Grid).attrs({item: true})`
  flex-grow: 1;
`;

export const EllipsisField = styled.div`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FullViewLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  display: flex;
  justify-content: center;
`;

export const TenantActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LoaderContainer = styled.div`
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TenantName = styled.div`
  padding: 5px;
  text-align: center;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
`;

export const ActionsList = styled(List)`
  width: 360px;
  padding-bottom: 0px;
  padding-top: 0px;
`;

export const TableCellIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-left: 0.5rem;
  vertical-align: bottom;
`;
