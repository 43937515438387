import styled from 'styled-components';

import {Grid, InputBase} from '@material-ui/core';

import * as theme from '../../../theme';

export const Container = styled(Grid)``;

export const Input = styled(InputBase)`
  background-color: ${theme.backgrounds.primary};
  padding: 5px;
  font-size: 14px;
  width: 100%;
  & > input {
    padding: 0;
  }
`;

export const Label = styled.div`
  font-size: 12px;
`;
