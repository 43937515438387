export const EDGE_TYPES = {
  ENV_TO_ROLE: 'envToRole',
  GROUP_TO_SERVICE: 'groupToService',
  PERMISSION_TO_ATTRIBUTE: 'permissionToAttribute',
  PERMISSION_TO_DERIVED: 'permissionToDerived',
  ROLE_TO_PERMISSION: 'roleToPermission',
  SERVICE_TO_ENV: 'serviceToEnv',
  USER_TO_GROUP: 'userToGroup',
  USER_TO_SERVICE: 'userToService'
};

export const VERTEX_TYPES = {
  ATTRIBUTE: 'attribute',
  DERIVED_PERMISSION: 'derivedPermission',
  ENV: 'env',
  GROUP: 'group',
  PERMISSION: 'permission',
  ROLE: 'role',
  SERVICE: 'service',
  USER: 'user'
};

export const PERMISSION_GRAPH_REJECT_VERTEX_TYPES = [VERTEX_TYPES.GROUP];

function getEdgeKey(source, target) {
  return `${source}${target}`;
}

export function setEdgeKey(vertices, source, target, type) {
  vertices[getEdgeKey(source, target)] = {source, target, type};
}
