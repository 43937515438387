import styled, {css, keyframes} from 'styled-components';

import * as theme from '../../theme';

export const SUPPORTED_SIZES = {
  large: '16px',
  medium: '14px',
  small: '12px',
  undefined: '14px',
  xlarge: '1.5rem',
  xxlarge: '2rem'
};

export const SUPPORTED_SIZES_PADDING = {
  large: '0.8rem 1.4rem',
  small: '0.3rem 0.8rem',
  xlarge: '0.8rem 1.4rem',
  xxlarge: '0.8rem 1.4rem'
};

const loadingAnimation = keyframes`
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
`;

export const Button = styled.button`
  color: ${theme.buttons.normal.text};
  background: ${theme.buttons.normal.background};
  border: 0;
  font-family: inherit;
  border-radius: 0px;
  user-select: none;

  &:not(:last-of-type) {
    margin-right: 1rem;
  }

  font-size: ${(props) => (props.size ? SUPPORTED_SIZES[props.size] : 'inherit')};
  padding: ${(props) => (props.size ? SUPPORTED_SIZES_PADDING[props.size] : '0.5rem 1rem')};

  &:focus {
    outline: none;
    box-shadow: 0 0 4px ${theme.buttons.normal.hover};
  }

  &:hover {
    box-shadow: 0 0 4px ${theme.buttons.normal.hover};
  }

  ${(props) =>
    props.loading === 'true' &&
    css`
      background: linear-gradient(270deg, #764fd7, #1d1436);
      background-size: 400% 400% !important;
      animation: ${loadingAnimation} 4s ease infinite !important;

      opacity: 0.8;

      cursor: not-allowed;
      pointer-events: none;
    `};

  ${(props) =>
    props.secondary &&
    css`
      color: ${theme.buttons.secondary.text};
      background: ${theme.buttons.secondary.background};
    `};

  ${(props) =>
    props.text &&
    css`
      background: transparent;
    `};

  ${(props) =>
    props.text &&
    props.secondary &&
    css`
      color: ${theme.buttons.secondaryText.text};
    `};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5 !important;
      &:hover {
        box-shadow: none;
      }
    `};

  ${(props) =>
    props.bold &&
    css`
      color: ${theme.buttons.bold.text};
      background: ${theme.buttons.bold.background};
    `};

  ${(props) =>
    props.danger &&
    css`
      color: ${theme.buttons.danger.text};
      background: ${theme.buttons.danger.background};
    `};

  & > svg {
    font-size: inherit;
    vertical-align: middle;
    margin-top: -2px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;
