import Collectors from './Collectors';
import Info from './Info';
import NotesAndComments from './NotesAndComments';
import TasksHistory from './TasksHistory';

export default {
  Collectors,
  Info,
  NotesAndComments,
  TasksHistory
};
