import _ from 'lodash';
import consts from '../consts';

import {SEARCH_TYPES} from '../context/Search/utils';

const HEALTH_STATUS_OPTIONS = [
  {key: 'up', value: 'Up'},
  {key: 'down', value: 'Down'},
  {key: 'none', value: 'Unknown'}
];

const MONITOR_STATUS_OPTIONS = [
  {key: 'on', value: 'On'},
  {key: 'off', value: 'Off'}
];

const TEMPLATE_FULLSTORY_URL = _.template(consts.EXTERNAL_FULLSTORY_URL);
const TEMPLATE_GRAFANA_URL = _.template(consts.EXTERNAL_GRAFANA_URL);
const TEMPLATE_JIRA_URL = _.template(consts.EXTERNAL_JIRA_URL);
const TEMPLATE_OPSGENIE_URL = _.template(consts.EXTERNAL_OPSGENIE_URL);
const TEMPLATE_PENDO_URL = _.template(consts.EXTERNAL_PENDO_URL);
const TEMPLATE_PROMETHEUS_URL = _.template(consts.EXTERNAL_PROMETHEUS_URL);

export function getGrafanaLink(tenantName) {
  return TEMPLATE_GRAFANA_URL({tenantName});
}

export function getPrometheusLink(tenantName) {
  return TEMPLATE_PROMETHEUS_URL({tenantName});
}

export function getJiraLink(tenantName) {
  return TEMPLATE_JIRA_URL({tenantName});
}

export function getOpsGenieLink(tenantName) {
  return TEMPLATE_OPSGENIE_URL({tenantName});
}

export function getPendoLink(tenantName) {
  return TEMPLATE_PENDO_URL({tenantName});
}

export function getFullStoryLink(tenantName) {
  return TEMPLATE_FULLSTORY_URL({tenantName});
}

export function evaluateOptions(tenants) {
  const getTenantsSearchOptions = withTenants(tenants);

  const tenantNameOptions = getTenantsSearchOptions('tenantId', (tenantId) => ({
    key: tenantId,
    value: tenants.find((tenant) => tenant.tenantId === tenantId).name
  }));
  const versionOptions = getTenantsSearchOptions('deployData', (deployData) => ({
    key: deployData.versionDeployed,
    value: deployData.versionDeployed
  }));
  const ownerOptions = getTenantsSearchOptions('operationalData.seOwner', (seOwner) => ({
    key: seOwner,
    value: seOwner
  }));
  const stageOptions = getTenantsSearchOptions('stage', (stage) => ({key: stage, value: stage}));
  const tierOptions = getTenantsSearchOptions('tier', (tier) => ({key: tier, value: tier}));

  return [
    {
      key: 'tenantId',
      options: tenantNameOptions,
      title: 'Tenant Name',
      type: SEARCH_TYPES.TEXT
    },
    {key: 'owner', options: ownerOptions, title: 'Owner', type: SEARCH_TYPES.TEXT},
    {key: 'version', options: versionOptions, title: 'Deployed Version', type: SEARCH_TYPES.TEXT},
    {key: 'healthStatus', options: HEALTH_STATUS_OPTIONS, title: 'Health Status', type: SEARCH_TYPES.CHIPS},
    {key: 'monitorStatus', options: MONITOR_STATUS_OPTIONS, title: 'Monitor Status', type: SEARCH_TYPES.CHIPS},
    {key: 'tier', options: tierOptions, title: 'Tier', type: SEARCH_TYPES.CHIPS},
    {key: 'stage', options: stageOptions, title: 'Stage', type: SEARCH_TYPES.CHIPS},
    {key: 'isSubscribed', title: 'Subscribed', type: SEARCH_TYPES.BOOLEAN},
    {key: 'hasNote', title: 'Has notes', type: SEARCH_TYPES.BOOLEAN}
  ];
}

const withTenants = (tenants) => (key, mapFunction) => {
  if (_.isEmpty(tenants)) {
    return [];
  }

  return _(tenants)
    .map(key)
    .compact()
    .map(mapFunction)
    .uniqBy('key')
    .value();
};
