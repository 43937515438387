import styled from 'styled-components';

import {Form} from '../Base';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Description = styled.div`
  font-size: 16px;
  text-align: center;
  white-space: pre-line;
`;

export const Error = styled(Form.Error)`
  margin-top: 1rem;
  text-align: center;
`;
