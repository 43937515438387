import _ from 'lodash';
import React from 'react';

import columnDefs from '../../../utils/agGridUtils/columnDefs';
import langs from '../../../localization/langs';
import models from '../../../models';
import tableUtils from './table.utils';

import Column from '../../AGTable/Column';
import AGTable from '../../AGTable/Table';
import {JiraTicket, PullRequest, Status} from './Parts/Columns';

async function getRows(params) {
  params.success(await tableUtils.getCherryPicks(params.api, params.request));
}

function Table() {
  return (
    <AGTable data={getRows} pagination title={langs('CHERRY_PICKS')} filterModel={{}}>
      <Column
        {...columnDefs.BASIC_COL}
        colId="branchName"
        title={langs('BRANCH')}
        value={models.staticData.CHERRY_PICKS_FIELDS.BRANCH_NAME}
      />
      <Column
        colId="pullRequest"
        exportFunc={({source_pr_url}) => source_pr_url}
        render={PullRequest}
        title={langs('PR')}
        tooltip={'source_pr_url'}
        value={_.identity}
      />
      <Column
        {...columnDefs.BASIC_COL}
        colId="commitId"
        title={langs('COMMIT_ID')}
        value={models.staticData.CHERRY_PICKS_FIELDS.COMMIT_ID}
      />
      <Column
        {...columnDefs.DATE_COL}
        colId="cpCreationDate"
        filter={false}
        title={langs('CREATION_DATE')}
        value={models.staticData.CHERRY_PICKS_FIELDS.CP_CREATION_DATE}
      />
      <Column
        {...columnDefs.BASIC_COL}
        colId="jiraTicket"
        exportFunc={({jira_ticket}) => jira_ticket}
        render={JiraTicket}
        title={langs('JIRA_TICKET')}
        tooltip={'jira_ticket'}
        value={_.identity}
      />
      <Column {...columnDefs.BASIC_COL} title={langs('REASON')} value={models.staticData.CHERRY_PICKS_FIELDS.REASON} />
      <Column
        {...columnDefs.BASIC_COL}
        filter={false}
        title={langs('REQUESTED_BY')}
        value={models.staticData.CHERRY_PICKS_FIELDS.REQUESTED_USER_NAME}
      />
      <Column
        {...columnDefs.BASIC_COL}
        colId="status"
        exportFunc={({status}) => status}
        render={Status}
        suppressSizeToFit
        title={langs('STATUS')}
        tooltip={'status'}
        value={_.identity}
      />
    </AGTable>
  );
}

export default Table;
