import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './Button.styles';

function Button({children, icon, loading, type = 'button', ...props}) {
  loading = _.isBoolean(loading) ? loading.toString() : undefined;
  return (
    <Styled.Button type={type} loading={loading} {...props}>
      {icon}
      {icon && children ? <span>{children}</span> : children}
    </Styled.Button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  loading: PropTypes.bool,
  secondary: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(Styled.SUPPORTED_SIZES)),
  text: PropTypes.bool,
  type: PropTypes.string
};

export default Button;
