import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './Actions.styles';

function ActionButton({icon: Icon, onClick, isDisabled}) {
  return (
    <Styled.IconButton disabled={isDisabled} onClick={onClick}>
      <Icon />
    </Styled.IconButton>
  );
}

ActionButton.propTypes = {
  icon: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ActionButton;
