import _ from 'lodash';

const SERVICE_CRON_REGEX = /^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/;
const SERVICE_DESCRIPTION_MIN_LENGTH = 3;
const SERVICE_NAME_MAX_LENGTH = 40;
const SERVICE_NAME_MIN_LENGTH = 2;
const SERVICE_NAME_REGEX = /^([a-z0-9]+)(-?[a-z0-9]+)*$/;

function validateServiceNameLength(serviceName) {
  const size = _.size(serviceName);
  return _.inRange(size, SERVICE_NAME_MIN_LENGTH, SERVICE_NAME_MAX_LENGTH + 1);
}

function validateRegexp(serviceName) {
  return SERVICE_NAME_REGEX.test(serviceName);
}

export function validateServiceName(serviceName) {
  if (!validateServiceNameLength(serviceName)) {
    return false;
  }
  return validateRegexp(serviceName);
}

export function validateServiceDescription(serviceDescription) {
  return _.size(serviceDescription) >= SERVICE_DESCRIPTION_MIN_LENGTH;
}

export function validateCronExpressions(cronExpression) {
  return SERVICE_CRON_REGEX.test(cronExpression);
}
