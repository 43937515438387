import _ from 'lodash';
import papaparse from 'papaparse';
import React from 'react';

import langs from '../../../localization/langs';

const EXPORT_FILE_NAME = 'export.csv';
const MIME_CSV = 'text/csv;charset=utf-8;';

export default function useCsvExportMenuItem(gridApi) {
  const getAllData = React.useCallback(() => {
    const totalCount = gridApi.getDisplayedRowCount() || 0;
    const request = {endRow: totalCount, filterModel: gridApi.getFilterModel(), startRow: 0};
    gridApi.getModel().datasource.getRows({api: gridApi, request, success: ({rowData}) => exportToCsv(rowData)});
  }, [gridApi]);

  function flattenObjectArray(array) {
    return _.map(array, (item) => flatten(item));
  }

  function flatten(obj, prefix = '', res = {}) {
    return Object.entries(obj).reduce((accumulator, [key, val]) => {
      const accPrefix = `${prefix}${key}`;
      if (_.isObject(val)) {
        flatten(val, `${accPrefix}.`, accumulator);
      } else {
        res[accPrefix] = val;
      }
      return accumulator;
    }, res);
  }

  function getDownloadLinkUrl(data, columns = null) {
    data = prepareData(data);
    const blobData = papaparse.unparse(data, {columns});
    const blob = new Blob([blobData], {type: MIME_CSV});
    return URL.createObjectURL(blob);
  }

  function prepareData(data) {
    return _.map(data, (row) => _.mapValues(row, prepareValue));
  }

  function prepareValue(value) {
    return _.isObject(value) ? JSON.stringify(value) : value;
  }

  function downloadCsv(linkHref, fileName) {
    const link = document.createElement('a');

    link.href = linkHref;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function exportToCsv(items) {
    const data = flattenObjectArray(items);
    const downloadLink = getDownloadLinkUrl(data);
    downloadCsv(downloadLink, EXPORT_FILE_NAME);
  }

  return {
    action: getAllData,
    customIcon: '<span class="ag-icon ag-icon-csv"></span>',
    name: langs('EXPORT_CSV')
  };
}
