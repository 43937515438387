import PropTypes from 'prop-types';

import * as Styled from './Link.styles.js';

const Link = Styled.Link;

Link.propTypes = {
  $dense: PropTypes.bool,
  to: PropTypes.string
};

export default Link;
