import React from 'react';
import PropTypes from 'prop-types';

import langs from '../../../../localization/langs';

import * as Styled from './EditorActions.styles';

function EditorActions({
  enableAddButton,
  enableDeleteButton,
  enableSaveButton,
  onAddBindingClick,
  onDeleteBindingClick,
  onSaveClick
}) {
  return (
    <Styled.Container>
      <Styled.ActionButton disabled={!enableAddButton} onClick={onAddBindingClick}>
        {langs('ADD_GROUP_SERVICE_ENV_BINDING')}
      </Styled.ActionButton>
      <Styled.ActionButton disabled={!enableDeleteButton} onClick={onDeleteBindingClick}>
        {langs('DELETE_GROUP_SERVICE_ENV_BINDING')}
      </Styled.ActionButton>
      <Styled.SaveButton disabled={!enableSaveButton} onClick={onSaveClick}>
        {langs('BUTTON_SAVE_CHANGES')}
      </Styled.SaveButton>
    </Styled.Container>
  );
}

EditorActions.propTypes = {
  enableAddButton: PropTypes.bool.isRequired,
  enableDeleteButton: PropTypes.bool.isRequired,
  enableSaveButton: PropTypes.bool.isRequired,
  onAddBindingClick: PropTypes.func.isRequired,
  onDeleteBindingClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired
};

export default EditorActions;
