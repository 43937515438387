import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import context from '../../context';
import hooks from '../../hooks';
import langs from '../../localization/langs';
import services from '../../services';

import {Button, Form, Input, ListSelection} from '../../components/Base';

import * as Styled from './NamespacesForm.styles';

function NamespacesForm({environmentId, namespaceId, onSubmit}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedEnvironmentId, setSelectedEnvironmentId] = React.useState(environmentId);
  const [namespaceName, setNamespaceName] = React.useState('');
  const [selectedStacks, setSelectedStacks] = React.useState([]);

  const notifications = context.Notifications.useNotifications();

  React.useEffect(() => {
    if (_.isEmpty(environmentId) || _.isEmpty(namespaceId)) {
      return;
    }
    services.services.namespaces
      .getById(environmentId, namespaceId)
      .then(({data: {name, stacks}}) => {
        setNamespaceName(name);
        setSelectedStacks(_.map(stacks, ({id, name}) => ({id, name})));
      })
      .catch((err) => {
        console.error(err.stack);
        notifications.error(langs('NAMESPACES_FAILED_TO_LOAD'));
      });
  }, []);

  const [envsResponse] = hooks.useGetResult(() => services.services.environments.list({}), []);
  const [stacksResponse] = hooks.useGetResult(() => services.services.stacks.list({length: -1}), []);

  async function onFormSubmit() {
    const formattedStacks = _.map(selectedStacks, ({id, name}) => ({id, stackName: name}));
    try {
      setIsLoading(true);
      await onSubmit(selectedEnvironmentId, namespaceId, namespaceName, formattedStacks);
    } catch (err) {
      console.error(err.stack);
    } finally {
      setIsLoading(false);
    }
  }

  const allEnvs = _.map(envsResponse?.items, ({id, name}) => ({id, name}));
  const allStacks = _.map(stacksResponse?.items, ({id, name}) => ({id, name}));
  return (
    <Form>
      <Form.Field>
        <Form.Label>{langs('NAMESPACES_FIELD_ENV_NAME')}</Form.Label>
        <Select
          disabled={!_.isEmpty(environmentId)}
          value={selectedEnvironmentId}
          onChange={(event) => setSelectedEnvironmentId(event.target.value)}
        >
          {_.map(allEnvs, ({id, name}) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </Form.Field>
      <Form.Field>
        <Form.Label>{langs('NAMESPACES_FIELD_NAME')}</Form.Label>
        <Input
          type="text"
          disabled={!_.isEmpty(namespaceId)}
          value={namespaceName}
          onChange={(e) => setNamespaceName(e.target.value)}
        />
      </Form.Field>
      <Form.Field>
        <Form.Label>{langs('NAMESPACES_FIELD_STACKS')}</Form.Label>
        <ListSelection selectedItems={selectedStacks} items={allStacks} onChange={setSelectedStacks} />
      </Form.Field>
      <Styled.BottomBar>
        <Button disabled={_.isEmpty(namespaceName) || isLoading} loading={isLoading} onClick={onFormSubmit}>
          {langs('BUTTON_SAVE')}
        </Button>
      </Styled.BottomBar>
    </Form>
  );
}

NamespacesForm.propTypes = {
  environmentId: PropTypes.string,
  namespaceId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

export default NamespacesForm;
