import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from '../../Base/Link.styles';

function LinkColumn({data, value, onClick}) {
  if (!value) {
    return null;
  }
  return <Styled.TextLink onClick={() => onClick(data)}>{value}</Styled.TextLink>;
}

LinkColumn.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default LinkColumn;
