import PropTypes from 'prop-types';
import React from 'react';

import services from '../../../../services';
import {completedTaskStatuses, taskStatuses} from '../../enums';

import ActionButton from './ActionButton';
import OpsLink from '../../../OpsLink';

import * as Styled from './Actions.styles';

function Actions({task, onClose, apiPrefix, tableRoute, disableReplay = false}) {
  const isDisabledActionButton = completedTaskStatuses.includes(task.status);

  return (
    <Styled.Container>
      <Styled.BackButton onClick={onClose}>
        <Styled.ChevronLeftIcon />
      </Styled.BackButton>

      <Styled.ActionsButtons>
        <OpsLink to={tableRoute} query={{taskId: task.id}} disabled={disableReplay}>
          <Styled.IconButton disabled={disableReplay}>
            <Styled.ReplayIcon />
          </Styled.IconButton>
        </OpsLink>
        <OpsLink to={tableRoute} query={{taskId: task.id}}>
          <Styled.IconButton>
            <Styled.TocIcon />
          </Styled.IconButton>
        </OpsLink>

        {task.status === taskStatuses.PAUSED ? (
          <ActionButton
            isDisabled={isDisabledActionButton}
            icon={Styled.PlayArrowIcon}
            onClick={() => services.tasks.continueTask(task.id, apiPrefix)}
          />
        ) : (
          <ActionButton
            isDisabled={isDisabledActionButton}
            icon={Styled.PauseIcon}
            onClick={() => services.tasks.pauseTask(task.id, apiPrefix)}
          />
        )}

        <ActionButton
          isDisabled={isDisabledActionButton}
          icon={Styled.DeleteRoundedIcon}
          onClick={() => services.tasks.cancelTask(task.id, apiPrefix)}
        />
      </Styled.ActionsButtons>
    </Styled.Container>
  );
}

Actions.propTypes = {
  apiPrefix: PropTypes.string.isRequired,
  disableReplay: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tableRoute: PropTypes.string.isRequired,
  task: PropTypes.object.isRequired
};

export default Actions;
