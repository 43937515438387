import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './ExternalLinkColumn.styles';

function ExternalLinkColumn({value, urlPrefix}) {
  if (!value) {
    return null;
  }
  return (
    <Styled.Link href={`${urlPrefix}${value}`} target="_blank" rel="noreferrer">
      {value}
    </Styled.Link>
  );
}

ExternalLinkColumn.propTypes = {
  urlPrefix: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ExternalLinkColumn;
