import styled from 'styled-components';

import SvgIcon from '@material-ui/core/SvgIcon';

import {CONFIGURATIONS_ACTIONS_HEIGHT} from './ConfigurationActions.styles';

export const Container = styled.div`
  height: 100%;
`;

export const IconButtonStyled = styled(SvgIcon)`
  cursor: pointer;
`;

export const ScrollableContainer = styled.div`
  height: calc(100% - ${CONFIGURATIONS_ACTIONS_HEIGHT}px);
  overflow-y: auto;
`;

export const StyledFilters = styled.div`
  padding-top: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
