import styled from 'styled-components';

import EditIcon from '@material-ui/icons/Edit';

import * as theme from '../../../theme';

export const Wrapper = styled.span``;

export const HoverIcon = styled(EditIcon)`
  display: none;
  margin-left: 4px;

  ${Wrapper}:hover & {
    display: inline;
  }
`;

export const DisabledHoverIcon = styled(HoverIcon)`
  color: ${theme.disabled.color};
`;
