import staticData from '../../models/staticData';

import {appendAttribute} from './common';

const MAESTRO_PERMISSIONS = staticData.RBAC.SERVICE_PERMISSIONS.MAESTRO;

export default {
  tenant: {
    configuration: {
      create: {
        all: {
          all: appendAttribute(
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION,
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION_LEVEL_ALL_ALL
          ),
          envs: appendAttribute(
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION,
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION_LEVEL_ALL_ENVS
          ),
          tenants: appendAttribute(
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION,
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION_LEVEL_ALL_TENANTS
          )
        },
        categories: {
          appDeployment: appendAttribute(
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION,
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION_CATEGORY_APP_DEPLOYMENT
          ),
          firmware: appendAttribute(
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION,
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION_CATEGORY_FIRMWARE
          ),
          terraform: appendAttribute(
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION,
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION_CATEGORY_TERRAFORM
          ),
          tms: appendAttribute(
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION,
            MAESTRO_PERMISSIONS.CREATE_CONFIGURATION_CATEGORY_TMS
          )
        },
        create: MAESTRO_PERMISSIONS.CREATE_CONFIGURATION
      },
      delete: {
        all: {
          all: appendAttribute(
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION,
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION_LEVEL_ALL_ALL
          ),
          envs: appendAttribute(
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION,
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION_LEVEL_ALL_ENVS
          ),
          tenants: appendAttribute(
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION,
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION_LEVEL_ALL_TENANTS
          )
        },
        categories: {
          appDeployment: appendAttribute(
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION,
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION_CATEGORY_APP_DEPLOYMENT
          ),
          firmware: appendAttribute(
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION,
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION_CATEGORY_FIRMWARE
          ),
          terraform: appendAttribute(
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION,
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION_CATEGORY_TERRAFORM
          ),
          tms: appendAttribute(
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION,
            MAESTRO_PERMISSIONS.DELETE_CONFIGURATION_CATEGORY_TMS
          )
        },
        delete: MAESTRO_PERMISSIONS.DELETE_CONFIGURATION
      },
      edit: {
        all: {
          all: appendAttribute(
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION,
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION_LEVEL_ALL_ALL
          ),
          envs: appendAttribute(
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION,
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION_LEVEL_ALL_ENVS
          ),
          tenants: appendAttribute(
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION,
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION_LEVEL_ALL_TENANTS
          )
        },
        categories: {
          appDeployment: appendAttribute(
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION,
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION_CATEGORY_APP_DEPLOYMENT
          ),
          firmware: appendAttribute(
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION,
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION_CATEGORY_FIRMWARE
          ),
          terraform: appendAttribute(
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION,
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION_CATEGORY_TERRAFORM
          ),
          tms: appendAttribute(
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION,
            MAESTRO_PERMISSIONS.EDIT_CONFIGURATION_CATEGORY_TMS
          )
        },
        edit: MAESTRO_PERMISSIONS.EDIT_CONFIGURATION
      },
      read: {
        all: {
          all: appendAttribute(
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES,
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES_LEVEL_ALL_ALL
          ),
          envs: appendAttribute(
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES,
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES_LEVEL_ALL_ENVS
          ),
          tenants: appendAttribute(
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES,
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES_LEVEL_ALL_TENANTS
          )
        },
        categories: {
          appDeployment: appendAttribute(
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES,
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES_CATEGORY_APP_DEPLOYMENT
          ),
          firmware: appendAttribute(
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES,
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES_CATEGORY_FIRMWARE
          ),
          terraform: appendAttribute(
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES,
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES_CATEGORY_TERRAFORM
          ),
          tms: appendAttribute(
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES,
            MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES_CATEGORY_TMS
          )
        },
        read: MAESTRO_PERMISSIONS.READ_CONFIGURATION_VALUES
      }
    },
    create: MAESTRO_PERMISSIONS.TENANT_CREATE,
    destroy: MAESTRO_PERMISSIONS.TENANT_DESTROY,
    edit: {
      isCdEnabled: appendAttribute(
        MAESTRO_PERMISSIONS.TENANT_UPDATE,
        MAESTRO_PERMISSIONS.PROTO_FIELDS_TO_PERMISSIONS.tenant_update.is_cd_enabled
      ),
      monitoring: appendAttribute(
        MAESTRO_PERMISSIONS.TENANT_UPDATE,
        MAESTRO_PERMISSIONS.PROTO_FIELDS_TO_PERMISSIONS.tenant_update.is_monitored
      ),
      stage: appendAttribute(
        MAESTRO_PERMISSIONS.TENANT_UPDATE,
        MAESTRO_PERMISSIONS.PROTO_FIELDS_TO_PERMISSIONS.tenant_update.stage
      )
    },
    read: MAESTRO_PERMISSIONS.TENANT_READ,
    rmq_relay_stop: MAESTRO_PERMISSIONS.RMQ_RELAY_STOP,
    stop: MAESTRO_PERMISSIONS.TENANT_STOP,
    task: {
      runAll: MAESTRO_PERMISSIONS.RUN_TASK_ALL,
      runElevated: {
        critical: MAESTRO_PERMISSIONS.RUN_TASK_ELEVATED_CRITICAL,
        high: MAESTRO_PERMISSIONS.RUN_TASK_ELEVATED_HIGH,
        low: MAESTRO_PERMISSIONS.RUN_TASK_ELEVATED_LOW,
        medium: MAESTRO_PERMISSIONS.RUN_TASK_ELEVATED_MEDIUM
      },
      runFieldAction: MAESTRO_PERMISSIONS.RUN_TASK_FIELD_ACTION,
      runNonElevated: MAESTRO_PERMISSIONS.RUN_TASK_NON_ELEVATED,
      runShellCommand: MAESTRO_PERMISSIONS.RUN_TASK_SHELL_COMMAND
    },
    update: MAESTRO_PERMISSIONS.TENANT_UPDATE
  }
};
