import PropTypes from 'prop-types';
import React from 'react';

import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

import useMonitoringMenuItem from './useMonitoringMenuItem';

import {TooltipMenuItem} from '../../Base';

function MonitoringMenuItem({tenantId, isMonitored, onChange}) {
  const [getDef, dialog] = useMonitoringMenuItem(onChange);
  const tenant = {isMonitored, tenantId};
  const def = getDef(tenant);

  return (
    <TooltipMenuItem
      dialog={dialog}
      disabled={def.disabled}
      icon={<LocalHospitalIcon />}
      name={def.name}
      onClick={def.onClick}
      tooltip={def.tooltip}
    />
  );
}

MonitoringMenuItem.propTypes = {
  isMonitored: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  tenantId: PropTypes.string.isRequired
};

export default MonitoringMenuItem;
