import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {useAnimate} from 'react-simple-animate';

import Message, {SEVERITY} from './Message';

function Notification({children, onClick = _.stubObject, show, severity = SEVERITY.INFO, timeout = 5000}) {
  const {play, style} = useAnimate({
    duration: 0.2,
    end: {opacity: 1, transform: 'translateX(0px)'},
    start: {opacity: 0, transform: 'translateX(-300px)'}
  });

  React.useEffect(() => {
    const timer = setTimeout(() => {
      onClick();
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout]);

  React.useEffect(() => {
    play(show);
  }, [show, play]);

  if (!show) {
    return null;
  }

  return (
    <div style={style}>
      <Message severity={severity} onClick={onClick}>
        {children}
      </Message>
    </div>
  );
}

Notification.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  severity: PropTypes.oneOf(_.values(SEVERITY)),
  show: PropTypes.bool.isRequired,
  timeout: PropTypes.number
};

export default Notification;
