import Ansi from 'ansi-to-react';
import styled from 'styled-components';

import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiButton from '@material-ui/core/Button';

import CollapseItem from './parts/CollapseItem';

import * as theme from '../../theme';

export const Container = styled(DialogContentText).attrs({component: 'span'})`
  font-size: 12px;
  font-family: monospace;
`;

export const Line = styled.div`
  margin-left: ${({indent}) => `${indent * 15}px`};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Title = styled(DialogTitle)`
  padding: 0;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const ActionButton = styled(MuiButton).attrs({size: 'small', variant: 'text'})`
  border: 1px solid ${theme.text.primary};
`;

export const Terminal = styled(Ansi).attrs({useClasses: true})`
  color: ${theme.text.primary};
  .ansi-red-fg,
  .ansi-bright-red-fg {
    color: ${theme.terminalColors.red};
  }
  .ansi-black-fg,
  .ansi-bright-black-fg {
    color: ${theme.terminalColors.black};
  }
  .ansi-green-fg,
  .ansi-bright-green-fg {
    color: ${theme.terminalColors.green};
  }
  .ansi-yellow-fg,
  .ansi-bright-yellow-fg {
    color: ${theme.terminalColors.yellow};
  }
  .ansi-blue-fg,
  .ansi-bright-blue-fg {
    color: ${theme.terminalColors.blue};
  }
  .ansi-magenta-fg,
  .ansi-bright-magenta-fg {
    color: ${theme.terminalColors.magenta};
  }
  .ansi-cyan-fg,
  .ansi-bright-cyan-fg {
    color: ${theme.terminalColors.cyan};
  }
  .ansi-white-fg,
  .ansi-bright-white-fg {
    color: ${theme.terminalColors.white};
  }
  .ansi-bold {
    font-weight: bold;
  }
`;

export const StyledCollapse = styled(CollapseItem).attrs(({isFail, isSuccess}) => ({
  titleColor: isFail ? theme.terminalColors.red : isSuccess ? theme.terminalColors.green : theme.terminalColors.grey
}))`
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: ${({indent}) => `${indent * 15}px`};
`;
