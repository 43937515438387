import AnsibleLogo from './icons/ansible-icon.svg';
import ArmisLogo from './armis-logo-white.svg';
import TerraformLogo from './icons/terraformio-icon.svg';
import UnallowedResource from './unallowed-resource.svg';

export default {
  AnsibleLogo,
  ArmisLogo,
  TerraformLogo,
  UnallowedResource
};
