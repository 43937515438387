import styled, {css} from 'styled-components';

import * as theme from '../../theme';

export const Container = styled.div`
  color: ${theme.text.primary};
  border-radius: 4px;
  font-weight: 500;
  padding: 12px 16px;
  display: flex;
  align-items: center;

  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);

  background-color: ${(props) => theme.statusBackground[props.severity]};

  margin: 0.5rem 0;

  ${(props) =>
    props.onClick &&
    css`
      user-select: none;
      cursor: pointer;
    `};
`;

export const Icon = styled.div`
  display: flex;
  opacity: 0.85;
  margin-right: 12px;
`;

export const Message = styled.div``;
