import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from '../../Base/Link.styles';

function EmailColumn({value}) {
  if (!value) {
    return null;
  }
  return (
    <Styled.BaseLink target="_blank" rel="noopener noreferrer" href={`mailto:${value}`}>
      {value}
    </Styled.BaseLink>
  );
}

EmailColumn.propTypes = {
  value: PropTypes.string
};

export default EmailColumn;
