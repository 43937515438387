import _ from 'lodash';

import consts from '../../../consts';
import demoConsts from '../../Demo/actions/SeedTenantDialog.consts';

const ENVIRONMENT = {
  DEMO: 'demo',
  DEVELOPMENT: 'development',
  PRODUCTION: 'production'
};
const CLUSTER_TO_TENANT_PREFIX = {
  default: ['test-'],
  [consts.envs.CLUSTER_PREFIXES.DEMO]: ['demo-', ...demoConsts.PARTNER_TENANT_PREFIX],
  [consts.envs.CLUSTER_PREFIXES.PROD]: [''],
  [consts.envs.CLUSTER_PREFIXES.QA]: ['']
};

const CLUSTERS_WITH_STAGE = [consts.DEMO_ENV];
const DEV_CLUSTERS = ['dev1'];
const QA_CLUSTERS = ['qa1'];

function checkCluster(cluster, prefix) {
  return !!cluster && _.includes(_.toLower(cluster.name), prefix);
}

function getMultitenantData(cluster, namespace, selectedStacks) {
  let multitenantData = {isNewNamespace: isDevCluster(cluster) && _.isEmpty(namespace)};
  if (_.isEmpty(namespace)) {
    _.assign(multitenantData, {
      stacks: _.map(selectedStacks, (stack) => ({
        continuous: stack.checked || false,
        id: stack.id
      }))
    });
  } else {
    _.assign(multitenantData, {tier: _.get(namespace, 'name', '')});
  }
  return multitenantData;
}

function getTenantEnvironment(cluster) {
  if (_.isEmpty(cluster)) {
    return ENVIRONMENT.PRODUCTION;
  }
  if (checkCluster(cluster, consts.envs.CLUSTER_PREFIXES.PROD)) {
    return ENVIRONMENT.PRODUCTION;
  }
  if (checkCluster(cluster, consts.envs.CLUSTER_PREFIXES.DEMO)) {
    return ENVIRONMENT.DEMO;
  }
  return ENVIRONMENT.DEVELOPMENT;
}

function getTenantNamePrefixes(cluster) {
  if (_.isEmpty(cluster)) {
    return [''];
  }
  let prefix = _.find(CLUSTER_TO_TENANT_PREFIX, (value, key) => _.startsWith(cluster.name, key));
  if (_.isUndefined(prefix)) {
    return CLUSTER_TO_TENANT_PREFIX.default;
  }
  return prefix;
}

function isClusterWithStage(cluster) {
  if (_.isEmpty(cluster)) {
    return false;
  }
  return CLUSTERS_WITH_STAGE.includes(cluster.name);
}

function isContinuousDelivery(branchSelection, deliveryType) {
  return _.includes(branchSelection, deliveryType);
}

function isDevCluster(cluster) {
  if (_.isEmpty(cluster)) {
    return false;
  }
  return DEV_CLUSTERS.includes(cluster.name);
}

function isQACluster(cluster) {
  if (_.isEmpty(cluster)) {
    return false;
  }
  return QA_CLUSTERS.includes(cluster.name);
}

function isSubmitDisabled(isLoading, cluster, name, isIndustryFilled, selectedStacks, namespace, stage) {
  const clusterType = _.get(cluster, 'type', '');
  const generalValidation = isLoading || _.isNil(cluster) || _.isEmpty(name);
  if (clusterType === ENVIRONMENT.DEVELOPMENT) {
    return generalValidation || (_.isEmpty(selectedStacks) && !namespace);
  }
  if (clusterType === ENVIRONMENT.PRODUCTION) {
    return generalValidation || !isIndustryFilled;
  }
  if (clusterType === ENVIRONMENT.DEMO) {
    return generalValidation || !stage;
  }
  return generalValidation;
}

function handleClusterChanged(selected, setCluster, setSelectedPrefix) {
  setCluster(selected);
  setSelectedPrefixFromCluster(selected, setSelectedPrefix);
}

function setSelectedPrefixFromCluster(clusterName, setSelectedPrefix) {
  setSelectedPrefix(_.head(getTenantNamePrefixes(clusterName)));
}

export default {
  checkCluster,
  getMultitenantData,
  getTenantEnvironment,
  getTenantNamePrefixes,
  handleClusterChanged,
  isClusterWithStage,
  isContinuousDelivery,
  isDevCluster,
  isQACluster,
  isSubmitDisabled,
  setSelectedPrefixFromCluster
};
