import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  list-style: none;
  margin: 0 1rem;
  padding: 0;
  right: 0;
  left: 0;
  bottom: 70px;
  z-index: 2000;
  left: 20%;
  right: 20%;
`;
