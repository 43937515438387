import _ from 'lodash';

import {EDGE_TYPES, VERTEX_TYPES, PERMISSION_GRAPH_REJECT_VERTEX_TYPES, setEdgeKey} from './graphUtils';

function getGroupGraph(group, selectedEnvs, selectedServices, roleMap, extendRoles = []) {
  let edges = {};
  let vertices = {[group.id]: {id: group.id, label: group.id, type: VERTEX_TYPES.GROUP}};

  const services = selectedServices
    ? _.filter(group.services, (service) => _.indexOf(selectedServices, service.id) >= 0)
    : group.services;

  _.forEach(services, (service) => {
    vertices[service.id] = {id: service.id, label: service.id, type: VERTEX_TYPES.SERVICE};
    setEdgeKey(edges, group.id, service.id, EDGE_TYPES.GROUP_TO_SERVICE);

    const envs = selectedEnvs ? _.filter(service.envs, (env) => _.indexOf(selectedEnvs, env.id) >= 0) : service.envs;

    _.forEach(envs, (env) => {
      vertices[env.id] = {id: env.id, label: env.id, type: VERTEX_TYPES.ENV};
      setEdgeKey(edges, service.id, env.id, EDGE_TYPES.SERVICE_TO_ENV);

      _.forEach(env.roles, (role) => {
        vertices[role.name] = {id: role.id, label: role.name, type: VERTEX_TYPES.ROLE};
        setEdgeKey(edges, env.id, role.id, EDGE_TYPES.ENV_TO_ROLE);
        if (roleMap && _.includes(extendRoles, role.id)) {
          const roleGraph = getRoleGraph(roleMap[role.id], true);
          edges = _.assign(edges, roleGraph.edges);
          vertices = _.assign(vertices, roleGraph.vertices);
        }
      });
    });
  });

  return {
    edges: _.values(edges),
    id: group.id,
    vertices: _.values(vertices)
  };
}

function getPermissionsGraph(permissions, edges, vertices, rootNodeId, isDerivedPermissions) {
  _.forEach(permissions, (permission) => {
    const permissionKey = permission.friendlyName;
    vertices[permissionKey] = {
      id: permissionKey,
      label: permissionKey,
      type: isDerivedPermissions ? VERTEX_TYPES.DERIVED_PERMISSION : VERTEX_TYPES.PERMISSION
    };
    setEdgeKey(
      edges,
      rootNodeId,
      permissionKey,
      isDerivedPermissions ? EDGE_TYPES.PERMISSION_TO_DERIVED : EDGE_TYPES.ROLE_TO_PERMISSION
    );

    _.forEach(permission.attributes, (attributeName) => {
      vertices[attributeName] = {
        id: attributeName,
        label: attributeName,
        type: VERTEX_TYPES.ATTRIBUTE
      };
      setEdgeKey(edges, permissionKey, attributeName, EDGE_TYPES.PERMISSION_TO_ATTRIBUTE);
    });

    getPermissionsGraph(permission.derivedPermissions, edges, vertices, permissionKey, true);
  });
}

function getRoleGraph(role, omitRootNode = false) {
  let edges = {};
  let vertices = omitRootNode ? {} : {[role.id]: {id: role.id, label: role.name, type: VERTEX_TYPES.ROLE}};

  getPermissionsGraph(role.permissions, edges, vertices, role.id);

  return {
    edges: _.values(edges),
    id: role.id,
    name: role.name,
    vertices: _.values(vertices)
  };
}

function getUserToGroupVertex(removeGroupVertices, userId, group) {
  return removeGroupVertices
    ? []
    : {
        source: userId,
        target: group.id,
        type: EDGE_TYPES.USER_TO_GROUP
      };
}

function getUserPermissionsGraph(userId, transformedSchema, removeGroupVertices = true, selectedGroups) {
  let edges = [];
  let vertices = [{id: userId, label: userId, type: VERTEX_TYPES.USER}];

  const groups = selectedGroups
    ? _.filter(transformedSchema.groups, (group) => _.includes(selectedGroups, group.id))
    : transformedSchema.groups;

  _.forEach(groups, (group) => {
    const userToGroup = getUserToGroupVertex(removeGroupVertices, userId, group);
    edges = _.concat(edges, userToGroup, transformGroupEdges(group, removeGroupVertices, userId));
    vertices = _.concat(vertices, transformGroupVertices(removeGroupVertices, group));
  });

  return {
    edges,
    timestamp: Date.now(),
    vertices
  };
}

function transformGroupEdges(group, removeGroupVertices, userId) {
  return _(group.edges)
    .values()
    .map((edge) => {
      if (edge.type === EDGE_TYPES.GROUP_TO_SERVICE && removeGroupVertices) {
        return _.assign({}, edge, {source: userId, type: EDGE_TYPES.USER_TO_SERVICE});
      }
      return edge;
    })
    .compact()
    .value();
}

function transformGroupVertices(removeGroupVertices, group) {
  return removeGroupVertices
    ? _.filter(_.values(group.vertices), (vertex) => !_.includes(PERMISSION_GRAPH_REJECT_VERTEX_TYPES, vertex.type))
    : _.values(group.vertices);
}

export default {
  group: getGroupGraph,
  roles: getRoleGraph,
  userPermissions: getUserPermissionsGraph
};
