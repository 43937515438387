import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';

import {commandType} from '../../models/command';
import context from '../../context';
import langs from '../../localization/langs';
import roles from '../../roles';

import {Accordion, ConfirmationModal, ProgressBar, TaskParametersForm, Visible} from '../Base';
import AddNewCommand from './AddNewCommand';
import TaskDialogActions from './TaskDialogActions';

import * as Styled from './Select.styles';

function SelectCommand({
  closeModal,
  createShellCommandApi,
  archiveShellCommandApi,
  onBack,
  onNext,
  parametersValues,
  setCommand,
  setDialogTitle,
  setParametersValues,
  command,
  getShellCommands
}) {
  const [searchValue, setSearchValue] = React.useState('');
  const [commands, setCommands] = React.useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isArchiveInProgress, setIsArchiveInProgress] = React.useState(false);
  const selectedCommand = _.find(commands, {id: command?.id});

  const notifications = context.Notifications.useNotifications();
  const ref = React.useRef(null);

  async function getCommands() {
    setIsLoading(true);
    try {
      const {
        data: {items}
      } = await getShellCommands();
      setCommands(items);
    } catch (e) {
      console.error(langs('FAILED_GET_COMMANDS'), e);
      notifications.error(langs('FAILED_GET_COMMANDS'));
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    getCommands();
  }, []);

  const filteredCommands = commands.filter(
    (command) =>
      command.command.toLowerCase().includes(searchValue) || command.description.toLowerCase().includes(searchValue)
  );

  React.useEffect(() => setDialogTitle(langs('SELECT_COMMAND_TO_RUN')));

  function onAddNewCommandResponse(customCommand, customCommandDescription, createCommandResponse) {
    setCommands([
      ...commands,
      {
        command: customCommand,
        commandType: commandType.SHELL_COMMAND,
        description: customCommandDescription,
        id: createCommandResponse.data.id
      }
    ]);
    setCommand(createCommandResponse.data);
    ref.current.scrollTop = ref.current.scrollHeight;
  }

  async function archiveCommand(id) {
    try {
      setIsArchiveInProgress(true);
      await archiveShellCommandApi(id);
      setIsArchiveInProgress(false);
      setSearchValue('');
      setCommands([]);
      getCommands();
    } catch (error) {
      console.log(error);
      setIsArchiveInProgress(false);
      notifications.error(langs('ERROR_ARCHIVE_COMMAND'));
    }
  }

  return (
    <div>
      <Styled.DialogContent>
        <FormControl fullWidth={true}>
          <Styled.Input
            onChange={(event) => setSearchValue(event.target.value.toLowerCase())}
            value={searchValue}
            placeholder={langs('SEARCH_FOR_COMMANDS')}
            startAdornment={
              <Styled.InputAdornment position="start">
                <SearchIcon fontSize={'inherit'} color={'inherit'} />
              </Styled.InputAdornment>
            }
          />

          <ProgressBar isLoading={isLoading} />

          <Styled.CommandsList ref={ref}>
            <Styled.BackgroundColorContainer>
              <Styled.Commands>
                {filteredCommands.map((command, idx) => (
                  <Styled.Command
                    key={idx}
                    selected={selectedCommand?.id === command.id}
                    onClick={() => setCommand(command)}
                  >
                    <Styled.ListItemText>
                      <Styled.CommandDescription>{command.description}</Styled.CommandDescription>
                      <Styled.ShellCommand>{command.command}</Styled.ShellCommand>
                    </Styled.ListItemText>
                    {isArchiveInProgress && selectedCommand?.id === command.id ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Visible role={roles.MANAGE_COMMANDS}>
                        <Styled.CommandActions>
                          <Styled.ArchiveButton onClick={() => setIsConfirmModalOpen(true)} />
                          <Styled.CopyButton onClick={() => navigator.clipboard.writeText(command.command)} />
                        </Styled.CommandActions>
                      </Visible>
                    )}
                  </Styled.Command>
                ))}
              </Styled.Commands>
            </Styled.BackgroundColorContainer>
          </Styled.CommandsList>
        </FormControl>

        {!_.isEmpty(selectedCommand?.parameters) && (
          <TaskParametersForm
            parameters={selectedCommand?.parameters}
            parametersValues={parametersValues}
            setParametersValues={setParametersValues}
          />
        )}

        <Visible role={roles.MANAGE_COMMANDS}>
          <Accordion title={langs('ADD_COMMAND')}>
            <AddNewCommand
              createShellCommandApi={createShellCommandApi}
              onAddNewCommandResponse={onAddNewCommandResponse}
              selectedCommand={selectedCommand}
            />
          </Accordion>
        </Visible>
      </Styled.DialogContent>
      <TaskDialogActions
        isAllowedNextStep={Boolean(selectedCommand)}
        onBack={onBack}
        onCancel={closeModal}
        onNext={onNext}
      />
      <ConfirmationModal
        open={isConfirmModalOpen}
        title={langs('ARE_YOU_SURE_COMMAND_ARCHIVE')}
        onConfirm={() => archiveCommand(selectedCommand.id)}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
    </div>
  );
}

SelectCommand.propTypes = {
  archiveShellCommandApi: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  command: PropTypes.object,
  commands: PropTypes.array,
  createShellCommandApi: PropTypes.func.isRequired,
  getShellCommands: PropTypes.func,
  isLoading: PropTypes.bool,
  onBack: PropTypes.func,
  onNext: PropTypes.func.isRequired,
  parametersValues: PropTypes.object,
  selectedCommand: PropTypes.object,
  setCommand: PropTypes.func,
  setDialogTitle: PropTypes.func.isRequired,
  setParametersValues: PropTypes.func
};

export default SelectCommand;
