import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import columnDefs from '../../../utils/agGridUtils/columnDefs';
import langs from '../../../localization/langs';
import models from '../../../models';

import AgTable from '../../../components/AGTable/Table';
import Column from '../../../components/AGTable/Column';
import useUrlSearch from '../../AGTable/hooks/useUrlSearch';

function Table({getRows, columns}) {
  const [tableApi, setTableApi] = React.useState();
  const [filterModel, onFilterChanged] = useUrlSearch(tableApi);

  return (
    <AgTable
      title={langs('CONFIGURATION')}
      data={getRows}
      filterModel={filterModel}
      onFilterChanged={onFilterChanged}
      pagination
      setTableApi={setTableApi}
    >
      <Column
        {...columnDefs.BASIC_COL}
        colId={models.configuration.FIELDS.KEY}
        title={langs('KEY')}
        value={models.configuration.FIELDS.KEY}
      />
      <Column
        {...columnDefs.BASIC_COL}
        colId={models.configuration.FIELDS.KEY_CATEGORY}
        title={langs('KEY_CATEGORY')}
        value={models.configuration.FIELDS.KEY_CATEGORY}
      />
      <Column
        {...columnDefs.BASIC_COL}
        colId="keySubCategory"
        title={langs('KEY_SUB_CATEGORY')}
        value={models.configuration.FIELDS.KEY_SUB_CATEGORY}
      />
      <Column
        {...columnDefs.BASIC_COL}
        colId={models.configuration.FIELDS.TYPE}
        title={langs('TYPE')}
        value={models.configuration.FIELDS.TYPE}
      />
      <Column
        hide
        {...columnDefs.BASIC_COL}
        colId={models.configuration.FIELDS.COMMENT}
        title={langs('COMMENT')}
        value={models.configuration.FIELDS.COMMENT}
      />
      <Column
        {...columnDefs.DATE_COL}
        colId={models.configuration.FIELDS.CREATION_DATE}
        title={langs('CREATION_DATE')}
        value={models.configuration.FIELDS.CREATION_DATE}
      />
      <Column
        hide
        {...columnDefs.BASIC_COL}
        colId={models.configuration.FIELDS.CONFIGURATION_VALUE_ID}
        title={langs('CONFIGURATION_VALUE_ID')}
        value={models.configuration.FIELDS.CONFIGURATION_VALUE_ID}
      />
      <Column
        {...columnDefs.DATE_COL}
        colId={models.configuration.FIELDS.MODIFICATION_DATE}
        title={langs('MODIFICATION_DATE')}
        value={models.configuration.FIELDS.MODIFICATION_DATE}
      />
      <Column
        {...columnDefs.BASIC_COL}
        colId={models.configuration.FIELDS.INHERITANCE_LEVEL}
        title={langs('INHERITANCE_LEVEL')}
        value={models.configuration.FIELDS.INHERITANCE_LEVEL}
      />
      <Column
        {...columnDefs.BASIC_COL}
        colId={models.configuration.FIELDS.VALUE_AS_STRING}
        title={langs('VALUE')}
        value={models.configuration.FIELDS.VALUE_AS_STRING}
      />
      {_.map(columns, (column) => (
        <Column
          {...columnDefs.BASIC_COL}
          colId={column}
          title={_.startCase(column)}
          value={_.get(models.configuration.FIELDS, _.toUpper(_.snakeCase(column)))}
        />
      ))}
    </AgTable>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  getRows: PropTypes.func.isRequired
};

export default Table;
