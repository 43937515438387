import consts from '../../../../consts';

import {envAwareHasPermission, getEnvNameFromLabels, hasAllPermission} from './common';

const PERMISSIONS = consts.rbac.permissions.mtms;

function getDirectAccessors(mtmsAccessors) {
  return {
    allowed: {
      configuration: {
        create: {
          all: {
            all: () =>
              hasAllPermission(
                mtmsAccessors.hasPermission,
                PERMISSIONS.configuration.create.all.all,
                mtmsAccessors.allEnvs
              ),
            envs: (labels) =>
              mtmsAccessors.hasPermission(PERMISSIONS.configuration.create.all.envs, getEnvNameFromLabels(labels)),
            namespaces: (labels) =>
              mtmsAccessors.hasPermission(
                PERMISSIONS.configuration.create.all.namespaces,
                getEnvNameFromLabels(labels)
              ),
            services: () => mtmsAccessors.hasPermissions(PERMISSIONS.configuration.create.all.services)
          },
          create: (labels) =>
            envAwareHasPermission(
              mtmsAccessors.hasPermission,
              PERMISSIONS.configuration.create.create,
              labels,
              mtmsAccessors.allEnvs
            )
        },
        delete: {
          all: {
            all: () =>
              hasAllPermission(
                mtmsAccessors.hasPermission,
                PERMISSIONS.configuration.delete.all.all,
                mtmsAccessors.allEnvs
              ),
            envs: (labels) =>
              mtmsAccessors.hasPermission(PERMISSIONS.configuration.delete.all.envs, getEnvNameFromLabels(labels)),
            namespaces: (labels) =>
              mtmsAccessors.hasPermission(
                PERMISSIONS.configuration.delete.all.namespaces,
                getEnvNameFromLabels(labels)
              ),
            services: () => mtmsAccessors.hasPermissions(PERMISSIONS.configuration.delete.all.services)
          },
          delete: (labels) =>
            envAwareHasPermission(
              mtmsAccessors.hasPermission,
              PERMISSIONS.configuration.delete.delete,
              labels,
              mtmsAccessors.allEnvs
            )
        },
        edit: {
          all: {
            all: () =>
              hasAllPermission(
                mtmsAccessors.hasPermission,
                PERMISSIONS.configuration.edit.all.all,
                mtmsAccessors.allEnvs
              ),
            envs: (labels) =>
              mtmsAccessors.hasPermission(PERMISSIONS.configuration.edit.all.envs, getEnvNameFromLabels(labels)),
            namespaces: (labels) =>
              mtmsAccessors.hasPermission(PERMISSIONS.configuration.edit.all.namespaces, getEnvNameFromLabels(labels)),
            services: () => mtmsAccessors.hasPermissions(PERMISSIONS.configuration.edit.all.services)
          },
          edit: (labels) =>
            envAwareHasPermission(
              mtmsAccessors.hasPermission,
              PERMISSIONS.configuration.edit.edit,
              labels,
              mtmsAccessors.allEnvs
            )
        },
        read: {
          all: {
            all: () => hasAllPermission(mtmsAccessors.hasPermission, PERMISSIONS.configuration.read.all.all),
            envs: (labels) =>
              mtmsAccessors.hasPermission(PERMISSIONS.configuration.read.all.envs, getEnvNameFromLabels(labels)),
            namespaces: (labels) =>
              mtmsAccessors.hasPermission(PERMISSIONS.configuration.read.all.namespaces, getEnvNameFromLabels(labels)),
            services: () => mtmsAccessors.hasPermissions(PERMISSIONS.configuration.read.all.services)
          },
          read: (labels) =>
            envAwareHasPermission(
              mtmsAccessors.hasPermission,
              PERMISSIONS.configuration.read.read,
              labels,
              mtmsAccessors.allEnvs
            )
        }
      },
      namespaces: {
        create: () => mtmsAccessors.hasPermission(PERMISSIONS.namespaces.create),
        edit: () => mtmsAccessors.hasPermission(PERMISSIONS.namespaces.edit)
      },
      stacks: {
        create: () => mtmsAccessors.hasPermission(PERMISSIONS.stacks.create),
        edit: () => mtmsAccessors.hasPermission(PERMISSIONS.stacks.edit)
      }
    }
  };
}

export default {
  getDirectAccessors
};
