import React from 'react';
import PropTypes from 'prop-types';

import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';

import langs from '../../../localization/langs';

import {IconButton} from '../../../components/Base';

import * as Styled from './SeedActions.styled';

const ACTIVE_STATUS = new Set(['SEED_STATUS_SEEDING']);

function SeedActions({value, onClick}) {
  const isSeeding = ACTIVE_STATUS.has(value.status);
  return (
    <Styled.Wrapper>
      <IconButton
        disabled={isSeeding}
        title={langs('DEMO_ACTIONS_NEW_SEED')}
        icon={PlayCircleFilledWhiteOutlinedIcon}
        onClick={() => onClick(value, true)}
      />
      {value.status && (
        <IconButton
          disabled={isSeeding}
          title={langs('DEMO_ACTIONS_RESEED')}
          icon={ReplayOutlinedIcon}
          onClick={() => onClick(value, false)}
        />
      )}
    </Styled.Wrapper>
  );
}

SeedActions.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired
};

export default SeedActions;
