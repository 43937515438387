import React from 'react';

import AddIcon from '@material-ui/icons/Add';

import columnDefs from '../../../utils/agGridUtils/columnDefs';
import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import models from '../../../models';
import tableUtils from '../table.utils';
import useUrlSearch from '../../../components/AGTable/hooks/useUrlSearch';

import AGTable from '../../../components/AGTable/Table';
import {Button} from '../../../components/Base';
import Column from '../../../components/AGTable/Column';
import NamespacePreview from '../../../components/Services/NamespacePreview';
import OpsLink from '../../../components/OpsLink';
import ProtectedComponent from '../../../components/Rbac/ProtectedComponent';

import * as Styled from './NamespacesView.styles';

const SET_FILTER_COL = {
  ...columnDefs.BASIC_COL,
  filter: async ({colDef, success}) => success(await tableUtils.getNamespacesFilters(colDef)),
  filterParams: {refreshValuesOnOpen: false}
};

async function getRows(params) {
  params.success(await tableUtils.getNamespaces(params.api, params.request));
}

function NamespacesView() {
  const [tableApi, setTableApi] = React.useState();
  const [namespacePreview, setNamespacePreview] = React.useState();

  const [filterModel, onFilterChanged] = useUrlSearch(tableApi);

  function closePreview() {
    setNamespacePreview(null);
  }

  const user = context.User.useUser();

  return (
    <div>
      <Styled.Drawer open={Boolean(namespacePreview)} onClose={closePreview}>
        <ProtectedComponent
          permissions={[consts.rbac.permissions.mtms.namespaces.edit]}
          rbacControlObj={user.rbac.mtms}
        >
          <NamespacePreview
            environmentId={namespacePreview?.environmentId}
            namespaceId={namespacePreview?.id}
            onClose={closePreview}
          />
        </ProtectedComponent>
      </Styled.Drawer>
      <Styled.ActionContainer>
        <ProtectedComponent
          permissions={[consts.rbac.permissions.mtms.namespaces.edit]}
          rbacControlObj={user.rbac.mtms}
        >
          <OpsLink to="services.namespacesAdd">
            <Button text icon={<AddIcon />}>
              {langs('NAMESPACES_NEW_NAMESPACE')}
            </Button>
          </OpsLink>
        </ProtectedComponent>
      </Styled.ActionContainer>
      <Styled.StyledPaper>
        <AGTable
          data={getRows}
          filterModel={filterModel}
          onFilterChanged={onFilterChanged}
          onRowClicked={({data}) => user.rbac.mtms.allowed.namespaces.edit() && setNamespacePreview(data)}
          pagination
          setTableApi={setTableApi}
          title={langs('NAMESPACES')}
        >
          <Column
            {...columnDefs.NUMBER_COL}
            colId="namespaceId"
            title={langs('NAMESPACES_FIELD_ID')}
            value={models.namespaces.fields.ID}
          />
          <Column
            {...SET_FILTER_COL}
            colId="namespaceName"
            title={langs('NAMESPACES_FIELD_NAME')}
            value={models.namespaces.fields.NAME}
          />
          <Column
            {...SET_FILTER_COL}
            colId="environmentName"
            title={langs('NAMESPACES_FIELD_ENV_NAME')}
            value={models.namespaces.fields.ENVIRONMENT_NAME}
          />
        </AGTable>
      </Styled.StyledPaper>
    </div>
  );
}

export default NamespacesView;
