import _ from 'lodash';
import React from 'react';
import {useHistory} from 'react-router-dom';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import context from '../../../context';
import langs from '../../../localization/langs';

import IconedLabel from '../../Base/IconedLabel';

function AccountCircle() {
  const history = useHistory();
  const {getUrl} = context.Navigation.useNavigation();
  const user = context.User.useUser();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = !_.isNil(anchorEl);

  function handleClick(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event) {
    event.stopPropagation();
    setAnchorEl(null);
  }

  function navigate(url) {
    setAnchorEl(null);
    history.push(url);
  }

  const allowedEditSchemaView = user.rbac.opsWeb.isEnabled() && user.rbac.rbac.allowed.schema.edit();
  const allowedFullSchemaView = user.rbac.opsWeb.isEnabled() && user.rbac.rbac.allowed.schema.export();

  return (
    <div>
      <AccountCircleIcon onClick={handleClick} size="large" />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '22ch'
          }
        }}
      >
        <MenuItem onClick={() => navigate(getUrl('rbac.self'))}>
          <IconedLabel icon={<AccountTreeIcon />} lowProfile>
            {langs('RBAC_SCHEMA_SELF_VIEW')}
          </IconedLabel>
        </MenuItem>
        {allowedFullSchemaView && (
          <MenuItem onClick={() => navigate(getUrl('rbac.schema'))}>
            <IconedLabel icon={<AccountTreeIcon />} lowProfile>
              {langs('RBAC_SCHEMA_VIEWER')}
            </IconedLabel>
          </MenuItem>
        )}
        {allowedEditSchemaView && (
          <MenuItem onClick={() => navigate(getUrl('rbac.editor'))}>
            <IconedLabel icon={<AccountTreeIcon />} lowProfile>
              {langs('RBAC_SCHEMA_EDITOR')}
            </IconedLabel>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default AccountCircle;
