import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import * as Router from 'react-router-dom';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import context from '../../../context';
import langs from '../../../localization/langs';

import * as Styled from './Search.styles';

const SimpleSearch = React.forwardRef(({onClick}, ref) => {
  const [searchInputValue, setSearchInputValue] = React.useState('');
  const [isShowShortcut, setIsShowShortcut] = React.useState(true);

  const location = Router.useLocation();
  const {isSearchDisabled, queryDescription, onTextSearch} = context.Search.useSearch();

  React.useEffect(() => {
    setSearchInputValue('');
  }, [location.pathname]);

  const handleSubmit = (event) => {
    event.preventDefault();
    onTextSearch.current(searchInputValue);
    onClick(true);
  };

  if (isSearchDisabled) {
    return (
      <Styled.Form onSubmit={_.stubObject} onClick={() => onClick()}>
        <Styled.Input key="mock-input" type="text" placeholder={langs('SEARCH_WITH_DOTS')} disabled />
      </Styled.Form>
    );
  }

  return (
    <Styled.Form onSubmit={handleSubmit} onClick={() => onClick()}>
      <Styled.Input
        type="text"
        placeholder={_.isEmpty(queryDescription) ? langs('SEARCH_WITH_DOTS') : queryDescription}
        value={searchInputValue}
        onChange={(event) => setSearchInputValue(event.currentTarget.value)}
        ref={ref}
        isShowShortcut={isShowShortcut}
        onFocus={() => setIsShowShortcut(false)}
        onBlur={() => setIsShowShortcut(true)}
      />
      {isShowShortcut && <Styled.ShortcutText>[Command + K]</Styled.ShortcutText>}
      <Styled.IconButton>
        <ExpandMoreIcon fontSize="large" />
      </Styled.IconButton>
    </Styled.Form>
  );
});

SimpleSearch.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default SimpleSearch;
