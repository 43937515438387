import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import consts from '../consts';
import context from '../context';
import enums from '../components/Configuration/enums';
import hooks from '../hooks';
import langs from '../localization/langs';
import models from '../models';
import services from '../services';
import utils from '../components/Configuration/utils';
import withTenants from '../withTenants.hoc';

import CollectorsArtifactInput from '../components/Collectors/CollectorsArtifactInput';
import Configuration from '../components/Configuration/Configuration';

const CATEGORY_PERMISSIONS_CREATE = consts.rbac.permissions.cms.collectors.configuration.create.categories;
const CATEGORY_PERMISSIONS_EDIT = consts.rbac.permissions.cms.collectors.configuration.edit.categories;
const CATEGORY_PERMISSIONS_READ = consts.rbac.permissions.cms.collectors.configuration.read.categories;

const categories = [
  {
    id: 'FIRMWARE',
    name: langs('FIRMWARE_CONFIGURATION'),
    permissions: {
      create: CATEGORY_PERMISSIONS_CREATE.firmware,
      edit: CATEGORY_PERMISSIONS_EDIT.firmware,
      read: CATEGORY_PERMISSIONS_READ.firmware
    }
  },
  {
    id: 'APP_DEPLOYMENT',
    name: langs('APP_DEPLOYMENT_CONFIGURATION'),
    permissions: {
      create: CATEGORY_PERMISSIONS_CREATE.appDeployment,
      edit: CATEGORY_PERMISSIONS_EDIT.appDeployment,
      read: CATEGORY_PERMISSIONS_READ.appDeployment
    }
  },
  {
    id: 'CMS',
    name: langs('CMS_CONFIGURATION'),
    permissions: {
      create: CATEGORY_PERMISSIONS_CREATE.cms,
      edit: CATEGORY_PERMISSIONS_EDIT.cms,
      read: CATEGORY_PERMISSIONS_READ.cms
    }
  },
  {
    id: 'PROVISION_PARAMS',
    name: langs('PROVISION_PARAMS_CONFIGURATION'),
    permissions: {
      create: CATEGORY_PERMISSIONS_CREATE.provision,
      edit: CATEGORY_PERMISSIONS_EDIT.provision,
      read: CATEGORY_PERMISSIONS_READ.provision
    }
  }
];

const extendedTypes = {
  [models.configuration.TYPES.ARTIFACT_ID]: CollectorsArtifactInput
};

function CollectorsConfiguration({tenants, isLoadingTenants}) {
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [collectors, setCollectors] = React.useState([]);
  const [configuration, setConfiguration] = React.useState([]);

  const [category, setCategory] = hooks.useSearchQueryFilter(
    'category',
    consts.FILTER_OPERATORS.EQUAL,
    _.head(categories).id
  );
  const [tenantId, setTenantId] = hooks.useSearchQueryFilter('tenantId', consts.FILTER_OPERATORS.EQUAL);
  const [collectorId, setCollectorId] = hooks.useSearchQueryFilter('collectorId', consts.FILTER_OPERATORS.EQUAL);

  const notifications = context.Notifications.useNotifications();
  const user = context.User.useUser();

  React.useEffect(() => {
    onTenantSelect(collectorId);
    setIsFirstLoad(false);
  }, []);

  React.useEffect(() => {
    if (isFirstLoad) {
      return;
    }
    onTenantSelect();
  }, [tenantId]);

  React.useEffect(() => {
    if (isFirstLoad) {
      return;
    }
    getConfiguration();
  }, [category, collectorId]);

  const wrappedCategories = React.useMemo(
    () =>
      _.map(categories, (category) =>
        _.assign(
          {
            permissionFuncs: {
              create: (labels) => user.rbac.cms.hasConfigPermissions(category.permissions.create, labels),
              edit: (labels) => user.rbac.cms.hasConfigPermissions(category.permissions.edit, labels),
              read: (labels) => user.rbac.cms.hasConfigPermissions(category.permissions.read, labels)
            }
          },
          category
        )
      ),
    [user.rbac.cms]
  );

  async function onTenantSelect(selectedCollectorId = null) {
    setCollectorId(selectedCollectorId);

    try {
      setIsLoading(true);
      const [newCollectors, newConfiguration] = await Promise.all([
        getCollectors(),
        services.collectors.getConfiguration(category, tenantId, selectedCollectorId)
      ]);

      const collectorItems = _.get(newCollectors, 'data.items', []);
      setCollectors(
        _.map(collectorItems, ({collectorId, collectorNumber}) => ({
          id: collectorId,
          name: collectorNumber
        }))
      );

      const configurationItems = _.get(newConfiguration, 'data.items', []);
      setConfiguration(_.map(configurationItems, (item) => _.defaults({value: JSON.parse(item.value)}, item)));
    } catch (ex) {
      setCollectors([]);
      setConfiguration([]);
      notifications.error(ex.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSaveConfiguration(operations) {
    const promises = _.map(operations, (operation) => {
      if (operation.operation === enums.OPERATIONS.DELETE) {
        return services.collectors.deleteConfigurationValue(operation.configurationValueId);
      }
      if (operation.operation === enums.OPERATIONS.UPDATE) {
        return services.collectors.updateConfiguration(operation.configurationValueId, operation.value);
      }
      if (operation.operation === enums.OPERATIONS.CREATE) {
        return services.collectors.createConfigurationValue(operation);
      }
    });

    try {
      await Promise.all(promises);
      await getConfiguration();
    } catch (ex) {
      notifications.error(ex.message);
    }
  }

  async function onAddConfigurationKey(...params) {
    try {
      await services.collectors.createConfigurationKey(...params);
      return getConfiguration();
    } catch (ex) {
      console.error(ex.stack);
      notifications.error(ex.message);
    }
  }

  async function onDeleteConfigurationKeyValue(configurationValueId) {
    try {
      await services.collectors.deleteConfigurationValue(configurationValueId);
      return getConfiguration();
    } catch (ex) {
      console.error(ex.stack);
      notifications.error(ex.message);
    }
  }

  async function onGetConfigurationKeyVersions(configurationKeyId) {
    try {
      const {
        data: {items: versions}
      } = await services.collectors.getVersions(tenantId, collectorId, configurationKeyId);
      return _.map(versions, (version) =>
        _.defaults(
          {
            level: utils.getLevelFromLabels(labelDefinitions, version.labels),
            value: _.isEmpty(version.value) ? '' : JSON.parse(version.value)
          },
          version
        )
      );
    } catch (ex) {
      console.error(ex.stack);
      notifications.error(ex.message);
    }
  }

  async function getCollectors() {
    if (_.isEmpty(tenantId)) {
      return [];
    }
    return services.collectors.getCollectors(tenantId);
  }

  async function getConfiguration() {
    try {
      setIsLoading(true);
      const response = await services.collectors.getConfiguration(category, tenantId, collectorId);
      const items = _.get(response, 'data.items', []);
      setConfiguration(_.map(items, (item) => _.defaults({value: JSON.parse(item.value)}, item)));
    } catch (ex) {
      setConfiguration([]);
      notifications.error(ex.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function getAffectedCollectors() {
    const response = await getCollectors();
    return {
      displayValues: _.map(response?.data?.items, (collector) => _.pick(collector, ['tenantName', 'collectorNumber'])),
      entities: response?.data?.items
    };
  }

  async function getConfigurationKeys(keyName) {
    const {
      data: {items}
    } = await services.collectors.getConfigurationKeys(keyName);
    return _.map(items, (item) => _.assign({name: item.key}, item));
  }

  if (_.isEmpty(tenants)) {
    return null;
  }

  const tenant = _.find(tenants, {id: tenantId});
  const collector = _.find(collectors, {id: collectorId});
  const labelDefinitions = [
    {
      defaultPermissionsFunc: user.rbac.cms.allowed.collectors.configuration.edit.all.all,
      key: 'tenantId',
      label: 'TENANT',
      onChange: setTenantId,
      options: tenants,
      permissionsFunc: user.rbac.cms.allowed.collectors.configuration.edit.all.tenants,
      value: tenant,
      width: 2
    },
    {
      key: 'collectorId',
      label: 'COLLECTOR',
      onChange: setCollectorId,
      options: collectors,
      permissionsFunc: user.rbac.cms.allowed.collectors.configuration.edit.all.collectors,
      value: collector,
      width: 1
    }
  ];

  return (
    <Configuration
      categories={wrappedCategories}
      configuration={configuration}
      extendedTypes={extendedTypes}
      getAffectedEntities={getAffectedCollectors}
      getConfigurationKeys={getConfigurationKeys}
      isLoading={isLoading || isLoadingTenants}
      labelDefinitions={labelDefinitions}
      onAddConfigurationKey={onAddConfigurationKey}
      onChangeCategory={setCategory}
      onDeleteConfigurationKeyValue={onDeleteConfigurationKeyValue}
      onGetConfigurationKeyVersions={onGetConfigurationKeyVersions}
      onSaveConfiguration={onSaveConfiguration}
      permissionFuncs={{
        create: user.rbac.cms.allowed.collectors.configuration.create.create,
        edit: user.rbac.cms.allowed.collectors.configuration.edit.edit,
        read: user.rbac.cms.allowed.collectors.configuration.read.read
      }}
      selectedCategory={category}
    />
  );
}

CollectorsConfiguration.propTypes = {
  isLoadingTenants: PropTypes.bool.isRequired,
  tenants: PropTypes.array.isRequired
};

export default withTenants(CollectorsConfiguration);
