import PropTypes from 'prop-types';
import React from 'react';

import * as Styled from './Table.styles';

function Table({data}) {
  return (
    <div>
      {Object.entries(data).map(([key, value], i) => (
        <Styled.Row key={i}>
          <Styled.Key>{key}</Styled.Key>
          <span>{isNumeric(value) ? parseInt(value, 10).toLocaleString() : value}</span>
        </Styled.Row>
      ))}
    </div>
  );
}

function isNumeric(val) {
  return /^-?\d+$/.test(val);
}

Table.propTypes = {
  data: PropTypes.object.isRequired
};

export default Table;
