import styled from 'styled-components';

import LinkIcon from '@material-ui/icons/Link';

import * as theme from '../../../../theme';

export const Container = styled.div`
  display: flex;
`;

export const CopyButton = styled(LinkIcon)`
  margin-left: 5px;
  margin-top: 1px;
  font-size: 16px;
  color: ${theme.statusColors.info};
  &:hover {
    color: inherit;
  }
`;
