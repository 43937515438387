import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import ConfirmationModal from './ConfirmationModal';

import * as Styled from './ConfirmationTextboxModal.styles';

function ConfirmationTextboxModal({open, onCancel, onConfirm, title, description, matchText}) {
  const [value, setValue] = React.useState('');
  const [isError, setIsError] = React.useState(false);

  function onTextChange(e) {
    setIsError(false);
    setValue(e.target.value);
  }

  async function onConfirmClick(event) {
    if (_.isEmpty(value) || _.toLower(value) !== matchText) {
      setIsError(true);
      return false;
    }
    setIsError(false);
    return onConfirm(event);
  }

  return (
    <ConfirmationModal
      onCancel={onCancel}
      open={open}
      onConfirm={onConfirmClick}
      title={title}
      description={description}
    >
      <Styled.StyledInput type="text" value={value} onChange={onTextChange} $isError={isError} />
    </ConfirmationModal>
  );
}

ConfirmationTextboxModal.propTypes = {
  description: PropTypes.string,
  matchText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string
};

export default ConfirmationTextboxModal;
