import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import AddIcon from '@material-ui/icons/Add';

import context from '../../context';
import langs from '../../localization/langs';
import roles from '../../roles';

import AddConfigurationKey from './AddConfigurationKey/AddConfigurationKey';
import {ConfirmationModal, InternalNavBar} from '../Base';

import * as Styled from './ConfigurationActions.styles';

const NO_VALID_CATEGORIES = [{id: 'INVALID', name: 'No Allowed Categories'}];

function createTitle(levels, selectedValues) {
  const values = _(selectedValues)
    .map('value')
    .reject(_.isEmpty)
    .value();

  if (_.isEmpty(values)) {
    return `${langs('CONFIGURE_ALL')} ${_.toLower(levels[1])}s`;
  }
  if (_.size(values) === 1) {
    const firstValue = _.head(values);
    return `${langs('CONFIGURE')} ${_.get(firstValue, 'name')} ${_.toLower(levels[1])}`;
  }
  return `${langs('CONFIGURE')} ${_(_.reverse(values))
    .map((value, idx) => `${_.get(value, 'name')} ${_.toLower(levels[_.size(levels) - idx - 1])}`)
    .join(` ${langs('OF')} `)}`;
}

function isAllSelected(selectedValues) {
  const values = _.map(selectedValues, 'value');
  return _.some(values, _.isEmpty);
}

function ConfigurationActions({
  categories,
  displaySettings,
  getAffectedEntities,
  getConfigurationKeys,
  levels,
  onAddConfigurationKey,
  onChangeCategory,
  onSave,
  operations,
  permissionFuncs,
  selectedCategory,
  selectedValues
}) {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [isAddKeyOpen, setIsAddKeyOpen] = React.useState(false);

  const user = context.User.useUser();

  const allSelected = isAllSelected(selectedValues);
  const hasManagePermissions = permissionFuncs
    ? permissionFuncs.create(selectedValues)
    : user.hasRole(roles.MANAGE_CONFIGURATIONS);

  const validCategories = _.filter(categories, (category) =>
    category.permissionFuncs ? category.permissionFuncs.create(selectedValues) : true
  );

  const navCategories = _.map(categories, (category) => ({
    disabled: !category.permissionFuncs.read(selectedValues),
    id: category.id,
    name: category.name
  }));

  return (
    <Styled.Container container spacing={0}>
      <ConfirmationModal
        open={isConfirmModalOpen}
        description={createTitle(levels, selectedValues)}
        disableAdd={_.isEmpty(validCategories)}
        onConfirm={() => onSave(operations)}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
      {_.isFunction(onAddConfigurationKey) && (
        <AddConfigurationKey
          value={isAddKeyOpen}
          onClose={() => setIsAddKeyOpen(false)}
          onChange={onAddConfigurationKey}
          categories={_.isEmpty(validCategories) ? NO_VALID_CATEGORIES : validCategories}
          levels={levels}
          displaySettings={displaySettings}
          selectedValues={selectedValues}
          getAffectedEntities={getAffectedEntities}
          getConfigurationKeys={getConfigurationKeys}
        />
      )}
      <Styled.Title item></Styled.Title>
      <Styled.ActionsWrapper item>
        {_.isFunction(onAddConfigurationKey) && (
          <Styled.AddParameterButton
            disabled={!hasManagePermissions}
            onClick={() => setIsAddKeyOpen(true)}
            startIcon={<AddIcon />}
          >
            {langs('ADD_NEW_CONFIGURATION')}
          </Styled.AddParameterButton>
        )}
        <Styled.ApplyButton
          $allselected={allSelected}
          disabled={!hasManagePermissions}
          onClick={() => setIsConfirmModalOpen(true)}
        >
          {langs('BUTTON_SAVE_CHANGES')}
        </Styled.ApplyButton>
      </Styled.ActionsWrapper>
      <Styled.NavBarWrapper item xs={12}>
        <InternalNavBar options={navCategories} all={false} value={selectedCategory} onChange={onChangeCategory} />
      </Styled.NavBarWrapper>
    </Styled.Container>
  );
}

ConfigurationActions.propTypes = {
  categories: PropTypes.array.isRequired,
  displaySettings: PropTypes.shape({
    showAddNewKeyDefaultValue: PropTypes.bool,
    showAllLabel: PropTypes.bool,
    showConfigurationKeySearch: PropTypes.bool,
    showCreatedByCol: PropTypes.bool,
    showEmptyFilter: PropTypes.bool,
    showInheritCol: PropTypes.bool,
    showLastUpdateCol: PropTypes.bool,
    titleizeSubCategories: PropTypes.bool
  }),
  getAffectedEntities: PropTypes.func.isRequired,
  getConfigurationKeys: PropTypes.func.isRequired,
  levels: PropTypes.array.isRequired,
  onAddConfigurationKey: PropTypes.func,
  onChangeCategory: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  operations: PropTypes.array.isRequired,
  permissionFuncs: PropTypes.shape({
    create: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    read: PropTypes.func.isRequired
  }),
  selectedCategory: PropTypes.string.isRequired,
  selectedValues: PropTypes.array.isRequired
};

export default ConfigurationActions;
