import PropTypes from 'prop-types';
import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';

import langs from '../../localization/langs';

import * as Styled from './TaskDialogActions.styles';

function TaskDialogActions({onCancel, onNext, onBack, isAllowedNextStep = true, labelNext = langs('BUTTON_NEXT')}) {
  return (
    <DialogActions disableSpacing>
      <Styled.Container>
        <Styled.GrowingGrid>
          <Styled.CancelButton onClick={onCancel}>Cancel</Styled.CancelButton>
        </Styled.GrowingGrid>
        <Grid item>
          {onBack && <Styled.BackButton onClick={onBack}>{langs('BUTTON_BACK')}</Styled.BackButton>}
          {onNext && (
            <Styled.NextButton onClick={onNext} disabled={!isAllowedNextStep}>
              {labelNext}
            </Styled.NextButton>
          )}
        </Grid>
      </Styled.Container>
    </DialogActions>
  );
}

TaskDialogActions.propTypes = {
  isAllowedNextStep: PropTypes.bool,
  labelNext: PropTypes.string,
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
  onNext: PropTypes.func
};

export default TaskDialogActions;
