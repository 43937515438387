import React from 'react';
import {Events as AgEvents} from 'ag-grid-community';

import ZoomOutMapOutlinedIcon from '@material-ui/icons/ZoomOutMapOutlined';

import langs from '../../../localization/langs';

import Action from '../Action';

function canBeExpanded(node) {
  return node.group && !node.expanded;
}

function isExpanded(node) {
  return node.group && node.expanded;
}

function shouldAutoExpand(node) {
  return node.key?.count === 1;
}

export default function useAutoExpand(grouped, gridApi) {
  const expandSingles = React.useCallback(() => {
    gridApi.forEachNode((node) => {
      if (canBeExpanded(node) && shouldAutoExpand(node)) {
        node.setExpanded(true);
      }
    });
  }, [gridApi]);

  React.useEffect(() => {
    if (!gridApi) {
      return;
    }

    function onStoreUpdate() {
      gridApi.forEachNode((node) => {
        const {parent} = node;
        if (!isExpanded(parent) || !shouldAutoExpand(parent)) {
          return;
        }
        if (node.childStore || !canBeExpanded(node) || !shouldAutoExpand(node)) {
          return;
        }
        node.setExpanded(true);
      });
    }

    if (grouped) {
      gridApi.eventService.addEventListener(AgEvents.EVENT_STORE_UPDATED, onStoreUpdate);
    } else {
      gridApi.eventService.removeEventListener(AgEvents.EVENT_STORE_UPDATED, onStoreUpdate);
    }

    return () => gridApi.eventService.removeEventListener(AgEvents.EVENT_STORE_UPDATED, onStoreUpdate);
  }, [grouped, gridApi]);

  if (!grouped) {
    return null;
  }

  return (
    <Action
      title={langs('EXPAND')}
      tooltip={langs('EXPAND_SINGLES')}
      icon={ZoomOutMapOutlinedIcon}
      onClick={expandSingles}
    />
  );
}
