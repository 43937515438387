import styled from 'styled-components';

import MuiButton from '@material-ui/core/Button';

import * as theme from '../../../../theme';

export const Container = styled.div`
  display: flex;
  justify-content: right;
  padding: 5px 30px 15px 30px;
`;

export const ActionButton = styled(MuiButton).attrs({size: 'small', variant: 'text'})`
  margin-right: 10px;
  border: 1px solid ${theme.text.primary};
`;

export const SaveButton = styled(MuiButton).attrs((props) => ({
  color: 'secondary',
  size: 'small',
  variant: 'contained'
}))`
  color: ${theme.buttons.normal.text};
  background-color: ${theme.statusColors.error};
  &:hover {
    background-color: ${theme.statusColors.error};
  }
`;
