import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import consts from './consts';
import context from './context';

import Beacons from './views/Beacons';
import CherryPicksView from './views/Releases/CherryPicksView';
import CollectorsConfiguration from './views/CollectorsConfiguration';
import CollectorsTasks from './views/Tasks/CollectorsTasks';
import CollectorsView from './views/Collectors/CollectorsView';
import DemoView from './views/Demo/DemoView';
import DeploymentsView from './views/Services/Deployments/DeploymentsView';
import GithubAuthWrapper from './components/GitHub/GithubAuthWrapper';
import Images from './views/Images';
import MultiTenantTasks from './views/Tasks/MultiTenantTasks';
import NamespaceAdd from './views/Services/Namespaces/NamespaceAdd';
import NamespacesView from './views/Services/Namespaces/NamespacesView';
import NotFound from './views/NotFound';
import ProtectedView from './components/Rbac/ProtectedView';
import ReleaseRequest from './views/Releases/Add/ReleaseRequest';
import ReleasesView from './views/Releases/ReleasesView';
import SchemaEditorView from './views/Rbac/SchemaEditorView';
import SchemaView from './views/Rbac/SchemaView';
import SelfView from './views/Rbac/SelfView';
import ServiceAdd from './views/Services/Services/ServiceAdd';
import ServicesConfiguration from './views/Services/Configuration/ServicesConfiguration';
import ServicesView from './views/Services/Services/ServicesView';
import StackAdd from './views/Services/Stacks/StackAdd';
import StacksView from './views/Services/Stacks/StacksView';
import Tenant from './views/Tenants/Tenant';
import TenantAdd from './views/Tenants/Add/TenantAdd';
import TenantsConfiguration from './views/TenantsConfiguration';
import TenantsTableConfiguration from './views/TenantsTableConfiguration';
import TenantsFlow from './views/Tenants/DataFlow/TenantsFlow';
import TenantsTasks from './views/Tasks/TenantsTasks';
import TenantsView from './views/Tenants/TenantsView';

function Routes() {
  const {getUrl} = context.Navigation.useNavigation();
  const user = context.User.useUser();

  const rbacPermissions = consts.rbac.permissions;
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={getUrl('tenants.main')} />
      </Route>

      <Route exact path="/collectors">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.collectors.view]} rbacControlObj={user.rbac.opsWeb}>
          <CollectorsView />
        </ProtectedView>
      </Route>
      <Route exact path="/collectors/tasks">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.collectors.tasks]} rbacControlObj={user.rbac.opsWeb}>
          <CollectorsTasks />
        </ProtectedView>
      </Route>
      <Route exact path="/collectors/beacons">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.collectors.beacons]} rbacControlObj={user.rbac.opsWeb}>
          <Beacons />
        </ProtectedView>
      </Route>
      <Route exact path="/collectors/images">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.collectors.images]} rbacControlObj={user.rbac.opsWeb}>
          <Images />
        </ProtectedView>
      </Route>
      <Route path="/collectors/configuration">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.collectors.config]} rbacControlObj={user.rbac.opsWeb}>
          <CollectorsConfiguration />
        </ProtectedView>
      </Route>

      <Route exact path="/tenants">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.tenants.view]} rbacControlObj={user.rbac.opsWeb}>
          <TenantsView />
        </ProtectedView>
      </Route>
      <Route exact path="/tenants/tasks">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.tenants.tasks]} rbacControlObj={user.rbac.opsWeb}>
          <TenantsTasks />
        </ProtectedView>
      </Route>
      <Route exact path="/tenants/flow/:tenantId">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.tenants.view]} rbacControlObj={user.rbac.opsWeb}>
          <TenantsFlow />
        </ProtectedView>
      </Route>
      <Route exact path="/tenants/add/:cluster?">
        <ProtectedView permissions={[rbacPermissions.maestro.tenant.create]} rbacControlObj={user.rbac.maestro}>
          <TenantAdd />
        </ProtectedView>
      </Route>
      <Route exact path="/tenants/configuration">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.tenants.config]} rbacControlObj={user.rbac.opsWeb}>
          <TenantsConfiguration />
        </ProtectedView>
      </Route>
      <Route exact path="/tenants/configuration/table">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.tenants.config]} rbacControlObj={user.rbac.opsWeb}>
          <TenantsTableConfiguration />
        </ProtectedView>
      </Route>
      <Route path="/tenants/:tenantId">
        <ProtectedView permissions={[rbacPermissions.maestro.tenant.read]} rbacControlObj={user.rbac.maestro}>
          <Tenant />
        </ProtectedView>
      </Route>
      <Route exact path="/multitenant">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.multiTenant.view]} rbacControlObj={user.rbac.opsWeb}>
          <DeploymentsView />
        </ProtectedView>
      </Route>
      <Route exact path="/multitenant/configuration">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.multiTenant.config]} rbacControlObj={user.rbac.opsWeb}>
          <ServicesConfiguration />
        </ProtectedView>
      </Route>
      <Route exact path="/multitenant/namespaces">
        <ProtectedView
          permissions={[rbacPermissions.opsWeb.view.multiTenant.namespaces]}
          rbacControlObj={user.rbac.opsWeb}
        >
          <NamespacesView />
        </ProtectedView>
      </Route>
      <Route exact path="/multitenant/namespaces/add">
        <ProtectedView permissions={[rbacPermissions.mtms.namespaces.create]} rbacControlObj={user.rbac.mtms}>
          <NamespaceAdd />
        </ProtectedView>
      </Route>
      <Route exact path="/multitenant/services">
        <ProtectedView
          permissions={[rbacPermissions.opsWeb.view.multiTenant.services]}
          rbacControlObj={user.rbac.opsWeb}
        >
          <ServicesView />
        </ProtectedView>
      </Route>
      <Route path="/multitenant/services/add">
        <ProtectedView permissions={[rbacPermissions.mtms.services.create]} rbacControlObj={user.rbac.mtms}>
          <ServiceAdd />
        </ProtectedView>
      </Route>
      <Route exact path="/multitenant/stacks">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.multiTenant.stacks]} rbacControlObj={user.rbac.opsWeb}>
          <StacksView />
        </ProtectedView>
      </Route>
      <Route exact path="/multitenant/stacks/add">
        <ProtectedView permissions={[rbacPermissions.mtms.stacks.create]} rbacControlObj={user.rbac.mtms}>
          <StackAdd />
        </ProtectedView>
      </Route>
      <Route exact path="/multitenant/tasks">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.multiTenant.tasks]} rbacControlObj={user.rbac.opsWeb}>
          <MultiTenantTasks />
        </ProtectedView>
      </Route>
      <Route exact path="/demo">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.demo]} rbacControlObj={user.rbac.opsWeb}>
          <DemoView />
        </ProtectedView>
      </Route>
      <Route exact path="/releases">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.cherryPicks]} rbacControlObj={user.rbac.opsWeb}>
          <GithubAuthWrapper>
            <ReleasesView />
          </GithubAuthWrapper>
        </ProtectedView>
      </Route>
      <Route exact path="/releases/cherryPicks">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.cherryPicks]} rbacControlObj={user.rbac.opsWeb}>
          <CherryPicksView />
        </ProtectedView>
      </Route>
      <Route exact path="/releases/add">
        <ProtectedView permissions={[rbacPermissions.opsWeb.view.cherryPicks]} rbacControlObj={user.rbac.opsWeb}>
          <GithubAuthWrapper>
            <ReleaseRequest />
          </GithubAuthWrapper>
        </ProtectedView>
      </Route>

      <Route exact path="/rbac-admin" />
      <Route exact path="/rbac-admin/schema">
        <ProtectedView permissions={[rbacPermissions.rbac.schema.export]} rbacControlObj={user.rbac.rbac}>
          <SchemaView />
        </ProtectedView>
      </Route>

      <Route exact path="/rbac-admin/self-view">
        <SelfView />
      </Route>

      <Route exact path="/rbac-admin/schema-editor">
        <ProtectedView permissions={[rbacPermissions.rbac.schema.edit]} rbacControlObj={user.rbac.rbac}>
          <SchemaEditorView />
        </ProtectedView>
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

export default Routes;
