import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';
import {Form} from '../../../../components/Base';
import langs from '../../../../localization/langs';

const STAGES = [
  {id: 0, name: langs('DEMO')},
  {id: 1, name: langs('TEST')}
];

function TenantStage({stage, setStage}) {
  return (
    <Form.Field>
      <Form.Label>{'Stage'}</Form.Label>
      <Select value={stage ?? {}} onChange={(e) => setStage(e.target.value)} style={{fontSize: 14}}>
        {_.map(STAGES, (stage) => (
          <MenuItem key={stage.id} value={stage.name} style={{fontSize: 14}}>
            {stage.name}
          </MenuItem>
        ))}
      </Select>
    </Form.Field>
  );
}

TenantStage.propTypes = {
  setStage: PropTypes.func.isRequired,
  stage: PropTypes.string.isRequired
};

export default TenantStage;
