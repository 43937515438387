import styled from 'styled-components';

import {Grid} from '@material-ui/core';

import InternalNavBar from '../Base/InternalNavBar';

import * as theme from '../../theme';

export const FullHeightGrid = styled(Grid)`
  height: 95%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 10px 0 10px;
`;

export const BreadCrumbs = styled.div`
  display: flex;
  gap: 5px;
  height: 15px;
  font-size: 16px;
  padding-left: 5px;
  z-index: 1;
`;

export const BreadCrumb = styled.span`
  color: ${theme.navbar.activeText};
  text-transform: uppercase;
  user-select: none;
  cursor: ${({$isClickable}) => ($isClickable ? 'pointer' : 'auto')};
`;

export const StyledInternalNavBar = styled(InternalNavBar)`
  height: calc(100% - 15px);
`;
