const fields = {
  CHART: 'chart',
  DESCRIPTION: 'description',
  ID: 'id',
  NAME: 'name',
  OWNER: 'owner',
  SUPPORTED_VERSION_RANGE: 'supportedVersionRange'
};

export default {
  fields
};
