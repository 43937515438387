import styled from 'styled-components';

export const Subheader = styled.div`
  margin-bottom: 1.2rem;
  margin-top: 0.4rem;
  font-size: 0.8rem;
  opacity: 0.7;

  & > span:not(:last-child) {
    margin-right: 1rem;
  }
`;
