import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../../../context';
import consts from '../../../../consts';
import services from '../../../../services';

import {Form, Paper, ProgressBar} from '../../../../components/Base';
import MultipleChoiceInput from '../../../../components/Base/inputs/MultipleChoiceInput';

import * as StyledCommon from './Common.styles';

function MajorTargetsSelector({majorTargetsSelection, setMajorTargetsSelection, branchType, label, displayUsage}) {
  const [majorTargets, setMajorTargets] = React.useState([]);
  const [majorsTenants, setMajorsTenants] = React.useState([]);
  const [majorsServices, setMajorsServices] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const notifications = context.Notifications.useNotifications();
  const {getUrl} = context.Navigation.useNavigation();

  React.useEffect(() => {
    fetchOpenCps(branchType)
      .then((majors) => {
        if (displayUsage) {
          _fetchTenantMajors().then((branches) => setMajorTargets(_.union(majors, branches)));
        } else {
          setMajorTargets(majors);
        }
      })
      .catch(notifications.error);
  }, []);

  React.useEffect(() => {
    if (_.isEmpty(majorTargets)) {
      return;
    }
    if (!displayUsage) {
      return;
    }
    setIsLoading(true);
    fetchMajorInfo()
      .then(([tenantsCount, servicesCount]) => {
        setMajorsTenants(formatCountData(tenantsCount.data, 'count', 'name'));
        setMajorsServices(formatCountData(servicesCount.data.items, 'value', 'key[0]'));
      })
      .catch((e) => {
        setMajorTargets([]);
        setMajorsServices([]);
        notifications.error(e.message);
        console.error(e.message);
      })
      .finally(() => setIsLoading(false));
  }, [majorTargets]);

  async function fetchOpenCps(branchType) {
    // to be sure we don't get old versions from before we started working with new release service
    var today = new Date();
    var oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    var formattedOneMonthAgo =
      oneMonthAgo.getFullYear() + '-' + (oneMonthAgo.getMonth() + 1) + '-' + oneMonthAgo.getDate();

    const branchStartPoint = (branchType === 'RELEASE_CANDIDATE' && 'RC-23.0-S167') || 'R-23.0-S167';
    const {
      data: {items}
    } = await services.tenants.artifact.listAll({
      branchGreater: branchStartPoint,
      branchTypes: [branchType],
      creationDate: formattedOneMonthAgo,
      length: 1,
      like: '%'
    });
    return items.filter((item) => item.branch).map((item) => formatBranchNameMajor(item.branch));
  }

  async function fetchMajorInfo() {
    const deploymentsSearchParams = new URLSearchParams();
    deploymentsSearchParams.set('groupBy', 'deploymentBranch');
    const promises = [
      services.tenants.count('applicationVersionMajor', 'environmentType=production'),
      services.services.deployments.count(deploymentsSearchParams)
    ];

    return Promise.all(promises);
  }

  async function _fetchTenantMajors() {
    const items = await services.tenants.count(
      'applicationVersionMajor',
      'environmentType=production&applicationVersionMajor[gt]=R-23.0-S166&environmentName[ne]=demo1&status=AVAILABILITY_STATUS_ACTIVE'
    );
    return items.data
      .filter(
        (branch) =>
          branch.name &&
          (branch.name.startsWith(consts.R_VERSION_PREFIX) || branch.name.startsWith(consts.RC_VERSION_PREFIX))
      )
      .map((branch) => branch.name);
  }

  function formatCountData(countData, countKey, branchKey) {
    return _(countData)
      .map((branch) => ({
        count: branch[countKey],
        major: formatBranchNameMajor(_.get(branch, branchKey, ''))
      }))
      .groupBy('major')
      .map((majorCount) => {
        return {
          count: _.sumBy(majorCount, 'count'),
          major: _.get(majorCount, '[0].major', '')
        };
      })
      .value();
  }

  function formatBranchNameMajor(major) {
    const majorRegex = /^(RC?)-(\d+).(\d+)-S(\d+).*$/;
    const replacementString = '$1-$2.$3-S$4';
    if (major) {
      return major.replace(majorRegex, replacementString);
    }
    return major;
  }
  function renderMajorLabel(major) {
    if (!displayUsage) {
      return <div>{major} </div>;
    }

    const tenantsCount = getMajorCount(majorsTenants, major);
    const servicesCount = getMajorCount(majorsServices, major);
    const tenantsLink = getDashboardLink(
      'tenants.withCustomFilter',
      tenantsCount,
      'applicationVersionMajor',
      major,
      'tenants'
    );
    const servicesLink = getDashboardLink('services.main', servicesCount, 'deploymentBranch', major, 'services');

    return (
      <div>
        {major} ({tenantsLink}, {servicesLink})
      </div>
    );
  }

  function getDashboardLink(to, count, paramKey, paramValue, label) {
    const text = `${label}: ${count}`;
    return count !== 0 ? (
      <StyledCommon.Link href={getUrl(to, {}, {[paramKey]: paramValue})}>{text}</StyledCommon.Link>
    ) : (
      text
    );
  }

  function getMajorCount(majors, lookupMajor) {
    return _.get(_.find(majors, ['major', lookupMajor]), 'count', 0);
  }

  return (
    <Paper style={{paddingBlock: '1em'}}>
      <Form.Field>
        <Form.Label>{label}</Form.Label>
        <ProgressBar isLoading={isLoading} />
        <MultipleChoiceInput
          onChange={setMajorTargetsSelection}
          options={majorTargets}
          value={majorTargetsSelection}
          render={renderMajorLabel}
        />
      </Form.Field>
    </Paper>
  );
}

MajorTargetsSelector.propTypes = {
  branchType: PropTypes.string.isRequired,
  displayUsage: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  majorTargetsSelection: PropTypes.array,
  setMajorTargetsSelection: PropTypes.func.isRequired
};

export default MajorTargetsSelector;
