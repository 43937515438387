import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import columnUtils from '../../utils/columnUtils';
import langs from '../../localization/langs';

import Column from '../../components/AGTable/Column';
import DateColumn from '../../components/AGTable/columns/DateColumn';
import EmailColumn from '../../components/AGTable/columns/EmailColumn';
import LinkColumn from '../../components/AGTable/columns/LinkColumn';
import ListColumn from '../../components/AGTable/columns/ListColumn';
import SeedActions from './actions/SeedActions';
import Table from '../../components/AGTable/Table';
import TenantNameColumn from '../../components/AGTable/columns/TenantNameColumn';

function DemoTenantsTable({data, dataDeps, setTableApi, onSeedActionClick, onStatusClick}) {
  return (
    <Table
      autoRefresh
      data={data}
      dataDeps={dataDeps}
      setTableApi={setTableApi}
      pagination
      title={langs('DEMO_TABLE_TITLE')}
      rowIdField="tenantId"
      filterModel={{}}
    >
      <Column
        colId="tenantName"
        exportFunc={({tenantName}) => tenantName}
        filter
        render={TenantNameColumn}
        sort="asc"
        suppressSizeToFit
        title={langs('NAME')}
        tooltip={({tenantName}) => tenantName}
        value={_.identity}
      />
      <Column filter sortable title={langs('VERSION')} value="applicationVersion" />
      <Column
        render={LinkColumn}
        renderProps={{onClick: ({tenantId, tenantName}) => onStatusClick(tenantId, tenantName)}}
        title={langs('DEMO_TABLE_COLUMN_STATUS')}
        value={({status}) => columnUtils.withPrefix('DEMO_', status)}
      />
      <Column render={DateColumn} title={langs('DEMO_TABLE_COLUMN_START_TIME')} value="startTime" />
      <Column
        title={langs('DEMO_TABLE_COLUMN_DURATION')}
        value={({startTime, endTime}) => columnUtils.duration(startTime, endTime)}
      />
      <Column title={langs('DEMO_TABLE_COLUMN_VERTICAL')} value="vertical" />
      <Column hide render={ListColumn} title={langs('DEMO_TABLE_COLUMN_MODULES_TO_SEED')} value="modulesToSeed" />
      <Column render={EmailColumn} title={langs('DEMO_TABLE_COLUMN_INITIATOR_EMAIL')} value="initiatorEmail" />
      <Column hide title={langs('DEMO_TABLE_COLUMN_TIMEZONE')} value="timezone" />
      <Column hide render={ListColumn} title={langs('DEMO_TABLE_COLUMN_TABLES_TO_IGNORE')} value="tablesToIgnore" />
      <Column
        hide
        title={langs('DEMO_TABLE_COLUMN_OVERRIDE_VMS')}
        value={({overrideVms}) => columnUtils.boolean(overrideVms)}
      />
      <Column
        render={SeedActions}
        renderProps={{onClick: onSeedActionClick}}
        title={langs('DEMO_TABLE_COLUMN_ACTIONS')}
        tooltip={false}
        value={_.identity}
        exportFunc={() => ''}
      />
    </Table>
  );
}

DemoTenantsTable.propTypes = {
  data: Table.propTypes.data,
  dataDeps: Table.propTypes.dataDeps,
  onSeedActionClick: PropTypes.func.isRequired,
  onStatusClick: PropTypes.func.isRequired,
  setTableApi: Table.propTypes.setTableApi
};

export default DemoTenantsTable;
