import React from 'react';

import AddIcon from '@material-ui/icons/Add';

import columnDefs from '../../../utils/agGridUtils/columnDefs';
import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import models from '../../../models';
import tableUtils from '../table.utils';
import useUrlSearch from '../../../components/AGTable/hooks/useUrlSearch';

import AGTable from '../../../components/AGTable/Table';
import {Button} from '../../../components/Base';
import Column from '../../../components/AGTable/Column';
import OpsLink from '../../../components/OpsLink';
import ProtectedComponent from '../../../components/Rbac/ProtectedComponent';
import StackPreview from '../../../components/Services/StackPreview';

import * as Styled from './StacksView.styles';

const SET_FILTER_COL = {
  ...columnDefs.BASIC_COL,
  filter: async ({colDef, success}) => success(await tableUtils.getStacksFilters(colDef)),
  filterParams: {refreshValuesOnOpen: false}
};

async function getRows(params) {
  params.success(await tableUtils.getStacks(params.api, params.request));
}

function StacksView() {
  const [tableApi, setTableApi] = React.useState();
  const [stackPreview, setStackPreview] = React.useState();

  const [filterModel, onFilterChanged] = useUrlSearch(tableApi);

  function closePreview() {
    setStackPreview(null);
  }

  const user = context.User.useUser();

  return (
    <div>
      <Styled.Drawer open={Boolean(stackPreview)} onClose={closePreview}>
        <ProtectedComponent permissions={[consts.rbac.permissions.mtms.stacks.edit]} rbacControlObj={user.rbac.mtms}>
          <StackPreview stackId={stackPreview?.id} onClose={closePreview} />
        </ProtectedComponent>
      </Styled.Drawer>
      <Styled.ActionContainer>
        <ProtectedComponent permissions={[consts.rbac.permissions.mtms.stacks.create]} rbacControlObj={user.rbac.mtms}>
          <OpsLink to="services.stacksAdd">
            <Button text icon={<AddIcon />}>
              {langs('STACKS_NEW_STACK')}
            </Button>
          </OpsLink>
        </ProtectedComponent>
      </Styled.ActionContainer>
      <Styled.StyledPaper>
        <AGTable
          data={getRows}
          filterModel={filterModel}
          onFilterChanged={onFilterChanged}
          onRowClicked={({data}) => user.rbac.mtms.allowed.stacks.edit() && setStackPreview(data)}
          pagination
          setTableApi={setTableApi}
          title={langs('STACKS')}
        >
          <Column
            {...columnDefs.NUMBER_COL}
            colId="id"
            title={langs('STACKS_FIELD_ID')}
            value={models.stacks.fields.ID}
          />
          <Column
            {...SET_FILTER_COL}
            colId="name"
            title={langs('STACKS_FIELD_NAME')}
            value={models.stacks.fields.NAME}
          />
        </AGTable>
      </Styled.StyledPaper>
    </div>
  );
}

export default StacksView;
