import _ from 'lodash';
import React from 'react';

import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import roles from '../../../roles';
import services from '../../../services';
import useMenuItem from '../../AGTable/hooks/useMenuItem';

import ConfirmationTextboxModal from '../../Base/ConfirmationTextboxModal';

export default function useStopMenuItem(onChange = _.noop) {
  const notifications = context.Notifications.useNotifications();

  async function onConfirm({tenantId}) {
    try {
      await services.tenants.stop(tenantId);
      onChange();
    } catch (error) {
      notifications.error(langs('ERROR_STOP_TENANT'));
    }
  }

  const user = context.User.useUser();
  const [getDef, Modal, row] = useMenuItem(
    onConfirm,
    langs('BUTTON_STOP'),
    'outline_stop_circle_white_24dp',
    user.rbac.maestro.isEnabled() ? consts.rbac.permissions.maestro.tenant.stop : roles.MANAGE_TENANTS,
    user.rbac.maestro,
    ConfirmationTextboxModal
  );

  const tenantName = _.get(row, 'tenantName', '');
  const ExtendedModal = React.cloneElement(Modal, {
    description: langs('TENANT_INSERT_NAME'),
    matchText: tenantName,
    title: langs('TENANT_STOP_TITLE')
  });

  return [getDef, ExtendedModal];
}
