import styled from 'styled-components';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as theme from '../../theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const NoMarginFormControlLabel = styled(FormControlLabel)`
  margin-left: -4px;
  margin-right: 0;
`;

export const Title = styled.span`
  color: ${theme.navbar.text};
  font-weight: 600;
  font-size: 16px;
  user-select: none;
`;

export const Filter = styled.span`
  margin-right: 5px;
  display: inline-block;
  color: ${theme.navbar.text};
  font-weight: 100;
  font-size: 14px;
  user-select: none;
  text-decoration: underline;
  cursor: pointer;
`;
