import PropTypes from 'prop-types';
import React from 'react';

import collectorUtils from '../../../views/Collectors/collectorUtils';

import TenantCollectorsTable from '../../Collectors/Table/TenantCollectorsTable';

import * as Styled from './Collectors.styles';

function Collectors({tenantId}) {
  const getRows = React.useCallback(async (params) => {
    return collectorUtils.getRows(params, tenantId);
  }, []);

  return (
    <Styled.Container>
      <TenantCollectorsTable getRows={getRows} />
    </Styled.Container>
  );
}

Collectors.propTypes = {
  tenantId: PropTypes.string
};

export default Collectors;
