import styled from 'styled-components';

export const Label = styled.div`
  line-height: 32px;
`;

export const Note = styled.div`
  user-select: none;
  font-size: 0.9rem;
  display: flex;
  gap: 10px;
`;

export const NoteTitle = styled.span`
  font-weight: bold;
`;
