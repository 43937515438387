import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import hooks from '../../../../hooks';
import langs from '../../../../localization/langs';
import services from '../../../../services';

import {Form} from '../../../../components/Base';

import * as Styled from '../ServiceForm.styles';

function ServiceOwner({value, onChange}) {
  const [ownersResponse, isLoadingOwners] = hooks.useGetResult(() => services.services.teams.list(), []);

  function isShowError() {
    return _.isEmpty(value);
  }

  const owners = _.map(ownersResponse?.items, 'name');
  return (
    <Form.Field>
      <Form.Label>{langs('SERVICES_FIELD_OWNER')}</Form.Label>
      {isLoadingOwners ? (
        <Select disabled value="0">
          <MenuItem value="0">{langs('LOADING')}</MenuItem>
        </Select>
      ) : (
        <Select required value={value ?? ''} onChange={(e) => onChange(e.target.value)}>
          {_.map(owners, (owner) => (
            <MenuItem key={owner} value={owner}>
              {owner}
            </MenuItem>
          ))}
        </Select>
      )}
      {isShowError() && <Styled.Error>{langs('ERROR_INVALID_SERVICE_OWNER')}</Styled.Error>}
    </Form.Field>
  );
}

ServiceOwner.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default ServiceOwner;
