import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import langs from '../../localization/langs';
import models from '../../models';

import TaskDialogActions from './TaskDialogActions';
import {TaskParametersForm} from '../Base';
import TenantInput from '../Base/inputs/TenantInput';

import * as Styled from './SelectParameters.styles';

const extendedTypes = {
  [models.configuration.TYPES.TENANT_ID]: TenantInput
};

function SelectParameters({
  setDialogTitle,
  closeModal,
  onBack,
  onNext,
  parametersValues,
  setParametersValues,
  parameters,
  title
}) {
  React.useEffect(function applyDialogTitle() {
    setDialogTitle(title);
  }, []);

  return (
    <div>
      {_.isEmpty(parameters) ? (
        <Styled.Message>{langs('NO_PARAMETERS_MESSAGE')}</Styled.Message>
      ) : (
        <TaskParametersForm
          parameters={parameters}
          parametersValues={parametersValues}
          setParametersValues={setParametersValues}
          extendedTypes={extendedTypes}
        />
      )}
      <TaskDialogActions onBack={onBack} onNext={onNext} onCancel={closeModal} />
    </div>
  );
}

SelectParameters.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onNext: PropTypes.func.isRequired,
  parameters: PropTypes.array,
  parametersValues: PropTypes.object,
  setDialogTitle: PropTypes.func.isRequired,
  setParametersValues: PropTypes.func,
  title: PropTypes.string
};

export default SelectParameters;
