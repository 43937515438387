import _ from 'lodash';
import React from 'react';

import consts from '../../../consts';
import context from '../../../context';
import langs from '../../../localization/langs';
import services from '../../../services';

import * as editorUtil from './util';

import AddBinding from './Parts/AddBinding';
import SchemaEditorForm from './Parts/SchemaEditorForm';
import {ConfirmationModal} from '../../Base';

function SchemaEditor() {
  const [addBindingModalOpen, setAddBindingModalOpen] = React.useState(false);
  const [confirmModalDetails, setConfirmModalDetails] = React.useState({open: false});
  const [currentBinding, setCurrentBinding] = React.useState(null);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [idpGroups, setIdpGroups] = React.useState([]);
  const [rbacEnvs, setRbacEnvs] = React.useState([]);
  const [schema, setSchema] = React.useState({});
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [selectedService, setSelectedService] = React.useState(null);
  const [selectedEnv, setSelectedEnv] = React.useState(null);

  const notifications = context.Notifications.useNotifications();
  const user = context.User.useUser();

  async function loadRbacSchema() {
    try {
      setIsLoading(true);
      const [rbacEnvsRequestResult, oktaGroupsRequestResult, schemaRequestResult] = await Promise.all([
        services.rbac.getEnvs(),
        services.rbac.listIdpGroups(),
        services.rbac.getPermissionsSchema()
      ]);
      setRbacEnvs(rbacEnvsRequestResult);
      setIdpGroups(oktaGroupsRequestResult.data);
      setSchema(schemaRequestResult.data);
      setIsDirty(false);
    } catch (ex) {
      setIdpGroups([]);
      setSchema({});
      notifications.error(ex.message);
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    loadRbacSchema();
  }, []);

  const transformedSchema = React.useMemo(
    () => editorUtil.decorateSchema(user.rbac.schema.transform.asGroups(schema)),
    [schema]
  );

  const boundEnvs = React.useMemo(
    () => editorUtil.mapBoundEnvs(selectedService, isDirty, transformedSchema, selectedGroup),
    [selectedService]
  );

  const boundServices = React.useMemo(() => editorUtil.mapBoundServices(selectedGroup, isDirty, transformedSchema), [
    selectedGroup
  ]);

  const getAddButtonEnableState = () => !isLoading && !isDirty;
  const getDeleteButtonEnableState = () => !isLoading && !isDirty && !!selectedEnv;
  const getSaveButtonEnableState = () => {
    if (!currentBinding) {
      return false;
    }
    const selectedRoles = _.filter(currentBinding.roles, {selected: true});
    return !isLoading && isDirty && !_.isEmpty(selectedRoles);
  };

  const openConfirmModal = (descriptionLangKey, onConfirm) =>
    setConfirmModalDetails({
      description: langs(descriptionLangKey, {
        envName: selectedEnv,
        groupName: selectedGroup,
        serviceName: selectedService
      }),
      onConfirm,
      open: true
    });

  const onAddBindingConfirm = (group, service, env) => {
    setIsDirty(true);
    setCurrentBinding({
      envName: env,
      groupName: group,
      roles: editorUtil.getAllRolesUnselected(transformedSchema),
      serviceName: service
    });
    setSelectedGroup(group);
    setSelectedService(service);
    setSelectedEnv(env);
    setAddBindingModalOpen(false);
  };

  const onDeleteBindingClick = () => {
    openConfirmModal('DELETE_GROUP_SERVICE_ENV_BINDING_CONFIRMATION', () => {
      services.rbac.deleteGroupBinding(selectedGroup, selectedService, selectedEnv).then(() => loadRbacSchema());
    });
  };

  const onEnvSelection = (env) => {
    if (isDirty) {
      setConfirmModalDetails({
        description: langs('CANCEL_EDIT_BINDING'),
        onConfirm: () => {
          setCurrentBinding(null);
          setIsDirty(false);
          setEnvSelection(env);
        },
        open: true
      });
    } else {
      setEnvSelection(env);
    }
  };

  const onRoleItemClick = (roleId, newValue) => {
    if (!isDirty) {
      setIsDirty(true);
    }
    const roles = editorUtil.updateRoleState(currentBinding, roleId, newValue);
    setCurrentBinding(_.assign({}, currentBinding, {roles}));
  };

  const onSaveBindingClick = () => {
    const onConfirm = () => {
      const roleIds = _.map(_.filter(currentBinding.roles, {selected: true}), 'id');
      services.rbac
        .saveGroupToRoleBinding(selectedGroup, selectedService, selectedEnv, roleIds)
        .then(() => loadRbacSchema());
    };
    openConfirmModal('SAVE_GROUP_SERVICE_ENV_BINDING_CONFIRMATION', onConfirm);
  };

  const setEnvSelection = (env) => {
    if (!env) {
      setCurrentBinding(null);
      setIsDirty(null);
      setSelectedEnv(null);
    } else {
      const roles = editorUtil.mapBoundRoles(transformedSchema, selectedGroup, selectedService, env);
      setCurrentBinding({envName: env, groupName: selectedGroup, roles, serviceName: selectedService});
      setSelectedEnv(env);
    }
  };

  const labelDefinitions = editorUtil.getLabelDefinitions(
    setSelectedGroup,
    transformedSchema,
    selectedGroup,
    setSelectedService,
    boundServices,
    selectedService,
    onEnvSelection,
    boundEnvs,
    selectedEnv
  );

  return (
    <>
      <ConfirmationModal
        open={confirmModalDetails.open}
        description={confirmModalDetails.description}
        onConfirm={() => confirmModalDetails.onConfirm()}
        onCancel={() => setConfirmModalDetails({open: false})}
      />
      <AddBinding
        envs={rbacEnvs}
        groups={selectedGroup ? [_.find(idpGroups, {name: selectedGroup})] : idpGroups}
        onConfirm={onAddBindingConfirm}
        onClose={() => setAddBindingModalOpen(false)}
        open={addBindingModalOpen}
        schema={transformedSchema}
        services={selectedService ? [selectedService] : _.values(consts.rbac.SERVICE_NAMES_BE)}
      />
      <SchemaEditorForm
        enableAddButton={getAddButtonEnableState()}
        enableDeleteButton={getDeleteButtonEnableState()}
        enableSaveButton={getSaveButtonEnableState()}
        isLoading={isLoading}
        labelDefinitions={labelDefinitions}
        onAddBindingClick={() => setAddBindingModalOpen(true)}
        onDeleteBindingClick={onDeleteBindingClick}
        onRoleItemClick={onRoleItemClick}
        onSaveButtonClick={onSaveBindingClick}
        roles={currentBinding?.roles}
      />
    </>
  );
}

export default SchemaEditor;
