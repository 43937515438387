import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import context from '../../../context';
import langs from '../../../localization/langs';
import services from '../../../services';

import RmqRelayInfo from './RmqRelayInfo';
import SelectParameters from '../SelectParameters';

function RmqRelaySelector({rmqRelayCommand, setCommand, hosts, ...props}) {
  const [parameters, setParameters] = React.useState([]);
  const [relay, setRelay] = React.useState(null);

  const notifications = context.Notifications.useNotifications();

  const tenantId = _.get(_.head(hosts), 'tenantId');
  const tenantName = _.get(_.head(hosts), 'tenantName');

  React.useEffect(() => {
    if (_.isEmpty(tenantId)) {
      return;
    }
    services.tenants
      .getTenantRmqRelay(tenantId)
      .then((items) => {
        const source = _.get(_.head(_.filter(items, {destination: tenantName})), 'source');
        setRelay(source);
      })
      .catch((error) => {
        notifications.error(error.message);
        console.error(error.message);
      });
  }, [tenantId]);

  React.useEffect(() => {
    if (_.isEmpty(rmqRelayCommand)) {
      return;
    }
    services.tenants
      .getRmqRelayCommand(rmqRelayCommand)
      .then((command) => setCommandInfo(command))
      .catch((error) => {
        notifications.error(error.message);
        console.error(error.message);
      });
  }, [rmqRelayCommand]);

  function setCommandInfo(command) {
    if (_.isEmpty(command)) {
      notifications.error(langs('FAILED_GET_COMMANDS'));
      console.error(langs('FAILED_GET_COMMANDS'));
    } else {
      setParameters(command.parameters);
      setCommand(command);
    }
  }

  if (_.isEmpty(parameters)) {
    return null;
  }

  return (
    <div>
      {relay ? (
        <RmqRelayInfo relayInfo={relay} setRelayInfo={setRelay} tenantId={tenantId} {...props} />
      ) : (
        <SelectParameters parameters={parameters} title={langs('CREATE_RMQ_RELAY')} {...props} />
      )}
    </div>
  );
}

RmqRelaySelector.propTypes = {
  hosts: PropTypes.array.isRequired,
  rmqRelayCommand: PropTypes.string.isRequired,
  setCommand: PropTypes.func.isRequired
};

export default RmqRelaySelector;
