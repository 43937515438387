import icons from '../components/Tasks/icons';
import langs from '../localization/langs';
import {collectorCommandStatuses} from '../components/Tasks/enums';

export const commandType = {
  APP_DEPLOYMENT: 4,
  CUSTOMER_CONFIGURATION: 10,
  FIRMWARE_UPGRADE: 2,
  HELM: 8,
  MT_APP_DEPLOYMENT: 6,
  PROVISION: 3,
  SHELL_COMMAND: 1,
  TERRAFORM: 5,
  WORKER_ANSIBLE: 7,
  WORKER_COMMAND: 9
};

export const category = {
  FIELD_ACTIONS: 'FIELD_ACTIONS'
};

export const commandToText = {
  [commandType.APP_DEPLOYMENT]: langs('APP_DEPLOYMENT'),
  [commandType.SHELL_COMMAND]: langs('SHELL_COMMAND'),
  [commandType.FIRMWARE_UPGRADE]: langs('FIRMWARE_UPDATE'),
  [commandType.PROVISION]: langs('COLLECTOR_PROVISION'),
  [commandType.TERRAFORM]: langs('TERRAFORM'),
  [commandType.MT_APP_DEPLOYMENT]: langs('APP_DEPLOYMENT'),
  [commandType.HELM]: langs('HELM'),
  [commandType.WORKER_ANSIBLE]: langs('WORKER_ANSIBLE'),
  [commandType.WORKER_COMMAND]: langs('WORKER_COMMAND'),
  [commandType.CUSTOMER_CONFIGURATION]: langs('CUSTOMER_CONFIGURATION')
};

function getTextByType(type, converter = commandToText) {
  return converter[type];
}

export function getCommandIconAndTextByStatus(status) {
  switch (status) {
    case collectorCommandStatuses.FAILED:
      return [icons.Failed, langs('FAILED'), true];
    case collectorCommandStatuses.SUCCESS:
      return [icons.Success, langs('COMPLETED'), true];
    case collectorCommandStatuses.PENDING:
      return [icons.Pending, langs('CREATED'), false];
    case collectorCommandStatuses.RUNNING:
      return [icons.Running, langs('STARTED'), true];
    case collectorCommandStatuses.WAITING_FOR_PARENT:
      return [icons.Pending, langs('WAITING_FOR_PARENT'), true];
    case collectorCommandStatuses.PARENT_FAILED:
      return [icons.Failed, langs('PARENT_FAILED'), false];
    default:
      throw new Error(`Unknown status ${status}`);
  }
}

export default {commandToText, commandType, getCommandIconAndTextByStatus, getTextByType};
