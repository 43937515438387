import styled from 'styled-components';

import {makeStyles} from '@material-ui/core/styles';

export const Container = styled.div``;

export const Label = styled.div`
  display: inline-block;
`;

export const useStyles = makeStyles((theme) => ({
  menu: {
    backgroundColor: theme.palette.grey['900'],
    fontSize: 12,
    minWidth: 200,
    padding: '2px 12px'
  }
}));
